import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { hexaToRgba } from '../../../utils/styleUtils'
import Typography from '../Typography/Typography'


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  & > tbody > *:nth-child(odd) {
    background-color: ${({ theme, transparent }) => transparent ? 'unset' : hexaToRgba(theme.default + '10')};
  }
`

const Row = styled.tr`
  white-space: nowrap;
  & > * > * {
    display: inline-block;
  }
`

const Cell = styled.td`
  padding: 8px 12px;
  & > :not(:last-child) {
    margin-right: 12px;
  }
`

const WideCell = styled(Cell)`
  position: relative;
  width: 100%;
  & > * {
    display: inline-block;
  }
  &:before {
    content: '&nbsp;';
    visibility: hidden;
  }
  & span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  & > * {
    width: calc(50% - 6px);
    white-space: nowrap;
    background-color: ${({ theme }) => hexaToRgba(theme.default + '10')};
    margin-bottom: 12px;
  }
  & > *:nth-child(odd) {
    margin-right: 12px;
  }
  & > *:nth-child(${({ lc }) => lc}),& > *:nth-child(${({ slc }) => slc}) {
    margin-bottom: 0;
  }
`

const GridItem = styled(Cell)`
  background-color: ${({ theme, transparent }) => transparent ? 'unset' : hexaToRgba(theme.default + '10')};
  white-space: nowrap;
`

const TruncateFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px;
`

const ColorPreviewCircle = styled.div`
  display: inline-block;
  background-color: ${({color}) => color};
  border-radius: 50%;
  width: 8px;
  height: 8px;
`

const PairRow = ({ colors, group1, group2 }) => {
  const maxChars = 10
  const name1 = (group1.name.length > maxChars) ? group1.name.slice(0, maxChars) + '...' : group1.name
  const name2 = (group2.name.length > maxChars) ? group2.name.slice(0, maxChars) + '...' : group2.name
  return (
    <Row>
      <Cell>
        <ColorPreviewCircle color={colors(group1.groupId)}/>
        <Typography variant="small" weight="light">{name1}</Typography>
      </Cell>
      <td style={{ width: '100%' }} />
      <Cell>
        <ColorPreviewCircle color={colors(group2.groupId)}/>
        <Typography variant="small" weight="light">{name2}</Typography>
      </Cell>
    </Row>
  )
}

const CountRow = ({ colors, values, group }) => {
  return (
    <Row>
      <Cell>
        <ColorPreviewCircle color={colors(group.groupId)}/>
      </Cell>
      <WideCell>
        <Typography variant="small" weight="light">{group.name}</Typography>
      </WideCell>
      <Cell>
        <Typography variant="small" weight="light">{values(group.groupId)} times</Typography>
      </Cell>
    </Row>
  )
}

const SingleSquare = ({ colors, group, transparent }) => {
  const maxChars = 20
  const name = (group.name.length > maxChars) ? group.name.slice(0, maxChars) + '...' : group.name
  return (
    <GridItem as="div" transparent={transparent}>
      <ColorPreviewCircle color={colors(group.groupId)}/>
      <Typography variant="small" weight="light">{name}</Typography>
    </GridItem>
  )
}

function renderTable(children, transparent) {
  return (
    <Table transparent={transparent}>
      <tbody>{children}</tbody>
    </Table>
  )
}

function renderSingleGrid(children) {
  if (children.length > 1) {
    const lastCount = children.length + (children.length % 2)
    const secondLastCount = (lastCount - 1)
    return (
      <Grid slc={secondLastCount} lc={lastCount}>
        {children}
      </Grid>
    )
  } else {
    return (
      <div>
        {children}
      </div>
    )
  }
}

const GroupList = ({children, isSingles, maxRows, transparent}) => {
  let items, willTruncate, hiddenCount
  if (isSingles) {
    if (!children.length) return null
    const maxChildren = (maxRows * 2)
    willTruncate = (children.length > maxChildren)
    const shownCount = willTruncate ? (maxChildren - 2) : children.length
    const displayedChildren = children.slice(0, shownCount)
    items = renderSingleGrid(displayedChildren)
    hiddenCount = (children.length - shownCount)
  } else {
    if (!children.length) return null
    willTruncate = (children.length > maxRows)
    const shownCount = willTruncate ? (maxRows - 1) : children.length
    const displayedChildren = children.slice(0, shownCount)
    items = renderTable(displayedChildren, transparent)
    hiddenCount = (children.length - shownCount)
  }

  let truncateElement
  if (willTruncate) {
    truncateElement = (
      <TruncateFooter>
        <Typography variant="small" weight="light">
          <FormattedMessage
            id="owner.EdgeList.truncateText"
            defaultMessage="... and {count} more {count, plural, one {connection} other {connections} }"
            values={{ count: hiddenCount }}
          />
        </Typography>
      </TruncateFooter>
    )
  }

  return (
    <div>
      {items}
      {truncateElement}
    </div>
  )
}

GroupList.PairRow = PairRow
GroupList.SingleSquare = SingleSquare
GroupList.CountRow = CountRow

GroupList.propTypes = {
  isSingles: PropTypes.bool,
  maxRows: PropTypes.number
}

GroupList.defaultProps = {
  isSingles: false,
  maxRows: 5
}

export default GroupList
