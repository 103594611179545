import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import styled, { withTheme } from 'styled-components'

import Typography from '../../../common/Typography/Typography'
import AnalyticsCard from '../../AnalyticsCard/AnalyticsCard'
import InteractionIcon from '../../AnalyticsInteraction/InteractionIcon'
import { formatData } from '../../AnalyticsInteraction/AnalyticsInteraction'


const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 223px;
  padding: 30px 0 20px 0;
`

const Details = styled.div`
  flex: 0 0 21px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
`

const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const titleMessages = defineMessages({
  connected: { defaultMessage: '{count} {count, plural, one {connection} other {connections}}', id: 'owner.InteractionAnalyticsCard.title.connected' },
  stronglyConnected: { defaultMessage: '{count} strong {count, plural, one {connection} other {connections}}', id: 'owner.InteractionAnalyticsCard.title.stronglyConnected' },
  selfReferential: { defaultMessage: '{count} self-referential {count, plural, one {group} other {groups}}', id: 'owner.InteractionAnalyticsCard.title.selfReferential' },
  disconnected: { defaultMessage: '{count} disconnected {count, plural, one {group} other {groups}}', id: 'owner.InteractionAnalyticsCard.title.disconnected' },
})


class InteractionAnalyticsCard extends Component {
  handleClick = () => this.props.onClick('interaction')

  renderChart(category) {
    const { locked } = this.props
    const hasCategory = (category != null)
    return (
      <ChartContainer>
        <InteractionIcon
          type={hasCategory ? category.key : 'connected'}
          isOneWay={false}
          empty={!hasCategory}
          inverted={locked}
        />
      </ChartContainer>
    )
  }

  renderDetails(count) {
    const { locked } = this.props
    const percentage = Math.round(count * 100)
    const amount = (
      <Typography inverted={locked} variant="small" weight="bold">
        {percentage} %
      </Typography>
    )
    return (
      <Details>
        <Typography inverted={locked} variant="small" weight="light">
          <FormattedMessage
            id="owner.InteractionAnalyticsCard.details"
            defaultMessage="Exchanged {amount} of all questions"
            values={{ amount }}
          />
        </Typography>
      </Details>
    )
  }

  render() {
    const { intl, groupGraph, locked, loading } = this.props
    let title, chart, details
    if (groupGraph && !loading) {
      const categories = formatData(groupGraph.nodes, groupGraph.edges)
      const category = categories[0]
      title = intl.formatMessage(titleMessages[category.key], { count: category.count })
      chart = this.renderChart(category)
      details = this.renderDetails(category.percentage)
    } else {
      chart = this.renderChart(null)
      details = this.renderDetails(0)
    }
    return (
      <AnalyticsCard
        subTitle="Interaction"
        title={title}
        onClick={this.handleClick}
        disabled={!groupGraph && !locked}
        locked={locked}
        loading={loading}
      >
        <Content>
          {chart}
          {details}
        </Content>
      </AnalyticsCard>
    )
  }
}

export default withTheme(injectIntl(InteractionAnalyticsCard))
