import { gql, useLazyQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useToggleQuestionPin } from '../../../../operations/question'
import ConversationHighlightsCard from '../../../../components/result/ConversationHighlightsCard/ConversationHighlightsCard'

const SEARCH_QUESTIONS_QUERY = gql`
  query q($filter:QuestionSearchFilter!) {
    questions(filter:$filter) {
      items {
        questionId
        createdAt
        questionContent
        questionSentAt
        questionGroupId
        answerContent
        answerSentAt
        answerGroupId
        isFirstQuestion
        pins {
          count
        }
      }
    }
  }
`

const HighlightsSection = ({ cardRef, qvestId, wordFrequencies, schedule, summary, error, loading, isOwner }) => {
  const [highlightedWords, setHighlightedWords] = useState(null)
  const toggleQuestionPin = useToggleQuestionPin()
  const [searchQuestions, { data }] = useLazyQuery(SEARCH_QUESTIONS_QUERY, { fetchPolicy: 'cache-and-network' })
  const questions = data && data.questions.items

  const handleShowAll = () => {
    setHighlightedWords(null)
    const filter = { qvestId }
    searchQuestions({ variables: { filter } })
  }

  const handleWordClick = (word) => {
    const wordFreq = wordFrequencies.find(w => w.topWord === word)
    const filter = { qvestId, contentTextSearch: wordFreq.stem }
    searchQuestions({ variables: { filter } })
    setHighlightedWords(wordFreq.words.map(w => w.text))
  }

  const handleShowPinned = () => {
    setHighlightedWords(null)
    const filter = { qvestId, isPinned: true }
    searchQuestions({ variables: { filter } })
  }

  const handleQuestionPinToggle = async (questionId, pinned) => {
    await toggleQuestionPin(qvestId, questionId, pinned)
    return pinned
  }

  return (
    <ConversationHighlightsCard
      cardRef={cardRef}
      onShowAll={handleShowAll}
      onShowPinned={handleShowPinned}
      wordFrequenciesData={wordFrequencies}
      questions={questions}
      schedule={schedule}
      summary={summary}
      highlightedWords={highlightedWords}
      error={error}
      isLoading={loading}
      isOwner={isOwner}
      onWordClick={handleWordClick}
      onPinToggle={handleQuestionPinToggle}
    />
  )
}

export default HighlightsSection
