import * as d3 from 'd3'


/*
 Map group ids to colors in a consistent way given the same set of ids
 */
export function makeGroupColorScales(theme, ids) {
  // TODO: Sort based on created date like themes? (Results in less confusing editor UX with jumping colors)
  const sortedIds = [...ids].sort()

  // Primary color scale (id -> color)
  const primaryColors = d3.scaleOrdinal()
    .range(theme.data.primary)
    .domain(sortedIds)
  // Secondary color scale (id -> color)

  const secondaryColors = d3.scaleOrdinal()
    .range(theme.data.secondary)
    .domain(sortedIds)

  return { primaryColors, secondaryColors }
}

/*
  Map themes to colors in a consistent way
 */
export function makeThemeColorScales(styledTheme, themes) {
  // Prepare Ids sorted by created date
  themes = [...themes]
  themes = themes.sort((a, b) => a.createdAt - b.createdAt)
  const themeIds = themes.map(t => t.themeId)

  // Make scale
  return d3.scaleOrdinal()
    .range(styledTheme.data.secondary)
    .domain(themeIds)
}

export function makeAvatarColorScale(theme, participants, groupings = null) {
  const hasGroups = groupings && (groupings.length > 0) && (groupings[0].groups.length > 0)
  if (hasGroups) {
    const groups = groupings[0].groups
    const groupIds = groups.map(g => g.groupId)
    const scale = makeGroupColorScales(theme, groupIds).primaryColors
    return (participant) => scale(participant.groupingConnections[0].groupId)
  } else {
    const names = participants.map(p => p.name)
    const scale = makeGroupColorScales(theme, names).primaryColors
    return (participant) => scale(participant.name)
  }
}
