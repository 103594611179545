import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import GenericModal from '../../common/GenericModal/GenericModal'
import Typography from '../../common/Typography/Typography'
import LoadingBody from './LoadingBody'
import { generateErrorMessage } from './participantModalUtils'


const messages = defineMessages({
  title: { defaultMessage: 'Resend mail',  id: 'owner.ResendParticipantModal.title' }
})


const ErrorDescription = ({ hidden }) => {
  if (hidden) return null
  return (
    <div>
      <Typography weight="light">
        <FormattedMessage
          id="owner.ResendParticipantModal.error.description"
          defaultMessage="Contact your account manager for further elaboration."
        />
      </Typography>
    </div>
  )
}

const ConfirmDescription = ({ hidden }) => {
  if (hidden) return null
  return (
    <div>
      <Typography.p weight="light">
        <FormattedMessage
          id="owner.ResendParticipantModal.confirm.description"
          defaultMessage="Are you sure you wish to send the latest notification mail again?"
        />
      </Typography.p>
    </div>
  )
}


const CompletedDescription = ({ hidden, participant }) => {
  if (hidden) return null
  const address = (<Typography weight="bold">{participant.email}</Typography>)
  return (
    <div>
      <Typography.p weight="light">
        <FormattedMessage
          id="owner.ResendParticipantModal.completed"
          defaultMessage="E-mail successfully delivered to {address}."
          values={{ address }}
        />
      </Typography.p>
    </div>
  )
}

const generateDisclaimer = () => {
  const emphasized = (
    <strong>
      <FormattedMessage
        id="owner.ResendParticipantModal.description.recommended"
        defaultMessage="not recommended"
      />
    </strong>
  )
  return ({
    type: 'info',
    content: (
      <Fragment>
        <Typography.p weight="light">
          <FormattedMessage
            id="owner.ResendParticipantModal.description"
            defaultMessage="Resending can be useful if participants contact you and inform that they lost a notification e-mail from Qvest."
          />
        </Typography.p>
        <Typography.p weight="light">
          <FormattedMessage
            id="owner.ResendParticipantModal.description2"
            defaultMessage="Beware that resending e-mails to increase engagement is {emphasized}. Qvest already has carefully designed mechanisms to notify and engage without flooding participants' inboxes."
            values={{ emphasized }}
          />
        </Typography.p>
      </Fragment>
    )
  })
}

const renderMessage = (participant, confirm, error, loading, complete, intl) => {
  if (loading || complete) {
    return null
  }
  if (confirm) {
    return generateDisclaimer(participant, intl)
  }
  if (error) {
    return generateErrorMessage(participant, intl)
  }
  return null
}

const hasError = ({ lastMailEvent }) =>
  (lastMailEvent && (lastMailEvent.eventName === 'bounce' || lastMailEvent.eventName === 'dropped'))

const ResendParticipantModal = ({ participant, confirm, loading, complete, intl, onSubmit, onClose }) => {
  const error = hasError(participant)
  const handleSubmit = () => {
    if (complete || (!confirm && error)) return onClose()
    onSubmit(participant)
  }
  const modalProps = {
    title: intl.formatMessage(messages.title),
    message: renderMessage(participant, confirm, error, loading, complete, intl),
    yes: confirm || loading,
    ok: complete || (error && !confirm && !loading),
    no: confirm || loading,
    onSubmit: handleSubmit,
    onCancel: onClose,
    loading
  }
  return (
    <GenericModal {...modalProps}>
      <ConfirmDescription hidden={!confirm} />
      <LoadingBody hidden={!loading} participant={participant} />
      <ErrorDescription hidden={!error || confirm || loading || complete} />
      <CompletedDescription hidden={!complete} participant={participant} />
    </GenericModal>
  )
}

ResendParticipantModal.propTypes = {
  participant: PropTypes.shape({
    email: PropTypes.string.isRequired,
    lastMailEvent: PropTypes.shape({
      eventName: PropTypes.string
    })
  }).isRequired,
  loading: PropTypes.bool,
  onConfirm: PropTypes.bool,
  onSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

export default injectIntl(ResendParticipantModal)
