import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from '../Icon/Icon'

const ComponentRoot = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1.0};
  & > *:first-child { margin-right: 10px; }
  & input {
    position: absolute;
    visibility: hidden;
  }
`

const Radio = ({ checked, disabled, children, onChange, ...props }) => (
  <ComponentRoot disabled={disabled}>
    <label>
      <Icon clickable={!disabled} variant={checked ? 'dot-circle' : 'circle'} />
      <input {...props} type="radio" checked={checked} disabled={disabled} onChange={onChange} />
    </label>
    {children}
  </ComponentRoot>
)

Radio.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

Radio.defaultProps = {
  checked: false,
  disabled: false,
  onChange: () => { },
}

export default Radio
