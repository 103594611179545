import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { loadLocale } from '../../../utils/i18nUtils'
import * as theme from '../../../components/theme'
import { Helmet } from 'react-helmet'
import { gql } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'

import DimmerLoader from '../../../components/common/DimmerLoader/DimmerLoader'
import QvestFooter from '../../../components/common/QvestFooter/QvestFooter'
import QvestHeader from '../../../components/qvest/QvestHeader/QvestHeader'
import ResultHeader from '../../../components/result/Header/Header'
import Summary from '../../../components/result/Summary/Summary'
import GroupDynamicsCard from '../../../components/result/GroupDynamicsCard/GroupDynamicsCard'
import HighlightsSection from './sections/HighlightsSection'
import QuestionProfileCard from '../../../components/result/QuestionProfileCard/QuestionProfileCard'
import BottomNavBar from '../../../components/qvest/BottomNavBar/BottomNavBar'
import Modal from '../../../components/common/Modal/Modal'
import AccessDeniedModal from '../../../components/common/AccessDeniedModal/AccessDeniedModal'

import { initParticipantTracking } from '../../../utils/trackingUtils'
import { getQvestSchedule } from '../../../actions/scheduleActions'
import { getParticipantsLight } from '../../../actions/participantActions'
import { getQuestionDistribution, getGroupGraph, getWordFrequencies } from '../../../actions/statsActions'
import { getConfiguration } from '../../../actions/configurationActions'
import { filterWordFrequenciesByQvestId, filterGroupGraphByQvestId, filterQuestionDistributionByQvestId } from '../../../reducers/stats'
import { filterSchedulesByQvestsId } from '../../../reducers/schedules'
import { filterFeaturesConfig, filterGroupGraphConfig } from '../../../reducers/configuration'
import * as authUtils from '../../../actions/utils/authUtils'
import FeaturesProvider from '../../../components/common/FeaturesProvider/FeaturesProvider'

const ComponentRoot = styled.div`
  @media (min-width: 700px) {
    & > *:nth-child(2) {
      margin-top: ${({ topOffset }) => topOffset ? '80px' : '0'};
    }
  }
`

const HeaderWrapper = styled.div`
  background-color: ${props => props.theme.two[0]};
  padding: 0 20px;
`

const Footer = styled.div`
  width: 100%;
  padding-bottom: 75px;
  background-color: ${props => props.theme.two[0]};
`

const BodyWrapper = styled.div`
  /* bg color hack until we split from owner */
  background-color: #f6f6f3;
  & > * { margin: 0 auto; }
  @media (min-width: 800px) {
    padding: 40px 0;
  }
`

const Body = styled.main`
  max-width: 1200px;
  background-color: ${({ theme }) => theme.two[0]};
  border: 1px solid #eee;
  border-radius: 3px;
  & > *:first-child {
    width: 100%;
    border-bottom: 1px solid #eee;
  }
  @media (min-width: 800px) {
    display: flex;
    & > *:first-child {
      width: 220px;
      border-right: 1px solid #eee;
      border-bottom-style: none;
    }
  }
`

const SummaryWrapper = styled.aside`
  padding: 40px 20px 0 20px;
  @media (min-width: 800px) {
    padding: 40px 60px 0 60px;
  }
`

const Content = styled.article`
  width: 100%;
  & > * + * { border-top: 1px solid #eee; }
`

const ContentSection = styled.section`
  width: 100%;
  padding: 40px 20px;
  @media (min-width: 800px) {
    padding: 40px 60px;
  }
`

const RESULTS_PAGE_QUERY = gql`
  query q($qvestId:String!) {
    qvest(id: $qvestId) {
      qvestId
      topic
      language
      state
      contactInfo {
        name
        email
      }
      organization {
        name
        logo
      }
      summary {
        answers
        participants
        questions
        groups
        pins
      }
    }
  }
`


class ResultsPage extends Component {
  state = {
    qvest: null,
    errors: null
  }

  componentDidMount() {
    const { client, match, history, dispatch, isOwner } = this.props
    const qvestId = match.params.qvestId

    let startPromise = Promise.resolve(true)
    if (!isOwner) startPromise = authUtils.initialize(true, match.params.qvestId)
    startPromise.then(ready => {
      if (!ready) return
      dispatch(getQvestSchedule(qvestId))
      dispatch(getWordFrequencies(qvestId))
      dispatch(getGroupGraph(qvestId))
      dispatch(getQuestionDistribution(qvestId))
      dispatch(getConfiguration(qvestId))
      dispatch(getParticipantsLight(qvestId))
        .then(participants => initParticipantTracking(history, participants, qvestId))

      this.apolloObservable = client.watchQuery({ query: RESULTS_PAGE_QUERY, variables: { qvestId } }).subscribe({
        next: (result) => this.setState({ qvest: result.data.qvest }),
        error: (errors) => this.setState({ error: errors })
      })
    })
  }

  componentWillUnmount() {
    if (this.apolloObservable) {
      this.apolloObservable.unsubscribe()
    }
  }

  render() {
    const {
      isOwner,
      match,
      scheduleStore,
      groupGraphStore,
      wordFrequenciesStore,
      questionDistributionStore,
      configurationStore
    } = this.props

    const qvestId = match.params.qvestId

    // Prepare qvest schedule
    const schedule = filterSchedulesByQvestsId(scheduleStore, qvestId)

    // Prepare word frequency data for word cloud
    const wordFrequencies = filterWordFrequenciesByQvestId(wordFrequenciesStore, qvestId)

    // Prepare graph data for group graph
    const groupGraph = filterGroupGraphByQvestId(groupGraphStore, qvestId)

    // Prepare question distribution data for radial chart
    const questionDistribution = filterQuestionDistributionByQvestId(questionDistributionStore, qvestId)

    // Prepare group graph configuration
    const groupGraphConfig = filterGroupGraphConfig(configurationStore, qvestId)
    const featuresConfig = filterFeaturesConfig(configurationStore, qvestId)

    // Prepare qvest
    const qvest = this.state.qvest

    const fatalError = (
      scheduleStore.error ||
      this.state.errors
    )
    if (fatalError) {
      if (fatalError.message === 'QVEST_EXPIRED') {
        const locale = fatalError.locale || 'en'
        const messages = loadLocale(locale)
        return (
          <IntlProvider locale={locale} messages={messages}>
            <Modal>
              <AccessDeniedModal variant="QVEST_EXPIRED" />
            </Modal>
          </IntlProvider>
        )
      }
      throw new Error(fatalError.message)
    }

    // Show full-screen loader till a qvest has been loaded
    if (!qvest || !featuresConfig) {
      return (<DimmerLoader />)
    }

    // Load language based on qvest configuration
    const locale = qvest.language
    const messages = loadLocale(locale)

    return (
      <IntlProvider locale={locale} messages={messages}>
        <ThemeProvider theme={theme.yellow}>
          <FeaturesProvider value={featuresConfig}>
            <ComponentRoot topOffset={!isOwner}>
              <Helmet>
                <title>{qvest && qvest.topic}</title>
              </Helmet>
              <HeaderWrapper>
                <QvestHeader qvest={qvest} schedule={schedule} />
              </HeaderWrapper>
              <ResultHeader
                schedule={schedule}
                contactInfo={qvest.contactInfo}
              />
              <BodyWrapper>
                <Body>
                  <SummaryWrapper>
                    <Summary summaryStats={qvest.summary} />
                  </SummaryWrapper>
                  <Content>
                    <ContentSection>
                      <GroupDynamicsCard
                        groupGraphData={groupGraph}
                        groupGraphConfig={groupGraphConfig}
                        error={groupGraphStore.error}
                        isLoading={groupGraphStore.isLoading}
                      />
                    </ContentSection>
                    <ContentSection>
                      <QuestionProfileCard
                        questionDistribution={questionDistribution}
                        error={questionDistributionStore.error}
                        isLoading={questionDistributionStore.isLoading}
                      />
                    </ContentSection>
                    <ContentSection>
                      <HighlightsSection
                        qvestId={qvestId}
                        wordFrequencies={wordFrequencies}
                        schedule={schedule}
                        summary={qvest.summary}
                        error={wordFrequenciesStore.error}
                        loading={wordFrequenciesStore.isLoading}
                        isOwner={isOwner}
                      />
                    </ContentSection>
                  </Content>
                </Body>
              </BodyWrapper>
              <Footer>
                <QvestFooter />
              </Footer>
              {!isOwner ? <BottomNavBar qvest={qvest} /> : null}
            </ComponentRoot>
          </FeaturesProvider>
        </ThemeProvider>
      </IntlProvider>
    )
  }
}

function mapStateToProps(state) {
  return {
    scheduleStore: state.schedules.toJS(),
    wordFrequenciesStore: state.stats.get('wordFrequencies').toJS(),
    groupGraphStore: state.stats.get('groupGraph').toJS(),
    questionDistributionStore: state.stats.get('questionDistribution').toJS(),
    configurationStore: state.configuration.toJS()
  }
}

export default connect(mapStateToProps)(withApollo(withRouter(ResultsPage)))
