import * as api from './apiClient'
import { raiseUnexpectedErrorNotification } from './notificationActions'

function parseSchedule(schedule) {
  if (!schedule) {
    return null
  }
  return {
    ...schedule,
    inviteAt: schedule.inviteAt ? new Date(schedule.inviteAt) : null,
    openAt: schedule.openAt ? new Date(schedule.openAt) : null,
    closeAt: schedule.closeAt ? new Date(schedule.closeAt) : null,
    expireAt: schedule.expireAt ? new Date(schedule.expireAt) : null
  }
}

export function getQvestSchedule(qvestId) {
  return dispatch => {
    dispatch({ type: 'GET_QVEST_SCHEDULE_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q {
            schedule(qvestId: "${qvestId}") {
              inviteAt,
              inviteStatus,
              openAt,
              openStatus,
              closeAt,
              closeStatus,
              expireAt,
              expireStatus,
              validity {
                general,
                items {
                  type,
                  name
                }
              }
            }
          }
        `
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const schedule = parseSchedule(data.schedule)
        dispatch({ type: 'GET_QVEST_SCHEDULE_SUCCESS', qvestId, schedule })
        return schedule
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'GET_QVEST_SCHEDULE_FAILURE', error })
      })
  }
}

export function updateSchedule(qvestId, patch) {
  return dispatch => {
    dispatch({ type: 'UPDATE_SCHEDULE_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($qvestId:String!, $patch:SchedulePatch!) {
            updateSchedule(qvestId: $qvestId, patch: $patch) {
              inviteAt,
              inviteStatus,
              openAt,
              openStatus,
              closeAt
              closeStatus,
              expireAt
              expireStatus,
              validity {
                general,
                items {
                  type,
                  name
                }
              }
            }
          }
        `,
        variables: { qvestId: qvestId, patch: patch }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const schedule = parseSchedule(data.updateSchedule)
        dispatch({ type: 'UPDATE_SCHEDULE_SUCCESS', qvestId, schedule })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'UPDATE_SCHEDULE_FAILURE', error })
      })
  }
}

export function toggleScheduleEnabled(qvestId, enabled = true) {
  return dispatch => {
    dispatch({ type: 'ENABLE_SCHEDULE_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m {
            toggleScheduleEnabled(qvestId: "${qvestId}", enabled: ${enabled}) {
              inviteAt,
              inviteStatus,
              openAt,
              openStatus,
              closeAt
              closeStatus,
              expireAt
              expireStatus,
              validity {
                general,
                items {
                  type,
                  name
                }
              }
            }
          }
        `
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const schedule = parseSchedule(data.toggleScheduleEnabled)
        dispatch({ type: 'ENABLE_SCHEDULE_SUCCESS', qvestId, schedule })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'ENABLE_SCHEDULE_FAILURE', error })
      })
  }
}

export function sendTestNotification(qvestId, state, name, email) {
  return dispatch => {
    dispatch({ type: 'TEST_NOTIFICATION_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($qvestId:String!, $state:QvestState! $email:String!, $name:String) {
            sendTestNotification(qvestId:$qvestId, state:$state, email:$email, name:$name)
          }
        `,
        variables: { qvestId, state, email, name }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(() => {
        dispatch({ type: 'TEST_NOTIFICATION_SUCCESS', qvestId })
        return true
      })
      .catch(() => {
        const error = { message: 'UNEXPECTED_ERROR' }
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'TEST_NOTIFICATION_FAILURE', error })
        return false
      })
  }
}

export function initUnsavedSchedule(schedule) {
  return { type: 'INIT_UNSAVED_SCHEDULE', schedule }
}

export function changeUnsavedSchedule(qvestId, patch) {
  return { type: 'CHANGE_UNSAVED_SCHEDULE', patch }
}

export function saveSchedule(qvestId) {
  return (dispatch, getState) => {
    const unsaved = getState().schedules.get('unsaved').toJS()
    return dispatch(updateSchedule(qvestId, {
      inviteAt: unsaved.inviteAt ? unsaved.inviteAt : undefined,
      openAt: unsaved.openAt ? unsaved.openAt : undefined,
      closeAt: unsaved.closeAt ? unsaved.closeAt : undefined
    }))
  }
}
