import React, { Component } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { Helmet } from 'react-helmet'

import Icon from '../../../../components/common/Icon/Icon'
import Card from '../../../../components/common/Card/Card'
import Button from '../../../../components/common/Button/Button'
import CenteredLoader from '../../../../components/common/CenteredLoader/CenteredLoader'
import Tooltip from '../../../../components/common/Tooltip/Tooltip'
import Heading from '../../../../components/common/Typography/Heading'
import Typography from '../../../../components/common/Typography/Typography'
import NextBar from '../../../../components/owner/NextBar/NextBar'
import LastUpdateStatus from '../../../../components/owner/LastUpdateStatus/LastUpdateStatus'
import GroupingForm from '../../../../components/owner/GroupingForm/GroupingForm'
import GroupsEditor from '../../../../components/owner/GroupsEditor/GroupsEditor'
import GroupingParticipantTable from '../../../../components/owner/GroupingParticipantTable/GroupingParticipantTable'
import { getParticipants } from '../../../../actions/participantActions'
import { hasStartedQvestState, isDraftQvestState, filterQvestsById } from '../../../../reducers/qvests'
import { filterParticipantsByQvest } from '../../../../reducers/participants'
import { filterGroupingValidityByQvest, filterUnsavedGrouping } from '../../../../reducers/grouping'
import { updateParticipant } from '../../../../actions/participantActions'
import {
  renameGrouping,
  changeEditingGroup,
  addGroup,
  changeGroup,
  removeGroup,
  getGroupingByQvestId,
  updateGrouping,
  createGrouping,
} from '../../../../actions/groupingActions'
import { countParticipantsInGroups } from '../../../../utils/groupingUtils'

const messages = defineMessages({
  title: { id: 'owner.OwnerQvestGroupsTab.title', defaultMessage: 'Groups | {qvestName}' },
})

const MainLayout = styled.div`
  display: inline-block;
  padding: 20px;
  min-width: 100%;
  & > *:not(:first-child) { margin-top: 20px; }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  visibility: ${({ hidden }) => hidden ? 'hidden' : 'visible'};

  & > :not(:last-child) {
    margin-right: 10px;
  }
`

const GroupingFormMargin = styled.div`
  margin: 20px 0 40px 0;
`

class OwnerQvestGroupsTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      lastUpdateParticipants: null, // TODO: Implement this in store when unsaved state introduced for participants
    }

    this.debouncedOnSave = debounce(this.onSave, 2000, { leading: true })
    this.trailingDebouncedOnSave = debounce(this.onSave, 500)
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getParticipants(match.params.qvestId))
    dispatch(getGroupingByQvestId(match.params.qvestId))
  }

  componentWillUnmount() {
    this.debouncedOnSave.flush()
  }

  renderGroupsHeaderText() {
    const caption = (
      <Tooltip.Area>
        <Tooltip.Reference>
          <Heading variant="heading3" primary>
            <FormattedMessage
              id="owner.QvestGroupsTab.groups.heading2"
              defaultMessage="Decide how to group"
            />
          </Heading>
        </Tooltip.Reference>
        <Tooltip>
          <Heading.h3>
            <FormattedMessage
              id="owner.QvestGroupsTab.groups.helpTooltip.heading"
              defaultMessage="Groups"
            />
          </Heading.h3>
          <Typography.p variant="small" weight="light">
            <FormattedMessage
              id="owner.QvestGroupsTab.groups.helpTooltip.content1"
              defaultMessage="Name your groups in a way that makes sense to your participants. "
            />
          </Typography.p>
          <Typography.p variant="small" weight="light">
            <FormattedMessage
              id="owner.QvestGroupsTab.groups.helpTooltip.content2"
              defaultMessage="You name your groups by tapping the titles. The more participants you have in your Qvest, the more groups you need. You will get the most out of Qvest if you have 2-10 groups per Qvest."
            />
          </Typography.p>
        </Tooltip>
      </Tooltip.Area>
    )
    return (
      <span>
        {caption}
        <Heading variant="heading3">
          {' '}
          <FormattedMessage
            id="owner.QvestGroupsTab.groups.heading1"
            defaultMessage="your participants"
          />
        </Heading>
      </span>
    )
  }

  renderConnectionsHeaderText() {
    const caption = (
      <Tooltip.Area>
        <Tooltip.Reference>
          <Heading variant="heading3" primary>
            <FormattedMessage
              id="owner.QvestGroupsTab.connections.heading2"
              defaultMessage="Sort your participants"
            />
          </Heading>
        </Tooltip.Reference>
        <Tooltip>
          <Heading.h3>
            <FormattedMessage
              id="owner.QvestGroupsTab.connections.helpTooltip.heading"
              defaultMessage="Sort your participants"
            />
          </Heading.h3>
          <Typography.p variant="small" weight="light">
            <FormattedMessage
              id="owner.QvestGroupsTab.connections.helpTooltip.content1"
              defaultMessage="You can sort your participants into groups by tapping the circles under the group titles."
            />
          </Typography.p>
          <Typography.p variant="small" weight="light">
            <FormattedMessage
              id="owner.QvestGroupsTab.connections.helpTooltip.content2"
              defaultMessage="You will get the most out of Qvest if you have 3+ participants per group."
            />
          </Typography.p>
        </Tooltip>
      </Tooltip.Area>
    )
    return (
      <span>
        {caption}
        <Heading variant="heading3">
          {' '}
          <FormattedMessage
            id="owner.QvestGroupsTab.connections.heading1"
            defaultMessage="into groups"
          />
        </Heading>
      </span>
    )
  }

  renderError() {
    const { error } = this.state
    if (!error) return null
    return (
      <Card.Message error>
        <Typography cta tertiary variant="medium">{error}</Typography>
      </Card.Message>
    )
  }

  renderNextBar(validity, isDraft) {
    if (!isDraft || !validity || validity.general === 'ERROR') return null
    return (
      <NextBar>
        <Button secondary onClick={this.handleNextClick}>
          <FormattedMessage id="owner.QvestGroupsTab.nextButton" defaultMessage="Next: Topic" />
          <Icon cta tertiary variant="angle-right" />
        </Button>
      </NextBar>
    )
  }

  render() {
    const { qvestStore, participantStore, groupingStore, match, dispatch, intl } = this.props
    const { lastUpdateParticipants } = this.state
    const qvestId = match.params.qvestId

    const qvest = filterQvestsById(qvestStore, qvestId)
    const hasStarted = hasStartedQvestState(qvestStore, qvestId)
    const isDraft = isDraftQvestState(qvestStore, qvestId)
    const participants = filterParticipantsByQvest(participantStore, qvestId)
    const validity = filterGroupingValidityByQvest(groupingStore, qvestId)
    const grouping = filterUnsavedGrouping(groupingStore)

    if (!participants || !grouping) return <CenteredLoader />

    const groupParticipantCounts = countParticipantsInGroups(grouping.groups, participants) // TODO: Implement this in the groupingService (backend) as integer field on groups equal to number of connections
    const unsavedGrouping = filterUnsavedGrouping(groupingStore)

    return (
      <MainLayout>
        <Helmet>
          <title>{intl.formatMessage(messages.title, { qvestName: qvest && qvest.name })}</title>
        </Helmet>
        <Card>
          <Card.Header>
            {this.renderGroupsHeaderText()}
            <ButtonGroup>
              <LastUpdateStatus lastUpdate={groupingStore.lastUpdate} />
            </ButtonGroup>
          </Card.Header>
          {this.renderError()}
          <Card.Body>
            <GroupingFormMargin>
              <GroupingForm
                grouping={grouping}
                disabled={hasStarted}
                onChangeGrouping={grouping => this.handleChangeGroupingName(grouping.name)}
              />
            </GroupingFormMargin>
            <GroupsEditor
              groups={grouping.groups}
              counts={groupParticipantCounts}
              exampleName={grouping.name}
              editingId={groupingStore.editingGroupId}
              disabled={!grouping.name || hasStarted}
              onStartEdit={groupId => dispatch(changeEditingGroup(groupId))}
              onAdd={this.handleAddGroup}
              onChange={this.handleChangeGroup}
              onRemove={this.handleRemoveGroup}
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            {this.renderConnectionsHeaderText()}
            <ButtonGroup hidden={!lastUpdateParticipants}>
              <LastUpdateStatus lastUpdate={lastUpdateParticipants} />
            </ButtonGroup>
          </Card.Header>
          <GroupingParticipantTable
            qvest={qvest}
            participants={participants}
            groups={grouping.groups}
            counts={groupParticipantCounts}
            unsavedGrouping={unsavedGrouping}
            exampleGroupName={grouping.name}
            onConnectionUpdate={this.handleConnectionUpdate}
          />
        </Card>
        {this.renderNextBar(validity, isDraft)}
      </MainLayout>
    )
  }

  handleNextClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/topic`)
  }

  handleChangeGroupingName = (name) => {
    this.props.dispatch(renameGrouping(name))
    this.trailingDebouncedOnSave()
  }

  handleAddGroup = () => {
    this.props.dispatch(addGroup())
    this.debouncedOnSave()
  }

  handleChangeGroup = (group) => {
    this.props.dispatch(changeGroup(group))
    this.debouncedOnSave()
  }

  handleRemoveGroup = (groupId) => {
    this.props.dispatch(removeGroup(groupId))
    this.debouncedOnSave()
  }

  handleConnectionUpdate = (participantId, groupId) => {
    const { groupingStore, dispatch, match } = this.props
    const grouping = filterUnsavedGrouping(groupingStore)
    const groupingId = grouping.groupingId
    const patch = { groupingConnections: [{ groupId, groupingId }] }
    return dispatch(updateParticipant(match.params.qvestId, participantId, patch))
      .then(() => this.setState({ lastUpdateParticipants: new Date() }))
      .then(() => this.debouncedOnSave())
  }

  onSave = () => {
    const { match, dispatch, groupingStore } = this.props
    const unsaved = filterUnsavedGrouping(groupingStore)

    // create grouping if it doesn't exist
    if (!unsaved.groupingId) return dispatch(createGrouping(match.params.qvestId))
    // update existing grouping
    dispatch(updateGrouping(match.params.qvestId))
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    participantStore: state.participants.toJS(),
    groupingStore: state.grouping.toJS(),
  }
}

export default connect(mapStateToProps)(injectIntl(OwnerQvestGroupsTab))
