import Immutable, { Map } from 'immutable'

let INITIAL_STATE = Immutable.fromJS({
  isLoading: true,
  lastUpdate: null,
  error: null,
  data: {},
  unsaved: null
})


const unsaved = (state = new Map(), action) => {
  switch (action.type) {
    case 'INIT_UNSAVED_SCHEDULE':
      return Immutable.fromJS(action.schedule)
    case 'CHANGE_UNSAVED_SCHEDULE':
      return state.merge(action.patch)
    default:
      return state
  }
}

const schedules = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_QVEST_SCHEDULE_REQUEST':
    case 'SCHEDULE_QVEST_REQUEST':
    case 'UPDATE_SCHEDULE_REQUEST':
    case 'TEST_NOTIFICATION_REQUEST':
      return state.merge({
        isLoading: true
      })
    case 'GET_QVEST_SCHEDULE_SUCCESS':
    case 'SCHEDULE_QVEST_SUCCESS':
      return state.merge({
        isLoading: false,
        error: null,
        data: state.get('data').set(action.qvestId, action.schedule)
      })
    case 'UPDATE_SCHEDULE_SUCCESS':
      return state.merge({
        isLoading: false,
        error: null,
        lastUpdate: new Date(),
        data: state.get('data').set(action.qvestId, action.schedule)
      })
    case 'TEST_NOTIFICATION_SUCCESS':
      return state.merge({
        isLoading: false,
        error: null
      })
    case 'GET_QVEST_SCHEDULE_FAILURE':
    case 'SCHEDULE_QVEST_FAILURE':
    case 'UPDATE_SCHEDULE_FAILURE':
    case 'TEST_NOTIFICATION_FAILURE':
      return state.merge({
        isLoading: false,
        error: action.error
      })
    case 'INIT_UNSAVED_SCHEDULE':
    case 'CHANGE_UNSAVED_SCHEDULE':
      return state.update('unsaved', us => unsaved(us, action))
    default:
      return state
  }
}


export function filterSchedulesByQvestsId(schedules, qvestId) {
  if (!schedules.data.hasOwnProperty(qvestId)) {
    return null
  }
  return schedules.data[qvestId]
}

export function filterScheduleValidityByQvest(store, qvestId) {
  if (!store.data.hasOwnProperty(qvestId)) {
    return null
  }
  const schedule = { ... store.data[qvestId] }
  const validity = schedule.validity
  delete schedule.validity
  // Add reference to schedule to each validation item, if any found
  if (validity.items && validity.items.length > 0) {
    validity.items = validity.items.map(item => ({ ...item, resource: { schedule } }))
  }
  return validity
}

export default schedules
