import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

import { isWhitespace } from '../../../utils/stringUtils'
import { isVimeoUrl } from '../../../utils/videoUtils'

import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Button from '../../common/Button/Button'
import Icon from '../../common/Icon/Icon'
import Input from '../../common/Input/Input'
import Anchor from '../../common/Anchor/Anchor'
import TextArea from '../../common/TextArea/TextArea'
import EditableSpan from '../../common/EditableSpan2/EditableSpan'


const messages = defineMessages({
  videoPlaceholder: {
    id: 'owner.EditablePurposeBox.videoPlaceholder',
    defaultMessage: 'Paste a link to a YouTube video',
  },
  remove: {
    id: 'owner.EditablePurposeBox.removeVideo',
    defaultMessage: 'Delete video',
  }
})

const ComponentRoot = styled.div`
  /* override input and textarea styles */
  & ${Input}, & ${TextArea} {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
  }
  & ${TextArea} {
    resize: none;
  }
  & > :nth-child(n + 3) > :last-child {
    max-width: 350px;
  }
`

const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;

  & + & { margin-top: 10px; }
  & > :first-child {
    padding-top: 10px;
    width: 130px;
    flex-shrink: 0;
  }
`

const VideoRowRoot = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  & > ${Input} {
    max-width: 350px;
    margin-right: 20px;
  }
  & > ${Button} {
    height: 26px;
    margin-left: 10px;
  }
  & > ${Button} > * {
    display: block;
    width: 14px;
  }
`

const VideoRow = injectIntl(({ video, intl, onShowVideoManager }) => {
  const isUploaded = video && isVimeoUrl(video.url)
  if (isUploaded) {
    return (
      <VideoRowRoot>
        <Typography>
          <FormattedMessage id="owner.EditablePurposeBox.uploadedVideo" defaultMessage="Uploaded video" />
        </Typography>
        <Button
          size="tiny"
          secondary
          title={intl.formatMessage(messages.remove)}
          onClick={() => onShowVideoManager('remove')}
        >
          <Icon clickable tertiary variant="trash" size="small" />
        </Button>
      </VideoRowRoot>
    )
  } else {
    return (
      <VideoRowRoot>
        <Typography cta>
          <Anchor inverted onClick={() => onShowVideoManager('upload')}>
            <FormattedMessage
              id="owner.EditablePurposeBox.upload"
              defaultMessage="Upload a video"
            />
          </Anchor>
        </Typography>
      </VideoRowRoot>
    )
  }
})

const EditablePurposeBox = props => {
  const {
    contactInfo,
    disableContactEmail,
    aboutTextPlaceholder,
    onShowVideoManager,
    onSubmit,
    onCancel,
  } = props

  const [name, setName] = useState(contactInfo.name || '')
  const [email, setEmail] = useState(contactInfo.email || '')
  const [senderName, setSenderName] = useState(contactInfo.senderName || '')
  const [senderTitle, setSenderTitle] = useState(contactInfo.senderTitle || '')
  const [aboutTextSource, setAboutText] = useState(props.aboutTextSource || '')

  const handleSubmit = () => {
    const patch = {}
    if (contactInfo.name !== name) patch.contactName = name
    if (contactInfo.email !== email) patch.contactEmail = email
    if (contactInfo.senderName !== senderName) patch.senderName = senderName
    if (contactInfo.senderTitle !== senderTitle) patch.senderTitle = senderTitle
    // TODO: nicer validation for invalid user input
    if ((props.aboutTextSource !== aboutTextSource) && !isWhitespace(aboutTextSource)) {
      patch.aboutTextSource = aboutTextSource
    }
    onSubmit(patch)
  }

  const editProps = {
    fullWidth: true,
    hasDoneKey: false,
    clickOutsideToCancel: false,
    onDone: handleSubmit,
    onCancel
  }

  return (
    <Fragment>
      <EditableSpan.Edit {...editProps}>
        <ComponentRoot>
          <InputRow>
            <Heading variant="heading4">
              <FormattedMessage defaultMessage="Video:" id="EditablePurposeBox.video" />
            </Heading>
            <VideoRow
              onShowVideoManager={onShowVideoManager}
              video={props.video}
            />
          </InputRow>
          <InputRow>
            <Heading variant="heading4">
              <FormattedMessage defaultMessage="Motivational text:" id="EditablePurposeBox.motivation" />
            </Heading>
            <TextArea
              rows="10"
              value={aboutTextSource}
              placeholder={aboutTextPlaceholder}
              onChange={({ target }) => setAboutText(target.value)}
            />
          </InputRow>
          <InputRow>
            <Heading variant="heading4">
              <FormattedMessage defaultMessage="Sender name:" id="EditablePurposeBox.senderName" />
            </Heading>
            <Input
              type="text"
              value={senderName}
              onChange={({ target }) => setSenderName(target.value)}
            />
          </InputRow>
          <InputRow>
            <Heading variant="heading4">
              <FormattedMessage defaultMessage="Sender title:" id="EditablePurposeBox.senderTitle" />
            </Heading>
            <Input
              type="text"
              value={senderTitle}
              onChange={({ target }) => setSenderTitle(target.value)}
            />
          </InputRow>
          <InputRow>
            <Heading variant="heading4">
              <FormattedMessage defaultMessage="Contact name:" id="EditablePurposeBox.contactName" />
            </Heading>
            <Input
              type="text"
              value={name}
              onChange={({ target }) => setName(target.value)}
            />
          </InputRow>
          <InputRow>
            <Heading variant="heading4">
              <FormattedMessage defaultMessage="Contact e-mail:" id="EditablePurposeBox.contactEmail" />
            </Heading>
            <Input
              type="text"
              value={email}
              placeholder="support@qvest.io"
              disabled={disableContactEmail}
              onChange={({ target }) => setEmail(target.value)}
            />
          </InputRow>
        </ComponentRoot>
      </EditableSpan.Edit>
    </Fragment>
  )
}


EditablePurposeBox.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    senderName: PropTypes.string,
    senderTitle: PropTypes.string,
  }),
  disableContactEmail: PropTypes.bool,
  video: PropTypes.shape({
    url: PropTypes.string,
  }),
  aboutTextSource: PropTypes.string,
  aboutTextPlaceholder: PropTypes.string,
}

EditablePurposeBox.defaultProps = {
  contactInfo: {},
  onSubmit: () => { },
  onCancel: () => { },
}

export default EditablePurposeBox
