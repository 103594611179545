import React, { useEffect, useState } from 'react'

import { log } from '../../../../../utils/trackingUtils'
import { excelSheetToParticipants } from '../../../../../utils/xlsxUtils'
import { hasMailFailed, hasMailDelivered } from '../../../../../utils/participantUtils'
import Modal from '../../../../../components/common/Modal/Modal'
import CreateParticipantModal from '../../../../../components/owner/CreateParticipantModal/CreateParticipantModal'
import DeleteParticipantModal from '../../../../../components/owner/DeleteParticipantModal/DeleteParticipantModal'
import ConfirmParticipantModal from '../../../../../components/owner/ConfirmParticipantModal/ConfirmParticipantModal'
import ImportParticipantsModal from '../../../../../components/owner/ImportParticipantsModal/ImportParticipantsModal'
import {
  createParticipantsBulk,
  deleteAllParticipants,
  deleteParticipant, getParticipant, resendParticipantNotification,
  toggleParticipantSuspension
} from '../../../../../actions/participantActions'
import {
  addGroup,
  changeEditingGroup,
  getGroupingByQvestId,
  updateGrouping
} from '../../../../../actions/groupingActions'
import { hasStartedQvestState, isDoneQvestState, isDraftQvestState } from '../../../../../utils/qvestUtils'
import ResendParticipantModal from '../../../../../components/owner/ParticipantModal/ResendParticipantModal'
import ReplyParticipantModal from '../../../../../components/owner/ParticipantModal/ReplyParticipantModal'


const CreateModal = ({ qvest, onCancel, grouping, options, onSubmit, loading }) => {
  return (
    <Modal onBackgroundClick={onCancel} onEscape={onCancel}>
      <CreateParticipantModal
        onClose={onCancel}
        onSubmit={onSubmit}
        onCancel={onCancel}
        participant={options}
        groups={grouping ? grouping.groups : null}
        qvest={qvest}
        loading={loading}
      />
    </Modal>
  )
}

const RemoveModal = props => {
  const {
    isDraft,
    isPlural = false,
    options,
    onCancel,
    onSuspend,
    onRemove,
    onRemoveAll,
  } = props
  let handleSubmit
  if (!isDraft) {
    handleSubmit = onSuspend
  } else if (isPlural) {
    handleSubmit = onRemoveAll
  } else {
    handleSubmit = onRemove
  }
  return (
    <Modal onBackgroundClick={onCancel} onEscape={onCancel}>
      <DeleteParticipantModal
        isPlural={isPlural}
        // Only offer real removal if qvest has not been started yet (is a draft)
        isSuspension={!isDraft}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        participant={options}
      />
    </Modal>
  )
}

const ConfirmModal = ({ options, onStartPolling, onCancel }) => {
  const [participant, setParticipant] = useState(null)
  const [loading, setLoading] = useState(true)

  // Start polling for mail status as soon as component is mounted (componentDidMount equivalent)
  useEffect(() => {
    onStartPolling(options.participantId)
      .then(participant => {
        // Success, close modal
        if (hasMailDelivered(participant)) {
          return onCancel()
        }
        // Failed, keep modal open and stop loading to show error
        setParticipant(participant)
        setLoading(false)
      })
      .catch(err => {
        // Timed out, close modal and log warning
        log.warning(err)
        onCancel()
      })
  }, [])

  return (
    <Modal onBackgroundClick={onCancel} onEscape={onCancel}>
      <ConfirmParticipantModal
        onCancel={onCancel}
        participant={participant || options}
        loading={loading}
      />
    </Modal>
  )
}

const ImportModal = ({ onSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState(null)
  const [participants, setParticipants] = useState(null)
  const [groups, setGroups] = useState(null)
  const [error, setError] = useState(null)

  const handleFileChange = (file) => {
    setFileName(file.name)
    setLoading(true)
    return excelSheetToParticipants(file)
      .then(result => {
        const { groups, participants } = result
        setParticipants(participants)
        setGroups(groups)
        setLoading(false)
      })
      .catch(error => {
        if (error) log.warning(error)
        setError(error)
        setLoading(false)
      })
  }

  const handleSubmit = (...args) => {
    setLoading(true)
    return onSubmit(...args)
  }

  return (
    <Modal onBackgroundClick={onCancel} onEscape={onCancel}>
      <ImportParticipantsModal
        onClose={onCancel}
        onFileChange={handleFileChange}
        onSubmit={handleSubmit}
        loading={loading}
        fileName={fileName}
        participants={participants}
        groups={groups}
        error={error}
      />
    </Modal>
  )
}

const ResendModal = ({ participant, onResend, onStartPolling, onCancel }) => {
  const [isLoading, setLoading] = useState(false)
  const [isConfirmed, setConfirmed] = useState(false)
  const [isCompleted, setCompleted] = useState(false)

  const handleSubmit = (participant) => {
    setConfirmed(true)
    setLoading(true)
    return onResend(participant)
      .then(() => onStartPolling(participant.participantId))
      .then(participant => {
        if (!hasMailFailed(participant)) {
          // Show completed (successfully) state
          setCompleted(true)
        }
        // ... Else resend failed, keep modal open and simply stop loading to show error...
        setLoading(false)
      })
      .catch(() => {
        // On poll timeout, close modal
        onCancel()
      })
  }

  return (
    <Modal onBackgroundClick={onCancel} onEscape={onCancel}>
      <ResendParticipantModal
        participant={participant}
        loading={isLoading}
        confirm={!isConfirmed}
        complete={isCompleted}
        onSubmit={handleSubmit}
        onClose={onCancel}
      />
    </Modal>
  )
}

const ReplyModal = ({ participant, isDone, onSubmit, onCancel }) => {
  return (
    <Modal onBackgroundClick={onCancel} onEscape={onCancel}>
      <ReplyParticipantModal
        disabled={isDone}
        participant={participant}
        onSubmit={onSubmit}
        onClose={onCancel}
      />
    </Modal>
  )
}

export const useModals = (dispatch, qvest, participants, grouping, onCreate, onCancel) => {
  const { qvestId } = qvest
  const hasStarted = hasStartedQvestState(qvest.state)
  const isDraft = isDraftQvestState(qvest.state)
  const isDone = isDoneQvestState(qvest.state)
  const [loadingModal, setLoadingModal] = useState(null)
  const [shownModal, setModal] = useState(null)
  const [options, setOptions] = useState(null)
  const [handlers, setHandlers] = useState(null)

  const handleCancel = () => {
    setLoadingModal(false)
    setModal(null)
    setOptions(null)
    onCancel()
  }

  const handleCreateConfirm = (name, email, groupId) => {
    setLoadingModal(true)
    return onCreate(name, email, groupId)
      .then(participant => {
        if (hasStarted) {
          setOptions(participant)
          setModal('confirm')
        } else {
          handleCancel()
        }
        handlers.resolve()
      })
      .catch(() => {
        handleCancel()
        handlers.reject()
      })
  }

  const handleImport = async (participants, groups) => {
    await dispatch(createParticipantsBulk(qvestId, participants))
    // Add empty groups, if any
    const emptyGroups = groups && groups.filter(group => group.count === 0)
    if (emptyGroups && emptyGroups.length > 0) {
      await dispatch(getGroupingByQvestId(qvestId))
      emptyGroups.forEach(group => {
        const action = addGroup()
        action.group.name = group.name
        dispatch(action)
      })
      dispatch(updateGrouping(qvestId))
      dispatch(changeEditingGroup(null))
    }
    handleCancel()
  }

  const handleRemove = (participant) => {
    handleCancel()
    return dispatch(deleteParticipant(participant.participantId, qvestId))
      .then(() => dispatch(getGroupingByQvestId(qvestId)))
  }

  const handleSuspendConfirm = (participant) => {
    handleCancel()
    return dispatch(toggleParticipantSuspension(participant, qvestId))
  }

  const handleRemoveAll = () => {
    return dispatch(deleteAllParticipants(qvestId))
      .finally(() => setModal(null))
  }

  const handleResend = async (participant) => {
    return dispatch(resendParticipantNotification(participant.participantId))
  }

  // Poll participant for mail delivery status (for ~1 min)
  const pollParticipant = (participantId, tries = 24) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        dispatch(getParticipant(qvestId, participantId))
          .then(participant => {
            if (hasMailFailed(participant) || hasMailDelivered(participant)) {
              resolve(participant)
            } else if (tries > 0) {
              resolve(pollParticipant(participantId, --tries))
            } else {
              reject(new Error('POLLING_TIMED_OUT'))
            }
          })
      }, 2500)
    })
  }

  let modal
  switch (shownModal) {
    case 'create':
      modal = <CreateModal
        qvest={qvest}
        grouping={grouping}
        options={options}
        onCancel={handleCancel}
        onSubmit={handleCreateConfirm}
        loading={loadingModal}
      />
      break
    case 'confirm':
      modal = <ConfirmModal
        options={options}
        onStartPolling={pollParticipant}
        onCancel={handleCancel}
      />
      break
    case 'remove':
      modal = <RemoveModal
        isDraft={isDraft}
        options={options}
        onCancel={handleCancel}
        onRemove={handleRemove}
        onSuspend={handleSuspendConfirm}
        loading={loadingModal}
      />
      break
    case 'removeAll':
      modal = <RemoveModal
        isDraft={isDraft}
        isPlural
        onCancel={handleCancel}
        onRemoveAll={handleRemoveAll}
        loading={loadingModal}
      />
      break
    case 'import':
      modal = <ImportModal
        onCancel={handleCancel}
        onSubmit={handleImport}
      />
      break
    case 'resend':
      modal = <ResendModal
        participant={options}
        onResend={handleResend}
        onStartPolling={pollParticipant}
        onCancel={handleCancel}
      />
      break
    case 'reply':
      modal = <ReplyModal
        participant={options}
        isDone={isDone}
        onSubmit={() => setModal('remove')}
        onCancel={handleCancel}
      />
      break
    default:
      modal = null
  }

  const showCreateModal = (name, email) => {
    const pr = new Promise((resolve, reject) => {
      setHandlers({ resolve, reject })
    })
    setOptions({ name, email })
    setModal('create')
    return pr
  }

  const showSuspendModal = participant => {
    setOptions(participant)
    setModal('remove')
  }

  const showRemoveModal = participant => {
    setOptions(participant)
    setModal('remove')
  }

  const showRemoveAllModal = () => {
    setModal('removeAll')
  }

  const showImportModal = () => {
    setModal('import')
  }

  const showResendModal = participant => {
    setOptions(participant)
    setModal('resend')
  }

  const showReplyModal = participant => {
    setOptions(participant)
    setModal('reply')
  }

  return [
    modal,
    showCreateModal,
    showSuspendModal,
    showRemoveModal,
    showRemoveAllModal,
    showImportModal,
    showResendModal,
    showReplyModal
  ]
}
