import 'whatwg-fetch'
import { log } from '../../utils/trackingUtils'

const IDENTITY_BASE_URL = process.env.IDENTITY_BASE_URL || 'https://dev-id.qvest.io'
const PROVIDER_COOKIE_KEY = 'auth_flow_provider_id'
const RETURN_TO_KEY = 'auth_flow_return_to'

async function invoke(path, options = {}) {
  const response = await fetch(`${IDENTITY_BASE_URL}${path}`, options)
  if (!response.ok) {
    const errorBody = await response.text()
    throw new Error(errorBody)
  }
  return response.json()
}

function hasProviderId() {
  return document.cookie.split(';').some(item => item.trim().indexOf(`${PROVIDER_COOKIE_KEY}=`) === 0)
}

function getProviderId() {
  if (hasProviderId()) {
    return document.cookie.split('; ').find(row => row.startsWith(`${PROVIDER_COOKIE_KEY}=`)).split('=')[1]
  }
  return null
}

function hasReturnToUrl() {
  return document.cookie.split(';').some(item => item.trim().indexOf(`${RETURN_TO_KEY}=`) === 0)
}

export function getReturnToUrl() {
  if (hasReturnToUrl()) {
    const value = document.cookie.split('; ').find(row => row.startsWith(`${RETURN_TO_KEY}=`)).split('=')[1]
    return decodeURIComponent(value)
  }
  return null
}

export function deleteReturnToUrl() {
  if (hasReturnToUrl()) {
    document.cookie = `${RETURN_TO_KEY}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`
  }
}

export async function getSession() {
  try {
    const options = {
      mode: 'cors',
      credentials: 'include'
    }
    return await invoke('/sessions/whoami', options)
  } catch (error) {
    log.warning(error)
    return null
  }
}

export async function getFlowConfig(flowId, variant) {
  // Exit early if flowId missing
  if (!flowId) {
    log.error('Attempt to obtain OIDC flow config without a flowId')
    throw new Error('MISSING_FLOW_ID')
  }
  // Retrieve config
  const responseBody = await invoke(`/self-service/${variant}/flows?id=${flowId}`)
  const config = responseBody.methods.oidc.config
  // Log errors, if any
  const error = config.messages && config.messages.find(m => m.type ==='error')
  if (error) {
    log.error('OIDC flow config contains error:' + error.text)
  }
  // Filter out irrelevant providers
  const providerId = getProviderId()
  const filteredConfig = {
    ...config,
    fields: config.fields.filter(f => f.name !== 'provider' || f.value === providerId)
  }
  return filteredConfig
}

export async function getFlowErrors(errorId) {
  if (!errorId) {
    log.error('Attempt to obtain error without an errorId')
    throw new Error('MISSING_ERROR_ID')
  }
  return invoke(`/self-service/error?error=${errorId}`)

}

export function initFlow(authProviderId, returnTo, refresh = false) {
  document.cookie = `${PROVIDER_COOKIE_KEY}=${authProviderId};path=/`
  document.cookie = `${RETURN_TO_KEY}=${encodeURIComponent(returnTo)};path=/`
  let url = `${IDENTITY_BASE_URL}/self-service/login/browser?return_to=${encodeURIComponent(returnTo)}`
  if (refresh) {
    url = url + '&refresh=true'
  }
  window.location = url
}

export function completeFlow() {
  const returnToUrl = getReturnToUrl()
  if (returnToUrl) {
    deleteReturnToUrl()
    if (window.location.href !== returnToUrl) {
      window.location = returnToUrl
      return true
    }
  }
  return false
}

export function logout(returnTo) {
  window.location = `${IDENTITY_BASE_URL}/self-service/browser/flows/logout?return_to=${returnTo}`
}
