import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

const ComponentRoot = styled.div`
  display: flex;
  align-items: flex-start;
  user-select: none;
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  & > *:first-child {
    margin-top: 2px;
    margin-right: 10px;
    min-width: 18px;
  }
  & > input {
    position: absolute;
    visibility: hidden;
  }
  && > *:first-child { color: white; }

  &:hover {
    & > *:first-child {
      text-shadow: 1px solid black;
      color: red;
    }
  }
`

const uncheckedSvg = (theme) => (
  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" stroke="#CCCECC" fill={theme.two[0]} x="0.5" y="0.5" width="17" height="17" rx="3" />
    </g>
  </svg>
)

const checkedSvg = (theme, disabled) => (
  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" fill={disabled ? '#9ea2a0' : theme.color[0]} x="0" y="0" width="18" height="18" rx="3" />
      <path d="M15.3303926,6.92991489 L8.41724363,13.8430638 C8.20683938,14.0534681 7.91522236,14.174234 7.61775427,14.174234 L7.61775427,14.6423191 L7.60769044,14.174234 C7.30624363,14.1716596 7.0134564,14.0457447 6.80422236,13.8287872 L3.31652023,10.213766 C2.88354151,9.76510638 2.89641385,9.04823404 3.34507342,8.61525532 C3.55688193,8.41070213 3.83562661,8.2983617 4.1298181,8.2983617 C4.4394564,8.2983617 4.72849895,8.421 4.94358406,8.64380851 L7.63226491,11.4305532 L13.7318819,5.33117021 C13.9453287,5.1177234 14.2292224,5 14.5313713,5 C14.8332862,5 15.1171798,5.11748936 15.3306266,5.33093617 C15.7710947,5.77187234 15.7710947,6.48921277 15.3303926,6.92991489 Z" id="Path" fill="#FFFFFF" transform="translate(9.330474, 9.821160) rotate(-6.000000) translate(-9.330474, -9.821160) " />
    </g>
  </svg>
)

// Note: This component needs to be wrapped in a <label> element to function

const Checkbox = ({ checked, disabled, children, theme, onChange, ...props }) => (
  <ComponentRoot disabled={disabled}>
    {checked ? checkedSvg(theme, disabled) : uncheckedSvg(theme)}
    <input {...props} type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
    {children}
  </ComponentRoot>
)

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  onChange: () => { },
}

export default withTheme(Checkbox)
