
const MAIL_SUCCESS_STATUS = ['delivered', 'open', 'click', 'spamreport', 'unsubscribe', 'group_unsubscribe', 'group_resubscribe']
const MAIL_FAILED_STATUS = ['bounce', 'dropped']

export function hasMailFailed(participant) {
  return participant.lastMailEvent && (MAIL_FAILED_STATUS.indexOf(participant.lastMailEvent.eventName) !== -1)
}

export function hasMailDelivered(participant) {
  return participant.lastMailEvent && (MAIL_SUCCESS_STATUS.indexOf(participant.lastMailEvent.eventName) !== -1)
}

export function hasReplied(participant) {
  return (participant.lastMailReply !== null)
}

export function getDuplicates(validity) {
  if (!validity) return null
  const validationItem = validity.items.find(i => i.name === 'DUPLICATE_PARTICIPANT_EMAILS')
  if (!validationItem) return null
  return validationItem.subjectIds
}

export function isDuplicate(participant, duplicates) {
  if (!duplicates) return false
  return (duplicates.indexOf(participant.participantId) !== -1)
}

