import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import * as d3 from 'd3'

import { hexaToRgba } from '../../../utils/styleUtils'
import Typography from '../Typography/Typography'


const ChartRow = styled.div`
  display:flex;
`

const TruncateFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px;
`

const Chart = withTheme(({ theme, itemWidth, itemHeight, groupId, value, label, color }) => {
  const radius = (itemHeight / 3.75)
  const isEmpty = (value === 0)
  const hasGroup = (groupId != null)

  // Value label (positioned relative to circle centroid at a tilted angle)
  const tilt = (Math.PI * 2 * 0.1) // 36 degrees (in radians)
  const angles = d3.pie().sort(null)([1, 1])
  const labelArc = d3.arc()
    .startAngle(d => d.startAngle + tilt)
    .endAngle(d => d.endAngle + tilt)
    .innerRadius(radius + 10)
    .outerRadius(radius + 10)
  const valuePosition = labelArc.centroid(angles[1])
  const valueElement = (
    <g key={1} transform={`translate(${valuePosition})`}>
      <text textAnchor="end" fontSize="25" fontFamily={theme.font2} dy="-15">{value}</text>
      <text textAnchor="end" fontSize="13" fontFamily={theme.font1}>times</text>
    </g>
  )

  // Style depending on empty state
  let fill = color
  if (!hasGroup) {
    fill = hexaToRgba(color + '10')
  } else if(isEmpty) {
    fill = 'transparent'
  }

  let strokeWidth = 0
  let strokeDashArray
  if (isEmpty) {
    strokeWidth = 1
  }
  if (!hasGroup) {
    strokeDashArray = [5, 5]
  }

  let opacity = 1.0
  if (isEmpty && hasGroup) {
    opacity = 0.5
  }

  // Render
  return (
    <svg width={itemWidth} height={itemHeight}>
      <g transform={`translate(${itemWidth / 2},${itemHeight/2.25})`}>
        <g>
          <circle
            fill={fill}
            stroke={color}
            strokeWidth={strokeWidth}
            strokeDasharray={strokeDashArray}
            r={radius}
            opacity={opacity}
          />
        </g>
        {!isEmpty ? valueElement : null}
        <g transform={`translate(0,${radius + 30})`}>
          <text textAnchor="middle" fontSize="13" fontFamily={theme.font1}>{label}</text>
        </g>
      </g>
    </svg>
  )
})

function determineDimensions(groupCount, width) {
  let itemWidth, itemHeight
  const height = (width / 4.5)
  if(groupCount > 2) {
    itemWidth = (width / 3)
    itemHeight = height
  } else {
    itemWidth = (width / 2)
    itemHeight = height
  }
  return { itemWidth, itemHeight }
}

function renderNoGroupsIndicator(itemWidth, itemHeight, theme) {
  return (
    <Chart
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      value={0}
      label="No group"
      color={theme.default}
    />
  )
}

function renderTruncateIndicator(groups) {
  const hiddenCount = (groups.length - 3)
  return (
    <TruncateFooter>
      <Typography variant="small" weight="light">
        <FormattedMessage
          id="owner.CountCircles.truncateText"
          defaultMessage="... and {count} more {count, plural, one {group} other {groups} }"
          values={{ count: hiddenCount }}
        />
      </Typography>
    </TruncateFooter>
  )
}

const CountCircles = ({ theme, groups, colors, width }) => {
  const { itemWidth, itemHeight } = determineDimensions(groups.length, width)
  const hasNoGroups = (!groups || groups.length === 0)
  const willTruncate = (groups.length > 3)
  return (
    <div>
      <ChartRow>
        {groups.slice(0,3).map((group) => (
          <Chart
            groupId={group.groupId}
            key={group.groupId}
            itemWidth={itemWidth}
            itemHeight={itemHeight}
            value={group.value}
            label={group.name}
            color={colors(group.groupId)}
          />
        ))}
        {hasNoGroups ? renderNoGroupsIndicator(itemWidth, itemHeight, theme) : null}
      </ChartRow>
      {willTruncate ? renderTruncateIndicator(groups) : null}
    </div>
  )
}

CountCircles.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  })),
  colors: PropTypes.func.isRequired,
  width: PropTypes.number
}

CountCircles.defaultProps = {
  width: 560
}

export default withTheme(CountCircles)
