import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { injectIntl, defineMessages } from 'react-intl'
import debounce from 'lodash/debounce'

import searchIcon from '../../../static/img/search.svg'

const PLATFORMS = ['iPhone', 'iPod', 'iPad', 'iPhone Simulator', 'iPod Simulator', 'iPad Simulator']

const messages = defineMessages({
  search: {
    id: 'RecipientSelector.search',
    defaultMessage: 'Search'
  }
})

const ComponentRoot = styled.label`
  position: relative;
  top: 0;
  left: 0;
`

const SearchInput = styled.input`
  font-family: ${props => props.theme.font2};
  color: ${props => props.theme.default};
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  height: 38px;
  padding-left: 50px;
  border-radius: 5px;
  border: 1px solid #eee;
  outline: none;
  ::placeholder { color: #ccc; }
  &:hover, &:focus {
    border-color: ${props => props.theme.main.primary};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`

const SearchIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 20px;
  padding-top: 1px;
  cursor: text;
`

class SearchBox extends Component {
  constructor(props) {
    super(props)
    this.state = { value: '' }
    this.handleChange = this.handleChange.bind(this)
    this.debouncedChange = debounce(props.onChange, 300)
  }

  // on IOS devices disable user level zoom to disable input focus issues
  UNSAFE_componentWillMount() {
    if (PLATFORMS.some(x => x === navigator.platform)) {
      const metas = document.getElementsByTagName('meta')
      for (let i = 0; i < metas.length; i++) {
        if (metas[i].name === 'viewport') {
          this.meta = metas[i]
          this.prevContent = metas[i].content
          this.meta.content = `${this.meta.content},user-scalable=no`
        }
      }
    }
  }

  // revert to default
  componentWillUnmount() {
    if (PLATFORMS.some(x => x === navigator.platform) && this.meta && this.prevContent) {
      this.meta.content = `${this.prevContent},user-scalable=yes`
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
    this.debouncedChange(event.target.value)
  }

  render() {
    const { intl } = this.props
    const { value } = this.state
    return (
      <ComponentRoot>
        <SearchIcon src={searchIcon} alt="search" />
        <SearchInput
          type="text"
          spellCheck="false"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={intl.formatMessage(messages.search)}
          value={value}
          onChange={this.handleChange}
        />
      </ComponentRoot>
    )
  }
}

SearchBox.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default injectIntl(SearchBox)
