import React, { Component } from 'react'
import { IntlProvider, FormattedMessage } from 'react-intl'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as authUtils from '../../actions/utils/authUtils'
import { initOwnerTracking } from '../../utils/trackingUtils'
import DimmerLoader from '../../components/common/DimmerLoader/DimmerLoader'
import Modal from '../../components/common/Modal/Modal'
import GenericModal from '../../components/common/GenericModal/GenericModal'
import Typography from '../../components/common/Typography/Typography'
import ApplicationBar from '../../components/owner/ApplicationBar/ApplicationBar'
import { getUser } from '../../actions/userActions'
import { getMyOrganizations } from '../../actions/organizationActions'
import { filterQvestsById } from '../../reducers/qvests'
import { filterMyUser } from '../../reducers/user'
import { filterByMyOrganizations, filterFirstOrganization } from '../../reducers/organization'
import OwnerAccountPage from './OwnerAccountPage'
import OwnerOrganizationPage from './OwnerOrganizationPage'
import OwnerQvestPage from './qvest/OwnerQvestPage'
import OwnerPreviewQvestSitePage from './preview/OwnerPreviewQvestSitePage'
import OwnerPreviewInviteEmail from './preview/OwnerPreviewInviteEmail'

const ComponentRoot = styled.div`
  min-height: 100vh;
  /* use padding to take up appbar space */
  padding-top: 45px;
  position: relative;
  & > *:first-child {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
`

function isAnalyticsSection(match) {
  return (match && match.path === 'analytics')
}

// detect internet explorer
function ieVersion(uaString) {
  uaString = uaString || navigator.userAgent
  var match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString)
  if (match) return parseInt(match[2])
}

// detect if mobile
const IS_MOBILE_REGEX = /iphone|ipod|android|webos|ipad|windows\sphone|ie|blackberry|fennec/i

function isMobile() {
  return IS_MOBILE_REGEX.test(navigator.userAgent)
}

class OwnerSection extends Component {
  state = { showModal: null }

  // scroll top on location change
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  // temporary solution
  UNSAFE_componentWillMount() {
    document.body.style.overflowX = 'auto'
    // TODO: Remove this when the background color has been implemented globally
    if (isAnalyticsSection(this.props.match)) {
      document.body.style.background = '#F6F6F3'
    }
  }

  // temporary solution
  componentWillUnmount() {
    document.body.style.overflowX = 'hide'
    if (isAnalyticsSection(this.props.match)) {
      document.body.style.background = '#F1ECE1'
    }
  }

  handleHideModal = () => {
    this.setState({ showModal: null })
  }

  componentDidMount() {
    const ie = ieVersion()
    // edge 16 is the first version supporting CSS grid
    if (ie && ie < 16) {
      this.setState({ showModal: 'ie' })
    }

    if (isMobile()) {
      this.setState({ showModal: 'mobile' })
    }

    const { dispatch, history } = this.props
    authUtils.initialize()
      .then(ready => {
        if(!ready) return
        Promise.all([
          dispatch(getUser()).then(user => initOwnerTracking(history, user)),
          dispatch(getMyOrganizations())
        ])
      })
  }

  renderMobileModal() {
    const ieModalTitle = <FormattedMessage
      id="owner.OwnerSection.mobileTitle"
      defaultMessage="Platform support"
    />

    return (
      <Modal>
        <GenericModal
          ok
          title={ieModalTitle}
          onSubmit={this.handleHideModal}
          onCancel={this.handleHideModal}
        >
          <Typography.p variant="medium">
            <FormattedMessage
              id="owner.OwnerSection.mobile1"
              defaultMessage="Hi there"
            />
          </Typography.p>
          <Typography.p variant="medium">
            <FormattedMessage
              id="owner.OwnerSection.mobile2"
              defaultMessage="It looks like you are on a mobile device."
            />
          </Typography.p>
          <Typography.p variant="medium">
            <FormattedMessage
              id="owner.OwnerSection.mobile3"
              defaultMessage="The admin interface is not optimized for mobile screens. We recommend switching to desktop."
            />
          </Typography.p>
          <Typography.p variant="medium">
            <FormattedMessage
              id="owner.OwnerSection.mobile4"
              defaultMessage="💛 Team Qvest"
            />
          </Typography.p>
        </GenericModal>
      </Modal>
    )
  }

  renderIEModal() {
    if (this.state.showModal !== 'ie') return null

    const ieModalTitle = <FormattedMessage
      id="owner.OwnerSection.ieTitle"
      defaultMessage="Browser support"
    />

    return (
      <Modal>
        <GenericModal
          ok
          title={ieModalTitle}
          onSubmit={this.handleHideModal}
          onCancel={this.handleHideModal}
        >
          <Typography.p variant="medium">
            <FormattedMessage
              id="owner.OwnerSection.ie1"
              defaultMessage="Hi there, it looks like you are working in Internet Explorer."
            />
          </Typography.p>
          <Typography.p variant="medium">
            <FormattedMessage
              id="owner.OwnerSection.ie2"
              defaultMessage="This application is not optimized for Internet Explorer, so some buttons and features may not work correctly."
            />
          </Typography.p>
          <Typography.p variant="medium">
            <FormattedMessage
              id="owner.OwnerSection.ie3"
              defaultMessage="We recommend that you switch to Chrome."
            />
          </Typography.p>
          <Typography.p variant="medium">
            <FormattedMessage
              id="owner.OwnerSection.ie4"
              defaultMessage="💛 Team Qvest"
            />
          </Typography.p>
        </GenericModal>
      </Modal>
    )
  }

  render() {
    const { userStore, organizationStore, routes } = this.props

    const user = filterMyUser(userStore)
    const organizations = filterByMyOrganizations(organizationStore)


    if(!organizations && organizationStore.error) {
      throw new Error('UNABLE_TO_FETCH_ORGANIZATIONS')
    } if (!user || !organizations) {
      return <DimmerLoader />
    } else if (organizations.length === 0) {
      return <Redirect to="/oidc/finalize" />
    }

    // cannot show backdrop due to scaling issues with min width
    if (this.state.showModal === 'mobile') {
      return <IntlProvider locale="en">{this.renderMobileModal()}</IntlProvider>
    }

    const organization = filterFirstOrganization(organizationStore)
    const organizationId = organization.organizationId

    let defaultRedirect = `/owner/organization/${organizationId}`
    if (user.source === 'jam') {
      defaultRedirect = '/jam'
    }

    // TODO: Render error through userStore.error
    return (
      <DndProvider backend={HTML5Backend}>
        <IntlProvider locale="en">
          <ComponentRoot isAnalytics={isAnalyticsSection(routes)}>
            <ApplicationBar
              user={user}
              organizations={organizations}
              homeUrl={this.homeUrl()}
              onOrganizationClick={this.navigateToOrganization}
              onOrganizationSettingsClick={this.navigateToOrganizationSettings}
              onUserSettingsClick={this.navigateToAccountSettings}
              onTermsClick={this.navigateToTerms}
              onLogoutClick={this.onLogout}
            />
            <Switch>
              <Route path="/owner/user" component={OwnerAccountPage} />
              <Route path="/owner/organization/:organizationId" component={OwnerOrganizationPage} />
              <Route path="/owner/qvest/:qvestId/:tabId?" component={OwnerQvestPage} />
              <Route path="/owner/preview/qvest/:qvestId/invite-email" component={OwnerPreviewInviteEmail} />
              <Route path="/owner/preview/qvest/:qvestId" component={OwnerPreviewQvestSitePage} />
              <Redirect to={defaultRedirect} />
            </Switch>
            {this.renderIEModal()}
            {this.props.children}
          </ComponentRoot>
        </IntlProvider>
      </DndProvider>
    )
  }

  onLogout() {
    authUtils.logout()
  }

  navigateToAccountSettings = () => {
    this.props.history.push('/owner/user')
  }

  homeUrl = () => {
    const { location, qvestStore } = this.props
    const splits = location.pathname.split('/')
    if (splits[2] === 'organization') {
      // on org pages
      const organizationId = splits[3]
      return `/owner/organization/${organizationId}`
    } else if (splits[2] === 'qvest') {
      // on qvest pages
      const qvestId = splits[3]
      const qvest = filterQvestsById(qvestStore, qvestId)
      if (qvest) {
        // TODO: override with new orgId location from graphql endpoint
        // qvest.organizationId -> qvest.organization.organizationId
        return `/owner/organization/${qvest.organizationId}`
      } else {
        // qvest not loaded yet
        return '/owner'
      }
    } else {
      // fallback for other routes, eg.: user settings
      return '/owner'
    }
  }

  navigateToOrganization = (organizationId) => {
    this.props.history.push(`/owner/organization/${organizationId}`)
  }

  navigateToOrganizationSettings = (organizationId) => {
    this.props.history.push(`/owner/organization/${organizationId}/settings`)
  }

  // TODO: make this an <a> tag, when AppBar is refactored into a downshift component
  navigateToTerms = () => {
    window.open('https://qvest.io/terms', '_blank')
  }
}

function mapStateToProps(state) {
  return {
    userStore: state.user.toJS(),
    organizationStore: state.organization.toJS(),
    qvestStore: state.qvests.toJS(),
  }
}

export default connect(mapStateToProps)(withRouter(OwnerSection))
