import React, { Component, Fragment } from 'react'
import { TransitionGroup, Transition } from 'react-transition-group'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'

import QuestionPage from './qvest/QuestionPage'
import ActivityPage from './qvest/ActivityPage'
import QvestSitePage from './qvest/QvestSitePage'
import LobbyPage from './qvest/LobbyPage'
import FeaturesProvider from '../../components/common/FeaturesProvider/FeaturesProvider'
import BottomNavBar from '../../components/qvest/BottomNavBar/BottomNavBar'
import DimmerLoader from '../../components/common/DimmerLoader/DimmerLoader'
import Modal from '../../components/common/Modal/Modal'
import AccessDeniedModal from '../../components/common/AccessDeniedModal/AccessDeniedModal'
import * as authUtils from '../../actions/utils/authUtils'
import * as theme from '../../components/theme'
import { scheduleRedirectToFinale } from '../../utils/qvestUtils'
import { loadLocale } from '../../utils/i18nUtils'
import { getQvest } from '../../actions/qvestActions'
import { getOwnQuestions } from '../../actions/questionActions'
import { getParticipantsLight } from '../../actions/participantActions'
import { getQvestSchedule } from '../../actions/scheduleActions'
import { getGroupingByQvestId } from '../../actions/groupingActions'
import { getConfiguration } from '../../actions/configurationActions'
import { filterQvestsById } from '../../reducers/qvests'
import { filterSchedulesByQvestsId } from '../../reducers/schedules'
import { filterOwnQuestions } from '../../reducers/questions'
import { filterParticipantsByQvest } from '../../reducers/participants'
import { filterFeaturesConfig } from '../../reducers/configuration'
import { initParticipantTracking } from '../../utils/trackingUtils'
import FinalePage from './qvest/FinalePage'


const Root = styled.div`
  background: ${({ theme }) => theme.two[0]};
  height: 100%;

  /* Bottom navigation padding */
  padding-bottom: 50px;
  @media (min-width: 700px) { padding-bottom: 75px; }
`


function findExpiredError(errors) {
  if (errors) {
    return errors.find(e => e.message === 'QVEST_EXPIRED')
  }
  return null
}

class QvestSection extends Component {
  state = {
    isFinaleScheduled: false
  }

  componentDidMount() {
    const { dispatch, match, history, alreadyLoggedIn } = this.props
    const { qvestId } = match.params

    // Init auth, if needed
    let authPromise
    if (!alreadyLoggedIn) {
      authPromise = authUtils.initialize(true, qvestId)
    } else {
      authPromise = Promise.resolve()
    }

    // Load data
    authPromise
      .then(ready => {
        if (!ready) return
        dispatch(getQvest(qvestId))
          .then(qvest => scheduleRedirectToFinale(qvest, match, history)) // Redirect to /finale page, when jam closes
        dispatch(getOwnQuestions(qvestId))
        dispatch(getQvestSchedule(qvestId))
        dispatch(getGroupingByQvestId(qvestId))
        dispatch(getConfiguration(qvestId))
        dispatch(getParticipantsLight(qvestId))
          .then(participants => initParticipantTracking(history, participants, qvestId))
      })
  }

  // fetch questions on next click to avoid stale tabs
  updateQuestions = () => {
    const { dispatch, match } = this.props
    const { qvestId } = match.params
    dispatch(getOwnQuestions(qvestId))
  }

  componentDidUpdate(prevProps) {
    // Scroll to top when page changes
    if (this.props.location !== prevProps.location) {
      window.scrollTo({ top: 0, behavior: 'instant' })
    }
  }

  render() {
    const {
      match,
      location,
      qvestStore,
      scheduleStore,
      questionStore,
      participantStore,
      configurationStore
    } = this.props

    const { qvestId, page } = match.params
    const qvest = filterQvestsById(qvestStore, qvestId)
    const schedule = filterSchedulesByQvestsId(scheduleStore, qvestId)
    const participants = filterParticipantsByQvest(participantStore, qvestId)
    const questions = filterOwnQuestions(questionStore, qvestId)
    const featuresConfig = filterFeaturesConfig(configurationStore, qvestId)

    const fatalErrors = qvestStore.error || participantStore.error
    const expiredError = findExpiredError(fatalErrors)
    if (expiredError) {
      const locale = expiredError.locale || 'en'
      const messages = loadLocale(locale)
      return (
        <IntlProvider locale={locale} messages={messages}>
          <Modal>
            <AccessDeniedModal variant="QVEST_EXPIRED" />
          </Modal>
        </IntlProvider>
      )
    } else if (fatalErrors) {
      throw Error(fatalErrors.message)
    } else if (!participants || !qvest || !schedule || !questions || !featuresConfig) {
      return (<DimmerLoader />)
    } else {
      const locale = qvest.language
      const messages = loadLocale(locale)
      const questions = filterOwnQuestions(questionStore, qvestId)
      const transitionKey = (page !== 'participants') ? page : undefined // Excluding "participants" as it's a modal
      return (
        <Fragment>
          <IntlProvider locale={locale} messages={messages}>
            <ThemeProvider theme={qvest.isJam ? theme.ocean : theme.yellow}>
              <FeaturesProvider value={featuresConfig}>
                <Root>
                  <Helmet>
                    <title>{qvest && qvest.topic}</title>
                  </Helmet>
                  <TransitionGroup appear={true} exit={true}>
                    <Transition key={transitionKey} timeout={300}>
                      {state =>
                        <Switch location={location}>
                          <Route
                            path="/qvest/:qvestId/question/:questionId"
                            render={props => <QuestionPage {...props} animation={state} />}
                          />
                          <Route
                            path="/qvest/:qvestId/activity"
                            render={props => <ActivityPage {...props} animation={state} />}
                          />
                          <Route
                            path="/qvest/:qvestId/lobby"
                            render={props => <LobbyPage {...props} animation={state} />}
                          />
                          <Route
                            path="/qvest/:qvestId/finale"
                            render={props => <FinalePage {...props} animation={state} />}
                          />
                          <Route
                            render={props => <QvestSitePage {...props} animation={state} />}
                          />
                        </Switch>
                      }
                    </Transition>
                  </TransitionGroup>
                  <BottomNavBar
                    qvest={qvest}
                    questions={questions}
                    onNextClick={this.updateQuestions}
                  />
                </Root>
              </FeaturesProvider>
            </ThemeProvider>
          </IntlProvider>
        </Fragment>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    scheduleStore: state.schedules.toJS(),
    questionStore: state.questions.toJS(),
    participantStore: state.participants.toJS(),
    configurationStore: state.configuration.toJS()
  }
}

export default connect(mapStateToProps)(withRouter(QvestSection))
