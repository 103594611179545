export const wasWelcomeShown = (afterDate) => {
  let modalShownAt = localStorage.getItem('welcome_shown_at')
  modalShownAt = modalShownAt && new Date(JSON.parse(modalShownAt))
  return !modalShownAt || (afterDate && (modalShownAt < afterDate))
}

export const markWelcomeShown = () => {
  const now = new Date().getTime()
  localStorage.setItem('welcome_shown_at', now)
}

export const wasQvestClosedShown = () => {
  return localStorage.getItem('qvest_closed_shown')
}

export const markQvestClosedShown = () => {
  localStorage.setItem('qvest_closed_shown', true)
}
