import React, { useState } from 'react'
import { withRouter } from 'react-router'
import styled, { withTheme } from 'styled-components'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { gql, useQuery } from '@apollo/client'

import * as authUtils from '../../actions/utils/authUtils'
import { loadLocale } from '../../utils/i18nUtils'
import Heading from '../../components/common/Typography/Heading'
import Button from '../../components/common/Button/Button'
import Input from '../../components/common/Input/Input'
import { useCreateQvest } from '../../operations/qvest'
import LanguageSelector from '../../components/common/LanguageSelector/LanguageSelector'
import Card from '../../components/common/Card/Card'
import DimmerLoader from '../../components/common/DimmerLoader/DimmerLoader'


const JAM_CREATE_PAGE_QUERY = gql`
  query q {
    organizations {
      items {
        organizationId
        name
        isMaster
        logo
      }
    }
  }
`

const ComponentRoot = styled.div`
  padding: 20px;
`

const Body = styled.div`
  padding: 20px;

  & > * {
    margin-top: 20px;
  }
`

const Form = styled.form`
  & > * {
    display: block;
    margin: 20px 0;
  }

  & Label:nth-child(2) {
    max-width: 200px;
  }
`

const Label = styled.label`
  font-family: ${({ theme }) => theme.font3};
  font-weight: 900;
  font-size: 13px;
  line-height: 15px;
  color: ${({ theme }) => theme.one[0]};
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0;

  & > * {
    margin-left: 15px;
  }
`

const CreateButton = ({ loading }) => {
  if (loading) {
    return (
      <Button disabled primary>
        <FormattedMessage id="JamCreatePage.submit.loading" defaultMessage="Loading..." />
      </Button>
    )
  }
  return (
    <Button primary>
      <FormattedMessage id="JamCreatePage.submit" defaultMessage="Create jam" />
    </Button>
  )
}

const JamCreatePage = ({ history }) =>  {
  const [loading, setLoading] = useState(false)
  const [topic, setTopic] = useState('')
  const [language, setLanguage] = useState('en')
  const languageFilter = ['en', 'da']
  const locale = 'en'

  const { data } = useQuery(JAM_CREATE_PAGE_QUERY)
  const createQvest = useCreateQvest()

  if (!data || !data.organizations) {
    return (<DimmerLoader />)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const organization = data.organizations.items[0]
    const qvest = await createQvest({
      isJam: true,
      name: 'A Jam',
      organizationId: organization.organizationId,
      language,
      topic
    })
    history.push(`/jam/${qvest.qvestId}`)
  }

  const handleLogout = (event) => {
    event.preventDefault()
    return authUtils.logout()
  }

  return (
    <IntlProvider locale={locale} messages={loadLocale(locale)}>
      <ComponentRoot>
        <Card>
          <Body>
            <Heading.h1 variant="heading1">
              <FormattedMessage id="JamCreatePage.heading" defaultMessage="Question Jam" />
              {' '}
              <Heading variant="heading2" transparent>[BETA]</Heading>
            </Heading.h1>
            <Form onSubmit={handleSubmit}>
              <Label htmlFor="topic">
                <span>
                  <FormattedMessage id="JamCreatePage.label.topic" defaultMessage="Topic:" />
                </span>
                <Input
                  id="topic"
                  type="text"
                  variant="large"
                  onChange={({ target }) => setTopic(target.value)}
                  value={topic}
                />
              </Label>
              <Label htmlFor="language">
                <span>
                  <FormattedMessage id="JamCreatePage.label.language" defaultMessage="Language:" />
                </span>
                <LanguageSelector id="language" value={language} onChange={setLanguage} filter={languageFilter} />
              </Label>
              <Actions>
                <Button outline onClick={handleLogout}>
                  <FormattedMessage id="JamCreatePage.signout" defaultMessage="Sign out" />
                </Button>
                <CreateButton loading={loading} />
              </Actions>
            </Form>
          </Body>
        </Card>
      </ComponentRoot>
    </IntlProvider>
  )
}

export default withRouter(withTheme(JamCreatePage))
