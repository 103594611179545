import React, { Component, Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { showCreateQvest, createQvest } from '../../actions/qvestActions'
import { filterByOrganizationId } from '../../reducers/organization'
import { filterQvestsByOrganization } from '../../reducers/qvests'
import OrganizationBar from '../../components/owner/OrganizationBar/OrganizationBar'
import OwnerOrganizationSettingsPage from './organization/OwnerOrganizationSettingsPage'
import OwnerOrganizationQvestsPage from './organization/OwnerOrganizationQvestsPage'
import JamCreatePage from '../jam/JamCreatePage'


class OwnerOrganizationPage extends Component {
  handleStartEditQvest = () => {
    const { dispatch, match, history } = this.props
    const organizationId = match.params.organizationId
    dispatch(showCreateQvest())
    history.push(`/owner/organization/${organizationId}`)
  }

  handleCancelEditQvest = () => {
    this.props.dispatch(showCreateQvest(false))
  }

  handleCreateQvest = ({ name, language }) => {
    const { dispatch, match, history } = this.props
    const organizationId = match.params.organizationId
    const qvest = { organizationId, name, language }
    if (!name) return null
    dispatch(createQvest(qvest))
      .then(qvest => {
        dispatch({ type: 'INIT_UNSAVED_QVEST', qvest })
        history.push(`/owner/qvest/${qvest.qvestId}/participants`)
      })
    dispatch(showCreateQvest(false))
  }

  filterHandledErrors(error) {
    if (!error) return null
    // filtering out specific error since it is handled in org settings locally
    if (Array.isArray(error) && error.length === 1 && error[0].message === 'ALREADY_IN_ORGANIZATION') {
      return null
    }
    return error.message
  }

  render() {
    const { qvestStore, organizationStore, match } = this.props
    const organizationId = match.params.organizationId
    const organization = filterByOrganizationId(organizationStore, organizationId)
    const qvests = filterQvestsByOrganization(qvestStore, organizationId)
    const hasNoQuests = !qvests ? false : !qvests.length // if the store filter returns null it is still loading stage. otherwise check for qvests length
    const pathPrefix = '/owner/organization/:organizationId'

    return (
      <Fragment>
        <Helmet>
          <title>{organization && organization.name}</title>
        </Helmet>
        <OrganizationBar
          organization={organization}
          onStartEdit={this.handleStartEditQvest}
          onCancelEdit={this.handleCancelEditQvest}
          onSave={this.handleCreateQvest}
          isCreatingQvest={qvestStore.isCreating || hasNoQuests}
        />
        <Switch>
          <Route path={`${pathPrefix}/settings`} component={OwnerOrganizationSettingsPage} key={organizationId} />
          <Route path={`${pathPrefix}/jam`} component={JamCreatePage} key={organizationId} />
          <Route path={pathPrefix} exact={true} component={OwnerOrganizationQvestsPage} key={organizationId} />
          <Redirect to={`/owner/organizationId/${organizationId}`} />
        </Switch>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    organizationStore: state.organization.toJS()
  }
}

export default connect(mapStateToProps)(OwnerOrganizationPage)
