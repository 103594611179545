import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'

import * as authUtils from '../../actions/utils/authUtils'
import * as theme from '../../components/theme'
import FeaturesProvider from '../../components/common/FeaturesProvider/FeaturesProvider'
import JamLandingPage from './JamLandingPage'
import JamCreatePage from './JamCreatePage'
import jamMetaImagePng from '../../static/img/jam-meta-image.png'

const PAGE_TITLE = 'Question Jam'
const META_DESCRIPTION = 'Join a Question Jam here. No login required. Question Jam is a free meeting game for facilitators who want to add energy and engagement to any meeting or workshop.'

const ComponentRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 40px;
  background-color: ${({ theme }) => theme.background2};
`

const Content = styled.div`
  width: 100%;
  max-width: 850px;
  margin: 0 auto;

  & > * {
    margin: 40px 0;
  }
`

const JamSection = () =>  {
  // Initialize authentication
  const [isAuthReady, setIsAuthReady] = useState(false)
  useEffect(() => {
    const initialize = async () => {
      const ready = await authUtils.initialize()
      if (ready) {
        setIsAuthReady(true)
      }
    }
    initialize()
  }, [])

  // Loop background music
  // useEffect(() => {
  //   var audio_file = new Audio(jamBgMusic)
  //   audio_file.addEventListener('timeupdate', function(){
  //     var buffer = .25
  //     if(this.currentTime > this.duration - buffer){
  //       this.currentTime = 0
  //       this.play()
  //     }
  //   })
  //   audio_file.play()
  // }, [])

  if (!isAuthReady) {
    return <div>Loading</div>
  }

  const features = {
    questionPins: { enabled: false }
  }

  return (
    <ThemeProvider theme={theme.ocean}>
      <FeaturesProvider value={features}>
        <ComponentRoot>
          <Helmet>
            <title>{PAGE_TITLE}</title>
            <meta property="description" content={META_DESCRIPTION} />
            <meta property="og:title" content={PAGE_TITLE} />
            <meta property="og:description" content={META_DESCRIPTION} />
            <meta property="og:image" content={jamMetaImagePng} />
            <meta property="og:image:secure_url" content={jamMetaImagePng} />
          </Helmet>
          <Content>
            <Switch>
              <Route path="/jam/:qvestId">
                <JamLandingPage />
              </Route>
              <Route path="/jam">
                <JamCreatePage />
              </Route>
            </Switch>
          </Content>
        </ComponentRoot>
      </FeaturesProvider>
    </ThemeProvider>
  )
}

export default JamSection
