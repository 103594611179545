import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, withTheme } from 'styled-components'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import { keyBy, mapValues } from 'lodash'

import Button from '../../common/Button/Button'
import Input from '../../common/Input/Input'
import Icon from '../../common/Icon/Icon'
import Heading from '../../common/Typography/Heading'
import Column from '../QuestionThemesEditor/Column'
import CreateColumn from '../QuestionThemesEditor/CreateColumn'
import SuggestThemes from '../QuestionThemesEditor/SuggestThemes'
import LastUpdateStatus from '../LastUpdateStatus/LastUpdateStatus'
import { makeThemeColorScales } from '../../../utils/d3Utils'

import ResultBox from './ResultBox'
import reducer from './reducer'
import { findQuestion, groupQuestionsByTheme, initializeQuestions, resolvePosition, findRelevantAssignment, findRelevantThemeSuggestions } from './utilities'
import Recommendation from './Recommendation'
import Modals from './Modals'


const ComponentRoot = styled.div`
  position: relative;
  padding-top: 20px;
  padding-left: 370px;
  display: flex;
  & > * { flex-shrink: 0; }
  & > * + * { margin-left: 20px; }
`

const ThemesArea = styled.div`
  & > * {
    display: flex;
    & > * + * { margin-left: 20px; }
  }
  & > *:first-child > * {
    margin-bottom: 20px;
  }
`

const FirstColumn = styled.div`
  position: fixed;
  top: 125px;
  left: 80px;
  bottom: 0;
  background-color: ${({ theme }) => theme.inverted.two[0]};
  padding: 20px 0 10px 0;
  box-shadow: 2px 2px 6px #edeeee;
  width: 370px;
  z-index: 10;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.inverted.two[1]};
`

const DescriptionWrapper = styled.div`
  margin-bottom: 25px;
  padding: 0 20px;
`

const SearchField = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  & > :not(:first-child) {
    margin-left: 10px;
  }
`

const ThemeCard = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.background1};
  padding-bottom: 10px;
  box-shadow: 2px 2px 6px #edeeee;
  border-radius: 3px;
  border: 1px solid #edeeee;
  width: 370px;
  height: 100%;
  opacity: ${({ disabled }) => disabled ? '0.6' : '1.0'};

  & > * {
    width: 100%;
    height: 100%;
    min-height: 74px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-color: ${props => props.fill};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
`

const IconButton = styled(Button)`
  display: ${({ hidden }) => hidden ? 'none': 'unset'};
  padding: 5px 10px 3px 7px;
  & > * {
    display: block;
    width: 14px;
  }
`

const rotate360 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const LoadingIcon = styled(Icon)`
  display: ${({ hidden }) => hidden ? 'none': 'unset'};
  animation: ${rotate360} 2s linear infinite;
`

const ButtonWrapper = styled.div`
  flex-direction: column;
  & > :first-child {
    margin-bottom: 10px;
  }
`


const messages = defineMessages({
  unassigned: { id: 'owner.AnalyticsThemes.unassigned', defaultMessage: 'Unassigned questions' },
})

const AnalyticsThemes = (props) => {
  const {
    themeFocusEnabled,
    insightsLink,
    onUpdateTheme,
    onDeleteTheme,
    onAssignTheme,
    onAddTheme,
    onSetRating,
    onDownload,
    onSortQuestions,
    onShowSettings,
    intl,
    theme,
    yOffset,
  } = props

  const [state, dispatch] = useReducer(reducer, {})
  const { themeSet, questions, updates, sorting, suggesting, lastUpdate } = state
  const [filter, setFilter] = useState('')
  const [shownModal, setShownModal] = useState(null)

  useEffect(() => {
    const defaultThemeSet = props.themeSets.find(themeSet => themeSet.label === 'DEFAULT')
    if (!defaultThemeSet) {
      throw new Error('No default themeSet found')
    }
    const indexedQuestions = initializeQuestions(props.questions, defaultThemeSet.themes, defaultThemeSet.themeSetId)
    dispatch({ type: 'INIT_UNSAVED_THEME_SET', themeSet: defaultThemeSet })
    dispatch({ type: 'INIT_QUESTIONS', questions: indexedQuestions })
  }, [])

  // Persist theme and position of dropped question cards
  useEffect(() => {
    if (updates && updates.length > 0) {
      const relevantUpdates = updates.filter(u => u.operation === 'THEME_ASSIGNMENT' && u.status === 'QUEUED')
      for (const { questionId  } of relevantUpdates) {
        const question = questions.find(q => q.questionId === questionId)
        const neighbours = questions.filter(q => q.theme.themeId === question.theme.themeId)
        const index = neighbours.findIndex(q => q.questionId === questionId)

        // Resolve position
        const previousQuestion = neighbours[index - 1]
        const nextQuestion = neighbours[index + 1]
        const position = resolvePosition(previousQuestion, nextQuestion)

        // Persist new position and theme in backend
        handleAssignTheme(question.questionId, position, question.theme, question.position)
      }
    }
  }, [updates])

  if (!questions || !themeSet) return null
  const { themeSetId, themes } = themeSet
  const colors = makeThemeColorScales(theme, themes)

  // Fixed vertical offset to allow IE-compatible inline-scrolling using viewport height (See Column styling)
  const firstYOffset = 210 + yOffset // The height of heading + root Y-offset
  const themeYOffset = 120 + yOffset // The height of theme title + root y-offset

  const rejectedThemes = (suggesting && suggesting.rejectedThemes) ? suggesting.rejectedThemes : []
  const suggestedThemeSet = props.themeSets.find(themeSet => themeSet.label === 'SUGGESTION')
  const suggestedThemes = findRelevantThemeSuggestions(questions, themes, suggestedThemeSet, rejectedThemes)

  const questionsByTheme = groupQuestionsByTheme(questions, themes)
  const isAdding = themes.some(theme => theme.themeId === 'new')
  const isFocusing = themes.some(theme => theme.isFocused)
  const unassigned = questionsByTheme.default || []
  const isAlreadyComplete = (unassigned.length === 0)
  const unassignedPlaceholderTheme = {
    themeId: 'default',
    isEditing: false,
    name: intl.formatMessage(messages.unassigned),
    themeSet: { themeSetId }
  }

  let filteredUnassigned = unassigned
  if (filter && filter.length > 0) {
    filteredUnassigned = unassigned.filter(q => q.questionContent.toLowerCase().indexOf(filter) !== -1)
  }

  const isCompleteAssignment = (toThemeId, questionId) => {
    const isMovingToUnassigned = (toThemeId === 'default')
    const isLastQuestion = (unassigned.length === 1 && unassigned[0].questionId === questionId)
    return !isMovingToUnassigned && (isAlreadyComplete || isLastQuestion)
  }

  const isThemeDisabled = (theme) => {
    return theme.isDeleting || theme.isSaving || (isFocusing && (sorting.themeId !== theme.themeId))
  }

  const sortUnassignedQuestions = (themeId) => {
    dispatch({ type: 'INIT_QUESTION_SORTING', themeId })
    return onSortQuestions(themeId)
      .then(questionsWithScore => {
        // Sort unassigned questions by comparison to given theme (similarity score)
        const unassignedQuestions = questionsWithScore.filter(q => !findRelevantAssignment(q, themeSetId))
        const unassignedQuestionsById = keyBy(unassignedQuestions, q => q.questionId)
        const scores = mapValues(unassignedQuestionsById, q =>
          q.themes.comparisons && q.themes.comparisons.find(c => c.theme.themeId === themeId).score
        )
        dispatch({ type: 'FINALIZE_QUESTION_SORTING', themeId, scores })
      })
      .catch(() => dispatch({ type: 'CANCEL_QUESTION_SORTING', themeId }))
  }

  const handleDeleteTheme = (themeId) => {
    dispatch({ type: 'TOGGLE_THEME_DELETE_FLAG', themeId, isDeleting: true })
    return onDeleteTheme(themeId, themeSet)
      .then(() => dispatch({ type: 'DELETE_THEME', themeId, themeSetId }))
      .catch(() => dispatch({ type: 'TOGGLE_THEME_DELETE_FLAG', themeId, isDeleting: false }))
  }

  const handleAssignTheme = (questionId, position, theme, oldPosition) => {
    // Lock as unsaved
    dispatch({ type: 'ASSIGN_THEME', questionId, position, theme })
    // Persist position and theme in backend
    const themeId = theme.themeId
    const externalThemeId = (themeId !== 'default' ? themeId : undefined)
    onAssignTheme(questionId, themeSetId, externalThemeId, position)
      .then(() => {
        dispatch({ type: 'CONFIRM_THEME_ASSIGNMENT', questionId })
        if (isCompleteAssignment(themeId, questionId)) {
          setShownModal('alignmentRating')
        }
      })
      .catch(() => dispatch({ type: 'REVERT_THEME_ASSIGNMENT', questionId, oldPosition }))
  }

  const handleAddTheme = (questionId) => {
    const theme = { themeId: 'new', name: '', themeSet: { themeSetId } }
    dispatch({ type: 'START_ADD_THEME', theme })
    // If theme added by dragging a question to "add theme" button,
    // then immediately finalize theme and assign question
    if (questionId) {
      const [question,] = findQuestion(questions, questionId)
      const toPosition = 0
      handleDoneEditTheme(theme)
        .then(persistedToTheme => {
          handleAssignTheme(questionId, toPosition, persistedToTheme, question.position)
        })
    }
  }

  const handleStartEditTheme = (theme) => {
    const { themeId, name } = theme
    dispatch({ type: 'START_THEME_EDIT', themeId, name })
  }

  const handleEditTheme = (themeId, patch) => {
    dispatch({ type: 'EDIT_THEME', themeId, patch })
  }

  const handleDoneEditTheme = (theme) => {
    const { themeId, name, isSuggesting } = theme
    if (isSuggesting || themeId === 'new') {
      // Finalize new theme
      dispatch({ type: 'FINALIZE_ADD_THEME', themeId, name })
      const baseThemeId = (isSuggesting ? themeId : null)
      return onAddTheme(themeSet, name, baseThemeId)
        .then(theme => {
          // const themeDefault = {...theme, themeSet: {themeSetId: themeSet.themeSetId}}
          dispatch({ type: 'CONFIRM_ADD_THEME_FINALIZATION', themeId, theme})

          if (isSuggesting) {
            const themeQuestions = questions.filter(q => q.theme.themeId === themeId)
            themeQuestions.map(question => {
              handleAssignTheme(question.questionId, 0, theme, question.position)
            })
          }
          return theme
        })
        .catch(() => dispatch({ type: 'REVERT_ADD_THEME_FINALIZATION', themeId }))
    } else {
      // Finalize edits to existing theme
      dispatch({ type: 'FINALIZE_EDIT_THEME', themeId, name })
      return onUpdateTheme(themeId, name)
        .then(() => dispatch({ type: 'CONFIRM_EDIT_THEME_FINALIZATION', themeId }))
        .catch(() => dispatch({ type: 'REVERT_EDIT_THEME_FINALIZATION', themeId, name: theme.name }))
    }
  }

  const handleCancelEditTheme = (themeId, isSuggesting) => {
    if (isSuggesting) {
      dispatch({ type: 'CANCEL_ADD_SUGGESTION', themeId })
    } else if (themeId === 'new') {
      dispatch({ type: 'CANCEL_ADD_THEME', themeId })
    } else {
      dispatch({ type: 'CANCEL_THEME_EDITING', themeId })
    }
  }

  const handleCardDrop = (questionId, themeId) => {
    /*
       Lock card in place and queue update for next render (see useEffect further up)

       NOTE: Update not made here because of how drag-and-drop closures work. We don't have access to the updated state
       in this context. This closure references the state from drag start and as a result we can't resolve the new
       position of the question to be persisted in backend.
     */
    let status = 'QUEUED'
    // If dropped on "Add theme" button, lock the card to be assigned once we have a themeId to assign to
    if (themeId === 'new') status = 'LOCKED'
    dispatch({ type: 'END_MOVE_QUESTION', questionId, status })
  }

  const handleCardHovered = (movingQuestionId, hoveringQuestionId, toTheme) => {
    const [, toIndex] = findQuestion(questions, hoveringQuestionId)
    const [question, fromIndex] = findQuestion(questions, movingQuestionId)
    const fromTheme = question.theme
    const indexChange = (toIndex - fromIndex)
    if (indexChange > 1 || indexChange < 0) { // Prevent self-loop with own hover placeholder card
      dispatch({ type: 'MOVE_QUESTION', question, fromIndex, toIndex, fromTheme, toTheme })
    }
  }

  const handleThemeHovered = (movingQuestionId, toTheme, isTop) => {
    const [question, fromIndex] = findQuestion(questions, movingQuestionId)
    const fromTheme = question.theme
    // Assume question was dragged to CreateColumn and thus should end up in new theme
    if (!toTheme) {
      toTheme = { themeId: 'new', name: '', themeSet: { themeSetId } }
    }
    if (fromTheme && fromTheme.themeId === toTheme.themeId) {
      return
    }
    // Resolve new index
    let toIndex = 0
    const themeQuestions = questionsByTheme[toTheme.themeId]
    if (themeQuestions && themeQuestions.length > 0) {
      if (isTop) {
        const firstQuestion = themeQuestions[0]
        const [, firstQuestionIndex] = findQuestion(questions, firstQuestion.questionId)
        toIndex = firstQuestionIndex
      } else {
        const lastQuestion = themeQuestions[themeQuestions.length - 1]
        const [, lastQuestionIndex] = findQuestion(questions, lastQuestion.questionId)
        toIndex = lastQuestionIndex
      }
    }
    dispatch({ type: 'MOVE_QUESTION', question, fromIndex, toIndex, fromTheme, toTheme })
  }

  // Move a question card without drag-and-drop (send to top, send to focused)
  const handleQuickMove = (questionId) => {
    const [question, fromIndex] = findQuestion(questions, questionId)
    const toTheme = (isFocusing ? themes.find(t => t.isFocused) : question.theme)
    const fromTheme = question.theme
    const themeQuestions = questionsByTheme[toTheme.themeId]
    // Resolve position relative to first question
    let toIndex = 0
    let position = 0
    if (themeQuestions &&  themeQuestions.length > 0) {
      const firstQuestion = themeQuestions[0]
      const [, firstQuestionIndex] = findQuestion(questions, firstQuestion.questionId)
      toIndex = firstQuestionIndex
      position = firstQuestion.position - 1
    }
    // Move card
    dispatch({ type: 'MOVE_QUESTION', question, fromIndex, toIndex, fromTheme, toTheme })
    dispatch({ type: 'END_MOVE_QUESTION', questionId, status: 'LOCKED' })
    // Persist new position
    if (toTheme.themeId !== 'default') {
      handleAssignTheme(questionId, position, toTheme, question.position)
    } else {
      // ... unless card is in the unassigned column, then simply confirm "assignment" immediately
      dispatch({ type: 'CONFIRM_THEME_ASSIGNMENT', questionId })
    }
  }

  const handleToggleCollapsed = (themeId, isCollapsed) => {
    dispatch({ type: 'TOGGLE_THEME_COLLAPSED', themeId, isCollapsed })
  }

  const handleSearchChange = (event) => {
    const { value } = event.target
    setFilter(value.toLowerCase())
  }

  const handleSetRating = (rating) => {
    return onSetRating(rating).then(() => setShownModal(null))
  }

  const handleToggleFocused = (themeId) => {
    if (!isFocusing || sorting.themeId !== themeId) {
      dispatch({ type: 'TOGGLE_THEME_FOCUS', isFocused: true, themeId })
      // Additionally, request a comparison between theme and all unassigned questions (sort them by similarity)
      sortUnassignedQuestions(themeId)
    } else {
      dispatch({ type: 'TOGGLE_THEME_FOCUS', isFocused: false, themeId })
      // Remove similarity scores and any other relevant sorting state
      dispatch({ type: 'CLEAR_QUESTION_SORTING', themeId })
    }
  }

  const handleColumnClick = (themeId) => {
    // If there is a focus and this column is not the ones focused, then clear the focus
    if (isFocusing && sorting.themeId !== themeId) {
      dispatch({ type: 'TOGGLE_THEME_FOCUS', isFocused: false, themeId: sorting.themeId })
      dispatch({ type: 'CLEAR_QUESTION_SORTING', themeId: sorting.themeId })
    }
  }

  const handleSuggestThemes = () => {
    // Add presentation flags and empty name field to suggested themes
    const suggestedThemeSlice = suggestedThemes.map(theme => ({...theme, isSuggesting: true, isEditing: true, name: '', themeSet}))
    // Update ".theme" property on questions to include suggested assignments
    let updatedQuestions = initializeQuestions(questions, [...themes, ...suggestedThemeSlice], themeSet.themeSetId)
    // Only update questions relevant to the suggestion
    updatedQuestions = updatedQuestions.filter(question =>
      suggestedThemeSlice.some(theme => question.theme && theme.themeId === question.theme.themeId)
    )
    // Convert to question array to dictionary (object). This simplifies reducer implementation.
    updatedQuestions = keyBy(updatedQuestions, 'questionId')
    // Publish to local state (preview, not persisted to backend before user gives names and confirms)
    dispatch({ type: 'TOGGLE_THEME_SUGGESTION', updatedQuestions, suggestedThemeSlice })
  }

  const questionCount = questions.length
  const recommendation = (
    <Recommendation questionCount={questionCount} />
  )

  return (
    <ComponentRoot>
      <FirstColumn>
        <TitleWrapper>
          <div>
            <Heading.h3 inverted>
              <FormattedMessage id="owner.AnalyticsThemes.title" defaultMessage="Theme Builder" />
              {' '}
              <Icon inverted clickable variant="gear" title="Settings" onClick={onShowSettings} />
            </Heading.h3>
            {lastUpdate && <LastUpdateStatus inverted lastUpdate={lastUpdate} />}
          </div>
        </TitleWrapper>
        <ResultBox
          show={isAlreadyComplete}
          colors={colors}
          themes={themes}
          questionsByTheme={questionsByTheme}
          insightsLink={insightsLink}
          onDownload={onDownload}
        />
        <DescriptionWrapper>
          <Heading variant="heading4" inverted>
            <FormattedMessage
              id="owner.AnalyticsThemes.description"
              defaultMessage="Sort your {count} {count, plural, one {question} other {questions}} into {recommendation}"
              values={{ count: questionCount, recommendation }}
            />
          </Heading>
          <SearchField>
            <Input inverted placeholder="Question search..." value={filter} onChange={handleSearchChange} />
            <Icon inverted variant="search" title="Search" />
            <LoadingIcon inverted variant="sync-alt" hidden={!isFocusing || !sorting.loading} />
            <IconButton size="tiny" secondary inverted hidden={!isFocusing || sorting.loading} onClick={() => sortUnassignedQuestions(sorting.themeId)}>
              <Icon variant="sort-amount-down" />
            </IconButton>
          </SearchField>
        </DescriptionWrapper>
        <Column
          inverted
          isLocked={true}
          theme={unassignedPlaceholderTheme}
          questions={filteredUnassigned}
          unfilteredCount={unassigned.length}
          onCardHovered={handleCardHovered}
          onThemeHovered={handleThemeHovered}
          onCardDrop={handleCardDrop}
          onQuickMove={handleQuickMove}
          onClearSearch={() => setFilter('')}
          yOffset={firstYOffset}
        />
      </FirstColumn>
      <ThemesArea>
        <div>
          {themes.filter(t => t.isCollapsed).map(theme => (
            <ThemeCard fill={colors(theme.themeId)} key={theme.themeId}>
              <Column
                theme={theme}
                questions={questionsByTheme[theme.themeId]}
                onToggleCollapsed={handleToggleCollapsed}
                onCardHovered={handleCardHovered}
                onThemeHovered={handleThemeHovered}
                onCardDrop={handleCardDrop}
              />
            </ThemeCard>
          ))}
        </div>
        <div>
          {themes.filter(t => !t.isCollapsed && !t.isRejected).map(theme => (
            <ThemeCard fill={colors(theme.themeId)} key={theme.themeId} disabled={isThemeDisabled(theme)}>
              <Column
                theme={theme}
                questions={questionsByTheme[theme.themeId]}
                disabled={isThemeDisabled(theme)}
                focusable={themeFocusEnabled}
                onStartEdit={handleStartEditTheme}
                onEdit={handleEditTheme}
                onDone={handleDoneEditTheme}
                onCancel={handleCancelEditTheme}
                onDelete={handleDeleteTheme}
                onCardHovered={handleCardHovered}
                onThemeHovered={handleThemeHovered}
                onCardDrop={handleCardDrop}
                onQuickMove={handleQuickMove}
                onToggleCollapsed={handleToggleCollapsed}
                onSort={handleToggleFocused}
                onClick={handleColumnClick}
                yOffset={themeYOffset}
              />
            </ThemeCard>
          ))}
          <ButtonWrapper>
            <SuggestThemes disabled={!themeFocusEnabled} count={suggestedThemes.length} onClick={handleSuggestThemes} />
            <CreateColumn show={!isAdding} onAdd={handleAddTheme} onThemeHovered={handleThemeHovered} handleColumnFocused={handleColumnClick} />
          </ButtonWrapper>
        </div>
      </ThemesArea>
      <Modals shown={shownModal} onSubmitRating={handleSetRating} onCancel={() => setShownModal(null)} />
    </ComponentRoot>
  )
}


const themeSetPropType = PropTypes.shape({
  themeSetId: PropTypes.string
})

const themePropType = PropTypes.shape({
  themeId: PropTypes.string,
  name: PropTypes.string,
  themeSet: themeSetPropType
})

AnalyticsThemes.propTypes = {
  themeSets: PropTypes.arrayOf(PropTypes.shape({
    themeSetId: PropTypes.string,
    themes: PropTypes.arrayOf(themePropType),
  })),
  questions: PropTypes.arrayOf(PropTypes.shape({
    questionContent: PropTypes.string,
    themes: PropTypes.shape({
      assignments: PropTypes.arrayOf(PropTypes.shape({
        theme: themePropType,
        themeSet: themeSetPropType,
        position: PropTypes.number
      }))
    })
  })),
  yOffset: PropTypes.number,
  themeFocusEnabled: PropTypes.bool,
  learnUrl: PropTypes.string,
  insightsLink: PropTypes.string,
  onUpdateTheme: PropTypes.func,
  onDeleteTheme: PropTypes.func,
  onAssignTheme: PropTypes.func,
  onAddTheme: PropTypes.func,
  onSortQuestions: PropTypes.func,
  onSetRating: PropTypes.func,
  onDownload: PropTypes.func,
  lastUpdate: PropTypes.instanceOf(Date)
}

AnalyticsThemes.defaultProps = {
  yOffset: 0,
  themeFocusEnabled: true,
  onUpdateTheme: () => { },
  onDeleteTheme: () => { },
  onAssignTheme: () => { },
  onAddTheme: () => { },
  onDownload: () => { },
}

export default withTheme(injectIntl(AnalyticsThemes))
