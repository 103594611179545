import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import ContainerDimensions from 'react-container-dimensions'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import * as d3 from 'd3'
import { makeGroupColorScales } from '../../../utils/d3Utils'
import { stringToIntHash } from '../../../utils/stringUtils'
import sortBy from 'lodash/sortBy'

import {
  Title,
  Content,
  Card,
  CardRaw,
  MainCard,
  SubContent,
} from '../AnalyticsLayout/AnalyticsLayout'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Tooltip from '../../common/Tooltip/Tooltip'
import GroupList from '../../common/GroupList/GroupList'
import { WordCloud } from 'data-viz'
import CountCircles from '../../common/CountCircles/CountCircles'

const MainCardLayout = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33%;
    max-width: 450px;
    text-align: center;
  }
  & > :nth-child(2) {
    height: 66%;
    width: 100%;
  }
`

const MediumCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px 30px 0 30px;
  & > :first-child {
    margin-bottom: 25px;
  }
`

const WideDataLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NarrowCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & > :first-child {
    flex: 0 0 80px;
  }
`

const NarrowChartContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

const NarrowListCardLayout = styled.div`
  & > :first-child {
    padding: 30px 30px 20px 30px;
  }
`

function collectGroupCount(wordFrequencies) {
  // Merge individual outlier stats into single summary (one count per group)
  const outliersGroupSummary = {}
  wordFrequencies.forEach(wordFreq => {
    wordFreq.groups.forEach(group => {
      if (!outliersGroupSummary.hasOwnProperty(group.groupId)) {
        outliersGroupSummary[group.groupId] = { ...group }
      } else {
        outliersGroupSummary[group.groupId].count += group.count
      }
    })
  })
  return outliersGroupSummary
}

function makeGroupsCountList(groups, counts) {
  // Attach counts to groups and sort by increasing value
  let groupsWithCount = groups.map(group => {
    if (counts.hasOwnProperty(group.groupId)) {
      return {
        ...group,
        value: counts[group.groupId].count
      }
    }
  })
  groupsWithCount = groupsWithCount.filter(g => g)
  return sortBy(groupsWithCount, g => -g.value)
}

function sumFrequency(wordFrequencies) {
  return wordFrequencies.map(wf => wf.count).reduce((a, b) => a + b, 0)
}

function domainFrequency(wordsFrequencies) {
  const maxSize = d3.max(wordsFrequencies, d => d.count)
  const minSize = d3.min(wordsFrequencies, d => d.count)
  return [minSize, maxSize]
}

export function formatData(groups, wordFrequencies) {
  const domain = domainFrequency(wordFrequencies)

  const stems = wordFrequencies.map(wf => wf.stem).join(',')
  const hash = stringToIntHash(stems)

  const outliers = wordFrequencies.filter(w => w.isOutlier)
  const outlierWords = outliers.map(o => o.topWord)
  const outlierGroupsCounts = collectGroupCount(outliers)
  const outlierGroupsList = makeGroupsCountList(groups, outlierGroupsCounts)
  const totalOutlierCount =  outlierGroupsList.map(g => g.value).reduce((a, b) => a + b, 0)

  const potentialOutliers = wordFrequencies.filter(w => w.isPotentialOutlier)
  const potentialOutliersGroupsCounts = collectGroupCount(potentialOutliers)
  const potentialOutliersSum = sumFrequency(potentialOutliers)

  return {
    domain,
    hash,
    outlierWords,
    outlierGroupsList,
    totalOutlierCount,
    potentialOutliers,
    potentialOutliersGroupsCounts,
    potentialOutliersSum
  }
}

class AnalyticsHighlights extends Component {
  renderPotentialOutliers(groupsCounts, groups, colors) {
    const groupIds = Object.keys(groupsCounts)
    groups = groups.filter(group => groupIds.indexOf(group.groupId) !== -1)
    groups = sortBy(groups, group => -groupsCounts[group.groupId].count)
    const rows = groups.map(group => (
      <GroupList.CountRow
        key={group.groupId}
        colors={colors}
        values={groupId => groupsCounts[groupId].count}
        group={group}
      />
    ))
    return (
      <GroupList maxRows={5}>{rows}</GroupList>
    )
  }

  render() {
    const { wordFrequencies, groups, theme } = this.props
    const colors = makeGroupColorScales(theme, groups.map(n => n.groupId)).primaryColors

    const {
      domain,
      outlierWords,
      outlierGroupsList,
      totalOutlierCount,
      potentialOutliers,
      potentialOutliersGroupsCounts,
      potentialOutliersSum
    } = formatData(groups, wordFrequencies)

    return (
      <Content>
        <MainCard highlighted>
          <MainCardLayout>
            <div>
              <Heading.h2>
                <Title>
                  <span>
                    <Tooltip.Area>
                      <Tooltip.Reference>
                        <Heading variant="heading2" primary>
                          <FormattedMessage
                            id="owner.HighlightsPage.heading.outliers"
                            defaultMessage="{count} prominent {count, plural, one {keyword} other {keywords}}"
                            values={{ count: outlierWords.length }}
                          />
                        </Heading>
                      </Tooltip.Reference>
                      <Tooltip>
                        <Heading.h3>
                          <FormattedMessage defaultMessage="Highlights explanation" id="owner.HighlightsPage.mainTooltip.title" />
                        </Heading.h3>
                        <Typography variant="medium" weight="light">
                          <FormattedMessage defaultMessage="A word is considered a prominent keyword when it deviates significantly from the other words in the number of times it is used in the questions and answers. Similarly, a word is considered a keyword when it shows signs of deviation in the number of times it is used in the questions and answers." id="owner.HighlightsPage.mainTooltip.text" />
                        </Typography>
                      </Tooltip>
                    </Tooltip.Area>
                    <span>&nbsp;</span>
                    <Heading variant="heading2">
                      <FormattedMessage
                        id="owner.HighlightsPage.description.outliers"
                        defaultMessage="{count, plural, one {was} other {were}} used significantly more times than a typical word in your Qvest"
                        values={{ count: outlierWords.length }}
                      />
                    </Heading>
                  </span>
                </Title>
              </Heading.h2>
            </div>
            <WideDataLayout>
              <WordCloud
                width={560}
                height={300}
                words={wordFrequencies}
                highlightedWords={outlierWords}
              />
            </WideDataLayout>
          </MainCardLayout>
        </MainCard>
        <SubContent>
          <CardRaw>
            <MediumCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage
                    id="owner.HighlightsPage.heading.outlierGroups"
                    defaultMessage=" {count} {count, plural, one {group} other {groups}}"
                    values={{ count: outlierGroupsList.length }}
                  />
                </Heading.h3>
                <Typography variant="medium" transparent>
                  <FormattedMessage
                    id="owner.HighlightsPage.description.outlierGroups"
                    defaultMessage="used the prominent keywords {count} times"
                    values={{ count: totalOutlierCount }}
                  />
                </Typography>
              </Title>
              <ContainerDimensions>
                {({ width }) =>
                  <CountCircles
                    colors={colors}
                    groups={outlierGroupsList}
                    width={width}
                  />
                }
              </ContainerDimensions>
            </MediumCardLayout>
          </CardRaw>
          <Card>
            <NarrowCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage
                    id="owner.HighlightsPage.heading.potentialOutliers"
                    defaultMessage=" {count} {count, plural, one {keyword} other {keywords}}"
                    values={{ count: potentialOutliers.length }}
                  />
                </Heading.h3>
                <Typography variant="medium" transparent>
                  <FormattedMessage
                    id="owner.HighlightsPage.description.potentialOutliers"
                    defaultMessage="were used more times than a typical word in your Qvest"
                  />
                </Typography>
              </Title>
              <NarrowChartContainer>
                <WordCloud
                  width={235}
                  height={135}
                  words={potentialOutliers}
                  domain={domain}
                />
              </NarrowChartContainer>
            </NarrowCardLayout>
          </Card>
          <CardRaw>
            <NarrowListCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage
                    id="owner.HighlightsPage.heading.potentialOutlierGroups"
                    defaultMessage=" {count} {count, plural, one {group} other {groups}}"
                    values={{ count: Object.keys(potentialOutliersGroupsCounts).length }}
                  />
                </Heading.h3>
                <Typography variant="medium" transparent>
                  <FormattedMessage
                    id="owner.HighlightsPage.description.potentialOutlierGroups"
                    defaultMessage="used the keywords {count} times"
                    values={{ count: potentialOutliersSum }}
                  />
                </Typography>
              </Title>
              {this.renderPotentialOutliers(potentialOutliersGroupsCounts, groups, colors)}
            </NarrowListCardLayout>
          </CardRaw>
        </SubContent>
      </Content>
    )
  }

  static propTypes = {
    wordFrequencies: PropTypes.arrayOf(PropTypes.shape({
      stem: PropTypes.string,
      topWord: PropTypes.string,
      count: PropTypes.number,
      isOutlier: PropTypes.bool,
      isPotentialOutlier: PropTypes.bool,
      groups: PropTypes.arrayOf(PropTypes.shape({
        groupId: PropTypes.string,
        count: PropTypes.number
      }))
    })),
    groups: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.string,
      name: PropTypes.string
    }))
  }
}

export default withTheme(AnalyticsHighlights)
