import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'
import Card from '../../common/Card/Card'
import Heading from '../../common/Typography/Heading'
import Icon from '../../common/Icon/Icon'
import Button from '../../common/Button/Button'
import TextArea from '../../common/TextArea/TextArea'


const CardWrapper = styled.div`
  width: 624px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 6px;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`


const SubmitButton = ({ loading, disabled, onClick }) => {
  let buttonElement = null
  useEffect(() => !loading && buttonElement.focus(), [])

  let text
  if (loading) {
    text = (<FormattedMessage defaultMessage="Loading..." id="owner.ReplyParticipantModal.button.loading" />)
  } else {
    text = (<FormattedMessage defaultMessage="Suspend participant" id="owner.ReplyParticipantModal.button.submit" />)
  }

  let title
  if (disabled) {
    title = 'Qvest is closed. It is no longer possible to suspend participants.'
  }

  return (
    <Button disabled={loading || disabled} title={title} onClick={onClick} ref={elm => buttonElement = elm}>
      {text}
    </Button>
  )
}

const ReplyParticipantModal = ({ participant, loading, disabled, onSubmit, onClose }) => {
  const emailBoldSpan = (
    <Typography weight="bold">{participant.email}</Typography>
  )
  return (
    <CardWrapper>
      <Card role="alertdialog" aria-modal="true" aria-labelledby="dialog-label" aria-describedby="dialog-desc">
        <Card.Header>
          <Heading as="h3" variant="heading3" id="dialog-label">
            <FormattedMessage id="owner.ReplyParticipantModal.heading" defaultMessage="Email reply received" />
          </Heading>
          <Icon clickable variant="times" onClick={onClose} />
        </Card.Header>
        <Card.Body id="dialog-desc">
          <Typography.p weight="light" id="dialog-desc">
            <FormattedMessage
              id="owner.ReplyParticipantModal.description"
              defaultMessage="Message content in the reply from {email}:"
              values={{ email: emailBoldSpan }}
            />
          </Typography.p>
          <TextArea disabled resize="none" spellcheck="false" rows="6">
            {participant.lastMailReply.text}
          </TextArea>
        </Card.Body>
        <Card.Footer>
          <ButtonGroup>
            <Button outline disabled={loading} onClick={onClose}>
              <FormattedMessage defaultMessage="Ignore" id="owner.ReplyParticipantModal.button.cancel" />
            </Button>
            <SubmitButton disabled={disabled} loading={loading} onClick={() => onSubmit(participant)} />
          </ButtonGroup>
        </Card.Footer>
      </Card>
    </CardWrapper>
  )
}

ReplyParticipantModal.propTypes = {
  participant: PropTypes.shape({
    email: PropTypes.string.isRequired,
    lastMailReply: PropTypes.shape({
      text: PropTypes.string
    })
  }).isRequired,
  learnUrl: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default injectIntl(ReplyParticipantModal)
