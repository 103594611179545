import React from 'react'
import styled, { keyframes } from 'styled-components'

import Typograpgy from '../Typography/Typography'
import Icon from '../Icon/Icon'

const rotate360 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const AnimatedIcon = styled(Icon)`
  animation: ${rotate360} 2s linear infinite;
`

const ComponentRoot = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .8);
  & > *:first-child { margin-right: 8px; }
`

const DimmerLoader = () => (
  <ComponentRoot>
    <AnimatedIcon variant="sync-alt" secondary />
    <Typograpgy variant="heading4" secondary>Loading</Typograpgy>
  </ComponentRoot>
)

export default DimmerLoader
