import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ContainerDimensions from 'react-container-dimensions'
import * as d3 from 'd3'
import range from 'lodash/range'

const ComponentRoot = styled.div`
  display: flex;
  min-height: 100px;
  height: 100%;
  width: 100%;
  & > * {
    width: 50%;
    height: 100%;
  }
  & > *:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const TextBox = styled.div`
  color: ${({ theme }) => theme.default};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  & > *:first-child > span {
    font-family: LL Circular Book Web, sans-serif;
    font-weight: 500;
    font-size: 60px;
    line-height: 50px;
  }
  & > *:first-child > span:last-child {
    font-size: 32px;
  }
  & > *:last-child {
    font-family: Raleway, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
`

const MAX_DIAGRAM_SIZE = 150
const MAX_EDGE_COUNT = 20

export default class NetworkDetailsGraph extends Component {
  renderGraph(width, height) {
    const { value, fill } = this.props

    // set the inner size of the diagram and limit the number of edges
    const size = Math.min(width, height, MAX_DIAGRAM_SIZE)
    const edgeCount = Math.min(value, MAX_EDGE_COUNT)

    let scale = d3.scalePoint()
      .range([0, Math.PI * 2])
      .domain(range(edgeCount + 1))

    // create edges
    const edges = range(edgeCount)
      .map(index => {
        const angle = scale(index)
        const x = size / 2.5 * Math.cos(angle)
        const y = size / 2.5 * Math.sin(angle)
        return (
          <line key={index} x2={x} y2={y} stroke="#777d79" strokeWidth="3" />
        )
      })

    // compose SVG
    return (
      <svg width={width} height={height}>
        <g transform={`translate(${width / 2}, ${height / 2}) rotate(-90)`}>
          {edges}
          <circle key="-1" r={size / 4.5} fill={fill} />
        </g>
      </svg>
    )
  }

  render() {
    const { label, percentage } = this.props
    return (
      <ComponentRoot>
        <div>
          <ContainerDimensions>
            {({ width, height }) => this.renderGraph(width, height)}
          </ContainerDimensions>
        </div>
        <div>
          <TextBox>
            <div>
              <span>{percentage}</span>
              <span>%</span>
            </div>
            {label && <div>{label}</div>}
          </TextBox>
        </div>
      </ComponentRoot>
    )
  }

  static propTypes = {
    value: PropTypes.number,
    label: PropTypes.string,
    percentage: PropTypes.number,
    fill: PropTypes.string,
  }

  static defaultProps = {
    value: 0,
    percentage: 0,
    fill: '#f4bf6c',
  }
}
