import React, { Component } from 'react'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { determineTransition, determineHasAskedAny } from '../../../../utils/questionUtils'
import { markQvestClosedShown, wasQvestClosedShown } from '../../../../utils/localStorageUtils'
import { determineNextWaveAt } from '../../../../utils/qvestUtils'
import Heading from '../../../../components/common/Typography/Heading'
import CenteredLoader from '../../../../components/common/CenteredLoader/CenteredLoader'
import QvestAboutFold from '../../../../components/qvest/QvestAboutFold/QvestAboutFold'
import QvestFooter from '../../../../components/common/QvestFooter/QvestFooter'
import QvestAsk from '../../../../components/qvest/QvestAsk/QvestAsk'
import QvestAnswer from '../../../../components/qvest/QvestAnswer/QvestAnswer'
import QvestJobDone from '../../../../components/qvest/QvestJobDone/QvestJobDone'
import QvestHeader from '../../../../components/qvest/QvestHeader/QvestHeader'
import { sendQuestion, sendAnswer, getOwnQuestions } from '../../../../actions/questionActions'
import { filterOwnQuestions, filterQuestionsById } from '../../../../reducers/questions'
import { filterParticipantsByQvest } from '../../../../reducers/participants'
import { filterQvestsById, isDoneQvestState } from '../../../../reducers/qvests'
import { filterSchedulesByQvestsId } from '../../../../reducers/schedules'
import Modal from '../../../../components/common/Modal/Modal'
import AccessDeniedModal from '../../../../components/common/AccessDeniedModal/AccessDeniedModal'
import { getParticipantsLight } from '../../../../actions/participantActions'


function offScreenX(props) {
  return props.left ? '-100vw' : '100vw'
}

const ComponentRoot = styled.div`
  position: absolute;
  top: 0;
  right: ${({ right }) => right ? '0' : 'auto'};
  left: ${({ left }) => left ? '0' : 'auto'};
  width: calc(100vw - 16px);
  z-index: 2;
  background: ${({ theme }) => theme.two[0]};
  min-height: 100vh;
  padding: 0 25px 50px 25px;
  transform: translateX(${props => (props.animation === 'entering' || props.animation === 'entered') ? '0' : offScreenX(props)});
  transition: transform 600ms;
  transition-delay: ${props => (props.animation === 'entering' || props.animation === 'entered') ? '600ms' : '0s'};
  transition-timing-function: ease-in;

  @media (min-width: 700px) {
    padding: 110px 50px 75px 50px;
  }

  @media (min-width: 1024px) {
    width: 70vw;
  }
`

const Body = styled.div`
  max-width: 850px;
  margin: 40px auto 40px auto;
  & > :not(:last-child) {
    margin-bottom: 40px;
  }
`

const HorizontalRule = styled.hr`
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.one[3]};
  margin: 20px 0 30px 0;
`

const JobDone = styled.div`
  padding-top: 50px;
`

class QuestionPane extends Component {
  renderModal(qvest) {
    if (!qvest || (qvest.state !== 'QVEST_CLOSED') || wasQvestClosedShown()) return null
    return (
      <Modal>
        <AccessDeniedModal variant="QVEST_CLOSED" qvestId={qvest.qvestId} onClick={markQvestClosedShown} />
      </Modal>
    )
  }

  renderTransitionScreen(qvest, schedule, participants, currentQuestion, nextQuestion, redirectToFirstQuestion) {
    const waveAt = determineNextWaveAt(qvest, schedule)
    return (
      <JobDone>
        <QvestJobDone
          qvestId={qvest.qvestId}
          isJam={qvest.isJam}
          nextWaveAt={waveAt}
          currentQuestion={currentQuestion}
          nextQuestion={nextQuestion}
          participants={participants}
          redirectToFirstQuestion={redirectToFirstQuestion}
        />
      </JobDone>
    )
  }

  renderTopic(qvest) {
    return (
      <div>
        <Heading.h1 variant="heading2">{qvest.topic}</Heading.h1>
        <HorizontalRule />
      </div>
    )
  }

  render() {
    const {
      match,
      qvestStore,
      participantStore,
      questionStore,
      scheduleStore,
      animation,
      left,
      right
    } = this.props
    const { qvestId, questionId } = match.params

    const qvest = filterQvestsById(qvestStore, qvestId)
    const question = filterQuestionsById(questionStore, questionId)
    const questions = filterOwnQuestions(questionStore, qvestId)
    const participants = filterParticipantsByQvest(participantStore, qvestId)
    const schedule = filterSchedulesByQvestsId(scheduleStore, qvestId)
    const isDone = isDoneQvestState(qvestStore, qvestId)

    let content, isTransition
    if (qvest && questions && participants && schedule) {
      const nextQuestion = determineTransition(question, questions, qvest.isJam)
      if (question.status === 'EMPTY') {
        // Ask form
        isTransition = false
        content = (
          <QvestAsk
            isJam={qvest.isJam}
            closed={qvest.state === 'QVEST_CLOSED'}
            error={questionStore.error}
            participants={participants}
            groupings={qvest.groupings}
            onRefresh={this.onParticipantRefresh}
            onSubmit={this.onSendQuestion}
          />
        )
      } else if (!isDone && nextQuestion && !determineHasAskedAny(questions)) {
        // Transition screen (leading to first question)
        // (When qvest is running and participant has not asked any questions, we do this hard override)
        isTransition = true
        content = this.renderTransitionScreen(qvest, schedule, participants, question, nextQuestion, true)
      } else if (question.status === 'ASKED') {
        // Answer form
        isTransition = false
        content = (
          <QvestAnswer
            isJam={qvest.isJam}
            closed={qvest.state === 'QVEST_CLOSED'}
            loading={questionStore.isLoading}
            error={questionStore.error}
            questionContent={question.questionContent}
            questionSentAt={question.questionSentAt}
            onSubmit={this.onSendAnswer}
          />
        )
      } else if (question.status === 'ANSWERED') {
        // Question already asked and answered
        // (May occur if participants open old e-mails of questions they already asked)
        isTransition = false
        content = (<Redirect to={`/qvest/${qvestId}/question/${question.questionId}/view`} />)
      } else {
        // Transition screen
        isTransition = true
        content = this.renderTransitionScreen(qvest, schedule, participants, question, nextQuestion)
      }
    } else {
      content = (<CenteredLoader width="50%" height="50vh" />)
    }

    return (
      <ComponentRoot animation={animation} left={left} right={right}>
        <QvestHeader light qvest={qvest} schedule={schedule} />
        <Body>
          {this.renderModal(qvest)}
          {!isTransition ? this.renderTopic(qvest) : null}
          {content}
          <QvestAboutFold qvest={qvest} participants={participants} hide={qvest.isJam || isTransition} />
        </Body>
        <QvestFooter hide={qvest.isJam} />
      </ComponentRoot>
    )
  }

  onParticipantRefresh = () => {
    const { dispatch, match } = this.props
    return dispatch(getParticipantsLight(match.params.qvestId))
  }

  onSendQuestion = (body) => {
    const { dispatch, match } = this.props
    const { qvestId, questionId } = match.params
    return dispatch(sendQuestion(qvestId, questionId, body))
      .then(() => dispatch(getOwnQuestions(qvestId)))
  }

  onSendAnswer = (body) => {
    const { dispatch, match } = this.props
    const { qvestId, questionId } = match.params
    return dispatch(sendAnswer(qvestId, questionId, body))
      .then(() => dispatch(getOwnQuestions(qvestId)))
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    participantStore: state.participants.toJS(),
    questionStore: state.questions.toJS(),
    scheduleStore: state.schedules.toJS()
  }
}

export default connect(mapStateToProps)(QuestionPane)
