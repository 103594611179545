import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '../Typography/Typography'
import { FormattedMessage } from 'react-intl'
import { youTubeUrlToVideoId, vimeoUrlToVideoId } from '../../../utils/videoUtils'

const ComponentRoot = styled.div`
  width: 100%;
  margin: 0 auto;
`

const VideoRatioBox = styled.div`
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;
`

const VideoIFrame = styled.iframe`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border: none;
`

const srcFromUrl = url => {
  // Convert youtube url to embeddable source url
  let videoId = youTubeUrlToVideoId(url)
  if (videoId) {
    return `https://www.youtube-nocookie.com/embed/${videoId}?rel=0&amp;showinfo=0`
  }
  // Convert vimeo url to embeddable source url
  videoId = vimeoUrlToVideoId(url)
  if (videoId) {
    return `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&dnt=1`
  }
  // Unknown url, discard it
  return null
}

const EmbeddedVideo = ({ url }) => {
  if (!url) return null

  const src = srcFromUrl(url)
  if (!src) {
    return (
      <Typography cta weight="bold">
        <FormattedMessage
          id="common.EmbeddedVideo.invalidLink"
          defaultMessage="[Unable to load video. Please ensure that you are using a valid YouTube/Vimeo link]"
        />
      </Typography>
    )
  }

  return (
    <ComponentRoot>
      <VideoRatioBox>
        <VideoIFrame
          src={src}
          frameBorder="0"
          allow="fullscreen; encrypted-media"
          allowFullScreen
        />
      </VideoRatioBox>
    </ComponentRoot>
  )
}

EmbeddedVideo.propTypes = {
  url: PropTypes.string
}

export default EmbeddedVideo
