import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Typography from '../Typography/Typography'
import Heading from '../Typography/Heading'
import { hexaToRgba } from '../../../utils/styleUtils'

const ComponentRoot = styled.div`
  display: flex;
  padding: 5px 12px;
  height: 32px;
  border-radius: 16px;
  background-color: ${({ fill }) => fill};
  & > *:first-child {
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
`

const Badge = ({ value, fill, label }) => (
  <ComponentRoot fill={hexaToRgba(`${fill}22`)}>
    <Heading variant="heading5">{value}</Heading>
    <Typography variant="small" weight="light">{label}</Typography>
  </ComponentRoot>
)

Badge.propTypes = {
  fill: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
}

Badge.defaultProps = {
  fill: 'rgba(0, 0, 0, 0)',
}

export default Badge
