import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

const ComponentRoot = styled.div`
  background-color: rgba(0,0,0,0.25);
  position: ${({ absolute }) => absolute ? 'absolute' : 'fixed'};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`

class Modal extends Component {
  constructor(props) {
    super(props)
    this.modalRoot = document.getElementById('modal')
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this)
    this.handleEscape = this.handleEscape.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleEscape)
    // TODO: find a nicer way to disable backdrop scrolling
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleEscape)
    // TODO: find a nicer way to enable backdrop scrolling
    document.body.style.overflow = 'auto'
  }

  render() {
    // cannot add aria support here, since the type of the modal is unknown at this moment
    return createPortal(
      <ComponentRoot
        onClick={this.handleBackgroundClick}
        ref={node => this.node = node}
        {...this.props}
      >
        {this.props.children}
      </ComponentRoot>,
      this.modalRoot
    )
  }

  handleBackgroundClick(event) {
    const { onBackgroundClick } = this.props
    const isDirectClick = this.node === event.target
    if (isDirectClick && onBackgroundClick) {
      onBackgroundClick()
    }
  }

  handleEscape(event) {
    const { onEscape } = this.props
    const isEscPressed = event.key === 'Escape'
    if (onEscape && isEscPressed) {
      event.stopPropagation()
      onEscape()
    }
  }
}

Modal.propTypes = {
  onBackgroundClick: PropTypes.func,
  onEscape: PropTypes.func
}

export default Modal
