
// Removed "null" or "undefined" fields from object (shallow)
export function removeEmptyFields(obj) {
  const newObj = JSON.parse(JSON.stringify(obj))
  Object.keys(newObj).forEach((key) => (newObj[key] == null) && delete newObj[key])
  return newObj
}

// Pivot object of columns to array of corresponding rows
export function pivotColumns(columns) {
  /*
   Columns is an object of arrays:
   {
     foo: [1311, 41, 159],
     bar: ["cat", 11, 41],
     zzz: [124.31, 55],
     aaa: [12]
   }
   The goal is to pivot this structure:
   [
     {foo: 1311, bar: "cat", zzz: 124.31},
     {foo: 41,   bar: 11},
     {foo: 159,  bar: 41, aaa: 12, zzz: 55}
   ]
  */
  var pivoted = []
  if (columns == null) return pivoted
  Object.keys(columns).forEach(function (key) {
    columns[key].forEach(function (value, i) {
      if (!pivoted[i]) pivoted[i] = {}
      pivoted[i][key] = value
    })
  })
  return pivoted
}
