import React, { Fragment } from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import styled, { withTheme } from 'styled-components'

import Card from '../../common/Card/Card'
import Typography from '../../common/Typography/Typography'
import ContainerDimensions from 'react-container-dimensions'
import Table from '../../common/Table/Table'
import { BarChart, Legend } from 'data-viz'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'


const Row = styled.tr`
  font-weight: ${({ isTotal }) => isTotal ? '700' : '500'};
`

const viewLabel = defineMessages({
  QVEST_PAGE: { defaultMessage: 'Qvest page', id: 'owner.VisitorsCard.viewLabel.QVEST_PAGE' },
  QUESTIONS: { defaultMessage: 'Questions', id: 'owner.VisitorsCard.viewLabel.FIRST_QUESTION' },
  OUTPUT: { defaultMessage: 'Output', id: 'owner.VisitorsCard.viewLabel.OUTPUT' },
})

// Convert decimal to percentage and round to 2 decimal precision
const formatPercentage = (value) => Math.round(value * 10000) / 100

const formatBarChartData = (data, theme, intl) => data.map((view, i) => ({
  id: view.name,
  label: intl.formatMessage(viewLabel[view.name]),
  value: formatPercentage(view.percentage),
  fill: theme.one[i]
}))

const DetailsTable = injectIntl(({ data, isLoading, intl }) => {
  if (!data) return
  return (
    <Table isLoading={isLoading}>
      <thead>
        <tr>
          <th><FormattedMessage defaultMessage="View" id="owner.VisitorsCard.DetailsTable.header.label" /></th>
          <th><FormattedMessage defaultMessage="Unique visitors" id="owner.VisitorsCard.DetailsTable.header.count" /></th>
          <th><FormattedMessage defaultMessage="Percentage" id="owner.VisitorsCard.DetailsTable.header.percentage" /></th>
        </tr>
      </thead>
      <tbody>
        {data.map(view => (
          <Row key={view.id}>
            <td>{intl.formatMessage(viewLabel[view.name])}</td>
            <td>{view.count}</td>
            <td>{formatPercentage(view.percentage)} %</td>
          </Row>
        ))}
      </tbody>
    </Table>
  )
})

const NoData = () => (
  <Card.Body>
    <Typography secondary variant="medium" weight="light">
      <FormattedMessage
        defaultMessage="No visitor data was collected. Check back after the Qvest has started."
        id="owner.VisitorsCard.NoData"
      />
    </Typography>
  </Card.Body>
)

const Loading = () => (
  <Card.Body>
    <CenteredLoader width="100%" height="200px" size="large" />
  </Card.Body>
)

const Content = withTheme(injectIntl(({ data, loading, theme, intl }) => {
  if (!data && loading) return <Loading />
  if (!data) return (<NoData />)
  const formattedData = formatBarChartData(data, theme, intl)
  return (
    <Fragment>
      <Card.Body>
        <Typography secondary variant="medium" weight="light">
          <FormattedMessage
            defaultMessage="Explore the percentage of participants who visited the Qvest"
            id="owner.VisitorsCard.Content.description"
          />
        </Typography>
      </Card.Body>
      <Card.ChartBox>
        <div>
          <ContainerDimensions>
            {({ width }) => <BarChart data={formattedData} width={width} />}
          </ContainerDimensions>
        </div>
        <Legend horizontal data={formattedData} />
      </Card.ChartBox>
      {<DetailsTable data={data} />}
    </Fragment>
  )
}))

const VisitorsCard = ({ data, loading }) => {
  return (
    <Card>
      <Card.Header>
        <Typography variant="heading3">Visitors</Typography>
      </Card.Header>
      <Content data={data} loading={loading} />
    </Card>
  )
}

export default withTheme(injectIntl(VisitorsCard))
