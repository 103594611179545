import * as api from './apiClient'
import { raiseUnexpectedErrorNotification } from './notificationActions'

function parseQvest(qvest) {
  const parsedQvest = Object.assign({}, qvest)
  // formatting date fields
  if (qvest.schedule && qvest.schedule.openAt) {
    parsedQvest.schedule = {
      inviteAt: new Date(qvest.schedule.inviteAt),
      openAt: new Date(qvest.schedule.openAt),
      closeAt: new Date(qvest.schedule.closeAt),
    }
  }
  // backward compatibility for organizations
  // TODO: make it work in all places with the org subfield
  if (qvest.organization && qvest.organization.organizationId) {
    parsedQvest.organizationId = qvest.organization.organizationId
  }
  if (qvest.organization && qvest.organization.name) {
    parsedQvest.organizationName = qvest.organization.name
  }
  return parsedQvest
}

export const getQvest = qvestId => dispatch => {
  dispatch({ type: 'GET_QVEST_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        query q($qvestId: String!) {
          qvest(id: $qvestId) {
            qvestId
            name
            language
            topic
            keywords
            state
            headline
            aboutTextSource
            videoUrl
            hasInvite
            inviteSubject
            inviteTextSource
            hasWaves
            hasCalendarInvite
            isJam
            isArchived
            isDummy
            contactInfo {
              name
              email
              senderName
              senderTitle
            }
            video {
              url
            }
            groupings {
              groupingId
              name
              groups {
                groupId
                name
              }
            }
            schedule {
              inviteAt
              openAt
              closeAt
            }
            summary {
              participants
              questions
              answers
              activeParticipants
            }
            organization {
              organizationId
              name
              logo
            }
            validity {
              general
              items {
                type
                name
              }
            }
          }
        }
      `,
      variables: { qvestId }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const qvest = parseQvest(result.data.qvest)
      dispatch({ type: 'GET_QVEST_SUCCESS', qvest })
      return qvest
    })
    .catch(errors => {
      dispatch({ type: 'GET_QVEST_FAILURE', errors })
    })
}

export const getQvestsByOrganization = (organizationId, isArchived = false) => dispatch => {
  dispatch({ type: 'GET_ORGANIZATION_QVESTS_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        query q($organizationId: String!, $isArchived: Boolean) {
          qvests(organizationId: $organizationId, isArchived: $isArchived) {
            qvestId
            name
            language
            topic
            keywords
            state
            headline
            aboutTextSource
            videoUrl
            hasInvite
            inviteSubject
            inviteTextSource
            hasWaves
            hasCalendarInvite
            isArchived
            isDummy
            contactInfo {
              name
              email
            }
            groupings {
              groupingId
              name
              groups {
                groupId
                name
              }
            }
            schedule {
              inviteAt
              openAt
              closeAt
            }
            summary {
              participants
              questions
              answers
              activeParticipants
            }
            organization {
              organizationId
              name
            }
          }
        }
      `,
      variables: { organizationId, isArchived }
    }
  }
  api.invoke('/api/graphql', options)
    .then(result => {
      const qvests = result.data.qvests
      let data = {}
      qvests.forEach(qvest => {
        qvest = parseQvest(qvest)
        data[qvest.qvestId] = qvest
      })
      const ids = qvests.map(q => q.qvestId)
      dispatch({ type: 'GET_ORGANIZATION_QVESTS_SUCCESS', organizationId, data, ids })
    })
    .catch(errors => {
      dispatch({ type: 'GET_ORGANIZATION_QVESTS_FAILURE', errors })
    })
}

export const getQvestVideo = qvestId => dispatch => {
  dispatch({ type: 'GET_QVEST_VIDEO_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        query q($qvestId: String!) {
          qvest(id: $qvestId) {
            qvestId
            video {
              url
              uploadUrl
              uploadStatus
              transcodeStatus
              thumbnail
            }
          }
        }
      `,
      variables: { qvestId }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const qvest = parseQvest(result.data.qvest)
      dispatch({ type: 'GET_QVEST_VIDEO_SUCCESS', qvest })
      return qvest
    })
    .catch(errors => {
      dispatch({ type: 'GET_QVEST_VIDEO_FAILURE', errors })
    })
}

export const createQvest = ({ organizationId, name, language }) => dispatch => {
  dispatch({ type: 'CREATE_QVEST_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        mutation m($organizationId:String!, $name:String!, $language:String) {
          createQvest(qvest: { organizationId: $organizationId, name: $name, language:$language }) {
            qvestId
            name
            language
            topic
            keywords
            state
            headline
            aboutTextSource
            videoUrl
            hasInvite
            inviteSubject
            inviteTextSource
            hasWaves
            hasCalendarInvite
            isArchived
            isDummy
            contactInfo {
              name
              email
              senderName
              senderTitle
            }
            organization {
              organizationId
              name
            }
          }
        }
      `,
      variables: { organizationId, name, language }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const qvest = parseQvest(result.data.createQvest)
      dispatch({ type: 'CREATE_QVEST_SUCCESS', qvest })
      return qvest
    })
    .catch(errors => {
      dispatch(raiseUnexpectedErrorNotification())
      dispatch({ type: 'CREATE_QVEST_FAILURE', errors })
    })
}

export const updateQvest = (qvestId, patch) => dispatch => {
  dispatch({ type: 'UPDATE_QVEST_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          mutation m($qvestId:String!, $patch:QvestUpdate!) {
            updateQvest(qvestId: $qvestId, patch: $patch) {
              qvestId
              name
              language
              topic
              keywords
              state
              headline
              aboutTextSource
              videoUrl
              hasInvite
              inviteSubject
              inviteTextSource
              hasWaves
              hasCalendarInvite
              isArchived
              isDummy
              contactInfo {
                name
                email
                senderName
                senderTitle
              }
              validity {
                general
                items {
                  type
                  name
                }
              }
            }
          }
        `,
      variables: { qvestId, patch }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const qvest = parseQvest(result.data.updateQvest)
      dispatch({ type: 'UPDATE_QVEST_SUCCESS', qvest })
    })
    .catch(errors => {
      dispatch(raiseUnexpectedErrorNotification())
      dispatch({ type: 'UPDATE_QVEST_FAILURE', errors })
    })
}

export const saveQvest = (qvestId, fields) => (dispatch, getState) => {
  const unsaved = getState().qvests.get('unsaved').toJS()
  const patch = {}
  fields.forEach(field => patch[field] = unsaved[field])
  return dispatch(updateQvest(qvestId, patch))
}

export const showCreateQvest = (show = true) => dispatch => {
  dispatch({ type: 'SHOW_CREATE_QVEST', show })
}

export function changeFilter(filter) {
  return { type: 'CHANGE_QVEST_FILTER', filter }
}

export function changeUnsavedQvest(patch) {
  return { type: 'CHANGE_UNSAVED_QVEST', patch }
}

export const getQvestReportInfo = (qvestId) => dispatch => {
  dispatch({ type: 'GET_QVEST_REPORT_INFO_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        query q($qvestId: String!) {
          qvest(id: $qvestId) {
            qvestId
            report {
              assets {
                format
                url
              }
            }
          }
        }
      `,
      variables: { qvestId }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const qvest = parseQvest(result.data.qvest)
      dispatch({ type: 'GET_QVEST_REPORT_INFO_SUCCESS', qvest })
      return qvest
    })
    .catch(errors => {
      dispatch({ type: 'GET_QVEST_REPORT_INFO_FAILURE', errors })
      throw errors
    })
}

export const clearQvestReport = (qvestId) => dispatch => {
  dispatch({ type: 'CLEAR_QVEST_REPORT_INFO', qvestId })
  const options = {
    method: 'POST',
    body: {
      query: `
        mutation q($qvestId: String!) {
          clearReportCache(qvestId: $qvestId)
        }
      `,
      variables: { qvestId }
    }
  }
  return api.invoke('/api/graphql', options)
    .catch(errors => {
      throw errors
    })
}

export const initiateVideoUpload = (qvestId, size) => dispatch => {
  dispatch({ type: 'INITIATE_VIDEO_UPLOAD_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        mutation m($qvestId:String!, $size:Int!) {
          uploadVideo(qvestId: $qvestId, size: $size) {
            url
            uploadUrl
            uploadStatus
            transcodeStatus
          }
        }
      `,
      variables: { qvestId, size }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const video = result.data.uploadVideo
      const videoUrl = result.data.uploadVideo.url
      const qvest = { qvestId, videoUrl, video }
      dispatch({ type: 'INITIATE_VIDEO_UPLOAD_SUCCESS', qvest })
      return video
    })
    .catch(errors => {
      dispatch({ type: 'INITIATE_VIDEO_UPLOAD_FAILURE', errors })
      throw errors
    })

}

export const removeVideo = (qvestId) => dispatch => {
  dispatch({ type: 'REMOVE_QVEST_VIDEO_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        mutation m($qvestId:String!) {
          removeVideo(qvestId: $qvestId)
        }
      `,
      variables: { qvestId }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(() => {
      const qvest = { qvestId, videoUrl: null }
      dispatch({ type: 'REMOVE_QVEST_VIDEO_SUCCESS', qvest })
    })
    .catch(errors => {
      dispatch({ type: 'REMOVE_QVEST_VIDEO_FAILURE', errors })
      throw errors
    })

}

export const orderReport = (qvestIds, format) => dispatch => {
  dispatch({ type: 'ORDER_QVEST_REPORT_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        mutation m($qvestIds:[String]!, $format:String!) {
          orderReport(qvestIds: $qvestIds, format: $format)
        }
      `,
      variables: { qvestIds, format }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(() => {
      dispatch({ type: 'ORDER_QVEST_REPORT_SUCCESS' })
    })
    .catch(errors => {
      dispatch({ type: 'ORDER_QVEST_REPORT_FAILURE', errors })
      throw errors
    })
}
