import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { areaRelativeRadiusScale } from '../../../utils/geometryUtils'


const ComponentRoot = styled.div`
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
  height: 60px;
  
  & path {
    fill: ${({ theme }) => theme.main.primary};
  }
  
  & circle {
    stroke: ${({ theme }) => theme.main.primary};
    fill: none;
  }
  
  &:hover path {
    fill: ${({ theme }) => theme.tertiary};
  }
  
  &:hover circle {
    stroke: ${({ theme }) => theme.main.secondary};
    fill: ${({ theme }) => theme.main.secondary};
  }
`

const Outline = styled.circle`
  stroke-width: 3px;
  fill: none;
`
const CenteredLogo = styled.svg`
  position: absolute;
  top: 20px;
  left: 20px;
`


const AddGroupButton = ({ onClick }) => (
  <ComponentRoot onClick={() => onClick && onClick()}>
    <svg height={60} width={60}>
      <Outline
        r={areaRelativeRadiusScale(0, 10, 0, 30)(4)}
        cx={30}
        cy={30}
      />
    </svg>
    <CenteredLogo width={20} height={20} viewBox="0 0 448 512">
      <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
    </CenteredLogo>
  </ComponentRoot>
)

AddGroupButton.propTypes = {
  value: PropTypes.number
}

export default AddGroupButton
