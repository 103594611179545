import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Icon from '../../common/Icon/Icon'
import RecipientList from '../RecipientList/RecipientList'
import SearchBox from './SearchBox'
import FilterDropdown from '../../common/FilterDropdown/FilterDropdown'


const ComponentRoot = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative;
  height: 100vh;
  width: 100vw;
  @media (min-width: 725px) {
    width: 675px;
    height: 700px;
    border-radius: 6px;
  }
`

const TitleBar = styled.div`
  height: 80px;
  display: flex;
  padding: 80px 30px 30px 30px;
  align-items: center;
  /* align the second piece of text to the search box */
  & > :first-child { width: ${1024 * .25 + 10}px; }
  @media (min-width: 725px) {
    padding: 0 30px;
  }
`

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 70px;
  right: 25px;
  font-size: 20px;
  @media (min-width: 725px) {
    top: 25px;
  }
`

const Pick = styled.div`
  position: relative;
  flex: 1;
  max-width: 100%;
  & > :last-child {
    width: calc(100% - 30px);
    padding-top: 70px;
    padding-left: 30px;
  }
  @media (max-width: 725px) {
    width: 100%;

    & > :last-child {
      width: calc(100% - 10px);
      padding-left: 10px;
    }
  }
`

const Body = styled.div`
  display: flex;
  height: calc(100% - 110px);
  & > * { height: 100%; }
`

const SearchBoxWrapper = styled.div`
  display: flex;
  position: absolute;
  width: calc(100% - 46px);
  padding: 20px 0 20px 40px;
  z-index: 110;
  background-color: ${({ theme }) => theme.two[0]};

  & > :nth-child(1) {
    flex: 1;
    margin-right: 20px;
  }

  & > :nth-child(2) {
    flex: 0 0 120px;
    padding: 7px;
  }

  @media (max-width: 725px) {
    padding: 20px 0 20px 20px;
    width: calc(100% - 25px);
  }
`

const messages = defineMessages({
  showAll: { defaultMessage: 'Show all', id: 'RecipientSelector.filter.showAll' }
})

function isConnectedTo(recipient, groupId) {
  return recipient.groupingConnections &&
    (recipient.groupingConnections.length > 0) &&
    (recipient.groupingConnections[0].groupId === groupId)
}

class RecipientSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFilter: null,
      recipientsDisplayed: props.recipients,
    }
  }

  handleSearch = (searchPhrase) => {
    this.setState({ searchPhrase })
  }

  handleFilter = (selectedFilter) => {
    this.setState({ selectedFilter })
  }

  render() {
    const { toggleModal, groups, onSelect, avatarColors, isJam, includeSelf, intl } = this.props
    const { selectedFilter, searchPhrase } = this.state

    let recipientsDisplayed = this.props.recipients
    if (!includeSelf) {
      recipientsDisplayed = this.props.recipients.filter(r => !r.isSelf)
    }

    if (selectedFilter) {
      recipientsDisplayed = recipientsDisplayed.filter(r => isConnectedTo(r, selectedFilter))
    }

    if (searchPhrase) {
      // 'i' is case insensitive flag
      const regex = new RegExp(`${searchPhrase}`, 'i')
      recipientsDisplayed = recipientsDisplayed.filter(recipient =>
        (recipient.name && recipient.name.match(regex)) ||
        (recipient.groupingConnections && recipient.groupingConnections[0].groupName.match(regex)))
    }

    const hasGroups = (groups != null && groups.length > 0)
    const showAllLabel = intl.formatMessage(messages.showAll)
    let filterOptions
    if (hasGroups) {
      filterOptions = groups.map(group => ({
        label: group.name,
        value: group.groupId
      }))
      filterOptions = [{ label: showAllLabel, value: null }].concat(filterOptions)
    }

    return (
      <ComponentRoot>
        <TitleBar>
          <Typography variant="heading3">
            {isJam ? <FormattedMessage id="RecipientSelector.title.jam" defaultMessage="Players" /> :
              <FormattedMessage id="RecipientSelector.title" defaultMessage="Participants" />}
          </Typography>
          <CloseIcon clickable variant="times" onClick={toggleModal} />
        </TitleBar>
        <Body>
          <Pick>
            <SearchBoxWrapper>
              <SearchBox onChange={this.handleSearch} />
              <FilterDropdown
                options={filterOptions}
                value={selectedFilter}
                onChange={this.handleFilter}
              />
            </SearchBoxWrapper>
            <RecipientList
              showGroups={hasGroups}
              disableBusy={isJam}
              colors={avatarColors}
              recipients={recipientsDisplayed}
              onClick={onSelect}
            />
          </Pick>
        </Body>
      </ComponentRoot>
    )
  }
}

RecipientSelector.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  recipients: PropTypes.array,
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string,
    name: PropTypes.string
  })),
  includeSelf: PropTypes.bool,
  isJam: PropTypes.bool
}

RecipientSelector.defaultProps = {
  recipients: [],
  includeSelf: false
}

export default injectIntl(RecipientSelector)
