import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Input from '../../../common/Input/Input'
import Dropdown from '../../../common/Dropdown/Dropdown'
import Typography from '../../../common/Typography/Typography'
import EditableSpan from '../../../common/EditableSpan/EditableSpan'
import CenteredLoader from '../../../common/CenteredLoader/CenteredLoader'
import { isWhitespace } from '../../../../utils/stringUtils'

// https://github.com/kevalbhatt/timezone-picker/blob/master/src/timezones.json
import timeZones from './timezones.json'
// https://github.com/umpirsky/locale-list/blob/master/data/en_US/locales.json
import locales from './locales.json'

const SettingBlock = styled.div`
  & > * { display: block; }
  & + & { margin-top: 10px; }
  & > :last-child { width: calc(100% - 20px); }
`

class AccountForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unsavedFullName: null,
      unsavedEmail: null,
      unsavedPhone: null,
      unsavedPassword: null,
      unsavedLocale: null,
      unsavedTimeZone: null,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If new props not an error, assume things are saved and clear unsaved state
    // TODO: Find a nicer way of doing this
    if (!nextProps.error && !nextProps.isLoading) {
      this.setState({
        unsavedFullName: null,
        unsavedEmail: null,
        unsavedPassword: null,
        unsavedPhone: null,
        unsavedLocale: null,
        unsavedTimeZone: null,
      })
    }
    return null
  }

  onStartEditFullName = () => {
    const { user } = this.props
    this.setState({
      unsavedFullName: user.fullName,
      unsavedEmail: null,
      unsavedPassword: null,
      unsavedPhone: null,
      unsavedLocale: null,
      unsavedTimeZone: null,
    })
  }

  onCancelEditFullName = () => {
    this.setState({ unsavedFullName: null })
  }

  onStartEditEmail = () => {
    const { user } = this.props
    this.setState({
      unsavedFullName: null,
      unsavedEmail: user.email,
      unsavedPassword: null,
      unsavedPhone: null,
      unsavedLocale: null,
      unsavedTimeZone: null,
    })
  }

  onCancelEditEmail = () => {
    this.setState({ unsavedEmail: null })
  }

  onStartEditPassword = () => {
    this.setState({
      unsavedFullName: null,
      unsavedEmail: null,
      unsavedPassword: '',
      unsavedPhone: null,
      unsavedLocale: null,
      unsavedTimeZone: null,
    })
  }

  onCancelEditPassword = () => {
    this.setState({ unsavedPassword: null })
  }

  onStartEditPhone = () => {
    const { user } = this.props
    this.setState({
      unsavedFullName: null,
      unsavedEmail: null,
      unsavedPassword: null,
      unsavedPhone: user.phone || '',
      unsavedLocale: null,
      unsavedTimeZone: null,
    })
  }

  onCancelEditPhone = () => {
    this.setState({ unsavedPhone: null })
  }

  onFullNameChange = (event) => {
    this.setState({ unsavedFullName: event.target.value })
  }

  onEmailChange = (event) => {
    this.setState({ unsavedEmail: event.target.value })
  }

  onPasswordChange = (event) => {
    this.setState({ unsavedPassword: event.target.value })
  }

  onPhoneChange = (event) => {
    this.setState({ unsavedPhone: event.target.value })
  }

  onFullNameSave = () => {
    this.onUserSave({ fullName: this.state.unsavedFullName })
  }

  onEmailSave = () => {
    this.onUserSave({ email: this.state.unsavedEmail })
  }

  onPasswordSave = () => {
    this.onUserSave({ password: this.state.unsavedPassword })
  }

  handleLocaleSave = (selected) => {
    if (selected === null) return null
    this.setState({ unsavedLocale: selected.value }, () => {
      this.onUserSave({ locale: selected.value })
    })
  }

  handleTimeZoneSave = (selected) => {
    if (selected === null) return null
    this.setState({ unsavedTimeZone: selected.value }, () => {
      this.onUserSave({ timeZone: selected.value })
    })
  }

  onPhoneSave = () => {
    const { unsavedPhone } = this.state
    if (isWhitespace(unsavedPhone)) {
      this.setState({ unsavedPhone: null })
    } else {
      this.onUserSave({ phone: unsavedPhone })
    }
  }

  onUserSave = (patch) => {
    const { onUserSave } = this.props
    onUserSave(patch)
  }

  renderLocaleSelector() {
    const { user } = this.props
    const { unsavedLocale } = this.state
    const localeOptions = locales.map(({ code, name }) => ({
      text: name,
      value: code,
    }))
    const localePlaceholder = (
      <Typography variant="medium">
        <FormattedMessage
          id="owner.AccountForm.localePlaceholder"
          defaultMessage="Select a locale"
        />
      </Typography>
    )
    const value = unsavedLocale || user.locale || null
    return (
      <SettingBlock>
        <Typography weight="bold" paragraph>
          <FormattedMessage id="owner.AccountForm.locale" defaultMessage="Locale" />
        </Typography>
        <Dropdown
          value={value}
          options={localeOptions}
          placeholder={{ node: localePlaceholder }}
          onChange={this.handleLocaleSave}
        />
      </SettingBlock>
    )
  }

  renderTimeZoneSelector() {
    const { user } = this.props
    const { unsavedTimeZone } = this.state
    const timeZoneOptions = timeZones.map(({ name }) => ({
      text: name,
      value: name,
    }))
    const timeZonePlaceholder = (
      <Typography variant="medium">
        <FormattedMessage
          id="owner.AccountForm.timeZonePlaceholder"
          defaultMessage="Select a time zone"
        />
      </Typography>
    )
    const value = unsavedTimeZone || user.timeZone || null
    return (
      <SettingBlock>
        <Typography weight="bold" paragraph>
          <FormattedMessage id="owner.AccountForm.timeZome" defaultMessage="Time zone" />
        </Typography>
        <Dropdown
          value={value}
          options={timeZoneOptions}
          placeholder={{ node: timeZonePlaceholder }}
          onChange={this.handleTimeZoneSave}
        />
      </SettingBlock>
    )
  }

  render() {
    const { user, isLoading } = this.props
    const { unsavedFullName, unsavedEmail, unsavedPassword, unsavedPhone } = this.state

    if (isLoading) return <CenteredLoader />
    return (
      <Fragment>
        <SettingBlock>
          <Typography weight="bold" paragraph>
            <FormattedMessage id="owner.AccountForm.name" defaultMessage="Full name" />
          </Typography>
          <EditableSpan
            isEditing={unsavedFullName != null}
            onStartEdit={this.onStartEditFullName}
            onDone={this.onFullNameSave}
            onCancel={this.onCancelEditFullName}
          >
            <EditableSpan.View>
              <Typography variant="small">{user.fullName}</Typography>
            </EditableSpan.View>
            <EditableSpan.Edit>
              <Input autoFocus value={unsavedFullName} onChange={this.onFullNameChange} />
            </EditableSpan.Edit>
          </EditableSpan>
        </SettingBlock>
        <SettingBlock>
          <Typography weight="bold" paragraph>
            <FormattedMessage id="owner.AccountForm.email" defaultMessage="Email" />
          </Typography>
          <EditableSpan
            isEditing={unsavedEmail != null}
            onStartEdit={this.onStartEditEmail}
            onDone={this.onEmailSave}
            onCancel={this.onCancelEditEmail}
          >
            <EditableSpan.View>
              <Typography variant="small">{user.email}</Typography>
            </EditableSpan.View>
            <EditableSpan.Edit>
              <Input autoFocus value={unsavedEmail} onChange={this.onEmailChange} />
            </EditableSpan.Edit>
          </EditableSpan>
        </SettingBlock>
        <SettingBlock>
          <Typography weight="bold" paragraph>
            <FormattedMessage id="owner.AccountForm.phone" defaultMessage="Phone number" />
          </Typography>
          <EditableSpan
            isEditing={unsavedPhone != null}
            onStartEdit={this.onStartEditPhone}
            onDone={this.onPhoneSave}
            onCancel={this.onCancelEditPhone}
          >
            <EditableSpan.View>
              <Typography variant="small">
                {user.phone
                  ? user.phone
                  : <FormattedMessage id="owner.AccountForm.phonePlaceholder" defaultMessage="Not provided" />}
              </Typography>
            </EditableSpan.View>
            <EditableSpan.Edit>
              <Input autoFocus value={unsavedPhone} onChange={this.onPhoneChange} type="tel" />
            </EditableSpan.Edit>
          </EditableSpan>
        </SettingBlock>
        <SettingBlock>
          <Typography weight="bold" paragraph>
            <FormattedMessage id="owner.AccountForm.password" defaultMessage="Password" />
          </Typography>
          <EditableSpan
            isEditing={unsavedPassword != null}
            onStartEdit={this.onStartEditPassword}
            onDone={this.onPasswordSave}
            onCancel={this.onCancelEditPassword}
          >
            <EditableSpan.View>
              <Typography variant="small">********</Typography>
            </EditableSpan.View>
            <EditableSpan.Edit>
              <Input autoFocus type="password" value={unsavedPassword} onChange={this.onPasswordChange} />
            </EditableSpan.Edit>
          </EditableSpan>
        </SettingBlock>
        {this.renderLocaleSelector()}
        {this.renderTimeZoneSelector()}
      </Fragment>
    )
  }
}

AccountForm.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
  }),
  onUserSave: PropTypes.func,
}

AccountForm.defaultProps = {
  onUserSave: () => { },
}


export default AccountForm
