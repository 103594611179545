import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'

const ComponentRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  & > * { margin-bottom: 30px; }
`

const SummaryItem = styled.div`
  & > * { display: block; }
`

const Summary = ({ summaryStats }) => {
  if(!summaryStats) {
    summaryStats = {
      questions: 0,
      answers: 0,
      participants: 0
    }
  }

  const { questions, answers, participants } = summaryStats

  return (
    <ComponentRoot>
      <SummaryItem>
        <Typography variant="caption">
          <FormattedMessage id="result.Summary.countQuestionsLabel" defaultMessage="Questions" />
        </Typography>
        <Heading variant="display2">{questions}</Heading>
      </SummaryItem>
      <SummaryItem>
        <Typography variant="caption">
          <FormattedMessage id="result.Summary.countAnswersLabel" defaultMessage="Answers" />
        </Typography>
        <Heading variant="display2">{answers}</Heading>
      </SummaryItem>
      <SummaryItem>
        <Typography variant="caption">
          <FormattedMessage id="result.Summary.countParticipantsLabel" defaultMessage="Participants" />
        </Typography>
        <Heading variant="display2">{participants}</Heading>
      </SummaryItem>
    </ComponentRoot>
  )
}

Summary.propTypes = {
  summaryStats: PropTypes.shape({
    questions: PropTypes.number.isRequired,
    answers: PropTypes.number.isRequired,
    participants: PropTypes.number.isRequired
  }),
}

export default Summary
