import React, { Component, Fragment } from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Card from '../../common/Card/Card'
import Icon from '../../common/Icon/Icon'
import Input from '../../common/Input/Input'
import Button from '../../common/Button/Button'
import Typography from '../../common/Typography/Typography'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'

const ComponentRoot = styled.div`
  width: 100%;
  max-width: 500px;
  ${Input} { margin-top: 20px; }
`

const SuccessWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & > *:first-child { margin-bottom: 20px; }
`

const Footer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  & > *:first-child { margin-left: auto; }
`

const verdictMessages = defineMessages({
  NOT_FOUND: { defaultMessage: 'Only participants of this Qvest has access.', id: 'EmailLoginCard.verdict.NOT_FOUND' },
  EXPIRED: { defaultMessage: 'Your login to this Qvest has expired.', id: 'EmailLoginCard.verdict.EXPIRED' },
  MALFORMED_TOKEN: { defaultMessage: 'An unexpected error occurred. We have made a note of the issue and will look into it.', id: 'EmailLoginCard.verdict.MALFORMED_TOKEN' }
})

const descriptionMessages = defineMessages({
  NOT_FOUND: { defaultMessage: 'To gain access, follow a link in an e-mail message you received previously or enter your e-mail address below to receive a new message:', id: 'EmailLoginCard.description.NOT_FOUND' },
  EXPIRED: { defaultMessage: 'For security reasons, you are logged out after a period of inactivity. To regain access, follow a link in an e-mail message you received previously or enter your e-mail address below to receive a new message:', id: 'EmailLoginCard.description.EXPIRED' },
  MALFORMED_TOKEN: { defaultMessage: 'Please try again by entering your e-mail address below and requesting a new access:', id: 'EmailLoginCard.description.MALFORMED_TOKEN' }
})

class EmailLoginCard extends Component {
  constructor(props) {
    super(props)
    this.state = { email: '' }

    this.onEmailChange = this.onEmailChange.bind(this)
    this.onInputKeyPress = this.onInputKeyPress.bind(this)
    this.onSendClick = this.onSendClick.bind(this)
  }

  renderError() {
    if (!this.props.error) return null
    return (
      <Card.Message error>
        <Typography cta tertiary variant="medium">
          <FormattedMessage defaultMessage="We're sorry - an unexpected error occurred." id="EmailLoginCard.message.error" />
        </Typography>
      </Card.Message>
    )
  }

  renderLogin() {
    const { status, intl } = this.props
    const { email } = this.state
    return (
      <Fragment>
        <Typography as="p" weight="light">{intl.formatMessage(verdictMessages[status])}</Typography>
        <Typography weight="light">{intl.formatMessage(descriptionMessages[status])}</Typography>
        <Input
          placeholder="Email address"
          value={email}
          onChange={this.onEmailChange}
          onKeyPress={this.onInputKeyPress}
        />
        <Footer>
          <Button disabled={!email || this.props.isLoading} onClick={this.onSendClick}>
            <FormattedMessage defaultMessage="Log in" id="EmailLoginCard.submit" />
          </Button>
        </Footer>
      </Fragment>
    )
  }

  renderSuccess() {
    return (
      <SuccessWrapper>
        <Icon variant="paper-plane" size="medium" secondary />
        <Typography>
          <FormattedMessage defaultMessage="We have emailed you a link which you can use to access the Qvest." id="EmailLoginCard.success" />
        </Typography>
      </SuccessWrapper>
    )
  }

  render() {
    const { wasSent, isLoading } = this.props
    return (
      <ComponentRoot>
        <Card>
          <Card.Header>
            <Typography variant="heading3">{wasSent ? 'Email sent' : 'Login'}</Typography>
            {isLoading && <CenteredLoader height="auto" width="auto" />}
          </Card.Header>
          {this.renderError()}
          <Card.Body>
            {wasSent ? this.renderSuccess() : this.renderLogin()}
          </Card.Body>
        </Card>
      </ComponentRoot>
    )
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onInputKeyPress({ key }) {
    if (key === 'Enter') {
      this.onSendClick()
    }
  }

  onSendClick() {
    const { onSend } = this.props
    onSend(this.state.email)
  }
}

EmailLoginCard.propTypes = {
  wasSent: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  status: PropTypes.oneOf([
    'NOT_FOUND',
    'EXPIRED',
    'MALFORMED_TOKEN'
  ]),
  onSend: PropTypes.func
}

EmailLoginCard.defaultProps = {
  status: 'NOT_FOUND',
  wasSent: false,
  isLoading: false,
  onSend: () => { },
}

export default injectIntl(EmailLoginCard)
