
export function raiseNotification(notification) {
  return { type: 'RAISE_NOTIFICATION', notification }
}

export function clearNotification() {
  return { type: 'CLEAR_NOTIFICATION' }
}

export function raiseConnectionRetryNotification() {
  return raiseNotification({ type: 'CONNECTION_RETRY' })
}

export function raiseConnectionTimeoutNotification() {
  return raiseNotification({ type: 'CONNECTION_TIMEOUT' })
}

export function raiseUnexpectedErrorNotification() {
  return raiseNotification({ type: 'UNEXPECTED_ERROR' })
}
