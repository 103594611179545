import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { WordCloud } from 'data-viz'

import DynamicContainerDimensions from '../DynamicContainerDimensions/DynamicContainerDimensions'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'
import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import QuestionAnswerTable from '../../common/QuestionAnswerTable/QuestionAnswerTable'
import PinInfoCard from '../../owner/PinInfoCard/PinInfoCard'
import { determinePinCloseAt, isPinningClosed } from '../../../utils/qvestUtils'
import { withFeatures } from '../../common/FeaturesProvider/FeaturesProvider'


const MAX_PIN_COUNT = (process.env.MAX_PIN_COUNT || 3)

const ChartWrapper = styled.div`
  padding: 40px;
  width: 100%;
`

const ContentWrapper = styled.div`
  width: 100%;
`

const QAWrapper = styled.div`
  margin-top: 50px;
`

const HighlightsDescription = styled.div`
  margin-top: 12px;
`

const LinkButton = styled.button`
  border-style: none;
  background-color: inherit;
  padding: 0;
  cursor: pointer;
  &:hover {
    & > * { text-decoration: underline; }
  }
`

// Some whitespace to allow smooth scrolling even when data has not arrived yet
const NoDataSpace = styled.div`
  display: ${({ show }) => show ? 'block' : 'none'};
  height: 60vh;
`

class ConversationHighlightsCard extends Component {
  renderDescription(onShowAll) {
    const showAll = (
      <LinkButton onClick={onShowAll}>
        <Typography cta>
          <FormattedMessage
            id="result.ConversationHighlightsCard.showAll"
            defaultMessage="here to show all"
          />
        </Typography>
      </LinkButton>
    )
    return (
      <HighlightsDescription>
        <Typography>
          <FormattedMessage
            id="result.ConversationHighlightsCard.interactionHint"
            defaultMessage="Click the words and see the questions and answers or {showAll}"
            values={{ showAll }}
          />
        </Typography>
      </HighlightsDescription>
    )
  }

  renderData() {
    const {
      features,
      questions,
      schedule,
      summary,
      wordFrequenciesData,
      highlightedWords,
      isOwner,
      onWordClick,
      onPinToggle,
      onShowPinned,
      onShowAll
    } = this.props

    const pinsDisabled = features.isDisabled('questionPins') // Show pins unless explicitly disabled

    const scrollToContent = () => {
      const offset = this.contentElement.offsetTop - 82 // Subtract a bit margin for header
      window.scrollTo({ top: offset, behavior: 'smooth' })
    }

    const handleShowPinned = (...args) => {
      scrollToContent()
      onShowPinned(...args)
    }

    const handleWordClick = (...args) => {
      scrollToContent()
      onWordClick(...args)
    }

    const handleShowAll = (...args) => {
      scrollToContent()
      onShowAll(...args)
    }

    let content
    if (questions) {
      content = (
        <QuestionAnswerTable
          isOwner={isOwner}
          pinnable={!pinsDisabled}
          pinningClosed={isPinningClosed(schedule)}
          outOfPins={summary.pins >= MAX_PIN_COUNT}
          data={questions}
          highlights={highlightedWords}
          onPinToggle={onPinToggle}
        />
      )
    }

    let pinInfoCard
    if (!pinsDisabled && schedule && summary) {
      const pinCount = !isOwner ? summary.pins : 0
      const pinCloseAt = determinePinCloseAt(schedule)
      pinInfoCard = (
        <PinInfoCard pinCount={pinCount} pinCloseAt={pinCloseAt} onShowPinned={handleShowPinned} />
      )
    }

    return (
      <Fragment>
        {this.renderDescription(handleShowAll)}
        <ChartWrapper class="tracked" id="result-word-cloud">
          <DynamicContainerDimensions>
            {({ width }) =>
              <WordCloud
                words={wordFrequenciesData}
                height={300}
                width={width - 80}
                onWordClick={handleWordClick}
                highlightedWords={highlightedWords || []}
              />
            }
          </DynamicContainerDimensions>
        </ChartWrapper>
        <ContentWrapper ref={elm => this.contentElement = elm}>
          {pinInfoCard}
          <NoDataSpace show={!questions || questions.length === 0} />
          <QAWrapper>
            {content}
          </QAWrapper>
        </ContentWrapper>
      </Fragment>
    )
  }

  renderError(error, callToAction) {
    let errorMessage
    switch (error.message) {
      case 'NO_DATA':
        errorMessage = (
          <FormattedMessage
            id="result.ConversationHighlightsCard.noData"
            defaultMessage="Not enough questions and answers to draw highlights."
          />
        )
        break
      default:
        errorMessage = (
          <FormattedMessage
            id="result.ConversationHighlightsCard.unexpectedError"
            defaultMessage="We're sorry – an unexpected error occurred."
          />
        )
    }
    return (
      <div>
        <Typography cta={callToAction} weight="bold">
          {errorMessage}
        </Typography>
      </div>
    )
  }

  render() {
    const { error, isLoading, wordFrequenciesData, cardRef } = this.props

    let body
    if (error) {
      body = this.renderError(error, true)
    } else if (isLoading && wordFrequenciesData == null) {
      body = (<CenteredLoader height={484} />)
    } else if (wordFrequenciesData == null) {
      body = this.renderError({ message: 'NO_DATA' })
    } else {
      body = this.renderData()
    }

    return (
      <Fragment>
        <Heading.h2 ref={cardRef}>
          <FormattedMessage id="result.ConversationHighlightsCard.title" defaultMessage="Question & Answer Content" />
        </Heading.h2>
        {body}
      </Fragment>
    )
  }
}

export default withFeatures(ConversationHighlightsCard)
