import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'

import Button from '../../common/Button/Button'
import Input from '../../common/Input/Input'

const errorMessages = defineMessages({
  INVALID_ACCESS_CODE: { id: 'qvest.FindQvestForm.error.NOT_FOUND', defaultMessage: 'No Question Jam found with this PIN. Check if you typed it correctly.' },
  QVEST_OPENED: { id: 'qvest.FindQvestForm.error.QVEST_OPENED', defaultMessage: 'Too late! Jam has started and no longer accepts players.' }
})

const WrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > :last-child {
    align-self: flex-end;
  }
`

const FieldSet = styled.fieldset`
  display: block;
  padding: 0;
  border: none;
  width: 100%;
  margin-bottom: 20px;
`

const HugeInput = styled(Input)`
  font-family: ${({ theme }) => theme.font4};
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding: 10px;
`

const Label = styled.label`
  font-family: ${({ theme }) => theme.font2};
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.one[0]};
  margin-bottom: 5px;
`

const ErrorWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  background-color: rgba(248, 105, 92, .2);
  color: #f8695c;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
`

const ErrorText = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-family: ${props => props.theme.font4};
`

const ErrorMessage = injectIntl(({ error, intl }) => {
  if (!errorMessages.hasOwnProperty(error)) return null
  return (
    <ErrorWrapper>
      <ErrorText>
        {intl.formatMessage(errorMessages[error])}
      </ErrorText>
    </ErrorWrapper>
  )
})

const SubmitButton = ({ disabled, loading }) => {
  if (loading) {
    return (
      <Button disabled size="large">
        <FormattedMessage id="qvest.FindQvestForm.button.loading" defaultMessage="Loading..." />
      </Button>
    )
  }
  return (
    <Button disabled={disabled} size="large">
      <FormattedMessage id="qvest.FindQvestForm.button.submit" defaultMessage="Proceed" />
    </Button>
  )
}

const FindQvestForm = ({ error, loading, onSubmit }) => {
  const [pin, setPin] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit({ pin })
  }

  const valid = (pin && pin.length >= 6)

  return (
    <WrapperForm onSubmit={handleSubmit}>
      <FieldSet>
        <Label htmlFor="pin">
          <FormattedMessage id="qvest.FindQvestForm.label.pin" defaultMessage="PIN:" />
        </Label>
        <HugeInput
          id="pin"
          type="text"
          maxLength={6}
          onChange={({ target }) => setPin(target.value)}
          value={pin}
        />
      </FieldSet>
      <ErrorMessage error={error} />
      <SubmitButton disabled={!valid} loading={loading} />
    </WrapperForm>
  )
}

FindQvestForm.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default FindQvestForm
