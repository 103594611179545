import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Card from '../../common/Card/Card'
import Heading from '../../common/Typography/Heading'
import Button from '../../common/Button/Button'
import scheduledPng from '../../../static/img/schedule-modal.png'


const CardWrapper = styled.div`
  width: 464px;
`

const SpaciousCardBody = styled.div`
  align-items: center;
  padding-bottom: 40px;
  text-align: center;
  & > img {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  & > *:first-child { margin-bottom: 30px; }
  & > *:last-child { margin-top: 30px; }
`

class ScheduledModal extends Component {
  render() {
    return (
      <CardWrapper>
        <Card>
          <SpaciousCardBody>
            <img src={scheduledPng} alt="Qvest scheduled" />
            <Heading.h2>
              <FormattedMessage id="owner.ScheduledModal.heading" defaultMessage="Your Qvest is now scheduled" />
            </Heading.h2>
            <Button onClick={this.props.onSubmit}>
              <FormattedMessage id="owner.ScheduledModal.cta" defaultMessage="Go to overview" />
            </Button>
          </SpaciousCardBody>
        </Card>
      </CardWrapper>
    )
  }
}

ScheduledModal.propTypes = {
  onSubmit: PropTypes.func
}

ScheduledModal.defaultProps = {
  onSubmit: () => {}
}

export default ScheduledModal
