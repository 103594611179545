import uuid from 'uuid/v4'
import debounce from 'lodash/debounce'
import * as api from './apiClient'


const makeQuery = (dispatch, queryId, query, language) => {
  const options = {
    method: 'POST',
    body: {
      query: `
        query q($query: String!, $language: String!) {
          topicSuggestions(query: $query, language: $language) {
            items {
              content
            }
          }
        }
      `,
      variables: { query, language }
    }
  }
  return api.invoke('/api/graphql/public', options, false)
    .then(result => {
      const suggestions = result.data.topicSuggestions.items
      dispatch({ type: 'QUERY_TOPIC_SUGGESTIONS_SUCCESS', queryId, suggestions })
      return suggestions
    })
    .catch(errors => {
      dispatch({ type: 'QUERY_TOPIC_SUGGESTIONS_FAILURE', queryId, errors })
    })
}

const debouncedMakeQuery = debounce(makeQuery, 500, { leading: false })

export const queryTopicSuggestions = (query, language) => dispatch => {
  const queryId = uuid()
  dispatch({ type: 'QUERY_TOPIC_SUGGESTIONS_REQUEST', queryId })
  debouncedMakeQuery(dispatch, queryId, query, language)
}
