import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl } from 'react-intl'
import styled from 'styled-components'

import Card from '../../common/Card/Card'
import Icon from '../../common/Icon/Icon'
import Typography from '../../common/Typography/Typography'
import Button from '../../common/Button/Button'

const ComponentRoot = styled.div`
  width: 650px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`


const MIN_PARTICIPANT_PER_GROUP = 3 // FIXME: Move to some centralized constant


const titles = defineMessages({
  DEFAULT: { defaultMessage: 'Move participant to a different group', id: 'owner.UpdateParticipantGroupModal.title.DEFAULT' },
  EMPTY_GROUP: { defaultMessage: 'Each group must have at least 1 participant', id: 'owner.UpdateParticipantGroupModal.title.EMPTY_GROUP' },
  TOO_FEW_PARTICIPANTS_PER_GROUP: { defaultMessage: 'A group should have {count} or more participants', id: 'owner.UpdateParticipantGroupModal.title.TOO_FEW_PARTICIPANTS_PER_GROUP' }
})

const messages = defineMessages({
  DEFAULT: { defaultMessage: 'Are you sure you want to move {participant} from {groupA} to {groupB}?', id: 'owner.UpdateParticipantGroupModal.message.DEFAULT' },
  EMPTY_GROUP: { defaultMessage: 'You cannot remove the last participant from a group while your Qvest is running.', id: 'owner.UpdateParticipantGroupModal.message.EMPTY_GROUP' },
  TOO_FEW_PARTICIPANTS_PER_GROUP: { defaultMessage: 'Qvest works best if you have 3 or more participants per group. Are you sure you want to move {participant} from {groupA} to {groupB}?', id: 'owner.UpdateParticipantGroupModal.message.TOO_FEW_PARTICIPANTS_PER_GROUP' }
})

const buttons = defineMessages({
  submit: { defaultMessage: 'Yes', id: 'owner.UpdateParticipantGroupModal.button.submit' },
  cancel: { defaultMessage: 'No', id: 'owner.UpdateParticipantGroupModal.button.cancel' },
  return: { defaultMessage: 'Go back', id: 'owner.UpdateParticipantGroupModal.button.return' },
  loading: { defaultMessage: 'Saving...', id: 'owner.UpdateParticipantGroupModal.button.loading' },
})


const UpdateParticipantGroupModal = ({ participant, fromGroup, toGroup, validity, loading, intl, onSubmit, onCancel, onClose }) => {
  const variant = validity.name || 'DEFAULT'
  let message, title

  title = (
    <Typography id="dialog-label" variant="heading3">
      {intl.formatMessage(titles[variant], { count: MIN_PARTICIPANT_PER_GROUP })}
    </Typography>
  )

  message = (
    <Typography weight="light">
      {intl.formatMessage(messages[variant], {
        participant: participant.name,
        groupA: fromGroup.name,
        groupB: toGroup.name
      })}
    </Typography>
  )

  let buttonElms
  if (validity && (validity.type === 'ERROR')) {
    buttonElms = (
      <Button type="button" onClick={onCancel}>{intl.formatMessage(buttons.return)}</Button>
    )
  } else {
    const participantId = participant.participantId
    const toGroupId = toGroup.groupId
    const submitText = intl.formatMessage(loading ? buttons.loading : buttons.submit)
    const cancelText = intl.formatMessage(buttons.cancel)
    buttonElms = (
      <Fragment>
        <Button type="button" outline disabled={loading} onClick={onCancel}>{cancelText}</Button>
        <Button type="button" disabled={loading} onClick={() => onSubmit(participantId, toGroupId)}>{submitText}</Button>
      </Fragment>
    )
  }

  return (
    <ComponentRoot role="alertdialog" aria-modal="true" aria-labelledby="dialog-label" aria-describedby="dialog-desc">
      <Card>
        <Card.Header>
          {title}
          <Icon clickable variant="times" onClick={onClose} />
        </Card.Header>
        <Card.Body>
          {message}
        </Card.Body>
        <Card.Body>
          <ButtonGroup>
            {buttonElms}
          </ButtonGroup>
        </Card.Body>
      </Card>
    </ComponentRoot>
  )
}

UpdateParticipantGroupModal.propTypes = {
  participant: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  validity: PropTypes.shape({
    type: PropTypes.oneOf(['ERROR', 'WARNING', 'OK']).isRequired,
    name: PropTypes.oneOf(['EMPTY_GROUP', 'TOO_FEW_PARTICIPANTS_PER_GROUP'])
  }).isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

UpdateParticipantGroupModal.defaultProps = {
  onClose: () => { },
  onSubmit: () => { },
  onCancel: () => { }
}

export default injectIntl(UpdateParticipantGroupModal)
