import * as api from './apiClient'
import uuid from 'uuid/v4'
import { raiseUnexpectedErrorNotification } from './notificationActions'

function emptyGroup() {
  return {
    groupId: `${uuid()}`, // TODO: Replace these in backend (don't trust client-side ids)
    name: '',
  }
}

export function renameGrouping(name) {
  return { type: 'CHANGE_GROUPING_NAME', name }
}

export function changeEditingGroup(groupId) {
  return { type: 'GROUPING_EDITING_GROUP', groupId }
}

export function addGroup() {
  return { type: 'GROUPING_ADD_GROUP', group: emptyGroup() }
}

export function removeGroup(groupId) {
  return { type: 'GROUPING_REMOVE_GROUP', groupId }
}

export function changeGroup(group) {
  return { type: 'GROUPING_CHANGE_GROUP', group }
}

export function getGroupingByQvestId(qvestId) {
  return dispatch => {
    dispatch({ type: 'GET_GROUPING_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q {
            groupings(qvestId: "${qvestId}") {
              items {
                groupingId,
                name,
                isDefault,
                groups {
                  groupId,
                  name
                }
              },
              validity {
                general,
                items {
                  type,
                  name
                }
              }
            }
          }
        `
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const grouping = data.groupings.items[0]
        const validity = data.groupings.validity
        dispatch({ type: 'GET_GROUPING_SUCCESS', grouping, validity, qvestId })
        dispatch({ type: 'INIT_UNSAVED_GROUPING', grouping })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'GET_GROUPING_FAILURE', error })
      })
  }
}

export function createGrouping(qvestId) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_GROUPING_REQUEST' })
    const { name, groups } = getState().grouping.get('unsaved').toJS()
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($qvestId:String!, $name:String, $groups:[GroupCreate]) {
            createGrouping(qvestId:$qvestId, name:$name, groups:$groups) {
              item {
                groupingId,
                name,
                isDefault,
                groups {
                  groupId,
                  name
                }
              },
              validity {
                general,
                items {
                  type,
                  name
                }
              }
            }
          }
        `,
        variables: {
          qvestId,
          name,
          groups: groups.map(group => ({ name: group.name })),
        }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const grouping = data.createGrouping.item
        const validity = data.createGrouping.validity
        dispatch({ type: 'UPDATE_GROUPING_SUCCESS', grouping, validity, qvestId })
        dispatch({ type: 'INIT_UNSAVED_GROUPING', grouping })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'UPDATE_GROUPING_FAILURE', error })
      })
  }
}

export function updateGrouping(qvestId) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_GROUPING_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($qvestId:String!, $patch:GroupingPatch!) {
            updateGrouping(qvestId:$qvestId, patch:$patch) {
              item {
                groupingId,
                name,
                isDefault,
                groups {
                  groupId,
                  name
                }
              },
              validity {
                general,
                items {
                  type,
                  name
                }
              }
            }
          }
        `,
        variables: {
          qvestId,
          patch: getState().grouping.get('unsaved').toJS()
        }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const grouping = data.updateGrouping.item
        const validity = data.updateGrouping.validity
        dispatch({ type: 'UPDATE_GROUPING_SUCCESS', grouping, validity, qvestId })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'UPDATE_GROUPING_FAILURE', error })
      })
  }
}

export function resetUnsavedGrouping() {
  return { type: 'RESET_UNSAVED_GROUPING' }
}
