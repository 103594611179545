
const HOST_NAME = process.env.HOST_NAME

let finaleTimeout = null

export function isDraftQvestState(qvestState) {
  return (qvestState == null)
}

// Deprecated: Use hasStarted below (cache invalidation on schedule changes is more simple)
export function hasStartedQvestState(qvestState) {
  return (qvestState != null) && (qvestState !== 'QVEST_SCHEDULED')
}

// Deprecated: Use hasClosed below (cache invalidation on schedule changes is more simple)
export function isDoneQvestState(qvestState) {
  return (qvestState === 'QVEST_CLOSED') || (qvestState === 'QVEST_EXPIRED')
}

export function determineNextWaveAt(qvest, schedule) {
  if(qvest.hasWaves) {
    const waveAt = new Date(schedule.openAt.getTime() + 86400000)
    if (waveAt > new Date()) {
      return waveAt
    }
  }
  return null
}

export function determinePinCloseAt(schedule) {
  if(!schedule) return null
  return new Date(schedule.closeAt.getTime() + 432000000) // closeAt + 5 days (TODO: Add to schedule in backend, if we keep this feature long term)
}

export function isPinningClosed(schedule) {
  if(!schedule) return null
  const now = new Date()
  const pinCloseAt = determinePinCloseAt(schedule)
  return (now > pinCloseAt)
}

export function makeJoinUrl(accessCode) {
  return (
    `${HOST_NAME}/join/${accessCode}`
  )
}

export function hasStarted(qvest) {
  const { schedule } = qvest
  if (!schedule || !schedule.openAt) return null
  if (schedule.inviteAt) return (new Date(schedule.inviteAt) < Date.now())
  return (new Date(schedule.openAt) < Date.now())
}

export function hasClosed(qvest) {
  if (!qvest.schedule || !qvest.schedule.closeAt) return null
  return (new Date(qvest.schedule.closeAt) < Date.now())
}

export function scheduleRedirectToFinale(qvest, match, history) {
  const isAlreadyAtFinale = (match.params.page === 'finale')
  const isAlreadyScheduled = (finaleTimeout != null)
  if (qvest.isJam && hasStarted(qvest) && !isAlreadyAtFinale && !isAlreadyScheduled) {
    const closeAt = new Date(qvest.schedule.closeAt)
    const msLeft = (closeAt - Date.now())
    const delay = Math.min(msLeft, 2147483647) // Note: setTimeout does not support longer delays than ~25 days
    finaleTimeout = setTimeout(() => {
      history.push(`/qvest/${qvest.qvestId}/finale`)
    }, delay)
  }
}
