import 'whatwg-fetch'


export async function getAuthProvider() {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache', // Legacy (IE11) support
    },
    method: 'POST',
    body: JSON.stringify({
      query: `
        query {
          authProvider {
            authProviderId
            variant
          }
        }
      `,
    })
  }
  const response = await fetch('/api/graphql/public', options)
  if (response.status !== 200) {
    const body = await response.text()
    throw new Error(body)
  }
  const body = await response.json()
  return body.data.authProvider
}
