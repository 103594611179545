import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import Typography from '../Typography/Typography'
import Heading from '../Typography/Heading'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import EditableSpan from '../EditableSpan2/EditableSpan'
import Checkbox from '../Checkbox/Checkbox'
import { useFeatures } from '../FeaturesProvider/FeaturesProvider'

const IconButton = styled(Button)`
  height: 26px;
  & > * {
    display: block;
    width: 14px;
  }
`

const HeadingBlock = styled.div`
  display: flex;
  margin-bottom: 10px;
  & > :last-child {
    display: ${({ editable }) => editable ? 'block' : 'none'};
    margin: 4px 0 0 10px;
  }
`

const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -27px;
  opacity: ${({ loading }) => loading ? '0.5' : '1.0'};
`

const DetailsRow = styled.div`
  flex: 1 0 300px;
  padding: 27px;
  & > :first-child { margin-bottom: 10px; }
  & > * { display: block; }
`

const EditingBlock = styled.div`
  max-width: 510px;

  & a {
    color: ${({ theme }) => theme.color[0] };
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }
`

const titles = defineMessages({
  edit: { defaultMessage: 'Edit process', id: 'owner.ProcessWidget.titles.edit' }
})

const DisplayedWidget = ({ hasWaves, loading }) => {
  const features = useFeatures()
  const hasPins = !features.isDisabled('questionPins') // Show pins unless explicitly disabled
  return (
    <DetailsWrapper loading={loading}>
      <DetailsRow>
        <Typography transparent weight="light">01</Typography>
        <Typography weight="light">
          <FormattedMessage
            id="ProcessWidget.bullet1"
            defaultMessage="When the Qvest opens, your task is to ask an opening question of one of your co-participants. Your opening question is important, because it shows what matters to you in relation to the topic."
          />
        </Typography>
      </DetailsRow>
      <DetailsRow>
        <Typography transparent weight="light">02</Typography>
        <Typography paragraph weight="light">
          <FormattedMessage
            id="ProcessWidget.bullet2"
            defaultMessage="If you receive a question from one of your co-participants, you must answer and ask a new question."
          />
        </Typography>
        {hasWaves ? <Typography weight="light">
          <FormattedMessage
            id="ProcessWidget.bullet2.waves"
            defaultMessage="24 hours after the Qvest opens, you will automatically receive a prompt to ask an additional question."
          />
        </Typography> : null}
      </DetailsRow>
      <DetailsRow>
        <Typography transparent weight="light">03</Typography>
        <Typography paragraph weight="light">
          <FormattedMessage
            id="ProcessWidget.bullet3"
            defaultMessage="When the Qvest closes, you get access to the collective insight on the Qvest output page."
          />
        </Typography>
        {hasPins ? <Typography weight="light">
          <FormattedMessage
            id="ProcessWidget.bullet3.pins"
            defaultMessage="On the output page, you can pin the 3 questions you find most important."
          />
        </Typography> : null}
      </DetailsRow>
    </DetailsWrapper>
  )
}

const EditingWidget = (props) => {
  const { onSubmit, onCancel } = props
  const [hasWaves, setHasWaves] = useState(props.hasWaves || false)
  const handleSubmit = () => onSubmit({ hasWaves })
  const waveLink = (
    <Typography variant="medium">
      <strong>
        <FormattedMessage defaultMessage="question wave" id="ProcessWidget.editing.label.questionWaves.waveLink" />
      </strong>
    </Typography>
  )
  return (
    <EditableSpan.Edit fullWidth onDone={handleSubmit} onCancel={onCancel}>
      <EditingBlock>
        <label>
          <Checkbox checked={hasWaves} onChange={() => setHasWaves(!hasWaves)}>
            <Typography variant="medium">
              <FormattedMessage
                id="ProcessWidget.editing.label.questionWaves"
                defaultMessage="Yes, I want to add a {waveLink}"
                values={{ waveLink }}
              />
            </Typography>
          </Checkbox>
        </label>
        <Typography transparent weight="light" variant="medium">
          <FormattedMessage
            id="ProcessWidget.editing.description.questionWaves"
            defaultMessage="When you add a question wave, your participants will receive an additional prompt to ask a question 24 hours after your Qvest opens."
          />
        </Typography>
      </EditingBlock>
    </EditableSpan.Edit>
  )
}

const ProcessWidget = ({ editable, hasWaves, intl, onSave }) => {
  const [isEditing, setEditing] = useState(false)
  const [isLoading, setLoading] = useState(false)

  // heading
  const heading = (
    <HeadingBlock editable={editable && !isEditing}>
      <Heading.h2 transparent={isLoading}>
        <FormattedMessage id="ProcessWidget.header" defaultMessage="How it works" />
      </Heading.h2>
      <IconButton size="tiny" title={intl.formatMessage(titles.edit)} onClick={() => setEditing(true)}>
        <Icon clickable tertiary variant="edit" size="small" />
      </IconButton>
    </HeadingBlock>
  )

  // uneditable content
  let displayed = <DisplayedWidget hasWaves={hasWaves} loading={isLoading} />

  const handleSubmit = (patch) => {
    setEditing(false)
    setLoading(true)
    return onSave(patch)
      .finally(() => setLoading(false))
  }

  // editable state
  if (editable) {
    let editableContent
    if (isEditing) {
      // editing state
      editableContent = (
        <Fragment>
          {heading}
          <EditingWidget
            hasWaves={hasWaves}
            onSubmit={handleSubmit}
            onCancel={() => setEditing(false)}
          />
        </Fragment>
      )
    } else {
      // wrap regular
      editableContent = (
        <EditableSpan.View onStartEdit={() => setEditing(true)} showEditIcon={false}>
          {heading}
          {displayed}
        </EditableSpan.View>
      )
    }

    return (
      <div>
        <EditableSpan fluid>{editableContent}</EditableSpan>
      </div>
    )
  } else {
    return (
      <div>
        {heading}
        {displayed}
      </div>
    )
  }
}

export default injectIntl(ProcessWidget)
