import React from 'react'
import styled from 'styled-components'

import Heading from '../../common/Typography/Heading'


const ComponentRoot = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 500px;

  & h1 {
    margin: 5px 0 20px 0;
  }
`

const HorizontalRule = styled.hr`
  margin: 0 0 40px 0;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.one[3]};
`

const JamHeader = ({ topic }) => {
  if (!topic) {
    return (
      <ComponentRoot>
        <h1>
          <Heading variant="heading1">Question Jam</Heading>
          {' '}
          <Heading variant="heading2" transparent>[BETA]</Heading>
        </h1>
        <HorizontalRule />
      </ComponentRoot>
    )
  } else {
    return (
      <ComponentRoot>
        <Heading variant="heading3" primary>Question Jam</Heading>
        <h1>
          <Heading variant="heading1">{topic}</Heading>
        </h1>
        <HorizontalRule />
      </ComponentRoot>
    )
  }
}

export default JamHeader
