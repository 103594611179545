import 'whatwg-fetch'
import { log } from '../../utils/trackingUtils'
import { getQueryStringValue } from '../../utils/stringUtils'
import { getAuthProvider } from './authProviderUtils'
import * as oidcUtils from './oidcUtils'

let tokenRenewalTimeout, authProvider
const state = {
  qvestId: null,
  accessToken: null,
  expiresAt: null
}
const MAX_DELAY = 2147483647 // Maximum delay supported by setTimeout (https://stackoverflow.com/a/3468650/904065)
const JWT_PAYLOAD_REGEX = /^[^.]+\.([^.]+)\.[^.]+$/


function isExpired(session) {
  const deadline = new Date(Date.now() + 259200000) // Buffer: 3 days before actual expiry
  return (new Date(session.expires_at) < deadline)
}

// Invoke backend to store session in secure cookie
async function persistSession() {
  try {
    const options = {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache' // Legacy (IE11) support
      },
      method: 'POST',
      body: JSON.stringify({
        query: `
          mutation m {
            persistParticipantSession
          }
        `,
      })
    }
    const response = await fetch('/api/graphql', options)
    if (response.status !== 200) {
      const error = await response.json()
      log.warning(error)
    }
  } catch (error) {
    // Log warning and fail silently, persisted session is not critical to user experience
    log.warning(error)
  }
}

// Retrieve accessToken using session stored in cookie
//
// NOTE: JavaScript runtime can't access session cookies for security reasons and the backend must be invoked to
// obtain a token and even to check if a valid session exists at all
async function getParticipantAccess(qvestId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache', // Legacy (IE11) support
    },
    method: 'POST',
    body: JSON.stringify({
      query: `
        query q($qvestId: String!) {
          participantAccess(qvestId: $qvestId) {
            accessToken
            expiredAt
            status
          }
        }
      `,
      variables: { qvestId }
    })
  }
  const response = await fetch('/api/graphql/public', options)
  let body
  try {
    body = await response.json()
  } catch (ex) {
    log.error(`Failed to parse error response: ${ex.message}`)
    throw ex
  }
  if (body.errors != null) {
    const error = body.errors[0]
    throw new Error(error.message)
  }
  return body.data.participantAccess
}


export async function joinQvest(accessCode, name) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache', // Legacy (IE11) support
    },
    method: 'POST',
    body: JSON.stringify({
      query: `
          mutation m($accessCode:String!, $name:String){
            joinQvest(accessCode: $accessCode, name: $name) {
              qvestId
              topic
              access {
                accessToken
                expiredAt
                status
              }
            }
          }
        `,
      variables: { accessCode, name }
    })
  }
  const response = await fetch('/api/graphql/public', options)
  let body
  try {
    body = await response.json()
  } catch (ex) {
    log.error(`Failed to parse error response: ${ex.message}`)
    throw ex
  }
  if (body.errors != null) {
    const error = body.errors[0]
    throw new Error(error.message)
  }
  return body.data.joinQvest
}

// Save session
async function setSession(qvestId, accessToken, expiresAt) {
  state.qvestId = qvestId
  state.accessToken = accessToken
  state.expiresAt = expiresAt
  persistSession() // Optional, intentionally don't await
  return scheduleRenewal(qvestId)
}

// Token renewal (Attempt new token from session, otherwise redirect to login)
async function renewToken(qvestId) {
  const { status, accessToken, expiredAt } = await getParticipantAccess(qvestId)
  if (!accessToken) {
    // Failed to obtain token, show login screen with appropriate message
    window.location = `/login?qvestId=${qvestId}&status=${status}`
    return false
  } else {
    // Successfully obtained new token from session cookie
    const expiresAtTime = new Date(expiredAt).getTime()
    return setSession(qvestId, accessToken, expiresAtTime)
  }
}

// Schedule token renewal
async function scheduleRenewal(qvestId) {
  const expiresAt = JSON.parse(state.expiresAt)
  const delay = (expiresAt) - Date.now()
  if (delay > 0) {
    let scheduledDelay = delay - (1000 * 30) // Renew 30 seconds before expiry
    scheduledDelay = Math.min(scheduledDelay, MAX_DELAY)
    tokenRenewalTimeout = setTimeout(() => {
      scheduleRenewal(qvestId) // NOTE: Re-runs scheduleRenewal on expiry because token might not be expired after all, if expiry happens to gos beyond maximum delay on "setTimeout" function
    }, scheduledDelay)
    return true
  } else {
    return renewToken(qvestId)
  }
}

function clearTokenFromUrl() {
  const cleanUrl = location.origin + location.pathname
  window.history.pushState({}, null, cleanUrl)
}

function extractTokenPayload(accessToken) {
  const match = accessToken.match(JWT_PAYLOAD_REGEX)
  return (match ? match[1] : null)
}

// Basic checks of access token integrity
function isTokenMalformed(accessToken) {
  const payload = extractTokenPayload(accessToken)
  if (!payload) {
    // Failed to: extract payload section from string
    return true
  }
  try {
    // Failed to: decode and parse payload
    JSON.parse(unescape(window.atob(payload)))
  } catch (error) {
    return true
  }
  // No malformation detected (within the bounds of the checks above)
  return false
}

// Login (Add new access token)
async function handleAuthentication(qvestId, accessToken) {
  const expiresAt = getQueryStringValue('expires_at')
  clearTokenFromUrl()
  // Exit early if malformed (Response to observations of malformed tokens and excessive error logging)
  if (isTokenMalformed(accessToken)) {
    log.warning('Malformed participant accessToken')
    window.location = `/login?qvestId=${qvestId}&status=MALFORMED_TOKEN`
    return false
  }
  // Persist session and schedule renewal
  return setSession(qvestId, accessToken, expiresAt)
}

// Check if already authenticated
function isAuthenticated() {
  // Check if previous access token present in local storage
  let { expiresAt } = state
  if (expiresAt) {
    // Check whether the current time is past the access token's expiry time
    expiresAt = JSON.parse(expiresAt)
    return new Date().getTime() < expiresAt
  }
  return false
}

// Log out (Remove access token)
export function logout() {
  // Stop token renewal loop
  if (tokenRenewalTimeout) {
    clearTimeout(tokenRenewalTimeout)
  }
}

// Retrieve authentication header for use in API requests
export function getAuthHeader() {
  if (authProvider && authProvider.variant === 'oidc') {
    // OIDC
    return {} // Uses cookies rather than headers
  } else {
    // Qvest auth
    return { Authorization: `Bearer ${state.accessToken}` }
  }
}

function initializeQvestAuth(qvestId, accessToken) {
  if (accessToken) {
    // If user appears to have just logged in, handle this
    return handleAuthentication(qvestId, accessToken)
  } else if (!isAuthenticated()) {
    // If user appears to be not logged in, attempt to renew token through session cookie or redirect to login view
    return renewToken(qvestId)
  } else {
    // If user appears to be logged in, simply schedule renewal at later point
    return scheduleRenewal(qvestId)
  }
}

async function initializeOIDCAuth() {
  // Attempt to get current session
  const session = await oidcUtils.getSession()
  // If no active session found, initialize login flow by redirecting
  if (!session) {
    oidcUtils.initFlow(authProvider.authProviderId, window.location)
    return false
  }
  // If session inactive or expired (soon), refresh it by redirecting
  if (!session.active || isExpired(session)) {
    const refresh = true
    oidcUtils.refreshSession(authProvider.authProviderId, window.location, refresh)
    return false
  }
  return true
}

export async function getFlowConfig(flowId, variant) {
  return oidcUtils.getFlowConfig(flowId, variant)
}

export async function getFlowErrors(errorId) {
  return oidcUtils.getFlowErrors(errorId)
}

export async function completeFlow() {
  return oidcUtils.completeFlow()
}

// Initialize authentication state
export async function initialize(qvestId) {
  const accessToken = getQueryStringValue('access_token')

  // OIDC
  // If unable to initialize immediately using available token/state, check if the Qvest has an OIDC auth provider
  if (!accessToken && !isAuthenticated()) {
    authProvider = await getAuthProvider()
    if (authProvider && authProvider.variant === 'oidc') {
      return initializeOIDCAuth()
    }
  }

  // Qvest auth
  return initializeQvestAuth(qvestId, accessToken)
}
