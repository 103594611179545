import Heading from '../../common/Typography/Heading'
import { FormattedMessage } from 'react-intl'
import Tooltip from '../../common/Tooltip/Tooltip'
import Typography from '../../common/Typography/Typography'
import React from 'react'


export const TopicHeading = () => (
  <span>
    <Heading variant="heading3">
      <FormattedMessage defaultMessage="Decide on an engaging" id="owner.QvestTopicTab.topic.heading1" />
      {' '}
    </Heading>
    <Tooltip.Area>
      <Tooltip.Reference>
        <Heading variant="heading3" primary>
          <FormattedMessage
            id="owner.QvestTopicTab.topic.heading2"
            defaultMessage="Qvest Topic"
          />
        </Heading>
      </Tooltip.Reference>
      <Tooltip wide>
        <Heading.h3>
          <FormattedMessage
            id="owner.QvestTopicTab.topic.helpTooltip.heading"
            defaultMessage="Topic"
          />
        </Heading.h3>
        <Typography.p variant="small" weight="light">
          <FormattedMessage
            id="owner.QvestTopicTab.topic.helpTooltip.content1"
            defaultMessage="To engage your participants in your Qvest, your Qvest topic must:"
          />
        </Typography.p>
        <ul>
          <li>
            <Typography variant="small" weight="light">
              <FormattedMessage defaultMessage="Be relevant to participants" id="owner.QvestTopicTab.topic.helpTooltip.listItem1" />
            </Typography>
          </li>
          <li>
            <Typography variant="small" weight="light">
              <FormattedMessage defaultMessage="Be short (2-10 words)" id="owner.QvestTopicTab.topic.helpTooltip.listItem2" />
            </Typography>
          </li>
          <li>
            <Typography variant="small" weight="light">
              <FormattedMessage defaultMessage="NOT be a question" id="owner.QvestTopicTab.topic.helpTooltip.listItem3" />
            </Typography>
          </li>
        </ul>
        <Typography.p variant="small" weight="light">
          <FormattedMessage
            id="owner.QvestTopicTab.topic.helpTooltip.content4"
            defaultMessage="Keywords are not shown directly to participants. They are used to compute the Relevance-score of your Qvest."
          />
        </Typography.p>
      </Tooltip>
    </Tooltip.Area>
  </span>
)

export const PageHeading = () => (
  <span>
    <Heading variant="heading3">
      <FormattedMessage defaultMessage="Compose and edit your" id="owner.QvestTopicTab.page.heading1" />
      {' '}
    </Heading>
    <Tooltip.Area>
      <Tooltip.Reference>
        <Heading variant="heading3" primary>
          <FormattedMessage
            id="owner.QvestTopicTab.page.heading2"
            defaultMessage="Qvest Page"
          />
        </Heading>
      </Tooltip.Reference>
      <Tooltip>
        <Heading.h3>
          <FormattedMessage
            id="owner.QvestTopicTab.page.helpTooltip.heading"
            defaultMessage="Qvest Page"
          />
        </Heading.h3>
        <Typography.p variant="small" weight="light">
          <FormattedMessage
            id="owner.QvestTopicTab.page.helpTooltip.content1"
            defaultMessage="To motivate your participants to engage during your Qvest, you must give some context to your Qvest topic by answering the following questions:"
          />
        </Typography.p>
        <ol>
          <li>
            <Typography variant="small" weight="light">
              <FormattedMessage defaultMessage="Why are you initiating this Qvest?" id="owner.QvestTopicTab.page.helpTooltip.listItem1" />
            </Typography>
          </li>
          <li>
            <Typography variant="small" weight="light">
              <FormattedMessage defaultMessage="What’s in it for the participants?" id="owner.QvestTopicTab.page.helpTooltip.listItem2" />
            </Typography>
          </li>
          <li>
            <Typography variant="small" weight="light">
              <FormattedMessage defaultMessage="How will the output be used?" id="owner.QvestTopicTab.page.helpTooltip.listItem3" />
            </Typography>
          </li>
        </ol>
      </Tooltip>
    </Tooltip.Area>
  </span>
)

export const InvitationHeading = () => (
  <span>
    <Heading variant="heading3">
      <FormattedMessage defaultMessage="Customize sender of your" id="owner.QvestTopicTab.email.heading1" />
      {' '}
    </Heading>
    <Tooltip.Area>
      <Tooltip.Reference>
        <Heading variant="heading3" primary>
          <FormattedMessage
            id="owner.QvestTopicTab.email.heading2"
            defaultMessage="invitation email"
          />
        </Heading>
      </Tooltip.Reference>
      <Tooltip>
        <Heading.h3>
          <FormattedMessage
            id="owner.QvestTopicTab.email.helpTooltip.heading"
            defaultMessage="Invitation email"
          />
        </Heading.h3>
        <Typography.p variant="small" weight="light">
          <FormattedMessage
            id="owner.QvestTopicTab.email.helpTooltip.content1"
            defaultMessage="The email invitation includes a link to the Qvest Page."
          />
        </Typography.p>
        <Typography.p variant="small" weight="light">
          <FormattedMessage
            id="owner.QvestTopicTab.email.helpTooltip.content2"
            defaultMessage="The only thing you can customize in the invitation email is the sender name and title."
          />
        </Typography.p>
      </Tooltip>
    </Tooltip.Area>
  </span>
)
