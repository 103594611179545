import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import QvestCard from './QvestCard'

// temporary loader
import DimmerLoader from '../../common/DimmerLoader/DimmerLoader'

const ComponentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
  & > * {
    margin: 13px;
  }
`

const QvestList = ({ isLoading, qvests, onItemClick, onArchive }) => {
  // show qvest even when the store is loading
  if ((isLoading && !qvests) || !qvests) return <DimmerLoader />
  return (
    <ComponentWrapper>
      {qvests.map(({ name, qvestId, schedule, summary, state, isArchived }) => (
        <QvestCard
          key={qvestId}
          qvestId={qvestId}
          name={name}
          schedule={schedule}
          summary={summary}
          state={state}
          isArchived={isArchived}
          onClick={onItemClick}
          onArchiveClick={onArchive}
        />
      ))}
    </ComponentWrapper>
  )
}

QvestList.propTypes = {
  qvests: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    state: PropTypes.string,
    qvestId: PropTypes.string,
  })),
  isLoading: PropTypes.bool,
  onItemClick: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired
}

QvestList.defaultProps = {
  qvests: [],
  isLoading: false,
}

export default QvestList
