import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import GenericModal from '../../common/GenericModal/GenericModal'
import Typography from '../../common/Typography/Typography'
import GroupsDropdown from '../../common/GroupsDropdown/GroupsDropdown'


function getDefaultGroupId(selectedGroup, groups) {
  if (!selectedGroup && groups.length > 0) {
    return groups[0].groupId
  } else {
    return selectedGroup
  }
}

class CreateParticipantModal extends Component {
  state = {
    selectedGroup: null,
    confirmed: false
  }

  handleGroupChanged = (value) => {
    this.setState({ selectedGroup: value })
  }

  handleConfirm = () => {
    this.setState({ confirmed: true })
  }

  handleSubmit = () => {
    const { onSubmit, participant, groups } = this.props
    const groupId = getDefaultGroupId(this.state.selectedGroup, groups)
    onSubmit(participant.name, participant.email, groupId)
  }

  renderForm() {
    const { participant, groups } = this.props
    const { selectedGroup } = this.state
    return (
      <Fragment>
        <Typography.p weight="light">
          <FormattedMessage
            id="owner.CreateParticipantModal.message"
            defaultMessage="Please assign {participantName} to a group."
            values={{ participantName: participant.name }}
          />
        </Typography.p>
        <div>
          <GroupsDropdown groups={groups} value={selectedGroup} onChange={this.handleGroupChanged} />
        </div>
      </Fragment>
    )
  }

  renderSummary() {
    const { participant, groups, qvest } = this.props
    const { selectedGroup } = this.state
    const group = groups.find(g => g.groupId === selectedGroup)

    let confirmationPrompt, emailPrompt = null
    if (qvest.state === 'QVEST_OPENED') {
      emailPrompt = <FormattedMessage
        id="owner.CreateParticipantModal.summaryStartLinkEmail"
        defaultMessage="An email with a start link will be sent to {email} immediately."
        values={{
          email: <Typography weight="bold">{participant.email}</Typography>
        }}
      />
      confirmationPrompt = <FormattedMessage
        id="owner.CreateParticipantModal.summaryConfirmRunning"
        defaultMessage="Are you sure you want to invite {name} to a running Qvest?"
        values={{ name: participant.name }}
      />
    } else {
      confirmationPrompt = <FormattedMessage
        id="owner.CreateParticipantModal.summaryConfirm"
        defaultMessage="Are you sure you want to invite {name} to this Qvest?"
        values={{ name: participant.name }}
      />
    }

    if (qvest.state === 'QVEST_ONBOARDING_STARTED') {
      emailPrompt = <FormattedMessage
        id="owner.CreateParticipantModal.summaryInvitationEmail"
        defaultMessage="An invitation email will be sent to {email} immediately."
        values={{
          email: <Typography weight="bold">{participant.email}</Typography>
        }}
      />
    }

    return (
      <Fragment>
        <Typography.p weight="light">
          <FormattedMessage
            id="owner.CreateParticipantModal.summaryDescription"
            defaultMessage="You have assigned {name} to the group {group}."
            values={{
              name: <Typography weight="bold">{participant.name}</Typography>,
              group: <Typography weight="bold">{group.name}</Typography>
            }}
          />
          <br />
          {emailPrompt}
        </Typography.p>
        <Typography.p weight="light">
          {confirmationPrompt}
        </Typography.p>
      </Fragment>
    )
  }

  render() {
    const { loading, onCancel } = this.props
    const { confirmed, selectedGroup } = this.state

    const modalProps = {
      narrow: true,
      title: (<FormattedMessage id="owner.CreateParticipantModal.title" defaultMessage="Invite new participant" />),
      confirm: !confirmed,
      cancel: !confirmed,
      yes: confirmed,
      no: confirmed,
      incomplete: !selectedGroup,
      onSubmit: (confirmed ? this.handleSubmit : this.handleConfirm),
      onCancel,
      loading
    }

    return (
      <GenericModal {...modalProps}>
        {!confirmed ? this.renderForm() : this.renderSummary()}
      </GenericModal>
    )
  }
}

CreateParticipantModal.propTypes = {
  qvest: PropTypes.shape({
    state: PropTypes.oneOf([
      'QVEST_OPENED',
      'QVEST_SCHEDULED',
      'QVEST_ONBOARDING_STARTED',
    ])
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string
  })).isRequired,
  participant: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

CreateParticipantModal.defaultProps = {
  onClose: () => { },
  onSubmit: () => { },
  onCancel: () => { }
}

export default CreateParticipantModal
