import React from 'react'
import styled from 'styled-components'
import { Manager, Popper, Reference } from 'react-popper'

export const TooltipCard = styled.div`
  z-index: 60;
  visibility: hidden;
  border: 1px solid #edeeee;
  border-radius: 3px;
  background-color: ${({ inverted, theme }) => inverted ? theme.inverted.two[1] : theme.two[0]};
  padding: 20px 30px;
  box-shadow: 12px 12px 34px 0 rgba(60,63,65,0.2);
  text-align: left;
  width: ${({ wide }) => wide ? '600px' : '400px'};
  cursor: auto;
  & > *:first-child { margin-bottom: 10px; }

  /*  TODO: have this globally for all ordered lists */
  ol {
    list-style: none;
    counter-reset: qvest-counter;
  }
  ol li {
    counter-increment: qvest-counter;
    position: relative;
  }
  ol li::before {
    content: counter(qvest-counter) ".";
    color: ${({ theme }) => theme.one[0]};
    font-family: ${({ theme }) => theme.font3};
    font-size: 1em;
    line-height: 1.2em;
    position: absolute;
    left: -20px;
    top: 0;
  }
`

const MouseOverArea = styled.span`
  cursor: help;
  &:hover ${TooltipCard} {
    visibility: visible;
  }
`

const MODIFIERS = {
  preventOverflow: {
    padding: 20
  }
}

const Tooltip = ({ children, inverted, wide }) => (
  <Popper placement="bottom-start" positionFixed={true} modifiers={MODIFIERS}>
    {({ ref, style, placement }) => (
      <TooltipCard ref={ref} style={style} data-placement={placement} inverted={inverted} wide={wide}>
        {children}
      </TooltipCard>
    )}
  </Popper>
)

const ReferenceWrapper = ({ children }) => (
  <Reference>
    {({ ref }) => (
      <span ref={ref}>
        {children}
      </span>
    )}
  </Reference>
)

const Area = ({ children }) => (
  <MouseOverArea>
    <Manager>{children}</Manager>
  </MouseOverArea>
)


Tooltip.Area = Area
Tooltip.Reference = ReferenceWrapper

export default Tooltip
