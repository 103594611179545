import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import styled, { withTheme } from 'styled-components'
import { WordCloud } from 'data-viz'

import Typography from '../../../common/Typography/Typography'
import AnalyticsCard from '../../AnalyticsCard/AnalyticsCard'
import { formatData } from '../../AnalyticsHighlights/AnalyticsHighlights'


const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 223px;
  padding: 30px 0 20px 0;
`

const Details = styled.div`
  flex: 0 0 21px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`


const messages = defineMessages({
  title: { id: 'owner.HighlightsAnalyticsCard.title.high', defaultMessage: '{count} prominent {count, plural, one {keyword} other {keywords}}' },
})

class HighlightsAnalyticsCard extends Component {
  handleClick = () => this.props.onClick('highlights')

  renderChart(key, wordFrequencies) {
    return (
      <ChartContainer>
        <WordCloud
          key={key}
          width={320}
          height={132}
          inverted={this.props.locked}
          words={wordFrequencies}
        />
      </ChartContainer>
    )
  }

  renderDetails(count) {
    const { locked } = this.props
    if (!count || count < 1) return null
    return (
      <Details>
        <Typography inverted={locked} variant="small" weight="light">
          <FormattedMessage defaultMessage="Used" id="owner.HighlightsAnalyticsCard.details.label" />
          &nbsp;
        </Typography>
        <Typography inverted={locked} variant="small" weight="bold">
          <FormattedMessage
            id="owner.HighlightsAnalyticsCard.details.count"
            defaultMessage="{count} times"
            values={{ count }}
          />
        </Typography>
      </Details>
    )
  }

  render() {
    const { intl, groups, wordFrequencies, locked, loading } = this.props
    if (loading) return (<AnalyticsCard loading />)

    let title, chart, details
    if (groups && wordFrequencies) {
      const { hash, outlierWords, totalOutlierCount } = formatData(groups, wordFrequencies)
      title = intl.formatMessage(messages.title, { count: outlierWords.length })
      chart = this.renderChart(hash, wordFrequencies)
      details = this.renderDetails(totalOutlierCount)
    } else {
      chart = this.renderChart(-1, [{ topWord: 'words', count: 1 }])
      details = this.renderDetails(0)
    }

    return (
      <AnalyticsCard
        subTitle="Highlights"
        title={title}
        onClick={this.handleClick}
        disabled={(!groups || !wordFrequencies) && !locked}
        locked={locked}
        loading={loading}
      >
        <Content>
          {chart}
          {details}
        </Content>
      </AnalyticsCard>
    )
  }
}

export default withTheme(injectIntl(HighlightsAnalyticsCard))
