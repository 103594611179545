import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import UserSettingsCard from '../../components/owner/settings/UserSettingsCard/UserSettingsCard'
import { updateUser } from '../../actions/userActions'
import { filterMyUser } from '../../reducers/user'

const ComponentRoot = styled.div`
  padding: 20px;
`

class OwnerHomePage extends Component {
  handleSave = (patch) => {
    const { dispatch } = this.props
    dispatch(updateUser(patch))
  }

  render() {
    const { userStore } = this.props
    const user = filterMyUser(userStore)
    return (
      <ComponentRoot>
        <Helmet>
          <title>{user && user.fullName}</title>
        </Helmet>
        <UserSettingsCard
          user={user}
          error={userStore.error}
          isLoading={userStore.isLoading}
          onSave={this.handleSave}
        />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    userStore: state.user.toJS()
  }
}

export default connect(mapStateToProps)(OwnerHomePage)
