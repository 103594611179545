import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import GenericModal from '../../common/GenericModal/GenericModal'
import Radio from '../../common/Radio/Radio'


const Form = styled.form`
 margin: 12px 0 12px 0;
 & p {
   margin: 0;
 }
 & label > div {
   margin-top: 8px;
   align-items: start;
 }
 & label > div > span {
   margin-top: 2px;
 }
`

const ANSWER_RATING_MAPPING = {
  YES: 0.9,
  PARTLY: 0.6,
  NO: 0.3
}

const AlignmentRatingModal = ({ onSubmit, onCancel }) => {
  const [selected, setSelected] = useState()
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    const rating = ANSWER_RATING_MAPPING[selected]
    onSubmit(rating).catch(() => setLoading(false))
  }

  const title = (
    <Typography id="dialog-label" variant="heading3">
      <FormattedMessage
        id="owner.AlignmentRatingModal.title"
        defaultMessage="Get your Alignment score"
      />
    </Typography>
  )

  const body = (
    <div>
      <Typography.p id="dialog-desc" weight="light">
        <FormattedMessage
          id="owner.AlignmentRatingModal.description"
          defaultMessage="To translate your theme sorting into your Alignment score, you must answer the question:"
        />
      </Typography.p>
      <br />
      <Heading.h4>
        <FormattedMessage
          id="owner.AlignmentRatingModal.form.title"
          defaultMessage="Are participants aligned on what's important?"
        />
      </Heading.h4>
      <Form>
        <label>
          <Radio checked={selected === 'YES'} onChange={() => setSelected('YES')}>
            <p>
              <Typography weight="bold" variant="medium">
                <FormattedMessage
                  id="owner.AlignmentRatingModal.option1.label"
                  defaultMessage="Yes."
                />
              </Typography>
              &nbsp;
              <Typography weight="light" variant="medium">
                <FormattedMessage
                  id="owner.AlignmentRatingModal.option1.description"
                  defaultMessage="It was easy to sort the question-data into a few distinctive themes."
                />
              </Typography>
            </p>
          </Radio>
        </label>
        <label>
          <Radio checked={selected === 'PARTLY'} onChange={() => setSelected('PARTLY')}>
            <p>
              <Typography weight="bold" variant="medium">
                <FormattedMessage
                  id="owner.AlignmentRatingModal.option2.label"
                  defaultMessage="Partly."
                />
              </Typography>
              &nbsp;
              <Typography weight="light" variant="medium">
                <FormattedMessage
                  id="owner.AlignmentRatingModal.option2.description"
                  defaultMessage="The question-data could be sorted into some clear themes, but there were also some or a lot of questions that didn’t fit the overall sorting."
                />
              </Typography>
            </p>
          </Radio>
        </label>
        <label>
          <Radio checked={selected === 'NO'} onChange={() => setSelected('NO')}>
            <p>
              <Typography weight="bold" variant="medium">
                <FormattedMessage
                  id="owner.AlignmentRatingModal.option3.label"
                  defaultMessage="No."
                />
              </Typography>
              &nbsp;
              <Typography weight="light" variant="medium">
                <FormattedMessage
                  id="owner.AlignmentRatingModal.option3.description"
                  defaultMessage="The question-data could not be sorted into a reasonable number of meaningful themes, and there were no clear prioritization."
                />
              </Typography>
            </p>
          </Radio>
        </label>
      </Form>
    </div>
  )

  const modalProps = {
    wide: true,
    title,
    loading,
    onCancel,
    onSubmit: handleSubmit,
    confirm: true,
    incomplete: !selected
  }

  return (
    <GenericModal {...modalProps}>
      {body}
    </GenericModal>
  )
}

AlignmentRatingModal.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

AlignmentRatingModal.defaultProps = {
  onSubmit: () => { },
  onCancel: () => { }
}

export default AlignmentRatingModal
