import Immutable from 'immutable'
import isEqual from 'lodash/isEqual'

let INITIAL_STATE = Immutable.fromJS({
  isLoading: false,
  error: null,
  // Raw question data
  data: {},
  // Results of searches and their input parameters
  searches: [],
  // Own question activity in a given qvest
  activity: {}
})

const questions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SEND_ANSWER_REQUEST':
    case 'SEND_QUESTION_REQUEST':
    case 'MARK_QUESTION_READ_REQUEST':
    case 'GET_QUESTIONS_REQUEST':
    case 'SEARCH_QUESTIONS_REQUEST':
      return state.merge(state, {
        isLoading: true,
        error: null
      })
    case 'GET_QUESTIONS_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').merge(action.data),
        activity: state.get('activity').set(action.qvestId, Immutable.List(action.ids))
      })
    case 'SEARCH_QUESTIONS_SUCCESS':
      // TODO: Limit number of cached searches/questions?
      return state.merge({
        isLoading: false,
        data: state.get('data').merge(action.data),
        searches: state.get('searches').push({
          parameters: action.parameters,
          ids: action.ids
        })
      })
    case 'SEND_ANSWER_SUCCESS':
      return state.merge(state, {
        data: state.get('data')
          .set(action.newQuestion.questionId, Immutable.fromJS(action.newQuestion))
          .set(action.oldQuestion.questionId, Immutable.fromJS(action.oldQuestion)),
        activity: state.get('activity').update(action.qvestId, ids => ids.push(action.newQuestion.questionId)),
        isLoading: false,
        error: null
      })
    case 'SEND_QUESTION_SUCCESS':
    case 'MARK_QUESTION_READ_SUCCESS':
      return state.merge(state, {
        data: state.get('data').set(action.question.questionId, Immutable.fromJS(action.question)),
        isLoading: false,
        error: null
      })
    case 'SEND_ANSWER_FAILURE':
    case 'SEND_QUESTION_FAILURE':
    case 'MARK_QUESTION_READ_FAILURE':
    case 'GET_QUESTIONS_FAILURE':
    case 'SEARCH_QUESTIONS_FAILURE':
      return state.merge(state, {
        isLoading: false,
        error: action.error
      })
    default:
      return state
  }
}

export function filterQuestionsById(questions, questionId) {
  if(!questions.data.hasOwnProperty(questionId)) {
    return null
  }
  return questions.data[questionId]
}

export function filterQuestionSearchResult(questions, searchParameters) {
  // TODO: Also check how old result is and invalidate eventually??
  const search = questions.searches.find(s => isEqual(s.parameters, searchParameters))
  if (search) {
    return search.ids.map(id => questions.data[id])
  } else {
    return null
  }
}

export function filterQuestionSearchResultDict(questions, searchParameters) {
  // TODO: Also check how old result is and invalidate eventually??
  const search = questions.searches.find(s => isEqual(s.parameters, searchParameters))
  if (search) {
    return search.ids.reduce((obj, id) => { obj[id] = questions.data[id]; return obj }, {})
  } else {
    return null
  }
}

export function filterOwnQuestions(questions, qvestId) {
  if (!questions.activity.hasOwnProperty(qvestId)) {
    return null
  }
  return questions.activity[qvestId]
    .map(id => questions.data[id])
    .sort(({ createdAt, questionSentAt, answerSentAt }) => {
      const now = new Date()
      const relevantDate = answerSentAt || questionSentAt || createdAt
      return now - relevantDate
    })
}


export default questions
