import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Input from '../../common/Input/Input'
import Radio from '../../common/Radio/Radio'
import Typography from '../../common/Typography/Typography'

const GROUPS = ['Department', 'Title']

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) { margin-right: 30px; }
`

const LabelRow = styled.label`
  display: flex;
  align-items: center;
`

export default class GroupingForm extends Component {
  constructor(props) {
    super(props)
    this.handleGroupingChange = this.handleGroupingChange.bind(this)
    this.focusInput = this.focusInput.bind(this)
  }

  handleGroupingChange(desiredGrouping) {
    return (event) => {
      const { onChangeGrouping, grouping } = this.props
      const name = GROUPS.indexOf(desiredGrouping) !== -1
        ? desiredGrouping
        : event.target.value
      onChangeGrouping({ ...grouping, name })
    }
  }

  focusInput() {
    this.input.focus()
  }

  render() {
    const { grouping, disabled } = this.props
    const hasCustomGrouping = (GROUPS.indexOf(grouping.name) === -1)
    return (
      <ComponentWrapper>
        <label>
          <Radio
            checked={grouping.name === GROUPS[0]}
            disabled={disabled}
            onChange={this.handleGroupingChange(GROUPS[0])}
          >
            <Typography clickable={!disabled} variant="small">Department</Typography>
          </Radio>
        </label>
        <label>
          <Radio
            checked={grouping.name === GROUPS[1]}
            disabled={disabled}
            onChange={this.handleGroupingChange(GROUPS[1])}
          >
            <Typography clickable={!disabled} variant="small">Title</Typography>
          </Radio>
        </label>
        <LabelRow>
          <Radio
            checked={hasCustomGrouping}
            disabled={disabled}
            onChange={this.focusInput}
          />
          <Input
            ref={x => { this.input = x }}
            placeholder="Define own"
            value={hasCustomGrouping && grouping.name ? grouping.name : ''}
            disabled={disabled}
            onChange={this.handleGroupingChange()}
          />
        </LabelRow>
      </ComponentWrapper>
    )
  }
}

GroupingForm.propTypes = {
  grouping: PropTypes.shape({
    name: PropTypes.string
  }),
  disabled: PropTypes.bool,
  onChangeGrouping: PropTypes.func
}

GroupingForm.defaultProps = {
  grouping: { name: GROUPS[0] },
  disabled: false,
  onChangeGrouping: () => { },
}
