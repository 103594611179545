import React, { Component } from 'react'
import { connect } from 'react-redux'

import { filterSubscriptionValidityByOrganization } from '../../reducers/subscription'
import Modal from '../../components/common/Modal/Modal'
import { getSubscriptionsLight } from '../../actions/subscriptionActions'
import AccessDeniedModal from '../../components/common/AccessDeniedModal/AccessDeniedModal'


class OwnerSubscriptionOverlay extends Component {
  componentDidMount() {
    const { dispatch, organizationId } = this.props
    dispatch(getSubscriptionsLight(organizationId))
  }

  render() {
    const { subscriptionStore, organizationId } = this.props
    const validity = filterSubscriptionValidityByOrganization(subscriptionStore, organizationId)
    const isInvalidSubscription = (validity && validity.general !== 'OK')
    if (isInvalidSubscription) {
      return (
        <Modal>
          <AccessDeniedModal variant="SUBSCRIPTION_INACTIVE" />
        </Modal>
      )
    }
    return null
  }
}

function mapStateToProps(state) {
  return {
    subscriptionStore: state.subscription.toJS(),
  }
}

export default connect(mapStateToProps)(OwnerSubscriptionOverlay)
