import React from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import TimelineItem from './TimelineItem'

import qnaExchange from '../../../static/img/qna-exchange.svg'
import qnaExchangeColor from '../../../static/img/qna-exchange-color.svg'
import invitationMail from '../../../static/img/invitationmail.svg'
import invitationMailDisabled from '../../../static/img/invitationmail-disabled.svg'
import invitationMailColor from '../../../static/img/invitationmail-color.svg'
import output from '../../../static/img/output.svg'
import outputColor from '../../../static/img/output-color.svg'

const messages = defineMessages({
  pagePlaceholder: { id: 'owner.SchedulingTimeline.pagePlaceholder', defaultMessage: '2 days recommended' },
  qnaPlaceholder: { id: 'owner.SchedulingTimeline.qnaPlaceholder', defaultMessage: '3 days recommended' },
  outputPlaceholder: { id: 'owner.SchedulingTimeline.outputPlaceholder', defaultMessage: '14 days' },
  outputDescription: { id: 'owner.SchedulingTimeline.outputDescription', defaultMessage: '14 days' },
})

const ComponentRoot = styled.div`
  display: flex;
  width: 100%;
  & > * { width: 33.33%; }
  & > *:not(:last-child) {
    width: calc(33.33% + 25px);
    margin-right: -25px
  }
`

// Difference between two dates in days (rounded to one decimal precision)
function diffDays(date1, date2) {
  const days = Math.round((date2.getTime() - date1.getTime()) / 8640000.0) / 10.0
  return <FormattedMessage
    id="owner.SchedulingTimeline.dayCount"
    defaultMessage="{dayCount} {dayCount, plural, one {day} other {days}}"
    values={{ dayCount: days }}
  />
}

const SchedulingTimeline = ({ isInviteEnabled, inviteAt, openAt, closeAt, intl }) => {
  let inviteDiff
  if (isInviteEnabled && inviteAt && openAt) {
    inviteDiff = diffDays(inviteAt, openAt)
  }

  let openDiff
  if (openAt && closeAt) {
    openDiff = diffDays(openAt, closeAt)
  }

  return (
    <ComponentRoot>
      <TimelineItem
        placeholder={intl.formatMessage(messages.pagePlaceholder)}
        img={isInviteEnabled
          ? (inviteAt ? invitationMailColor : invitationMail)
          : invitationMailDisabled}
        disabled={!isInviteEnabled}
        description={inviteDiff}
        active={isInviteEnabled && inviteAt}
      />
      <TimelineItem
        placeholder={intl.formatMessage(messages.qnaPlaceholder)}
        img={openAt ? qnaExchangeColor : qnaExchange}
        description={openDiff}
        active={!!openAt}
      />
      <TimelineItem
        placeholder={intl.formatMessage(messages.outputPlaceholder)}
        description={closeAt && intl.formatMessage(messages.outputDescription)}
        img={closeAt ? outputColor : output}
        active={!!closeAt}
      />
    </ComponentRoot>
  )
}

SchedulingTimeline.propTypes = {
  inviteAt: PropTypes.instanceOf(Date),
  openAt: PropTypes.instanceOf(Date),
  closeAt: PropTypes.instanceOf(Date),
  isInviteEnabled: PropTypes.bool,
}

SchedulingTimeline.defaultProps = {
  isInviteEnabled: false,
}

export default injectIntl(SchedulingTimeline)
