import * as api from './apiClient'
import { shouldFetchSubscribeLinks } from '../utils/subscriptionUtils'

export function getPlans() {
  return dispatch => {
    dispatch({ type: 'GET_PLANS_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q {
            plan {
              items {
                id,
                name,
                price,
                metaData {
                  analyticsTier,
                  customLogo
                }
              }
            }
          }
        `,
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const plans = data.plan.items
        dispatch({ type: 'GET_PLANS_SUCCESS', plans })
      })
      .catch(error => {
        dispatch({ type: 'GET_PLANS_FAILURE', error })
      })
  }
}

export function getSubscriptions(organizationId) {
  return dispatch => {
    dispatch({ type: 'GET_SUBSCRIPTIONS_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q($organizationId:String!) {
            subscription(organizationId:$organizationId) {
              paymentLink,
              items {
                id,
                status,
                isActive,
                cardStatus,
                currentTermEnd,
                totalDues, 
                plan {
                  id,
                  name,
                  price,
                  metaData {
                    analyticsTier,
                    customLogo
                  }
                },
                scheduledPlan {
                  id,
                  name,
                  price
                }
              },
              validity {
                general,
                items {
                  type,
                  name
                }
              }
            }
          }
        `,
        variables: { organizationId }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        let subscriptions = data.subscription
        dispatch({ type: 'GET_SUBSCRIPTIONS_SUCCESS', organizationId, subscriptions })
        return subscriptions
      })
      .catch(errors => {
        dispatch({ type: 'GET_SUBSCRIPTIONS_FAILURE', errors })
      })
  }
}

export function getSubscriptionsLight(organizationId) {
  return dispatch => {
    dispatch({ type: 'GET_SUBSCRIPTIONS_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q($organizationId:String!) {
            subscription(organizationId:$organizationId) {
              items {
                id,
                status,
                isActive,
                currentTermEnd,
                plan {
                  id,
                  name,
                  metaData {
                    analyticsTier,
                    customLogo
                  }
                },
                scheduledPlan {
                  id,
                  name
                }
              },
              validity {
                general,
                items {
                  type,
                  name
                }
              }
            }
          }
        `,
        variables: { organizationId }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        let subscriptions = data.subscription
        dispatch({ type: 'GET_SUBSCRIPTIONS_SUCCESS', organizationId, subscriptions })
        return subscriptions
      })
      .catch(errors => {
        dispatch({ type: 'GET_SUBSCRIPTIONS_FAILURE', errors })
      })
  }
}

export function getSubscriptionsValidity(organizationId) {
  return dispatch => {
    dispatch({ type: 'GET_SUBSCRIPTIONS_VALIDITY_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q {
            subscription(organizationId:"${organizationId}") {
              validity {
                general,
                items {
                  type,
                  name
                }
              }
            }
          }
        `,
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        let subscriptions = data.subscription
        dispatch({ type: 'GET_SUBSCRIPTIONS_VALIDITY_SUCCESS', organizationId, subscriptions })
        return subscriptions
      })
      .catch(errors => {
        dispatch({ type: 'GET_SUBSCRIPTIONS_VALIDITY_FAILURE', errors })
      })
  }
}

export function getInvoices(organizationId) {
  return dispatch => {
    dispatch({ type: 'GET_INVOICES_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q($organizationId:String!) {
            invoice(organizationId:$organizationId, includeUpcoming:true) {
              items {
                id,
                date,
                status,
                total,
                currency,
                url
              }
            }
          }
        `,
        variables: { organizationId }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const invoices = data.invoice
        dispatch({ type: 'GET_INVOICES_SUCCESS', organizationId, invoices })
      })
      .catch(errors => {
        dispatch({ type: 'GET_INVOICES_FAILURE', errors })
      })
  }
}

export function updateSubscribedPlan(organizationId, subscriptionId, planId) {
  return dispatch => {
    dispatch({ type: 'UPDATE_SUBSCRIBED_PLAN_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
              mutation m($subscriptionId:String!, $update:SubscriptionUpdate!) {
                updateSubscription(subscriptionId:$subscriptionId, update:$update) {
                  id,
                  status,
                  isActive,
                  cardStatus,
                  currentTermEnd,
                  totalDues, 
                  plan {
                    id,
                    name,
                    price,
                    metaData {
                      analyticsTier,
                      customLogo
                    }
                  },
                  scheduledPlan {
                    id,
                    name,
                    price
                  }
                }
              }
            `,
        variables: {
          subscriptionId,
          update: { planId }
        }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        let subscription = data.updateSubscription
        dispatch({ type: 'UPDATE_SUBSCRIBED_PLAN_SUCCESS', organizationId, subscription })
        dispatch(getInvoices(organizationId))
      })
      .catch(errors => {
        dispatch({ type: 'UPDATE_SUBSCRIBED_PLAN_FAILURE', errors })
      })
  }
}

export function cancelSubscription(organizationId, subscriptionId) {
  return dispatch => {
    dispatch({ type: 'CANCEL_SUBSCRIPTION_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($subscriptionId:String!) {
            cancelSubscription(subscriptionId:$subscriptionId) {
              id,
              status,
              cardStatus,
              currentTermEnd, 
              totalDues, 
              plan {
                id,
                name,
                price,
                metaData {
                  analyticsTier,
                  customLogo
                }
              },
              scheduledPlan {
                id,
                name,
                price
              }
            }
          }
        `,
        variables: { subscriptionId }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        let subscription = data.cancelSubscription
        dispatch({ type: 'CANCEL_SUBSCRIPTION_SUCCESS', organizationId, subscription })
      })
      .catch(errors => {
        dispatch({ type: 'CANCEL_SUBSCRIPTION_FAILURE', errors })
      })
  }
}

export function undoCancelSubscription(organizationId, subscriptionId) {
  return dispatch => {
    dispatch({ type: 'UNDO_CANCEL_SUBSCRIPTION_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($subscriptionId:String!) {
            undoCancelSubscription(subscriptionId:$subscriptionId) {
              id,
              status,
              cardStatus,
              currentTermEnd,
              totalDues,  
              plan {
                id,
                name,
                price,
                metaData {
                  analyticsTier,
                  customLogo
                }
              },
              scheduledPlan {
                id,
                name,
                price
              }
            }
          }
        `,
        variables: { subscriptionId }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        let subscription = data.undoCancelSubscription
        dispatch({ type: 'UNDO_CANCEL_SUBSCRIPTION_SUCCESS', organizationId, subscription })
      })
      .catch(errors => {
        dispatch({ type: 'UNDO_CANCEL_SUBSCRIPTION_FAILURE', errors })
      })
  }
}

export function getSubscribeLinks(organizationId, subscriptionId) {
  return (dispatch) => {
    dispatch({ type: 'GET_SUBSCRIBE_LINKS_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q($subscriptionId:String!) {
            starter: subscribeLink(subscriptionId:$subscriptionId, planId:"starter"),
            premium: subscribeLink(subscriptionId:$subscriptionId, planId:"premium"),
            pro: subscribeLink(subscriptionId:$subscriptionId, planId:"pro"),
            starter_yearly: subscribeLink(subscriptionId:$subscriptionId, planId:"starter_yearly"),
            premium_yearly: subscribeLink(subscriptionId:$subscriptionId, planId:"premium_yearly"),
            pro_yearly: subscribeLink(subscriptionId:$subscriptionId, planId:"pro_yearly")
          }
        `,
        variables: { subscriptionId }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(response => {
        const subscribeLinks = response.data
        dispatch({ type: 'GET_SUBSCRIBE_LINKS_SUCCESS', organizationId, subscribeLinks })
      })
      .catch(errors => {
        dispatch({ type: 'GET_SUBSCRIBE_LINKS_FAILURE', errors })
      })
  }
}

export function togglePlanSelector(show, organizationId, variant = 'overall') {
  return dispatch => {
    dispatch({ type: 'TOGGLE_PLAN_SELECTOR', show, variant })
    if (show) {
      dispatch(getSubscriptions(organizationId))
        .then(subscriptions => {
          if (shouldFetchSubscribeLinks(subscriptions)) {
            dispatch(getSubscribeLinks(organizationId, subscriptions.items[0].id))
          }
        })
    }
  }
}
