import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import styled, { ThemeProvider, withTheme } from 'styled-components'
import BlueOnWhiteTheme from '../../blue-on-white-theme'

import Divider from '../../common/Divider/Divider'
import Modal from '../../common/Modal/Modal'
import RecipientSelector from '../RecipientSelector/RecipientSelector'
import SubmitButton from '../SubmitButton/SubmitButton'
import AskInput from '../AskInput/AskInput'
import RecipientField from '../RecipientField/RecipientField'
import { makeAvatarColorScale } from '../../../utils/d3Utils'
import { trackEvent } from '../../../utils/trackingUtils'
import QuestioningGuideModal from '../QuestioningGuideModal/QuestioningGuideModal'
import AskDisclaimer from '../AskDisclaimer/AskDisclaimer'

const messages = defineMessages({
  closedContentOverride: { id: 'qvest.QvestAsk.closedContentOverride', defaultMessage: 'Qvest is closed' },
  contentPlaceholder: { id: 'qvest.QvestAsk.contentPlaceholder', defaultMessage: 'Ask your question here' },
  recipientPlaceholder: { id: 'qvest.QvestAsk.recipientPlaceholder', defaultMessage: 'Search ...' }
})


const WrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledSubmit = styled(SubmitButton)`
  align-self: flex-end;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  & > *:first-child {
    margin-top: 10px;
    flex-grow: 1;
  }
  & > *:last-child {
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }
`

const Label = styled.label`
  font-family: ${({ theme }) => theme.font2};
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.one[0]};
`


class QvestAsk extends Component {
  state = {
    question: null,
    recipientId: null,
    focused: false,
    loading: false,
    modal: null
  }

  showModal = (modal) => {
    this.setState({ modal })
  }

  hideModal = () => {
    this.setState({ modal: null })
  }

  onParticipantSelect = (recipientId) => {
    const { isJam, onSubmit } = this.props
    this.setState({ modal: null, recipientId })
    if (isJam) {
      onSubmit({
        content: this.state.question,
        recipientId
      })
    }
  }

  handleChange = (event) => {
    this.setState({ question: event.target.value })
  }

  handleSubmit = async (event) => {
    const { isJam, onSubmit, onRefresh } = this.props
    const { question, recipientId } = this.state
    event.preventDefault()
    this.setState({ loading: true })
    if (isJam) {
      await onRefresh()
      window.scrollTo(0, 0)
      this.showModal('participant')
    } else {
      await onSubmit({
        content: question,
        recipientId
      })
    }
    this.setState({ loading: false })
  }

  handleBrowseClick = (event) => {
    event.preventDefault()
    window.scrollTo(0, 0)
    this.showModal('participant')
  }

  handleGuideClick = () => {
    trackEvent('Guides', 'Link click', 'questioning guidelines')
    this.showModal('guide')
  }

  renderRecipientField(avatarColors) {
    const { participants, isJam } = this.props
    const { recipientId } = this.state

    if (isJam) return null

    let selectedParticipant
    if (recipientId) {
      selectedParticipant = participants.find(p => p.participantId === recipientId)
    }

    return (
      <Fragment>
        <Label>
          <FormattedMessage id="qvest.QvestAsk.recipientHeading" defaultMessage="Send to:" />
        </Label>
        <Divider height="15" />
        <RecipientField
          colors={avatarColors}
          participant={selectedParticipant}
          onClick={this.handleBrowseClick}
        />
      </Fragment>
    )
  }

  renderGuideModal() {
    return (
      <Modal onBackgroundClick={this.hideModal} onEscape={this.hideModal}>
        <QuestioningGuideModal onSubmit={this.hideModal} />
      </Modal>
    )
  }

  renderParticipantModal(groups, avatarColors) {
    const { participants, isJam } = this.props
    return (
      <ThemeProvider theme={BlueOnWhiteTheme}>
        <Modal absolute onBackgroundClick={this.hideModal} onEscape={this.hideModal}>
          <RecipientSelector
            isJam={isJam}
            toggleModal={this.hideModal}
            onSelect={this.onParticipantSelect}
            recipients={participants}
            avatarColors={avatarColors}
            groups={groups}
          />
        </Modal>
      </ThemeProvider>
    )
  }

  renderSubmit() {
    const { error, isJam } = this.props
    const { question, recipientId, loading } = this.state
    const variant = isJam ? 'SELECT_RECIPIENT' : 'SEND_QUESTION'
    const errorMessage = error ? error.message : ''
    const cta = (question && (isJam || recipientId))
    return (
      <StyledSubmit
        variant={variant}
        cta={cta}
        disabled={!cta}
        isLoading={loading}
        errorMessage={errorMessage}
      />
    )
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    this.setState({ focused: false })
  }

  render() {
    const { intl, theme, closed, participants, groupings, isJam } = this.props
    const { focused, modal } = this.state

    const closeContentOverride = intl.formatMessage(messages.closedContentOverride)

    let groups
    if (groupings && (groupings.length > 0)) {
      groups = groupings[0].groups
    }

    const avatarColors = makeAvatarColorScale(theme, participants, groupings)

    return (
      <Fragment>
        {(modal === 'participant') ? this.renderParticipantModal(groups, avatarColors) : null}
        {(modal === 'guide') ? this.renderGuideModal() : null}
        <WrapperForm onSubmit={this.handleSubmit}>
          <Label>
            <FormattedMessage id="qvest.QvestAsk.contentHeading" defaultMessage="Your question:" />
          </Label>
          <InputWrapper focused={focused} ref={x => { this.node = x }}>
            <div>
              <AskInput
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                placeholder={intl.formatMessage(messages.contentPlaceholder)}
                onChange={this.handleChange}
                value={closed ? closeContentOverride : this.state.question}
                disabled={closed}
              />
            </div>
            {!isJam ? <AskDisclaimer variant="question" invisible={focused} onGuideClick={this.handleGuideClick} /> : null}
          </InputWrapper>
          {this.renderRecipientField(avatarColors)}
          <Divider height="50" />
          {!closed ? this.renderSubmit() : null}
        </WrapperForm>
      </Fragment>
    )
  }
}

QvestAsk.propTypes = {
  onSubmit: PropTypes.func
}

export default injectIntl(withTheme(QvestAsk))
