import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { makeGroupColorScales } from '../../../utils/d3Utils'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'

import {
  Title,
  Content,
  Card,
  MainCard,
  SubContent,
} from '../AnalyticsLayout/AnalyticsLayout'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Tooltip from '../../common/Tooltip/Tooltip'
import RadialBarChart from '../../common/RadialBarChart/RadialBarChart'
import { Legend } from 'data-viz'

const MainCardLayout = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33%;
    max-width: 450px;
    text-align: center;
  }
  & > :nth-child(2) {
    margin: 0 auto;
    height: 47%;
    width: 240px;
  }
  & > :nth-child(3) {
    margin: 0 auto;
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

const MediumCardLayout = styled.div`
  height: 100%;
  & > :first-child {
    width: 100%;
  }
  & > :nth-child(2) {
    margin-top: 30px;
    width: 100%;
  }
`

const MediumDataLayout = styled.div`
  display: flex;
  & > :first-child {
    flex: 1;
    margin-right: 80px;
    & > * {
      margin: 0 auto;
    }
  }
  & > :nth-child(2) {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const SmallCardLayout = styled.div`
  & > :nth-child(2) {
    width: 143px;
    margin: 25px auto;
  }
`

function sum(arr) {
  return arr.reduce((a, b) => a + b, 0)
}

function sumQuestions(activity) {
  return sum(activity.map(p => p.questionReceivedCount))
}

function percentageQuestions(activity, totalQuestionCount) {
  const count = sumQuestions(activity)
  return (count / totalQuestionCount)
}

function groupDistribution(activity, groupColors) {
  const grouped = groupBy(activity, p => p.groupId)
  const distribution = Object.keys(grouped).map(groupId => ({
    groupId,
    label: <FormattedMessage
      id="owner.AnalyticsKeyStakeholders.stakeholders"
      defaultMessage="{label} ({value} {value, plural, one {stakeholder} other {stakeholders}})"
      values={{ label: grouped[groupId][0].groupName, value: grouped[groupId].length }}
    />,
    fill: groupColors(groupId),
    value: grouped[groupId].length
  }))
  return sortBy(distribution, d => -d.value) // Highest value first
}

export function formatData(activity, groups, colors) {

  const outliers = activity.filter(p => p.isOutlier)
  const potentialOutliers = activity.filter(p => p.isPotentialOutlier)
  const withQuestions = activity.filter(p => !p.isOutlier && !p.isPotentialOutlier && (p.questionReceivedCount > 0))
  const withoutQuestions = activity.filter(p => !p.isOutlier && !p.isPotentialOutlier && (p.questionReceivedCount === 0))

  const totalQuestionCount = sumQuestions(activity)
  const questionPercentage = {
    outliers: percentageQuestions(outliers, totalQuestionCount),
    potentialOutliers: percentageQuestions(potentialOutliers, totalQuestionCount),
    withQuestions: percentageQuestions(withQuestions, totalQuestionCount)
  }

  return {
    outliers,
    potentialOutliers,
    questionPercentage,
    withQuestions,
    withoutQuestions,
    outlierDistribution: groupDistribution(outliers, colors),
    potentialDistribution: groupDistribution(potentialOutliers, colors)
  }
}

class AnalyticsKeyStakeholders extends Component {
  renderMainHeading(count) {
    return (
      <Title>
        <span>
          <Tooltip.Area>
            <Tooltip.Reference>
              <Heading variant="heading2" primary>
                <FormattedMessage
                  id="owner.KeyStakeholdersPage.mainHeading.amount"
                  defaultMessage="{count} key {count, plural, one {stakeholder} other {stakeholders}}"
                  values={{ count }}
                />
              </Heading>
            </Tooltip.Reference>
            <Tooltip>
              <Heading.h3>
                <FormattedMessage id="owner.AnalyticsKeyStakeholders.tooltipTitle" defaultMessage="Key stakeholders explanation" />
              </Heading.h3>
              <Typography variant="medium" weight="light">
                <FormattedMessage
                  id="owner.AnalyticsKeyStakeholders.tooltipBody"
                  defaultMessage="A stakeholder is considered a key stakeholder when he or she deviates significantly from the remaining stakeholders. The deviation is measured in the number of received questions. Similarly, a stakeholder is considered a potential key stakeholder when he or she is showing signs of deviation in the number of received questions."
                />
              </Typography>
            </Tooltip>
          </Tooltip.Area>
          <span>&nbsp;</span>
          <Heading variant="heading2">
            <FormattedMessage
              id="owner.KeyStakeholdersPage.mainHeading.description"
              defaultMessage="received significantly more questions than a typical stakeholder in this Qvest"
            />
          </Heading>
        </span>
      </Title>
    )
  }

  render() {
    const { activity, groups, theme } = this.props
    const groupIds = groups.map(g => g.groupId)
    const groupColors = makeGroupColorScales(theme, groupIds).primaryColors
    const data = formatData(activity, groups, groupColors)

    return (
      <Content>
        <MainCard highlighted>
          <MainCardLayout>
            <div>
              <Heading variant="heading3">{this.renderMainHeading(data.outliers.length)}</Heading>
            </div>
            <div>
              <RadialBarChart
                primary
                value={data.questionPercentage.outliers}
                text="Of all questions"
                height={240}
                width={240}
              />
            </div>
            <Legend title="" data={data.outlierDistribution} />
          </MainCardLayout>
        </MainCard>
        <SubContent>
          <Card>
            <MediumCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage
                    id="owner.KeyStakeholdersPage.cardHeading.potential"
                    defaultMessage="{count} potential key {count, plural, one {stakeholder} other {stakeholders}}"
                    values={{ count: data.potentialOutliers.length }}
                  />
                </Heading.h3>
                <Typography variant="medium" transparent>
                  <FormattedMessage
                    id="owner.KeyStakeholdersPage.cardDescription.potential"
                    defaultMessage="received more questions than a typical stakeholder"
                  />
                </Typography>
              </Title>
              <MediumDataLayout>
                <div>
                  <RadialBarChart
                    primary
                    value={data.questionPercentage.potentialOutliers}
                    height={143}
                    width={143}
                  />
                </div>
                <Legend title="" data={data.potentialDistribution} />
              </MediumDataLayout>
            </MediumCardLayout>
          </Card>
          <Card>
            <SmallCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage
                    id="owner.KeyStakeholdersPage.cardHeading.others"
                    defaultMessage="{count} other {count, plural, one {stakeholder} other {stakeholders}}"
                    values={{ count: data.withQuestions.length }}
                  />
                </Heading.h3>
                <Typography variant="medium" transparent>
                  <FormattedMessage
                    id="owner.KeyStakeholdersPage.cardDescription.others"
                    defaultMessage="received questions"
                  />
                </Typography>
              </Title>
              <div>
                <RadialBarChart
                  primary
                  value={data.questionPercentage.withQuestions}
                  height={143}
                  width={143}
                />
              </div>
            </SmallCardLayout>
          </Card>
          <Card>
            <SmallCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage
                    id="owner.KeyStakeholdersPage.cardHeading.remaining"
                    defaultMessage="{count} {count, plural, one {stakeholder} other {stakeholders}}"
                    values={{ count: data.withoutQuestions.length }}
                  />
                </Heading.h3>
                <Typography variant="medium" transparent>
                  <FormattedMessage
                    id="owner.KeyStakeholdersPage.cardDescription.remaining"
                    defaultMessage="didn't receive any questions"
                  />
                </Typography>
              </Title>
              <RadialBarChart
                primary
                value={0}
                height={143}
                width={143}
              />
            </SmallCardLayout>
          </Card>
        </SubContent>
      </Content>
    )
  }

  static propTypes = {
    activity: PropTypes.arrayOf(PropTypes.shape({
      participantId: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      groupId: PropTypes.string,
      groupName: PropTypes.string,
      questionReceivedCount: PropTypes.number,
      answerSentCount: PropTypes.number,
      questionSentCount: PropTypes.number,
      isOutlier: PropTypes.bool,
      isPotentialOutlier: PropTypes.bool
    })),
    groups: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.string,
      name: PropTypes.string
    }))
  }
}

export default withTheme(AnalyticsKeyStakeholders)
