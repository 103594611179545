import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../../common/Typography/Typography'
import Button from '../../../common/Button/Button'
import Modal from '../../../common/Modal/Modal'
import Card from '../../../common/Card/Card'
import AccountForm from './AccountForm'
import TerminateAccountModal from '../../TerminateAccountModal/TerminateAccountModal'


const ButtonFooter = styled.div`
  margin-top: 20px;
`

class UserSettingsCard extends Component {
  constructor(props) {
    super(props)
    this.state = { showTerminationModal: false }
    this.handleToggleTerminationModal = this.handleToggleTerminationModal.bind(this)
  }

  handleToggleTerminationModal() {
    this.setState({
      showTerminationModal: !this.state.showTerminationModal
    })
  }

  renderError() {
    const { error } = this.props
    if (!error) return null
    return (
      <Card.Message error>
        <Typography cta tertiary variant="small">
          {error.message === 'EMAIL_ALREADY_KNOWN'
            ? <FormattedMessage
              id="owner.AccountPage.emailError"
              defaultMessage="Email already registered with a different account"
            />
            : <FormattedMessage
              id="owner.AccountPage.dedaultError"
              defaultMessage="Unfortunately, an unexpected error occurred"
            />}
        </Typography>
      </Card.Message>
    )
  }

  renderTerminationModal() {
    return (
      <Modal>
        <TerminateAccountModal onClose={this.handleToggleTerminationModal} />
      </Modal>
    )
  }

  renderTerminateAccount() {
    return (
      <ButtonFooter>
        <Button outline onClick={this.handleToggleTerminationModal}>
          Terminate Account
        </Button>
      </ButtonFooter>
    )
  }

  render() {
    const { user, isLoading, error, onSave } = this.props
    const { showTerminationModal } = this.state
    return (
      <div>
        {showTerminationModal ? this.renderTerminationModal() : null}
        <Card>
          <Card.Header>
            <Typography variant="heading3">
              <FormattedMessage id="owner.AccountPage.title" defaultMessage="User settings" />
            </Typography>
          </Card.Header>
          {this.renderError()}
          <Card.Body>
            <AccountForm
              user={user}
              isLoading={isLoading}
              error={error}
              onUserSave={onSave}
            />
            {!isLoading ? this.renderTerminateAccount() : null}
          </Card.Body>
        </Card>
      </div>
    )
  }
}

UserSettingsCard.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    locale: PropTypes.string,
    timeZone: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  onSave: PropTypes.func
}

UserSettingsCard.defaultProps = {
  onSave: () => { }
}

export default UserSettingsCard
