import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import Table from '../../common/Table/Table'
import { HoverIcons } from '../../common/Table/Table'
import Card from '../../common/Card/Card'
import Icon from '../../common/Icon/Icon'
import Button from '../../common/Button/Button'
import Radio from '../../common/Radio/Radio'
import Input from '../../common/Input/Input'
import GenericModal from '../../common/GenericModal/GenericModal'


const ComponentRoot = styled.div`
  width: 670px;
`

const CloseButton = styled.button`
  background-color: transparent;
  border-style: none;
  cursor: pointer;
  /* phasing out clickable prop on Icon for better accessibility */
  && > * { cursor: pointer; }
`

const PreTableSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 250px;
  border-width: 1px 0;
  border-style: solid;
  border-color:  ${({ theme }) => theme.one[3]};
`

const RadioCell = styled.td`
  width: 100px;
`

const IconButton = styled(Button)`
  &:disabled {
    cursor: not-allowed;
  }

  & > * {
    display: block;
    width: 14px;
  }
`

const CellInput = styled(Input)`
  height: 38px;
  margin-left: -9px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`

export const EditIcons = styled.div`
  display: flex;
  float: right;
  & > *:not(:first-child) { margin-left: 8px; }
`

const isActive = (themeSet) => (themeSet.label === 'DEFAULT')

const ThemeSetRow = (props) => {
  const {
    themeSet,
    active,
    hidden,
    disabled,
    editing,
    onStartEdit,
    onSetActive,
    onSave,
    onDelete,
    onCancel
  } = props
  const [name, setName] = useState(themeSet ? themeSet.name : '')

  const handleSave = async () => {
    await onSave({ name })
    setName(themeSet ? themeSet.name : '')
  }

  const handleCancel = () => {
    setName(themeSet ? themeSet.name : '')
    onCancel()
  }

  const handleKeyboardEvent = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
      handleSave()
    } else if (event.key === 'Escape') {
      event.stopPropagation()
      handleCancel()
    }
  }

  if (hidden) {
    return null
  }

  if (editing) {
    return (
      <Table.Row>
        <RadioCell>
          <Radio checked={active} disabled />
        </RadioCell>
        <td>
          <CellInput
            value={name}
            disabled={disabled}
            onKeyUp={handleKeyboardEvent}
            onChange={({ target }) => setName(target.value)} autoFocus
          />
        </td>
        <td>
          <EditIcons>
            <IconButton size="tiny" title="Save" disabled={disabled} onClick={handleSave}>
              <Icon clickable tertiary variant="check" size="small" />
            </IconButton>
            <IconButton secondary size="tiny" disabled={disabled} onClick={handleCancel} title="Cancel">
              <Icon clickable tertiary variant="times" size="small" />
            </IconButton>
          </EditIcons>
        </td>
      </Table.Row>
    )
  }
  const deleteTitle = (!active ? 'Remove' : 'Active theme, cannot be removed')
  return (
    <Table.Row>
      <RadioCell>
        <Radio checked={active} disabled={disabled} onChange={() => onSetActive(themeSet.themeSetId)} />
      </RadioCell>
      <td>{themeSet.name}</td>
      <td>
        <HoverIcons>
          <IconButton size="tiny" disabled={disabled} onClick={() => onStartEdit(themeSet.themeSetId)} title="Edit">
            <Icon clickable tertiary variant="edit" size="small" />
          </IconButton>
          <IconButton secondary size="tiny" disabled={active || disabled} onClick={() => onDelete(themeSet.themeSetId)} title={deleteTitle}>
            <Icon clickable={!active && !disabled} tertiary variant="trash" size="small" />
          </IconButton>
        </HoverIcons>
      </td>
    </Table.Row>
  )
}

const NoResultsRow = ({ show }) => {
  if (!show) return null
  return (
    <Table.Row>
      <td>
        <FormattedMessage
          defaultMessage="No theme sets found."
          id="owner.ThemeSettingsModal.noResults"
        />
      </td>
    </Table.Row>
  )
}

const ThemeSettingsModal = (props) => {
  const { themeSets, onClose, onAdd, onEdit, onDelete, onSetActive } = props
  const initialActiveThemeSet = themeSets.find(isActive)
  const initialActiveId = (initialActiveThemeSet ? initialActiveThemeSet.themeSetId : null)
  const [isLoading, setIsLoading] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [activeThemeSetId, setActiveThemeSetId] = useState(initialActiveId)
  const [editingThemeSetId, setEditingThemeSetId] = useState(null)
  const [deletingThemeSet, setDeletingThemeSet] = useState(null)

  const handleSetActive = async (themeSetId) => {
    const previousThemeSetId = activeThemeSetId
    try {
      setActiveThemeSetId(themeSetId)
      setIsLoading(true)
      await onSetActive(themeSetId)
    } catch (error) {
      setActiveThemeSetId(previousThemeSetId)
      throw error
    } finally {
      setIsLoading(false)
    }

  }

  const handleStartEdit = (themeSetId) => {
    setEditingThemeSetId(themeSetId)
  }

  const handleStartAdd = () => {
    setIsAdding(true)
  }

  const handleCancelEdit = () => {
    setIsAdding(false)
    setEditingThemeSetId(null)
  }

  const handleConfirmEdit = async (patch) =>  {
    try {
      setIsLoading(true)
      await onEdit(editingThemeSetId, patch)
      setEditingThemeSetId(null)
    } finally {
      setIsLoading(false)
    }
  }

  const handleConfirmAdd = async ({ name }) => {
    try {
      setIsLoading(true)
      await onAdd(name)
      setIsAdding(false)
    } finally {
      setIsLoading(false)
    }
  }

  const handleStartDelete = (themeSet) => {
    setDeletingThemeSet(themeSet)
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      await onDelete(deletingThemeSet.themeSetId)
      setDeletingThemeSet(null)
    } finally {
      setIsLoading(false)
    }
  }

  if(deletingThemeSet) {
    const nameStrong = (
      <strong>{deletingThemeSet.name}</strong>
    )
    return (
      <GenericModal
        confirm
        cancel
        title="Confirm deletion"
        loading={isLoading}
        onSubmit={handleDelete}
        onCancel={() => setDeletingThemeSet(null)}
      >
        <Typography weight="light">
          <FormattedMessage
            id="owner.ThemeSettingsModal.DeleteConfirmation.description"
            defaultMessage="Are you sure you wish to delete the {name} theme set?"
            values={{ name: nameStrong }}
          />
        </Typography>
      </GenericModal>
    )
  }

  let actionText = (<FormattedMessage defaultMessage="Add theme set" id="owner.ThemeSettingsModal.action.add" />)
  if (isLoading) {
    actionText = (<FormattedMessage defaultMessage="Loading..." id="owner.ThemeSettingsModal.action.loading" />)
  }

  return (
    <ComponentRoot>
      <Card>
        <Card.Header>
          <Heading id="dialog-label" variant="heading3">
            <FormattedMessage
              id="owner.ThemeSettingsModal.title"
              defaultMessage="Theme Builder settings"
            />
          </Heading>
          <CloseButton onClick={onClose}>
            <Icon variant="times" />
          </CloseButton>
        </Card.Header>
        <Card.Body>
          <PreTableSection>
            <Typography id="dialog-desc" weight="light">
              <FormattedMessage
                id="owner.ThemeSettingsModal.description"
                defaultMessage="Manage theme sets and set currently active set"
              />
            </Typography>
            <Button disabled={isAdding || isLoading} onClick={handleStartAdd}>{actionText}</Button>
          </PreTableSection>
        </Card.Body>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Active</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ThemeSetRow
                editing
                hidden={!isAdding}
                disabled={isLoading}
                onSave={handleConfirmAdd}
                onCancel={handleCancelEdit}
              />
              {themeSets.map(themeSet => (
                <ThemeSetRow
                  key={themeSet.themeSetId}
                  themeSet={themeSet}
                  active={activeThemeSetId === themeSet.themeSetId}
                  editing={editingThemeSetId === themeSet.themeSetId}
                  disabled={isLoading}
                  onSetActive={handleSetActive}
                  onStartEdit={handleStartEdit}
                  onSave={handleConfirmEdit}
                  onCancel={handleCancelEdit}
                  onDelete={() => handleStartDelete(themeSet)}
                />
              ))}
              <NoResultsRow show={themeSets && themeSets.length === 0} />
            </tbody>
          </Table>
        </TableWrapper>
        <Card.Footer>
          <Button onClick={onClose} outline>
            <FormattedMessage id="owner.ThemeSettingsModal.close" defaultMessage="Close" />
          </Button>
        </Card.Footer>
      </Card>
    </ComponentRoot>
  )
}

ThemeSettingsModal.propTypes = {
  themeSets: PropTypes.arrayOf(PropTypes.shape({
    themeSetId: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string
  })),
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSetActive: PropTypes.func,
  onClose: PropTypes.func,
}

ThemeSettingsModal.defaultProps = {
  onClose: () => { },
}

export default ThemeSettingsModal
