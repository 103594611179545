import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../../common/Typography/Typography'
import Heading from '../../../common/Typography/Heading'
import Card from '../../../common/Card/Card'
import Button from '../../../common/Button/Button'
import Icon from '../../../common/Icon/Icon'


const ComponentRoot = styled.div`
  width: 100%;
  max-width: 550px;
`

const CenteredBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
`


class UnlockLogoModal extends Component {
  render() {
    const { onSubmit, onClose } = this.props
    return (
      <ComponentRoot>
        <Card>
          <Card.Header>
            <Heading.h3>
              <FormattedMessage id="owner.UnlockLogoModal.title" defaultMessage="Subscription required"/>
            </Heading.h3>
            <Icon clickable variant="times" onClick={onClose} />
          </Card.Header>
          <CenteredBody>
            <Typography.p weight="light">
              <FormattedMessage
                id="owner.OrganizationSettingsCard.unlockLogo"
                defaultMessage="Brand customization requires an active Qvest subscription. Your logo will appear in participant emails and the Qvest interface."
              />
            </Typography.p>
            <Button onClick={onSubmit}>See pricing</Button>
          </CenteredBody>
        </Card>
      </ComponentRoot>
    )
  }
}

UnlockLogoModal.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
}

UnlockLogoModal.defaultProps = {
  onSubmit: () => {},
  onClose: () => {}
}

export default UnlockLogoModal
