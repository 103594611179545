import { combineReducers } from 'redux'
import status from './status'
import qvests from './qvests'
import participants from './participants'
import questions from './questions'
import stats from './stats'
import grouping from './grouping'
import schedules from './schedules'
import auth from './auth'
import user from './user'
import organization from './organization'
import configuration from './configuration'
import themes from './themes'
import subscription from './subscription'
import notification from './notification'
import topic from './topic'

export default combineReducers({
  status,
  qvests,
  participants,
  questions,
  stats,
  grouping,
  schedules,
  auth,
  user,
  organization,
  configuration,
  themes,
  subscription,
  notification,
  topic
})
