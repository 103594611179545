import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import DateTimeInput from '../../common/DateTimeInput/DateTimeInput'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'

const ComponentRoot = styled.div`
  display: flex;
  & > :not(:last-child) {
    width: 33%;
  }
`

const Field = styled.div`
  display: inline-block;
  & > :first-child {
    color: ${({ disabled, theme }) => disabled ? theme.one[2] : theme.one[0]};
  }

  & > :not(:last-child) {
    margin-bottom: 12px;
  }

  & a {
    color: ${({ theme }) => theme.color[0] };
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }
`

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const qvestStateOrder = {
  QVEST_SCHEDULED: 1,
  QVEST_ONBOARDING_STARTED: 2,
  QVEST_OPENED: 3,
  QVEST_CLOSED: 4,
  QVEST_EXPIRED: 5
}

function hasPassed(currentState, boundaryState) {
  let currentValue = 0
  if (qvestStateOrder.hasOwnProperty(currentState)) {
    currentValue = qvestStateOrder[currentState]
  }
  let boundaryValue = 0
  if (qvestStateOrder.hasOwnProperty(boundaryState)) {
    boundaryValue = qvestStateOrder[boundaryState]
  }
  return (currentValue >= boundaryValue)
}

function makeEarliestOpenAt(inviteAt) {
  const minDelta = 300000 // >5min after invite
  return new Date(inviteAt.getTime() + minDelta)
}

function makeEarliestCloseAt(openAt, hasWaves) {
  const minDelta = (hasWaves ? 172800000 : 300000) // >48hrs after open if waves, otherwise >5min after open
  return new Date(openAt.getTime() + minDelta)
}

class ScheduleForm extends Component {
  constructor(props) {
    super(props)
    const { qvestState, schedule } = props

    // Lock up fields if relevant qvest state passed (disable inputs and stop coercing past dates)
    const inviteLocked = hasPassed(qvestState, 'QVEST_ONBOARDING_STARTED')
    const openLocked = hasPassed(qvestState, 'QVEST_OPENED')
    const closeLocked = hasPassed(qvestState, 'QVEST_CLOSED')

    // Coerce to future, unless locked
    let inviteAt = schedule.inviteAt
    if (inviteAt && !inviteLocked) {
      inviteAt = (inviteAt.getTime() > Date.now() ? inviteAt : new Date())
    }
    let openAt = schedule.openAt
    if (openAt && !openLocked) {
      openAt = (openAt.getTime() > Date.now() ? openAt : new Date())
    }
    let closeAt = schedule.closeAt
    if (closeAt && !closeLocked) {
      closeAt = (closeAt.getTime() > Date.now() ? closeAt : new Date())
    }

    this.state = { inviteAt, inviteLocked, openAt, openLocked, closeAt, closeLocked }
  }

  handleChange = () => {
    const { hasWaves } = this.props
    let { inviteAt, openAt, closeAt } = this.state
    if (inviteAt && openAt) {
      // If openAt occurs before minimum allowed, coerce it
      const earliestOpenAt = makeEarliestOpenAt(inviteAt)
      if (earliestOpenAt.getTime() > openAt.getTime()) {
        openAt = earliestOpenAt
      }
    }
    if (openAt && closeAt) {
      // If closeAt occurs before minimum allowed, coerce it
      const earliestCloseAt = makeEarliestCloseAt(openAt, hasWaves)
      if (earliestCloseAt.getTime() > closeAt.getTime()) {
        closeAt = new Date(earliestCloseAt)
      }
    }
    this.setState({ inviteAt, openAt, closeAt })
    this.props.onChange({ inviteAt, openAt, closeAt })
  }

  handleInviteAtChange = (date) => {
    this.setState({ inviteAt: date }, this.handleChange)
  }

  handleOpenAtChange = (date) => {
    this.setState({ openAt: date }, this.handleChange)
  }

  handleCloseAtChange = (date) => {
    this.setState({ closeAt: date }, this.handleChange)
  }

  renderInviteAtInput() {
    const { hasInvite } = this.props
    const { inviteAt, openAt, closeAt, inviteLocked } = this.state
    const lowerBound = new Date() // today (you cannot qvest in the past)
    let markedDays = []
    if (openAt) {
      markedDays.push(openAt)
    }
    if (closeAt) {
      markedDays.push(closeAt)
    }
    return (
      <DateTimeInput
        disabled={!hasInvite || inviteLocked}
        markedDays={markedDays}
        lowerBound={!inviteLocked ? lowerBound : null}
        value={inviteAt}
        onChange={this.handleInviteAtChange}
      />
    )
  }

  renderOpenAtInput() {
    const { hasInvite } = this.props
    const { inviteAt, openAt, closeAt, openLocked } = this.state
    let lowerBound = new Date() // today (you cannot qvest in the past)
    let markedDays = []
    if (inviteAt && hasInvite) {
      markedDays.push(inviteAt)
      if (inviteAt.getTime() > lowerBound.getTime())
        lowerBound = inviteAt
    }
    if (closeAt) {
      markedDays.push(closeAt)
    }
    return (
      <DateTimeInput
        disabled={openLocked}
        markedDays={markedDays}
        lowerBound={!openLocked ? lowerBound : null}
        value={openAt}
        onChange={this.handleOpenAtChange}
      />
    )
  }

  renderCloseAtInput() {
    const { hasInvite, hasWaves } = this.props
    const { inviteAt, openAt, closeAt, closeLocked } = this.state
    let lowerBound = new Date() // today (you cannot qvest in the past)
    let markedDays = []
    if (inviteAt && hasInvite) {
      markedDays.push(inviteAt)
    }
    if (openAt) {
      markedDays.push(openAt)
      const earliestCloseAt = makeEarliestCloseAt(openAt, hasWaves)
      if (earliestCloseAt > lowerBound.getTime()) {
        lowerBound = earliestCloseAt
      }
    }
    return (
      <DateTimeInput
        disabled={closeLocked}
        markedDays={markedDays}
        lowerBound={!closeLocked ? lowerBound : null}
        value={closeAt}
        onChange={this.handleCloseAtChange}
      />
    )
  }

  renderTestLink(variant) {
    const { hasTestOption, hasInvite, onSendTest } = this.props
    const disabled = !hasTestOption || (!hasInvite && (variant === 'QVEST_ONBOARDING_STARTED'))
    return (
      <Typography
        clickable={!disabled}
        cta={!disabled}
        secondary={disabled}
        weight="light"
        variant="medium"
        onClick={!disabled ? () => onSendTest(variant) : null}
      >
        <FormattedMessage defaultMessage="Send test" id="owner.ScheduleForm.testLink" />
      </Typography>
    )
  }

  render() {
    return (
      <ComponentRoot>
        <div disabled={!this.props.hasInvite}>
          <Field>
            <Label>
              <Heading.h4>
                <FormattedMessage defaultMessage="Send invitation mail" id="owner.ScheduleForm.label.invite"/>
              </Heading.h4>
              {this.renderTestLink('QVEST_ONBOARDING_STARTED')}
            </Label>
            {this.renderInviteAtInput()}
          </Field>
        </div>
        <div>
          <Field>
            <Label>
              <Heading.h4>
                <FormattedMessage defaultMessage="Open Qvest" id="owner.ScheduleForm.label.open"/>
              </Heading.h4>
              {this.renderTestLink('QVEST_OPENED')}
            </Label>
            {this.renderOpenAtInput()}
          </Field>
        </div>
        <div>
          <Field>
            <Label>
              <Heading.h4>
                <FormattedMessage defaultMessage="Close Qvest" id="owner.ScheduleForm.label.close"/>
              </Heading.h4>
              {this.renderTestLink('QVEST_CLOSED')}
            </Label>
            {this.renderCloseAtInput()}
          </Field>
        </div>
      </ComponentRoot>
    )
  }
}

ScheduleForm.propTypes = {
  schedule: PropTypes.shape({
    inviteAt: PropTypes.instanceOf(Date),
    openAt: PropTypes.instanceOf(Date),
    closeAt: PropTypes.instanceOf(Date)
  }),
  hasInvite: PropTypes.bool,
  hasWaves: PropTypes.bool,
  onChange: PropTypes.func,
  onSendTest: PropTypes.func
}

ScheduleForm.defaultProps = {
  schedule: {},
  hasInvite: true,
  onChange: () => {},
  onSendTest: () => {}
}

export default ScheduleForm
