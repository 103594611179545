import MarkdownIt from 'markdown-it'
import sanitizeHtml from 'sanitize-html'

const md = MarkdownIt({
  html: false,
  linkify: true
})

export function renderMarkdown(source) {
  const renderedHtml = md.render(source)
  return sanitizeHtml(renderedHtml)
}
