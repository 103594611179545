
// Truncates an SVG text element by trial-and-error (horrible, but sadly only way to do this with IE11 compatibility)
// NOTE: Requires element to have the text value in a 'data-text' attribute
export function truncateText(element, maxWidth) {
  let text = element.attributes['data-text'].value
  element.innerHTML = text
  let textLength = element.getComputedTextLength()
  while (textLength > maxWidth && text.length > 0) {
    text = text.slice(0, -1)
    element.innerHTML = (text + '...')
    textLength = element.getComputedTextLength()
  }
  if (text.length === 0) {
    element.innerHTML = ''
  }
}
