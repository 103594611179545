import Immutable from 'immutable'


let INITIAL_STATE = Immutable.fromJS({
  planSelector: {
    show: false,
    variant: 'overall'
  },
  plans: {
    isLoading: false,
    errors: null
  },
  invoices: {
    isLoading: false,
    errors: null,
    data: {}
  },
  isLoading: false,
  errors: null,
  data: {}
})


const plans = (state = INITIAL_STATE.get('plans'), action) => {
  switch (action.type) {
    case 'GET_PLANS_REQUEST':
      return state.set('isLoading', true)
    case 'GET_PLANS_SUCCESS':
      return state.merge({
        isLoading: false,
        data: action.plans
      })
    case 'GET_PLANS_FAILURE':
      return state.merge({
        isLoading: false,
        errors: action.errors
      })
    default:
      return state
  }
}

const invoices = (state = INITIAL_STATE.get('invoices'), action) => {
  const { organizationId, invoices } = action
  switch (action.type) {
    case 'GET_INVOICES_REQUEST':
      return state.set('isLoading', true)
    case 'GET_INVOICES_SUCCESS':
      return state
        .set('isLoading', false)
        .update('data', data => data.set(organizationId, Immutable.fromJS(invoices)))
    case 'GET_INVOICES_FAILURE':
      return state.merge({
        isLoading: false,
        errors: action.errors
      })
    default:
      return state
  }
}

const data = (state = INITIAL_STATE.get('data'), action) => {
  const { organizationId, subscriptions, subscription, subscribeLinks } = action
  switch (action.type) {
    case 'GET_SUBSCRIPTIONS_SUCCESS':
      return state.set(organizationId, Immutable.fromJS(subscriptions))
    case 'GET_SUBSCRIPTIONS_VALIDITY_SUCCESS':
      return state.mergeIn([organizationId], Immutable.fromJS((subscriptions)))
    case 'UPDATE_SUBSCRIBED_PLAN_SUCCESS':
    case 'CANCEL_SUBSCRIPTION_SUCCESS':
    case 'UNDO_CANCEL_SUBSCRIPTION_SUCCESS':
      return state.update(organizationId, os =>
        os.update('items', items => items.set(0, subscription))
      )
    case 'GET_SUBSCRIBE_LINKS_SUCCESS':
      return state.update(organizationId, os =>
        os.set('subscribeLinks', subscribeLinks)
      )
    default:
      return state
  }
}

const planSelector = (state = INITIAL_STATE.get('planSelector'), action) => {
  switch (action.type) {
    case 'TOGGLE_PLAN_SELECTOR':
      return state
        .set('show', action.show)
        .set('variant', action.variant)
    default:
      return state
  }
}

const configuration = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_PLANS_REQUEST':
    case 'GET_PLANS_SUCCESS':
    case 'GET_PLANS_FAILURE':
      return state.update('plans', p => plans(p, action))
    case 'GET_INVOICES_REQUEST':
    case 'GET_INVOICES_SUCCESS':
    case 'GET_INVOICES_FAILURE':
      return state.update('invoices', p => invoices(p, action))
    case 'GET_SUBSCRIPTIONS_REQUEST':
    case 'UPDATE_SUBSCRIBED_PLAN_REQUEST':
    case 'CANCEL_SUBSCRIPTION_REQUEST':
    case 'UNDO_CANCEL_SUBSCRIPTION_REQUEST':
    case 'GET_SUBSCRIBE_LINKS_REQUEST':
    case 'GET_SUBSCRIPTIONS_VALIDITY_REQUEST':
      return state.set('isLoading', true)
    case 'GET_SUBSCRIPTIONS_SUCCESS':
    case 'UPDATE_SUBSCRIBED_PLAN_SUCCESS':
    case 'CANCEL_SUBSCRIPTION_SUCCESS':
    case 'UNDO_CANCEL_SUBSCRIPTION_SUCCESS':
    case 'GET_SUBSCRIBE_LINKS_SUCCESS':
    case 'GET_SUBSCRIPTIONS_VALIDITY_SUCCESS':
      return state
        .set('isLoading', false)
        .update('data', d => data(d, action))
    case 'GET_SUBSCRIPTIONS_FAILURE':
    case 'UPDATE_SUBSCRIBED_PLAN_FAILURE':
    case 'CANCEL_SUBSCRIPTION_FAILURE':
    case 'UNDO_CANCEL_SUBSCRIPTION_FAILURE':
    case 'GET_SUBSCRIBE_LINKS_FAILURE':
    case 'GET_SUBSCRIPTIONS_VALIDITY_FAILURE':
      return state.merge({
        isLoading: false,
        errors: action.errors
      })
    case 'TOGGLE_PLAN_SELECTOR':
      return state.update('planSelector', ps => planSelector(ps, action))
    default:
      return state
  }
}

export function filterAllPlans(store) {
  if (store.plans.hasOwnProperty('data')) {
    return store.plans.data
  }
  return null
}

export function findSubscriptionByOrganization(store, organizationId) {
  if (store.data.hasOwnProperty(organizationId) && store.data[organizationId].items.length > 0) {
    return store.data[organizationId].items[0]
  }
  return null
}

export function filterInvoicesByOrganization(store, organizationId) {
  if (store.invoices.data.hasOwnProperty(organizationId)) {
    return store.invoices.data[organizationId].items
  }
  return null
}

export function findPaymentLinkByOrganization(store, organizationId) {
  if (store.data.hasOwnProperty(organizationId)) {
    return store.data[organizationId].paymentLink
  }
  return null
}

export function findSubscribeLinksByOrganization(store, organizationId) {
  if (store.data.hasOwnProperty(organizationId)) {
    return store.data[organizationId].subscribeLinks
  }
  return null

}

export function filterSubscriptionValidityByOrganization(store, organizationId) {
  if (!store.data.hasOwnProperty(organizationId)) {
    return null
  }
  const { validity, items } = store.data[organizationId]
  // Add reference to participants to each validation item, if any found
  if (validity.items && validity.items.length > 0) {
    const resource = items[0]
    validity.items = validity.items.map(item => ({ ...item, resource }))
  }
  return validity
}

export function filterSubscriptionAnalyticsTier(store, qvest) {
  if (!qvest) return null
  if (qvest.isDummy) return 3
  const subscription = findSubscriptionByOrganization(store, qvest.organizationId)
  if (!subscription) return null
  if (!subscription.isActive) return 1 // Inactive subscription always get tier 1 (free)
  return subscription.plan.metaData.analyticsTier
}

export function filterSubscriptionMetaData(store, organizationId) {
  const subscription = findSubscriptionByOrganization(store, organizationId)
  if (!subscription) return null
  return subscription.plan.metaData
}

export default configuration
