import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import React, { useCallback } from 'react'
import QRCode from 'qrcode'

import { makeJoinUrl } from '../../../utils/qvestUtils'
import Anchor from '../../common/Anchor/Anchor'
import Typography from '../../common/Typography/Typography'


const ComponentRoot = styled.div`
  display: ${({ hidden }) => hidden ? 'none' : 'flex'};
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  background-color: ${({ theme }) => theme.inverted.two[1]};
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
  padding: 24px;

  & a, & strong {
    font-family: ${({ theme  }) => theme.font4};
  }
`

const JoinLink = ({ code }) => (
  <Typography inverted cta variant="large" weight="bold">
    <Anchor inverted href={makeJoinUrl(code)}>questionjam.it</Anchor>
  </Typography>
)

const JoinCode = ({ code }) => (
  <Typography inverted cta variant="large" weight="bold">
    <strong>{code}</strong>
  </Typography>
)

const QRCodeCanvas = ({ code }) => {
  const refQRCanvas = useCallback(node => {
    if (node !== null) {
      const url = makeJoinUrl(code)
      const options = {
        color: { dark: '#3e4341', light: '#f2eee2' },
        margin: 1
      }
      QRCode.toCanvas(node, url, options)
    }
  })
  return (
    <canvas ref={refQRCanvas} />
  )
}

const JoinInfoCard = ({ code, hidden }) => (
  <ComponentRoot hidden={hidden}>
    <div>
      <p>
        <Typography inverted variant="large">
          <FormattedMessage
            id="qvest.JamSection.joinInfo.linkPhrase"
            defaultMessage="Join us at {joinLink}"
            values={{ joinLink: <JoinLink code={code} /> }}
          />
        </Typography>
      </p>
      <p>
        <Typography inverted variant="large">
          <FormattedMessage
            id="qvest.JamSection.joinInfo.codePhrase"
            defaultMessage="The passcode is {joinCode}"
            values={{ joinCode: <JoinCode code={code} /> }}
          />
        </Typography>
      </p>
    </div>
    <QRCodeCanvas code={code} />
  </ComponentRoot>
)

export default JoinInfoCard
