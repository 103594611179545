import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import styled, { withTheme } from 'styled-components'
import { PolarChart, focusMapping } from 'data-viz'

import AnalyticsCard from '../../AnalyticsCard/AnalyticsCard'


const titleMessages = defineMessages({
  northEast: { defaultMessage: 'Clear focus on purpose', id: 'owner.FocusAnalyticsCard.title.northEast' },
  southEast: { defaultMessage: 'Clear focus on result', id: 'owner.FocusAnalyticsCard.title.southEast' },
  southWest: { defaultMessage: 'Clear focus on process', id: 'owner.FocusAnalyticsCard.title.southWest' },
  northWest: { defaultMessage: 'Clear focus on experience', id: 'owner.FocusAnalyticsCard.title.northWest' },
  north: { defaultMessage: 'Focus on experience & purpose', id: 'owner.FocusAnalyticsCard.title.north' },
  east: { defaultMessage: 'Focus on purpose & result', id: 'owner.FocusAnalyticsCard.title.east' },
  south: { defaultMessage: 'Focus on process & result', id: 'owner.FocusAnalyticsCard.title.south' },
  west: { defaultMessage: 'Focus on experience & process', id: 'owner.FocusAnalyticsCard.title.west' },
  none: { defaultMessage: 'No focus', id: 'owner.FocusAnalyticsCard.title.none' },
})

const ChartWrapper = styled.div`
  position: absolute;
  top: -60px;
  left: -60px;
`

class FocusAnalyticsCard extends Component {
  handleClick = () => this.props.onClick('focus')
  renderChart(data) {
    const { locked } = this.props
    return (
      <ChartWrapper>
        <PolarChart
          round
          width={500}
          height={500}
          data={data}
          inverted={locked}
        />
      </ChartWrapper>
    )
  }
  render() {
    const { intl, questionProfile, locked, loading } = this.props
    let title, chart
    if (questionProfile && !loading) {
      const { data, direction } = focusMapping.formatData(questionProfile)
      if (direction) {
        title = intl.formatMessage(titleMessages[direction])
      } else {
        title = intl.formatMessage(titleMessages.none)
      }
      chart = this.renderChart(data)
    } else {
      chart = this.renderChart(null)
    }
    return (
      <AnalyticsCard
        subTitle="Focus"
        title={title}
        onClick={this.handleClick}
        disabled={!questionProfile && !locked}
        locked={locked}
        loading={loading}
      >
        {chart}
      </AnalyticsCard>
    )
  }
}

export default withTheme(injectIntl(FocusAnalyticsCard))
