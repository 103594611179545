import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Card from '../../common/Card/Card'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Button from '../../common/Button/Button'
import EmbeddedVideo from '../../common/EmbeddedVideo/EmbeddedVideo'


const CardWrapper = styled.div`
  width: 600px;
  ${Typography} { display: block; }
  ${Card.Body} > *:last-child { margin-top: 20px; }
`

const SpaciousCardBody = styled.div`
  align-items: center;
  text-align: center;
  padding-bottom: 40px;
  & > *:first-child { margin-bottom: 30px; }
  & > *:nth-child(2) { margin-bottom: 5px; }
  & > *:last-child { margin-top: 20px; }
`

class WelcomeModal extends Component {
  render() {
    return (
      <CardWrapper>
        <Card>
          <SpaciousCardBody>
            <EmbeddedVideo url="https://vimeo.com/471352809" />
            <Heading.h2>
              <FormattedMessage
                id="owner.WelcomeModal.heading"
                defaultMessage="Welcome to Qvest"
              />
            </Heading.h2>
            <Typography.p weight="light" variant="medium">
              <FormattedMessage
                id="owner.WelcomeModal.subHeading"
                defaultMessage="Start by setting up your first Qvest to see how it works"
              />
            </Typography.p>
            <Button onClick={this.props.onSubmit}>
              <FormattedMessage id="owner.WelcomeModal.cta" defaultMessage="Let´s go" />
            </Button>
          </SpaciousCardBody>
        </Card>
      </CardWrapper>
    )
  }
}

WelcomeModal.propTypes = {
  onSubmit: PropTypes.func
}

WelcomeModal.defaultProps = {
  onSubmit: () => {}
}

export default WelcomeModal
