
const BlueOnWhiteTheme = {
  /* Background colors */
  background1: '#ffffff',
  background2: '#D4DADD',
  background3: '#F0F3F5',
  background4: '#C8D3DC',
  background5: '#F0F3F5',
  /* Basic colors */
  main: {
    primary: '#294457',
    secondary: '#496F8A',
    tertiary: '#ffffff'
  },
  /* Call-to-action colors */
  cta: {
    primary: '#FC8F85',
    secondary: '#FDA59D',
    tertiary: '#ffffff',
    warning: '#f7a15d',
  },
  /* Alerts */
  alert: {
    error: '#fc8f85',
    warning: '#f7a15d',
    ok: '#64936E'
  },
  /* Data visualization colors */
  data: {
    primary:   ['#65936e', '#ed9a91', '#f4bf6c', '#486e88', '#cd5154', '#f7a15d', '#42675c', '#c5cfc7', '#5c8f85', '#3d4640'],
    secondary: ['#7FAD88', '#FFB4AB', '#FFD986', '#6288A2', '#E76B6E', '#FFBB77', '#5C8176', '#DFE9E1', '#76A99F', '#57605A']
  },
  /* Fonts */
  font1: 'Raleway, sans-serif',
  font2: '\'LL Circular Book Web\', sans-serif',
  font3: '\'LL Circular Black Web\', sans-serif',
  font4: '\'Andale Mono MT Pro\', monospace'
}

export default BlueOnWhiteTheme
