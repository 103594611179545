import {
  Observable,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  defaultDataIdFromObject
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import * as authUtils from './utils/authUtils'
import { raiseNotification, clearNotification } from '../operations/notification'


// Backing off exponentially at first, settles on max delay and jitters (0x-2x = 0-8000ms = ~4000ms)
const INITIAL_RETRY_DELAY = 150 // ms
const MAX_RETRY_DELAY = 8000 // ms
const MAX_RETRY_COUNT = 75 // approximate time to timeout: 75 * ~4000ms = ~5 min


const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'Qvest': return `Qvest:${object.qvestId}`
      case 'Participant': return `Participant:${object.participantId}`
      case 'Question': return `Question:${object.questionId}`
      case 'Grouping': return `Grouping:${object.groupingId}`
      case 'Group': return `Group:${object.groupId}`
      case 'ThemeSet': return `ThemeSet:${object.themeSetId}`
      case 'Theme': return `Theme:${object.themeId}`
      default: return defaultDataIdFromObject(object) // fall back to default handling
    }
  },
  typePolicies: {
    Theme: {
      fields: {
        createdAt: {
          read(createdAt) {
            return createdAt && new Date(createdAt)
          }
        }
      }
    }
  }
})

const httpLink = new HttpLink({
  uri: '/api/graphql',
  fetch: (uri, options) => {
    return authUtils.getAuthHeader()
      .then(authHeader => {
        options.headers = { ...options.headers, ...authHeader }
        return fetch(uri, options)
      })
  }
})

const errorNotifyLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext()
  return new Observable(observer => {
    let subscription, hasApplicationError
    try {
      subscription = forward(operation).subscribe({
        next: result => {
          if (result.errors) {
            // Encountered application error (not network related)
            hasApplicationError = true
            raiseNotification('UNEXPECTED_ERROR')
          }
          observer.next(result)
        },
        error: networkError => {
          // Encountered network error
          if (context.retryCount === 1) {
            // Started retrying
            raiseNotification('CONNECTION_RETRY')
          }
          if (context.retryCount === MAX_RETRY_COUNT) {
            // Timed out after retrying
            raiseNotification('CONNECTION_TIMEOUT')
          }
          observer.error(networkError)
        },
        complete: () => {
          if (!hasApplicationError) {
            // Completed successfully after retrying
            clearNotification()
          }
          observer.complete.bind(observer)()
        },
      })
    } catch (e) {
      observer.error(e)
    }
    return () => {
      if (subscription) subscription.unsubscribe()
    }
  })
})

const retryLink = new RetryLink({
  delay: (count) => {
    const base = Math.pow(2, count) * INITIAL_RETRY_DELAY
    const delay = Math.min(base, MAX_RETRY_DELAY / 2)
    const jitter = 2 * Math.random()
    return delay * jitter
  },
  attempts: (count, operation, error) => {
    if (!error.message || (error.message !== 'Failed to fetch' && error.name !== 'ServerParseError')) {
      // If error is not related to connection, do not retry
      return false
    }
    if (count > MAX_RETRY_COUNT) {
      return false
    }
    operation.setContext(context => ({ ...context, retryCount: count }))
    return true
  }
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    retryLink,
    errorNotifyLink,
    httpLink
  ]),
  connectToDevTools: true,
  cache
})
