import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, FormattedDate } from 'react-intl'
import styled, { withTheme } from 'styled-components'

import Heading from '../../common/Typography/Heading'
import QvestCardDropdown from './QvestCardDropdown'
import Typography from '../../common/Typography/Typography'

const ComponentRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  height: 290px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .05);
  border-radius: 6px;
  padding: 20px 20px 20px 30px;
  border-left: 3px solid ${({ theme, color }) => color || theme.color[0]};
  background-color: ${({ theme }) => theme.two[0]};
  transition: 0.2s;
  cursor: pointer;
  word-wrap: break-word;
  & > *:first-child { margin-bottom: 10px; }
  & > *:nth-child(2) {
    max-height: 75px;
    overflow: hidden;
    margin-bottom: auto;
  }
  & > *:last-child { margin-top: 10px; }
  &:hover, &:focus {
    box-shadow: 5px 10px 20px rgba(0,0,0,.15);
    outline: none;
  }
`

const Status = styled.div`
  position: relative;
  padding-right: 25px;
  align-self: flex-end;
  &&& > * {
    color: ${({ theme, color }) => color || theme.color[0]};
  }
  &::after {
    content: "";
    position: absolute;
    background-color: ${({ theme, color }) => color || theme.color[0]};
    right: 6px;
    top: 8px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
`

const Summary = styled.div`
  flex: 1;
  padding-right: 15px;
  & > * + * { margin-left: 5px; }
  & > *:nth-child(3) { margin-left: 15px; }
`

class QvestCard extends Component {
  handleClick = () => {
    const { qvestId, onClick } = this.props
    onClick(qvestId)
  }

  handleArchiveClick = (event) => {
    const { qvestId, isArchived, onArchiveClick } = this.props
    event.stopPropagation()
    onArchiveClick(qvestId, !isArchived)
  }

  determineColor = () => {
    const { state, theme } = this.props
    if (!state) return theme.one[2]
    if (state === 'QVEST_CLOSED' || state === 'QVEST_EXPIRED') return theme.one[3]
    return theme.color[0]
  }

  renderSchedule() {
    const { schedule, state } = this.props
    if (!state || !schedule) return null
    return (
      <Fragment>
        <div>
          <Typography variant="medium" weight="bold">
            <FormattedMessage id="owner.QvestList.startDate" defaultMessage="Open date" />
            :&nbsp;
          </Typography>
          <Typography variant="medium" weight="light">
            <FormattedDate value={schedule.openAt} weekday="short" month="short" day="numeric" year="numeric" />
          </Typography>
        </div>
        <div>
          <Typography variant="medium" weight="bold">
            <FormattedMessage id="owner.QvestList.endDate" defaultMessage="Close date" />
            :&nbsp;
          </Typography>
          <Typography variant="medium" weight="light">
            <FormattedDate value={schedule.closeAt} weekday="short" month="short" day="numeric" year="numeric" />
          </Typography>
        </div>
      </Fragment>
    )
  }

  renderState() {
    const { state } = this.props
    switch (state) {
      case 'QVEST_CLOSED':
      case 'QVEST_EXPIRED':
        return <FormattedMessage id="owner.QvestList.closed" defaultMessage="Closed" />
      case 'QVEST_OPENED':
        return <FormattedMessage id="owner.QvestList.opened" defaultMessage="Open" />
      case 'QVEST_ONBOARDING_STARTED':
        return <FormattedMessage id="owner.QvestList.invitationSent" defaultMessage="Invitation sent" />
      case 'QVEST_SCHEDULED':
        return <FormattedMessage id="owner.QvestList.scheduled" defaultMessage="Scheduled" />
      default:
        return <FormattedMessage id="owner.QvestList.created" defaultMessage="Draft" />
    }
  }

  renderParticipation = () => {
    const { summary, state } = this.props
    const participants = (summary && summary.participants) || 0
    const activeParticipants = (summary && summary.activeParticipants) || 0
    const participation = (participants && activeParticipants) ? Math.round(activeParticipants / participants * 100) : 0

    switch (state) {
      case 'QVEST_OPENED':
      case 'QVEST_CLOSED':
      case 'QVEST_EXPIRED':
        return (
          <Fragment>
            <div>
              <Typography variant="medium" weight="bold">
                <FormattedMessage id="owner.QvestList.participantsClosed" defaultMessage="Participants" />
                :&nbsp;
              </Typography>
              <Typography variant="medium" weight="light">
                {participants}
              </Typography>
            </div>
            <div>
              <Typography variant="medium" weight="bold">
                <FormattedMessage id="owner.QvestList.activity" defaultMessage="Activity" />
                :&nbsp;
              </Typography>
              <Typography variant="medium" weight="light">
                {participation}%
              </Typography>
            </div>
          </Fragment>
        )
      default:
        return null
    }
  }

  renderSummary() {
    const { summary, state } = this.props
    const { participants, answers, questions } = summary || {
      participants: 0,
      answers: 0,
      questions: 0,
    }
    switch (state) {
      case 'QVEST_OPENED':
      case 'QVEST_CLOSED':
      case 'QVEST_EXPIRED':
        return (
          <Summary>
            <Typography variant="heading5">{questions}</Typography>
            <Typography variant="small" weight="light">
              <FormattedMessage
                id="owner.QvestList.questions"
                defaultMessage="{questions, plural, one {question} other {questions}}"
                values={{ questions }}
              />
            </Typography>
            <Typography variant="heading5">{answers}</Typography>
            <Typography variant="small" weight="light">
              <FormattedMessage
                id="owner.QvestList.answers"
                defaultMessage="{answers, plural, one {answer} other {answers}}"
                values={{ answers }}
              />
            </Typography>
          </Summary>
        )
      default:
        return (
          <Summary>
            <Typography variant="heading5">{participants}</Typography>
            <Typography variant="small" weight="light">
              <FormattedMessage
                id="owner.QvestList.participants"
                defaultMessage="{participants, plural, one {participant} other {participants}}"
                values={{ participants }}
              />
            </Typography>
          </Summary>
        )
    }
  }

  renderMenu() {
    return (
      <QvestCardDropdown
        isArchived={this.props.isArchived}
        onArchiveClick={this.handleArchiveClick}
      />
    )
  }

  render() {
    const { name } = this.props
    return (
      <ComponentRoot
        role="button"
        tabIndex="0"
        onClick={this.handleClick}
        color={this.determineColor()}
      >
        <Status color={this.determineColor()}>
          <Heading variant="heading5">{this.renderState()}</Heading>
        </Status>
        <Heading variant="heading3">{name}</Heading>
        {this.renderSchedule()}
        {this.renderParticipation()}
        <Footer>
          {this.renderSummary()}
          {this.renderMenu()}
        </Footer>
      </ComponentRoot>
    )
  }
}

QvestCard.propTypes = {
  onClick: PropTypes.func,
  onArchiveClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  state: PropTypes.string,
  qvestId: PropTypes.string.isRequired,
  isArchived: PropTypes.bool,
  schedule: PropTypes.shape({
    inviteAt: PropTypes.instanceOf(Date),
    openAt: PropTypes.instanceOf(Date),
    closeAt: PropTypes.instanceOf(Date),
  }),
  summary: PropTypes.shape({
    participants: PropTypes.number,
    questions: PropTypes.number,
    answers: PropTypes.number,
    activeParticipants: PropTypes.number,
  }),
}

QvestCard.defaultProps = {
  onClick: () => { },
  onArchiveClick: () => { },
  isArchived: false,
  schedule: null,
  summary: null,
}

export default withTheme(QvestCard)
