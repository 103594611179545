import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Table from '../../common/Table/Table'


const NoInputWrapper = styled.div`
  width: 100%;
  padding: 20px;
`

const ParticipantsActivityTable = ({ isLoading, participants }) => {
  const hasData = (participants && participants.length)

  if (!isLoading && !hasData) {
    return (
      <NoInputWrapper>
        <Typography secondary>
          <FormattedMessage
            id="owner.ParticipantsActivityTable.noActivity"
            defaultMessage="There is no activity yet"
          />
        </Typography>
      </NoInputWrapper>
    )
  }

  return (
    <Table isLoading={isLoading}>
      <thead>
        <tr>
          <th><FormattedMessage id="owner.ParticipantActivityTable.name" defaultMessage="Name" /></th>
          <th><FormattedMessage id="owner.ParticipantActivityTable.received" defaultMessage="Received" /></th>
          <th><FormattedMessage id="owner.ParticipantActivityTable.answered" defaultMessage="Answered" /></th>
          <th><FormattedMessage id="owner.ParticipantActivityTable.asked" defaultMessage="Asked" /></th>
        </tr>
      </thead>
      <tbody>
        {hasData && participants.map(participant => (
          <tr key={participant.participantId}>
            <td>{participant.name}</td>
            <td>{participant.questionReceivedCount}</td>
            <td>{participant.answerSentCount}</td>
            <td>{participant.questionSentCount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

ParticipantsActivityTable.propTypes = {
  isLoading: PropTypes.bool,
  participants: PropTypes.arrayOf(PropTypes.shape({
    participantId: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    questionReceivedCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    answerSentCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    questionSentCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  }))
}

ParticipantsActivityTable.defaultProps = {
  isLoading: false,
  participants: [],
}

export default ParticipantsActivityTable
