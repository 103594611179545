import * as api from './apiClient'

export function getConfiguration(qvestId) {
  return dispatch => {
    dispatch({ type: 'GET_CONFIGURATION_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q {
            configuration(qvestId: "${qvestId}") {
              features
              groupGraph {
                selfLinkAngle
                linkForceDistance
                nodeCollideRadius
                nodeCoordinates
              }
            }
          }
        `
      }
    }
    return api.invoke('/api/graphql', options)
      .then(result => {
        const configuration = result.data.configuration
        const groupGraph = configuration.groupGraph
        if (groupGraph && groupGraph.nodeCoordinates) {
          groupGraph.nodeCoordinates = JSON.parse(groupGraph.nodeCoordinates)
        }
        if (configuration.features) {
          configuration.features = JSON.parse(configuration.features)
        }
        dispatch({ type: 'GET_CONFIGURATION_SUCCESS', configuration, qvestId })
      })
      .catch(error => {
        dispatch({ type: 'GET_CONFIGURATION_FAILURE', error })
      })
  }
}

export function updateConfiguration(qvestId, configurationPatch) {
  return dispatch => {
    dispatch({ type: 'UPDATE_CONFIGURATION_REQUEST' })
    const groupGraph = configurationPatch.groupGraph
    if (groupGraph && groupGraph.nodeCoordinates) {
      groupGraph.nodeCoordinates = JSON.stringify(groupGraph.nodeCoordinates)
    }
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($qvestId: String!, $patch: ConfigurationPatch!) {
            updateConfiguration(qvestId: $qvestId, patch: $patch) {
              groupGraph {
                selfLinkAngle
                linkForceDistance
                nodeCollideRadius
                nodeCoordinates
              }
            }
          }
        `,
        variables: { qvestId, patch: configurationPatch }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(results => {
        const configuration = results.data.configuration
        dispatch({ type: 'UPDATE_CONFIGURATION_SUCCESS', configuration, qvestId })
      })
      .catch(error => {
        dispatch({ type: 'UPDATE_CONFIGURATION_FAILURE', error })
      })
  }
}

export function initUnsavedConfiguration(qvestId) {
  return { type: 'INIT_UNSAVED_CONFIGURATION', qvestId }
}

export function changeUnsavedConfiguration(qvestId, configuration) {
  return { type: 'UNSAVED_CONFIGURATION_CHANGE', configuration, qvestId }
}

export function clearGroupGraphCoordinates(qvestId) {
  return { type: 'CLEAR_GROUP_GRAPH_COORDINATES', qvestId }
}
