import 'normalize.css/normalize.css'

import { createGlobalStyle } from 'styled-components'

import circularBlackEot from './static/fonts/lineto-circular-black.eot'
import circularBlackWoff from './static/fonts/lineto-circular-black.woff'
import circularBlackWoff2 from './static/fonts/lineto-circular-black.woff2'
import circularBookEot from './static/fonts/lineto-circular-book.eot'
import circularBookWoff from './static/fonts/lineto-circular-book.woff'
import circularBookWoff2 from './static/fonts/lineto-circular-book.woff2'
import ralewayEot from './static/fonts/raleway-v12-latin-regular.eot'
import ralewayWoff from './static/fonts/raleway-v12-latin-regular.woff'
import ralewayWoff2 from './static/fonts/raleway-v12-latin-regular.woff2'
import ralewayTtf from './static/fonts/raleway-v12-latin-regular.ttf'
import raleway500Eot from './static/fonts/raleway-v12-latin-500.eot'
import raleway500Woff from './static/fonts/raleway-v12-latin-500.woff'
import raleway500Woff2 from './static/fonts/raleway-v12-latin-500.woff2'
import raleway500Ttf from './static/fonts/raleway-v12-latin-500.ttf'
import raleway600Eot from './static/fonts/raleway-v12-latin-600.eot'
import raleway600Woff from './static/fonts/raleway-v12-latin-600.woff'
import raleway600Woff2 from './static/fonts/raleway-v12-latin-600.woff2'
import raleway600Ttf from './static/fonts/raleway-v12-latin-600.ttf'
import raleway700Eot from './static/fonts/raleway-v12-latin-700.eot'
import raleway700Woff from './static/fonts/raleway-v12-latin-700.woff'
import raleway700Woff2 from './static/fonts/raleway-v12-latin-700.woff2'
import raleway700Ttf from './static/fonts/raleway-v12-latin-700.ttf'
import andaleMonoRegularEot from './static/fonts/andale-mono-mtpro-regular.eot'
import andaleMonoRegularWoff from './static/fonts/andale-mono-mtpro-regular.woff'
import andaleMonoRegularWoff2 from './static/fonts/andale-mono-mtpro-regular.woff2'
import andaleMonoRegularTtf from './static/fonts/andale-mono-mtpro-regular.ttf'
import andaleMonoBoldEot from './static/fonts/andale-mono-mtpro-bold.eot'
import andaleMonoBoldWoff from './static/fonts/andale-mono-mtpro-bold.woff'
import andaleMonoBoldWoff2 from './static/fonts/andale-mono-mtpro-bold.woff2'
import andaleMonoBoldTtf from './static/fonts/andale-mono-mtpro-bold.ttf'
import fontAwesomeEot from './static/fonts/fa-solid-900.eot'
import fontAwesomeWoff from './static/fonts/fa-solid-900.woff'
import fontAwesomeWoff2 from './static/fonts/fa-solid-900.woff2'
import fontAwesomeTtf from './static/fonts/fa-solid-900.ttf'

export default createGlobalStyle`

  /* Border-Box */
  /* Basic box reset based on semantic-ui */
  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
  }
  html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

  /* iPad Input Shadows */
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    /* mobile firefox too! */
  }


  @font-face {
    font-family: 'LL Circular Black Web';
    font-style: normal;
    font-weight: 900;
    src: url('${circularBlackEot}');
    src: local("LL Circular Black Web"), local("LLCircularBlackWeb-Regular"),
         url('${circularBlackEot}?#iefix') format("embedded-opentype"),
         url('${circularBlackWoff2}') format("woff2"),
         url('${circularBlackWoff}') format("woff");
  }

  @font-face {
    font-family: "LL Circular Book Web";
    font-style: normal;
    font-weight: 300;
    src: url('${circularBookEot}');
    src: local("LL Circular Book Web"), local("LLCircularBookWeb-Regular"),
         url('${circularBookEot}?#iefix') format("embedded-opentype"),
         url('${circularBookWoff2}') format("woff2"),
         url('${circularBookWoff}') format("woff");
  }

  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('${ralewayEot}');
    src: local('Raleway'), local('Raleway-Regular'),
         url('${ralewayEot}?#iefix') format('embedded-opentype'),
         url('${ralewayWoff2}') format('woff2'),
         url('${ralewayWoff}') format('woff'),
         url('${ralewayTtf}') format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('${raleway500Eot}');
    src: local('Raleway Medium'), local('Raleway-Medium'),
         url('${raleway500Eot}?#iefix') format('embedded-opentype'),
         url('${raleway500Woff2}') format('woff2'),
         url('${raleway500Woff}') format('woff'),
         url('${raleway500Ttf}') format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    src: url('${raleway600Eot}');
    src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
         url('${raleway600Eot}?#iefix') format('embedded-opentype'),
         url('${raleway600Woff2}') format('woff2'),
         url('${raleway600Woff}') format('woff'),
         url('${raleway600Ttf}') format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('${raleway700Eot}');
    src: local('Raleway Bold'), local('Raleway-Bold'),
         url('${raleway700Eot}?#iefix') format('embedded-opentype'),
         url('${raleway700Woff2}') format('woff2'),
         url('${raleway700Woff}') format('woff'),
         url('${raleway700Ttf}') format('truetype');
  }

  @font-face {
    font-family: 'Andale Mono MT Pro';
    font-style: normal;
    font-weight: normal;
    src: url('${andaleMonoRegularEot}');
    src: local('Andale Mono MT Pro Regular'), local('AndaleMonoMTPro'),
         url('${andaleMonoRegularEot}?#iefix') format('embedded-opentype'),
         url('${andaleMonoRegularWoff2}') format('woff2'),
         url('${andaleMonoRegularWoff}') format('woff'),
         url('${andaleMonoRegularTtf}') format('truetype');
  }

  @font-face {
    font-family: 'Andale Mono MT Pro';
    font-style: normal;
    font-weight: bold;
    src: url('${andaleMonoBoldEot}');
    src: local('Andale Mono MT Pro Bold'), local('AndaleMonoMTPro-Bold'),
         url('${andaleMonoBoldEot}?#iefix') format('embedded-opentype'),
         url('${andaleMonoBoldWoff2}') format('woff2'),
         url('${andaleMonoBoldWoff}') format('woff'),
         url('${andaleMonoBoldTtf}') format('truetype');
  }

  @font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    src: url('${fontAwesomeEot}');
    src: local('Font Awesome 5 Solid'), local('Font-Awesome-5-Solid'),
         url('${fontAwesomeEot}?#iefix') format('embedded-opentype'),
         url('${fontAwesomeWoff2}') format('woff2'),
         url('${fontAwesomeWoff}') format('woff'),
         url('${fontAwesomeTtf}') format('truetype');
  }

  body {
    /* background color is necessary in order to override default white on overflow x
    on e.g analytics page */
    background-color: #f3eee2;
  }
`
