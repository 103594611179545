import React, { Component } from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'

import { wasWelcomeShown, markWelcomeShown } from '../../../utils/localStorageUtils'
import Modal from '../../../components/common/Modal/Modal'
import WelcomeModal from '../../../components/owner/WelcomeModal/WelcomeModal'
import QvestList from '../../../components/owner/QvestList/QvestList'
import QvestFilter from '../../../components/owner/QvestFilter/QvestFilter'
import * as theme from '../../../components/theme'
import { filterQvestsByOrganization, filterQvestsById } from '../../../reducers/qvests'
import { changeFilter, updateQvest } from '../../../actions/qvestActions'
import { getQvestsByOrganization } from '../../../actions/qvestActions'

const SORT_OPTIONS = ['DATE', 'STATUS', 'NAME', 'PARTICIPANTS']

const FILTER_OPTIONS = [
  'ALL',
  'QVEST_OPENED',
  'QVEST_ONBOARDING_STARTED',
  'QVEST_CREATED',
  'QVEST_SCHEDULED',
  'QVEST_CLOSED',
  'QVEST_ARCHIVED'
]

const STATUS_SORT_ORDER = [
  'QVEST_OPENED',
  'QVEST_ONBOARDING_STARTED',
  'QVEST_SCHEDULED',
  null, // Draft
  'QVEST_CLOSED',
  'QVEST_EXPIRED'
]

const messages = defineMessages({
  QVEST_CLOSED: { id: 'owner.organizationQvestPage.closed', defaultMessage: 'Closed' },
  QVEST_OPENED: { id: 'owner.organizationQvestPage.opened', defaultMessage: 'Open' },
  QVEST_SCHEDULED: { id: 'owner.organizationQvestPage.scheduled', defaultMessage: 'Scheduled' },
  QVEST_ONBOARDING_STARTED: { id: 'owner.organizationQvestPage.invitationSent', defaultMessage: 'Invitation sent' },
  QVEST_CREATED: { id: 'owner.organizationQvestPage.created', defaultMessage: 'Draft' },
  QVEST_ARCHIVED: { id: 'owner.organizationQvestPage.archived', defaultMessage: 'Archived' },
  ALL: { id: 'owner.organizationQvestPage.all', defaultMessage: 'All Qvests' },
  DATE: { id: 'owner.organizationQvestPage.date', defaultMessage: 'Start date' },
  STATUS: { id: 'owner.organizationQvestPage.status', defaultMessage: 'Status' },
  NAME: { id: 'owner.organizationQvestPage.name', defaultMessage: 'Name' },
  PARTICIPANTS: { id: 'owner.organizationQvestPage.participants', defaultMessage: 'Participants' },
})

const ComponentRoot = styled.div`
  padding: 0 50px;
`

// Dismissing welcome modal after this date will be persisted
const WELCOME_LAUNCH_AT = new Date('2018-09-18T06:00:00.000Z')


class OwnerOrganizationQvestsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showOnboardingVideoModal: wasWelcomeShown(WELCOME_LAUNCH_AT),
      sortValue: 'STATUS',
    }
  }

  componentDidMount() {
    const { dispatch, match, qvestStore } = this.props
    const organizationId = match.params.organizationId
    const includeArchived = (qvestStore.filter === 'QVEST_ARCHIVED') // Optimization: Lazy load archived qvests
    dispatch(getQvestsByOrganization(organizationId, includeArchived))
  }

  handleQvestClick = (qvestId) => {
    const { qvestStore, history, dispatch } = this.props
    const qvest = filterQvestsById(qvestStore, qvestId)
    dispatch({ type: 'INIT_UNSAVED_QVEST', qvest })
    if (qvest.state === 'QVEST_CLOSED' || qvest.state === 'QVEST_EXPIRED') {
      history.push(`/owner/qvest/${qvestId}/analytics/home`)
    } else {
      history.push(`/owner/qvest/${qvestId}`)
    }
  }

  handleCloseModal = () => {
    markWelcomeShown()
    this.setState({ showOnboardingVideoModal: false })
  }

  renderOnboardingVideoModal() {
    if (!this.state.showOnboardingVideoModal) return null
    return (
      <Modal onBackgroundClick={this.handleCloseModal} onEscape={this.handleCloseModal}>
        <WelcomeModal onSubmit={this.handleCloseModal} />
      </Modal>
    )
  }

  handleFilterChange = (value) => {
    const { dispatch, match } = this.props
    if (value === 'QVEST_ARCHIVED') {
      const organizationId = match.params.organizationId
      dispatch(getQvestsByOrganization(organizationId, true))
    }
    dispatch(changeFilter(value))
  }

  handleSortChange = (value) => {
    this.setState({ sortValue: value })
  }

  handleArchive = (qvestId, archive) => {
    this.props.dispatch(updateQvest(qvestId, { isArchived: archive }))
  }

  filterQvests = (qvests, filter) => {
    if (!qvests) return qvests
    if (filter === 'QVEST_ARCHIVED') {
      return qvests.filter(({ isArchived }) => isArchived)
    } else {
      qvests = qvests.filter(({ isArchived }) => !isArchived)
    }
    if (filter === 'ALL') return qvests
    if (filter === 'QVEST_CREATED') {
      // created qvests have null state
      return qvests.filter(({ state }) => !state)
    }
    if (filter === 'QVEST_CLOSED') {
      // closed qvests might also be expired
      return qvests.filter(({ state }) =>
        state === filter || state === 'QVEST_EXPIRED')
    }
    // otherwise filter by state
    return qvests.filter(({ state }) => state === filter)
  }

  sortQvests = (qvests) => {
    if (!qvests) return []
    const { sortValue } = this.state
    // slice is needed to circumvent mutation
    if (sortValue === 'NAME') {
      return qvests.slice(0).sort((a, b) => {
        const aComp = a.name.toLowerCase()
        const bComp = b.name.toLowerCase()
        if (aComp < bComp) return -1
        if (aComp > bComp) return +1
        return 0
      })
    }
    // summary fields are optional
    if (sortValue === 'PARTICIPANTS') {
      return qvests.slice(0).sort((a, b) => {
        const bComp = (a.summary && a.summary.participants) || 0
        const aComp = (b.summary && b.summary.participants) || 0
        if (aComp < bComp) return -1
        if (aComp > bComp) return +1
        return 0
      })
    }
    // if qvest has null state indexof will return -1
    if (sortValue === 'DATE') {
      // schedule and openedAt fields are optional
      return qvests.slice(0).sort((a, b) => {
        const aComp = (a.schedule && a.schedule.openedAt) || ''
        const bComp = (b.schedule && b.schedule.openedAt) || ''
        return aComp - bComp
      })
    }
    return qvests.slice(0).sort((a, b) => {
      const aComp = STATUS_SORT_ORDER.indexOf(a.state)
      const bComp = STATUS_SORT_ORDER.indexOf(b.state)
      return aComp - bComp
    })
  }

  render() {
    const { sortValue } = this.state
    const { qvestStore, match, intl } = this.props
    const qvests = filterQvestsByOrganization(qvestStore, match.params.organizationId)
    const filter = this.props.qvestStore.filter
    const filteredQvests = this.filterQvests(qvests, filter)
    const displayedQvests = this.sortQvests(filteredQvests)

    const displayedSortOptions = SORT_OPTIONS.map(value => ({
      label: intl.formatMessage(messages[value]),
      value,
    }))
    const displayedFilterOptions = FILTER_OPTIONS.map(value => ({
      label: intl.formatMessage(messages[value]),
      value,
    }))

    return (
      <ComponentRoot>
        {this.renderOnboardingVideoModal()}
        <ThemeProvider theme={theme.green}>
          <QvestFilter
            sortOptions={displayedSortOptions}
            filterOptions={displayedFilterOptions}
            sortValue={sortValue}
            filterValue={filter}
            onSortChange={this.handleSortChange}
            onFilterChange={this.handleFilterChange}
          />
        </ThemeProvider>
        <QvestList
          isLoading={qvestStore.isLoading}
          qvests={displayedQvests}
          onItemClick={this.handleQvestClick}
          onArchive={this.handleArchive}
        />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS()
  }
}

export default connect(mapStateToProps)(injectIntl(OwnerOrganizationQvestsPage))
