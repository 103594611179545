import React from 'react'
import styled, { withTheme } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useQuery, gql } from '@apollo/client'

import { makeGroupColorScales } from '../../../../../utils/d3Utils'
import Card from '../../../../../components/common/Card/Card'
import Typography from '../../../../../components/common/Typography/Typography'
import Disclaimer from '../../../../../components/owner/Disclaimer/Disclaimer'
import { TitleBar } from '../../../../../components/owner/AnalyticsLayout/AnalyticsLayout'
import AnalyticsActivity from '../../../../../components/owner/AnalyticsActivity/AnalyticsActivity'
import OverallParticipationTable from '../../../../../components/owner/OverallParticipationTable/OverallParticipationTable'
import ContainerDimensions from 'react-container-dimensions'
import VisitorsCard from '../../../../../components/owner/PageViewsCard/VisitorsCard'
import { BarChart, Legend, activityMapping } from 'data-viz'


const PARTICIPATION_QUERY = gql`
  query ($qvestId: String!) {
    participation(qvestId: $qvestId) {
      groupId
      name
      participantCount
      participantActiveCount
      questionReceivedCount
      answerSentCount
      questionSentCount
      answerReceivedCount
    }
  }
`

// TODO Re-combine into single query when we have a cache and can do partial load (Visits field may be slow to load and we don't want this to delay page load)
const VISITS_QUERY = gql`
  query ($qvestId: String!) {
    participants(qvestId: $qvestId) {
      visits {
        unique {
          name
          count
          percentage
        }
      }
    }
  }
`

const ComponentRoot = styled.div`
  & > * { max-width: 1400px; }
  & > :nth-child(3), & > :nth-child(4) {
    padding: 0 30px;
    margin-top: 30px;
    min-width: 1270px;
  }
  & > :last-child {
    margin: 50px 30px 30px 30px;
    max-width: 989px
  }
`

const formatBarChartData = participation => participation.map(({ groupId, name, participationRate, fill }) => ({
  id: groupId,
  value: participationRate,
  label: name,
  fill,
}))

const FullDataCard = ({ sortedParticipation, sum }) => {
  const barData = formatBarChartData([...sortedParticipation, sum])
  // remove values from legend
  // eslint-disable-next-line no-unused-vars
  const legendData = barData.map(({ value, ...rest }) => rest)
  return (
    <Card>
      <Card.Header>
        <Typography variant="heading3">Active participants</Typography>
      </Card.Header>
      <Card.Body>
        <Typography secondary variant="medium" weight="light">
          Explore the percentage of participants who asked or answered any questions.
        </Typography>
      </Card.Body>
      <Card.ChartBox>
        <div>
          <ContainerDimensions>
            {({ width }) => <BarChart data={barData} width={width} />}
          </ContainerDimensions>
        </div>
        <Legend horizontal data={legendData} />
      </Card.ChartBox>
      <OverallParticipationTable participation={[...sortedParticipation, sum]} />
    </Card>
  )
}

const OwnerAnalyticsActivityPage = ({ match, history, theme }) => {
  const qvestId = match.params.qvestId
  const { data } = useQuery(PARTICIPATION_QUERY, { variables: { qvestId } })
  const { data: visitsData, loading: isVisitsLoading } = useQuery(VISITS_QUERY, { variables: { qvestId } })

  // TODO: Display error and loading state

  const handleBackClick = () => {
    history.push(`/owner/qvest/${qvestId}/analytics/home`)
  }

  if (!data || !data.participation) return null

  const { participation } = data
  const ids = participation.map(p => p.groupId)
  const colorScale = makeGroupColorScales(theme, ids).primaryColors

  const formattedData = activityMapping.formatData(participation, theme, colorScale)
  const { sortedParticipation, sum } = formattedData

  const title = <FormattedMessage
    id="owner.AnalyticsActivity.title"
    defaultMessage="Activity"
  />
  const description = <FormattedMessage
    id="owner.AnalyticsActivity.description"
    defaultMessage="Commitment to Qvest project"
  />

  return (
    <ComponentRoot>
      <TitleBar
        title={title}
        description={description}
        onBack={handleBackClick}
      />
      <AnalyticsActivity participation={participation} />
      <div>
        <FullDataCard sortedParticipation={sortedParticipation} sum={sum} />
      </div>
      <div>
        <VisitorsCard
          data={visitsData ? visitsData.participants.visits.unique : null}
          loading={isVisitsLoading}
        />
      </div>
      <Disclaimer />
    </ComponentRoot>
  )
}

export default withTheme(OwnerAnalyticsActivityPage)
