import { log } from '../../utils/trackingUtils'
import * as participantAuthUtils from './participantAuthUtils'
import * as ownerAuthUtils from './ownerAuthUtils'

let currentMode = null
let ready = false

function hasPersistedAuthMode() {
  return document.cookie.split(';').some(item => item.trim().indexOf('role=') === 0)
}

function setCurrentAuthMode(isParticipant) {
  currentMode = isParticipant ? 'participant' : 'owner'
  document.cookie = `role=${currentMode};path=/`
}

function getCurrentAuthMode() {
  if (currentMode) {
    return currentMode
  }
  if (hasPersistedAuthMode()) {
    return document.cookie.split('; ').find(row => row.startsWith('role=')).split('=')[1]
  }
  return null
}

export function logout() {
  if (getCurrentAuthMode() === 'participant') {
    return participantAuthUtils.logout()
  } else {
    return ownerAuthUtils.logout()
  }
}

export async function getAuthHeader() {
  // If debug variable set return static token provided in variable (No internet connection required)
  if (process.env.DEBUGGING_AUTH_HEADER) {
    return { Authorization: `Bearer ${process.env.DEBUGGING_AUTH_HEADER}` }
  }
  // If auth module isn't initialized, exit and log a warning (this should never happen)
  if (!ready) {
    log.warning(new Error('Auth header requested before initialization'))
    return null
  }
  // ... Otherwise, get header from authentication utilities
  if (getCurrentAuthMode() === 'participant') {
    return participantAuthUtils.getAuthHeader()
  } else {
    return ownerAuthUtils.getAuthHeader()
  }
}

export async function getFlowConfig(flowId, variant) {
  if (getCurrentAuthMode() === 'participant') {
    return participantAuthUtils.getFlowConfig(flowId, variant)
  } else {
    return ownerAuthUtils.getFlowConfig(flowId, variant)
  }
}

export async function getFlowErrors(errorId) {
  if (getCurrentAuthMode() === 'participant') {
    return participantAuthUtils.getFlowErrors(errorId)
  } else {
    return ownerAuthUtils.getFlowErrors(errorId)
  }
}

export async function completeFlow() {
  if (getCurrentAuthMode() === 'participant') {
    return participantAuthUtils.completeFlow()
  } else {
    return ownerAuthUtils.completeFlow()
  }
}

export function initialize(isParticipant = false, qvestId = null) {
  // If debug variable set, don't initialize anything
  if (process.env.DEBUGGING_AUTH_HEADER) {
    return Promise.resolve(true)
  }
  // ... Otherwise, initialize authentication
  setCurrentAuthMode(isParticipant)
  if (isParticipant) {
    return participantAuthUtils.initialize(qvestId)
      .then(result => {
        ready = result
        return ready
      })
  } else {
    return ownerAuthUtils.initialize()
      .then(result => {
        ready = result
        return ready
      })
  }
}

export function joinQvest(accessCode, name) {
  return participantAuthUtils.joinQvest(accessCode, name)
}

export function createUser(credentials, inviteToken = null, fields = {}) {
  return ownerAuthUtils.createUser(credentials, inviteToken, fields)
}

