import { fromJS } from 'immutable'

let INITIAL_STATE = fromJS({
  isLoading: false,
  lastUpdate: null,
  error: null,
  file: {
    isLoading: false,
    fileName: null,
    participants: null,
    groups: null,
    error: null
  },
  data: {},
  byQvest: {}
})

const file = (state = INITIAL_STATE.file, action) => {
  const { type, fileName, participants, groups, error } = action
  switch (type) {
    case 'IMPORT_PARTICIPANTS_FILE_REQUEST':
      return state.merge(INITIAL_STATE.get('file'), {
        isLoading: true
      })
    case 'IMPORT_PARTICIPANTS_FILE_SUCCESS':
      return state.merge({
        fileName,
        participants,
        groups,
        isLoading: false
      })
    case 'IMPORT_PARTICIPANTS_FILE_FAILURE':
      return state.merge({
        error,
        isLoading: false
      })
    case 'CLEAR_PARTICIPANTS_FILE':
    case 'CREATE_PARTICIPANTS_BULK_SUCCESS':
      return INITIAL_STATE.get('file')
    default:
      return state
  }
}

const byQvest = (state = INITIAL_STATE.byQvest, action) => {
  const { type, qvestId, ids, participant, validity } = action
  switch (type) {
    case 'CREATE_PARTICIPANTS_BULK_SUCCESS':
      return state
        .updateIn([qvestId, 'ids'], oldIds => oldIds.concat(ids))
        .setIn([qvestId, 'validity'], validity)
    case 'GET_PARTICIPANTS_SUCCESS':
      return state.set(qvestId,
        fromJS({ ids, validity })
      )
    case 'CREATE_PARTICIPANT_SUCCESS':
      return state
        .updateIn([qvestId, 'ids'], ids => ids.unshift(participant.participantId))
        .setIn([qvestId, 'validity'], validity)
    case 'DELETE_PARTICIPANT_SUCCESS':
      return state
        .updateIn([qvestId, 'ids'], ids => ids.filter(id => id !== participant.participantId))
        .setIn([qvestId, 'validity'], validity)
    case 'UPDATE_PARTICIPANT_SUCCESS':
      return state
        .setIn([qvestId, 'validity'], validity)
    case 'DELETE_ALL_PARTICIPANTS_SUCCESS':
      return state
        .updateIn([qvestId, 'ids'], ids => ids.filter(id => action.ids.indexOf(id) === -1))
        .setIn([qvestId, 'validity'], validity)
    default:
      return state
  }
}

const data = (state = INITIAL_STATE.data, action) => {
  const { type, data, ids, participant } = action
  switch (type) {
    case 'GET_PARTICIPANTS_SUCCESS':
    case 'CREATE_PARTICIPANTS_BULK_SUCCESS':
      return state.merge(fromJS(data))
    case 'CREATE_PARTICIPANT_SUCCESS':
    case 'GET_PARTICIPANT_SUCCESS':
    case 'UPDATE_PARTICIPANT_SUCCESS':
    case 'TOGGLE_PARTICIPANT_SUSPENSION_SUCCESS':
      return state.set(participant.participantId, fromJS(participant))
    case 'DELETE_PARTICIPANT_SUCCESS':
      return state.remove(participant.participantId)
    case 'DELETE_ALL_PARTICIPANTS_SUCCESS':
      return ids.reduce((state, id) => state.delete(id), state)
    default:
      return state
  }
}

const participants = (state = INITIAL_STATE, action) => {
  const { type, error } = action
  switch (type) {
    case 'GET_PARTICIPANTS_REQUEST':
    case 'CREATE_PARTICIPANT_REQUEST':
    case 'GET_PARTICIPANT_REQUEST':
    case 'DELETE_PARTICIPANT_REQUEST':
    case 'UPDATE_PARTICIPANT_REQUEST':
    case 'DELETE_ALL_PARTICIPANTS_REQUEST':
    case 'CREATE_PARTICIPANTS_BULK_REQUEST':
    case 'TOGGLE_PARTICIPANT_SUSPENSION_REQUEST':
      return state
        .set('isLoading', true)
    case 'GET_PARTICIPANTS_SUCCESS':
      return state
        .set('isLoading', false)
        .set('error', null)
        .update('byQvest', bq => byQvest(bq, action))
        .update('data', d => data(d, action))
    case 'CREATE_PARTICIPANTS_BULK_SUCCESS':
    case 'CREATE_PARTICIPANT_SUCCESS':
    case 'GET_PARTICIPANT_SUCCESS':
    case 'DELETE_PARTICIPANT_SUCCESS':
    case 'UPDATE_PARTICIPANT_SUCCESS':
    case 'DELETE_ALL_PARTICIPANTS_SUCCESS':
    case 'TOGGLE_PARTICIPANT_SUSPENSION_SUCCESS':
      return state
        .set('isLoading', false)
        .set('error', null)
        .set('lastUpdate', new Date())
        .update('byQvest', bq => byQvest(bq, action))
        .update('data', d => data(d, action))
        .update('file', fs => file(fs, action))
    case 'GET_PARTICIPANTS_FAILURE':
    case 'CREATE_PARTICIPANT_FAILURE':
    case 'GET_PARTICIPANT_FAILURE':
    case 'DELETE_PARTICIPANT_FAILURE':
    case 'UPDATE_PARTICIPANT_FAILURE':
    case 'DELETE_ALL_PARTICIPANTS_FAILURE':
    case 'CREATE_PARTICIPANTS_BULK_FAILURE':
      return state.merge(state, {
        isLoading: false,
        error: error
      })
    case 'IMPORT_PARTICIPANTS_FILE_REQUEST':
    case 'IMPORT_PARTICIPANTS_FILE_SUCCESS':
    case 'IMPORT_PARTICIPANTS_FILE_FAILURE':
    case 'CLEAR_PARTICIPANTS_FILE':
      return state.update('file', fileState => file(fileState, action))
    default:
      return state
  }
}

export function filterParticipantsByQvest(participants, qvestId) {
  if (!participants.byQvest.hasOwnProperty(qvestId)) {
    return null
  }
  return participants.byQvest[qvestId].ids.map(id => participants.data[id])
}

export function filterValidityByQvest(participants, qvestId) {
  if (!participants.byQvest.hasOwnProperty(qvestId)) {
    return null
  }
  const { validity, ids } = participants.byQvest[qvestId]
  // Add reference to participants to each validation item, if any found
  if (validity.items && validity.items.length > 0) {
    const resource = { participants: ids.map(id => participants.data[id]) }
    validity.items = validity.items.map(item => ({ ...item, resource }))
  }
  return validity
}

export default participants
