import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Typography from '../Typography/Typography'
import Heading from '../Typography/Heading'
import { groupHighlightedWords } from '../../../utils/stringUtils'
import PinIcon from './PinIcon'

const TwoColumns = styled.div`
  display: flex;
  padding: 14px 0 28px 0;
  flex-wrap: wrap;

  & > * {
    width: 50%
  }

  &:not(:last-child) {
    border-bottom: 1px solid #DFDFDF;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 280px;
  margin-top: 21px;
`

const ColumnContent = styled.div`
  flex: 1;
  padding-right: 40px;

  & > * {
    display: block;
  }

  & ${Typography} {
    font-family: ${({ theme }) => theme.font4};
    line-height: 20px;
    white-space: pre-wrap;
  }
`

const ColumnHeading = styled.span`
  margin-bottom: 10px;
  & > * {
    display: inline
  }
  & > :not(:last-child) {
    margin-right: 10px;
  }
`

const isPinned = ({ pins }) => pins && (pins.count != null) && (pins.count > 0)

class QuestionAnswerTable extends Component {
  toHighlightedSpans(content, highlights) {
    // Prepare substrings of content to be highlighted or not highlighted
    const wordGroups = groupHighlightedWords(content, highlights)

    // Prepare highlighted or not highlighted text components
    return wordGroups.map((wg, i) => {
      if (wg.highlighted) {
        return (<Typography weight="bold" key={i}>{wg.content}</Typography>)
      } else {
        return (<Typography weight="light" key={i}>{wg.content}</Typography>)
      }
    })
  }

  render() {
    const { data, highlights, onPinToggle, pinnable, outOfPins, isOwner, pinningClosed } = this.props
    return (
      <Fragment>
        {data.map(question => (
          <TwoColumns key={question.questionId}>
            <Column>
              <ColumnContent>
                <ColumnHeading>
                  <Heading.h3>
                    <FormattedMessage id="QuestionAnswerTable.questionHeading" defaultMessage="Question" />
                  </Heading.h3>
                  <PinIcon
                    show={pinnable}
                    pinned={isPinned(question)}
                    out={outOfPins}
                    locked={isOwner}
                    closed={pinningClosed}
                    onToggle={value => onPinToggle(question.questionId, value)}
                  />
                </ColumnHeading>
                <span>{this.toHighlightedSpans(question.questionContent, highlights)}</span>
              </ColumnContent>
            </Column>
            <Column>
              <ColumnContent>
                <Heading.h3>
                  <FormattedMessage id="QuestionAnswerTable.answerHeading" defaultMessage="Answer" />
                </Heading.h3>
                <span>{this.toHighlightedSpans(question.answerContent, highlights)}</span>
              </ColumnContent>
            </Column>
          </TwoColumns>
        ))}
      </Fragment>
    )
  }
}

QuestionAnswerTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      questionContent: PropTypes.string,
      questionSentAt: PropTypes.date,
      answerContent: PropTypes.string,
      answerSentAt: PropTypes.date
    })
  ).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string),
  pinnable: PropTypes.bool
}

QuestionAnswerTable.defaultProps = {
  highlights: [],
}

export default QuestionAnswerTable
