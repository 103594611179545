import PropTypes from 'prop-types'
import styled from 'styled-components'

function determineBackgroundColor(props) {
  const theme = props.theme
  if (props.error) {
    return theme.alert.error
  } else if (props.warning) {
    return theme.alert.warning
  } else {
    return theme.background3
  }
}

function determineTextColor(props) {
  const theme = props.theme
  if (props.error || props.warning) {
    return theme.tertiary
  } else {
    return theme.default
  }
}

const Message = styled.div`
  background-color: ${props => determineBackgroundColor(props)};
  padding: 20px;
  text-align: center;
  
  & > * {
    color: ${props => determineTextColor(props)};  
  }
`

Message.propTypes = {
  warning: PropTypes.bool,
  error: PropTypes.bool
}

Message.defaultProps = {
  warning: false,
  error: false
}

export default Message
