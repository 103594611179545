import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

import Disclaimer from '../../../../../components/owner/Disclaimer/Disclaimer'
import AnalyticsGrid from '../../../../../components/owner/AnalyticsGrid/AnalyticsGrid.js'
import { hasSufficientData } from '../../../../../components/owner/AnalyticsGrid/AnalyticsGrid.js'

import { getGroupingByQvestId } from '../../../../../actions/groupingActions'
import { getParticipation } from '../../../../../actions/statsActions'
import { searchQuestions } from '../../../../../actions/questionActions'
import { getParticipantsActivity } from '../../../../../actions/statsActions'
import { getGroupGraph } from '../../../../../actions/statsActions'
import { getQuestionDistribution } from '../../../../../actions/statsActions'
import { getWordFrequencies, getQvestSummary } from '../../../../../actions/statsActions'

import { filterGroupingsByQvest } from '../../../../../reducers/grouping'
import { filterParticipationByQvestId } from '../../../../../reducers/stats'
import { filterParticipantsActivityByQvestId } from '../../../../../reducers/stats'
import { filterGroupGraphByQvestId } from '../../../../../reducers/stats'
import { filterWordFrequenciesByQvestId } from '../../../../../reducers/stats'
import { filterQuestionDistributionByQvestId } from '../../../../../reducers/stats'
import { filterQuestionSearchResult } from '../../../../../reducers/questions'


const ComponentRoot = styled.div`
  min-width: 300px;
`

const BottomPadded = styled.div`
  padding: 0 25px 25px 25px;
  max-width: 989px;
`

const ANALYTICS_HOME_QUERY = gql`
  query q ($qvestId:String!) {
    themeSet(qvestId: $qvestId) {
      themeSetId
      themes {
        name
        themeId
        createdAt
        themeSet {
          themeSetId
        }
        questionIds
      }
    }
  }
`

class OwnerAnalyticsHomePage extends Component {
  state = {
    themeSet: null,
    error: null
  }

  componentDidMount() {
    const { dispatch, match, client } = this.props
    const qvestId = match.params.qvestId

    dispatch(getGroupingByQvestId(qvestId))
    dispatch(getQvestSummary(qvestId))
      .then(summary => {
        if (hasSufficientData(summary)) {
          dispatch(getParticipation(qvestId))
          dispatch(searchQuestions({qvestId}))
          dispatch(getParticipantsActivity(qvestId))
          dispatch(getGroupGraph(qvestId))
          dispatch(getWordFrequencies(qvestId))
          dispatch(getQuestionDistribution(qvestId))
        }
      })

    this.apolloObservable = client.watchQuery({ query: ANALYTICS_HOME_QUERY, variables: { qvestId } }).subscribe({
      next: (result) => this.setState({ themeSet: result.data.themeSet }),
      error: (errors) => this.setState({ error: errors })
    })
  }

  componentWillUnmount() {
    if (this.apolloObservable) {
      this.apolloObservable.unsubscribe()
    }
  }

  handleTabClick = (analyticsTabId) => {
    const { match, history } = this.props
    const qvestId = match.params.qvestId
    history.push(`/owner/qvest/${qvestId}/analytics/${analyticsTabId}`)
  }

  render() {
    const {
      groupingStore,
      questionsStore,
      participationStore,
      participantsActivityStore,
      groupGraphStore,
      wordFrequenciesStore,
      questionDistributionStore,
      themeStore,
      match
    } = this.props

    const qvestId = match.params.qvestId
    const questions = filterQuestionSearchResult(questionsStore, {qvestId})
    const grouping = filterGroupingsByQvest(groupingStore, qvestId)
    const groups = grouping ? grouping.groups : null
    const participation = filterParticipationByQvestId(participationStore, qvestId)
    const participantsActivity = filterParticipantsActivityByQvestId(participantsActivityStore, qvestId)
    const groupGraph = filterGroupGraphByQvestId(groupGraphStore, qvestId)
    const wordFrequencies = filterWordFrequenciesByQvestId(wordFrequenciesStore, qvestId)
    const questionProfile = filterQuestionDistributionByQvestId(questionDistributionStore, qvestId)

    const { themeSet } = this.state
    const themes = themeSet && themeSet.themes

    let loading = {
      activity: !participation && participationStore.isLoading,
      mindset: !participation && participationStore.isLoading,
      stakeholders: (!participantsActivity || !groups) && (participantsActivityStore.isLoading || groupingStore.isLoading),
      distribution: !participation && participationStore.isLoading,
      interaction: !groupGraph && groupGraphStore.isLoading,
      network: !groupGraph && groupGraphStore.isLoading,
      highlights: (!wordFrequencies || !groups) && (wordFrequenciesStore.isLoading || groupingStore.isLoading),
      focus: !questionProfile && questionDistributionStore.isLoading,
      themes: (!participation || !themes) && (participationStore.isLoading || themeStore.isLoading),
    }

    return (
      <ComponentRoot>
        <AnalyticsGrid
          tier={3}
          loading={loading}
          groups={groups}
          questions={questions}
          participation={participation}
          participantsActivity={participantsActivity}
          groupGraph={groupGraph}
          wordFrequencies={wordFrequencies}
          questionProfile={questionProfile}
          themes={themes}
          onNavigateClick={this.handleTabClick}
        />
        <BottomPadded>
          <Disclaimer inverted />
        </BottomPadded>
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    groupingStore: state.grouping.toJS(),
    questionsStore: state.questions.toJS(),
    participationStore: state.stats.get('participation').toJS(),
    participantsActivityStore: state.stats.get('participantsActivity').toJS(),
    groupGraphStore: state.stats.get('groupGraph').toJS(),
    wordFrequenciesStore: state.stats.get('wordFrequencies').toJS(),
    questionDistributionStore: state.stats.get('questionDistribution').toJS(),
    themeStore: state.themes.toJS(),
  }
}

export default connect(mapStateToProps)(withApollo(OwnerAnalyticsHomePage))
