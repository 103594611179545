import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import TopicSuggestionModal from '../../components/owner/TopicSuggestionModal/TopicSuggestionModal'
import { queryTopicSuggestions } from '../../actions/topicActions'
import { IntlProvider } from 'react-intl'


const ComponentRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const TopicSuggestionPage = ({ topicStore, dispatch }) => {
  const suggestions = topicStore.suggestions
  const loading = topicStore.isLoading
  const handleTopicQuery = (query, language) => dispatch(queryTopicSuggestions(query, language))
  return (
    <ComponentRoot>
      <Helmet>
        <title>Qvest Topic Generator</title>
      </Helmet>
      <IntlProvider locale="en">
        <TopicSuggestionModal
          loading={loading}
          suggestions={suggestions}
          onQuery={handleTopicQuery}
        />
      </IntlProvider>
    </ComponentRoot>
  )
}

function mapStateToProps(state) {
  return {
    topicStore: state.topic.toJS()
  }
}

export default connect(mapStateToProps)(TopicSuggestionPage)
