import { addLocaleData } from 'react-intl'
import daMessages from '../static/lang/da.json'
import deMessages from '../static/lang/de.json'
import daLocaleData from 'react-intl/locale-data/da'
import deLocaleData from 'react-intl/locale-data/de'

export function loadLocale(locale) {
  // TODO: When it becomes needed, make this fetch from some remote server (TMS? GCloud storage?)
  switch (locale) {
    case 'da':
      addLocaleData(daLocaleData)
      return daMessages
    case 'de':
      addLocaleData(deLocaleData)
      return deMessages
    default:
      return null
  }
}

// Converts a noun to possessive form in the given locale
// e.g. "John" becomes "John's"
//
// (BEWARE: Does not support plural nouns. In some languages you
// need extra rules for that, such as in English where "witches' brooms"
// and "women's clothing" would be the correct form)
//
// Translation rules based on: https://medium.com/@ssandler_35379/hi-aaron-1c7a2c11aa2d
export function possessive(noun, locale) {
  switch (locale.toLowerCase().substr(0, 2)) {
    case 'en':
      return `${noun}’s`
    case 'es':
      return `de ${noun}`
    case 'fr':
      return noun.match(/^[aeiou]/i) ? `d’${noun}` : `de ${noun}`
    case 'de':
      return noun.match(/[sßxz]$/i) ? `${noun}’` : `${noun}s`
    case 'ja':
      return `${noun}の`
    case 'da':
      return noun.match(/[s]$/i) ? `${noun}’` : `${noun}s`
    default:
      throw new Error(`Unsupported locale ${locale}, use one of: en, es, fr, de, ja`)
  }
}
