import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import * as tus from 'tus-js-client'
import { log } from '../../../utils/trackingUtils'
import Modal from '../../common/Modal/Modal'
import UploadVideoModal from '../UploadVideoModal/UploadVideoModal'
import GenericModal from '../../common/GenericModal/GenericModal'


// Upload through TUS protocol
const uploadFile = ({ uploadUrl, file, handleProgress, handleError, handleSuccess }) => {
  const upload = new tus.Upload(file, {
    uploadUrl,
    retryDelays: [0, 3000, 5000, 10000, 20000],
    onProgress: handleProgress,
    onError: handleError,
    onSuccess: handleSuccess
  })
  upload.start()
}

const UploadModal = ({ video, onInitUpload, onClose }) => {
  const [file, setFile] = useState(null)
  const [uploadedBytes, setUploadedBytes] = useState(0)
  const [error, setError] = useState(null)
  const [transcodingStatus, setTranscodingStatus] = useState(null)

  // Upon error, reset and log
  const handleError = error => {
    setFile(null)
    setError(error)
    log.error(error)
  }

  // Show transcoding status
  const handleSuccess = () => {
    setFile(null)
    setTranscodingStatus('in_progress')
  }

  // Update progress bar
  const handleProgress = (bytes) => {
    setUploadedBytes(bytes)
  }

  // Start upload when uploadUrl is provided
  const uploadUrl = video && video.uploadUrl
  const uploadStatus = video && video.uploadStatus
  useEffect(() => {
    if (uploadUrl && uploadStatus === 'in_progress') {

      uploadFile({ uploadUrl, file, handleProgress, handleError, handleSuccess })
    }
  }, [uploadUrl]) // only runs when uploadUrl changes

  // Initiate upload on file change
  const handleFileChange = file => {
    setFile(file)
    return onInitUpload(file.size)
      .catch(handleError)
  }

  return (
    <Modal
      onBackgroundClick={onClose}
      onEscape={onClose}
    >
      <UploadVideoModal
        onClose={onClose}
        onFileChange={handleFileChange}
        file={file}
        error={error}
        transcoding={transcodingStatus === 'in_progress'}
        uploadedBytes={uploadedBytes}
        video={video}
      />
    </Modal>
  )
}

const RemoveModal = ({ onRemoveVideo, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const title = (
    <FormattedMessage
      id="owner.VideoManager.RemoveModal.title"
      defaultMessage="Delete video"
    />
  )
  const handleSubmit = () => {
    setLoading(true)
    onRemoveVideo()
      .catch(err => {
        setLoading(false)
        throw err
      })
  }
  return (
    <Modal>
      <GenericModal
        loading={loading}
        confirm
        cancel
        title={title}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      >
        <Typography weight="light">
          <FormattedMessage
            id="owner.VideoManager.RemoveModal.description"
            defaultMessage="Are you sure you wish to delete the uploaded video?"
          />
        </Typography>
      </GenericModal>
    </Modal>
  )
}

const VideoManager = (props) => {
  const { variant, video, onInitUpload, onRemoveVideo, onClose } = props

  let displayedModal = null
  switch (variant) {
    case 'upload':
      return (
        <UploadModal
          video={video}
          onInitUpload={onInitUpload}
          onClose={onClose}
        />
      )
    case 'remove':
      return (
        <RemoveModal
          onCancel={onClose}
          onRemoveVideo={onRemoveVideo}
        />
      )
    case 'edit':
      // TODO: Implement to set subtitles and/or replace video
      return displayedModal
    default:
      return displayedModal
  }
}

VideoManager.propTypes = {
  variant: PropTypes.oneOf(['upload', 'remove', 'edit']),
  video: PropTypes.shape({
    uploadUrl: PropTypes.string,
    uploadStatus: PropTypes.string
  }),
  onInitUpload: PropTypes.func,
  onRemoveVideo: PropTypes.func,
  onClose: PropTypes.func
}

export default VideoManager
