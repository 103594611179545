import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Icon from '../../common/Icon/Icon'

const ComponentRoot = styled.div`
  position: relative;
`

const Dropdown = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  right: -40px;
  z-index: 10;
  background-color: ${({ theme }) => theme.background1};
  box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
  border-radius: 4px;
  padding: 20px;
  display: ${({ show }) => show ? 'flex' : 'none'};
  flex-direction: column;
  & > * + * { margin-top: 10px; }
  & > * { white-space:nowrap; }
  cursor: default;
`

const Button = styled.button`
  padding: 5px 10px;
  background-color: transparent;
  border-style: none;
  transition: 0.2s;
  border-radius: 4px;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
  }
  /* phasing out clickable prop on Icon for accessibility */
  && > * { cursor: pointer; }
`

export default class QvestCardDropdown extends Component {
  state = {
    showDropdown: false,
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  unsubscribe = () => {
    document.removeEventListener('click', this.handleClickAnywhere)
  }

  handleButtonClick = (event) => {
    event.stopPropagation()
    const { showDropdown } = this.state
    if (showDropdown) return null
    this.setState({ showDropdown: true })
    document.addEventListener('click', this.handleClickAnywhere)
  }

  handleClickAnywhere = (event) => {
    event.preventDefault()
    this.setState({ showDropdown: false })
    this.unsubscribe()
  }

  handleDropdownClick = (event) => {
    event.stopPropagation()
  }

  render() {
    const { onArchiveClick, isArchived } = this.props
    const { showDropdown } = this.state
    let text
    if (isArchived) {
      text = (
        <FormattedMessage
          id="owner.QvestCardDropdown.unarchive"
          defaultMessage="Unarchive Qvest"
        />
      )
    } else {
      text = (
        <FormattedMessage
          id="owner.QvestCardDropdown.archive"
          defaultMessage="Archive Qvest"
        />
      )
    }
    return (
      <ComponentRoot>
        <Button onClick={this.handleButtonClick}>
          <Icon variant="ellipsis-h" />
        </Button>
        <Dropdown show={showDropdown} onClick={this.handleDropdownClick}>
          <Typography clickable variant="heading5" onClick={onArchiveClick}>{text}</Typography>
        </Dropdown>
      </ComponentRoot>
    )
  }

  static propTypes = {
    onArchiveClick: PropTypes.func,
  }

  static defaultProps = {
    onArchiveClick: () => { },
  }
}
