import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import * as d3 from 'd3'
import Typography from '../Typography/Typography'
import CenteredLoader from '../CenteredLoader/CenteredLoader'

const THICKNESS = 6


const ComponentRoot = styled.div`
  position: relative;
  top: 0;
  left: 0
`

const CenteredLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LabelNumber = styled.span`
  display: inline;
  font-family: ${({ theme }) => theme.font2};
  font-size: 42px;
  color: ${({ inverted, theme }) => inverted ? theme.inverted.one[0] : theme.one[0]}
`

const LabelPercentage = styled.span`
  display: inline;
  font-family: ${({ theme }) => theme.font2};
  font-size: 22px;
  color: ${({ inverted, theme }) => inverted ? theme.inverted.one[0] : theme.one[0]}
`


class RadialBarChart extends Component {
  constructor(props) {
    super(props)
  }

  renderChart(width) {
    let { value, theme, inverted, primary } = this.props
    const radius = (width / 2)

    // Compute geometric angles
    const values = [ value, (1 - value) ]
    const angles = d3.pie().sort(null)(values)

    // Prepare colors
    const bgColor = inverted ? theme.inverted.two[0]: theme.inverted.one[0]
    let barColor = inverted ? theme.inverted.one[0] : theme.one[0]
    if (primary) barColor = theme.color[0]
    const colors = [
      { fill: barColor },
      { fill: bgColor }
    ]

    // Zip slice and angle data into single collection
    const sliceData = values.map((value, i) => ({
      value,
      ...angles[i],
      ...colors[i]
    }))

    // Compute geometrical paths
    let path = d3.arc()
      .innerRadius(radius - THICKNESS)
      .outerRadius(radius)
      .cornerRadius(d => (d.index !== 0) ? 0 : THICKNESS/2)

    // Generate slices (SVG paths)
    const arcs = sliceData.map((slice, i) => (
      <path
        fill={slice.fill}
        opacity={slice.opacity}
        key={i}
        d={path(slice)}
      />
    ))

    // Wrap in SVG translating origin of chart to center of drawing
    return (
      <div>
        <svg width={width} height={width}>
          <g transform={`translate(${width / 2},${width / 2})`}>
            <g>
              {arcs}
            </g>
          </g>
        </svg>
      </div>
    )
  }

  renderLabels() {
    const { text, value, isLoading, inverted } = this.props
    let textElement
    if (isLoading) return null
    if (text) {
      textElement = (
        <Typography variant="small" weight="light" inverted={inverted}>{text}</Typography>
      )
    }
    const valueFormatted = Math.round(value * 100)
    return (
      <CenteredLabel>
        <span>
          <LabelNumber inverted={inverted}>{valueFormatted}</LabelNumber>
          <LabelPercentage inverted={inverted}>%</LabelPercentage>
        </span>
        {textElement}
      </CenteredLabel>
    )
  }

  render() {
    let { width, height, value, isLoading } = this.props
    width = width || 300
    height = height || 300

    const hasData = (value != null)
    const showLoader = (!hasData && isLoading)

    return (
      <ComponentRoot style={{ width, height }}>
        {showLoader ? <CenteredLoader /> : null}
        {hasData ? this.renderChart(width) : null}
        {this.renderLabels()}
      </ComponentRoot>
    )
  }
}

export default withTheme(RadialBarChart)
