import React, { Component } from 'react'
import ContainerDimensions from 'react-container-dimensions'
import debounce from 'lodash/debounce'

class DynamicContainerDimensions extends Component {
  constructor(props) {
    super(props)
    this.state = { show: true }
    this.showChildren = debounce(this.handleShow, 250)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    // Only trigger on width changes to avoid bouncing on mobile browsers where navigation constantly changes height
    if (this.viewPortWidth !== window.innerWidth) {
      this.viewPortWidth = window.innerWidth
      this.setState({ show: false })
      this.showChildren()
    }
  }

  handleShow = () => {
    this.setState({ show: true })
  }

  render() {
    // hide children when resizing the window allowing svgs' parents to shrink
    // also low end machines without hardware acceleration won't catch fire
    if (!this.state.show) return null

    return (
      <ContainerDimensions>
        {this.props.children}
      </ContainerDimensions>
    )
  }
}

export default DynamicContainerDimensions
