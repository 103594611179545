import * as color from 'color'

const HEX_ALPHA_RE = /#([0-9a-f]{4}(?:[0-9a-f]{4})?)\b/i

/**
 * transform RGBA hexadecimal notations (#RRGGBBAA or #RGBA) to rgba().
 *
 * @param  {String} string declaration value
 * @return {String}        converted declaration value to rgba()
 */
export function hexaToRgba(string) {
  const m = HEX_ALPHA_RE.exec(string)
  if (!m) {
    return string
  }
  return color(string).string()
}
