import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl } from 'react-intl'
import styled from 'styled-components'
import Icon from '../Icon/Icon'
import Typography from '../Typography/Typography'
import Button from '../Button/Button'


const ComponentRoot = styled.div`
  width: 400px;
  background-color: ${({ theme }) => theme.two[0]};
  box-shadow: 10px 12px 24px 0 rgba(0,0,0,0.15);
  border-radius: 4px;
  border: 1px solid #EDEEEE;
  border-left-width: 3px;
  border-left-color: ${({ theme }) => theme.alert.error};
  display: flex;
  padding: 10px;
  
  & > :first-child, & > :last-child {
    flex: 0 0 12px
  }

  & > :nth-child(2) {
    flex: 1
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  & > * {
    display: block;
  }
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`

const CloseButton = styled.button`
  height: 20px;
  background: none;
  border: none;
  padding: 0 4px;
  cursor: pointer;
  &:hover span {
    color: ${({ theme }) => theme.color[0]};
  }
`

const titleMessages = defineMessages({
  CONNECTION_RETRY: { defaultMessage: 'Connection issues', id: 'NotificationToast.title.CONNECTION_RETRY' },
  CONNECTION_TIMEOUT: { defaultMessage: 'Connection lost', id: 'NotificationToast.title.CONNECTION_TIMEOUT' },
  UNEXPECTED_ERROR: { defaultMessage: 'Something went wrong', id: 'NotificationToast.title.UNEXPECTED_ERROR' }
})
const bodyMessages = defineMessages({
  CONNECTION_RETRY: { defaultMessage: 'Some of your changes may not be saved. Please check your connection. Retrying shortly.', id: 'NotificationToast.body.CONNECTION_RETRY' },
  CONNECTION_TIMEOUT: { defaultMessage: 'Failed to reconnect. Your unsaved changes may be lost.', id: 'NotificationToast.body.CONNECTION_TIMEOUT' },
  UNEXPECTED_ERROR: { defaultMessage: 'We have made a note of the issue and will look into it. Please restart and try again. ', id: 'NotificationToast.body.UNEXPECTED_ERROR' }
})
const actionMessages = defineMessages({
  CONNECTION_TIMEOUT: { defaultMessage: 'Restart', id: 'NotificationToast.action.CONNECTION_TIMEOUT' },
  UNEXPECTED_ERROR: { defaultMessage: 'Restart', id: 'NotificationToast.action.UNEXPECTED_ERROR' }
})


const ActionButton = ({ notification, intl, onAction }) => (
  <Button size="small" outline onClick={() => onAction(notification.id)}>
    {intl.formatMessage(actionMessages[notification.type])}
  </Button>
)

const NotificationToast = props => {
  const { notification, onClose, intl } = props
  const hasAction = actionMessages.hasOwnProperty(notification.type)
  return (
    <ComponentRoot>
      <Icon variant="exclamation-circle" error />
      <Body>
        <Typography variant="medium" weight="bold">{intl.formatMessage(titleMessages[notification.type])}</Typography>
        <Typography variant="medium" weight="light">{intl.formatMessage(bodyMessages[notification.type])}</Typography>
      </Body>
      <RightColumn>
        <CloseButton onClick={() => onClose(notification.id)}>
          <Icon variant="times" />
        </CloseButton>
        {hasAction ?  <ActionButton {...props} /> : null}
      </RightColumn>
    </ComponentRoot>
  )
}

NotificationToast.propTypes = {
  onClose: PropTypes.func,
  onAction: PropTypes.func
}

NotificationToast.defaultProps = {
  onClose: () => {},
  onAction: () => {}
}

export default injectIntl(NotificationToast)
