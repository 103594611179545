import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import Typography from '../../common/Typography/Typography'

import logoSvg from '../../../static/img/logo-full.svg'


const ComponentRoot = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: ${props => props.theme.background1};
  & > :not(:first-child) { margin-left: 30px; }
  & > :first-child { margin-right: auto; }
  & > ${Typography}:hover {
    color: ${props => props.theme.main.secondary};
  }
  & > a > img { height: 26px; }
  & > * {
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
  }
`

const MenuWrapper = styled.div`
  position: relative;
`

const MenuContent = styled.div`
  display: flex;
  align-items: center;
  & > img { height: 17px; }
  & > svg {
    fill: ${({ theme }) => theme.default};
    height: 20px;
    width: 12px;
  }
  & > :not(:first-child) { margin-left: 10px; }
  &:hover {
    & > * {
      fill: ${({ theme }) => theme.main.secondary};
      color: ${({ theme }) => theme.main.secondary};
    }
  }
`

const Dropdown = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
  position: absolute;
  top: 2em;
  right: -5px;
  background-color: ${props => props.theme.background1};
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
  padding: 10px 0;
  cursor: auto;
`

const DropdownItem = styled.div`
  padding: 2px 40px;
`

const DropdownItemGroup = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.background3};
  margin-bottom: 10px;
`

const DropDownItemSubTypography = styled(Typography)`
  display: block;
  margin-bottom: 4px;
`

const DropdownItemWithAction = styled(DropdownItem)`
  cursor: pointer;
  &:hover { background-color: ${props => props.theme.background3}; }
`

class ApplicationBar extends Component {
  state = { showDropdown: false }

  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickAnywhere)
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickAnywhere)
  }

  handleClickAnywhere = (event) => {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      // close dropdown if clicked outside
      this.setState({ showDropdown: false })
    } else {
      // toggle if inside
      this.setState({ showDropdown: !this.state.showDropdown })
    }
  }

  renderOrganizations() {
    const { organizations, onOrganizationClick, onOrganizationSettingsClick } = this.props
    return organizations.map(organization => (
      <DropdownItemGroup key={organization.organizationId}>
        <DropdownItemWithAction
          onClick={() => onOrganizationClick(organization.organizationId)}
        >
          <Typography variant="medium" weight="bold">{organization.name}</Typography>
        </DropdownItemWithAction>
        <DropdownItemWithAction
          onClick={() => onOrganizationSettingsClick(organization.organizationId)}
        >
          <Typography variant="small">Settings</Typography>
        </DropdownItemWithAction>
      </DropdownItemGroup>
    ))
  }

  renderAngle() {
    const { showDropdown } = this.state
    if (showDropdown) {
      return <path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" />
    }
    return <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
  }

  renderDropdown() {
    const { user, organizations, onUserSettingsClick, onLogoutClick, onTermsClick } = this.props
    const { showDropdown } = this.state
    return (
      <MenuWrapper ref={x => this.dropdown = x}>
        <MenuContent>
          <Typography variant="small">{user.fullName}</Typography>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            {this.renderAngle()}
          </svg>
        </MenuContent>
        <Dropdown visible={showDropdown}>
          {organizations && this.renderOrganizations()}
          <DropdownItem>
            <Typography variant="medium" weight="bold">{user.fullName}</Typography>
            <DropDownItemSubTypography variant="tiny">{user.email}</DropDownItemSubTypography>
          </DropdownItem>
          <DropdownItemWithAction onClick={onUserSettingsClick}>
            <Typography variant="small">
              <FormattedMessage id="owner.ApplicationBar.userSettings" defaultMessage="User settings" />
            </Typography>
          </DropdownItemWithAction>
          <DropdownItemWithAction onClick={onTermsClick}>
            <Typography variant="small">
              <FormattedMessage id="owner.ApplicationBar.terms" defaultMessage="Terms of service" />
            </Typography>
          </DropdownItemWithAction>
          <DropdownItemWithAction onClick={onLogoutClick}>
            <Typography variant="small">
              <FormattedMessage id="owner.ApplicationBar.logout" defaultMessage="Sign out" />
            </Typography>
          </DropdownItemWithAction>
        </Dropdown>
      </MenuWrapper>
    )
  }

  render() {
    const { user, homeUrl } = this.props
    return (
      <ComponentRoot>
        <Link to={homeUrl}>
          <img src={logoSvg} alt="Qvest logo" />
        </Link>
        {user && this.renderDropdown()}
      </ComponentRoot>
    )
  }
}

ApplicationBar.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
  }),
  homeUrl: PropTypes.string,
  onOrganizationClick: PropTypes.func,
  onOrganizationSettingsClick: PropTypes.func,
  onTermsClick: PropTypes.func,
  onUserSettingsClick: PropTypes.func,
  onLogoutClick: PropTypes.func,
}

export default ApplicationBar
