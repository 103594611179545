import React, { Component } from 'react'
import { IntlProvider, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { loadLocale } from '../../../utils/i18nUtils'
import DimmerLoader from '../../../components/common/DimmerLoader/DimmerLoader'
import Typography from '../../../components/common/Typography/Typography'
import Input from '../../../components/common/Input/Input'
import EditableSpan from '../../../components/common/EditableSpan/EditableSpan'
import PreviewTopBar from '../../../components/owner/PreviewTopBar/PreviewTopBar'
import InviteEmail from '../../../components/common/Email/InviteEmail'
import { getQvest, updateQvest, changeUnsavedQvest, getQvestVideo } from '../../../actions/qvestActions'
import { getQvestSchedule } from '../../../actions/scheduleActions'
import { filterVideoByQvestId, hasUnsavedQvestState } from '../../../reducers/qvests'
import { filterSchedulesByQvestsId } from '../../../reducers/schedules'


const ComponentRoot = styled.div`
  padding-top: 80px;
`

const EmailMetaHeaderWrapper = styled.div`
  width: 100%;
`
const EmailMetaHeader = styled.div`
  background-color: ${({theme}) => theme.two[0]};
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.04);
  border-radius: 0 0 4px 4px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;

  & > *:not(:last-child) {
    margin-bottom: 14px;
  }
`
const InlineBlock = styled(Typography)`
  display: inline-block;
`

class OwnerPreviewInviteEmail extends Component {
  state = {
    isEditing: null
  }

  componentDidMount() {
    const { match, dispatch, qvestStore } = this.props
    const qvestId = match.params.qvestId

    if (!hasUnsavedQvestState(qvestStore, qvestId)) {
      dispatch(getQvestSchedule(qvestId))
      dispatch(getQvest(qvestId))
        .then(qvest => dispatch({ type: 'INIT_UNSAVED_QVEST', qvest }))
        .then(() => dispatch(getQvestVideo(qvestId)))
    }
  }

  renderEditableSenderName(contactInfo) {
    const placeholder = '[Sender name]'
    const viewValue = (contactInfo && contactInfo.senderName) || placeholder
    const editValue = (contactInfo && contactInfo.senderName) || ''
    return (
      <EditableSpan
        isEditing={this.state.isEditing === 'senderName'}
        onStartEdit={() => this.onEditing('senderName')}
        onDone={() => this.onSave()}
      >
        <EditableSpan.View>
          <Typography weight="light">{viewValue}</Typography>
        </EditableSpan.View>
        <EditableSpan.Edit>
          <Input
            autoFocus
            type="text"
            value={editValue}
            placeholder={placeholder}
            onChange={({ target }) => this.onChange({ contactInfo: { senderName: target.value } })}
          />
        </EditableSpan.Edit>
      </EditableSpan>
    )
  }

  render() {
    const { qvestStore, scheduleStore, match } = this.props

    const qvestId = match.params.qvestId
    const schedule = filterSchedulesByQvestsId(scheduleStore, qvestId)
    const video = filterVideoByQvestId(qvestStore, qvestId)

    if (!qvestStore.unsaved.qvestId) {
      return <DimmerLoader />
    }

    const qvest = qvestStore.unsaved
    const locale = qvest.language
    const messages = loadLocale(locale)

    return (
      <IntlProvider locale={locale} messages={messages}>
        <ComponentRoot>
          <PreviewTopBar onSave={this.onSave} onBack={this.onBack} lastUpdate={qvestStore.lastUpdate}/>
          <div>
            <EmailMetaHeaderWrapper>
              <EmailMetaHeader>
                <div>
                  <Typography weight="bold">From:</Typography>
                  &nbsp;
                  <InlineBlock>
                    {this.renderEditableSenderName(qvest.contactInfo)}
                  </InlineBlock>
                  &nbsp;
                  <Typography weight="light">(noreply@qvest.io)</Typography>
                </div>
                <div>
                  <Typography weight="bold">Subject:</Typography>
                  &nbsp;
                  <Typography weight="light">
                    <FormattedMessage
                      id="owner.previewInviteEmail.subject"
                      defaultMessage="Welcome to our Qvest"
                    />
                  </Typography>
                </div>
              </EmailMetaHeader>
            </EmailMetaHeaderWrapper>
            <InviteEmail
              organizationName={qvest.organizationName}
              logo={qvest.organizationLogo}
              topic={qvest.topic}
              recipientName="Michael Hansen"
              inviteTextSource={qvest.inviteTextSource}
              contactInfo={qvest.contactInfo}
              schedule={schedule}
              videoUrl={qvest.videoUrl}
              video={video}
              url={`/owner/preview/qvest/${qvestId}`}
              participantCount={qvest.summary.participants}
              onScheduleEdit={this.handleScheduleEdit}
              onTopicEdit={this.onBack}
              isEditing={this.state.isEditing}
              onEditing={this.onEditing}
              onChange={this.onChange}
              onSave={this.onSave}
            />
          </div>
        </ComponentRoot>
      </IntlProvider>
    )
  }

  onEditing = (field) => {
    this.setState({ isEditing: field })
  }

  onChange = (patch) => {
    const { dispatch } = this.props
    dispatch(changeUnsavedQvest(patch))
  }

  onSave = () => {
    const { dispatch, match, qvestStore } = this.props
    const { contactInfo } = qvestStore.unsaved
    const patch = {}
    if (contactInfo && contactInfo.senderName) {
      patch.senderName = contactInfo.senderName
    }
    if (contactInfo && contactInfo.senderTitle) {
      patch.senderTitle = contactInfo.senderTitle
    }
    dispatch(updateQvest(match.params.qvestId, patch))
    this.onEditing(null)
  }

  onBack = () => {
    const { match, history } = this.props
    const qvestId = match.params.qvestId
    history.push(`/owner/qvest/${qvestId}/topic`)
  }

  handleScheduleEdit = () => {
    const { match, history } = this.props
    const qvestId = match.params.qvestId
    history.push(`/owner/qvest/${qvestId}/schedule`)
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    groupingStore: state.grouping.toJS(),
    scheduleStore: state.schedules.toJS()
  }
}

export default connect(mapStateToProps)(OwnerPreviewInviteEmail)
