import groupBy from 'lodash/groupBy'

export function countParticipantsInGroups(groups, participants) {
  const participantsWithGroups = participants.filter(p => p.groupingConnections && p.groupingConnections.length > 0)
  const participantsByGroup = groupBy(participantsWithGroups, p => p.groupingConnections[0].groupId)
  const groupCounts = {}
  groups.forEach(({ groupId }) => {
    if (participantsByGroup.hasOwnProperty(groupId)) {
      groupCounts[groupId] = participantsByGroup[groupId].length
    } else {
      groupCounts[groupId] = 0
    }
  })
  return groupCounts
}
