import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import GenericModal from '../../common/GenericModal/GenericModal'


const ManageUserModal = ({ onInvite, onRemove, onCancel, name, organizationName, isRemove }) => {
  const title = isRemove
    ? <FormattedMessage id="owner.ManageUserModal.titleRemove" defaultMessage="Remove user" />
    : <FormattedMessage id="owner.ManageUserModal.titleAdd" defaultMessage="Add user" />

  const values = {
    name: <strong>{name}</strong>,
    organizationName: <strong>{organizationName}</strong>,
  }

  const message = isRemove
    ? (
      <FormattedMessage
        id="owner.ManageUserModal.messageRemove"
        defaultMessage="Are you sure you want to remove {name} from {organizationName}?"
        values={values}
      />
    )
    : (
      <FormattedMessage
        id="owner.ManageUserModal.messageAdd"
        defaultMessage="Are you sure you want to send an invitation to {name}?"
        values={values}
      />
    )

  const onSubmit = (isRemove ? onRemove : onInvite)

  return (
    <GenericModal yes no title={title} onSubmit={onSubmit} onCancel={onCancel}>
      <Typography weight="light">{message}</Typography>
    </GenericModal>
  )
}

ManageUserModal.propTypes = {
  onInvite: PropTypes.func,
  onRemove: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  organizationName: PropTypes.string,
  isRemove: PropTypes.bool,
}

ManageUserModal.defaultProps = {
  onInvite: () => { },
  onRemove: () => { },
  isRemove: false,
}

export default ManageUserModal
