import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Typography from '../../common/Typography/Typography'
import { hexaToRgba } from '../../../utils/styleUtils'
import qnaExchange from '../../../static/img/qna-exchange.svg'


const LineWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  & > *:first-child {
    margin-left: -80px;
    padding-left: 60px;
  }
`

const Line = styled.div`
  background-color: ${({ theme, active, disabled }) => active
    ? hexaToRgba(theme.color[0] + '30')
    : (disabled ? 'transparent' : hexaToRgba(theme.two[1] + '60'))};
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: ${({ disabled }) => disabled ? '1px dashed #ddd' : 'none'};
`

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: ${({ disabled }) => disabled ? '1px dashed #ddd' : '2px solid white'};
  box-shadow: ${({ disabled }) => disabled ? 'none' : '2px 2px 8px 0 #ddd'};
`

const TimelineItem = (props) => {
  const { description, placeholder, disabled, active, img } = props
  return (
    <div>
      <LineWrapper>
        <Line active={active} disabled={disabled}>
          <Typography secondary={!description} variant="caption">
            {description || placeholder}
          </Typography>
        </Line>
        <Image disabled={disabled} src={img} alt="" />
      </LineWrapper>
    </div>
  )
}

TimelineItem.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  img: PropTypes.string,
}

TimelineItem.defaultProps = {
  disabled: false,
  active: false,
  img: qnaExchange,
}

export default TimelineItem
