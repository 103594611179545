import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { hexaToRgba } from '../../../utils/styleUtils'

import Icon from '../Icon/Icon'
import Button from '../Button/Button'


const EditIconWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -25px;
  cursor: pointer;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

const HoverArea = styled.div`
  position:absolute;
  top: -6px;
  right: -20px;
  width: 20px;
  height: calc(100% + 6px);
`

const HoverBackground = styled.div`
  position:absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 40px);
  height: calc(100% + 20px);
  background-color: ${({ theme }) => hexaToRgba(theme.main.primary + '19')};
`

const EditRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ fullWidth }) => fullWidth ? 'wrap' : 'nowrap'};
  align-items: center;
  padding: 5px 0;
  width: 100%;

  & > *:not(:first-child) {
    margin-top: ${({ fullWidth }) => fullWidth ? '8px' : '0'}
  }

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

const EditChildren = styled.div`
  display: inline;
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
`

const ViewWrapper = styled.div`
  & ${EditIconWrapper} {
    display: none;
  }

  & ${HoverBackground} {
    display: none;
  }

  &:hover ${EditIconWrapper} {
    display: block
  }

  &:hover ${HoverBackground} {
    display: block
  }
`

const ComponentRoot = styled.div`
  position: relative;
  cursor: pointer;
  width: ${({ fluid }) => fluid ? '100%' : 'auto'};
`

const VerticalSpace = styled.div`
  height: 100%;
  width: 35px;
`

const IconButton = styled(Button)`
  && > * {
    cursor: pointer;
  }
`


class View extends Component {
  onRemoveClick = (event) => {
    event.stopPropagation()
    const { onRemove } = this.props
    if (onRemove) {
      onRemove()
    }
  }

  onCollapseClick = (event) => {
    event.stopPropagation()
    const { onCollapse } = this.props
    if (onCollapse) {
      onCollapse()
    }
  }

  onClickInside = () => {
    const { onStartEdit } = this.props
    if (onStartEdit) {
      onStartEdit()
    }
  }

  render() {
    const { onRemove, onCollapse, showEditIcon } = this.props

    let deleteIcon = null
    if (onRemove) {
      deleteIcon = (
        <Icon
          clickable={true}
          variant="trash"
          title="Remove"
          onClick={this.onRemoveClick}
        />
      )
    }

    let collapseIcon = null
    if (onCollapse) {
      collapseIcon = (
        <Icon
          clickable={true}
          variant="chevron-up"
          title="Collapse"
          onClick={this.onCollapseClick}
        />
      )
    }

    return (
      <ViewWrapper onClick={this.onClickInside}>
        <HoverBackground />
        <HoverArea />
        {this.props.children}
        <EditIconWrapper>
          {collapseIcon}
          {showEditIcon ? <Icon clickable={true} variant="edit" title="Edit" /> : null}
          {deleteIcon}
        </EditIconWrapper>
      </ViewWrapper>
    )
  }
}

View.propTypes = {
  showEditIcon : PropTypes.bool,
  onStartEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onCollapse: PropTypes.func
}

View.defaultProps = {
  showEditIcon: true
}


class Edit extends Component {
  componentDidMount() {
    if (this.props.clickOutsideToCancel) {
      document.addEventListener('mouseup', this.onClickOutside)
      this.rootElement.addEventListener('mouseup', this.onClickInside)
    }
  }

  componentWillUnmount() {
    if (this.props.clickOutsideToCancel) {
      document.removeEventListener('mouseup', this.onClickOutside)
      this.rootElement.removeEventListener('mouseup', this.onClickInside)
    }
  }

  onDoneClick = () => {
    const { onDone } = this.props
    if (onDone) {
      onDone()
    }
  }

  onCancelClick = () => {
    const { onCancel } = this.props
    if (onCancel) {
      onCancel()
    }
  }

  onClickOutside = () => {
    const { onCancel, onDone } = this.props
    if (onCancel) {
      onCancel()
    } else if (onDone) {
      onDone()
    }
  }

  onClickInside = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  onKeyDown = ({ key }) => {
    const { hasDoneKey, onCancel } = this.props
    if (hasDoneKey && key === 'Enter') {
      this.onDoneClick()
    } else if (key === 'Escape') {
      if (onCancel) {
        this.onCancelClick()
      } else {
        this.onDoneClick()
      }
    }
  }

  render() {
    const { children, fullWidth, onCancel, disabled } = this.props
    return (
      <EditRow onKeyDown={this.onKeyDown} fullWidth={fullWidth} ref={elm => this.rootElement = elm}>
        <EditChildren fullWidth={fullWidth}>
          {children}
        </EditChildren>
        <IconButton size="small" title="Done" onClick={this.onDoneClick} disabled={disabled}>
          <VerticalSpace />
          <Icon tertiary variant="check" />
          <VerticalSpace />
        </IconButton>
        {onCancel ? <Icon clickable={true} title="Cancel" variant="times" onClick={this.onCancelClick} /> : null}
      </EditRow>
    )
  }
}

Edit.propTypes = {
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
  hasDoneKey: PropTypes.bool,
  clickOutsideToCancel: PropTypes.bool,
  onDone: PropTypes.func,
  onCancel: PropTypes.func
}

Edit.defaultProps = {
  disabled: false,
  hasDoneKey: true,
  clickOutsideToCancel: true
}


class EditableSpan extends Component {
  render() {
    const { children, fluid } = this.props
    return (
      <ComponentRoot fluid={fluid}>
        {children}
      </ComponentRoot>
    )
  }
}

EditableSpan.View = View
EditableSpan.Edit = Edit

EditableSpan.propTypes = {
  fluid: PropTypes.bool
}

EditableSpan.defaultProps = {
  fluid: false
}

export default EditableSpan
