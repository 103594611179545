import React, { Component } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import Typography from '../../../../components/common/Typography/Typography'
import Heading from '../../../../components/common/Typography/Heading'
import StillOpenMessage from '../../../../components/owner/NoDataMessage/NoDataMessage'
import ResultsPage from '../../../participant/results/ResultsPage'
import { isDoneQvestState, filterQvestsById } from '../../../../reducers/qvests'


const messages = defineMessages({
  title: {
    id: 'owner.OwnerQvestParticipantOutputTab.title',
    defaultMessage: 'Participant output | {qvestName}',
  },
})

const ComponentRoot = styled.div`
  padding: 0 30px;
`

const Backdrop = styled.div`
  background-color: #2a2d2b;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
`

const Centered = styled.div`
  max-width: 1340px;
  margin: auto;
`

const Content = styled.div`
  position: relative;
  top: 0;
  left: 0;
`

const Title = styled.div`
  padding: 30px 0;
  text-align: center;
`

class OwnerQvestParticipantOutputTab extends Component {
  handleNavigateClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics`)
  }

  renderStillOpen() {
    const buttonContent = (<FormattedMessage defaultMessage="Go to Analytics" id="owner.StillOpenMessage.button" />)
    return (
      <StillOpenMessage inverted buttonContent={buttonContent} onClick={this.handleNavigateClick}>
        <Typography inverted weight="bold">
          <FormattedMessage
            id="owner.StillOpenMessage.content"
            defaultMessage="You can access the participant output page here when the Qvest closes"
          />
        </Typography>
      </StillOpenMessage>
    )
  }

  renderResults() {
    return (
      <ResultsPage match={this.props.match} isOwner={true} />
    )
  }

  render() {
    const { qvestStore, match, intl } = this.props
    const qvest = filterQvestsById(qvestStore, match.params.qvestId)
    const isDone = isDoneQvestState(qvestStore, match.params.qvestId)
    return (
      <ComponentRoot>
        <Helmet>
          <title>{intl.formatMessage(messages.title, { qvestName: qvest && qvest.name })}</title>
        </Helmet>
        <Backdrop />
        {/*<TitleBar title="Participant Output" />*/}
        <Centered>
          <Title>
            <Heading.h3 inverted>Participant Output</Heading.h3>
          </Title>
          <Content>
            {isDone ? this.renderResults() : this.renderStillOpen()}
          </Content>
        </Centered>
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    subscriptionStore: state.subscription.toJS(),
    groupingStore: state.grouping.toJS(),
    participationStore: state.stats.get('participation').toJS(),
    participantsActivityStore: state.stats.get('participantsActivity').toJS(),
    groupGraphStore: state.stats.get('groupGraph').toJS(),
    wordFrequenciesStore: state.stats.get('wordFrequencies').toJS(),
    questionDistributionStore: state.stats.get('questionDistribution').toJS(),
    themeStore: state.themes.toJS(),
  }
}

export default connect(mapStateToProps)(injectIntl(OwnerQvestParticipantOutputTab))
