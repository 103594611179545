import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import ContainerDimensions from 'react-container-dimensions'
import * as d3 from 'd3'
import { GroupsNetworkGraph, QuestionThemesRadialChart, RadialChart, themesMapping } from 'data-viz'

import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Button from '../../common/Button/Button'
import StillOpenMessage from '../NoDataMessage/NoDataMessage'
import { formatData } from '../AnalyticsNetwork/AnalyticsNetwork'
import { makeThemeColorScales } from '../../../utils/d3Utils'
import Disclaimer from '../Disclaimer/Disclaimer'

const ComponentRoot = styled.div`
  height: calc(100vh - ${({ yOffset }) => yOffset}px);
  min-width: 1100px;
  max-width: 1400px;
`

const Backdrop = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.inverted.two[0]};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
`

const TitleBar = styled.div`
  display: flex;
  & > * + * { margin-left: 20px; }
  & > *:nth-child(2) { margin-left: auto; }
`

const Layout = styled.div`
  height: 100%;
  min-height: 900px;
  display: flex;
`

const LeftColumn = styled.div`
  width: 373px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.one[1]};
  & > * {
    flex: 1;
  }
  & > :first-child {
    border-bottom: 1px solid ${({ theme }) => theme.one[1]};
  }
`

const SmallBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const CenteredChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  & > :nth-child(2) {
    margin-top: 20px;
  }
`

const RightColumn = styled.div`
  width: calc(100% - 373px);
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 30px;
  & > ${CenteredChart} {
    justify-content: flex-start;
  }
`

const FixedBottomContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  & > *:first-child { max-width: 684px; }
  & > *:last-child {
    max-width: 300px;
    margin-left: 30px;
  }
`

const LegendItem = styled.div`
  position: relative;
  padding-left: 20px;
  &::before {
    content: "";
    width: 12px;
    height: 4px;
    border-radius: 2px;
    background-color: ${({ fill }) => fill};
    position: absolute;
    top: 10px;
    left: 0;
  }
`

const NoDataContent = styled.div`
  & > :not(:last-child) {
    margin-bottom: 20px;
    display: block;
  }
`

const networkMessages = defineMessages({
  HIGH: { id: 'owner.AnalyticsInsights.network.high', defaultMessage: 'High collaboration' },
  GOOD: { id: 'owner.AnalyticsInsights.network.good', defaultMessage: 'Good collaboration' },
  LOW: { id: 'owner.AnalyticsInsights.network.low', defaultMessage: 'Low collaboration' }
})

class AnalyticsInsights extends Component {
  renderLegend(themeColors) {
    const { themes } = this.props

    const items = themes.map(({ themeId, name }) => (
      <LegendItem key={themeId} fill={themeColors(themeId)}>
        <Typography inverted weight="light" variant="small">
          {name}
        </Typography>
      </LegendItem>
    ))

    return (
      <div>{items}</div>
    )
  }

  renderPreviewNetwork() {
    const { intl, theme, groupGraph, onNetworkClick } = this.props
    const { type } = formatData(groupGraph.nodes, groupGraph.edges, () => '', theme)
    return (
      <Fragment>
        <TitleBar>
          <div>
            <Heading.h3 inverted>
              <FormattedMessage id="owner.AnalyticsInsights.networkHeading" defaultMessage="Network" />
            </Heading.h3>
            <Typography secondary inverted variant="medium">
              {intl.formatMessage(networkMessages[type])}
            </Typography>
          </div>
        </TitleBar>
        <CenteredChart>
          <GroupsNetworkGraph
            inverted
            tertiary
            size="small"
            showGroupNames={false}
            showNodeTooltips={false}
            showEdgeTooltips={false}
            data={groupGraph}
            linkForceDistance={180}
            nodeCollideRadius={50}
            width={240}
            height={240}
          />
          <Button inverted outline onClick={onNetworkClick}>
            <FormattedMessage id="owner.AnalyticsInsights.networkButton" defaultMessage="Clickable Network" />
          </Button>
        </CenteredChart>
      </Fragment>
    )
  }

  renderThemesChart(themeColors, sortedQuestionCount) {
    const { theme, themes, onThemesClick, questionCount } = this.props
    const isFullySorted = (sortedQuestionCount === questionCount)
    const themeCount = themes.length

    let chart
    if (isFullySorted) {
      const sortedThemes = themesMapping.sortForOptimalDisplay(themes)
      chart = (
        <QuestionThemesRadialChart
          height={200}
          width={300}
          radius={80}
          themes={sortedThemes}
          colors={themeColors}
        />
      )
    } else {
      const colors = [theme.inverted.two[1]]
      let data = [{ label: '', value: 1 }]
      chart = (
        <RadialChart
          noStroke
          height={200}
          width={330}
          radius={80}
          data={data.concat(data).concat(data)}
          colors={colors}
        />
      )
    }
    return (
      <Fragment>
        <TitleBar>
          <div>
            <Heading.h3 inverted>
              <FormattedMessage id="owner.AnalyticsInsights.themesHeading" defaultMessage="Themes" />
            </Heading.h3>
            <Typography secondary inverted variant="medium">
              <FormattedMessage
                id="owner.AnalyticsInsights.themesDescription"
                defaultMessage="{questionCount} {questionCount, plural, one {question} other {questions}} sorted into {themeCount} {themeCount, plural, one {theme} other {themes}}"
                values={{ questionCount: sortedQuestionCount, themeCount }}
              />
            </Typography>
          </div>
        </TitleBar>
        <CenteredChart>
          {chart}
          <Button inverted outline onClick={onThemesClick}>
            <FormattedMessage id="owner.AnalyticsInsights.themesButton" defaultMessage="Edit themes" />
          </Button>
        </CenteredChart>
      </Fragment>
    )
  }

  renderNoData() {
    const buttonContent = (<FormattedMessage defaultMessage="Edit themes" id="owner.AnalyticsInsights.noDataButton" />)
    return (
      <StillOpenMessage inverted icon="diamond" buttonContent={buttonContent} onClick={this.props.onThemesClick}>
        <NoDataContent>
          <Typography inverted weight="bold" paragraph>
            <FormattedMessage
              id="owner.AnalyticsInsights.noDataDescription1"
              defaultMessage="The Project Map combines the quantitative data in your Qvest network with the qualitative question-data in Themes."
            />
          </Typography>
          <Typography inverted weight="bold" paragraph>
            <FormattedMessage
              id="owner.AnalyticsInsights.noDataDescription2"
              defaultMessage="You can see the Project Map when you have sorted all the questions in the Theme builder."
            />
          </Typography>
        </NoDataContent>
      </StillOpenMessage>
    )
  }

  renderMainNetwork(themeColors, sortedQuestionCount) {
    const { groupGraph, themes, questionCount } = this.props
    const isFullySorted = (sortedQuestionCount === questionCount)

    let content, legend
    if (!themes || (themes.length === 0) || !isFullySorted) {
      content = this.renderNoData()
    } else {
      content = (
        <ContainerDimensions>
          {({ width, height }) => (
            <GroupsNetworkGraph
              key={groupGraph.hash}
              inverted
              tertiary
              showGroupNames
              showNodeTooltips={false}
              showEdgeTooltips={true}
              themes={themes}
              data={groupGraph}
              edgeColors={themeColors}
              linkForceDistance={300}
              nodeCollideRadius={Math.min(width, height) > 750 ? 120 : 60}
              width={width}
              height={800}
            />
          )}
        </ContainerDimensions>
      )
      legend = this.renderLegend(themeColors)
    }

    return (
      <Fragment>
        <TitleBar>
          <div>
            <Heading.h3 inverted>
              <FormattedMessage id="owner.AnalyticsInsights.mainHeading" defaultMessage="Project Map" />
            </Heading.h3>
            <Typography secondary inverted variant="medium">
              <FormattedMessage id="owner.AnalyticsInsights.mainDescription" defaultMessage="Network enriched with Themes" />
            </Typography>
          </div>
        </TitleBar>
        <CenteredChart>
          {content}
        </CenteredChart>
        <FixedBottomContainer>
          <Disclaimer inverted />
          {legend}
        </FixedBottomContainer>
      </Fragment>
    )
  }

  render() {
    const { groupGraph, theme, themes, yOffset } = this.props

    if (!groupGraph || !themes) return null

    const sortedQuestionCount = d3.sum(themes.map(t => t.questionIds.length))
    const themeColors = makeThemeColorScales(theme, themes)

    return (
      <ComponentRoot yOffset={yOffset}>
        <Backdrop />
        <Layout>
          <LeftColumn>
            <SmallBox>
              {this.renderPreviewNetwork()}
            </SmallBox>
            <SmallBox>
              {this.renderThemesChart(themeColors, sortedQuestionCount)}
            </SmallBox>
          </LeftColumn>
          <RightColumn>
            {this.renderMainNetwork(themeColors, sortedQuestionCount)}
          </RightColumn>
        </Layout>
      </ComponentRoot>
    )
  }

  static propTypes = {
    themes: PropTypes.arrayOf(PropTypes.shape({
      themeId: PropTypes.string,
      name: PropTypes.name,
    })),
    groupGraph: PropTypes.shape({
      nodes: PropTypes.array,
      edges: PropTypes.array,
    }),
    questionCount: PropTypes.number,
    yOffset: PropTypes.number,
    onNetworkClick: PropTypes.func,
    onThemesClick: PropTypes.func,
    onDownload: PropTypes.func,
  }

  static defaultProps = {
    questionCount: 0,
    yOffset: 0,
    onNetworkClick: () => { },
    onThemesClick: () => { },
    onDownload: () => { },
  }
}

export default injectIntl(withTheme(AnalyticsInsights))
