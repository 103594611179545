import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Typography from '../Typography/Typography'


const List = styled.ul`
  padding: 0;
  margin: 4px 0 0 0;
  list-style-type: none;
  ${({ horizontal }) => horizontal && `
    display: flex;
    flex-wrap: wrap;
    & > * { margin-right: 10px; }
  `}
`

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  flex-wrap: nowrap;
  width: ${({ horizontal }) => horizontal ? 'auto' : '100%'};

  & > * {
    margin-right: 8px;
  }
  & > :nth-child(2) {
    flex: 1 0;
    min-width: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

const ColorPreviewCircle = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  width: 8px;
  height: 8px;
`

const DEFAULT_MAX_ITEMS = 6

const GroupLegend = ({ groups, colors, horizontal, max, size }) => {
  let truncateElement
  const maxItems = max || DEFAULT_MAX_ITEMS
  if (groups.length > maxItems) {
    const hiddenCount = groups.length - maxItems
    truncateElement = (
      <ListItem key="-1">
        <Typography variant={size} weight="light">
          <FormattedMessage
            id="GroupLegend.truncateText"
            defaultMessage="... and {count} more {count, plural, one {group} other {groups} }"
            values={{ count: hiddenCount }}
          />
        </Typography>
      </ListItem>
    )
  }
  const displayedGroups = horizontal ? groups : groups.slice(0, maxItems)
  return (
    <div>
      <List horizontal={horizontal}>
        {displayedGroups.map((group, i) => (
          <ListItem key={i} horizontal={horizontal}>
            <ColorPreviewCircle color={colors(group.groupId)} />
            <Typography variant={size} weight="light">{group.name}</Typography>
          </ListItem>
        ))}
        {!horizontal && truncateElement}
      </List>
    </div>
  )
}

GroupLegend.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  colors: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  max: PropTypes.number,
  size: PropTypes.oneOf([
    'small',
    'medium',
    'regular'
  ])
}

GroupLegend.defaultProps = {
  horizontal: false,
  size: 'regular'
}

export default GroupLegend
