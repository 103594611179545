
// Compute circle radius such that relative values are reflected in relative circle areas
export function areaRelativeRadiusScale(minNodeValue, maxNodeValue, minRadius = 3, maxRadius = 30) {
  return (value) => {
    let radius = minRadius
    const maxArea = Math.pow(maxRadius, 2) * Math.PI
    if (value && value > 0) {
      const factor = value / maxNodeValue
      // Based on area
      const area = maxArea * factor
      radius = Math.sqrt(area / Math.PI)
    }
    return radius
  }
}
