import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Card from '../../common/Card/Card'
import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'
import Icon from '../../common/Icon/Icon'
import Button from '../../common/Button/Button'
import Dropdown from '../../common/Dropdown/Dropdown'


const CardWrapper = styled.div`
  width: 423px;
  ${Card.Body} > *:last-child { margin-top: 20px; }
`

// TODO: Make this a variant of Card.Body?
const SpaciousCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 40px;
  text-align: center;
  & > *:not(:last-child) {
    margin-bottom: 25px
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 6px;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`

const LoadingCard = () => (
  <CardWrapper>
    <Card>
      <SpaciousCardBody>
        <CenteredLoader size="large" />
        <Heading.h2>
          <FormattedMessage id="owner.ReportModal.loading.heading" defaultMessage="Generating report" />
        </Heading.h2>
        <Typography variant="medium" weight="light">
          <FormattedMessage
            id="owner.ReportModal.loading.description"
            defaultMessage="Your download options will be shown when your report is ready. This may take up to a minute."
          />
        </Typography>
      </SpaciousCardBody>
    </Card>
  </CardWrapper>
)
const ErrorCard = () => (
  <CardWrapper>
    <Card>
      <SpaciousCardBody>
        <Icon variant="exclamation-circle" size="large" />
        <Heading.h2>
          <FormattedMessage id="owner.ReportModal.error.heading" defaultMessage="Failed to generate" />
        </Heading.h2>
        <Typography variant="medium" weight="light">
          <FormattedMessage
            id="owner.ReportModal.error.description"
            defaultMessage="An error occurred while generating your Qvest report. Please try again or contact us, if the issue persists."
          />
        </Typography>
      </SpaciousCardBody>
    </Card>
  </CardWrapper>
)

const FORMAT_TEXT = {
  pptx: 'Microsoft PowerPoint (.pptx)',
  pdf: 'Portable Document Format (.pdf)',
  odp: 'Open Document Format (.odp)'
}

const findUrlByFormat = (options, format) => options.find(o => o.format === format).url

const DownloadCard = ({ options, onCancel, onGenerate }) => {
  const [ format, setFormat ] = useState(options[0].format)
  const dropDownOptions = options.map(option => ({
    value: option.format,
    text: FORMAT_TEXT[option.format],
  }))
  return (
    <CardWrapper>
      <Card>
        <Card.Header>
          <Heading.h2>
            <FormattedMessage id="owner.ReportModal.heading" defaultMessage="Qvest Report" />
          </Heading.h2>
          <Icon clickable variant="times" onClick={onCancel} />
        </Card.Header>
        <Card.Body>
          <Typography weight="light">
            <FormattedMessage
              id="owner.ReportModal.description"
              defaultMessage="Your Qvest Report is ready."
            />
          </Typography>
          <Typography.p weight="light">
            <FormattedMessage
              id="owner.ReportModal.description2"
              defaultMessage="Choose your desired file format and proceed to start downloading the report:"
            />
          </Typography.p>
          <Dropdown
            value={format}
            options={dropDownOptions}
            onChange={selected => { setFormat(selected.value) }}
          />
        </Card.Body>
        <Card.Body>
          <ButtonGroup>
            <Button outline onClick={onGenerate}>
              <FormattedMessage defaultMessage="Generate again" id="owner.ReportModal.button.generate" />
            </Button>
            <Button.a
              href={findUrlByFormat(options, format)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onCancel}
            >
              <FormattedMessage defaultMessage="Download" id="owner.ReportModal.button.submit" />
            </Button.a>
          </ButtonGroup>
        </Card.Body>
      </Card>
    </CardWrapper>
  )
}

const ReportModal = (props) => {
  if (props.options && props.options.length > 0) {
    return <DownloadCard {...props} />
  } else if (props.error) {
    return <ErrorCard />
  } else {
    return <LoadingCard />
  }
}

export default ReportModal
