import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { defineMessages, injectIntl } from 'react-intl'

import Card from '../Card/Card'
import Icon from '../Icon/Icon'
import Typography from '../Typography/Typography'
import Button from '../Button/Button'


function determineWidth({ narrow, wide }) {
  if (narrow) return 480
  else if(wide) return 650
  else return 550
}

const ComponentRoot = styled.div`
  width: 100%;
  max-width: ${props => determineWidth(props)}px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 6px;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`


const buttonText = defineMessages({
  YES: { id: 'GenericModal.button.YES', defaultMessage: 'Yes' },
  OK: { id: 'GenericModal.button.OK', defaultMessage: 'Ok' },
  NO: { id: 'GenericModal.button.NO', defaultMessage: 'No' },
  CONFIRM: { id: 'GenericModal.button.CONFIRM', defaultMessage: 'Confirm' },
  CANCEL: { id: 'GenericModal.button.CANCEL', defaultMessage: 'Cancel' },
  LOADING: { id: 'GenericModal.button.LOADING', defaultMessage: 'Loading...' }
})


class GenericModal extends Component {
  renderMessage() {
    const { message } = this.props
    if (!message) return null
    return (
      <Card.Message error={message.type === 'error'} warning={message.type === 'warning'}>
        {message.content}
      </Card.Message>
    )
  }

  renderSubmit() {
    const { intl, yes, ok, confirm, onSubmit, loading, incomplete } = this.props
    let message

    if (loading) message = buttonText.LOADING
    else if (confirm) message = buttonText.CONFIRM
    else if (yes) message = buttonText.YES
    else if (ok) message = buttonText.OK
    else return null

    return (
      <Button disabled={loading || incomplete} onClick={onSubmit}>
        {intl.formatMessage(message)}
      </Button>
    )
  }

  renderCancel() {
    const { intl, no, ok, cancel, onCancel, loading } = this.props
    let message

    if (ok) return null
    if (cancel) message = buttonText.CANCEL
    else if (no) message = buttonText.NO
    else message = buttonText.CANCEL

    return (
      <Button disabled={loading} outline onClick={onCancel}>
        {intl.formatMessage(message)}
      </Button>
    )
  }

  render() {
    const { children, title, onCancel, narrow, wide } = this.props
    return (
      <ComponentRoot narrow={narrow} wide={wide}>
        <Card role="alertdialog" aria-modal="true" aria-labelledby="dialog-label" aria-describedby="dialog-desc">
          <Card.Header>
            <Typography id="dialog-label" variant="heading3">{title}</Typography>
            <Icon clickable variant="times" onClick={onCancel} />
          </Card.Header>
          {this.renderMessage()}
          <Card.Body id="dialog-desc">{children}</Card.Body>
          <Card.Body>
            <ButtonGroup>
              {this.renderCancel()}
              {this.renderSubmit()}
            </ButtonGroup>
          </Card.Body>
        </Card>
      </ComponentRoot>
    )
  }
}

GenericModal.propTypes = {
  yes: PropTypes.bool,
  ok: PropTypes.bool,
  no: PropTypes.bool,
  confirm: PropTypes.bool,
  cancel: PropTypes.bool,
  wide: PropTypes.bool,
  narrow: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  message: PropTypes.shape({
    type: PropTypes.oneOf(['error', 'warning']),
    content: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string
    ])
  }),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

GenericModal.defaultProps = {
  yes: false,
  ok: false,
  no: false,
  confirm: false,
  cancel: false,
  narrow: false,
  wide: false,
  onSubmit: () => { },
  onCancel: () => { },
}

export default injectIntl(GenericModal)
