import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import styled from 'styled-components'

import {
  useThemeUpdate,
  useThemeCreate,
  useThemeDelete,
  useThemeSetUpdate,
  useThemeSetCreate,
  useThemeSetDelete,
  useThemeSetSetActive
} from '../../../../../operations/themeSet'
import { useAssignTheme, useGetThemeComparison } from '../../../../../operations/question'
import AnalyticsThemes from '../../../../../components/owner/AnalyticsThemes/AnalyticsThemes'
import CenteredLoader from '../../../../../components/common/CenteredLoader/CenteredLoader'
import { questionThemesToExcelSheet2 } from '../../../../../utils/xlsxUtils'
import ThemesSettingsModal from '../../../../../components/owner/ThemesSettingsModal/ThemesSettingsModal'
import Modal from '../../../../../components/common/Modal/Modal'


const Y_OFFSET = 130 // Fixed nav bar + tabbed bar height
const THEMES_PAGE_QUERY = gql`
  query ($qvestId: String!, $questionFilter:QuestionSearchFilter!) {
    questions(filter:$questionFilter) {
      items {
        questionId
        createdAt
        questionContent
        questionSentAt
        questionGroupId
        answerContent
        answerSentAt
        answerGroupId
        isFirstQuestion
        pins {
          count
        }
        themes {
          assignments {
            position
            theme {
              themeId
              name
              themeSet {
                themeSetId
                label
              }
            }
            themeSet {
              themeSetId
              label
            }
          }
        }
      }
    }
    qvest(id: $qvestId) {
      qvestId
      name
      themeSets {
        items {
          themeSetId
          name
          alignmentRating
          label
          themes {
            name
            themeId
            createdAt
            themeSet {
              themeSetId
            }
          }
        }
      }
    }
    user {
      userId
    }
  }
`

const ComponentRoot = styled.div`
  min-width: 100%;
  margin-right: 20px;
  & > *:first-child {
    margin-bottom: 10px;
  }
`


const OwnerQuestionThemesPage = ({ match }) => {
  const qvestId = match.params.qvestId
  const questionFilter = { qvestId }
  const [showSettings, setShowSettings] = useState(false)

  const { data, error, loading } = useQuery(THEMES_PAGE_QUERY, { variables: { qvestId, questionFilter } })
  const updateTheme = useThemeUpdate()
  const createTheme = useThemeCreate()
  const deleteTheme = useThemeDelete()
  const createThemeSet = useThemeSetCreate()
  const updateThemeSet = useThemeSetUpdate()
  const deleteThemeSet = useThemeSetDelete()
  const setActiveThemeSet = useThemeSetSetActive()
  const assignTheme = useAssignTheme()
  const getThemeComparison = useGetThemeComparison(qvestId)

  if (error) throw error
  if (loading) return <CenteredLoader />

  const themeSets = data.qvest.themeSets.items
  const questions = data.questions.items

  const defaultThemeSet = themeSets.find(themeSet => themeSet.label === 'DEFAULT')
  const nonSuggestionThemeSets = themeSets.filter(themeSet => themeSet.label !== 'SUGGESTION')

  // Similarity sorting does not perform well enough. As the feature is largely unused Question NLP service has been
  // disabled to conserve resources. As a result similarity sorting cannot be provided and this feature has to be
  // disabled for all users.
  const focusThemeEnabled = false
  //const focusThemeEnabled = isEarlyAdopter(data.user)

  return (
    <ComponentRoot>
      {defaultThemeSet && defaultThemeSet.themes && questions &&
      <AnalyticsThemes
        key={defaultThemeSet.themeSetId}
        yOffset={Y_OFFSET}
        themeFocusEnabled={focusThemeEnabled}
        questions={questions}
        themeSets={themeSets}
        insightsLink={`/owner/qvest/${qvestId}/analytics/project-map`}
        onUpdateTheme={updateTheme}
        onDeleteTheme={deleteTheme}
        onAssignTheme={assignTheme}
        onAddTheme={createTheme}
        onSortQuestions={getThemeComparison}
        onSetRating={(alignmentRating) => updateThemeSet(qvestId, defaultThemeSet.themeSetId, { alignmentRating })}
        onDownload={() => questionThemesToExcelSheet2(data.qvest.name, questions, defaultThemeSet.themes)}
        onShowSettings={() => setShowSettings(true)}
      />}
      {showSettings ?
        <Modal>
          <ThemesSettingsModal
            themeSets={nonSuggestionThemeSets}
            onAdd={(name) => createThemeSet(data.qvest, name)}
            onDelete={(themeSetId) => deleteThemeSet(qvestId, themeSetId)}
            onEdit={(themeSetId, patch) => updateThemeSet(qvestId, themeSetId, patch)}
            onSetActive={(themeSetId) => setActiveThemeSet(data.qvest, themeSetId)}
            onClose={() => setShowSettings(false)}
          />
        </Modal>
        : null}
    </ComponentRoot>
  )
}

export default OwnerQuestionThemesPage
