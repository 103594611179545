import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import * as theme from '../../theme'
import { FormattedMessage } from 'react-intl'
import { hexaToRgba } from '../../../utils/styleUtils'

import Typography from '../../common/Typography/Typography'
import Button from '../../common/Button/Button'
import LastUpdateStatus from '../LastUpdateStatus/LastUpdateStatus'

const ComponentRoot = styled.div`
  position: fixed;
  top: 45px;
  left: 0;
  z-index: 11;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: ${({ theme }) => theme.background1};
  padding: 0 20px 0 20px;
  border-color: ${hexaToRgba('#00000019')};
  border-width: 1px;
  border-style: solid none;
`

const SubTypography = styled(Typography)`
  padding: 5px 0 0 5px;
`

const ButtonGroup = styled.div`
  & > * {
    display: inline-block;
    margin-left: 10px;
  }
`


class PreviewTopBar extends Component {
  constructor(props) {
    super(props)

    this.onBackClick = this.onBackClick.bind(this)
  }

  render() {
    return (
      <ThemeProvider theme={theme.yellow}>
        <ComponentRoot>
          <div>
            <Typography variant="heading3">
              <FormattedMessage
                id="owner.PreviewTopBar.title"
                defaultMessage="Preview"
              />
            </Typography>
            <SubTypography>{' ‒ '}</SubTypography>
            <SubTypography>
              <FormattedMessage
                id="owner.PreviewTopBar.description"
                defaultMessage="Click texts on page to edit"
              />
            </SubTypography>
          </div>
          <ButtonGroup>
            <LastUpdateStatus lastUpdate={this.props.lastUpdate} />
            <Button outline onClick={this.onBackClick}>Close</Button>
          </ButtonGroup>
        </ComponentRoot>
      </ThemeProvider>
    )
  }

  onBackClick() {
    const { onBack } = this.props
    if (onBack) {
      onBack()
    }
  }
}

PreviewTopBar.propTypes = {
  onBack: PropTypes.func,
  lastUpdate: PropTypes.instanceOf(Date)
}

export default PreviewTopBar
