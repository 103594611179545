import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, FormattedDate, injectIntl, defineMessages } from 'react-intl'
import sortBy from 'lodash/sortBy'

import Card from '../../common/Card/Card'
import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import Icon from '../../common/Icon/Icon'
import Button from '../../common/Button/Button'
import Table from '../../common/Table/Table'
import Checkbox from '../../common/Checkbox/Checkbox'
import { isDoneQvestState } from '../../../utils/qvestUtils'
import Dropdown from '../../common/Dropdown/Dropdown'


const FORMAT_OPTIONS = [
  { value: 'pptx', text: 'Microsoft PowerPoint (.pptx)' },
  { value: 'pdf', text: 'Portable Document Format (.pdf)' },
  { value: 'odp', text: 'Open Document Format (.odp)' }
]

const messages = defineMessages({
  closedQvests: { defaultMessage: 'closed Qvests', id: 'owner.ComparisonReportModal.description.closedQvests' },
  hoverMinimum: { defaultMessage: 'Minimum 2 Qvests required to generate comparison', id: 'owner.ComparisonReportModal.hover.minimum' }
})


const CardWrapper = styled.div`
  width: 624px;
  ${Card.Body} > *:last-child { margin-top: 20px; }
`

// TODO: Make this a variant of Card.Body?
const SpaciousCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 40px;
  text-align: center;
  & > *:not(:last-child) {
    margin-bottom: 25px
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 6px;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`

const ScrollableArea = styled.div`
  max-height: 250px;
  overflow-y: auto;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.one[3] };
`

const FormatField = styled.div`
  display: flex;
  margin: 20px 20px 0 20px;
  align-items: center;
  justify-content: flex-end;
  & > * {
    flex: 1;
  }
`

const CheckHeaderCell = styled.th`
  width: 50px;
`

const CheckCell = styled.td`
  width: 50px;
`

const NameCell = styled.td`
  padding: 12px;
`

const DateCell = styled.td`
  white-space: nowrap;
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  & > * + * { margin-left: 20px; }
`


const ErrorCard = () => (
  <CardWrapper>
    <Card>
      <SpaciousCardBody>
        <Icon variant="exclamation-circle" size="large" />
        <Heading.h2>
          <FormattedMessage id="owner.ComparisonReportModal.error.heading" defaultMessage="Failed to generate" />
        </Heading.h2>
        <Typography variant="medium" weight="light">
          <FormattedMessage
            id="owner.ComparisonReportModal.error.description"
            defaultMessage="An error occurred while generating your Qvest report. Please try again or contact us, if the issue persists."
          />
        </Typography>
      </SpaciousCardBody>
    </Card>
  </CardWrapper>
)

const CompleteCard = ({ onCancel }) => (
  <CardWrapper>
    <Card>
      <SpaciousCardBody>
        <Icon variant="paper-plane" size="large" />
        <Heading.h2>
          <FormattedMessage id="owner.ComparisonReportModal.complete.heading" defaultMessage="Your report has been ordered" />
        </Heading.h2>
        <Typography variant="medium" weight="light">
          <FormattedMessage
            id="owner.ComparisonReportModal.complete.description"
            defaultMessage="Your Qvest report is being generated and will be sent to your e-mail inbox shortly. If you do not receive it within 10 minutes, check your SPAM folder and otherwise contact us."
          />
        </Typography>
        <Button onClick={onCancel}>
          <FormattedMessage defaultMessage="Ok" id="owner.ComparisonReportModal.complete.button" />
        </Button>
      </SpaciousCardBody>
    </Card>
  </CardWrapper>
)

const isCheckedFunc = (selected) => (value) => (selected.indexOf(value) !== -1)

const QvestRow = ({ checked, disabled, qvest, onClick }) => {
  const { qvestId, name, schedule } = qvest
  const handleClick = event => {
    event.stopPropagation()
    event.preventDefault()
    if (!disabled) {
      onClick(qvestId)
    }
  }
  return (
    <Table.Row disable={disabled} clickable={!disabled} key={qvestId} onClick={handleClick}>
      <CheckCell>
        <label>
          <Checkbox disabled={disabled} checked={checked} onClick={handleClick} />
        </label>
      </CheckCell>
      <NameCell>{name}</NameCell>
      <DateCell>
        <FormattedDate value={schedule.closeAt} month="short" day="numeric" year="numeric" />
      </DateCell>
    </Table.Row>
  )
}

const SubmitButton = ({ loading, onClick }) => {
  let text
  if (loading) {
    text = (<FormattedMessage defaultMessage="Loading..." id="owner.ComparisonReportModal.button.loading" />)
  } else {
    text = (<FormattedMessage defaultMessage="Generate" id="owner.ComparisonReportModal.button.submit" />)
  }
  return (
    <Button disabled={loading} onClick={onClick}>{text}</Button>
  )
}

const sortAndFilterQvests = (main, qvests) => {
  // Separate main Qvest to be placed at the top
  const mainQvest = qvests.find(q => q.qvestId === main)
  const theRest = qvests.filter(q => q.qvestId !== main)
  // Filter to only include Qvests that are done, have a schedule and questions
  let result = theRest.filter(q => q.schedule && isDoneQvestState(q.state) && q.summary && q.summary.questions > 0)
  // Sort by closing date
  result = sortBy(result, q => q.schedule.closeAt)
  result.reverse()
  // Put main qvest first
  return [mainQvest, ...result]
}

const DownloadCard = ({ main, qvests, intl, onSubmit, onCancel }) => {
  const [selected, setSelected] = useState([main])
  const isChecked = isCheckedFunc(selected)
  const isValid = (selected.length > 1)

  const handleCheck = (qvestId) => {
    if (isChecked(qvestId)) {
      setSelected(selected.filter(o => o !== qvestId))
    } else {
      setSelected([...selected, qvestId])
    }
  }

  let title
  if (!isValid) {
    title = intl.formatMessage(messages.hoverMinimum)
  }

  let displayedQvests = []
  if (qvests && qvests.length > 0) {
    displayedQvests = sortAndFilterQvests(main, qvests)
  }

  return (
    <CardWrapper>
      <Card>
        <Card.Header>
          <Heading.h2>
            <FormattedMessage id="owner.ComparisonReportModal.DownloadCard.heading" defaultMessage="Qvest Comparison Report" />
          </Heading.h2>
          <Icon clickable variant="times" onClick={onCancel} />
        </Card.Header>
        <Card.Body>
          <Typography weight="light">
            <FormattedMessage
              id="owner.ComparisonReportModal.description"
              defaultMessage="You may select any number of {closedQvests} to be included in the comparison report. The current Qvest is selected by default."
              values={{ closedQvests: <Typography weight="bold">{intl.formatMessage(messages.closedQvests)}</Typography> }}
            />
          </Typography>
        </Card.Body>
        <ScrollableArea>
          <Table>
            <thead>
              <tr>
                <CheckHeaderCell />
                <th>Name</th>
                <th>Closed on</th>
              </tr>
            </thead>
            <tbody>
              {displayedQvests.map(qvest =>
                (<QvestRow
                  key={qvest.qvestId}
                  disabled={qvest.qvestId === main}
                  checked={isChecked(qvest.qvestId)}
                  qvest={qvest}
                  onClick={handleCheck}
                />)
              )}
            </tbody>
          </Table>
        </ScrollableArea>
        <Footer>
          <div>
            <Typography weight="light">
              <FormattedMessage
                id="owner.ComparisonReportModal.footer.status"
                defaultMessage="{count} {count, plural, one {Qvest} other {Qvests}} selected"
                values={{ count: selected.length }}
              />
            </Typography>
          </div>
          <ButtonGroup>
            <Button outline onClick={onCancel}>
              <FormattedMessage defaultMessage="Cancel" id="owner.ComparisonReportModal.button.cancel" />
            </Button>
            <Button disabled={!isValid} onClick={() => onSubmit(selected)} title={title}>
              <FormattedMessage defaultMessage="Proceed" id="owner.ComparisonReportModal.button.proceed" />
            </Button>
          </ButtonGroup>
        </Footer>
      </Card>
    </CardWrapper>
  )
}

const FormatCard = ({ onCancel, loading, onSubmit }) => {
  const [format, setFormat] = useState(FORMAT_OPTIONS[0].value)
  return (
    <CardWrapper>
      <Card>
        <Card.Header>
          <Heading.h2>
            <FormattedMessage id="owner.ComparisonReportModal.FormatCard.heading" defaultMessage="Qvest Comparison Report" />
          </Heading.h2>
          <Icon clickable variant="times" onClick={onCancel} />
        </Card.Header>
        <FormatField>
          <Typography.p weight="light">
            <FormattedMessage
              id="owner.ComparisonReportModal.description2"
              defaultMessage="Choose your desired file format:"
            />
          </Typography.p>
          <Dropdown
            value={format}
            options={FORMAT_OPTIONS}
            onChange={selected => { setFormat(selected.value) }}
          />
        </FormatField>
        <Card.Body>
          <ButtonGroup>
            <Button outline onClick={onCancel}>
              <FormattedMessage defaultMessage="Cancel" id="owner.ComparisonReportModal.button.cancel" />
            </Button>
            <SubmitButton loading={loading} onClick={() => onSubmit(format)} />
          </ButtonGroup>
        </Card.Body>
      </Card>
    </CardWrapper>
  )
}

const ComparisonReportModal = (props) => {
  if (props.complete) {
    return <CompleteCard {...props}  />
  } else if (props.format) {
    return <FormatCard {...props} />
  } else if (props.error) {
    return <ErrorCard />
  }
  return <DownloadCard {...props} />
}

export default injectIntl(ComparisonReportModal)
