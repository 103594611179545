import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import ContainerDimensions from 'react-container-dimensions'

import { makeGroupColorScales } from '../../../utils/d3Utils'
import {
  Title,
  Content,
  Card,
  MainCard,
  SubContent,
} from '../AnalyticsLayout/AnalyticsLayout'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Tooltip from '../../common/Tooltip/Tooltip'
import { BarChart, Legend, activityMapping } from 'data-viz'


const MainCardLayout = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
    max-width: 500px;
    text-align: center;
  }
  & > :nth-child(2) {
    margin: 0 auto;
    padding: 70px 0;
    height: 50%;
    width: 100%;
    max-width: 500px;
  }
  & > :nth-child(3) {
    margin: 0 auto;
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

const MediumCardLayout = styled.div`
  height: 100%;
  & > :first-child {
    width: 100%;
  }
  & > :nth-child(2) {
    margin-top: 40px;
    width: 100%;
  }
`

const SmallCardLayout = styled.div`
  height: 100%;
  & > :first-child {
    width: 100%;
  }
  & > :nth-child(2) {
    margin-bottom: 40px;
    width: 100%;
  }
`

const messages = defineMessages({
  highest: { id: 'owner.ActivityPage.cardDescription.highest', defaultMessage: 'This group had the highest number of active participants' },
  lowest: { id: 'owner.ActivityPage.cardDescription.lowest', defaultMessage: 'This group had the lowest number of active participants' },
})

const totalMessages = defineMessages({
  LOW: { id: 'owner.ActivityPage.cardDescription.totalLow', defaultMessage: 'Be careful. Low activity means inconclusive data' },
  OK: { id: 'owner.ActivityPage.cardDescription.totalOk', defaultMessage: 'You\'re good to go. Good activity means valid data' },
  HIGH: { id: 'owner.ActivityPage.cardDescription.totalHigh', defaultMessage: 'This is great! Activity was high which means loads of valid data' },
  DIVERSE: { id: 'owner.ActivityPage.cardDescription.totalDiverse', defaultMessage: 'Don\'t forget. Your findings won\'t be equally relevant or applicable to all your groups' },
})

const amountMessages = defineMessages({
  LOW: { id: 'owner.ActivityPage.mainHeading.lowAmount', defaultMessage: 'low activity' },
  OK: { id: 'owner.ActivityPage.mainHeading.okAmount', defaultMessage: 'good activity' },
  HIGH: { id: 'owner.ActivityPage.mainHeading.highAmount', defaultMessage: 'high activity' },
  DIVERSE: { id: 'owner.ActivityPage.mainHeading.diverseAmount', defaultMessage: 'varied activity' },
})

const tooltipMessages = defineMessages({
  LOW: { id: 'owner.ActivityPage.mainTooltip.lowAmount', defaultMessage: 'Activity is considered low when less than 30% of your stakeholders either asked or answered a question. Similarly, activity is considered high when more than 60% of your stakeholders asked or answered a question, and good when 30-59% of your stakeholders asked or answered a question.' },
  OK: { id: 'owner.ActivityPage.mainTooltip.okAmount', defaultMessage: 'Activity is considered good when 30-59% of your stakeholders either asked or answered a question. Similarly, activity is considered low when less than 30% of your stakeholders either asked or answered a question and high when more than 60% of your stakeholders asked or answered a question.' },
  HIGH: { id: 'owner.ActivityPage.mainTooltip.highAmount', defaultMessage: 'Activity is considered high when more than 60% of your stakeholders either asked or answered a question. Similarly, activity is considered low when less than 30% of your stakeholders asked or answered a question, and good when 30-59% of your stakeholders asked or answered a question.' },
  DIVERSE: { id: 'owner.ActivityPage.mainTooltip.diverseAmount', defaultMessage: 'There are three levels of activity; high, good and low. Activity is considered varied when two or more of your groups have different levels of activity.' },
})

const relationMessages = defineMessages({
  LOW: { id: 'owner.ActivityPage.mainHeading.lowRelation', defaultMessage: 'less than 30%' },
  OK: { id: 'owner.ActivityPage.mainHeading.okRelation', defaultMessage: '30-59%' },
  HIGH: { id: 'owner.ActivityPage.mainHeading.highRelation', defaultMessage: 'more than 60%' },
})


class AnalyticsActivity extends Component {
  determineHeadline(type, isDiverse, participationRate) {
    const { intl } = this.props

    let amountText, tooltipText, relationText
    if (!isDiverse) {
      amountText = intl.formatMessage(amountMessages[type])
      tooltipText = intl.formatMessage(tooltipMessages[type])
      relationText = intl.formatMessage(relationMessages[type], { value: participationRate })
    } else {
      amountText = intl.formatMessage(amountMessages['DIVERSE'])
      tooltipText = intl.formatMessage(tooltipMessages['DIVERSE'])
    }

    const tooltip = (
      <Tooltip>
        <Heading.h3>
          <FormattedMessage id="owner.AnalyticsNetwork.tooltipTitle" defaultMessage="Activity explanation" />
        </Heading.h3>
        <Typography variant="medium" weight="light">{tooltipText}</Typography>
      </Tooltip>
    )

    const amount = (
      <Tooltip.Area>
        <Tooltip.Reference>
          <Heading variant="heading3" primary>{amountText}</Heading>
        </Tooltip.Reference>
        {tooltip}
      </Tooltip.Area>
    )

    if (!isDiverse) {
      return (
        <FormattedMessage
          id="owner.ActivityPage.mainHeading.default"
          defaultMessage="Your Qvest had overall {amount}, meaning that {relation} of your stakeholders either asked or answered a question"
          values={{ amount, relation: relationText }}
        />
      )
    } else {
      return (
        <FormattedMessage
          id="owner.ActivityPage.mainHeading.diversity"
          defaultMessage="Your Qvest had {amount}, meaning that your stakeholder groups asked or answered different amounts of questions"
          values={{ amount }}
        />
      )
    }
  }

  render() {
    const { intl, theme, participation } = this.props

    const ids = participation.map(p => p.groupId)
    const colorScale = makeGroupColorScales(theme, ids).primaryColors

    const { type, isDiverse, sum, formattedSum, lowest, highest } = activityMapping.formatData(participation, theme, colorScale)
    const headline = this.determineHeadline(type, isDiverse, sum.participationRate)
    const totalDescription = intl.formatMessage(totalMessages[isDiverse ? 'DIVERSE' : type])

    return (
      <Content>
        <MainCard highlighted>
          <MainCardLayout>
            <div>
              <Heading variant="heading3">{headline}</Heading>
            </div>
            <div>
              <ContainerDimensions>
                {({ width }) => <BarChart simple data={isDiverse ? [lowest, highest] : formattedSum} width={width} />}
              </ContainerDimensions>
            </div>
            {isDiverse ? <Legend horizontal data={[lowest, highest]} /> : null}
          </MainCardLayout>
        </MainCard>
        <SubContent>
          <Card>
            <MediumCardLayout>
              <Title>
                <Heading.h3>Total</Heading.h3>
                <Typography variant="medium" transparent>{totalDescription}</Typography>
              </Title>
              <ContainerDimensions>
                {({ width }) => <BarChart highlight data={formattedSum} width={width} />}
              </ContainerDimensions>
            </MediumCardLayout>
          </Card>
          <Card>
            <SmallCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage id="owner.ActivityPage.cardHeading.highest" defaultMessage="Highest" />
                </Heading.h3>
                <Typography variant="medium" transparent>{intl.formatMessage(messages.highest)}</Typography>
              </Title>
              <ContainerDimensions>
                {({ width }) => <BarChart highlight data={[highest]} width={width} />}
              </ContainerDimensions>
              <Legend data={[highest]} />
            </SmallCardLayout>
          </Card>
          <Card>
            <SmallCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage id="owner.ActivityPage.cardHeading.lowest" defaultMessage="Lowest" />
                </Heading.h3>
                <Typography variant="medium" transparent>{intl.formatMessage(messages.lowest)}</Typography>
              </Title>
              <ContainerDimensions>
                {({ width }) => <BarChart highlight data={[lowest]} width={width} />}
              </ContainerDimensions>
              <Legend data={[lowest]} />
            </SmallCardLayout>
          </Card>
        </SubContent>
      </Content>
    )
  }

  static propTypes = {
    participation: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.string,
      name: PropTypes.string,
      participantActiveCount: PropTypes.number,
      participantCount: PropTypes.number,
    }))
  }
}

export default withTheme(injectIntl(AnalyticsActivity))
