import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Fold from '../../common/Fold/Fold'
import QvestAbout from '../QvestAbout/QvestAbout'


class QvestAboutFold extends Component {
  render() {
    const { qvest, participants, hide } = this.props
    if (hide) return null
    const groupings = qvest.groupings
    const label = (
      <Typography>
        <FormattedMessage
          id="AboutFold.label"
          defaultMessage="About this Qvest"
        />
      </Typography>
    )
    return (
      <Fold label={label}>
        <QvestAbout
          isEditable={false}
          qvest={qvest}
          participants={participants}
          groups={groupings ? groupings[0].groups : null}
        />
      </Fold>
    )
  }
}

export default QvestAboutFold
