import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const UNICODE_MAP = {
  'times': '\uf00d',
  'question-circle': '\uf059',
  'question': '\uf128',
  'angle-up': '\uf106',
  'angle-down': '\uf107',
  'angle-right': '\uf105',
  'chevron-up': '\uf077',
  'chevron-down': '\uf078',
  'chevron-left': '\uf053',
  'chevron-right': '\uf054',
  'users': '\uf0c0',
  'code-branch': '\uf126',
  'cloud': '\uf0c2',
  'chart-pie': '\uf200',
  'comments': '\uf086',
  'file': '\uf15b',
  'columns': '\uf0db',
  'edit': '\uf044',
  'trash': '\uf1f8',
  'check': '\uf00c',
  'square': '\uf0c8',
  'check-square': '\uf14a',
  'file-excel': '\uf1c3',
  'file-image': '\uf1c5',
  'undo': '\uf0e2',
  'exclamation-triangle': '\uf071',
  'exclamation-circle': '\uf06a',
  'check-circle': '\uf058',
  'sync-alt': '\uf2f1',
  'exclamation': '\uf12a',
  'paper-plane': '\uf1d8',
  'envelope': '\uf0e0',
  'id-card': '\uf2c2',
  'circle': '\uf111',
  'dot-circle': '\uf192',
  'home': '\uf015',
  'ban': '\uf05e',
  'clock': '\uf017',
  'download': '\uf019',
  'external-link-square-alt': '\uf360',
  'long-arrow-alt-right': '\uf30b',
  'lock': '\uf023',
  'ellipsis-h': '\uf141',
  'brain': '\uf5dc',
  'bolt': '\uf0e7',
  'balance-scale': '\uf24e',
  'arrows-alt-h': '\uf337',
  'arrow-left': '\uf060',
  'arrow-right': '\uf061',
  'arrow-up': '\uf062',
  'arrow-down': '\uf063',
  'arrows-alt': '\uf0b2',
  'caret-down': '\uf0d7',
  'caret-up': '\uf0d8',
  'eye': '\uf06e',
  'hand-paper': '\uf256',
  'thumbtack': '\uf08d',
  'search': '\uf002',
  'sort-amount-down': '\uf160',
  'crosshairs': '\uf05b',
  'gear': '\uf013'
}

const SIZE = {
  small: '12px',
  regular: '16px',
  medium: '40px',
  large: '60px',
}

const LINE_HEIGHT = {
  small: '16px',
  regular: '20px',
  medium: '50px',
  large: '70px',
}

const determineColor = ({ theme, primary, secondary, tertiary, ok, warning, error, inverted, two }) => {
  if (error) return theme.alert.error
  if (warning) return theme.alert.warning
  if (ok) return theme.alert.ok
  let rank
  if (primary) {
    rank = 'primary'
  } else if (secondary) {
    rank = 'secondary'
  } else if (tertiary) {
    rank = theme.tertiary
  }
  if (rank) {
    return theme.main[rank]
  } else if (two) {
    return inverted ? theme.inverted.two[0] : theme.two[0]
  } else {
    return inverted ? theme.inverted.one[0] : theme.one[0]
  }
}

const determineHoverColor = (props) => {
  if (props.disabled) return determineColor(props)
  return props.theme.main.secondary
}

const StyledIcon = styled.span`
  font-family: 'Font Awesome 5 Free';
  color: ${determineColor};
  font-weight: 900;
  font-size: ${({ size }) => SIZE[size] || '16px'};
  line-height: ${({ size }) => LINE_HEIGHT[size] || '20px'};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: ${({ clickable }) => clickable ? 'none' : 'auto'};
  opacity: ${({ disabled }) => disabled ? '0.5' : '1.0'};
  ${({ transparent }) => transparent && 'opacity: .5;'};
  transition: 0.2s opacity, 0.2s color;
  &:hover {
    ${({ clickable, disabled }) => clickable && !disabled ? 'cursor: pointer;' : ''}
    ${props => props.clickable ? `color: ${determineHoverColor(props)};` : ''}
  }
`

const Icon = (props) => (
  <StyledIcon {...props} >
    {UNICODE_MAP[props.variant]}
  </StyledIcon>
)

Icon.propTypes = {
  variant: PropTypes.oneOf(Object.keys(UNICODE_MAP)),
  size: PropTypes.oneOf([
    'small',
    'regular',
    'medium',
    'large'
  ]),
  disabled: PropTypes.bool,
  clickable: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  transparent: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
}

Icon.defaultProps = {
  variant: 'angle-down',
  color: 'default',
  size: 'regular',
  disabled: false,
  clickable: false,
  warning: false,
  error: false,
  transparent: false,
  primary: false,
  secondary: false,
  tertiary: false,
}

export default Icon
