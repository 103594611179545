import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import sortBy from 'lodash/sortBy'

import {
  Title,
  Content,
  Card,
  MainCard,
  SubContent,
} from '../AnalyticsLayout/AnalyticsLayout'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Tooltip from '../../common/Tooltip/Tooltip'
import DistributionChart from '../../common/DistributionChart/DistributionChart'
import PerParticipantChart from '../../common/PerParticipantChart/PerParticipantChart'
import { Legend } from 'data-viz'
import Badge from '../../common/Badge/Badge'
import { makeGroupColorScales } from '../../../utils/d3Utils'

const MainCardLayout = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
    max-width: 500px;
    text-align: center;
  }
  & > :nth-child(2) {
    height: 50%;
    width: calc(100% - 40px);
    margin: 0 auto;
  }
  & > :nth-child(3) {
    margin: 0 auto;
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

const MediumCardLayout = styled.div`
  height: 100%;
  & > *:first-child {
    height: 20%;
  }
  & > *:nth-child(2) {
    margin-top: 20px;
    width: 100%;
    height: 55%;
  }
  & > *:last-child {
    height: calc(25% - 20px);
  }
`

const SmallCardLayout = styled.div`
  height: 100%;
  & > :first-child {
    width: 100%;
    height: 30%;
  }
  & > :nth-child(2) {
    margin-top: 20px;
    width: 100%;
    height: 40%;
  }
  & > *:last-child {
    height: calc(30% - 20px);
  }
`

const BottomGroup = styled.div`
  display: flex;
  align-items: flex-end;
  & > *:last-child {
    margin-left: auto;
    position: relative;
    top: 5px;
  }
`

export const messages = defineMessages({
  allGroups: { id: 'owner.AnalyticsDistributionPage.all', defaultMessage: 'All groups' },
  distributionLabel: {
    id: 'owner.DistributionPage.distributionLabel',
    defaultMessage: '{questionCount, plural, one {Question} other {Questions}} received',
  },
  perParticipantCountLabel: {
    id: 'owner.DistributionPage.perParticipantCountLabel',
    defaultMessage: '{questionCount, plural, one {Question} other {Questions}}',
  },
  perParticipantIconLabel: {
    id: 'owner.DistributionPage.perParticipantIconLabel',
    defaultMessage: 'Per participant',
  },
  mainBadgeLabel: {
    id: 'owner.DistributionPage.mainBadgeLabel',
    defaultMessage: '{questions, plural, one {question} other {questions}} in total',
  },
  badgeLabel: {
    id: 'owner.DistributionPage.badgeLabel',
    defaultMessage: '{questions, plural, one {question} other {questions}}',
  },
})


const messagesAmount = defineMessages({
  NORMAL: { id: 'owner.DistributionPage.mainHeading.normal', defaultMessage: 'no difference' },
  DIFFER: { id: 'owner.DistributionPage.mainHeading.differ', defaultMessage: 'difference' },
  EXTREME: { id: 'owner.DistributionPage.mainHeading.extreme', defaultMessage: 'big difference' },
})


const messagesTooltip = defineMessages({
  NORMAL: { id: 'owner.DistributionPage.mainTooltip.normal', defaultMessage: 'There is no difference in question distribution when there is less than 10% difference between the group that receives the highest number of questions and the group that receives the lowest number of questions. Similarly, there is difference in question distribution when there is more than 10% difference between the group that receives the highest number of questions and the group that receives the lowest number of questions, and big difference in question distribution when there is more than 100% difference between the group that receives the highest number of questions and the group that receives the lowest number of questions.' },
  DIFFER: { id: 'owner.DistributionPage.mainTooltip.differ', defaultMessage: 'There is difference in question distribution when there is more than 10% difference between the group that receives the highest number of questions and the group that receives the lowest number of questions. Similarly, there is a big difference in question distribution when there is more than 100% difference between the group that receives the highest number of questions and the group that receives the lowest number of questions, and no difference in question distribution when there is less than 10% difference between the group that receives the highest number of questions and the group that receives the lowest number of questions.' },
  EXTREME: { id: 'owner.DistributionPage.mainTooltip.extreme', defaultMessage: 'There is a big difference in question distribution when there is more than 100% difference between the group that receives the highest number of questions and the group that receives the lowest number of questions. Similarly, there is difference in question distribution when there is more than 10% difference between the group that receives the highest number of questions and the group that receives the lowest number of questions, and no difference in question distribution when there is less than 10% difference between the group that receives the highest number of questions and the group that receives the lowest number of questions.' },
})

const roundOne = num => Math.round(num * 10) / 10
const truncate = (name, maxChars) => (name.length > maxChars) ? name.slice(0, maxChars) + '...' : name
const determineType = (ratio) => {
  // these ratios are completely arbitrary
  if (ratio > .9) return 'NORMAL'
  if (ratio > .5) return 'DIFFER'
  return 'EXTREME'
}

// format sort and format data to match main chart input
export function formatData(participation, colors, theme) {
  const extendedParticipation = participation
    .map((group) => ({
      ...group,
      questionsPerParticipant: group.participantCount ? (group.questionReceivedCount / group.participantCount) : 0,
      // make sure we are not running out of colors
      fill: colors(group.groupId),
    }))

  // count total participants
  const count = extendedParticipation
    .reduce((acc, { participantCount }) => acc + participantCount, 0)
  // count total questions being asked
  const questionsCount = extendedParticipation
    .reduce((acc, { questionReceivedCount }) => acc + questionReceivedCount, 0)
  const sum = {
    groupId: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
    participantCount: count,
    questionReceivedCount: questionsCount,
    questionsPerParticipant: count ? (questionsCount / count) : 0,
    fill: theme.main.primary,
  }

  const sortedByReceivedQuestions = sortBy(extendedParticipation, 'questionReceivedCount')
  const leastActive = sortedByReceivedQuestions[0]
  const mostActive = sortedByReceivedQuestions.slice(-1)[0]
  const data = [{
    value: mostActive.questionReceivedCount,
    fill: mostActive.fill,
  }, {
    value: leastActive.questionReceivedCount,
    fill: leastActive.fill,
  }]
  const legend = [{
    fill: mostActive.fill,
    label: mostActive.name,
  }, {
    fill: leastActive.fill,
    label: leastActive.name,
  }]

  const type = determineType(leastActive.questionReceivedCount / mostActive.questionReceivedCount)

  const sortedByPerParticipant = sortBy(extendedParticipation, 'questionsPerParticipant')
  const leastPerParticipant = sortedByPerParticipant[0]
  const mostPerParticipant = sortedByPerParticipant.slice(-1)[0]

  return {
    type,
    data,
    sum,
    legend,
    leastPerParticipant,
    mostPerParticipant
  }
}

class AnalyticsDistribution extends Component {
  renderMainHeading(type) {
    const { intl } = this.props
    const tooltipText = intl.formatMessage(messagesTooltip[type])
    const amount = (
      <Tooltip.Area>
        <Tooltip.Reference>
          <Heading variant="heading2" primary>
            {intl.formatMessage(messagesAmount[type])}
          </Heading>
        </Tooltip.Reference>
        <Tooltip>
          <Heading.h3>
            <FormattedMessage
              id="owner.DistributionPage.mainTooltip.title"
              defaultMessage="Distribution explanation"
            />
          </Heading.h3>
          <Typography variant="medium" weight="light">{tooltipText}</Typography>
        </Tooltip>
      </Tooltip.Area>
    )
    return (
      <Heading variant="heading2">
        <FormattedMessage
          id="owner.DistributionPage.mainHeading.body"
          defaultMessage="There was {amount} in question distribution among your groups"
          values={{ amount }}
        />
      </Heading>
    )
  }

  render() {
    const { theme, participation, intl } = this.props

    if (!participation) return null

    const groupIds = participation.map(g => g.groupId)
    const colors = makeGroupColorScales(theme, groupIds).primaryColors
    const { type, data, legend, sum, leastPerParticipant, mostPerParticipant } = formatData(participation, colors, theme)
    const dataWithLabels = data.map(group => ({
      ...group,
      labels: intl.formatMessage(messages.distributionLabel, { questionCount: group.questionReceivedCount })
    }))

    return (
      <Content>
        <MainCard highlighted>
          <MainCardLayout>
            <div>{this.renderMainHeading(type)}</div>
            <DistributionChart data={dataWithLabels} />
            <Legend horizontal data={legend} />
          </MainCardLayout>
        </MainCard>
        <SubContent>
          <Card>
            <MediumCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage
                    id="owner.AnalyticsDistribution.mediumCardTitle"
                    defaultMessage="Average number"
                  />
                </Heading.h3>
                <Typography variant="small" weight="light" transparent>
                  <FormattedMessage
                    id="owner.DistributionPage.avgSub"
                    defaultMessage="of questions received per stakeholder"
                  />
                </Typography>
              </Title>
              <PerParticipantChart
                value={roundOne(sum.questionsPerParticipant)}
                valueLabel={intl.formatMessage(messages.perParticipantCountLabel, { questionCount: sum.questionsPerParticipant })}
                iconLabel={intl.formatMessage(messages.perParticipantIconLabel)}
              />
              <BottomGroup>
                <Legend data={[{ label: intl.formatMessage(messages.allGroups), fill: sum.fill }]} />
                <Badge
                  value={sum.questionReceivedCount}
                  label={intl.formatMessage(messages.mainBadgeLabel, { questions: sum.questionReceivedCount })}
                  fill={sum.fill}
                />
              </BottomGroup>
            </MediumCardLayout>
          </Card>
          <Card>
            <SmallCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage id="owner.DistributionPage.highest" defaultMessage="Highest number" />
                </Heading.h3>
                <Typography variant="small" weight="light" transparent>
                  <FormattedMessage
                    id="owner.DistributionPage.highestSub"
                    defaultMessage="of questions received per stakeholder"
                  />
                </Typography>
              </Title>
              <PerParticipantChart
                compact
                value={roundOne(mostPerParticipant.questionsPerParticipant)}
                fill={mostPerParticipant.fill}
                valueLabel={intl.formatMessage(messages.perParticipantCountLabel, { questionCount: mostPerParticipant.questionsPerParticipant })}
                iconLabel={intl.formatMessage(messages.perParticipantIconLabel)}
              />
              <BottomGroup>
                <Legend data={[{ label: truncate(mostPerParticipant.name, 15), fill: mostPerParticipant.fill }]} />
                <Badge
                  value={mostPerParticipant.questionReceivedCount}
                  label={intl.formatMessage(messages.badgeLabel, { questions: mostPerParticipant.questionReceivedCount })}
                  fill={mostPerParticipant.fill}
                />
              </BottomGroup>
            </SmallCardLayout>
          </Card>
          <Card>
            <SmallCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage id="owner.DistributionPage.lowest" defaultMessage="Lowest number" />
                </Heading.h3>
                <Typography variant="small" weight="light" transparent>
                  <FormattedMessage
                    id="owner.DistributionPage.lowestSub"
                    defaultMessage="of questions received per stakeholder"
                  />
                </Typography>
              </Title>
              <PerParticipantChart
                compact
                value={roundOne(leastPerParticipant.questionsPerParticipant)}
                fill={leastPerParticipant.fill}
                valueLabel={intl.formatMessage(messages.perParticipantCountLabel, { questionCount: leastPerParticipant.questionsPerParticipant })}
                iconLabel={intl.formatMessage(messages.perParticipantIconLabel)}
              />
              <BottomGroup>
                <Legend data={[{ label: truncate(leastPerParticipant.name), fill: leastPerParticipant.fill }]} />
                <Badge
                  value={leastPerParticipant.questionReceivedCount}
                  label={intl.formatMessage(messages.badgeLabel, { questions: leastPerParticipant.questionReceivedCount })}
                  fill={leastPerParticipant.fill}
                />
              </BottomGroup>
            </SmallCardLayout>
          </Card>
        </SubContent>
      </Content>
    )
  }

  static propTypes = {
    participation: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.string,
      name: PropTypes.string,
      participantActiveCount: PropTypes.number,
      participantCount: PropTypes.number,
      participationRate: PropTypes.number,
      fill: PropTypes.string,
    }))
  }
}

export default withTheme(injectIntl(AnalyticsDistribution))
