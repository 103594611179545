import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { DropTarget } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import styled from 'styled-components'

import Card from '../../common/Card/Card'
import Icon from '../../common/Icon/Icon'
import Typography from '../../common/Typography/Typography'
import Button from '../../common/Button/Button'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'
import FileDrop from '../../common/FileDrop/FileDrop'
import OwnerParticipantTable from '../OwnerParticipantTable/OwnerParticipantTable'
import { FormattedMessage } from 'react-intl'


const ComponentRoot = styled.div`
  width: 650px;
`

const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
  & > :not(:last-child) {
    margin-right: 12px;
  }
`

const Centered = styled.div`
  & > * {
    margin: 14px auto 14px auto;
  }
`

const HorizontalRule = styled.hr`
  border-style: none;
  border-bottom: 1px solid ${({ theme }) => theme.two[1]};
  margin: 20px 20px 0 20px;
`

const SpacedSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`

const HorizontalList = styled.ul`
  list-style: none;
  padding: 0 20px;
  margin: 0;
	& > li {
		display: inline;
		padding-right: 4px;
	}
	& > li:after {
	  content: ', '
	}
	& > li:last-child:after {
	  content: ''
	}
`


const MAX_PREVIEW_COUNT = 3 // Maximum number of participants to preview

const ParticipantPreview = ({ participants }) => {
  const count = Math.min(MAX_PREVIEW_COUNT, participants.length)
  return (
    <Fragment>
      <SpacedSection>
        <Typography variant="heading4">
          <FormattedMessage defaultMessage="Participants" id="owner.ImportParticipantsModal.ParticipantPreview.title" />
        </Typography>
        <Typography secondary variant="medium" weight="light">
          <FormattedMessage
            id="owner.ImportParticipantsModal.ParticipantPreview.description"
            defaultMessage="(showing {count} of {participantCount} {participantCount, plural, one {participant} other {participants}})"
            values={{ count, participantCount: participants.length }}
          />
        </Typography>
      </SpacedSection>
      <OwnerParticipantTable participants={participants.slice(0, MAX_PREVIEW_COUNT)} />
      <HorizontalRule />
    </Fragment>
  )
}


const GroupListItem = ({ group }) => (
  <li>
    <Typography variant="medium">{group.name}</Typography>
    {' '}
    <Typography variant="medium" weight="light">({group.count})</Typography>
  </li>
)

const GroupPreview = ({ groups, participants }) => {
  return (
    <Fragment>
      <SpacedSection>
        <Typography variant="heading4">
          <FormattedMessage defaultMessage="Groups" id="owner.ImportParticipantsModal.GroupPreview.title" />
        </Typography>
        <Typography secondary variant="medium" weight="light">
          <FormattedMessage
            id="owner.ImportParticipantsModal.GroupPreview.description"
            defaultMessage="({groupCount} {groupCount, plural, one {group} other {groups}} with {participantCount} {participantCount, plural, one {participant} other {participants}})"
            values={{ groupCount: groups.length, participantCount: participants.length }}
          />
        </Typography>
      </SpacedSection>
      <HorizontalList>
        {groups.map((group, i) => <GroupListItem key={i} group={group} />)}
      </HorizontalList>
      <HorizontalRule />
    </Fragment>
  )
}

const SubmitButton = ({ fileName, onSubmit, onClose }) => (
  <SpacedSection>
    <Typography secondary variant="medium" weight="light">{fileName}</Typography>
    <AlignRight>
      <Button outline onClick={onClose}>Cancel</Button>
      <Button onClick={onSubmit}>Import</Button>
    </AlignRight>
  </SpacedSection>
)

const target = {
  drop(props, monitor) {
    const { onFileChange } = props
    if (onFileChange) {
      onFileChange(monitor.getItem().files[0])
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }
}

class ImportParticipantsModal extends Component {
  renderError() {
    const { error } = this.props
    if (!error) return null
    return (
      <Card.Message error>
        <Typography cta tertiary>
          <span>Sorry, the file you chose doesn&#39;t match the format required.</span>
        </Typography>
      </Card.Message>
    )
  }

  renderFileDrop() {
    const { isLoading, fileName, participants, onFileChange, isOver } = this.props
    if (participants) return null

    let fileDrop
    if (!isLoading) {
      fileDrop = (
        <FileDrop
          isOver={isOver}
          fileName={fileName}
          onFileChange={onFileChange}
        />
      )
    } else if (isLoading) {
      fileDrop = (
        <CenteredLoader height={78} />
      )
    }

    return (
      <Card.Body>
        <div>
          <Typography.p weight="light">
            Upload an Excel file with names and email addresses of participants
          </Typography.p>
          <Typography.p weight="light">
            Make sure the spreadsheet only has one column with the participants&#x27;
            names and one column with the participants&#x27; email addresses.
          </Typography.p>
        </div>
        <Centered>
          {fileDrop}
        </Centered>
      </Card.Body>
    )
  }

  render() {
    const { fileName, participants, groups, connectDropTarget, onClose } = this.props
    return connectDropTarget(
      <div>
        <ComponentRoot>
          <Card>
            <Card.Header>
              <Typography variant="heading3">Import via Excel</Typography>
              <Icon clickable variant="times" onClick={this.handleClose} />
            </Card.Header>
            {this.renderError()}
            {this.renderFileDrop()}
            {participants ? <ParticipantPreview participants={participants} onSubmit={this.handleSubmit} /> : null}
            {groups ? <GroupPreview groups={groups} participants={participants} /> : null}
            {participants ? <SubmitButton fileName={fileName} participants={participants} onSubmit={this.handleSubmit} onClose={onClose} /> : null}
          </Card>
        </ComponentRoot>
      </div>
    )
  }

  handleClose = () => {
    const { onClose } = this.props
    onClose()
  }

  handleSubmit = () => {
    const { onSubmit, participants, groups } = this.props
    onSubmit(participants, groups)
  }
}

ImportParticipantsModal.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  })),
  fileName: PropTypes.string,
  onFileChange: PropTypes.func,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

ImportParticipantsModal.defaultProps = {
  onClose: () => { },
  onSubmit: () => { },
  isLoading: false
}

export default DropTarget(NativeTypes.FILE, target, collect)(ImportParticipantsModal)
