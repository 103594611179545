
const SEVERITY_TO_NUM = {
  'OK': 0,
  'WARNING': 1,
  'ERROR': 2
}

const NUM_TO_SEVERITY = {
  0: 'OK',
  1: 'WARNING',
  2: 'ERROR'
}

/*
Validating an e-mail address to standard is tedious and it might still be invalid, since it may be expired. And there
are addresses in use which do not follow the standard. The only true validation is to attempt to send.

The point of validation used here is to catch basic typos such as extra white spaces or punctuations.

Source: https://stackoverflow.com/a/742588
*/
const EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/

export function maxValiditySeverity(validities) {
  const numSeverities = validities.map(validity => SEVERITY_TO_NUM[validity.general])
  const maxNumSeverity = Math.max(...numSeverities)
  return NUM_TO_SEVERITY[maxNumSeverity]
}

export function isValidEmailAddress(email) {
  return email.match(EMAIL_REGEX)
}
