import React, { createContext, useContext } from 'react'

const FeaturesContext = createContext()

const FeaturesProvider = FeaturesContext.Provider

export const useFeatures = () => {
  let features = useContext(FeaturesContext)
  if (!features) {
    features = {} // Default to empty object, if no context found
  }

  // Is feature enabled?
  features.isEnabled = (name) =>
    (features != null) && (features[name] != null) && (features[name].enabled != null) && features[name].enabled
  // Is feature disabled? (only if feature is defined and actively disabled)
  features.isDisabled = (name) =>
    (features != null) && (features[name] != null) && (features[name].enabled != null) && !(features[name].enabled)

  return features
}

export const withFeatures = (WrappedComponent) => {
  const highOrderComponent = (props) => {
    const features = useFeatures()
    return (
      <WrappedComponent features={features} {...props} />
    )
  }
  return highOrderComponent
}

export const isEnabled = (features, name) =>
  (features != null) && (features[name] != null) && (features[name].enabled != null) && features[name].enabled


export default FeaturesProvider
