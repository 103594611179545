import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import styled, { withTheme } from 'styled-components'
import ContainerDimensions from 'react-container-dimensions'

import { formatData } from '../../../../../components/owner/AnalyticsMindset/AnalyticsMindset'
import GreenOnBeigeTheme from '../../../../../components/green-on-beige-theme'
import Typography from '../../../../../components/common/Typography/Typography'
import Card from '../../../../../components/common/Card/Card'
import Disclaimer from '../../../../../components/owner/Disclaimer/Disclaimer'
import { TitleBar } from '../../../../../components/owner/AnalyticsLayout/AnalyticsLayout'
import AnalyticsMindset from '../../../../../components/owner/AnalyticsMindset/AnalyticsMindset'
import { StackedBarChart } from 'data-viz'
import DistributionParticipationTable from '../../../../../components/owner/DistributionParticipationTable/DistributionParticipationTable'
import { filterParticipationByQvestId } from '../../../../../reducers/stats'
import { getParticipation } from '../../../../../actions/statsActions'


const ComponentRoot = styled.div`
  & > * { max-width: 1400px; }
  & > :nth-child(3) {
    padding: 0 30px;
    margin-top: 30px;
    min-width: 1270px;
  }
  & > :last-child {
    margin: 50px 30px 30px 30px;
    max-width: 989px
  }
`


class OwnerMindsetPage extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getParticipation(match.params.qvestId))
  }

  handleBackClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics/home`)
  }

  renderDistributionChart(distributionParticipation) {
    const { primary, secondary } = GreenOnBeigeTheme.data
    const colors = {
      primary: [primary[0], primary[3]],
      secondary: [secondary[0], secondary[3]]
    }
    const labels = {
      questionsPercent: 'Questions',
      answersPercent: 'Answers'
    }
    return (
      <div>
        <ContainerDimensions>
          {({ width }) =>
            <StackedBarChart
              data={{ labels, values: distributionParticipation }}
              colors={colors}
              valuesTitle="Impact"
              unitTitle="(overall percentage)"
              width={width}
            />
          }
        </ContainerDimensions>
      </div>
    )
  }

  render() {
    const { participationStore, theme, match } = this.props
    const participation = filterParticipationByQvestId(participationStore, match.params.qvestId)
    const { distribution, distributionWithAll } = formatData(participation, theme)


    const title = <FormattedMessage
      id="owner.AnalyticsMindset.title"
      defaultMessage="Mindset"
    />
    const description = <FormattedMessage
      id="owner.AnalyticsMindset.description"
      defaultMessage="Commitment to Qvest project"
    />

    return (
      <ComponentRoot>
        <TitleBar
          title={title}
          description={description}
          onBack={this.handleBackClick}
        />
        <AnalyticsMindset participation={participation} />
        <div>
          <Card>
            <Card.Header>
              <Typography variant="heading3">Impact on output</Typography>
            </Card.Header>
            <Card.Body>
              <Typography secondary variant="medium" weight="light">
                Explore how much the different groups contributed to the question and answer output.
              </Typography>
            </Card.Body>
            <Card.ChartBox>
              {participation ? this.renderDistributionChart(distribution) : null}
            </Card.ChartBox>
            {participation ? <DistributionParticipationTable participation={distributionWithAll} /> : null}
          </Card>
        </div>
        <Disclaimer />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    participationStore: state.stats.get('participation').toJS()
  }
}

export default connect(mapStateToProps)(withTheme(OwnerMindsetPage))
