import React from 'react'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'

const ComponentRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 69px;
  border-radius: 4px;
  border: 3px solid ${({ theme }) => theme.main.secondary};
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme }) => theme.main.secondary};

  & > * {
    color: ${({ theme }) => theme.tertiary};
  }

  &:hover {
    background-color: ${({ theme }) => theme.main.secondary};
    border-color: ${({ theme }) => theme.main.secondary};

    & > * {
      color: ${({ theme }) => theme.tertiary};
    }
  }
  &:active {
    opacity: 0.4;
  }
`

const SuggestThemes = ({ disabled, count, onClick }) => {
  if (disabled || !count) return null // Hide if no suggestions available or feature disabled
  return (
    <ComponentRoot onClick={() => onClick()}>
      <Typography variant="heading4" secondary>
        {(count > 1) ? `Suggest ${count} themes` : 'Suggest a theme'}
      </Typography>
    </ComponentRoot>
  )
}

export default SuggestThemes
