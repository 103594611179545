import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Card from '../../common/Card/Card'
import Icon from '../../common/Icon/Icon'
import Typography from '../../common/Typography/Typography'
import Button from '../../common/Button/Button'


const ComponentRoot = styled.div`
  width: 650px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  
  & > :not(:last-child) {
    margin-right: 14px;
  }
`

class TerminateAccountModal extends Component {
  render() {
    const { onClose } = this.props

    return (
      <div>
        <ComponentRoot>
          <Card>
            <Card.Header>
              <Typography variant="heading3">Account termination</Typography>
              <Icon clickable variant="times" onClick={onClose} />
            </Card.Header>
            <Card.Body>
              <div>
                <Typography weight="light">Terminating your account is an</Typography>
                {' '}
                <Typography weight="bold">irrevocable action.</Typography>
              </div>
              <div>
                <Typography weight="light">Please contact us via </Typography>
                <Typography clickable weight="bold" onClick={this.handleMailClick}>support@qvest.io</Typography>
                <Typography weight="light"> or our built-in chat (lower-right corner) to confirm your decision and initiate the deletion process.</Typography>
              </div>
            </Card.Body>
            <Card.Message error>
              <Typography tertiary>If you go through with your request, it will result in complete deletion of your account details and all associated qvests within 30 days.</Typography>
            </Card.Message>
            <Card.Body>
              <ButtonGroup>
                <Button size="large" onClick={onClose}>OK</Button>
              </ButtonGroup>
            </Card.Body>
          </Card>
        </ComponentRoot>
      </div>
    )
  }

  handleMailClick() {
    window.location.href = 'mailto:support@qvest.io'
  }
}

TerminateAccountModal.propTypes = {
  onClose: PropTypes.func
}

TerminateAccountModal.defaultProps = {
  onClose: () => {}
}

export default TerminateAccountModal
