import React, { Component } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Card from '../../common/Card/Card'
import Heading from '../../common/Typography/Heading'
import Tooltip from '../../common/Tooltip/Tooltip'
import Typography from '../../common/Typography/Typography'
import Button from '../../common/Button/Button'
import Icon from '../../common/Icon/Icon'
import LastUpdateStatus from '../LastUpdateStatus/LastUpdateStatus'
import SchedulingTimeline from '../SchedulingTimeline/SchedulingTimeline'
import ScheduleForm from './ScheduleForm'


const MinWidth = styled.div`
  min-width: 1350px;
`

const Body = styled.div`
  padding: 50px 70px 40px 70px;

  & > :first-child {
    margin-bottom: 20px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`

const ButtonFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`


class ScheduleCard extends Component {
  renderHeaderText() {
    const caption = (
      <Tooltip.Area>
        <Tooltip.Reference>
          <Heading variant="heading3" primary>
            <FormattedMessage
              id="owner.QvestScheduleTab.schedule.heading2"
              defaultMessage="Schedule"
            />
          </Heading>
        </Tooltip.Reference>
        <Tooltip wide>
          <Heading.h3>
            <FormattedMessage
              id="owner.QvestScheduleTab.schedule.helpTooltip.heading"
              defaultMessage="Scheduling"
            />
          </Heading.h3>
          <Typography.p variant="small" weight="light">
            <FormattedMessage
              id="owner.QvestScheduleTab.schedule.helpTooltip.content1"
              defaultMessage="A Qvest has 3 phases."
            />
          </Typography.p>
          <Typography.p variant="small" weight="light">
            <FormattedMessage
              id="owner.QvestScheduleTab.schedule.helpTooltip.content2"
              defaultMessage="The first phase starts when you send out the invitation email with a link to your Qvest Page."
            />
            <br />
            <FormattedMessage
              id="owner.QvestScheduleTab.schedule.helpTooltip.content3"
              defaultMessage="The second phase starts when the Qvest opens and your participants exchange questions and answers."
            />
            <br />
            <FormattedMessage
              id="owner.QvestScheduleTab.schedule.helpTooltip.content4"
              defaultMessage="The third phase starts when the Qvest ends and your participants get access to the Output page for 14 days."
            />
          </Typography.p>
          <Typography.p variant="small" weight="light">
            <FormattedMessage
              id="owner.QvestScheduleTab.schedule.helpTooltip.content5"
              defaultMessage="If you choose not to send out an invitation email, you simply skip the first phase."
            />
          </Typography.p>
        </Tooltip>
      </Tooltip.Area>
    )
    return (
      <span>
        {caption}
        <Heading variant="heading3">
          {' '}
          <FormattedMessage defaultMessage="your Qvest" id="owner.QvestScheduleTab.schedule.heading1" />
        </Heading>
      </span>
    )
  }

  renderFooterText(hasErrors, state) {
    let text = ''
    let icon = null
    if (state) {
      switch (state) {
        case 'QVEST_SCHEDULED':
          text = 'Qvest scheduled'
          break
        case 'QVEST_ONBOARDING_STARTED':
          text = 'Qvest invitations sent'
          break
        case 'QVEST_OPENED':
          text = 'Qvest opened'
          break
        case 'QVEST_CLOSED':
          text = 'Qvest closed'
          break
        case 'QVEST_EXPIRED':
          text = 'Qvest expired'
      }
      if (text) {
        icon = <Icon variant="check-circle" ok />
      }
    } else if (hasErrors) {
      text = 'Errors need to be resolved before the Qvest can be scheduled.'
      icon = <Icon variant="exclamation-circle" error />
    }
    return (
      <div>
        {icon}
        {' '}
        <Typography variant="small" weight="light">{text}</Typography>
      </div>
    )
  }

  renderSubmitButton(isReady) {
    const { qvest, onSubmit } = this.props
    const isScheduled = (qvest.state === 'QVEST_SCHEDULED')
    const disabled = !isScheduled && (!isReady || !!qvest.state)
    let text
    if (isScheduled) {
      text = (<FormattedMessage id="owner.QvestScheduleTab.unscheduleCta" defaultMessage="Unschedule Qvest" />)
    } else {
      text = (<FormattedMessage id="owner.QvestScheduleTab.scheduleCta" defaultMessage="Schedule Qvest" />)
    }
    return (
      <Button
        outline={isScheduled}
        disabled={disabled}
        onClick={(isReady || isScheduled) ? onSubmit : null}
      >
        {text}
      </Button>
    )
  }

  render() {
    const {
      qvest,
      schedule,
      generalValidity,
      scheduleValidity,
      lastUpdate,
      onChange,
      onSendTest
    } = this.props

    if (!schedule || !qvest) {
      return null
    }

    const hasErrors = (generalValidity === 'ERROR')
    const isReady = (!hasErrors) && (scheduleValidity.general !== 'ERROR')

    return (
      <MinWidth>
        <Card>
          <Card.Header>
            {this.renderHeaderText()}
            <ButtonGroup>
              <LastUpdateStatus lastUpdate={lastUpdate} />
            </ButtonGroup>
          </Card.Header>
          <Body>
            <SchedulingTimeline
              isInviteEnabled={qvest.hasInvite}
              inviteAt={schedule.inviteAt}
              openAt={schedule.openAt}
              closeAt={schedule.closeAt}
            />
            <ScheduleForm
              hasInvite={qvest.hasInvite}
              hasWaves={qvest.hasWaves}
              hasTestOption={isReady}
              schedule={schedule}
              qvestState={qvest.state}
              onChange={onChange}
              onSendTest={onSendTest}
            />
            <ButtonFooter>
              {this.renderFooterText(hasErrors, qvest.state)}
              {this.renderSubmitButton(isReady)}
            </ButtonFooter>
          </Body>
        </Card>
      </MinWidth>
    )
  }
}

export default ScheduleCard
