import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { msToHour, hourToMs } from '../../../utils/timeUtils'
import Typography from '../Typography/Typography'

const RE_NUMBER = new RegExp('^[0-9]{0,2}$')
const RE_HOUR = new RegExp('^[0-1][0-9]$|^2[0-3]$')

const ComponentRoot = styled.div`
  display: flex;
  align-items: center;
  & > * + * { margin-left: 10px; }
`

const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.two[1]};
  border-radius: 4px;
  width: 55px;
  height: 45px;
  padding-left: 15px;
  font-family: ${({ theme }) => theme.font2};
  font-size: 18px;
  line-height: 1em;
  color: ${({ theme }) => theme.one[0]};

  &:focus, &:hover:not(:disabled) {
    border-color: ${({ theme }) => theme.color[0]};
    outline: none;
  }

  &:disabled {
    background: ${({ theme }) => theme.two[0]};
    color: ${({ theme }) => theme.one[2]};
    user-select: none;
  }
`

const validateInput = text => !RE_NUMBER.exec(text)

const coerce = (val, min, max) => Math.max(Math.min(max, val), min)

const addTrailingZeroes = num => {
  return (num.toString().length === 1) ? `0${num}` : `${num}`
}

const addTrailingZeroesToHour = ({ hours, minutes }) => ({
  hours: addTrailingZeroes(hours),
  minutes: addTrailingZeroes(minutes)
})


class HourPicker extends Component {
  constructor(props) {
    super(props)
    const { value, lowerBound, upperBound } = props
    const validValue = coerce(value, lowerBound, upperBound)
    this.state = addTrailingZeroesToHour(msToHour(validValue))
  }

  componentDidUpdate(prevProps) {
    // update on prop change
    const value = this.props.value
    if (value !== prevProps.value) {
      const { value, lowerBound, upperBound } = this.props
      const validValue = coerce(value, lowerBound, upperBound)
      this.setState(addTrailingZeroesToHour(msToHour(validValue)))
    }
  }

  // validate input format on change
  handleMinutesChange = event => {
    if (validateInput(event.target.value)) return null
    this.setState({ minutes: event.target.value })
  }

  handleHoursChange = event => {
    const hours = event.target.value
    if (validateInput(hours)) return null
    this.setState({ hours }, () => {
      if (RE_HOUR.exec(hours)) this.minuteNode.select()
    })
  }

  // validate range on blur
  handleBlur = () => {
    const { onChange, upperBound, lowerBound } = this.props
    const { hours: currHours, minutes: currMinutes } = this.state
    // coerce parses strings to numbers
    const ms = hourToMs(
      coerce(currHours, 0, 23),
      coerce(currMinutes, 0, 59),
    )
    const validMs = coerce(ms, lowerBound, upperBound)
    const { hours, minutes } = addTrailingZeroesToHour(msToHour(validMs))
    this.setState({ hours, minutes })
    onChange(validMs)
  }

  // jump back to hour node when deleting empty input
  handleBackspace = (event) => {
    if (!this.state.minutes && event.keyCode == 8) {
      this.hourNode.select()
    }
  }

  render() {
    const { hours, minutes } = this.state
    return (
      <ComponentRoot>
        <Input
          type="text"
          value={hours}
          disabled={this.props.disabled}
          onChange={this.handleHoursChange}
          onBlur={this.handleBlur}
          ref={x => { this.hourNode = x }}
        />
        <Typography variant="caption">:</Typography>
        <Input
          type="text"
          value={minutes}
          disabled={this.props.disabled}
          onChange={this.handleMinutesChange}
          onBlur={this.handleBlur}
          ref={x => { this.minuteNode = x }}
          onKeyDown={this.handleBackspace}
        />
      </ComponentRoot>
    )
  }

  static propTypes = {
    value: PropTypes.number,
    disabled: PropTypes.bool,
    upperBound: PropTypes.number,
    lowerBound: PropTypes.number,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    value: 0,
    disabled: false,
    upperBound: 3600 * 24 * 1000 - 1,
    lowerBound: 0,
    onChange: () => { },
  }
}

export default HourPicker
