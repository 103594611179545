import Immutable from 'immutable'


let INITIAL_STATE = Immutable.fromJS({
  isLoading: false,
  latestQueryId: null, // Keep track of queries to sustain loading flag until there are no more pending queries
  suggestions: null
})


const topic = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'QUERY_TOPIC_SUGGESTIONS_REQUEST':
      return state.merge({
        isLoading: true,
        latestQueryId: action.queryId
      })
    case 'QUERY_TOPIC_SUGGESTIONS_SUCCESS':
      return state.merge({
        isLoading: (action.queryId !== state.get('latestQueryId')),
        suggestions: action.suggestions
      })
    case 'QUERY_TOPIC_SUGGESTIONS_FAILURE':
      return state.merge({
        isLoading: (action.queryId !== state.get('latestQueryId'))
      })
    default:
      return state
  }
}


export default topic
