import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import styled from 'styled-components'

import { isWhitespace } from '../../../../utils/stringUtils'
import Typography from '../../../common/Typography/Typography'
import Input from '../../../common/Input/Input'
import Card from '../../../common/Card/Card'
import EditableSpan from '../../../common/EditableSpan/EditableSpan'
import FileDrop from '../../../common/FileDrop/FileDrop'
import ImgIconSvg from '../../../../static/img/img-icon.svg'
import Modal from '../../../common/Modal/Modal'
import UnlockLogoModal from './UnlockLogoModal'
import FailedLogoUploadModal from './FailedLogoUploadModal'

const SettingBlock = styled.div`
  max-width: ${({ narrow }) => narrow ? 'calc(100% - 280px)' : '100%'};
  & > * { display: block; }
`

const RelativeCardBody = styled(Card.Body)`
  position: relative;
  top: 0;
  left: 0;
  & > :not(:first-child) {
    margin-top: 20px;
  }
`

const LogoImg = styled.img`
  position: absolute;
  top: 0;
  right: 20px;
  max-height: 130px;
  max-width: 230px;
`

const LogoPlaceholder = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  height: 130px;
  width: 230px;
  background-color: #F6F6F3;
  background-image: url(${ImgIconSvg});
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid ${({ theme }) => theme.one[3]};
`

const MasterUserBlock = ({ user }) => {
  if (!user) return null
  const { fullName, email } = user
  return (
    <SettingBlock narrow>
      <Typography weight="bold" paragraph>
        <FormattedMessage id="owner.OrganizationSettingsPage.master" defaultMessage="Master user" />
      </Typography>
      <Typography variant="small">{fullName} ({email})</Typography>
    </SettingBlock>
  )
}

const Logo = ({ organization, hasLogoUpload, isOver, onFileChange, onUnlockClick }) => {
  let previewImg
  if (organization.logo) {
    const cacheBreaker = organization.logoUploadedAt || ''
    previewImg = (<LogoImg src={`${organization.logo}?${cacheBreaker}`} alt={organization.name} />)
  } else {
    previewImg = (<LogoPlaceholder />)
  }
  return (
    <Fragment>
      <SettingBlock>
        <Typography weight="bold" paragraph>
          <FormattedMessage id="owner.OrganizationSettingsPage.logo" defaultMessage="Logo" />
        </Typography>
        <div>
          <FileDrop
            locked={!hasLogoUpload}
            isOver={isOver}
            fileType="image"
            onFileChange={onFileChange}
            onUnlock={onUnlockClick}
          />
        </div>
      </SettingBlock>
      {previewImg}
    </Fragment>
  )
}

const Modals = ({ show, error, onClose, onPricingClick }) => {
  let modal
  switch (show) {
    case 'unlock':
      modal = (
        <UnlockLogoModal onClose={onClose} onSubmit={onPricingClick} />
      )
      break
    case 'upload-error':
      modal = (
        <FailedLogoUploadModal error={error} onClose={onClose} onSubmit={onClose} />
      )
  }
  if (!modal) return null
  return (
    <Modal onBackgroundClick={onClose} onEscape={onClose}>
      {modal}
    </Modal>
  )
}

const OrganizationSettingsCard = props => {
  const { organization, masterUser, hasLogoUpload, onNameChange, onPricingClick, onLogoFileChange } = props
  const [modal, setModal] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [uploadError, setUploadError] = useState(null)
  const [unsavedName, setUnsavedName] = useState(organization.name)

  const [{ isOver }, drop] = useDrop({
    accept: NativeTypes.FILE,
    collect(monitor) {
      return { isOver: monitor.isOver() }
    },
    drop(item) {
      onLogoFileChange(item.files[0]).catch(error => showUploadErrorModal(error))
    }
  })

  const showUnlockModal = () => {
    setModal('unlock')
  }

  const showUploadErrorModal = (error) => {
    setUploadError(error)
    setModal('upload-error')
  }

  const hideModal = () => {
    setModal(null)
  }

  const handleStartEditName = () => {
    setIsEditing(true)
  }

  const handleCancelEditName = () => {
    setIsEditing(false)
    setUnsavedName(organization.name)
  }

  const handleNameChange = (event) => {
    setUnsavedName(event.target.value)
  }

  const handleDoneEditName = () => {
    if (isWhitespace(unsavedName)) {
      handleCancelEditName()
      return null
    }
    onNameChange(unsavedName)
    setIsEditing(false)
  }

  const handlePricingClick = () => {
    showUnlockModal()
    onPricingClick()
  }

  const handleLogoFileChange = (...args) => {
    onLogoFileChange(...args).catch(error => showUploadErrorModal(error))
  }

  return (
    <div ref={drop}>
      <Card>
        <Card.Header>
          <Typography variant="heading3">
            <FormattedMessage id="owner.OrganizationSettingsPage.title" defaultMessage="Organization" />
          </Typography>
        </Card.Header>
        <RelativeCardBody>
          <SettingBlock narrow>
            <Typography weight="bold" paragraph>
              <FormattedMessage id="owner.OrganizationSettingsPage.orgName" defaultMessage="Organization name" />
            </Typography>
            <EditableSpan
              isEditing={isEditing}
              onStartEdit={handleStartEditName}
              onDone={handleDoneEditName}
              onCancel={handleCancelEditName}
            >
              <EditableSpan.View>
                <Typography variant="small">{organization.name}</Typography>
              </EditableSpan.View>
              <EditableSpan.Edit>
                <Input autoFocus value={unsavedName} onChange={handleNameChange} />
              </EditableSpan.Edit>
            </EditableSpan>
          </SettingBlock>
          <MasterUserBlock user={masterUser} />
          <Logo
            organization={organization}
            hasLogoUpload={hasLogoUpload}
            isOver={isOver}
            onFileChange={handleLogoFileChange}
            onUnlockClick={showUnlockModal}
          />
        </RelativeCardBody>
      </Card>
      <Modals
        show={modal}
        error={uploadError}
        onClose={hideModal}
        onPricingClick={handlePricingClick}
        onUploadError={showUploadErrorModal}
      />
    </div>
  )
}

OrganizationSettingsCard.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  masterUser: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string
  }),
  hasLogoUpload: PropTypes.bool,
  onNameChange: PropTypes.func,
  onLogoFileChange: PropTypes.func,
  onPricingClick: PropTypes.func
}

OrganizationSettingsCard.defaultProps = {
  onNameChange: () => {
  },
  onLogoFileChange: () => {
  },
  onPricingClick: () => {
  }
}

export default OrganizationSettingsCard
