import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

import Icon from '../../common/Icon/Icon'
import Typography from '../../common/Typography/Typography'
import Card from '../../common/Card/Card'
import Button from '../../common/Button/Button'
import FileDrop from '../../common/FileDrop/FileDrop'


const ComponentRoot = styled.div`
  width: 650px;
`

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
  & > *:last-child { margin-top: 5px; }
`

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${props => props.theme.two[1]};
  border-radius: 8px;
  & > div {
    height: 100%;
    width: ${props => `${props.progress}%`};
    background-color: ${props => props.theme.main.primary};
    border-radius: 3px;
    transition: width 1s;
  }
`

const FileDropWrapper = styled.div`
  margin: 20px 0;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 6px;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`


const ErrorMessage = ({ error }) => {
  if (!error) return null
  let message = (
    <FormattedMessage
      id="owner.UploadVideoModal.error"
      defaultMessage="An error occured when uploading your video. Please try again or contact us."
    />
  )
  if (error.length && error.some(e => e.message === 'FILE_TOO_LARGE')) {
    message = (
      <FormattedMessage
        id="owner.UploadVideoModal.error.fileSize"
        defaultMessage="Video file too large. We only accept files below 500mb. Contact us to learn more."
      />
    )
  }
  if (error.length && error.some(e => e.message === 'NO_ACTIVE_SUBSCRIPTION')) {
    message = (
      <FormattedMessage
        id="owner.UploadVideoModal.error.subscription"
        defaultMessage="Your Qvest subscription has expired. The video upload functionality is only available to organizations with an active subscription."
      />
    )
  }
  return (
    <Card.Message error>
      <Typography tertiary>
        {message}
      </Typography>
    </Card.Message>
  )
}

const TranscodingStatus = () => (
  <ProgressWrapper>
    <div>
      <Typography weight="bold">
        <FormattedMessage
          id="owner.UploadVideoModal.transcoding.title"
          defaultMessage="Processing video"
        />
      </Typography>
    </div>
    <Typography as="p" weight="light">
      <FormattedMessage
        id="owner.UploadVideoModal.transcoding.description1"
        defaultMessage="This usually takes less than 10 minutes. Your video has been transfered. You may close this window and return later."
      />
    </Typography>
  </ProgressWrapper>
)

const MainButton = ({ transcoding, file, onClose }) => {
  let text = (<FormattedMessage defaultMessage="Cancel" id="owner.UploadVideoModal.button.cancel" />)
  if (transcoding) {
    text = (<FormattedMessage defaultMessage="Ok" id="owner.UploadVideoModal.button.confirm" />)
  }
  return (
    <Button outline={!transcoding} disabled={file && !transcoding} onClick={onClose}>{text}</Button>
  )
}

const UploadVideoModal = props => {
  const { onClose, onFileChange, file, transcoding, error, uploadedBytes } = props

  const [{ isOver }, drop] = useDrop({
    accept: NativeTypes.FILE,
    collect(monitor) {
      return { isOver: monitor.isOver() }
    },
    drop(item) {
      onFileChange(item.files[0])
    }
  })

  let content
  if (file) {
    const megaBytes = file.size / 1000000
    const displayedSize = (megaBytes > 1000)
      ? `${(megaBytes / 1000).toFixed(1)}GB`
      : `${(megaBytes).toFixed(0)}MB`
    const progress = Math.floor(uploadedBytes / file.size * 100)
    content = (
      <ProgressWrapper>
        <div>
          <Typography weight="bold">
            <FormattedMessage
              id="owner.UploadVideoModal.progress"
              defaultMessage="Uploading video"
              values={{ fileName: file.name, size: displayedSize }}
            />
          </Typography>
        </div>
        <Typography weight="light">{progress}%</Typography>
        <ProgressBar progress={progress}><div /></ProgressBar>
      </ProgressWrapper>
    )
  } else if (transcoding) {
    content = <TranscodingStatus />
  } else {
    content = (
      <FileDropWrapper>
        <FileDrop fileType="video" onFileChange={onFileChange} isOver={isOver} />
      </FileDropWrapper>
    )
  }

  return (
    <ComponentRoot ref={drop}>
      <Card>
        <Card.Header>
          <Typography variant="heading3">
            <FormattedMessage
              id="owner.UploadVideoModal.title"
              defaultMessage="Upload video"
            />
          </Typography>
          <Icon clickable variant="times" onClick={onClose} />
        </Card.Header>
        <ErrorMessage error={error} />
        <Card.Body>
          <Typography as="p" weight="light">
            <FormattedMessage
              id="owner.UploadVideoModal.description"
              defaultMessage="Adding an optional video is an easy way to create a more engaging Qvest page. We recommend your video to be between 30 and 90 seconds."
            />
          </Typography>
          {content}
          <ButtonGroup>
            <MainButton transcoding={transcoding} file={file} onClose={onClose} />
          </ButtonGroup>
        </Card.Body>
      </Card>
    </ComponentRoot>
  )
}

UploadVideoModal.propTypes = {
  onClose: PropTypes.func,
  onFileChange: PropTypes.func,
  file: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
  })
}

UploadVideoModal.defaultProps = {
  onClose: () => { },
  onFileChange: () => { },
}

export default UploadVideoModal
