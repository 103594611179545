import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Icon from '../../common/Icon/Icon'
import Input from '../../common/Input/Input'
import Typography from '../../common/Typography/Typography'
import { isValidEmailAddress  } from '../../../utils/validationUtils'
import { Row, HoverIcons } from '../../common/Table/Table'
import Button from '../../common/Button/Button'


const CellInput = styled(Input)`
  height: 38px;
  margin-left: -9px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`

const CellIcon = styled(Icon)`
  margin-right: 7px;
  user-select: none;
`

const IconButton = styled(Button)`
  & > * {
    display: block;
    width: 14px;
  }
`


class EditableRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInput: false,
      name: props.participant.name,
      email: props.participant.email,
    }
    this.handleShowInput = this.handleShowInput.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleResend = this.handleResend.bind(this)
    this.handleSuspend = this.handleSuspend.bind(this)
    this.handleUndo = this.handleUndo.bind(this)
    this.handleClickAnywhere = this.handleClickAnywhere.bind(this)
    this.handleKeyboardEvent = this.handleKeyboardEvent.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleStatusClick = this.handleStatusClick.bind(this)
  }

  componentDidMount() {
    const { showInput } = this.props
    if (showInput) {
      this.handleShowInput()
    }
  }

  componentWillUnmount() {
    this.cleanupEventListeners()
  }

  handleKeyboardEvent(event) {
    if (event.key === 'Enter') {
      event.stopPropagation()
      this.handleEdit()
    } else if (event.key === 'Escape') {
      event.stopPropagation()
      this.handleCancel()
    }
  }

  handleClickAnywhere(event) {
    if (this.node && !this.node.contains(event.target)) {
      // if clicking outside input row
      event.stopPropagation()
      this.handleCancel()
    }
  }

  handleShowInput(column) {
    this.setState({ showInput: true, clickedColumn: column })
    document.addEventListener('keyup', this.handleKeyboardEvent)
    document.addEventListener('click', this.handleClickAnywhere)
  }

  cleanupEventListeners() {
    document.removeEventListener('keyup', this.handleKeyboardEvent)
    document.removeEventListener('click', this.handleClickAnywhere)
  }

  handleCancel() {
    const { participant: { name, email }, onCancel } = this.props
    this.setState({ name, email, showInput: false })
    this.cleanupEventListeners()
    onCancel()
  }

  handleEdit() {
    const {
      onEdit,
      onError,
      participant: {
        participantId,
        name: originalName,
        email: originalEmail,
      },
    } = this.props
    const { name, email } = this.state
    // mild input validation
    if (!name) return onError('INVALID_NAME')
    if (!isValidEmailAddress(email)) return onError('INVALID_EMAIL')
    this.setState({ showInput: false })
    this.cleanupEventListeners()
    // check if inputs have changed
    if (originalEmail !== email || originalName !== name) {
      onEdit(name, email, participantId)
    }
  }

  handleRemove(event) {
    event.stopPropagation()
    const { participant, onRemove } = this.props
    onRemove(participant)
  }

  handleResend(event) {
    event.stopPropagation()
    const { participant, onResend } = this.props
    onResend(participant)
  }

  handleSuspend(event) {
    event.stopPropagation()
    const { participant, onSuspend } = this.props
    onSuspend(participant)
  }

  handleUndo(event) {
    event.stopPropagation()
    const { participant, onUndo } = this.props
    if (onUndo) {
      onUndo(participant)
    }
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value })
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  handleStatusClick(event) {
    event.stopPropagation()
    const { participant, status, onStatusClick } = this.props
    if (onStatusClick) {
      onStatusClick(participant, status.name)
    }
  }

  renderStatus() {
    const { status } = this.props
    if (status) {
      return (
        <CellIcon
          error={status.type === 'error'}
          warning={status.type === 'warning'}
          variant={status.icon}
          title={status.message}
          clickable={status.clickable}
          onClick={status.clickable ? this.handleStatusClick : undefined}
        />
      )
    }
  }

  render() {
    const { name, email, showInput, clickedColumn } = this.state
    const { onEdit: allowEdit, onRemove: allowRemove, onSuspend: allowSuspend, onUndo: allowUndo, onResend: allowResend, participant, status } = this.props
    const isEmailFocused = (clickedColumn === 'email')
    const noAction = () => { }
    const onClickName = (allowEdit ? () => this.handleShowInput('name') : noAction)
    const onClickEmail = (allowEdit ? () => this.handleShowInput('email') : noAction)

    if (showInput) {
      return (
        <tr ref={x => { this.node = x }}>
          <td><CellInput value={name} onChange={this.handleNameChange} autoFocus={!isEmailFocused} /></td>
          <td><CellInput value={email} onChange={this.handleEmailChange} autoFocus={isEmailFocused} /></td>
          <td>
            <HoverIcons>
              <IconButton size="tiny" onClick={this.handleEdit} title="Save">
                <Icon clickable tertiary variant="check" size="small" />
              </IconButton>
              <IconButton secondary size="tiny" onClick={this.handleCancel} title="Cancel">
                <Icon clickable tertiary variant="times" size="small" />
              </IconButton>
            </HoverIcons>
          </td>
        </tr>
      )
    }

    let editButton
    if (allowEdit) {
      editButton = (
        <IconButton size="tiny" onClick={onClickName} title="Edit">
          <Icon clickable tertiary variant="edit" size="small" />
        </IconButton>
      )
    }

    let removeButton
    if (allowRemove) {
      removeButton = (
        <IconButton secondary size="tiny" onClick={this.handleRemove} title="Remove">
          <Icon clickable tertiary variant="trash" size="small" />
        </IconButton>
      )
    }

    let resendButton
    if (allowResend) {
      resendButton = (
        <IconButton size="tiny" onClick={this.handleResend} title="Resend mail">
          <Icon clickable tertiary variant="envelope" size="small" />
        </IconButton>
      )
    }

    let suspendButton
    if (allowSuspend) {
      suspendButton = (
        <IconButton secondary size="tiny" onClick={this.handleSuspend} title="Suspend">
          <Icon clickable tertiary variant="ban" size="small" />
        </IconButton>
      )
    }

    let undoButton
    if (allowUndo) {
      undoButton = (
        <IconButton secondary size="tiny" onClick={this.handleUndo} title="Unsuspend">
          <Icon clickable tertiary variant="undo" size="small" />
        </IconButton>
      )
    }

    let groupName
    let groupingConnections = participant.groupingConnections
    if (status && (status.name === 'duplicate') && groupingConnections && (groupingConnections.length > 0)) {
      groupName = groupingConnections[0].groupName
    }

    return (
      <Row
        clickable={allowEdit || allowUndo}
        disabled={participant.isSuspended}
      >
        <td onClick={onClickName}>{name}</td>
        <td onClick={onClickEmail}>
          {this.renderStatus()}
          <span>{email}</span>
          {groupName ? <Typography variant="medium" secondary> (Group: {groupName})</Typography> : null}
        </td>
        <td>
          <HoverIcons>
            {editButton}
            {removeButton}
            {resendButton}
            {suspendButton}
            {undoButton}
          </HoverIcons>
        </td>
      </Row>
    )
  }
}

EditableRow.propTypes = {
  participant: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    participantId: PropTypes.string,
  }),
  onRemove: PropTypes.func,
  onSuspend: PropTypes.func,
  onResend: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onUndo: PropTypes.func,
  onStatusClick: PropTypes.func,
  onError: PropTypes.func,
  showInput: PropTypes.bool,
  isDuplicate: PropTypes.bool,
}

EditableRow.defaultProps = {
  participant: {
    name: '',
    email: '',
  },
  onRemove: null,
  onSuspend: null,
  onEdit: null,
  onCancel: () => { },
  onError: () => { },
  onUndo: null,
  showInput: false,
  isDuplicate: false
}

export default EditableRow
