import * as api from './apiClient'
import { determineQuestionStatus } from '../utils/questionUtils'

const questionFields = `
  questionId
  createdAt
  questionContent
  questionSentAt
  questionReadAt
  questionParticipantId
  answerContent
  answerSentAt
  answerReadAt
  answerParticipantId
  perspective
  isFirstQuestion
`

function parseQuestionDates(question) {
  const parsedQuestion = Object.assign({}, question)
  if (question.hasOwnProperty('createdAt')) {
    parsedQuestion.createdAt = new Date(question.createdAt)
  }
  if (question.hasOwnProperty('questionSentAt')) {
    parsedQuestion.questionSentAt = new Date(question.questionSentAt)
  }
  if (question.hasOwnProperty('answerSentAt')) {
    parsedQuestion.answerSentAt = new Date(question.answerSentAt)
  }
  return parsedQuestion
}

export const getOwnQuestions = qvestId => dispatch => {
  dispatch({ type: 'GET_QUESTIONS_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        query q($filter:QuestionSearchFilter!) {
          questions(filter:$filter) {
            items {
              ${questionFields}
            }
          }
        }
      `,
      variables: {
        filter: { qvestId, involvesSelf: true, allowEmpty: true }
      }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(({ data: { questions } }) => {
      let data = {}
      questions.items.forEach(question => {
        question = parseQuestionDates(question)
        question.status = determineQuestionStatus(question)
        data[question.questionId] = question
      })
      const ids = questions.items.map(q => q.questionId)
      dispatch({ type: 'GET_QUESTIONS_SUCCESS', ids, data, qvestId })
    })
    .catch(error => {
      dispatch({ type: 'GET_QUESTIONS_FAILURE', error })
    })
}

export const sendQuestion = (qvestId, questionId, { content, recipientId }) => dispatch => {
  dispatch({ type: 'SEND_QUESTION_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        mutation m($questionId:String!, $content:String!, $recipientId:String!) {
          sendQuestion(questionId:$questionId, content:$content, recipientId:$recipientId) {
            ${questionFields}
          }
        }
      `,
      variables: { questionId, content, recipientId }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(response => {
      const question = response.data.sendQuestion
      question.status = determineQuestionStatus(question)
      dispatch({ type: 'SEND_QUESTION_SUCCESS', qvestId, question })
    })
    .catch(error => {
      dispatch({ type: 'SEND_QUESTION_FAILURE', error })
      throw error
    })
}

export const sendAnswer = (qvestId, questionId, { content }) => dispatch => {
  dispatch({ type: 'SEND_ANSWER_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        mutation m($questionId:String!, $content:String!) {
          sendAnswer(questionId:$questionId, content:$content) {
            oldQuestion {
              ${questionFields}
            }
            newQuestion {
              ${questionFields}
            }
          }
        }
      `,
      variables: { questionId, content }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(response => {
      const oldQuestion = response.data.sendAnswer.oldQuestion
      const newQuestion = response.data.sendAnswer.newQuestion
      oldQuestion.status = determineQuestionStatus(oldQuestion)
      newQuestion.status = determineQuestionStatus(newQuestion)
      dispatch({ type: 'SEND_ANSWER_SUCCESS', qvestId, oldQuestion, newQuestion })
    })
    .catch(error => {
      dispatch({ type: 'SEND_ANSWER_FAILURE', error })
      throw error
    })
}

export const markAsRead = questionId => dispatch => {
  dispatch({ type: 'MARK_QUESTION_READ_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          mutation q($questionId:String!) {
            markQuestionAsRead(questionId:$questionId) {
              ${questionFields}
            }
          }
        `,
      variables: { questionId }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(({ data }) => {
      let question = data.markQuestionAsRead
      question.status = determineQuestionStatus(question)
      dispatch({ type: 'MARK_QUESTION_READ_SUCCESS', question })
      return question
    })
    .catch(error => {
      dispatch({ type: 'MARK_QUESTION_READ_FAILURE', error })
    })
}

export const searchQuestions = parameters => dispatch => {
  dispatch({ type: 'SEARCH_QUESTIONS_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          query q($filter:QuestionSearchFilter!) {
            questions(filter:$filter) {
              items {
                questionId
                createdAt
                questionContent
                questionSentAt
                questionGroupId
                answerContent
                answerSentAt
                answerGroupId
                isFirstQuestion
              }
            }
          }
        `,
      variables: {
        filter: parameters
      }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(({ data: { questions } }) => {
      let data = {}
      questions.items = questions.items.map(question => {
        question = parseQuestionDates(question)
        question.status = determineQuestionStatus(question)
        data[question.questionId] = question
        return question
      })
      const ids = questions.items.map(q => q.questionId)
      dispatch({ type: 'SEARCH_QUESTIONS_SUCCESS', parameters, data, ids })
      return questions.items
    })
    .catch(error => {
      dispatch({ type: 'SEARCH_QUESTIONS_FAILURE', error })
    })
}
