import React, { Fragment } from 'react'
import styled from 'styled-components'
import { FormattedMessage, FormattedRelative, injectIntl } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Icon from '../../common/Icon/Icon'


const ComponentRoot = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.inverted.two[1]};
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
  padding: 14px 24px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const IconSection = styled.div`
  flex: 0 0 30px;
  padding-top: 7px;

  & > span {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    flex: 0 0 40px;
  }
`

const ContentSection = styled.div`
  flex: 0 1 600px;

  & > p {
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    flex: 1 0 100px;
    margin-bottom: 22px;
  }
`

const StatusSection = styled.div`
  flex: 1 0 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > :not(:last-child) {
    margin: 0 0 20px 0;
  }

  @media (max-width: 600px) {
    flex: 0 0 46px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    & > :not(:last-child) {
      margin: 0 15px 0 0;
    }
  }
`

const StatusField = styled.div`
  text-align: right;

  & > * {
    display: block;
  }
`

const StatusIconValue = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;

  & > :not(:last-child) {
    margin-right: 6px;
  }
`


const PinnedQuestionsLink = ({ pinCount, onClick }) => (
  <Typography clickable cta variant="medium" weight="bold" onClick={onClick}>
    <FormattedMessage
      id="result.PinInfoCard.PinnedQuestionsLink"
      defaultMessage="pinned {count} {count, plural, one {question} other {questions}}"
      values={{ count: pinCount }}
    />
  </Typography>
)

const PinsLeftStrong = ({ pinCount }) => {
  if (pinCount > 2) {
    return (
      <strong>
        <FormattedMessage
          id="result.PinInfoCard.PinsLeftStrong.none"
          defaultMessage="no more pins left"
        />
      </strong>
    )
  }
  return (
    <strong>
      <FormattedMessage
        id="result.PinInfoCard.PinsLeftStrong.oneOrMore"
        defaultMessage="{count} {count, plural, one {pin} other {pins}} left"
        values={{ count: (3 - pinCount) }}
      />
    </strong>
  )
}

const PinProgressText = ({ pinCount, pinCloseAt, onShowPinned }) => {
  const isOpen = (new Date() < pinCloseAt)
  if (isOpen && (pinCount > 0)) {
    return (
      <FormattedMessage
        id="result.PinInfoCard.PinProgressText.open.some"
        defaultMessage="You {link} and you have {pinsLeft}"
        values={{
          link: <PinnedQuestionsLink pinCount={pinCount} onClick={onShowPinned} />,
          pinsLeft: <PinsLeftStrong pinCount={pinCount} onClick={onShowPinned} />
        }}
      />
    )
  }
  if (isOpen) {
    return (
      <FormattedMessage
        id="result.PinInfoCard.PinProgressText.open.all"
        defaultMessage="You have {pinsLeft}"
        values={{
          pinsLeft: <PinsLeftStrong pinCount={pinCount} />
        }}
      />
    )
  }
  if (pinCount > 0) {
    return (
      <FormattedMessage
        id="result.PinInfoCard.PinProgressText.closed.some"
        defaultMessage="You {link}"
        values={{
          link: <PinnedQuestionsLink pinCount={pinCount} onClick={onShowPinned} />
        }}
      />
    )
  }
  return null
}

const CountStatusField = ({ pinCount, pinCloseAt }) => {
  if (new Date() > pinCloseAt) return null
  return (
    <StatusField>
      <Typography inverted variant="small" weight="regular">
        <FormattedMessage defaultMessage="Your pin status" id="result.PinInfoCard.status.pinLabel" />
      </Typography>
      <StatusIconValue>
        <Icon transparent={pinCount < 1} inverted variant="thumbtack" />
        <Icon transparent={pinCount < 2} inverted variant="thumbtack" />
        <Icon transparent={pinCount < 3} inverted variant="thumbtack" />
      </StatusIconValue>
    </StatusField>
  )
}

const TimeStatusField = injectIntl(({ pinCloseAt, isClosed, intl }) => {
  const closesStamp = `${intl.formatDate(pinCloseAt)} ${intl.formatTime(pinCloseAt)}`
  return (
    <StatusField>
      <Typography inverted variant="small" weight="regular">
        {isClosed ?
          <FormattedMessage defaultMessage="Pinning closed" id="result.PinInfoCard.TimeStatusField.closed.label" /> :
          <FormattedMessage defaultMessage="Pinning closes" id="result.PinInfoCard.TimeStatusField.open.label" />
        }
      </Typography>
      <Typography inverted variant="heading3" title={closesStamp}>
        <FormattedRelative value={pinCloseAt} style="numeric" />
      </Typography>
    </StatusField>
  )
})

const DescriptionText = ({ isClosed }) => {
  let cta
  if (isClosed) {
    cta = (
      <Fragment>
        <FormattedMessage defaultMessage="Times up! Pinning is now closed." id="result.PinInfoCard.description.closed" />
        <br />
      </Fragment>
    )
  } else {
    cta = (
      <Fragment>
        <FormattedMessage
          defaultMessage="Pin up to 3 questions to emphasize that they are important. Pinning is completely anonymous."
          id="result.PinInfoCard.description" />
        {' '}
      </Fragment>
    )
  }
  return (
    <Fragment>
      {cta}
      <FormattedMessage
        defaultMessage="The Qvest admin will be able to see the number of pins on each question."
        id="result.PinInfoCard.description.admin"
      />
    </Fragment>
  )
}

const PinInfoCard = (props) => {
  const isClosed = (props.pinCloseAt < new Date())
  return (
    <ComponentRoot>
      <IconSection>
        <Icon inverted variant="thumbtack" />
      </IconSection>
      <ContentSection>
        <Typography inverted variant="medium" weight="regular">
          <DescriptionText isClosed={isClosed} />
        </Typography>
        <Typography.p inverted variant="medium" weight="regular">
          <PinProgressText {...props} />
        </Typography.p>
      </ContentSection>
      <StatusSection>
        <CountStatusField {...props} />
        <TimeStatusField {...props} isClosed={isClosed} />
      </StatusSection>
    </ComponentRoot>
  )
}

export default PinInfoCard
