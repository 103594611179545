import { gql, useMutation } from '@apollo/client'

export const useThemeSetCreate = () => {
  const [mutate] = useMutation(gql`
    mutation m ($qvestId: String!, $name: String!) {
      createQuestionThemeSet(qvestId: $qvestId, name: $name) {
        themeSetId
        name
        alignmentRating
        label
        themes {
          name
          themeId
          createdAt
          themeSet {
            themeSetId
          }
        }
      }
    }
  `)
  return async (qvest, name) => {
    const qvestId = qvest.qvestId
    const options = {
      variables: { qvestId, name },
      // Add resulting themeSet to cached Qvest object
      update(cache, result) {
        cache.modify({
          id: cache.identify(qvest),
          fields: {
            themeSets(existingThemeSets = { items: [] }) {
              const newThemeSet = cache.writeFragment({
                data: result.data.createQuestionThemeSet,
                fragment: gql`
                  fragment NewThemeSet on ThemeSet {
                    themeSetId
                    name
                    themes {
                      themeId
                    }
                  }
                `
              })
              return {
                items: [newThemeSet, ...existingThemeSets.items]
              }
            }
          }
        })
      }
    }
    const result = await mutate(options)
    return result.data.createQuestionThemeSet
  }
}

export const useThemeSetUpdate = () => {
  const [mutate] = useMutation(gql`
    mutation m ($qvestId: String!, $themeSetId: String!, $patch: ThemeSetPatch!) {
      updateQuestionThemeSet(qvestId: $qvestId, themeSetId: $themeSetId, patch: $patch) {
        themeSetId
        name
        label
        alignmentRating
        themes {
          themeId
          name
          questionIds
          createdAt
          themeSet {
            themeSetId
          }
        }
      }
    }
  `)
  return async (qvestId, themeSetId, patch) => {
    const result = await mutate({ variables: { qvestId, themeSetId, patch } })
    return result.data.updateQuestionThemeSet
  }
}

export const useThemeSetDelete = () => {
  const [mutate] = useMutation(gql`
    mutation m ($qvestId: String!, $themeSetId: String!) {
      deleteQuestionThemeSet(qvestId: $qvestId, themeSetId: $themeSetId) {
        themeSetId
      }
    }
  `)
  return async (qvestId, themeSetId) => {
    const options = {
      variables: { qvestId, themeSetId },
      update(cache, result) {
        cache.evict(result)
      }
    }
    const result = await mutate(options)
    return result.data.deleteQuestionThemeSet
  }
}

export const useThemeSetSetActive = () => {
  const [mutate] = useMutation(gql`
    mutation m ($qvestId: String!, $themeSetId: String!) {
      setActiveQuestionThemeSet(qvestId: $qvestId, themeSetId: $themeSetId) {
          items {
              themeSetId
              alignmentRating
              label
              name
              themes {
                  themeId
                  name
                  createdAt
                  questionIds
              }
          }
      }
    }
  `)
  return async (qvest, themeSetId) => {
    const qvestId = qvest.qvestId
    const options = {
      variables: { qvestId, themeSetId },
      // Update Qvest object with resulting list of themeSets
      update(cache, result) {
        cache.modify({
          id: cache.identify(qvest),
          fields: {
            themeSets() {
              return {
                items: result.items
              }
            }
          }
        })
      }
    }
    const result = await mutate(options)
    return result.data.setActiveQuestionThemeSet
  }
}

export const useThemeCreate = () => {
  const [mutate] = useMutation(gql`
    mutation m ($themeSetId: String!, $name: String, $baseThemeId: String, $questionIds: [String]) {
      createQuestionTheme(themeSetId: $themeSetId, name: $name, baseThemeId:$baseThemeId, questionIds: $questionIds) {
        themeId
        name
        createdAt
        themeSet {
          themeSetId
          label
        }
      }
    }
  `)
  return async (themeSet, name, baseThemeId) => {
    const options = {
      variables: { name, baseThemeId, themeSetId: themeSet.themeSetId },
      update(cache, result) {
        cache.modify({
          id: cache.identify(themeSet),
          fields: {
            themes(existingThemes = []) {
              const newTheme = cache.writeFragment({
                data: result.data.createQuestionTheme,
                fragment: gql`
                  fragment NewTheme on Theme {
                    themeId
                    name
                    themeSet {
                      themeSetId
                    }
                  }
                `
              })
              return [newTheme, ...existingThemes]
            }
          }
        })
      }
    }
    const result = await mutate(options)
    return result.data.createQuestionTheme
  }
}

export const useThemeUpdate = () => {
  const [mutate] = useMutation(gql`
    mutation m ($themeId: String!, $name: String) {
      updateQuestionTheme(themeId: $themeId, name: $name) {
        themeId
        name
        themeSet {
          themeSetId
        }
      }
    }
  `)
  return async (themeId, name) => {
    const result = await mutate({ variables: { themeId, name } })
    return result.data.updateQuestionTheme
  }
}

export const useThemeDelete = () => {
  const [mutate] = useMutation(gql`
    mutation m ($themeId: String!) {
      deleteQuestionTheme(themeId: $themeId) {
        themeId
      }
    }
  `)
  return async (themeId, themeSet) => {
    const options = {
      variables: { themeId },
      update(cache, response) {
        const theme = response.data.deleteQuestionTheme
        cache.modify({
          id: cache.identify(themeSet),
          fields: {
            themes(existingThemes = []) {
              return existingThemes.filter(t => t.themeId !== themeId)
            }
          }
        })
        cache.evict({ id: cache.identify(theme) })
      }
    }
    await mutate(options)
    return themeId
  }
}
