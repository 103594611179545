import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { Helmet } from 'react-helmet'

import { saveQvest, changeUnsavedQvest } from '../../../../actions/qvestActions'
import {
  hasStartedQvestState,
  filterQvestValidityById,
  isDraftQvestState,
  filterQvestsById,
} from '../../../../reducers/qvests'
import LastUpdateStatus from '../../../../components/owner/LastUpdateStatus/LastUpdateStatus'
import NextBar from '../../../../components/owner/NextBar/NextBar'
import Card from '../../../../components/common/Card/Card'
import Icon from '../../../../components/common/Icon/Icon'
import Checkbox from '../../../../components/common/Checkbox/Checkbox'
import Typography from '../../../../components/common/Typography/Typography'
import Button from '../../../../components/common/Button/Button'
import Input from '../../../../components/common/Input/Input'
import Divider from '../../../../components/common/Divider/Divider'
import { TopicHeading, PageHeading, InvitationHeading } from '../../../../components/owner/OwnerCardHeadings/OwnerTopicCardHeadings'

import pagePreviewSvg from '../../../../static/img/page-preview.svg'
import mailPreviewSvg from '../../../../static/img/mail-preview.svg'


const messages = defineMessages({
  title: { id: 'owner.OwnerQvestTopicTab.title', defaultMessage: 'Topic | {qvestName}' },
  wavesDisabled: { id: 'owner.QvestTopicTab.wavesDisabled', defaultMessage: 'Locked after Qvest has been scheduled' }
})

const MainLayout = styled.div`
  padding: 20px;
  min-width: 1400px;
  & > *:not(:first-child) { margin-top: 20px; }
`

const CardRow = styled.div`
  display: flex;
  & > :first-child { margin-right: 20px; }
  & > * { flex-basis: calc(50% - 10px); }
`

const StyledBody = styled(Card.Body)`
  padding-bottom: 80px;
`

const KeywordRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: 120px;
  }

  & > :not(:last-child) {
    margin-right: 14px;
  }
`

const EditorPreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #F9F9F9;
  width: 100%;
  line-height: 0;

  & > img {
    margin-top: 30px;
    border-radius: 8px 8px 0 0;
    box-shadow: 3px -2px 7px 0 rgba(0,0,0,0.04);
  }
`

const ButtonContainer = styled.div`
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  & > * {
    display: inline-block;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`

const EmailLabel = styled.label`
  & ${Typography} { padding-top: 5px; }
`


const TopicMessage = ({ show }) => {
  if (!show) return null
  return (
    <Card.Message warning>
      <Icon variant="exclamation-triangle" />
      {' '}
      <Typography variant="medium">
        <FormattedMessage
          id="owner.QvestTopicTab.editWarning"
          defaultMessage="You should only change topic and page during a running Qvest if it's absolutely necessary. Changes may confuse participants."
        />
      </Typography>
    </Card.Message>
  )
}

const WavesToggle = injectIntl(({ isDraft, hasWaves, onChange, intl }) => {
  const waveLink = (
    <Typography variant="medium">
      <strong>
        <FormattedMessage defaultMessage="question wave" id="owner.QvestTopicTab.waveLink" />
      </strong>
    </Typography>
  )
  let title
  if (!isDraft) {
    title = intl.formatMessage(messages.wavesDisabled)
  }
  return (
    <label title={title}>
      <Checkbox disabled={!isDraft} checked={hasWaves} onChange={event => onChange(event)}>
        <Typography transparent={!isDraft} variant="medium">
          <FormattedMessage
            id="owner.QvestTopicTab.label.questionWaves"
            defaultMessage="Yes, I want to add a {waveLink}"
            values={{ waveLink }}
          />
        </Typography>
      </Checkbox>
    </label>
  )
})

const TopicCard = ({ lastUpdate, topic, keywords, hasStarted, hasWaves, isDraft, onQvestChange, onSave }) => {
  keywords = keywords || []
  topic = topic || ''
  let kc = keywords.length

  const handleHasWavesChange = (event) => {
    onQvestChange({ hasWaves: event.target.checked })
    onSave()
  }

  const handleKeywordChange = (id) => (event) => {
    const newKeywords = keywords.slice()
    newKeywords[id] = event.target.value
    onQvestChange({ keywords: newKeywords })
  }

  return (
    <Card>
      <Card.Header>
        <TopicHeading />
        <ButtonGroup>
          <LastUpdateStatus lastUpdate={lastUpdate} />
        </ButtonGroup>
      </Card.Header>
      <TopicMessage show={hasStarted} />
      <Card.Body>
        <Input
          name="topic"
          placeholder="Choose a motivational topic"
          value={topic}
          onChange={({ target: { value } }) => onQvestChange({ topic: value })}
          onBlur={onSave}
        />
        <Divider height={14} />
        <KeywordRow>
          <div>
            <Input
              name="keyword1"
              placeholder="[Keyword 1]"
              value={(kc > 0 && keywords[0] != null) ? keywords[0] : ''}
              onChange={handleKeywordChange(0)}
              onBlur={onSave}
            />
          </div>
          <div>
            <Input
              name="keyword2"
              placeholder="[Keyword 2]"
              value={(kc > 1 && keywords[1] != null) ? keywords[1] : ''}
              onChange={handleKeywordChange(1)}
              onBlur={onSave}
            />
          </div>
          <div>
            <Input
              name="keyword3"
              placeholder="[Keyword 3]"
              value={(kc > 2 && keywords[2] != null) ? keywords[2] : ''}
              onChange={handleKeywordChange(2)}
              onBlur={onSave}
            />
          </div>
          <div>
            <Input
              name="keyword4"
              placeholder="[Keyword 4]"
              value={(kc > 3 && keywords[3] != null) ? keywords[3] : ''}
              onChange={handleKeywordChange(3)}
              onBlur={onSave}
            />
          </div>
          <div>
            <Input
              name="keyword5"
              placeholder="[Keyword 5]"
              value={(kc > 4 && keywords[4] != null) ? keywords[4] : ''}
              onChange={handleKeywordChange(4)}
              onBlur={onSave}
            />
          </div>
          <div>
            <Input
              name="keyword6"
              placeholder="[Keyword 6]"
              value={(kc > 5 && keywords[5] != null) ? keywords[5] : ''}
              onChange={handleKeywordChange(5)}
              onBlur={onSave}
            />
          </div>
        </KeywordRow>
        <Divider height={14} />
        <WavesToggle hasWaves={hasWaves} isDraft={isDraft} onChange={handleHasWavesChange} />
      </Card.Body>
    </Card>
  )
}

const QvestPageCard = ({ qvestId }) => (
  <Card>
    <Card.Header>
      <PageHeading />
    </Card.Header>
    <EditorPreviewContainer>
      <img src={pagePreviewSvg} alt="Page preview" />
    </EditorPreviewContainer>
    <StyledBody>
      <ButtonContainer>
        <Button.Link to={`/owner/preview/qvest/${qvestId}`}>
          <FormattedMessage id="owner.qvestTopicTab.pageButtonText" defaultMessage="Edit Qvest Page" />
        </Button.Link>
      </ButtonContainer>
    </StyledBody>
  </Card>
)

const InvitationCard = ({ qvest, hasInvite, onQvestChange, onSave }) => {
  let inviteLinkButton
  const inviteLinkButtonText = (
    <FormattedMessage id="owner.qvestTopicTab.emailButtonText" defaultMessage="Customize invitation email" />
  )
  if (hasInvite) {
    inviteLinkButton = (
      <Button.Link to={`/owner/preview/qvest/${qvest.qvestId}/invite-email`}>{inviteLinkButtonText}</Button.Link>
    )
  } else {
    inviteLinkButton = (
      <Button disabled>{inviteLinkButtonText}</Button>
    )
  }

  const handleInviteToggle = (event) => {
    onQvestChange({ hasInvite: event.target.checked })
    onSave()
  }

  return (
    <Card>
      <Card.Header>
        <InvitationHeading />
        <EmailLabel>
          <Checkbox disabled={qvest && qvest.state} checked={hasInvite} onChange={handleInviteToggle}>
            <Typography variant="tiny">
              <FormattedMessage id="owner.qvestTopicTab.toggleEmail" defaultMessage="Yes, I want to send an invitation email" />
            </Typography>
          </Checkbox>
        </EmailLabel>
      </Card.Header>
      <EditorPreviewContainer>
        <img src={mailPreviewSvg} alt="Mail preview" />
      </EditorPreviewContainer>
      <StyledBody>
        <ButtonContainer>
          {inviteLinkButton}
        </ButtonContainer>
      </StyledBody>
    </Card>
  )
}

const TopicNextBar = ({ qvestId, validity, isDraft }) => {
  if (!isDraft || !validity || validity.general === 'ERROR') return null
  return (
    <NextBar>
      <Button.Link secondary to={`/owner/qvest/${qvestId}/schedule`}>
        <FormattedMessage id="owner.QvestTopicTab.nextButton" defaultMessage="Next: Schedule" />
        <Icon cta tertiary variant="angle-right" />
      </Button.Link>
    </NextBar>
  )
}

const useDebouncedSave = (dispatch, qvestId) => {
  // Initialize debounced handler (throttles invocations such that save requests are made at most every 2 secs)
  const onSave = () => dispatch(saveQvest(qvestId, ['topic', 'keywords', 'hasInvite', 'hasWaves']))
  const [handleSaveObj] = useState({ func: debounce(onSave, 2000, { leading: true }) })
  // Flush pending invocation, if any, when component is dismounted
  useEffect(() => {
    return () => handleSaveObj.func.flush()
  }, [])
  // Only return function
  return handleSaveObj.func
}

const OwnerQvestTopicTab = ({ qvestStore, match, dispatch, intl }) => {
  const qvestId = match.params.qvestId
  const qvest = filterQvestsById(qvestStore, qvestId)
  const hasInvite = qvestStore.unsaved.hasInvite
  const hasWaves = qvestStore.unsaved.hasWaves
  const validity = filterQvestValidityById(qvestStore, qvestId)
  const hasStarted = hasStartedQvestState(qvestStore, qvestId)
  const isDraft = isDraftQvestState(qvestStore, qvestId)
  const headTitle = intl.formatMessage(messages.title, { qvestName: qvest && qvest.name })

  const handleSave = useDebouncedSave(dispatch, qvestId)
  const handleQvestChange = (patch) => dispatch(changeUnsavedQvest(patch))

  return (
    <MainLayout>
      <Helmet>
        <title>{headTitle}</title>
      </Helmet>
      <TopicCard
        lastUpdate={qvestStore.lastUpdate}
        topic={qvestStore.unsaved.topic}
        keywords={qvestStore.unsaved.keywords}
        hasStarted={hasStarted}
        hasWaves={hasWaves}
        isDraft={isDraft}
        onQvestChange={handleQvestChange}
        onSave={handleSave}
      />
      <CardRow>
        <QvestPageCard
          qvestId={qvestId}
        />
        <InvitationCard
          qvest={qvest}
          hasInvite={hasInvite}
          onQvestChange={handleQvestChange}
          onSave={handleSave}
        />
      </CardRow>
      <TopicNextBar qvestId={qvestId} validity={validity} isDraft={isDraft} />
    </MainLayout>
  )
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
  }
}

export default connect(mapStateToProps)(injectIntl(OwnerQvestTopicTab))
