import React from 'react'
import { FormattedMessage } from 'react-intl'

import { recommendedThemeCount } from '../../../utils/questionUtils'
import Tooltip from '../../common/Tooltip/Tooltip'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'


const Recommendation = ({ questionCount }) => {
  const recommendedCount = recommendedThemeCount(questionCount)
  return (
    <Tooltip.Area>
      <Tooltip.Reference>
        <Heading variant="heading4" primary>
          <FormattedMessage
            id="owner.AnalyticsThemes.recommendation"
            defaultMessage="max {count} themes"
            values={{ count: recommendedCount }}
          />
        </Heading>
      </Tooltip.Reference>
      <Tooltip wide>
        <Heading.h3>
          <FormattedMessage defaultMessage="Themes explanation" id="owner.AnalyticsThemes.tooltip.title" />
        </Heading.h3>
        <Typography.p variant="medium" weight="light">
          <FormattedMessage
            id="owner.AnalyticsThemes.tooltip.text1"
            defaultMessage="The theme builder helps you create an overview of your stakeholders' main interests. Drag and drop your questions into maximum {count} meaningful themes."
            values={{ count: recommendedCount }}
          />
        </Typography.p>
        <Typography.p variant="medium" weight="light">
          <FormattedMessage
            id="owner.AnalyticsThemes.tooltip.text2"
            defaultMessage="The theme builder rules of thumb are:"
          />
        </Typography.p>
        <ul>
          <li>
            <Typography variant="medium" weight="light">
              <FormattedMessage
                id="owner.AnalyticsThemes.tooltip.listItem1"
                defaultMessage="Less than 20 questions: max 3 themes"
              />
            </Typography>
          </li>
          <li>
            <Typography variant="medium" weight="light">
              <FormattedMessage
                id="owner.AnalyticsThemes.tooltip.listItem2"
                defaultMessage="20-50 questions: max 4 themes"
              />
            </Typography>
          </li>
          <li>
            <Typography variant="medium" weight="light">
              <FormattedMessage
                id="owner.AnalyticsThemes.tooltip.listItem3"
                defaultMessage="51-100 questions: max 6 themes"
              />
            </Typography>
          </li>
          <li>
            <Typography variant="medium" weight="light">
              <FormattedMessage
                id="owner.AnalyticsThemes.tooltip.listItem4"
                defaultMessage="100+ questions: max 8 themes"
              />
            </Typography>
          </li>
        </ul>
        <Typography.p variant="medium" weight="light">
          <FormattedMessage
            id="owner.AnalyticsThemes.tooltip.text3"
            defaultMessage="Note: You shouldn't aim for the same amount of questions in each of your themes. Rather, different amounts of questions can be useful when understanding and explaining the importance of the different themes."
          />
        </Typography.p>
      </Tooltip>
    </Tooltip.Area>
  )
}

export default Recommendation
