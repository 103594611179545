import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { injectIntl, defineMessages } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Table from '../../common/Table/Table'
import EditableRow from '../OwnerParticipantTable/EditableRow'

const messages = defineMessages({
  pending: {
    id: 'owner.OrganizationUsersTable.pending',
    defaultMessage: 'invitation pending',
  }
})

class OwnerOrganizationUsersTable extends Component {
  renderUsers() {
    const { users, onRemove } = this.props
    return users.map(({ userId, fullName, email, isMaster }) =>
      <EditableRow
        key={userId}
        participant={{ name: fullName, email, participantId: userId, isSuspended: isMaster }}
        onRemove={isMaster ? null : () => onRemove(userId)}
      />)
  }

  renderPending() {
    const { pendingUsers, intl } = this.props
    return pendingUsers.map(({ organizationUserInvitationId, fullName }) =>
      <EditableRow
        key={organizationUserInvitationId}
        participant={{ name: fullName, isSuspended: true, email: `(${intl.formatMessage(messages.pending)})` }}
      />
    )
  }

  renderInvite() {
    const { onCancel, onInvite, showInvite } = this.props
    if (!showInvite) return null
    return <EditableRow
      key="inviteUser"
      showInput
      onCancel={onCancel}
      onEdit={onInvite}
    />
  }

  render() {
    const { users } = this.props
    if (!users || users.length === 0) return null
    return (
      <Table>
        <thead>
          <tr>
            <th>
              <Typography weight="bold">
                <FormattedMessage id="owner.OrganizationUsersTable.name" defaultMessage="Name" />
              </Typography>
            </th>
            <th>
              <Typography weight="bold">
                <FormattedMessage id="owner.OrganizationUsersTable.email" defaultMessage="Email" />
              </Typography>
            </th>
            <th>{/* empty header for the icons */}</th>
          </tr>
        </thead>
        <tbody>
          {this.renderInvite()}
          {this.renderPending()}
          {this.renderUsers()}
        </tbody>
      </Table>
    )
  }
}

OwnerOrganizationUsersTable.propTypes = {
  showIvite: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    isMaster: PropTypes.bool,
  })),
  pendingUsers: PropTypes.arrayOf(PropTypes.shape({
    organizationUserInvitationId: PropTypes.string,
    fullName: PropTypes.string,
  })),
  onRemove: PropTypes.func,
  onCancel: PropTypes.func,
  onInvite: PropTypes.func,
}

OwnerOrganizationUsersTable.defaultProps = {
  showInvite: false,
  users: [],
  pendingUsers: [],
  onRemove: () => { },
  onCancel: () => { },
  onInvite: () => { },
}

export default injectIntl(OwnerOrganizationUsersTable)
