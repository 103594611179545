import React from 'react'
import { IntlProvider } from 'react-intl'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { TransitionGroup, Transition } from 'react-transition-group'

import NotificationToast from '../../components/common/NotificationToast/NotificationToast'
import { clearNotification as clearReduxNotification } from '../../actions/notificationActions'
import { useNotificationVar, clearNotification as clearApolloNotification } from '../../operations/notification'


const ComponentRoot = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  padding: 20px;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const AnimationWrapper = styled.div`
    transform: translateX(${({ animation }) => (animation === 'entered') ? '0' : '-450px'});
    transition: transform ease-in-out 300ms;
`


const NotificationOverlay = ({ dispatch, notificationStore }) => {
  const apolloNotification = useNotificationVar()
  const reduxNotification = notificationStore.displayed
  const notification = apolloNotification || reduxNotification

  const handleAction = () => location.reload()
  const handleClose = () => {
    dispatch(clearReduxNotification())
    clearApolloNotification()
  }

  const renderNotification = (notification) => {
    return (
      <Transition key={notification.type} timeout={300}>
        {state => (
          <AnimationWrapper animation={state}>
            <NotificationToast
              notification={notification}
              onAction={handleAction}
              onClose={handleClose}
            />
          </AnimationWrapper>
        )}
      </Transition>
    )
  }

  // TODO: Set locale to match Qvest for participants
  return (
    <IntlProvider locale="en">
      <ComponentRoot>
        <TransitionGroup enter leave>
          {notification ? renderNotification(notification) : null}
        </TransitionGroup>
      </ComponentRoot>
    </IntlProvider>
  )
}

function mapStateToProps(state) {
  return {
    notificationStore: state.notification.toJS()
  }
}

export default connect(mapStateToProps)(NotificationOverlay)
