import { defineMessages } from 'react-intl'

export const TITLE_MESSAGES = defineMessages({
  participants: { id: 'owner.ValidationTable.participants', defaultMessage: 'Participants' },
  groups: { id: 'owner.ValidationTable.groups', defaultMessage: 'Groups' },
  topic: { id: 'owner.ValidationTable.topic', defaultMessage: 'Topic' },
  page: { id: 'owner.ValidationTable.page', defaultMessage: 'Page' },
  email: { id: 'owner.ValidationTable.email', defaultMessage: 'Email' },
  subscription: { id: 'owner.ValidationTable.subscription', defaultMessage: 'Subscription' }
})

export const UTIL_MESSAGES = defineMessages({
  video: { id: 'owner.ValidationTable.video', defaultMessage: 'Video' },
  subject: { id: 'owner.ValidationTable.subject', defaultMessage: 'Subject' },
  yes: { id: 'owner.ValidationTable.yes', defaultMessage: 'Yes' },
  no: { id: 'owner.ValidationTable.no', defaultMessage: 'No' },
  contactEmail: { id: 'owner.ValidationTable.contactEmail', defaultMessage: 'Contact email' },
  none: { id: 'owner.ValidationTable.none', defaultMessage: 'none' },
})

export const HELP_MESSAGES = defineMessages({
  NO_PARTICIPANTS: {
    id: 'owner.ValidationTable.NO_PARTICIPANTS',
    defaultMessage: `Qvest is designed for 10-500 participants.
    Add more participants.`,
  },
  TOO_FEW_PARTICIPANTS: {
    id: 'owner.ValidationTable.TOO_FEW_PARTICIPANTS',
    defaultMessage: `Qvest is designed for groups with 10-500 participants.
    Add more participants to get the most out of Qvest.`,
  },
  TOO_MANY_PARTICIPANTS: {
    id: 'owner.ValidationTable.TOO_MANY_PARTICIPANTS',
    defaultMessage: `The maximum number of participants in a Qvest is 500.
    Remove participants.`,
  },
  DUPLICATE_PARTICIPANT_EMAILS: {
    id: 'owner.ValidationTable.DUPLICATE_PARTICIPANT_EMAILS',
    defaultMessage: `Two or more participants have the same email address.
    Delete duplicates.`
  },
  INVALID_FORMAT_PARTICIPANT_EMAILS: {
    id: 'owner.ValidationTable.INVALID_FORMAT_PARTICIPANT_EMAILS',
    defaultMessage: `One or more participants have invalid e-mail addresses.
    Edit the relevant participants.`
  },
  NO_GROUPS: {
    id: 'owner.ValidationTable.NO_GROUPS',
    defaultMessage: `Qvest participants must be divided into 2 or more groups.
    Add groups and assign participants.`,
  },
  NAMELESS_GROUPS: {
    id: 'owner.ValidationTable.NAMELESS_GROUPS',
    defaultMessage: `Qvest groups must be named.
    Assign a name to all groups.`,
  },
  TOO_FEW_PARTICIPANTS_PER_GROUP: {
    id: 'owner.ValidationTable.TOO_FEW_PARTICIPANTS_PER_GROUP',
    defaultMessage: `Each group should have 3 or more participants.
    Assign more participants to groups.`,
  },
  TOO_MANY_GROUPS: {
    id: 'owner.ValidationTable.TOO_MANY_GROUPS',
    defaultMessage: `The Qvest output is more valuable when participants are divided into 2-10 groups.
    Re-distribute participants into fewer groups.`,
  },
  TOO_FEW_GROUPS: {
    id: 'owner.ValidationTable.TOO_FEW_GROUPS',
    defaultMessage: `Qvest is designed for 4 or more groups.
    Sort your participants into more groups to get the most out of Qvest`,
  },
  EMPTY_GROUPS: {
    id: 'owner.ValidationTable.EMPTY_GROUPS',
    defaultMessage: `Each group must have at least one participant.
    Delete empty groups or assign participants to them.`,
  },
  GROUPLESS_PARTICIPANTS: {
    id: 'owner.ValidationTable.GROUPLESS_PARTICIPANTS',
    defaultMessage: `Each participant must be assigned to a group.
    Assign the remaining participants.`,
  },
  NO_TOPIC: {
    id: 'owner.ValidationTable.NO_TOPIC',
    defaultMessage: 'A Qvest needs a topic. Add topic.',
  },
  TOO_SHORT_TOPIC: {
    id: 'owner.ValidationTable.TOO_SHORT_TOPIC',
    defaultMessage: `The most impactful Qvest topics consist of 2 to 10 words.
    Edit topic to make it even more relevant to participants.`,
  },
  TOO_LONGISH_TOPIC: {
    id: 'owner.ValidationTable.TOO_LONGISH_TOPIC',
    defaultMessage: `The most impactful Qvest topics consist of 2 to 10 words.
    Edit topic to make it even more clear to participants.`,
  },
  QUESTION_TOPIC: {
    id: 'owner.ValidationTable.QUESTION_TOPIC',
    defaultMessage: `A Qvest topic should never be formulated as a question.
    Edit topic.`,
  },
  TOO_LONG_TOPIC: {
    id: 'owner.ValidationTable.TOO_LONG_TOPIC',
    defaultMessage: `Qvest topic is too long.
    Shorten topic.`,
  },
  NO_KEYWORDS: {
    id: 'owner.ValidationTable.NO_KEYWORDS',
    defaultMessage: `No keywords were given.
    Add keywords to facilitate Relevance-score calculation.`,
  },
  NO_PAGE: {
    id: 'owner.ValidationTable.NO_PAGE',
    defaultMessage: `Your Qvest Page communicates the topic to participants.
    Add text to your Qvest Page.`,
  },
  NO_PAGE_ABOUT_TEXT: {
    id: 'owner.ValidationTable.NO_PAGE_ABOUT_TEXT',
    defaultMessage: `The motivational text on the Qvest Page is a great way to make your participants
    feel part of a meaningful journey.
    Edit your Qvest Page to motivate your participants to join the Qvest.`,
  },
  NO_VIDEO: {
    id: 'owner.ValidationTable.NO_VIDEO',
    defaultMessage: `Including a video on your Qvest Page is a great way to communicate the topic and purpose to participants.
    Add a video to your Qvest Page to increase participation.`,
  },
  NO_INVITE_EMAIL: {
    id: 'owner.ValidationTable.NO_INVITE_EMAIL',
    defaultMessage: `The invitation email motivates your participants to join the Qvest.
    Add an email subject and a motivational text.`,
  },
  NO_INVITE_EMAIL_SUBJECT: {
    id: 'owner.ValidationTable.NO_INVITE_EMAIL_SUBJECT',
    defaultMessage: `Your invitation e-mail should have a relevant subject.
    Add a subject to your invitation e-mail.`,
  },
  NO_INVITE_EMAIL_TEXT: {
    id: 'owner.ValidationTable.NO_INVITE_EMAIL_TEXT',
    defaultMessage: `Text: Your invitation e-mail has no content.
    Add content to your invitation e-mail.`,
  },
  NO_ACTIVE_SUBSCRIPTION: {
    id: 'owner.ValidationTable.NO_ACTIVE_SUBSCRIPTION',
    defaultMessage: `You don't have an active Qvest subscription.
    Reactivate your subscription to continue.`,
  },
  PARTICIPANT_QUOTA_EXCEEDED: {
    id: 'owner.ValidationTable.PARTICIPANT_QUOTA_EXCEEDED',
    defaultMessage: `Number of participants exceeds your current plan.
    Upgrade your subscription to continue.`,
  },
  NO_CONTACT_EMAIL: {
    id: 'owner.ValidationTable.NO_CONTACT_EMAIL',
    defaultMessage: 'Contact email is not provided.',
  },
  INVALID_CONTACT_EMAIL: {
    id: 'owner.ValidationTable.INVALID_CONTACT_EMAIL',
    defaultMessage: 'Contact email has invalid format.',
  },
})

export const CONTENT_MAP = {
  PARTICIPANTS: 'participants',
  NO_PARTICIPANTS: 'participants',
  TOO_FEW_PARTICIPANTS: 'participants',
  TOO_MANY_PARTICIPANTS: 'participants',
  DUPLICATE_PARTICIPANT_EMAILS: 'participants',
  INVALID_FORMAT_PARTICIPANT_EMAILS: 'participants',
  GROUPS: 'groups',
  NO_GROUPS: 'groups',
  NAMELESS_GROUPS: 'groups',
  EMPTY_GROUPS: 'groups',
  TOO_FEW_PARTICIPANTS_PER_GROUP: 'groups',
  TOO_MANY_GROUPS: 'groups',
  TOO_FEW_GROUPS: 'groups',
  GROUPLESS_PARTICIPANTS: 'groups',
  TOPIC: 'topic',
  NO_TOPIC: 'topic',
  TOO_SHORT_TOPIC: 'topic',
  TOO_LONGISH_TOPIC: 'topic',
  QUESTION_TOPIC: 'topic',
  TOO_LONG_TOPIC: 'topic',
  NO_KEYWORDS: 'topic',
  Qvest_PAGE: 'page',
  NO_PAGE: 'page',
  NO_PAGE_ABOUT_TEXT: 'page',
  NO_VIDEO: 'page',
  INVITE_EMAIL: 'email',
  NO_INVITE_EMAIL: 'email',
  NO_INVITE_EMAIL_SUBJECT: 'email',
  NO_INVITE_EMAIL_TEXT: 'email',
  NO_ACTIVE_SUBSCRIPTION: 'subscription',
  PARTICIPANT_QUOTA_EXCEEDED: 'subscription',
  NO_CONTACT_EMAIL: 'page',
  INVALID_CONTACT_EMAIL: 'page',
}

export const CONTENT_PRIORITY = {
  subscription: 0,
  participants: 1,
  groups: 2,
  topic: 3,
  page: 4,
  email: 5
}
