import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from '../Dropdown/Dropdown'


const LANGUAGE_OPTIONS = [
  { text: 'English', value: 'en' },
  { text: 'Dansk', value: 'da' },
  { text: 'Deutsch', value: 'de' }
]

// eslint-disable-next-line react/display-name
const handleChange = onChange => selected => {
  if (!selected) return null
  onChange(selected.value)
}

const LanguageSelector = ({ value, onChange, filter }) => {
  let options = LANGUAGE_OPTIONS
  if (filter) {
    options = LANGUAGE_OPTIONS.filter(lang => filter.includes(lang.value))
  }
  return (
    <Dropdown
      options={options}
      value={value}
      onChange={handleChange(onChange)}
    />
  )
}

LanguageSelector.propTypes = {
  value: PropTypes.oneOf(LANGUAGE_OPTIONS.map(l => l.value)),
  filter: PropTypes.array,
  onChange: PropTypes.func,
}

LanguageSelector.defaultProps = {
  value: 'en',
  filter: null,
  onChange: () => { },
}

export default LanguageSelector
