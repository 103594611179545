import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PurposeWidget from '../../common/PurposeWidget/PurposeWidget'
import ParticipantsWidget from '../../common/ParticipantsWidget/ParticipantsWidget'
import ProcessWidget from '../../common/ProcessWidget/ProcessWidget'
import { isDraftQvestState } from '../../../utils/qvestUtils'


const HorizontalRule = styled.hr`
  margin: 40px 0;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.one[3]};
`

const ParticipantList = ({ participants, groups }) => {
  if (!participants) return null
  return (
    <Fragment>
      <HorizontalRule />
      <ParticipantsWidget participants={participants} groups={groups} />
    </Fragment>
  )
}

const QvestAbout = props => {
  const { qvest, validity, isEditable, participants, groups, onSave, onShowVideoManager } = props
  const isDraft = isDraftQvestState(qvest.state)
  const errors = validity && validity.items.filter(e => e.type === 'ERROR')
  return (
    <div>
      <PurposeWidget
        isEditable={isEditable}
        errors={errors}
        headline={qvest.headline}
        aboutTextSource={qvest.aboutTextSource}
        video={qvest.video}
        contactInfo={qvest.contactInfo}
        disableContactEmail={!isDraft}
        onShowVideoManager={onShowVideoManager}
        onSave={onSave}
      />
      <HorizontalRule />
      <ProcessWidget
        editable={isEditable && isDraft}
        hasWaves={qvest.hasWaves}
        onSave={onSave}
      />
      <ParticipantList
        participants={participants}
        groups={groups}
      />
    </div>
  )
}

QvestAbout.propTypes = {
  isEditable: PropTypes.bool,
  qvest: PropTypes.shape({
    headline: PropTypes.string,
    aboutTextSource: PropTypes.string,
    videoUrl: PropTypes.string,
    contactInfo: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      senderName: PropTypes.string,
      senderTitle: PropTypes.string,
    })
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groupingConnections: PropTypes.arrayOf(
        PropTypes.shape({
          groupName: PropTypes.string.isRequired
        })
      )
    })
  ),
  onShowVideoManager: PropTypes.func,
  onSave: PropTypes.func
}

QvestAbout.defaultProps = {
  onShowVideoManager: () => { },
  onSave: () => { }
}

export default QvestAbout
