import React from 'react'
import styled from 'styled-components'

import logo from '../../../static/img/qvest-regular.svg'

const Wrapper = styled.div`
  display: flex;
  padding: 20px;

  & > * { margin: 0 auto; }
`

const Image = styled.img`
  width: 50px;
  @media (min-width: 512px) { width: 70px; }
`

const QvestFooter = ({ hide }) => {
  if (hide) return null
  return (
    <Wrapper>
      <a href="https://qvest.io" target="_blank" rel="noopener noreferrer">
        <Image src={logo} alt="logo" />
      </a>
    </Wrapper>
  )
}

export default QvestFooter
