import React, { Component } from 'react'
import styled from 'styled-components'

import Icon from '../Icon/Icon'


const ComponentRoot = styled.div`
  & > * { display: block }
`

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none; 
`

const HorizontalRule = styled.hr`
  border-width: 1px 0 0 0;
  border-style: solid; 
  border-color: ${({ theme }) => theme.one[3]};
  margin: 20px 0 30px 0;
`


class Fold extends Component {
  state = {
    open: true
  }
  handleToggle = () => {
    this.setState({ open: !this.state.open })
  }
  render() {
    const { children, label } = this.props
    const { open } = this.state
    const icon = open ? 'chevron-up' : 'chevron-down'
    return (
      <ComponentRoot>
        <Label onClick={this.handleToggle}>
          {label}
          <Icon clickable variant={icon} />
        </Label>
        <HorizontalRule />
        {open ? children : null}
      </ComponentRoot>
    )
  }
}

export default Fold
