import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage, FormattedDate, FormattedRelative } from 'react-intl'

import Icon from '../../common/Icon/Icon'
import Heading from '../../common/Typography/Heading'
import { getLatestActivityDate } from '../../../utils/questionUtils'
import RightArrow from '../SubmitButton/RightArrow'

import questionAskSvg from '../../../static/img/question-ask.svg'
import questionAskGraySvg from '../../../static/img/question-ask-gray.svg'
import questionAnswerSvg from '../../../static/img/question-answer.svg'
import questionAnswerGraySvg from '../../../static/img/question-answer-gray.svg'
import answerReceivedSvg from '../../../static/img/answer-received.svg'
import answerReceivedGraySvg from '../../../static/img/answer-received-gray.svg'
import questionCheckmarkSvg from '../../../static/img/question-checkmark.svg'

const BgColor = ({ completed, locked, theme }) => {
  if (completed) return '#F6F6F3'
  if (locked) return theme.one[0]
  return theme.two[0]
}

const BgColorHover = ({ locked, theme }) => {
  if (locked) return theme.one[0]
  return theme.two[0]
}

const LeftBorderColor = ({ completed, locked, theme }) => {
  if (completed || locked) return '#EEEEEE'
  return theme.color[0]
}

const LeftBorderColorHover = ({ locked, theme }) => {
  if (locked) return '#EEEEEE'
  return theme.color[0]
}

const QuestionImg = styled.img`
  width: 40px;
  height: 40px;
  @media (min-width: 700px) {
    width: 65px;
    height: 65px;
  }
`

const HoverQuestionImg = styled(QuestionImg)`
  display: none;
`

const ComponentRoot = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${BgColor};
  border: 1px solid #EEEEEE;
  border-left-width: ${({ completed, locked }) => (completed || locked) ? '1px' : '3px'};
  border-left-color: ${LeftBorderColor};
  border-radius: 6px;
  padding: 20px;
  cursor: ${({ locked }) => locked ? 'unset' : 'pointer'};
  pointer-events: ${({ locked }) => locked ? 'none' : 'unset'};
  width: calc(50% - 20px);
  height: 172px;
  user-select: none;
  text-decoration: none;

  &:hover {
    box-shadow: 10px 10px 24px 0 rgba(61,70,65,0.05);
    background: ${BgColorHover};
    border-left-width: ${({ locked }) => locked ? '1px' : '3px'};
    border-left-color: ${LeftBorderColorHover};
    ${({ completed }) => completed ? 'padding-left: calc(30px - 2px);' : ''}
  }
  &:hover ${QuestionImg} { display: none; }
  &:hover ${HoverQuestionImg} { display: block; }
  &:hover svg g { fill: ${({ theme }) => theme.color[0]}; }
  &:active { box-shadow: none; }

  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    /* remove all hover and active styles to enable single click navigation */
    &:hover {
      box-shadow: none;
      background: ${BgColorHover};
      border-left-width: ${({ completed }) => completed ? '1px' : '3px'};
      border-left-color: ${LeftBorderColorHover};
    }
    &:hover ${QuestionImg} { display: block; }
    &:hover ${HoverQuestionImg} { display: none; }
    &:hover svg g { fill: ${({ theme }) => theme.one[0]}; }
    &:active { box-shadow: none; }
  }

  @media (min-width: 700px) {
    width: 350px;
    height: 350px;
    padding: 40px 30px 30px 30px;
  }
`

const ImageRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const TextBox = styled.div`
  & > * {
    display: block;
  }
  @media (min-width: 700px) {
    min-height: 60px;
  }
`

const CheckImg = styled.img`
  width: 20px;
  height: 20px;
  @media (min-width: 700px) {
    width: 29px;
    height: 29px;
  }
`

const Title = styled.span`
  font-family: ${({ theme }) => theme.font2};
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: ${({ locked, theme }) => locked ? theme.inverted.one[0] : theme.one[0]};
  @media (min-width: 700px) {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 12px;
  }
`

const Preview = styled.span`
  font-family: ${({ theme }) => theme.font4};
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: ${({ theme }) => theme.one[2]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 700px) {
    font-size: 16px;
    line-height: 28px;
  }
`

const DateLabel = styled.span`
  font-family: ${({ theme }) => theme.font2};
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: ${({ theme }) => theme.one[2]};
  @media (min-width: 700px) {
    font-size: 15px;
    line-height: 19px;
  }
`

const LineBreaker = styled.span`
    & > * { display: block; }

  @media (min-width: 700px) {
    & > * { display: inline; }
  }
`

const DefaultFooter = styled.div`
  display: flex;
  justify-content: space-between;

  & > :last-child { display: none }
  @media (min-width: 700px) {
    & > :last-child { display: block }
  }
`

const LockedFooter = styled(DefaultFooter)`
  justify-content: flex-end;
  & > :last-child {
    margin-left: 16px;
    font-size: 28px;
  }
  @media (max-width: 699px) {
    & > * {
      font-size: 12px;
      line-height: 12px;
    }
    & > :last-child {
      display: none;
    }
  }
`

const QuestionIcon = ({ status, completed }) => {
  switch (status) {
    case 'ASKED':
      return (
        <Fragment>
          <QuestionImg src={questionAnswerSvg} alt="answer icon" />
          <HoverQuestionImg src={questionAnswerSvg} alt="answer icon" />
        </Fragment>
      )
    case 'ASKED_BY_SELF':
      return (
        <Fragment>
          <QuestionImg src={questionAskGraySvg} alt="ask icon" />
          <HoverQuestionImg src={questionAskSvg} alt="ask icon" />
          <CheckImg src={questionCheckmarkSvg} />
        </Fragment>
      )
    case 'ANSWERED':
      if (completed) {
        return (
          <Fragment>
            <QuestionImg src={answerReceivedGraySvg} alt="answer received icon" />
            <HoverQuestionImg src={answerReceivedSvg} alt="answer received icon" />
            <CheckImg src={questionCheckmarkSvg} />
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <QuestionImg src={answerReceivedSvg} alt="answer received icon" />
            <HoverQuestionImg src={answerReceivedSvg} alt="answer received icon" />
          </Fragment>
        )
      }
    case 'ANSWERED_BY_SELF':
      return (
        <Fragment>
          <QuestionImg src={questionAnswerGraySvg} alt="answer icon" />
          <HoverQuestionImg src={questionAnswerSvg} alt="answer icon" />
          <CheckImg src={questionCheckmarkSvg} />
        </Fragment>
      )
    case 'LOCKED':
      return (
        <Fragment>
          <QuestionImg src={questionAskGraySvg} alt="ask icon" />
          <HoverQuestionImg src={questionAskGraySvg} alt="ask icon" />
        </Fragment>
      )
    default:
      return (
        <Fragment>
          <QuestionImg src={questionAskSvg} alt="ask icon" />
          <HoverQuestionImg src={questionAskSvg} alt="ask icon" />
        </Fragment>
      )
  }
}


const StatusSummary = ({ status }) => {
  switch (status) {
    case 'ASKED':
      return (
        <LineBreaker>
          <FormattedMessage
            id="QvestActivityItem.askedSummary.line1"
            defaultMessage="Answer"
          />
          {' '}
          <FormattedMessage
            id="QvestActivityItem.askedSummary.line2"
            defaultMessage="a question"
          />
        </LineBreaker>
      )
    case 'ASKED_BY_SELF':
      return (
        <LineBreaker>
          <FormattedMessage
            id="QvestActivityItem.askedBySelfSummary.line1"
            defaultMessage="You asked"
          />
          {' '}
          <FormattedMessage
            id="QvestActivityItem.askedBySelfSummary.line2"
            defaultMessage="a question"
          />
        </LineBreaker>
      )
    case 'ANSWERED':
      return (
        <LineBreaker>
          <FormattedMessage
            id="QvestActivityItem.answeredSummary.line1"
            defaultMessage="You received"
          />
          {' '}
          <FormattedMessage
            id="QvestActivityItem.answeredSummary.line2"
            defaultMessage="an answer"
          />
        </LineBreaker>
      )
    case 'ANSWERED_BY_SELF':
      return (
        <LineBreaker>
          <FormattedMessage
            id="QvestActivityItem.answeredBySelfSummary.line1"
            defaultMessage="You answered"
          />
          {' '}
          <FormattedMessage
            id="QvestActivityItem.answeredBySelfSummary.line2"
            defaultMessage="a question"
          />
        </LineBreaker>
      )
    default:
      return (
        <LineBreaker>
          <FormattedMessage
            id="QvestActivityItem.defaultSummary.line1"
            defaultMessage="Ask"
          />
          {' '}
          <FormattedMessage
            id="QvestActivityItem.defaultSummary.line2"
            defaultMessage="a question"
          />
        </LineBreaker>
      )
  }
}


const Footer = ({ question}) => {
  if (question.status === 'LOCKED') {
    const relativeTime = <FormattedRelative value={question.createdAt} />
    return (
      <LockedFooter>
        <Heading inverted variant="heading3">
          <FormattedMessage
            id="QvestActivityItem.footer.locked"
            defaultMessage="Unlocks {relativeTime}"
            values={{ relativeTime }}
          />
        </Heading>
        <Icon inverted variant="lock" />
      </LockedFooter>
    )
  } else {
    return (
      <DefaultFooter>
        <DateLabel>
          <FormattedDate
            day="numeric"
            month="short"
            hour="numeric"
            minute="numeric"
            value={getLatestActivityDate(question)}
          />
        </DateLabel>
        <RightArrow />
      </DefaultFooter>
    )
  }
}

export default class QvestActivityItem extends Component {
  resolveLink(status) {
    const { qvestId, question } = this.props
    const toAsk = `/qvest/${qvestId}/question/${question.questionId}/ask`
    const toAnswer = `/qvest/${qvestId}/question/${question.questionId}/answer`
    const toView = `/qvest/${qvestId}/question/${question.questionId}/view`
    switch (status) {
      case 'ASKED':
        return toAnswer
      case 'ASKED_BY_SELF':
        return toView
      case 'ANSWERED':
        return toView
      case 'ANSWERED_BY_SELF':
        return toView
      case 'LOCKED':
        return ''
      default:
        return toAsk
    }
  }

  resolvePreview(status) {
    const { question } = this.props
    switch (status) {
      case 'ASKED':
        return question.questionContent
      case 'ASKED_BY_SELF':
        return question.questionContent
      case 'ANSWERED':
        return question.answerContent
      case 'ANSWERED_BY_SELF':
        return question.answerContent
      default:
        return ''
    }
  }

  resolveCompletedState(status) {
    const { question } = this.props
    switch (status) {
      case 'ASKED':
        return false
      case 'ASKED_BY_SELF':
        return true
      case 'ANSWERED':
        return !!question.answerReadAt
      case 'ANSWERED_BY_SELF':
        return true
      default:
        return false
    }
  }

  render() {
    const { isQvestClosed, question } = this.props
    const status = question.status
    const link = this.resolveLink(status)
    const isCompleted = this.resolveCompletedState(status)
    const isLocked = (status === 'LOCKED')
    return (
      <ComponentRoot to={link} completed={isCompleted || isQvestClosed} locked={isLocked}>
        <ImageRow>
          <QuestionIcon status={status} completed={isCompleted} />
        </ImageRow>
        <TextBox>
          <Title locked={isLocked}>
            <StatusSummary status={status} />
          </Title>
          <Preview>{this.resolvePreview(status)}</Preview>
        </TextBox>
        <Footer question={question} />
      </ComponentRoot>
    )
  }
}

QvestActivityItem.propTypes = {
  qvestId: PropTypes.string,
  question: PropTypes.object.isRequired,
  isQvestClosed: PropTypes.bool,
}
