import React from 'react'
import { getQueryStringValue } from '../../utils/stringUtils'
import GenericErrorDisplay from '../../components/common/GenericErrorDisplay/GenericErrorDisplay'


const OwnerLoginErrorPage = () => {
  const error = getQueryStringValue('error')
  return (
    <GenericErrorDisplay alwaysHasError={true} errorMessage={error} />
  )
}


export default OwnerLoginErrorPage
