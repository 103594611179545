import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { defineMessages, injectIntl } from 'react-intl'

import { hexaToRgba } from '../../../utils/styleUtils'
import Typography from '../../common/Typography/Typography'
import Input from '../../common/Input/Input'
import EditableSpan from '../../common/EditableSpan/EditableSpan'
import LanguageSelector from '../../common/LanguageSelector/LanguageSelector'

// it might be necessary to extract this
const scheduleSubTexts = defineMessages({
  QVEST_SCHEDULED: {
    id: 'owner.QvestTabbedBar.scheduled',
    defaultMessage: 'Scheduled'
  },
  QVEST_ONBOARDING_STARTED: {
    id: 'owner.QvestTabbedBar.invitationSent',
    defaultMessage: 'Invitation sent'
  },
  QVEST_OPENED: {
    id: 'owner.QvestTabbedBar.opened',
    defaultMessage: 'Opened'
  },
  QVEST_CLOSED: {
    id: 'owner.QvestTabbedBar.closed',
    defaultMessage: 'Closed'
  },
  QVEST_EXPIRED: {
    id: 'owner.QvestTabbedBar.expired',
    defaultMessage: 'Expired'
  },
  QVEST_CREATED: {
    id: 'owner.QvestTabbedBar.created',
    defaultMessage: 'Created'
  },
})
const topicSubTexts = defineMessages({
  DRAFT: {
    id: 'owner.QvestTabbedBar.topicSubtext.draft',
    defaultMessage: 'Draft'
  },
  DONE: {
    id: 'owner.QvestTabbedBar.topicSubtext.done',
    defaultMessage: 'Done'
  }
})

const ComponentRoot = styled.div`
  z-index: 50;
  top: 45px;
  left: 0px;
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 20px;
  background-color: ${props => props.theme.background1};
  border-top: 1px solid ${props => hexaToRgba(props.theme.default + '1A')}; /* 10% opacity */
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.04);

  & > :nth-last-child(2) { margin-left: auto; }
  & > :not(:last-child) { margin-right: 20px; }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 40px;
  white-space: nowrap;
`

const NameBox = styled.div`
  display: flex;
  align-items: center;
  & > * { display: inline-block; }
  & > :first-child {
    margin-right: 4px;
    max-width: 640px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const LangTypography = styled(Typography)`
  opacity: 0.5;
`

const Tab = styled.div`
  height: 100%;
  padding: 22px 10px 0 10px;
  user-select: none;
  cursor: pointer;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme, active }) => active
    ? theme.main.primary
    : 'rgba(0, 0, 0, 0)'};
  & > *:first-child { margin-bottom: 5px; }
  & > * { display: block; }
  &:hover {
    border-bottom-color: ${({ theme }) => theme.main.primary};
  }
`

const CaptionTypography = styled(Typography)`
  height: 12px;
`

const QvestEditRow = styled.div`
  display: flex;

  & > :not(:last-child) {
    margin-right: 6px;
  }

  & > :first-child {
    width: 275px;
  }

  && > :nth-child(2) {
    width: 120px;
  }
`

const LANGUAGE_OPTIONS = [
  { text: 'English', short: 'EN', value: 'en' },
  { text: 'Dansk', short: 'DA', value: 'da' },
  { text: 'Deutsch', short: 'DE', value: 'de' }
]

const renderScheduleSubtext = (tabs, intl) => {
  return tabs.map(tab => {
    if (tab.id === 'schedule' && tab.subText) {
      return Object.assign({}, tab, {
        subText: intl.formatMessage(scheduleSubTexts[tab.subText])
      })
    }
    if (tab.id === 'topic' && tab.subText) {
      return Object.assign({}, tab, {
        subText: intl.formatMessage(topicSubTexts[tab.subText])
      })
    }
    return tab
  })
}

class QvestTabbedBar extends Component {
  constructor(props) {
    super(props)
    this.state = { isEditing: false }
  }

  handleTabClick = (id) => () => {
    const { onTabClick } = this.props
    onTabClick(id)
  }

  renderTabs() {
    const { tabs, intl, qvest } = this.props
    const displayedTabs = renderScheduleSubtext(tabs, intl, qvest)
    return displayedTabs.map(({ id, active, text, subText }) => (
      <Tab
        key={id}
        active={active}
        onClick={this.handleTabClick(id)}
      >
        <CaptionTypography variant="tiny" secondary>{subText || '\u200B'}</CaptionTypography>
        <Typography variant="heading5">{text}</Typography>
      </Tab>
    ))
  }

  render() {
    const { qvest } = this.props
    const language = LANGUAGE_OPTIONS.find(l => l.value === qvest.language) || LANGUAGE_OPTIONS[0]
    return (
      <ComponentRoot>
        <Title>
          <EditableSpan
            onStartEdit={() => this.setState({ isEditing: true })}
            onDone={this.handleSave}
            isEditing={this.state.isEditing}
            onCancel={this.handleCancel}
          >
            <EditableSpan.View>
              <NameBox>
                <Typography variant="heading3">{qvest.name}</Typography>
                <LangTypography variant="heading3" secondary> ({language.short})</LangTypography>
              </NameBox>
            </EditableSpan.View>
            <EditableSpan.Edit disabled={!qvest.name}>
              <QvestEditRow>
                <Input
                  autoFocus
                  type="text"
                  value={qvest.name}
                  onChange={this.handleNameChange}
                />
                <LanguageSelector
                  value={qvest.language}
                  onChange={this.handleLanguageChange}
                />
              </QvestEditRow>
            </EditableSpan.Edit>
          </EditableSpan>
        </Title>
        {this.renderTabs()}
      </ComponentRoot>
    )
  }

  handleNameChange = ({ target }) => {
    const { onChange } = this.props
    onChange({ name: target.value })
  }

  handleLanguageChange = (language) => {
    const { onChange } = this.props
    onChange({ language })
  }

  handleSave = () => {
    const { onSave } = this.props
    onSave()
    this.setState({ isEditing: false })
  }

  handleCancel = () => {
    const { onCancel } = this.props
    onCancel()
    this.setState({ isEditing: false })
  }
}

QvestTabbedBar.propTypes = {
  qvest: PropTypes.shape({
    name: PropTypes.string,
    state: PropTypes.oneOf([
      'QVEST_SCHEDULED',
      'QVEST_ONBOARDING_STARTED',
      'QVEST_OPENED',
      'QVEST_CLOSED',
    ]),
    organizationName: PropTypes.string
  }),
  tabs: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.string,
    active: PropTypes.bool,
  })),
  onTabClick: PropTypes.func,
  onSave: PropTypes.func
}

QvestTabbedBar.defaultProps = {
  qvest: {
    name: '[qvest]',
    state: null,
    organizationName: '[organization]'
  },
  tabs: [],
  onCancel: () => { },
  onSave: () => { },
  onChange: () => { },
  onTabClick: () => { },
}

export default injectIntl(QvestTabbedBar)
