import React, {Component, Fragment} from 'react'
import styled, { withTheme } from 'styled-components'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'

import { makeAvatarColorScale } from '../../../utils/d3Utils'

import Typography from '../../common/Typography/Typography'
import Divider from '../../common/Divider/Divider'
import RecipientAvatar from '../RecipientAvatar/RecipientAvatar'


const Label = styled.div`
  font-family: ${({ theme }) => theme.font2};
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.one[0]};
`

const AnswerLayout = styled.div`
  display: flex;
  & > :first-child {
    flex: 0 0 45px;
    margin: 0 20px 0 0;
  }
  & > :not(:first-child) { flex: 1; }

  @media (max-width: 700px) {
    flex-direction: column;
    & > :first-child {
      margin: 0 0 10px 0;
    }
  }
`

const Content = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.font4};
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: ${({ theme, placeholder }) => placeholder ? theme.one[2] : theme.one[0]};
  white-space: pre-wrap;
  word-break: break-word;

  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 28px;
  }
`

const HorizontalRule = styled.hr`
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.one[3]};
  margin: 40px 0 30px 0;
`


const answerLabel = defineMessages({
  QUESTIONER: { defaultMessage: 'Answer from:', id: 'QuestionDisplay.answerLabel.QUESTIONER' },
  ANSWERER: { defaultMessage: 'Your answer:', id: 'QuestionDisplay.answerLabel.ANSWERER' }
})

const questionLabel = defineMessages({
  QUESTIONER: { defaultMessage: 'Your question:', id: 'QuestionDisplay.questionLabel.QUESTIONER' },
  ANSWERER: { defaultMessage: 'A question for you:', id: 'QuestionDisplay.questionLabel.ANSWERER' }
})

class QuestionDisplay extends Component {
  renderAnswer() {
    const { theme, groupings, participants, question, isClosed, intl } = this.props
    const hasContent = (question.answerContent != null)

    let answerContent = question.answerContent
    if (!hasContent && isClosed) {
      answerContent = (
        <FormattedMessage
          id="qvest.QuestionDisplay.placeholder.closed"
          defaultMessage="Qvest is closed"
        />
      )
    } else if (!hasContent) {
      answerContent = (
        <FormattedMessage
          id="qvest.QuestionDisplay.placeholder"
          defaultMessage="Awaiting answer"
        />
      )
    }

    let answerSection
    if (question.perspective === 'QUESTIONER') {
      const answererColors = makeAvatarColorScale(theme, participants, groupings)
      const answerer = participants.find(p => p.participantId === question.answerParticipantId)
      answerSection = (
        <AnswerLayout>
          <RecipientAvatar color={answererColors(answerer)} />
          <div>
            <Typography>{answerer.name}</Typography>
            <Content placeholder={!hasContent}>{answerContent}</Content>
          </div>
        </AnswerLayout>
      )
    } else {
      answerSection = (
        <Content placeholder={!hasContent}>{answerContent}</Content>
      )
    }

    return (
      <Fragment>
        <Label>
          {intl.formatMessage(answerLabel[question.perspective])}
        </Label>
        {answerSection}
      </Fragment>
    )
  }

  renderQuestion() {
    const { intl, question } = this.props
    return (
      <Fragment>
        <Label>
          {intl.formatMessage(questionLabel[question.perspective])}
        </Label>
        <Content>{question.questionContent}</Content>
      </Fragment>
    )
  }

  render() {
    const { question } = this.props
    const isQuestioner = (question.perspective === 'QUESTIONER')
    return (
      <div>
        <HorizontalRule />
        {isQuestioner ? this.renderAnswer() : this.renderQuestion()}
        <HorizontalRule />
        <Divider height="25" />
        {isQuestioner ? this.renderQuestion() : this.renderAnswer()}
        <HorizontalRule />
      </div>
    )
  }
}

export default withTheme(injectIntl(QuestionDisplay))
