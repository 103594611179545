
const GreenOnBeigeTheme = {
  /* Background colors */
  background1: '#F3EEE2',
  background2: '#F7F4ED',
  background3: '#EDE6D5',
  background4: '#C1D3C5',
  background5: '#F0F5F1',
  /* Basic colors */
  main: {
    primary: '#3D4640',
    secondary: '#64936E',
    tertiary: '#ffffff',
  },
  /* Call-to-action colors */
  cta: {
    primary: '#FC8F85',
    secondary: '#FDA59D',
    tertiary: '#ffffff',
  },
  /* Alerts */
  alert: {
    error: '#fc8f85',
    warning: '#f7a15d',
    ok: '#64936E'
  },
  /* Data visualization colors */
  data: {
    primary:   ['#65936e', '#ed9a91', '#f4bf6c', '#486e88', '#cd5154', '#f7a15d', '#42675c', '#c5cfc7', '#5c8f85', '#3d4640'],
    secondary: ['#7FAD88', '#FFB4AB', '#FFD986', '#6288A2', '#E76B6E', '#FFBB77', '#5C8176', '#DFE9E1', '#76A99F', '#57605A']
  },
  /* Fonts */
  font1: 'Raleway, sans-serif',
  font2: '\'LL Circular Book Web\', sans-serif',
  font3: '\'LL Circular Black Web\', sans-serif',
  font4: '\'Andale Mono MT Pro\', monospace'
}

export default GreenOnBeigeTheme
