import React from 'react'
import styled from 'styled-components'

const ComponentRoot = styled.svg`
  & g {
    fill: ${({ theme }) => theme.one[0]};
  }
`

const RightArrow = () => (
  <ComponentRoot width="32px" height="17px" viewBox="0 0 32 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Overview-Copy-7" transform="translate(-831.000000, -561.000000)">
        <g id="Group-5" transform="translate(148.000000, 263.000000)">
          <g id="Group-2" transform="translate(397.000000, 0.000000)">
            <path d="M310.040824,298.225932 C309.739582,297.924689 309.249594,297.924689 308.947101,298.225932 C308.645859,298.528424 308.645859,299.018412 308.947101,299.319655 L315.366941,305.738244 L286.773731,305.738244 C286.346241,305.738244 286,306.084485 286,306.511975 C286,306.939464 286.346241,307.285705 286.773731,307.285705 L315.351941,307.285705 L308.947101,313.690545 C308.645859,313.991788 308.645859,314.481775 308.947101,314.784268 C309.099598,314.934264 309.297093,315.010512 309.494588,315.010512 C309.692083,315.010512 309.890828,314.934264 310.040824,314.784268 L317.773131,307.051961 C318.075623,306.749469 318.075623,306.260731 317.773131,305.958239 L310.040824,298.225932 Z" id="Arrow-right" />
          </g>
        </g>
      </g>
    </g>
  </ComponentRoot>
)

export default RightArrow
