import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import Button from '../../common/Button/Button'


const MessageContainer = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Message = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & > * { margin: 15px 0 }
`


const PizzaSvg = ({ color }) => (
  <svg width="52px" height="46px" viewBox="0 0 52 46" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Pie chart</title>
    <g id="Overall-page-concepts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Analytics-preview1" transform="translate(-772.000000, -522.000000)" fill={color}>
        <path d="M820.042584,543.074919 C819.931217,543.198391 819.691537,543.401756 819.313859,543.401756 L797.711159,543.401756 L809.789593,525.595204 C810.002643,525.285315 810.302848,525.200579 810.465056,525.176369 C810.620001,525.15458 810.937154,525.152159 811.227675,525.389418 C813.789107,527.502963 815.878441,530.057132 817.442416,532.979294 C818.999127,535.891773 819.960269,539.031827 820.294369,542.319563 C820.330684,542.697241 820.151529,542.956289 820.042584,543.074919 M794.972993,564.803512 C783.906542,564.803512 774.902795,555.850605 774.902795,544.851943 C774.902795,533.85328 783.906542,524.905216 794.972993,524.905216 C798.497988,524.905216 801.892249,525.866358 804.920936,527.599803 L793.76975,544.038482 C793.467123,544.483949 793.438071,545.06015 793.687435,545.534668 C793.941642,546.009187 794.435528,546.306971 794.972993,546.306971 L814.965719,546.306971 C814.212784,556.62775 805.543136,564.803512 794.972993,564.803512 M820.003848,531.611422 C818.263139,528.352738 815.934125,525.505627 813.077329,523.149981 C812.237238,522.457571 811.150203,522.147682 810.051063,522.302627 C808.971291,522.45515 807.998044,523.062825 807.385527,523.963442 L806.552699,525.195737 C803.037388,523.137876 799.081452,522 794.972993,522 C782.303832,522 772,532.250569 772,544.851943 C772,557.453316 782.303832,567.706306 794.972993,567.706306 C807.145847,567.706306 817.110737,558.230461 817.868514,546.306971 L819.313859,546.306971 C820.408157,546.306971 821.461298,545.842137 822.197285,545.023835 C822.933273,544.210374 823.294004,543.118497 823.182638,542.029041 C822.812223,538.361206 821.739714,534.85558 820.003848,531.611422" id="Pie-chart"></path>
      </g>
    </g>
  </svg>
)

const DiamondSvg = ({ color }) => (
  <svg width="35px" height="46px" viewBox="0 0 30 25" version="1.1">
    <g transform="translate(-26, -357)" fill={color}>
      <path
        d="M50.6731703,357.257538 C50.5663863,357.106784 50.3967883,357 50.2083461,357 L31.4520647,357 C31.2636225,357 31.0940245,357.106784 30.9872406,357.257538 L26.1003059,364.330402 C25.9558336,364.54397 25.9683964,364.826633 26.131713,365.021357 L40.3528185,381.729899 C40.453321,381.886935 40.6354818,382 40.823924,382 L40.8302054,382 C41.024929,382 41.2008084,381.886935 41.301311,381.729899 L55.5224165,365.027638 C55.6857331,364.832915 55.7045773,364.537688 55.5538235,364.330402 L50.6731703,357.257538 Z M46.6153813,365.228643 L40.823924,379.864322 L35.0638738,365.228643 L46.6153813,365.228643 Z M35.5349793,364.09799 L40.7799542,358.469849 L46.1191502,364.09799 L35.5349793,364.09799 Z M42.0487984,358.130653 L49.4106074,358.130653 L47.243522,363.60804 L42.0487984,358.130653 Z M34.4294517,363.582915 L32.2812105,358.130653 L39.51111,358.130653 L34.4294517,363.582915 Z M33.4055823,364.09799 L27.6455321,364.09799 L31.3201552,358.790201 L33.4055823,364.09799 Z M33.8515622,365.228643 L39.0588486,378.451005 L27.7900044,365.228643 L33.8515622,365.228643 Z M47.8276929,365.228643 L53.8704064,365.228643 L42.5889994,378.469849 L47.8276929,365.228643 Z M48.2799542,364.09799 L50.3653813,358.821608 L54.0148788,364.09799 L48.2799542,364.09799 Z"/>
    </g>
  </svg>
)

class NoDataMessage extends Component {
  render() {
    const { children, buttonContent, icon, inverted, theme, onClick } = this.props
    const primaryColor = (inverted ? theme.inverted.one[0] : theme.one[0])
    let iconElement
    if (icon === 'diamond') {
      iconElement = <DiamondSvg color={primaryColor} />
    } else {
      iconElement = <PizzaSvg color={primaryColor} />
    }
    return (
      <MessageContainer>
        <Message>
          {iconElement}
          {children}
          <Button inverted={inverted} outline onClick={onClick}>
            {buttonContent}
          </Button>
        </Message>
      </MessageContainer>
    )
  }
}

NoDataMessage.propTypes = {
  icon: PropTypes.oneOf([
    'pizza',
    'diamond'
  ]),
  buttonContent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  onClick: PropTypes.func
}

NoDataMessage.defaultProps = {
  icon: 'pizza',
  buttonContent: 'Go to Analytics',
  onClick: () => {}
}

export default withTheme(NoDataMessage)
