import { gql, useMutation } from '@apollo/client'

export const useUpdateSchedule = () => {
  const [mutate] = useMutation(gql`
    mutation m($qvestId:String!, $patch:SchedulePatch!) {
      updateSchedule(qvestId: $qvestId, patch: $patch) {
        inviteAt,
        inviteStatus,
        openAt,
        openStatus,
        closeAt
        closeStatus
      }
    }
  `)

  return async (qvest, patch) => {
    const options = {
      variables: { qvestId: qvest.qvestId, patch },
      update(cache, result) {
        cache.modify({
          id: cache.identify(qvest),
          fields: {
            schedule(existingSchedule = {}) {
              return { ...existingSchedule, ...result.data.updateSchedule }
            }
          }
        })
      }
    }
    const result = await mutate(options)
    return result.data.updateSchedule
  }
}

export const useToggleScheduleEnabled = () => {
  const [mutate] = useMutation(gql`
    mutation m($qvestId:String!, $enabled:Boolean!) {
      toggleScheduleEnabled(qvestId: $qvestId, enabled: $enabled) {
        inviteAt,
        inviteStatus,
        openAt,
        openStatus,
        closeAt
        closeStatus
      }
    }
  `)

  return async (qvest, enabled) => {
    const options = {
      variables: { qvestId: qvest.qvestId, enabled },
      update(cache, result) {
        cache.modify({
          id: cache.identify(qvest),
          fields: {
            schedule(existingSchedule = {}) {
              return { ...existingSchedule, ...result.data.toggleScheduleEnabled }
            }
          }
        })
      }
    }
    const result = await mutate(options)
    return result.data.updateSchedule
  }
}
