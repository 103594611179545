import { gql, useMutation } from '@apollo/client'

export const useCreateQvest = () => {
  const [mutate] = useMutation(gql`
    mutation m($qvest:QvestInput!) {
      createQvest(qvest: $qvest) {
        qvestId
        name
        language
        topic
        keywords
        state
        headline
        aboutTextSource
        videoUrl
        hasInvite
        inviteSubject
        inviteTextSource
        hasWaves
        hasCalendarInvite
        isArchived
        isDummy
        contactInfo {
          name
          email
          senderName
          senderTitle
        }
      }
    }
  `)
  return async (qvest) => {
    const result = await mutate({ variables: { qvest } })
    return result.data.createQvest
  }
}

export const useUpdateQvest = () => {
  const [mutate] = useMutation(gql`
    mutation m($qvestId:String!, $patch:QvestUpdate!) {
      updateQvest(qvestId: $qvestId, patch: $patch) {
        qvestId
        name
        language
        topic
        keywords
        state
        headline
        aboutTextSource
        videoUrl
        hasInvite
        inviteSubject
        inviteTextSource
        hasWaves
        hasCalendarInvite
        isArchived
        isDummy
        contactInfo {
          name
          email
          senderName
          senderTitle
        }
        validity {
          general
          items {
            type
            name
          }
        }
      }
    }
  `)
  return async (qvestId, patch) => {
    const result = await mutate({ variables: { qvestId, patch } })
    return result.data.updateQvest
  }
}

export const useInitiateVideoUpload = () => {
  const [mutate] = useMutation(gql`
    mutation m($qvestId:String!, $size:Int!) {
      uploadVideo(qvestId: $qvestId, size: $size) {
        url
        uploadUrl
        uploadStatus
        transcodeStatus
      }
    }
  `)

  return async (qvest, size) => {
    const { qvestId } = qvest
    // Update cached Qvest with returned video info
    const options = {
      variables: { qvestId, size },
      update(cache, result) {
        cache.modify({
          id: cache.identify(qvest),
          fields: {
            video() { return result.data.uploadVideo }
          }
        })
      }
    }
    const result = await mutate(options)
    return result.data.uploadVideo
  }
}

export const useRemoveVideo = () => {
  const [mutate] = useMutation(gql`
    mutation m($qvestId:String!) {
      removeVideo(qvestId: $qvestId)
    }
  `)
  return async (qvest) => {
    const { qvestId } = qvest
    // Evict video from cached Qvest
    const options = {
      variables: { qvestId },
      update(cache) {
        cache.evict({
          id: cache.identify(qvest),
          fieldName: 'video'
        })
      }
    }
    const result = await mutate(options)
    return result.data.removeVideo
  }
}
