import React from 'react'
import { FormattedMessage } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import Tooltip from '../../common/Tooltip/Tooltip'


export const ParticipantsHeading = () => {
  const caption = (
    <Tooltip.Area>
      <Tooltip.Reference>
        <Heading variant="heading3" primary>
          <FormattedMessage
            id="owner.QvestParticipantsTab.heading2"
            defaultMessage="participants"
          />
        </Heading>
      </Tooltip.Reference>
      <Tooltip>
        <Heading.h3>
          <FormattedMessage
            id="owner.QvestParticipantsTab.helpTooltip.heading"
            defaultMessage="Participants"
          />
        </Heading.h3>
        <Typography.p variant="small" weight="light">
          <FormattedMessage
            id="owner.QvestParticipantsTab.helpTooltip.content1"
            defaultMessage="You will get the most out of Qvest with 10-150 participants."
          />
        </Typography.p>
        <Typography.p variant="small" weight="light">
          <FormattedMessage
            id="owner.QvestParticipantsTab.helpTooltip.content2"
            defaultMessage="You can import an Excel file with participant names and email addresses or add them manually."
          />
        </Typography.p>
        <Typography.p variant="small" weight="light">
          <FormattedMessage
            id="owner.QvestParticipantsTab.helpTooltip.content3"
            defaultMessage="Make sure you have two columns in the Excel file you import – providing each participant with:"
          />
        </Typography.p>
        <ol>
          <li>
            <Typography variant="small" weight="light">
              <FormattedMessage defaultMessage="Name" id="owner.QvestParticipantsTab.helpTooltip.listItem1" />
            </Typography>
          </li>
          <li>
            <Typography variant="small" weight="light">
              <FormattedMessage defaultMessage="E-mail" id="owner.QvestParticipantsTab.helpTooltip.listItem2" />
            </Typography>
          </li>
        </ol>
      </Tooltip>
    </Tooltip.Area>
  )
  return (
    <span>
      <Heading variant="heading3">
        <FormattedMessage
          id="owner.QvestParticipantsTab.heading1"
          defaultMessage="Add your"
        />
        {' '}
      </Heading>
      {caption}
    </span>
  )
}
