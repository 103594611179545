import React, { Component } from 'react'
import styled from 'styled-components'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'

import AskInput from '../AskInput/AskInput'
import SubmitButton from '../SubmitButton/SubmitButton'
import AskDisclaimer from '../AskDisclaimer/AskDisclaimer'

const messages = defineMessages({
  closedContentOverride: { id: 'qvest.QvestAnswer.closedContentOverride', defaultMessage: 'Qvest is closed' },
  contentPlaceholder: { id: 'qvest.QvestAnswer.contentPlaceholder', defaultMessage: 'Write your answer here' }
})


const ComponentRoot = styled.form`
  & > * { display: block; }
`

const MessageRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`

const HorizontalRule = styled.hr`
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.one[3]};
  margin: 20px 0 30px 0;
`

const StyledSubmit = styled(SubmitButton)`
  align-self: flex-start;
  margin-top: 50px;
`

const Label = styled.label`
  font-family: ${({ theme }) => theme.font2};
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.one[0]};
`

const QuestionContent = styled.span`
  font-family: ${({ theme }) => theme.font4};
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.one[0]};
  margin: 10px 0;
  white-space: pre-wrap;
`

class QvestAnswer extends Component {
  state = {
    answer: '',
    focused: false
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    this.setState({ focused: false })
  }

  renderSubmit() {
    const { loading, error } = this.props
    const { answer } = this.state
    const errorMessage = error ? error.message : ''
    const cta = (answer != null) && (answer !== '')
    return (
      <StyledSubmit
        cta={cta}
        variant="SEND_ANSWER"
        disabled={!cta}
        isLoading={loading}
        errorMessage={errorMessage}
      />
    )
  }

  render() {
    const { questionContent, closed, isJam, intl } = this.props
    const closeContentOverride = intl.formatMessage(messages.closedContentOverride)
    return (
      <ComponentRoot onSubmit={this.handleSubmit}>
        <Label>
          <FormattedMessage id="qvest.QvestAnswer.contentHeading" defaultMessage="A question for you:" />
        </Label>
        <QuestionContent>
          {questionContent}
        </QuestionContent>
        <HorizontalRule />
        <Label>
          <FormattedMessage id="qvest.QvestAnswer.inputHeading" defaultMessage="Your answer:" />
        </Label>
        <MessageRow>
          <AskInput
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            placeholder={intl.formatMessage(messages.contentPlaceholder)}
            onChange={this.handleChange}
            value={closed ? closeContentOverride : this.state.answer}
            disabled={closed}
          />
        </MessageRow>
        {!isJam ? <AskDisclaimer variant="answer" visible={this.state.focused} /> : null}
        <HorizontalRule />
        {!closed ? this.renderSubmit() : null}
      </ComponentRoot>
    )
  }

  handleChange = (event) => {
    this.setState({ answer: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.onSubmit({ content: this.state.answer })
  }
}

export default injectIntl(QvestAnswer)
