import Immutable, { Map } from 'immutable'
import pickBy from 'lodash/pickBy'

let INITIAL_STATE = Immutable.fromJS({
  isLoading: false,
  isDirty: false,
  error: null,
  data: {},
  unsaved: null
})


const unsaved = (state = new Map(), action) => {
  switch (action.type) {
    case 'UNSAVED_CONFIGURATION_CHANGE':
      return state.mergeDeep(action.configuration)
    case 'CLEAR_GROUP_GRAPH_COORDINATES':
      return state.set('groupGraph', new Map())
    default:
      return state
  }
}


const configuration = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_CONFIGURATION_REQUEST':
    case 'UPDATE_CONFIGURATION_REQUEST':
      return state
        .set('isLoading', true)
    case 'GET_CONFIGURATION_SUCCESS':
    case 'UPDATE_CONFIGURATION_SUCCESS':
      return state
        .setIn(['data', action.qvestId], Immutable.fromJS(action.configuration))
        .merge({
          isLoading: false,
          isDirty: false
        })
    case 'GET_CONFIGURATION_FAILURE':
    case 'UPDATE_CONFIGURATION_FAILURE':
      return state
        .merge({
          error: action.error,
          isLoading: false
        })
    case 'INIT_UNSAVED_CONFIGURATION':
      return state
        .merge({
          unsaved: state.getIn(['data', action.qvestId]),
          isDirty: false
        })
    case 'UNSAVED_CONFIGURATION_CHANGE':
    case 'CLEAR_GROUP_GRAPH_COORDINATES':
      return state
        .update('unsaved', us => unsaved(us, action))
        .set('isDirty', true)
    default:
      return state
  }
}

export function filterGroupGraphConfig(configuration, qvestId) {
  if(!configuration.data.hasOwnProperty(qvestId)) {
    return null
  }
  if(!configuration.data[qvestId].groupGraph) {
    return {}
  }
  const groupGraphConfigNoNulls = pickBy(configuration.data[qvestId].groupGraph) // Remove null properties (legacy requirement from REST interface)
  return groupGraphConfigNoNulls
}

export function filterFeaturesConfig(configuration, qvestId) {
  if(!configuration.data.hasOwnProperty(qvestId)) {
    return null
  }
  if(!configuration.data[qvestId].features) {
    return {}
  }
  return configuration.data[qvestId].features
}

export function filterUnsavedGroupGraphConfig(configuration) {
  if(!configuration.unsaved) {
    return null
  }
  if(!configuration.unsaved.groupGraph) {
    return {}
  }
  const groupGraphConfigNoNulls = pickBy(configuration.unsaved.groupGraph) // Remove null properties (legacy requirement from REST interface)
  return groupGraphConfigNoNulls
}

export default configuration
