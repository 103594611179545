import React from 'react'
import styled from 'styled-components'
import EditableSpan from '../../common/EditableSpan2/EditableSpan'
import Typography from '../../common/Typography/Typography'
import Input from '../../common/Input/Input'
import Icon from '../../common/Icon/Icon'

const Title = styled.div`
  padding: 5px;
  overflow: hidden;

  & > span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const ComponentRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 25px 15px 25px;
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  /* fix editable span overlap */
  ${({ isEditing }) => isEditing ? '' : 'padding-right: 70px;'}
`

const FixedWidthInput = styled(Input)`
  width: 173px;
`

const FilterIcon = styled(Icon)`
  display: ${({ hidden }) => hidden ? 'none' : 'unset'};
  position: absolute;
  padding: 3px 5px 3px 5px;
  border-radius: 50%;
  top: 28px;
  right: 20px;
  background-color: ${({ isFocused, theme }) => isFocused ? theme.one[0] : ''};
`

const NameEditor = ({ show, theme, onDone, onCancel, onEdit }) => {
  if (!show) return null
  return (
    <EditableSpan.Edit
      clickOutsideToCancel={false}
      onDone={() => onDone(theme)}
      onCancel={() => onCancel(theme.themeId, theme.isSuggesting)}
    >
      <FixedWidthInput
        autoFocus
        type="text"
        value={theme.name}
        onChange={event => onEdit(theme.themeId, { name: event.target.value })}
      />
    </EditableSpan.Edit>
  )
}

const NameViewer = ({ show, theme, onStartEdit, onDelete, onToggleCollapsed }) => {
  if (!show) return null
  const { themeId, name } = theme
  return (
    <EditableSpan.View
      showEditIcon={true}
      onStartEdit={() => onStartEdit(theme)}
      onRemove={() => onDelete(themeId)}
      onCollapse={() => onToggleCollapsed(themeId, true)}
    >
      <Title>
        <Typography variant="heading3">{name}</Typography>
      </Title>
    </EditableSpan.View>
  )
}

const ColumnHeader = (props) => {
  const { theme, disabled, focusable, onSort, onToggleCollapsed } = props
  const { themeId, name, isCollapsed, isFocused } = theme
  const isEditing = (theme.isEditing != null ? theme.isEditing : false)

  if (isCollapsed) {
    return (
      <ComponentRoot isEditing={true} disabled={disabled}>
        <Title>
          <Typography variant="heading3">{name}</Typography>
        </Title>
        <Icon
          clickable
          title="Expand"
          variant="chevron-down"
          onClick={() => onToggleCollapsed(themeId, false)}
        />
      </ComponentRoot>
    )
  }

  return (
    <ComponentRoot isEditing={isEditing} disabled={disabled}>
      <EditableSpan fluid>
        <NameViewer show={!isEditing} {...props} />
        <NameEditor show={isEditing} {...props} />
      </EditableSpan>
      <FilterIcon clickable variant="crosshairs" onClick={() => onSort(themeId)} isFocused={isFocused} inverted={isFocused} hidden={!focusable || isEditing} />
    </ComponentRoot>
  )
}

export default ColumnHeader
