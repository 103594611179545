import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import * as d3 from 'd3'
import memoize from 'lodash/memoize'

import { makeGroupColorScales } from '../../../utils/d3Utils'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Tooltip, { TooltipCard } from '../../common/Tooltip/Tooltip'
import GroupList from '../../common/GroupList/GroupList'
import QuestionAnswerCircles from '../../common/QuestionAnswerCircles/QuestionAnswerCircles'
import {
  Title,
  Content,
  MainCard,
  Card,
  SubContent
} from '../AnalyticsLayout/AnalyticsLayout'

const MainCardLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  & > :first-child {
    text-align: center;
    max-width: 400px;
    flex-grow: 0;
    flex-shrink: 1;
  }
  & > :nth-child(2) {
    flex-grow: 0;
    flex-shrink: 3;
    max-width: 560px;
  }
  /* fix tooltip padding / margin */
  & > *:last-child ${TooltipCard} { padding: 0; }
  & > *:last-child ${TooltipCard} > * { margin: 0; }
`

const GeneralCardLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & > :first-child {
    width: 100%;
    flex: 0 0 auto;
  }
  & > :nth-child(2) {
    flex: 0 0 auto;
  }
`


const messagesHeading = defineMessages({
  reactive: { id: 'owner.MindsetPage.mainHeading.reactive', defaultMessage: '{count} reactive {count, plural, one {group} other {groups} }' },
  proactive: { id: 'owner.MindsetPage.mainHeading.proactive', defaultMessage: '{count} proactive {count, plural, one {group} other {groups} }' },
  active: { id: 'owner.MindsetPage.mainHeading.active', defaultMessage: '{count} Q&A {count, plural, one {group} other {groups} }' },
  inactive: { id: 'owner.MindsetPage.mainHeading.inactive', defaultMessage: '{count} inactive {count, plural, one {group} other {groups} }' },
})

const messagesSubHeading = defineMessages({
  reactive: { id: 'owner.MindsetPage.mainHeading.reactiveSub', defaultMessage: 'answered more questions than they asked' },
  proactive: { id: 'owner.MindsetPage.mainHeading.proactiveSub', defaultMessage: 'asked more questions than they answered' },
  active: { id: 'owner.MindsetPage.mainHeading.activeSub', defaultMessage: 'asked and answered the same amount of questions' },
  inactive: { id: 'owner.MindsetPage.mainHeading.inactiveSub', defaultMessage: 'did not ask or answer any questions' }
})

const messagesTooltip = defineMessages({
  title: { id: 'owner.MindsetPage.mainTooltip.title', defaultMessage: 'Mindset explanation' },
  reactive: { id: 'owner.MindsetPage.mainTooltip.reactive', defaultMessage: 'A group is considered reactive when the stakeholders answer more questions than the ask. Similarly, a group is considered proactive when the stakeholders ask more questions than they answer, and active when they ask and answer the same amount of questions. When the stakeholders in a group neither ask nor answer any questions, the group is considered inactive.' },
  proactive: { id: 'owner.MindsetPage.mainTooltip.proactive', defaultMessage: 'A group is considered proactive when the stakeholders ask more questions than they answer. Similarly, a group is considered reactive when the stakeholders answer more questions than they ask, and active when they ask and answer the same amount of questions. When the stakeholders in a group neither ask nor answer any questions, the group is considered inactive.' },
  active: { id: 'owner.MindsetPage.mainTooltip.active', defaultMessage: 'A group is considered reactive when the stakeholders ask and answer the same amount of questions. Similarly, a group is considered proactive when the stakeholders ask more questions than they answer, and reactive when the stakeholders answer more questions than they ask. When the stakeholders in a group neither ask nor answer any questions, the group is considered inactive.' },
  inactive: { id: 'owner.MindsetPage.mainTooltip.inactive', defaultMessage: 'A group is considered inactive when the stakeholders didn’t ask or answer any questions. Similarly, a group is considered proactive when the stakeholders ask more questions than they answer, and reactive when the stakeholders answer more questions than they ask. When the stakeholders in a group ask and answer the same amount of questions, the group is considered active.' }
})


function round2(value) {
  return Math.round(value * 100) / 100
}

function ratePercentage(subset, total) {
  if (total === 0) return null
  const rate = (subset / total)
  return round2(rate * 100)
}

function computeGroupDistribution(participation) {
  // Compute per participant values
  const totalQuestions = d3.sum(participation.map(group => group.questionSentCount))
  const totalAnswers = d3.sum(participation.map(group => group.answerSentCount))
  const distribution = participation.map(group => ({
    ...group,
    id: group.groupId,
    questionsPercent: ratePercentage(group.questionSentCount, totalQuestions),
    answersPercent: ratePercentage(group.answerSentCount, totalAnswers)
  }))
  const distributionAll = {
    id: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
    name: 'All',
    questionSentCount: totalQuestions,
    answerSentCount: totalAnswers,
    questionsPercent: 100,
    answersPercent: 100
  }
  return { distribution, distributionAll }
}

export const formatData = memoize((participation) => {
  let distribution, distributionWithAll
  let categories = [
    { key: 'reactive', groups: [] },
    { key: 'proactive', groups: [] },
    { key: 'active', groups: [] },
    { key: 'inactive', groups: [] }
  ]
  if (participation) {
    const GroupDistribution = computeGroupDistribution(participation)
    distribution = GroupDistribution.distribution
    distributionWithAll = distribution.concat([GroupDistribution.distributionAll])

    categories = [
      { key: 'reactive', groups: distribution.filter(g => g.questionSentCount < g.answerSentCount) },
      { key: 'proactive', groups: distribution.filter(g => g.questionSentCount > g.answerSentCount) },
      {
        key: 'active', groups: distribution.filter(g =>
          (g.questionSentCount > 0 || g.answerSentCount > 0) &&
          (g.questionSentCount === g.answerSentCount))
      },
      { key: 'inactive', groups: distribution.filter(g => !g.questionSentCount && !g.answerSentCount) }
    ]
  }

  categories.sort((a, b) => b.groups.length - a.groups.length)

  return {
    distribution,
    distributionWithAll,
    categories
  }
})

class AnalyticsMindset extends Component {
  generateHeadingText(type, count) {
    const { intl } = this.props
    return {
      heading: intl.formatMessage(messagesHeading[type], { count }),
      subHeading: intl.formatMessage(messagesSubHeading[type]),
      tooltipTitle: intl.formatMessage(messagesTooltip.title),
      tooltipText: intl.formatMessage(messagesTooltip[type])
    }
  }

  renderMainHeading(type, groupCount) {
    const { heading, subHeading, tooltipTitle, tooltipText } = this.generateHeadingText(type, groupCount)
    return (
      <Title>
        <span>
          <Tooltip.Area>
            <Tooltip.Reference>
              <Heading variant="heading2" primary>{heading}</Heading>
            </Tooltip.Reference>
            <Tooltip>
              <Heading.h3>{tooltipTitle}</Heading.h3>
              <Typography variant="medium" weight="light">{tooltipText}</Typography>
            </Tooltip>
          </Tooltip.Area>
          <Heading variant="heading2">{' ' + subHeading}</Heading>
        </span>
      </Title>
    )
  }

  renderGeneralHeading(type, groupCount) {
    const { heading, subHeading } = this.generateHeadingText(type, groupCount)
    return (
      <Title>
        <Heading.h3>{heading}</Heading.h3>
        <Typography variant="medium" transparent>{subHeading}</Typography>
      </Title>
    )
  }

  renderMore(groups, colors) {
    if (groups.length <= 9) return null
    const hiddenGroups = groups.slice(9)
    return (
      <Tooltip.Area>
        <Tooltip.Reference>
          <Typography variant="small" weight="light">
            <FormattedMessage
              id="owner.AnalyticsMindset.more"
              defaultMessage="... and {count} more {count, plural, one {group} other {groups}}"
              values={{ count: hiddenGroups.length }}
            />
          </Typography></Tooltip.Reference>
        <Tooltip>
          <GroupList maxRows={100} transparent>
            {hiddenGroups.map(group => (
              <GroupList.SingleSquare
                transparent
                key={group.groupId}
                colors={colors}
                group={group}
              />
            ))}
          </GroupList>
        </Tooltip>
      </Tooltip.Area>
    )
  }

  render() {
    const { participation, theme } = this.props
    const { distribution, categories } = formatData(participation, theme)

    let primaryColors
    if (participation) {
      const ids = distribution.map(g => g.id)
      primaryColors = makeGroupColorScales(theme, ids).primaryColors
    }

    const mainCategory = categories[0]

    return (
      <Content>
        <MainCard highlighted>
          <MainCardLayout>
            {this.renderMainHeading(mainCategory.key, mainCategory.groups.length)}
            <QuestionAnswerCircles
              size="large"
              // cap at 9 circles
              groups={mainCategory.groups.slice(0, 9)}
              colors={primaryColors}
            />
            {this.renderMore(mainCategory.groups, primaryColors)}
          </MainCardLayout>
        </MainCard>
        <SubContent>
          {categories.slice(1, 4).map((category, i) => (
            <Card key={category.id}>
              <GeneralCardLayout>
                {this.renderGeneralHeading(category.key, category.groups.length)}
                <QuestionAnswerCircles
                  size={(i === 0) ? 'medium' : 'small'}
                  // cap secondary at 3 and everything else at 1
                  groups={category.groups.slice(0, i === 0 ? 3 : 1)}
                  colors={primaryColors}
                />
              </GeneralCardLayout>
            </Card>
          ))}
        </SubContent>
      </Content>
    )
  }

  static propTypes = {
    participation: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.string,
      name: PropTypes.string,
      questionSentCount: PropTypes.number,
      answerSentCount: PropTypes.number,
    }))
  }
}

export default withTheme(injectIntl(AnalyticsMindset))
