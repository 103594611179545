import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import GenericModal from '../../common/GenericModal/GenericModal'


class DeleteParticipantModal extends Component {
  render() {
    const { participant, isSuspension, isPlural, onSubmit, onCancel } = this.props
    let message, title, wide
    if (isSuspension) {
      wide = true
      title = (
        <Typography id="dialog-label" variant="heading3">
          <FormattedMessage
            id="owner.DeleteParticipantModal.suspensionTitle"
            defaultMessage="Participant suspension"
          />
        </Typography>
      )
      message = (
        <div id="dialog-desc">
          <Typography.p weight="light">
            <FormattedMessage
              id="owner.DeleteParticipantModal.suspensionMessage1"
              defaultMessage="Suspended participants cannot access the Qvest and cannot receive further questions from other participants."
            />
            <br />
            <FormattedMessage
              id="owner.DeleteParticipantModal.suspensionMessage2"
              defaultMessage="Any questions already received or asked by the suspended participant remain part of the output."
            />
          </Typography.p>
          <Typography.p weight="light">
            <FormattedMessage
              id="owner.DeleteParticipantModal.suspensionMessage3"
              defaultMessage="Are you sure you wish to suspend {participant} from the Qvest?"
              values={{ participant: <Typography weight="bold">{participant.name} ({participant.email})</Typography> }}
            />
          </Typography.p>

        </div>
      )
    } else if (isPlural) {
      title = (
        <Typography id="dialog-label" variant="heading3">
          <FormattedMessage
            id="owner.DeleteParticipantModal.clearTitle"
            defaultMessage="Clear participant list"
          />
        </Typography>
      )
      message = (
        <div id="dialog-desc">
          <Typography weight="light">
            <FormattedMessage
              id="owner.DeleteParticipantModal.clearMessage"
              defaultMessage="Are you sure you want to clear your participant list?"
            />
          </Typography>
        </div>
      )
    } else {
      title = (
        <Typography id="dialog-label" variant="heading3">
          <FormattedMessage
            id="owner.DeleteParticipantModal.removeTitle"
            defaultMessage="Participant removal"
          />
        </Typography>
      )
      message = (
        <div id="dialog-desc">
          <Typography weight="light">
            <FormattedMessage
              id="owner.DeleteParticipantModal.removeMessage"
              defaultMessage="Are you sure you want to remove {name} from the Qvest?"
              values={{ name: <Typography weight="bold">{participant.name}</Typography> }}
            />
          </Typography>
        </div>
      )
    }

    const modalProps = {
      wide,
      title,
      onCancel,
      onSubmit: () => onSubmit(participant),
      yes: true,
      no: true
    }
    return (
      <GenericModal {...modalProps}>
        {message}
      </GenericModal>
    )
  }
}

DeleteParticipantModal.propTypes = {
  participant: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  isSuspension: PropTypes.bool,
  isPlural: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

DeleteParticipantModal.defaultProps = {
  isSuspension: false,
  isPlural: false,
  onSubmit: () => { },
  onCancel: () => { }
}

export default DeleteParticipantModal
