import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Icon from '../../common/Icon/Icon'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'

export const PageHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1270px;
  max-width: 1400px;
  padding: 20px 30px;
  & > *:nth-child(-n+3) {
    width: 33.33%;
    display: flex;
  }
  & > *:first-child > *:first-child { margin-right: 20px; }
  & > *:nth-child(2) { justify-content: center; }
  & > *:nth-last-child(2) { justify-content: flex-end; }
  & > *:nth-last-child(2) > * + * { margin-left: 10px; }
`

const Back = styled.button`
  outline: none;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
  & > *:first-child { margin-right: 10px; }
  & > * { transition: color 250ms ease-in; }

  &:hover, &:focus {
    & > * { color: ${({ theme }) => theme.color[1]}; }
  }
`

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: -80px;
  right: 0;
  height: 300px;
  min-width: 100vw;
  background-color: ${({ theme }) => theme.inverted.two[0]};
  z-index: -10;
`

export const Title = styled.div`
  & > span { line-height: 140%; }
  & > * { display: block; }
  & > *:nth-child(2) { margin-top: 5px; }
`

export const TitleBar = ({ onBack, title, description, children }) => (
  <PageHeader>
    <div>
      <Back onClick={onBack}>
        <Icon inverted clickable variant="arrow-left" />
        <Heading variant="heading4" inverted>
          <FormattedMessage id="owner.AnalyticsLayout.back" defaultMessage="Back" />
        </Heading>
      </Back>
      <Typography inverted variant="medium" weight="light" transparent>{description}</Typography>
    </div>
    <div>
      <Title>
        <Heading.h3 inverted>{title}</Heading.h3>
      </Title>
    </div>
    <div>
      {children}
    </div>
    <Backdrop />
  </PageHeader>
)

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 1270px;
  max-width: 1400px;
  padding: 0 30px;
  & > * { width: calc(50% - 15px); }
`

export const SingleColumn = styled.div`
  min-width: 1270px;
  max-width: 1400px;
`

export const SubContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    height: calc(50% - 15px);
    width: calc(50% - 15px);
  }
  & > *:first-child {
    margin-bottom: 30px;
    width: 100%;
  }
`

export const CardRaw = styled.div`
  background-color: ${({ theme }) => theme.background1};
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.06);
`

export const Card = styled(CardRaw)`
  padding: 30px;
`

const MainWrapper = styled.div`
  position: relative;
  height: 0;
  padding-bottom: calc(50% - 15px);
`

const MainContent = styled(Card)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const HighlightedCorner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 30px 0;
  border-color: rgba(0, 0, 0, 0);
  border-right-color: ${({ theme }) => theme.main.primary};
`

export const MainCard = ({ highlighted, children }) => (
  <MainWrapper>
    <MainContent>{children}</MainContent>
    {highlighted ? <HighlightedCorner /> : null}
  </MainWrapper>
)
