import { gql, useMutation, useApolloClient } from '@apollo/client'
import update from 'immutability-helper'


export const useAssignTheme = () => {
  const [mutate] = useMutation(gql`
    mutation m ($questionId: String!, $themeSetId: String!, $themeId: String, $position: Float) {
      assignQuestionTheme(themeSetId: $themeSetId, themeId: $themeId, questionId: $questionId, position: $position) {
        questionId
        createdAt
        questionContent
        questionSentAt
        questionGroupId
        answerContent
        answerSentAt
        answerGroupId
        isFirstQuestion
        themes {
          assignments {
            position
            theme {
              themeId
              name
              themeSet {
                themeSetId
              }
            }
            themeSet {
              themeSetId
            }
          }
        }
      }
    }
  `)
  return async (questionId, themeSetId, themeId, position) => {
    const result = await mutate({ variables: { questionId, themeSetId, themeId, position } })
    return result.data.assignQuestionTheme
  }
}

export const useToggleQuestionPin = () => {
  const [mutate] = useMutation(gql`
    mutation m ($questionId: String!, $isPinned: Boolean!) {
      toggleQuestionPin(questionId: $questionId, isPinned: $isPinned) {
        questionId
        pins {
          count
        }
      }
    }
  `)
  return async (qvestId, questionId, isPinned) => {
    const options = {
      variables: { questionId, isPinned },
      update(cache) {
        cache.modify({
          id: `Qvest:${qvestId}`,
          fields: {
            summary(existingSummary = {}) {
              return update(existingSummary, {
                pins: value => (value || 0) + (isPinned ? 1 : -1)
              })
            }
          }
        })
      }
    }
    const result = await mutate(options)
    return result.data.toggleQuestionPin
  }
}

export const useGetThemeComparison = (qvestId) => {
  const client = useApolloClient()
  return (themeId) => client.query({
    query: gql`
      query q($filter:QuestionSearchFilter!, $compare:QuestionSearchCompare) {
        questions(filter:$filter, compare:$compare) {
          items {
            questionId
            themes {
              assignments {
                position
                theme {
                  themeId
                  name
                  themeSet {
                    themeSetId
                  }
                }
                themeSet {
                  themeSetId
                }
              }
              comparisons {
                score
                theme {
                  themeId
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      filter: { qvestId },
      compare: { themeId }
    },
    fetchPolicy: 'network-only'
  }).then(({ data }) => data.questions.items)
}
