import React, { useState, useEffect, useRef, Fragment } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import { getQueryStringValue } from '../../utils/stringUtils'
import * as authUtils from '../../actions/utils/authUtils'
import Card from '../../components/common/Card/Card'
import Heading from '../../components/common/Typography/Heading'
import Typography from '../../components/common/Typography/Typography'
import Button from '../../components/common/Button/Button'
import Input from '../../components/common/Input/Input'


const ComponentRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const LoginCard = styled(Card)`
  max-width: 460px;
`

const Form = styled.form`
  & label {
    display: block;
    margin-top: 18px;
  }
  & label > *:first-child {
    display: inline-block;
    margin-bottom: 4px;
  }
`

const Label = styled.label`
  font-family: ${({ theme }) => theme.font3};
  font-weight: 900;
  font-size: 13px;
  line-height: 15px;
  color: ${({ theme }) => theme.one[0]};
`

// TODO: Replace with ids when possible
const MISSING_ID_TOKEN = 'Authentication failed because no id_token was returned. Please accept the "openid" permission and try again.'
const MISSING_PERMISSION = 'Unable to finish because one or more permissions were not granted. Please retry and accept all permissions.'


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const OIDCFormField = ({ type, name, value }) => {
  switch(type) {
    //  return (<button type="submit" name={name} value={value}>Sign in</button>)
    case 'hidden':
    case 'submit':
      return (<input type="hidden" name={name} value={value} />)
    case 'text':
      return (
        <Label for={name}>
          <span>{capitalizeFirstLetter(name)}</span>
          <Input type="text" name={name} value={value} />
        </Label>
      )
    default:
      return null
  }
}




const OIDCForm = ({ config, error }) => {
  const formRef = useRef(null)

  useEffect(() => {
    if (!error) {
      // Automatically submit upon form load if there are no errors (options have been filtered to single OIDC provider)
      formRef.current.submit()
    }
  }, [formRef])

  const { action, method, fields } = config
  return (
    <Form ref={formRef} action={action} method={method}>
      <Card.Body>
        <div>
          <Typography>Redirecting to secure login.</Typography>
        </div>
        {fields.map((field) => (<OIDCFormField key={field.name} {...field} />))}
      </Card.Body>
      <Card.Footer>
        <Button>Continue</Button>
      </Card.Footer>
    </Form>
  )
}

const Body = ({ config, error }) => {
  if (error && !config) {
    return (
      <Card.Body />
    )
  }

  if (!config) {
    return  (
      <Fragment>
        <Card.Body>
          <Typography>Checking login method.</Typography>
        </Card.Body>
        <Card.Footer>
          <Button disabled>Loading...</Button>
        </Card.Footer>
      </Fragment>
    )
  }

  return <OIDCForm config={config} error={error} />
}

const Message = ({ error }) => {
  let errorStyle = true
  if (error) {
    let message = 'Something went wrong! We have made a note of the issue and will look into it. Please try again later.'
    if (error.text === MISSING_ID_TOKEN || error.text === MISSING_PERMISSION) {
      message = 'Insufficient permissions granted to log into Qvest. Please try again and ensure that consent is given to all requested permissions'
    }
    if (error.id === 1) {
      errorStyle = false
      message = 'You are successfully logged in. Admin access required to proceed. Please contact us for further information on how to obtain access.'
    }
    return (
      <Card.Message error={errorStyle}>
        <Typography>{message}</Typography>
      </Card.Message>
    )
  }
  return null
}


const OidcPage = ({ match }) => {
  const { variant } = match.params

  const [config, setConfig] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const getAuthFlowState = async () => {
      try {
        if (variant === 'errors') {
          const errorId = getQueryStringValue('error')
          const errors = await authUtils.getFlowErrors(errorId)
          setError(errors[0])
        } else if (variant === 'unauthorized') {
          setError({ id: 1, type: 'error' })
        } else {
          const flowId = getQueryStringValue('flow')
          const config = await authUtils.getFlowConfig(flowId, variant)
          const configError = config && config.messages && config.messages.find(m => m.type === 'error')
          if (configError) {
            setError(configError)
          }
          setConfig(config)
        }
      } catch {
        setError({ id: -1, type: 'error' })
      }
    }
    getAuthFlowState()
  }, [])

  return (
    <ComponentRoot>
      <Helmet>
        <title>Log in</title>
      </Helmet>
      <LoginCard>
        <Card.Header>
          <Heading variant="heading2">Log in</Heading>
        </Card.Header>
        <Message error={error} config={config} />
        <Body error={error} config={config} />
      </LoginCard>
    </ComponentRoot>
  )
}

export default OidcPage
