import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import EmailLoginCard from '../../components/auth/EmailLoginCard/EmailLoginCard'
import { sendParticipantLogin } from '../../actions/participantActions'
import { getQueryStringValue } from '../../utils/stringUtils'


const ComponentRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`


const ParticipantLoginPage = ({ dispatch, authStore }) => {
  const [status, setStatus] = useState('')

  useEffect(() => {
    const status = getQueryStringValue('status')
    if (status) {
      setStatus(status)
    }
  }, [])

  const handleLogin = (email) => {
    const qvestId = getQueryStringValue('qvestId')
    dispatch(sendParticipantLogin(qvestId, email))
  }

  return (
    <ComponentRoot>
      <Helmet>
        <title>Login {status}</title>
      </Helmet>
      <EmailLoginCard
        isLoading={authStore.isLoading}
        status={status}
        error={authStore.error}
        wasSent={authStore.wasSent}
        onSend={handleLogin}
      />
    </ComponentRoot>
  )
}

function mapStateToProps(state) {
  return {
    authStore: state.auth.toJS()
  }
}

export default connect(mapStateToProps)(ParticipantLoginPage)
