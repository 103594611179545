import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { injectIntl, defineMessages } from 'react-intl'

import ItemTypes from './ItemTypes'
import Typography from '../../common/Typography/Typography'
import styled from 'styled-components'
import Icon from '../../common/Icon/Icon'


const determineBackgroundColor = ({ theme, inverted, dragging }) => {
  if (dragging) {
    if (inverted) return theme.inverted.one[2]
    return theme.one[3]
  }
  if (inverted) return theme.inverted.two[1]
  return theme.two[0]
}

const FloatingUpperRight = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: ${(props) => determineBackgroundColor(props)};
`

const FloatingLowerRight = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: default;
`

const ComponentRoot = styled.div`
  padding: 0px 10px 10px 10px;
`

const CardMain = styled.div`
  position: relative;
  background-color: ${(props) => determineBackgroundColor(props)};
  padding: 15px 20px;
  border-radius: 4px;
  ${({ inverted }) => inverted ? '' : 'border: 1px solid #edeeee;'}
  cursor: move;
  user-select: none;
  pointer-events: ${({ disabled }) => disabled ? 'none': 'auto'};
  opacity: ${({ disabled }) => disabled ? '0.6': '1.0'};
  height: ${({ dragging }) => dragging ? '120px' : 'auto'};

  & > * {
    opacity: ${({ dragging }) => dragging ? '0' : '1'};
  }

  & > ${FloatingUpperRight} {
    display: ${({ pinned }) => pinned ? 'block' : 'none'};
  }

  &:hover > ${FloatingUpperRight} {
    display: block;
  }

  &:hover > ${FloatingLowerRight} {
    opacity: 0.3;
  }
`

const PinCountSpan = styled.span`
  font-family: ${({ theme }) => theme.font3};
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-align: right;
  color: ${({ inverted, theme }) => inverted ? theme.inverted.one[0] : theme.one[0]};
  margin-right: 2px;
`

const tooltips = defineMessages({
  pinCount: {
    id: 'owner.AnalyticsThemes.Card.tooltip.pinCount',
    defaultMessage: '{count} {count, plural, one {participant} other {participants}} pinned this question'
  },
  sendToTop: {
    id: 'owner.AnalyticsThemes.Card.tooltip.sendToTop',
    defaultMessage: 'Send question to top'
  },
  sendToFocused: {
    id: 'owner.AnalyticsThemes.Card.tooltip.sendToFocused',
    defaultMessage: 'Send to currently focused theme'
  }
})

const PinCountIndicator = injectIntl(({ pins, inverted, hide, intl }) => {
  const count = (pins ? pins.count : 0)
  if (hide || count === 0) return null
  const tooltip = intl.formatMessage(tooltips.pinCount, { count })
  return (
    <div title={tooltip}>
      <PinCountSpan inverted={inverted}>{count}</PinCountSpan>
      <Icon size="small" variant="thumbtack" inverted={inverted} />
    </div>
  )
})

const QuickMoveIcon = injectIntl(({ question, inverted, hasScore, intl, onQuickMove }) => {
  if (hasScore) {
    return (
      <Icon
        clickable
        inverted={inverted}
        variant="arrow-right"
        title={intl.formatMessage(tooltips.sendToTop)}
        onClick={() => onQuickMove(question.questionId)}
      />
    )
  }
  return (
    <Icon
      clickable
      inverted={inverted}
      variant="arrow-up"
      title={intl.formatMessage(tooltips.sendToFocused)}
      onClick={() => onQuickMove(question.questionId)}
    />
  )
})

const Card = (props) => {
  const { theme, question, inverted, disabled, onHovered, onDrop } = props
  const { isUnsaved, isDragging, questionContent, pins } = question
  const questionId = question.questionId
  const hasScore = (theme && theme.themeId === 'default' && question.score)

  // Configure as a drag source
  const [, drag] = useDrag({
    item: { type: ItemTypes.CARD, questionId, question },
    canDrag: () => !isUnsaved,
    end: (item, monitor) => {
      let themeId
      if(monitor.didDrop()) {
        themeId = monitor.getDropResult().themeId
      }
      onDrop(question.questionId, themeId)
    }
  })

  // Configure as a drop target
  // (Only subscribing to hover events, drop events are propagated to parent column)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => false,
    // Activated when question cards are hovered above other cards
    hover: (item) => {
      if (item.questionId !== questionId) {
        onHovered(item.questionId, questionId, theme)
      }
    }
  })

  const ScoreLabel = ({ hide }) => {
    if (hide) return null
    return (
      <Typography secondary variant="heading5">
        {Math.round(question.score * 100)}%
      </Typography>
    )
  }

  return (
    <ComponentRoot ref={node => drag(drop(node))}>
      <CardMain disabled={disabled || isUnsaved} inverted={inverted} dragging={isDragging}>
        <Typography inverted={inverted} variant="small" weight="light">
          {questionContent}
        </Typography>
        <FloatingLowerRight>
          <PinCountIndicator hide={hasScore} inverted={inverted} pins={pins} />
          <ScoreLabel hide={!hasScore} />
        </FloatingLowerRight>
        <FloatingUpperRight inverted={inverted}>
          <QuickMoveIcon {...props} hasScore={hasScore} />
        </FloatingUpperRight>
      </CardMain>
    </ComponentRoot>
  )
}

export default injectIntl(Card)
