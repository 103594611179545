import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'

import Icon from '../../common/Icon/Icon'
import Typography from '../../common/Typography/Typography'
import Table from '../../common/Table/Table'
import { CONTENT_MAP, CONTENT_PRIORITY, HELP_MESSAGES, TITLE_MESSAGES, UTIL_MESSAGES } from './staticContent'


const Row = styled(Table.Row)`
  border-bottom: 1px solid ${({ theme }) => theme.two[0]};

  & > :first-child {
    width: 6px;
    padding: 0;
    background-color: ${({ theme, type }) => theme.alert[type.toLowerCase()]}
  }
  & > *:nth-child(2) {
    padding: 8px 20px 8px 12px;
  }
  & > *:nth-child(3) {
    min-width: 270px;
    padding: 8px 40px 8px 0;
  }
  & > *:nth-child(4) {
    padding: 8px 0;
  }
  & > *:last-child {
    width: 20px;
    margin: 0 20px;
    padding: 8px 12px 8px 8px;
  }
`

const ResourceWrapper = styled.div`
  & > * { display: block; }
`

class ValidationTable extends Component {
  constructor(props) {
    super(props)
    this.renderItem = this.renderItem.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(newRoute) {
    const { onClick } = this.props
    return () => {
      if (!newRoute) return null
      onClick(newRoute)
    }
  }

  renderIcon(type) {
    if (type === 'OK') return <Icon variant="check-circle" ok />
    return type === 'ERROR'
      ? <Icon variant="exclamation-circle" error />
      : <Icon variant="exclamation-triangle" warning />
  }

  renderResource(name, resource) {
    const { intl } = this.props
    const category = CONTENT_MAP[name]
    if (category === 'participants' && resource && resource.participants)
      return <Typography variant="monospace">{resource.participants.length}</Typography>
    if (category === 'topic' && resource && resource.topic)
      return <Typography variant="monospace">{resource.topic}</Typography>
    if (category === 'groups' && resource && resource.groups) {
      const count = resource.groups.length
      const names = resource.groups.map(x => x.name).join(', ')
      return <Typography variant="monospace">{`${count} (${names})`}</Typography>
    }
    if (name === 'PARTICIPANT_QUOTA_EXCEEDED') {
      return <Typography variant="monospace">{resource.participants.length} participants</Typography>
    }
    if ((name === 'NO_CONTACT_EMAIL' || name === 'INVALID_CONTACT_EMAIL') && resource) {
      const email = (resource.contactInfo && resource.contactInfo.email) || intl.formatMessage(UTIL_MESSAGES.none)
      return (
        <Typography variant="monospace">
          {`${intl.formatMessage(UTIL_MESSAGES.contactEmail)}: ${email}`}
        </Typography>
      )
    }
    if (category === 'page' && resource) {
      return (
        <ResourceWrapper>
          <Typography variant="monospace">
            {`${intl.formatMessage(UTIL_MESSAGES.video)}: ${resource.videoUrl
              ? intl.formatMessage(UTIL_MESSAGES.yes)
              : intl.formatMessage(UTIL_MESSAGES.no)}`}
          </Typography>
        </ResourceWrapper>
      )
    }
    if (category === 'email' && resource && resource.inviteSubject) {
      return (
        <Typography variant="monospace">
          {`${intl.formatMessage(UTIL_MESSAGES.subject)}: ${resource.inviteSubject}`}
        </Typography>
      )
    }
    return <span />
  }

  renderItem({ type, name, resource }) {
    const { intl } = this.props
    const contentType = CONTENT_MAP[name]
    const title = contentType && TITLE_MESSAGES[contentType]
    const help = HELP_MESSAGES[name]
    if (!title) return null
    return (
      <Row clickable type={type} onClick={this.handleClick(contentType)} key={name}>
        <td>{/* Colored left-border */}</td>
        <td>
          {intl.formatMessage(title)}
        </td>
        <td>
          {this.renderResource(name, resource)}
        </td>
        <td>
          {help && <Typography weight="light" variant="small">{intl.formatMessage(help)}</Typography>}
        </td>
        <td>
          {this.renderIcon(type)}
        </td>
      </Row>
    )
  }

  render() {
    const { items } = this.props
    if (!items || items.length === 0) return null
    const sortedItems = sortBy(items, item => CONTENT_PRIORITY[CONTENT_MAP[item.name]])
    return (
      <Table odd>
        <tbody>
          {sortedItems.map(this.renderItem)}
        </tbody>
      </Table>
    )
  }
}

ValidationTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    resource: PropTypes.object
  })),
  onClick: PropTypes.func,
}

ValidationTable.defaultProps = {
  items: [],
  onClick: () => { },
}

export default injectIntl(ValidationTable)
