import partition from 'lodash/partition'
import orderBy from 'lodash/orderBy'

export function determineQuestionStatus(question) {
  if (question.answerContent && question.perspective === 'QUESTIONER') {
    return 'ANSWERED'
  } else if (question.answerContent) {
    return 'ANSWERED_BY_SELF'
  } else if (question.questionContent && question.perspective === 'QUESTIONER') {
    return 'ASKED_BY_SELF'
  } else if (question.questionContent) {
    return 'ASKED'
  } else {
    return 'EMPTY'
  }
}

export const filterNotEmptyQuestions = questions => questions
  .filter(question => {
    const status = determineQuestionStatus(question)
    return (status !== 'EMPTY')
  })

export const determineHasAskedAny = questions => {
  return questions.some(question => question.status === 'ASKED_BY_SELF' || question.status === 'ANSWERED')
}

export const getLatestActivityDate = question => {
  const status = determineQuestionStatus(question)
  switch (status) {
    case 'ASKED':
    case 'ASKED_BY_SELF':
      return question.questionSentAt
    case 'ANSWERED':
    case 'ANSWERED_BY_SELF':
      return question.answerSentAt
    default:
      return question.createdAt
  }
}

export const makeQuestionUrl = (qvestId, question) => {
  const { status, questionId } = question
  let action
  if (status === 'ASKED') {
    action = 'answer'
  } else if (status === 'ANSWERED') {
    action = 'view'
  } else {
    action = 'ask'
  }
  return `/qvest/${qvestId}/question/${questionId}/${action}`
}

/*
Partition and sort quest into two prioritized queue based on method-informed urgency
 - outstanding:
   - First question
   - Opportunities to ask
   - Opportunities to answer
   - Unread answers from other participants (moved up 2 places in jams)
 - completed:
   - Awaiting other participants answer
   - Already answered by current participant
   - Already read answers by other participants
*/
export const prioritizeQuestions = (allQuestions, isJam = false) => {
  // Separate own first question to be put first (if no questions have been asked yet)
  let firstQuestion = []
  if (!determineHasAskedAny(allQuestions)) {
    firstQuestion = allQuestions.filter(q => q.status === 'EMPTY')
    allQuestions = allQuestions.filter(q => q.status !== 'EMPTY')
  }

  // All questions into open/closed
  // - Open: Opportunity for current participant to ask or answer
  // - Closed: Awaiting of other participant or already answered
  const [open, closed] = partition(allQuestions, question =>
    (['ASKED', 'EMPTY', 'LOCKED'].indexOf(question.status) !== -1)
  )

  // Open questions into toAsk/toAnswer
  const [toAsk, toAnswer] = partition(open, question => question.status === 'EMPTY')

  // Closed questions into unread/read
  const [unread, read] = partition(closed, question =>
    question.status === 'ANSWERED' && !question.answerReadAt
  )

  // Prioritize reading answers if Qvest is a Jam
  //  - Since you have infinite opportunities to ask
  let outstanding
  if (isJam) {
    // Jam prioritization
    outstanding = [
      ...firstQuestion,
      ...orderBy(unread, 'answerSentAt'),
      ...orderBy(toAnswer, 'questionSentAt', 'desc'),
      ...orderBy(toAsk, 'createdAt', 'desc')
    ]
  } else {
    // Regular Qvest prioritization
    outstanding = [
      ...firstQuestion,
      ...orderBy(toAnswer, 'questionSentAt', 'desc'),
      ...orderBy(toAsk, 'createdAt', 'desc'),
      ...orderBy(unread, 'answerSentAt')
    ]
  }

  return {
    outstanding,
    completed: orderBy(read, 'answerSentAt')
  }
}

/*
  Determine next question to lead the participant to based on logic in
  'prioritizeQuestions' above. Except if the user just answered a question.
  In such cases we always lead the user to an empty question (opportunity to ask).
 */
export const determineTransition = (currentQuestion, questions, isJam) => {
  const { outstanding } = prioritizeQuestions(questions, isJam)
  if (!isJam && currentQuestion.status === 'ANSWERED_BY_SELF') {
    // ONLY REGULAR QVEST: If just answered, skip to first empty question (opportunity to ask)
    return outstanding.find(q => q.status === 'EMPTY')
  } else if (outstanding.length > 0) {
    // ... If any outstanding questions pick one with highest priority
    return outstanding[0]
  }
  // ... Otherwise, return nothing
  return null
}

export const recommendedThemeCount = (questionCount) => {
  if (questionCount < 20) return 3
  else if (questionCount >= 20 && questionCount < 50) return 4
  else if (questionCount >= 50 && questionCount < 100) return 6
  else if (questionCount >= 100) return 8
}
