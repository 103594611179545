import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { PolarChart, focusMapping } from 'data-viz'

import DynamicContainerDimensions from '../DynamicContainerDimensions/DynamicContainerDimensions'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'

const ChartBounds = styled.div`
  min-width: 400px;
  min-height: 400px;
  width: 100%;
  @media (max-width: 899px) {
    min-width: 300px;
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > *:first-child { width: 100%; }
  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
`

class QuestionProfileCard extends Component {
  renderData() {
    const { questionDistribution } = this.props
    const { data } = focusMapping.formatData(questionDistribution)
    return (
      <ColumnWrapper>
        <ChartBounds>
          <DynamicContainerDimensions>
            {({ height, width }) => (
              <PolarChart
                primary
                height={height - 10}
                width={width}
                data={data}
                round
                labels
              />
            )}
          </DynamicContainerDimensions>
        </ChartBounds>
        <div>
          <Heading.h4>
            <FormattedMessage
              id="result.QuestionProfileCard.experience"
              defaultMessage="Experience"
            />
          </Heading.h4>
          <Typography.p weight="light" variant="medium">
            <FormattedMessage
              id="result.QuestionProfileCard.experienceDetails"
              defaultMessage="Groups that combine why-questions with who-, when- and where-questions are often focused on the reasons things are the way they are"
            />
          </Typography.p>
          <Heading.h4>
            <FormattedMessage
              id="result.QuestionProfileCard.process"
              defaultMessage="Process"
            />
          </Heading.h4>
          <Typography.p weight="light" variant="medium">
            <FormattedMessage
              id="result.QuestionProfileCard.processDetails"
              defaultMessage="Groups that combine who-, when- and where-questions with how-questions are often focused on the best way to utilize their resources"
            />
          </Typography.p>
          <Heading.h4>
            <FormattedMessage
              id="result.QuestionProfileCard.purpose"
              defaultMessage="Purpose"
            />
          </Heading.h4>
          <Typography.p weight="light" variant="medium">
            <FormattedMessage
              id="result.QuestionProfileCard.purposeDetails"
              defaultMessage="Groups that combine why-questions with what-questions are often focused on understanding and qualifying their overall goals."
            />
          </Typography.p>
          <Heading.h4>
            <FormattedMessage
              id="result.QuestionProfileCard.result"
              defaultMessage="Result"
            />
          </Heading.h4>
          <Typography.p weight="light" variant="medium">
            <FormattedMessage
              id="result.QuestionProfileCard.resultDetails"
              defaultMessage="Groups that combine what-questions with how-questions are often focused on reaching the goal they set out to reach."
            />
          </Typography.p>
        </div>
      </ColumnWrapper>
    )
  }

  renderError(error, callToAction) {
    let errorMessage
    switch (error.message) {
      case 'NO_DATA':
        errorMessage = (
          <FormattedMessage
            id="result.QuestionProfileCard.noData"
            defaultMessage="Not enough questions and answers to draw question profile."
          />
        )
        break
      default:
        errorMessage = (
          <FormattedMessage
            id="result.QuestionProfileCard.unexpectedError"
            defaultMessage="We're sorry – an unexpected error occurred."
          />
        )
    }
    return (
      <div>
        <Typography cta={callToAction} weight="bold">
          {errorMessage}
        </Typography>
      </div>
    )
  }

  render() {
    const { error, isLoading, questionDistribution, cardRef } = this.props

    let body
    if (error) {
      body = this.renderError(error, true)
    } else if (isLoading) {
      body = (<CenteredLoader height={484} />)
    } else if (questionDistribution == null) {
      body = this.renderError({ message: 'NO_DATA' })
    } else {
      body = this.renderData()
    }

    return (
      <Fragment>
        <Heading.h2 ref={cardRef}>
          <FormattedMessage id="result.QuestionProfileCard.title" defaultMessage="Question Profile" />
        </Heading.h2>
        {body}
      </Fragment>
    )
  }
}

export default QuestionProfileCard
