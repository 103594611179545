import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Dropdown from '../Dropdown/Dropdown'
import GroupNode from '../GroupNode/GroupNode'
import Typography from '../Typography/Typography'


const GroupItem = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  & > :first-child { margin-right: 10px; }
`

const handleChange = onChange => selected => {
  if (!selected) {
    onChange(null)
  } else {
    onChange(selected.value)
  }
}

const GroupsDropdown = (props) => {
  const { groups, value, onChange } = props

  const placeholder = (
    <FormattedMessage defaultMessage="Choose a group" id="owner.GroupsDropdown.placeholder" />
  )

  const options = groups.map(({ groupId, name }, nid) => {
    const node = (
      <GroupItem>
        <GroupNode nid={nid} size="micro" minValue={0} maxValue={5} value={5} />
        <Typography variant="medium">{name}</Typography>
      </GroupItem>
    )
    return { node, value: groupId }
  })

  return (
    <Dropdown
      options={options}
      value={value}
      placeholder={{ text: placeholder }}
      onChange={handleChange(onChange)}
    />
  )
}

GroupsDropdown.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      groupId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.number.isRequired,
}

export default GroupsDropdown
