import * as api from './apiClient'
import { saveAs } from 'file-saver'
import { log } from '../utils/trackingUtils'
import { questionDumpToExcelSheet, questionDistributionToExcelSheet } from '../utils/xlsxUtils'
import { makeFilename, stringToIntHash } from '../utils/stringUtils'

export const getWordFrequencies = qvestId => dispatch => {
  dispatch({ type: 'GET_WORD_FREQUENCIES_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          query q {
            wordFrequency(qvestId: "${qvestId}") {
              items {
                topWord,
                stem,
                count,
                isPotentialOutlier,
                isOutlier,
                words {
                  text,
                  count
                },
                groups {
                  groupId,
                  count
                }
              }
            }
          }
        `
    }
  }
  return api.invoke('/api/graphql', options)
    .then(({ data }) => {
      const frequencies = data.wordFrequency.items
      dispatch({ type: 'GET_WORD_FREQUENCIES_SUCCESS', qvestId, frequencies })
    })
    .catch(error => {
      dispatch({ type: 'GET_WORD_FREQUENCIES_FAILURE', error })
    })
}

export const getGroupGraph = qvestId => dispatch => {
  dispatch({ type: 'GET_GROUP_GRAPH_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          query q {
            groupGraph(qvestId: "${qvestId}", toUndirected: true) {
              groupingId,
              nodes {
                id,
                name,
                value,
                degree,
                connectedness,
                isOutlier,
                isPotentialOutlier
              },
              edges {
                source,
                target,
                value,
                isUniDirectional,
                isOutlier,
                isPotentialOutlier
                themeId,
                themes {
                  themeId,
                  count
                }
              }
            }
          }
        `
    }
  }
  return api.invoke('/api/graphql', options)
    .then(({ data }) => {
      const graph = data.groupGraph
      graph.hash = stringToIntHash(JSON.stringify(graph))
      dispatch({ type: 'GET_GROUP_GRAPH_SUCCESS', qvestId, graph })
    })
    .catch(error => {
      dispatch({ type: 'GET_GROUP_GRAPH_FAILURE', error })
    })
}

export const getQuestionDistribution = qvestId => dispatch => {
  dispatch({ type: 'GET_QUESTION_DISTRIBUTION_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          query q {
            questionProfile(qvestId: "${qvestId}") {
              raw {
                questionId,
                isWhy,
                isWho,
                isWhat,
                isHow
              },
              groups {
                groupId,
                count {
                  why,
                  who,
                  what,
                  how,
                  other
                }
              }
              count {
                why,
                who,
                what,
                how,
                other
              }
            }
          }
        `
    }
  }
  return api.invoke('/api/graphql', options)
    .then(({ data }) => {
      const distribution = data.questionProfile
      dispatch({ type: 'GET_QUESTION_DISTRIBUTION_SUCCESS', qvestId, distribution })
    })
    .catch(error => {
      dispatch({ type: 'GET_QUESTION_DISTRIBUTION_FAILURE', error })
    })
}

export const downloadQuestionProfile = (qvestName, questions, distribution, headers) => dispatch => {
  dispatch({ type: 'DOWNLOAD_QUESTION_DISTRIBUTION_REQUEST' })
  questionDistributionToExcelSheet(qvestName, questions, distribution, headers)
    .then(() => dispatch({ type: 'DOWNLOAD_QUESTION_DISTRIBUTION_SUCCESS' }))
    .catch(error => dispatch({ type: 'DOWNLOAD_QUESTION_DISTRIBUTION_FAILURE', error }))
}

export const getQvestSummary = qvestId => dispatch => {
  dispatch({ type: 'GET_QVEST_SUMMARY_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          query q {
            summary(qvestId: "${qvestId}") {
              answers
              participants
              questions
              groups
            }
          }
        `
    }
  }
  return api.invoke('/api/graphql', options)
    .then(response => {
      const summary = response.data.summary
      dispatch({ type: 'GET_QVEST_SUMMARY_SUCCESS', qvestId, summary })
      return summary
    })
    .catch(error => {
      if (error) log.warning(error)
      dispatch({ type: 'GET_QVEST_SUMMARY_FAILURE', error })
    })
}

export const getQuestionDump = qvestId => dispatch => {
  dispatch({ type: 'GET_QUESTION_DUMP_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          query q {
            questionDump(qvestId: "${qvestId}") {
              answerContent
              answerGroupName
              answerParticipantName
              answerSentAt
              questionContent
              questionGroupName
              questionId
              questionParticipantName
              questionSentAt
              threadId
              metadata
            }
          }
        `
    }
  }
  return api.invoke('/api/graphql', options)
    .then(({ data }) => {
      dispatch({ type: 'GET_QUESTION_DUMP_SUCCESS', qvestId, distribution: data.questionDump })
      return data.questionDump
    })
    .catch(error => {
      dispatch({ type: 'GET_QUESTION_DUMP_FAILURE', error })
    })
}

export const downloadQuestionDump = (qvestId, qvestName) => dispatch =>
  dispatch(getQuestionDump(qvestId))
    .then(questionDump => {
      // return and don't generate anything if fetch failed
      if (!questionDump) return null
      // Convert JSON response to Excel sheet (xlsx)
      const sheet = questionDumpToExcelSheet(questionDump)
      // Download to user file system
      const sheetBlob = new Blob([sheet], { type: 'application/octet-stream' })
      const fileName = makeFilename(qvestName, 'Questions and Answers', 'xlsx')
      saveAs(sheetBlob, fileName)
    })

export const getParticipation = qvestId => dispatch => {
  dispatch({ type: 'GET_PARTICIPATION_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          query q {
            participation(qvestId: "${qvestId}") {
              groupId
              name
              participantCount
              participantActiveCount
              questionReceivedCount
              answerSentCount
              questionSentCount
              answerReceivedCount
            }
          }
        `
    }
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const activity = result.data.participation
      dispatch({ type: 'GET_PARTICIPATION_SUCCESS', qvestId, activity })
    })
    .catch(error => {
      dispatch({ type: 'GET_PARTICIPATION_FAILURE', error })
    })
}

export const getParticipantsActivity = qvestId => dispatch => {
  dispatch({ type: 'GET_PARTICIPANTS_ACTIVITY_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          query q {
            participantActivity(qvestId: "${qvestId}") {
              items {
                participantId,
                name,
                email,
                groupId,
                groupName,
                questionReceivedCount,
                answerSentCount,
                questionSentCount,
                isOutlier,
                isPotentialOutlier
              }
            }
          }
        `
    }
  }
  return api.invoke('/api/graphql', options)
    .then(({ data }) => {
      const activity = data.participantActivity.items
      dispatch({ type: 'GET_PARTICIPANTS_ACTIVITY_SUCCESS', qvestId, activity })
    })
    .catch(error => {
      dispatch({ type: 'GET_PARTICIPANTS_ACTIVITY_FAILURE', error })
    })
}
