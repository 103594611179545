import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Anchor = styled.a`
  &, &:visited, &:link {
    text-decoration: ${props => props.inverted ? 'none' : 'underline'};
    color: inherit;
    cursor: pointer;
  }
  &:hover, &:active {
    text-decoration: ${props => props.inverted ? 'underline' : 'none'};
  }
`

const AnchorLink = (props) => (
  <Anchor as={Link} {...props}>{props.children}</Anchor>
)
Anchor.Link = AnchorLink

Anchor.propTypes = {
  inverted: PropTypes.bool,
}

Anchor.defaultProps = {
  inverted: false,
}

export default Anchor
