import Modal from '../../components/common/Modal/Modal'
import React from 'react'
import { IntlProvider } from 'react-intl'
import styled from 'styled-components'

import PagePreviewModal from '../../components/owner/PagePreviewModal/PagePreviewModal'
import QvestPagePreviewBgPng from '../../static/img/preview/qvest_page_preview_bg.png'
import AskQPreviewBgPng from '../../static/img/preview/ask_q_preview_bg.png'
import OutputPagePreviewBgPng from '../../static/img/preview/output_page_preview_bg.png'


const BG_IMG = {
  invited: QvestPagePreviewBgPng,
  opened: AskQPreviewBgPng,
  closed: OutputPagePreviewBgPng
}

const Background = styled.div`
  background-image: url(${({ variant }) => BG_IMG[variant]});
  background-position: top center;
  background-repeat: no-repeat;
  background-color: ${({ variant }) => (variant === 'closed') ? '#F6F6F3' : '#ffffff'};
  width: 100vw;
  height: 100vh;
`


const PreviewPage = props => {
  const { variant } = props.match.params
  return (
    <IntlProvider>
      <Background variant={variant}>
        <Modal>
          <PagePreviewModal variant={variant} />
        </Modal>
      </Background>
    </IntlProvider>
  )
}

export default PreviewPage
