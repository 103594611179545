// File imports
import './static/index.html'
import './static/img/logo-vertical.png' // NOTE: Referenced by e-mail templates
import './static/img/favicon.ico' // NOTE: Favicon
// Environment variables import
import './utils/loadEnvVars'
// JS imports
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducers from './reducers'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import { ApolloProvider } from '@apollo/client'

import { apolloClient } from './actions/apolloConfiguration'
import * as theme from './components/theme'
import GenericErrorDisplay from './components/common/GenericErrorDisplay/GenericErrorDisplay'
import NotificationOverlay from './containers/owner/NotificationOverlay'
import ParticipantLoginPage from './containers/public/ParticipantLoginPage'
import TopicSuggestionPage from './containers/public/TopicSuggestionPage'
import OwnerLoginErrorPage from './containers/public/OwnerLoginErrorPage'
import GenericErrorPage from './containers/public/GenericErrorPage'
import QvestSection from './containers/participant/QvestSection'
import ResultsPage from './containers/participant/results/ResultsPage'
import OwnerSection from './containers/owner/OwnerSection'
import OwnerSignupPage from './containers/public/OwnerSignupPage'
import GlobalStyles from './global-styles'
import PreviewPage from './containers/public/PreviewPage'
import OidcPage from './containers/public/OidcPage'
import OidcFinalizePage from './containers/public/oidc/OidcFinalizePage'
import JoinPage from './containers/participant/qvest/JoinPage'
import JamSection from './containers/jam/JamSection'
import JamSignupPage from './containers/jam/JamSignupPage'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
let store = createStore(
  reducers,
  undefined,
  composeEnhancers(applyMiddleware(thunkMiddleware))
)

render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient} >
      <ThemeProvider theme={theme.yellow}>
        <GenericErrorDisplay>
          <Helmet titleTemplate="%s | Qvest.io" defaultTitle="Qvest.io" />
          <BrowserRouter>
            <Switch>
              {/* Shared */}
              <Route path="/error" component={GenericErrorPage} />
              {/* Participant */}
              <Route path="/qvest/:qvestId/:page?" component={QvestSection} />
              <Route path="/results/:qvestId" component={ResultsPage} />
              <Route path="/login" component={ParticipantLoginPage} />
              {/* Public */}
              <Route path="/join/:pin?" component={JoinPage} />
              <Route path="/topic" component={TopicSuggestionPage} />
              <Route path="/preview/:variant" component={PreviewPage} />
              <Route path="/oidc/finalize" component={OidcFinalizePage} />
              <Route path="/oidc/:variant" component={OidcPage} />
              {/* Owner */}
              <Route path="/owner-login-error" component={OwnerLoginErrorPage} />
              <Route path="/owner-signup" component={OwnerSignupPage} />
              <Route path="/owner-signup-complete" component={OwnerSignupPage} />
              <Route path="/owner" component={OwnerSection} />
              {/* Jam Leader */}
              <Route path="/jam-signup" component={JamSignupPage} />
              <Route path="/jam" component={JamSection} />
              <Redirect to="/owner" />
            </Switch>
          </BrowserRouter>
          <GlobalStyles />
          <NotificationOverlay />
        </GenericErrorDisplay>
      </ThemeProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
)
