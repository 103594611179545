import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import { loadLocale } from '../../utils/i18nUtils'
import OwnerSignupCard from '../../components/auth/OwnerSignupCard/OwnerSignupCard'
import { createUser, inviteUser } from '../../actions/userActions'
import { getQueryStringValue } from '../../utils/stringUtils'

import qvestRegularSvg from '../../static/img/qvest-regular.svg'

const ComponentRoot = styled.div`
  /* 100vw breaks on screens below 320px because of device zoom */
  width: 100%;
  /* media query to tackle IE bug of flexbox*/
  @media (min-width: 460px) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
`

const Container = styled.div`
  & > img { display: none; }
  @media (min-height: 900px) {
    position: relative;
    & > img {
      display: block;
      position: absolute;
      top: 100%;
      left: calc(50% - 60px);
      width: 120px;
    }
  }
`

class OwnerSignupPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mode: getQueryStringValue('mode'),
      plan: getQueryStringValue('plan'),
      coupon: getQueryStringValue('coupon'),
      inviteToken: getQueryStringValue('invite_token'),
      fullName: getQueryStringValue('full_name'),
      expiresAt: getQueryStringValue('expires_at'), // TODO: Use this to show expiry error right away if expired
    }
  }

  renderForm() {
    const { userStore } = this.props
    const mode = this.state.mode
    let showEmail, showFullName, showOrganizationName, showPassword, showPhone, showConsentBoxes

    let wasSent = userStore.wasSent
    if (mode === 'COMPLETED') {
      wasSent = true
    }

    // If finalizing account, go directly to app on success (no e-mail sent)
    if (wasSent) {
      if (mode === 'ORG_INVITE_FINALIZE' || mode === 'LIGHT_FINALIZE') {
        return (<Redirect to="/owner" />)
      }
      return (<Redirect to="/owner-signup-complete" />)
    }

    switch (mode) {
      case 'LIGHT_INVITE':
        showEmail = true
        showFullName = false
        showOrganizationName = false
        showPassword = false
        showPhone = false
        showConsentBoxes = false
        break
      case 'LIGHT_FINALIZE':
        showEmail = false
        showFullName = true
        showOrganizationName = true
        showPassword = true
        showPhone = true
        showConsentBoxes = true
        break
      case 'ORG_INVITE_FINALIZE':
        showEmail = false
        showFullName = true
        showOrganizationName = false
        showPassword = true
        showPhone = true
        showConsentBoxes = true
        break
      default:
        showEmail = true
        showFullName = true
        showOrganizationName = true
        showPassword = true
        showPhone = true
        showConsentBoxes = true
    }

    return (
      <OwnerSignupCard
        showEmail={showEmail}
        showFullName={showFullName}
        showOrganizationName={showOrganizationName}
        showPassword={showPassword}
        showPhone={showPhone}
        showConsentBoxes={showConsentBoxes}
        isLoading={userStore.isLoading}
        error={userStore.error}
        wasSent={wasSent}
        onSignup={this.onSignup}
        fullName={this.state.fullName}
      />
    )
  }

  renderCompleted() {
    return (
      <OwnerSignupCard wasSent={true} />
    )
  }

  render() {
    const locale = 'en'
    const messages = loadLocale(locale)
    return (
      <IntlProvider locale={locale} messages={messages}>
        <ComponentRoot>
          <Helmet>
            <title>Sign up</title>
          </Helmet>
          <Container>
            <Switch>
              <Route path="/owner-signup-complete" render={() => this.renderCompleted()} />
              <Route render={() => this.renderForm()} />
            </Switch>
            <img src={qvestRegularSvg} alt="Qvest logo" />
          </Container>
        </ComponentRoot>
      </IntlProvider>
    )
  }

  onSignup = async (credentials, organizationName, hasMarketingConsent) => {
    const { dispatch } = this.props
    const { mode, plan, coupon, inviteToken } = this.state
    if (mode === 'LIGHT_INVITE') {
      await dispatch(inviteUser(credentials.email))
    } else if (mode === 'ORG_INVITE_FINALIZE') {
      const options = Intl.DateTimeFormat().resolvedOptions()
      const fields = {
        locale: options.locale || navigator.language,
        timeZone: options.timeZone, // timeZone field is not supported in IE
        hasMarketingConsent
      }
      await dispatch(createUser(credentials, inviteToken, fields))
    } else {
      const options = Intl.DateTimeFormat().resolvedOptions()
      const fields = {
        locale: options.locale || navigator.language,
        timeZone: options.timeZone, // timeZone field is not supported in IE
        planId: plan,
        couponId: coupon,
        hasMarketingConsent,
        organizationName,
      }
      await dispatch(createUser(credentials, inviteToken, fields))
    }
  }
}

function mapStateToProps(state) {
  return {
    userStore: state.user.toJS()
  }
}

export default connect(mapStateToProps)(OwnerSignupPage)
