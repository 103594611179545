import React from 'react'

import Modal from '../../common/Modal/Modal'
import AlignmentRatingModal from '../AlignmentRatingModal/AlignmentRatingModal'


const Modals = ({ shown, onSubmitRating, onCancel }) => {
  if (!shown) return null
  return (
    <Modal onBackgroundClick={onCancel} onEscape={onCancel}>
      <AlignmentRatingModal onSubmit={onSubmitRating} onCancel={onCancel} />
    </Modal>
  )
}

export default Modals
