import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled, { withTheme, ThemeProvider } from 'styled-components'
import groupBy from 'lodash/groupBy'

import { hexaToRgba } from '../../../utils/styleUtils'
import * as theme from '../../theme'
import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'

const ComponentRoot = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 80px;
`

const StaticBar = styled.nav`
  position: fixed;
  top: 125px;
  bottom: 0;
  left: 0;
  width: 80px;
  border-right: 1px solid #edeeee;
  background-color: white;
  z-index: 30;
`

const SVGGroup = styled.g`
  stroke: none;
  fill: ${({ theme }) => theme.default};
  fill-rule: evenodd;
`

const BarButton = styled.button`
  width: 100%;
  height: 80px;
  outline: none;
  border-style: none;
  background-color: inherit;
  cursor: pointer;
  &:hover, &:focus {
    & > svg > g {
      fill: ${({ theme }) => theme.color[0]};
    }
  }
  ${({ theme, active }) => active && `
    background-color: ${hexaToRgba(theme.color[0] + 33)};
    & > svg > g { fill: ${theme.color[0]}; }
  `}

  &:disabled {
    & > svg > g { fill: ${({ theme }) => theme.one[2]}; }
    cursor: auto;
  }
`

const DynamicBar = styled.nav`
  position: fixed;
  top: 125px;
  left: 80px;
  bottom: 0;
  width: 280px;
  z-index: 20;
  background-color: white;
  transform: ${({ collapsed }) => collapsed ? 'translateX(-280px)' : 'none'};
  transition: transform 150ms ease-in;
  padding: 30px;
  overflow-y: auto;
  & > * + * { margin-top: 50px; }
`

const NavigationGroup = styled.div`
  & > * + * { margin-top: 5px; }
`

const Link = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  outline: none;
  border-style: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: ${({ theme, active }) => active ? theme.main.primary : theme.one[0]};

  &:hover, &:focus {
    & > * { color: ${({ theme }) => theme.main.primary}; }
  }

  &:disabled {
    cursor: default;
    & > * { color: ${({ theme }) => theme.one[2]}; }
  }
`

const LinkText = styled.span`
  font-family: ${({ theme }) => theme.font2};
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
`

const NewBadge = styled.div`
  padding: 0 4px;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.main.primary};
  & > * { color: white; }
`

const Backdrop = styled.div`
  position: fixed;
  top: 125px;
  left: 0;
  right: ${({ collapsed }) => collapsed ? '100%' : 0};
  bottom: 0;
  z-index: 11;
  background-color: black;
  opacity: ${({ collapsed }) => collapsed ? 0 : .5};
  transition: opacity 150ms ease-in;
`

const ExpandButton = ({ active, onClick }) => {
  return (
    <BarButton active={active} onClick={onClick}>
      <svg width="25px" height="25px" viewBox="0 0 25 25">
        <SVGGroup transform="translate(-28, -178)">
          <path d="M51.5860182,191.810793 C51.5860182,192.866267 50.7274871,193.723619 49.6731921,193.723619 L39.1903863,193.723619 C38.134912,193.723619 37.2775603,192.866267 37.2775603,191.810793 L37.2775603,181.327987 C37.2775603,180.273692 38.134912,179.413982 39.1903863,179.413982 L49.6731921,179.413982 C50.7274871,179.413982 51.5860182,180.273692 51.5860182,181.327987 L51.5860182,191.810793 Z M43.723619,199.672013 C43.723619,200.727487 42.8662673,201.586018 41.810793,201.586018 L31.3279872,201.586018 C30.2736922,201.586018 29.4151611,200.727487 29.4151611,199.672013 L29.4151611,189.189207 C29.4151611,188.134912 30.2736922,187.276381 31.3279872,187.276381 L35.8623992,187.276381 L35.8623992,191.810793 C35.8623992,193.645785 37.3553941,195.137601 39.1903863,195.137601 L43.723619,195.137601 L43.723619,199.672013 Z M49.6731921,178 L39.1903863,178 C37.3553941,178 35.8623992,179.492995 35.8623992,181.327987 L35.8623992,185.862399 L31.3279872,185.862399 C29.492995,185.862399 28,187.355394 28,189.189207 L28,199.672013 C28,201.508184 29.492995,203 31.3279872,203 L41.810793,203 C43.6469645,203 45.1399594,201.508184 45.1399594,199.672013 L45.1399594,195.137601 L49.6731921,195.137601 C51.5081843,195.137601 53.0011793,193.645785 53.0011793,191.810793 L53.0011793,181.327987 C53.0011793,179.492995 51.5081843,178 49.6731921,178 Z" />
        </SVGGroup>
      </svg>
    </BarButton>
  )
}


const InsightButton = ({ activeTab, disabled, show, onClick }) => {
  if (!show) return null
  return (
    <ThemeProvider theme={theme.red}>
      <BarButton
        title="Project Map"
        active={activeTab === 'insights'}
        disabled={disabled}
        onClick={onClick}
      >
        <svg width="30px" height="25px" viewBox="0 0 30 25" version="1.1">
          <SVGGroup transform="translate(-26, -357)">
            <path
              d="M50.6731703,357.257538 C50.5663863,357.106784 50.3967883,357 50.2083461,357 L31.4520647,357 C31.2636225,357 31.0940245,357.106784 30.9872406,357.257538 L26.1003059,364.330402 C25.9558336,364.54397 25.9683964,364.826633 26.131713,365.021357 L40.3528185,381.729899 C40.453321,381.886935 40.6354818,382 40.823924,382 L40.8302054,382 C41.024929,382 41.2008084,381.886935 41.301311,381.729899 L55.5224165,365.027638 C55.6857331,364.832915 55.7045773,364.537688 55.5538235,364.330402 L50.6731703,357.257538 Z M46.6153813,365.228643 L40.823924,379.864322 L35.0638738,365.228643 L46.6153813,365.228643 Z M35.5349793,364.09799 L40.7799542,358.469849 L46.1191502,364.09799 L35.5349793,364.09799 Z M42.0487984,358.130653 L49.4106074,358.130653 L47.243522,363.60804 L42.0487984,358.130653 Z M34.4294517,363.582915 L32.2812105,358.130653 L39.51111,358.130653 L34.4294517,363.582915 Z M33.4055823,364.09799 L27.6455321,364.09799 L31.3201552,358.790201 L33.4055823,364.09799 Z M33.8515622,365.228643 L39.0588486,378.451005 L27.7900044,365.228643 L33.8515622,365.228643 Z M47.8276929,365.228643 L53.8704064,365.228643 L42.5889994,378.469849 L47.8276929,365.228643 Z M48.2799542,364.09799 L50.3653813,358.821608 L54.0148788,364.09799 L48.2799542,364.09799 Z"/>
          </SVGGroup>
        </svg>
      </BarButton>
    </ThemeProvider>
  )
}

const DownloadButton = ({ active, disabled, onClick }) => (
  <ThemeProvider theme={theme.yellow}>
    <BarButton
      active={active}
      title={(disabled ? '(Available after Qvest has closed) ' : '') + 'Download options'}
      disabled={disabled}
      onClick={onClick}
    >
      <svg width="35px" height="28px" viewBox="0 0 67.999998 88.00004" version="1.1">
        <SVGGroup transform="translate(-16,-958.36216)">
          <path d="m 18,958.36216 c -1.12867,0.02 -1.99428,1.0331 -2,2 v 84.00004 c 10e-5,1.0471 0.95283,1.9999 2,2 h 50 c 0.52204,0 1.03918,-0.2226 1.40625,-0.5938 l 14,-14 c 0.37122,-0.3671 0.58957,-0.8842 0.59375,-1.4062 v -70.00004 c -1e-4,-1.0472 -0.95283,-1.9999 -2,-2 z m 2,4 h 60 v 66.00004 H 68 c -1.08152,0.011 -2.00371,1.0001 -2,2 v 12 H 20 Z m 30,20 c -1.1045,0 -2,0.8954 -2,2 v 23.18754 l -6.59375,-6.5938 c -0.781,-0.781 -2.0503,-0.7993 -2.8125,0 -0.8005,0.8397 -0.7849,2.0159 0,2.8125 3.3022,3.2918 6.65475,6.5949 9.90625,9.9063 0.3666,0.4194 0.8992,0.6875 1.5,0.6875 0.6093,0 1.1331,-0.2889 1.5,-0.7188 3.3163,-3.1308 6.59405,-6.6271 9.90625,-9.875 0.8044,-0.8044 0.781,-2.0314 0,-2.8125 -0.781,-0.781 -2.0315,-0.781 -2.8125,0 L 52,1007.5497 v -23.18754 c 0,-1.1046 -0.8955,-2 -2,-2 z m 20,50.00004 h 7.15625 L 70,1039.5184 Z" />
        </SVGGroup>
      </svg>
    </BarButton>
  </ThemeProvider>
)


class AnalyticsSidebar extends Component {
  state = { activeParent: null }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickAnywhere)
  }

  handleClickAnywhere = event => {
    const el = this.dynamicBar
    const collapsed = (this.state.activeParent == null)
    if (el && !el.contains(event.target) && !collapsed) {
      this.handleCollapse()
    }
  }

  handleOpen = (parent) => {
    document.addEventListener('click', this.handleClickAnywhere)
    this.setState({ activeParent: parent })
  }

  handleCollapse = () => {
    document.removeEventListener('click', this.handleClickAnywhere)
    this.setState({ activeParent: null })
  }

  handleTabClick = (tabId, parentId) => {
    const { onTabClick } = this.props
    return () => {
      this.handleCollapse()
      onTabClick(tabId, parentId)
    }
  }

  renderTabs = (tabs, activeTab) => tabs.map(tab => {
    const { id, parent, label, isNew, disabled } = tab
    return (
      <Link
        key={id}
        active={id === activeTab}
        disabled={disabled}
        onClick={this.handleTabClick(id, parent)}
      >
        <LinkText>{label}</LinkText>
        {isNew && (
          <NewBadge>
            <Heading variant="heading6" tertiary>
              <FormattedMessage id="owner.AnalyticsSidebar.new" defaultMessage="New" />
            </Heading>
          </NewBadge>
        )}
      </Link>
    )
  })

  renderContent = () => {
    const { tabs, activeTab } = this.props
    const { activeParent } = this.state
    const relevantTabs = tabs.filter(t => t.parent === activeParent)
    const tabGroups = groupBy(relevantTabs, 'group')
    return Object.entries(tabGroups).map(([groupName, tabGroup], i) => {
      return (
        <NavigationGroup key={i}>
          <Typography variant="medium" weight="light" transparent>{groupName}</Typography>
          {this.renderTabs(tabGroup, activeTab)}
        </NavigationGroup>
      )
    })
  }

  render() {
    const { activeParent } = this.state
    const { tabs, activeTab, showInsights, isDone } = this.props
    const disabledInsights = (tabs && tabs.find(t => t.id === 'themes').disabled)
    const collapsed = (activeParent == null)
    return (
      <ComponentRoot>
        <StaticBar>
          <ExpandButton active={activeParent === 'main'} onClick={() => this.handleOpen('main')} />
          <InsightButton
            activeTab={activeTab}
            disabled={disabledInsights}
            show={showInsights}
            onClick={this.handleTabClick('project-map')}
          />
          <DownloadButton
            active={activeParent === 'download'}
            disabled={!isDone}
            onClick={() => this.handleOpen('download')}
          />
        </StaticBar>
        <DynamicBar ref={el => { this.dynamicBar = el }} collapsed={collapsed}>
          {this.renderContent()}
        </DynamicBar>
        <Backdrop collapsed={collapsed} />
        {this.props.children}
      </ComponentRoot >
    )
  }

  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      parent: PropTypes.string,
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      isNew: PropTypes.bool
    })).isRequired,
    onTabClick: PropTypes.func.isRequired,
    isDone: PropTypes.bool
  }
}

export default withTheme(AnalyticsSidebar)
