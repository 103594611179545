import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'

const FONTS = {
  large: 'font1', // Raleway
  regular: 'font1', // Raleway
  medium: 'font1',  // Raleway
  small: 'font1',  // Raleway
  tiny: 'font2',   // LL Circular Book Web
  footer: 'font4',  // Andale Mono MT Pro
  monospace: 'font4',  // Andale Mono MT Pro
  display1: 'font3',  // LL Circular Black Web
  display2: 'font3',  // LL Circular Black Web
  heading1: 'font3',  // LL Circular Black Web
  heading2: 'font3',  // LL Circular Black Web
  heading3: 'font3',  // LL Circular Black Web
  heading4: 'font3',  // LL Circular Black Web
  heading5: 'font3',  // LL Circular Black Web
  menu: 'font3',    // LL Circular Black Web
  caption: 'font2', // LL Circular Book Web
}

const FONT_SIZES = {
  large: '24px',
  regular: '16px',
  medium: '14px',
  small: '13px',
  monospace: '13px',
  footer: '10px',
  tiny: '10px',
  display1: '65px',
  display2: '45px',
  heading1: '38px',
  heading2: '25px',
  heading3: '20px',
  heading4: '16px',
  heading5: '14px',
  heading6: '10px',
  menu: '12px',
  caption: '14px',
}

const LINE_HEIGHTS = {
  large: '40px',
  regular: '26px',
  medium: '26px',
  small: '21px',
  monospace: '21px',
  footer: '12px',
  tiny: '12px',
  display1: '85px',
  display2: '60px',
  heading1: '48px',
  heading2: '32px',
  heading3: '25px',
  heading4: '18px',
  heading5: '16px',
  heading6: '15px',
  menu: '15px',
  caption: '18px',
}

const FONT_WEIGHTS = {
  light: '500',
  large: '600',
  regular: '600',
  bold: '700'
}

const determineColor = ({ theme, cta, secondary, tertiary, inverted }) => {
  if (tertiary) {
    return theme.tertiary
  } else if (cta) {
    return inverted ? theme.color[1] : theme.color[0]
  } else if (secondary) {
    return inverted ? theme.inverted.one[2] : theme.one[2]
  } else {
    return inverted ? theme.inverted.one[0] : theme.one[0]
  }
}

const determineWeight = ({ variant, weight }) => {
  if (['large', 'regular', 'medium', 'small'].indexOf(variant) !== -1) {
    return FONT_WEIGHTS[weight]
  } else if (variant.indexOf('heading') === 0) {
    return '900'
  } else {
    return 'normal'
  }
}

const Typography = styled.span`
  font-family: ${props => props.theme[FONTS[props.variant]]};
  font-weight: ${determineWeight};
  font-size: ${props => FONT_SIZES[props.variant]};
  line-height: ${props => LINE_HEIGHTS[props.variant]};
  color: ${determineColor};
  opacity: ${props => props.transparent ? '0.5' : '1.0'};
  ${props => props.paragraph ? 'margin-bottom: 0.35em' : ''};
  ${props => props.clickable && `
    user-select: none;
    cursor: pointer;
    &:hover { text-decoration: underline; }
  `}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const TypographyParagraph = (props) => (
  <Typography as="p" {...props}>{props.children}</Typography>
)
Typography.p = TypographyParagraph

Typography.propTypes = {
  clickable: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(FONT_SIZES)),
  weight: PropTypes.oneOf(Object.keys(FONT_WEIGHTS)),
  size: PropTypes.string,
  cta: PropTypes.bool,
  paragraph: PropTypes.bool,
  transparent: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  inverted: PropTypes.bool,
}

Typography.defaultProps = {
  clickable: false,
  variant: 'regular',
  weight: 'regular',
  paragraph: false,
  secondary: false,
  tertiary: false,
  inverted: false,
  cta: false,
}

export default Typography
