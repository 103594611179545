import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { isEnter } from '../../../../utils/animationUtils'
import { determineTransition, makeQuestionUrl } from '../../../../utils/questionUtils'
import Button from '../../../../components/common/Button/Button'
import Heading from '../../../../components/common/Typography/Heading'
import CenteredLoader from '../../../../components/common/CenteredLoader/CenteredLoader'
import QvestHeader from '../../../../components/qvest/QvestHeader/QvestHeader'
import QvestFooter from '../../../../components/common/QvestFooter/QvestFooter'
import QuestionDisplay from '../../../../components/qvest/QuestionDisplay/QuestionDisplay'
import { filterOwnQuestions, filterQuestionsById } from '../../../../reducers/questions'
import { filterParticipantsByQvest } from '../../../../reducers/participants'
import { filterQvestsById } from '../../../../reducers/qvests'
import { filterSchedulesByQvestsId } from '../../../../reducers/schedules'


const ComponentRoot = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.two[0]};
  min-height: 100vh;
  padding: 100px 50px 50px 50px;
  opacity: ${props => isEnter(props) ? '1' : '0'};
  transition: opacity 300ms ease-in;

  @media (max-width: 512px) { padding: 0 25px; }
`

const Content = styled.div`
  max-width: 800px;
  margin: auto;
`

const AlignedRight = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Actions = ({ show, qvestId, nextQuestion }) => {
  if (!show) return null
  return (
    <AlignedRight>
      <Button.Link to={makeQuestionUrl(qvestId, nextQuestion)}>
        <FormattedMessage defaultMessage="Next action" id="qvest.QuestionView.actions.next" />
      </Button.Link>
    </AlignedRight>
  )
}


class QuestionView extends Component {
  render() {
    const { match, qvestStore, scheduleStore, participantStore, questionStore, animation, left, right } = this.props
    const { qvestId, questionId } = match.params

    const qvest = filterQvestsById(qvestStore, qvestId)
    const question = filterQuestionsById(questionStore, questionId)
    const questions = filterOwnQuestions(questionStore, qvestId)
    const participants = filterParticipantsByQvest(participantStore, qvestId)
    const schedule = filterSchedulesByQvestsId(scheduleStore, qvestId)
    const currentQuestion = filterQuestionsById(questionStore, questionId)
    const nextQuestion = determineTransition(currentQuestion, questions, qvest.isJam)

    let content
    if (qvest && question && participants) {
      const status = question.status
      if (status === 'ASKED') {
        if (qvest.state === 'QVEST_CLOSED') {
          return (
            <Redirect to={`/qvest/${qvestId}/question/${question.questionId}/answer`} />
          )
        } else {
          return (
            <Redirect to={`/qvest/${qvestId}/question/${question.questionId}/answer`}/>
          )
        }
      } else if (status === 'ASKED_BY_SELF') {
        content = (
          <QuestionDisplay
            isLoading={questionStore.isLoading}
            error={questionStore.error}
            groupings={qvest.groupings}
            participants={participants}
            question={question}
          />
        )
      } else if (status === 'ANSWERED') {
        content = (
          <QuestionDisplay
            isLoading={questionStore.isLoading}
            error={questionStore.error}
            groupings={qvest.groupings}
            participants={participants}
            question={question}
          />
        )
      } else if (status === 'ANSWERED_BY_SELF') {
        content = (
          <QuestionDisplay
            isLoading={questionStore.isLoading}
            error={questionStore.error}
            groupings={qvest.groupings}
            participants={participants}
            question={question}
          />
        )
      } else if (status === 'EMPTY') {

        if (qvest.state === 'QVEST_CLOSED') {
          return (
            <Redirect to={`/qvest/${qvestId}/question/${question.questionId}/ask`} />
          )
        } else {
          return (
            <Redirect to={`/qvest/${qvestId}/question/${question.questionId}/ask`} />
          )
        }
      }
    } else {
      content = (<CenteredLoader width="50%" height="50vh" />)
    }

    const viewPortWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    const maxWidth = 700
    const rightPad = 16
    const width = (viewPortWidth < maxWidth + rightPad) ? (viewPortWidth - rightPad) : maxWidth
    return (
      <ComponentRoot width={width} animation={animation} left={left} right={right}>
        <QvestHeader light qvest={qvest} schedule={schedule} />
        <Content>
          <Heading.h1 variant="heading2">{qvest.topic}</Heading.h1>
          {content}
          <Actions show={qvest.isJam} qvestId={qvestId} nextQuestion={nextQuestion} />
        </Content>
        <QvestFooter />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    participantStore: state.participants.toJS(),
    questionStore: state.questions.toJS(),
    scheduleStore: state.schedules.toJS()
  }
}

export default connect(mapStateToProps)(QuestionView)
