import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import debounce from 'lodash/debounce'

import { prioritizeQuestions } from '../../../utils/questionUtils'


// lower header for mobiles
const ComponentRoot = styled.div`
  position: fixed;
  bottom: 0;
  transform: ${({ show }) => show ? 'none' : 'translateY(65px)'};
  transition: transform 200ms ease-out;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  /* row-reversed + space-between due to a flex-end bug on IE11 */
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 28px;
  align-items: center;
  background-color: ${({ theme }) => theme.two[0]};
  box-shadow: -2px -8px 24px 0 rgba(0,0,0,0.04);
  z-index: 100;
  @media (min-width: 700px) {
    height: 65px;
  }
`

const FooterLink = styled(Link)`
  font-family: ${({ theme }) => theme.font3};
  font-size: 15px;
  font-weight: 900;
  color: ${({ theme }) => theme.one[0]};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color[0]};
  }
`

class BottomNavBar extends Component {
  state = { showFooter: true }
  lastScrollPosition = 0

  componentDidMount() {
    this.debouncedShowFooter = debounce(() => {
      this.setState({ showFooter: true })
    }, 1000)
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const currentScrollPosition = window.scrollY
    if (currentScrollPosition > this.lastScrollPosition) {
      // hide footer when user scrolls down and put it on a timer
      // TODO: Remove when we've decided how this should work
      //this.setState({ showFooter: false })
      //this.debouncedShowFooter()
    } else {
      // instantly show the footer if user scrolls up
      this.setState({ showFooter: true })
    }
    this.lastScrollPosition = currentScrollPosition
  }

  renderTaskLink(isQvestClosed) {
    const { qvest, questions, onNextClick } = this.props

    let taskQueue = []
    if (!isQvestClosed && questions) {
      taskQueue = prioritizeQuestions(questions).outstanding
    }

    if (!taskQueue.length) {
      return null
    }

    return (
      <FooterLink
        to={`/qvest/${qvest.qvestId}/question/${taskQueue[0].questionId}`}
        onClick={onNextClick}
      >
        <FormattedMessage
          id="qvest.BottomNavBar.taskLink"
          defaultMessage="Next task ({count})"
          values={{ count: taskQueue.length }}
        />
      </FooterLink>
    )
  }

  renderResultsLink(isQvestClosed) {
    const { qvest } = this.props
    if (!isQvestClosed) return null
    return (
      <FooterLink to={`/results/${qvest.qvestId}`}>
        <FormattedMessage id="qvest.BottomNavBar.resultsLink" defaultMessage="Qvest Output" />
      </FooterLink>
    )
  }

  render() {
    const { qvest } = this.props
    const isQvestClosed = (qvest.state === 'QVEST_CLOSED')

    return (
      <ComponentRoot show={this.state.showFooter && !qvest.isJam}>
        <FooterLink to={`/qvest/${qvest.qvestId}/activity`}>
          <FormattedMessage id="qvest.BottomNavBar.activityLink" defaultMessage="My activity" />
        </FooterLink>
        {this.renderTaskLink(isQvestClosed)}
        {this.renderResultsLink(isQvestClosed)}
      </ComponentRoot>
    )
  }
}

BottomNavBar.propTypes = {
  qvest: PropTypes.shape({
    qvestId: PropTypes.string.isRequired,
    state: PropTypes.string,
  }).isRequired,
  questions: PropTypes.array,
  onNextClick: PropTypes.func,
}

BottomNavBar.defaultProps = {
  onNextClick: () => { },
}

export default BottomNavBar
