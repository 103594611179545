import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import { isEnter } from '../../../utils/animationUtils'
import { determineNextWaveAt } from '../../../utils/qvestUtils'
import Heading from '../../../components/common/Typography/Heading'
import QvestActivity from '../../../components/qvest/QvestActivity/QvestActivity'
import QvestAboutFold from '../../../components/qvest/QvestAboutFold/QvestAboutFold'
import QvestHeader from '../../../components/qvest/QvestHeader/QvestHeader'
import QvestFooter from '../../../components/common/QvestFooter/QvestFooter'
import { getOwnQuestions } from '../../../actions/questionActions'
import { filterOwnQuestions } from '../../../reducers/questions'
import { filterQvestsById } from '../../../reducers/qvests'
import { filterParticipantsByQvest } from '../../../reducers/participants'
import { filterSchedulesByQvestsId } from '../../../reducers/schedules'


const messages = defineMessages({
  title: { id: 'QvestActivityPage.title', defaultMessage: 'My Activity | {qvestTopic}' },
})

const Main = styled.div`
  min-height: 100vh;
  opacity: ${props => isEnter(props) ?  '1' : '0'};
  transition: opacity 300ms ease-in;
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 20px;
  & > h1 { margin-bottom: 30px; }
  @media (min-width: 1024px) {
    & > * { padding-top: 25px; }
  }
`

// Work-around to test wave concept without making major changes in core qvest logic
// TODO: Introduce locked question concept in backend, if the waves feature takes off
function generateLockedQuestions(qvest, schedule) {
  const lockedQuestions = []
  if(qvest.state === 'QVEST_ONBOARDING_STARTED') {
    lockedQuestions.push({
      status: 'LOCKED',
      createdAt: schedule.openAt
    })
  }
  const waveAt = determineNextWaveAt(qvest,  schedule)
  if(waveAt) {
    const now = new Date()
    if(waveAt > now) {
      lockedQuestions.push({
        status: 'LOCKED',
        createdAt: waveAt
      })
    }
  }
  return lockedQuestions
}


class ActivityPage extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getOwnQuestions(match.params.qvestId))
  }

  renderGreeting(participants) {
    const self = participants.find(p => p.isSelf)
    if (!self) return null
    return (
      <Heading.h1 variant="display3">
        <FormattedMessage
          id="ActivityPage.greeting"
          defaultMessage="Hi {name}"
          values={{ name: self.name }}
        />
      </Heading.h1>
    )
  }

  render() {
    const {
      questionStore,
      qvestStore,
      scheduleStore,
      participantStore,
      match,
      animation,
      intl,
    } = this.props
    const { qvestId } = match.params

    const qvest = filterQvestsById(qvestStore, qvestId)
    const questions = filterOwnQuestions(questionStore, qvestId)
    const participants = filterParticipantsByQvest(participantStore, qvestId)
    const schedule = filterSchedulesByQvestsId(scheduleStore, qvestId)

    if (!schedule) return null

    const lockedQuestions = generateLockedQuestions(qvest, schedule)
    const shownQuestions = questions.concat(lockedQuestions)

    return (
      <Main animation={animation}>
        <Helmet>
          <title>{intl.formatMessage(messages.title, { qvestTopic: qvest && qvest.topic })}</title>
        </Helmet>
        <QvestHeader qvest={qvest} schedule={schedule} />
        {this.renderGreeting(participants)}
        <QvestActivity
          qvestId={qvestId}
          questions={shownQuestions}
          qvestState={qvest.state}
        />
        <QvestAboutFold qvest={qvest} participants={participants} />
        <QvestFooter />
      </Main>
    )
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    questionStore: state.questions.toJS(),
    participantStore: state.participants.toJS(),
    scheduleStore: state.schedules.toJS()
  }
}

export default connect(mapStateToProps)(injectIntl(ActivityPage))
