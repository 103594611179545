import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Table from '../../common/Table/Table'

const DistributionParticipationTable = ({ isLoading, participation }) => {
  const hasData = (participation && participation.length)
  return (
    <Table isLoading={isLoading}>
      <thead>
        <tr>
          <th><FormattedMessage id="owner.DistributionParticipationTable.name" defaultMessage="Group" /></th>
          <th><FormattedMessage id="owner.DistributionParticipationTable.questions" defaultMessage="Questions"/></th>
          <th><FormattedMessage id="owner.DistributionParticipationTable.questionsPercent" defaultMessage="Question percentage" /></th>
          <th><FormattedMessage id="owner.DistributionParticipationTable.answers" defaultMessage="Answers" /></th>
          <th><FormattedMessage id="owner.DistributionParticipationTable.answersPercent" defaultMessage="Answer percentage" /></th>
        </tr>
      </thead>
      <tbody>
        {hasData && participation.map(group => (
          <tr key={group.groupId}>
            <td>{(group.groupId !== '-1') ? group.name : ''}</td>
            <td>{group.questionSentCount}</td>
            <td>{group.questionsPercent}%</td>
            <td>{group.answerSentCount}</td>
            <td>{group.answersPercent}%</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

DistributionParticipationTable.propTypes = {
  isLoading: PropTypes.bool,
  participation: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string,
    name: PropTypes.string,
    questionSentCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    questionsPercent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    answerSentCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    answersPercent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }))
}

DistributionParticipationTable.defaultProps = {
  isLoading: false,
  participation: [],
}

export default DistributionParticipationTable
