import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage, defineMessages, injectIntl, IntlProvider } from 'react-intl'
import ContainerDimensions from 'react-container-dimensions'
import { makeGroupColorScales } from '../../../utils/d3Utils'
import sortBy from 'lodash/sortBy'
import { loadLocale } from '../../../utils/i18nUtils'
import { PolarChart, focusMapping } from 'data-viz'

import {
  Title,
  Content,
  SingleColumn,
  Card,
  CardRaw,
  MainCard,
} from '../AnalyticsLayout/AnalyticsLayout'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Tooltip  from '../../common/Tooltip/Tooltip'
import GroupList from '../../common/GroupList/GroupList'


const ComponentRoot = styled.div`
  & > *:last-child {
    max-width: 1400px;
    padding: 0 30px;
  }
  & > :not(:last-child) {
    margin-bottom: 30px;
  }
`

const MainCardLayout = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33%;
    text-align: center;
    padding-bottom: 20px;
  }
  & > :nth-child(2) {
    height: 66%;
    width: 100%;
  }
`

const SecondaryCardLayout = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    height: 33%;
  }
  & > :nth-child(2) {
    height: 66%;
    width: 100%;
  }
`

const SmallCardLayout = styled.div`
  & > :first-child {
    padding: 30px 30px 20px 30px;
  }
`

const WideDataLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  & > :first-child {
    flex: 1;
  }
`

const FullProfileColumns = styled.div`
  display: flex;
  height: 800px;
  margin-top: 30px;
  width: 100%;
  & > :not(:nth-child(2)) {
    width: 20%;
    padding: 20px;
  }
  & > :nth-child(2) {
    width: 60%;
    padding: 20px;
  }
`

const FullProfileRows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`


const subjectMessages = defineMessages({
  northEast: { defaultMessage: 'Why & What', id: 'owner.FocusPage.subject.northEast' },
  southEast: { defaultMessage: 'How & What', id: 'owner.FocusPage.subject.southEast' },
  southWest: { defaultMessage: 'How & Who', id: 'owner.FocusPage.subject.southWest' },
  northWest: { defaultMessage: 'Why & Who', id: 'owner.FocusPage.subject.northWest' },
  north: { defaultMessage: 'Who, Why & What', id: 'owner.FocusPage.subject.north' },
  east: { defaultMessage: 'Why, What & How', id: 'owner.FocusPage.subject.east' },
  south: { defaultMessage: 'Who, How & What', id: 'owner.FocusPage.subject.south' },
  west: { defaultMessage: 'Why, Who & How', id: 'owner.FocusPage.subject.west' },
})

const patternMessages = defineMessages({
  northEast: { defaultMessage: 'clear focus on purpose', id: 'owner.FocusPage.pattern.northEast' },
  southEast: { defaultMessage: 'clear focus on result', id: 'owner.FocusPage.pattern.southEast' },
  southWest: { defaultMessage: 'clear focus on process', id: 'owner.FocusPage.pattern.southWest' },
  northWest: { defaultMessage: 'clear focus on experience', id: 'owner.FocusPage.pattern.northWest' },
  north: { defaultMessage: 'focus on experience & purpose', id: 'owner.FocusPage.pattern.north' },
  east: { defaultMessage: 'focus on purpose & result', id: 'owner.FocusPage.pattern.east' },
  south: { defaultMessage: 'focus on process & result', id: 'owner.FocusPage.pattern.south' },
  west: { defaultMessage: 'focus on experience & process', id: 'owner.FocusPage.pattern.west' },
  none: { defaultMessage: 'no focus', id: 'owner.FocusPage.pattern.none' },
})

const tooltipMessages = defineMessages({
  title: { defaultMessage: 'Focus explanation', id: 'owner.FocusPage.tooltip.title' },
  focus: { defaultMessage: 'Your Question Profile has four focus-sections. When more than 60% of the questions that include question words are placed in one section, your stakeholders are considered clearly focused. Similarly, your stakeholders are considered focused when more than 60% of the questions that include question words are placed in two sections, and not focused when there are less than 30% of the questions in each of the sections.', id: 'owner.FocusPage.tooltip.focus' },
  doubleFocus: { defaultMessage: 'Your Question Profile has four focus-sections. When more than 60% of the questions that include question words are placed in two sections, your stakeholders are considered focused. Similarly, your stakeholders are considered clearly focused when more than 60% of the questions that include question words are placed in one section, and not focused when there are less than 30% of the questions in each of the sections.', id: 'owner.FocusPage.tooltip.doubleFocus' },
  noFocus: { defaultMessage: 'Your Question Profile has four focus-sections. When there are less than 30% of the questions that include question words in each of the sections, your stakeholders are considered not focused. Similarly, your stakeholders are considered focused when more than 60% of the questions that include question words are placed in two sections, and clearly focused when more than 60% of the questions that include question words are placed in one section.', id: 'owner.FocusPage.tooltip.noFocus' },
})


function collectGroupDetails(questionProfile, data, focuses, blindspots) {
  let groupFocuses = {}
  let groupBlindspots = {}
  Object.keys(focuses).forEach(direction => {
    const focus = data[direction]
    questionProfile.groups.forEach(group => {
      const groupId = group.groupId
      const value = group.count[focus.key]
      const exists = groupFocuses.hasOwnProperty(groupId)
      groupFocuses[groupId] = !exists ? value : (groupFocuses[groupId] + value)
    })
  })
  Object.keys(blindspots).forEach(direction => {
    const blindspot = data[direction]
    questionProfile.groups.forEach(group => {
      const groupId = group.groupId
      const value = group.count[blindspot.key]
      const exists = groupBlindspots.hasOwnProperty(groupId)
      groupBlindspots[groupId] = !exists ? value : (groupBlindspots[groupId] + value)
    })
  })
  return { groupFocuses, groupBlindspots }
}

function groupDetailsToList(groups, details) {
  let detailsList = Object.entries(details).map(([groupId, value]) => ({
    groupId,
    value,
    group: groups.find(g => g.groupId === groupId)
  }))
  return sortBy(detailsList, g => -g.value)
}


class AnalyticsFocus extends Component {
  renderMainHeading(focusCount, direction) {
    const { intl } = this.props
    if (focusCount > 0) {
      const tooltip = (focusCount > 1) ? tooltipMessages.doubleFocus : tooltipMessages.focus
      const subject = intl.formatMessage(subjectMessages[direction])
      const pattern = (
        <Tooltip.Area>
          <Tooltip.Reference>
            <Heading variant="heading2" primary>{intl.formatMessage(patternMessages[direction])}</Heading>
          </Tooltip.Reference>
          <Tooltip wide>
            <Heading.h3>{intl.formatMessage(tooltipMessages.title)}</Heading.h3>
            <Typography variant="medium" weight="light">{intl.formatMessage(tooltip)}</Typography>
          </Tooltip>
        </Tooltip.Area>
      )
      return (
        <Heading.h2>
          <FormattedMessage
            id="owner.FocusPage.heading.focus"
            defaultMessage="{subject} were used more than the other question words, indicating a {pattern} in your Qvest"
            values={{ subject, pattern }}
          />
        </Heading.h2>
      )
    } else {
      const pattern = (
        <Tooltip.Area>
          <Tooltip.Reference>
            <Heading variant="heading2" primary>{intl.formatMessage(patternMessages.none)}</Heading>
          </Tooltip.Reference>
          <Tooltip wide>
            <Heading.h3>{intl.formatMessage(tooltipMessages.title)}</Heading.h3>
            <Typography variant="medium" weight="light">{intl.formatMessage(tooltipMessages.noFocus)}</Typography>
          </Tooltip>
        </Tooltip.Area>
      )
      return (
        <Heading.h2>
          <FormattedMessage
            id="owner.FocusPage.heading.noFocus"
            defaultMessage="The four kinds of question words were used equally, indicating {pattern} in your Qvest"
            values={{ pattern }}
          />
        </Heading.h2>
      )
    }
  }

  renderSecondHeading(blindspots) {
    const count = Object.keys(blindspots).length
    if (count > 0) {
      return (
        <Title>
          <Heading.h2>
            <FormattedMessage
              id="owner.FocusPage.secondHeading.blindspots"
              defaultMessage="{count} {count, plural, one {clear blindspot} other {blindspots}}"
              values={{ count: (count - 1) }}
            />
          </Heading.h2>
          <Typography variant="medium" transparent>
            <FormattedMessage
              id="owner.FocusPage.secondHeading.description"
              defaultMessage="escaped your stakeholders' attention"
            />
          </Typography>
        </Title>
      )
    } else {
      return (
        <Title>
          <Heading.h2>
            <FormattedMessage
              id="owner.FocusPage.secondHeading.none.title"
              defaultMessage="No blindspots"
            />
          </Heading.h2>
          <Typography variant="medium" transparent>
            <FormattedMessage
              id="owner.FocusPage.secondHeading.none.description"
              defaultMessage="in your stakeholders' approach to the Qvest topic"
            />
          </Typography>
        </Title>
      )
    }
  }

  renderGroupList(groupsList) {
    const { theme, groups } = this.props
    const colors = makeGroupColorScales(theme, groups.map(n => n.groupId)).primaryColors
    const groupRows = groupsList.map(({ groupId, value, group }) => {
      return (<GroupList.CountRow
        key={groupId}
        colors={colors}
        values={() => value}
        group={group}
      />)
    })
    return (
      <GroupList maxRows={6}>{groupRows}</GroupList>
    )
  }

  renderGroupCards(data, focuses, blindspots) {
    const { groups, questionProfile } = this.props
    const { groupFocuses, groupBlindspots } = collectGroupDetails(questionProfile, data, focuses, blindspots)
    const focusesGroupList = groupDetailsToList(groups, groupFocuses)
    const blindspotsGroupList = groupDetailsToList(groups, groupBlindspots)
    return (
      <Content>
        <CardRaw>
          <SmallCardLayout>
            <Title>
              <Heading.h3>
                <FormattedMessage
                  id="owner.AnalyticsFocus.matchCard.heading"
                  defaultMessage="{count} {count, plural, one {group} other {groups}}"
                  values={{ count: focusesGroupList.length }}
                />
              </Heading.h3>
              <Typography variant="medium" transparent>
                <FormattedMessage
                  id="owner.AnalyticsFocus.matchCard.description"
                  defaultMessage="match the Question Profile (see below)"
                />
              </Typography>
            </Title>
            {this.renderGroupList(focusesGroupList)}
          </SmallCardLayout>
        </CardRaw>
        <CardRaw>
          <SmallCardLayout>
            <Title>
              <Heading.h3>
                <FormattedMessage
                  id="owner.AnalyticsFocus.noMatchCard.heading"
                  defaultMessage="{count} {count, plural, one {group} other {groups}}"
                  values={{ count: blindspotsGroupList.length }}
                />
              </Heading.h3>
              <Typography variant="medium" transparent>
                <FormattedMessage
                  id="owner.AnalyticsFocus.noMatchCard.description"
                  defaultMessage="pulled the Question Profile in other directions"
                />
              </Typography>
            </Title>
            {this.renderGroupList(blindspotsGroupList)}
          </SmallCardLayout>
        </CardRaw>
      </Content>
    )
  }

  renderDisclaimer() {
    const count = this.props.questionProfile.count.other
    if (count < 1) return null
    return (
      <Typography variant="medium" weight="light" transparent>
        <FormattedMessage
          id="owner.AnalyticsFocus.fullProfile.disclaimer"
          defaultMessage="{count} {count, plural, one {question} other {questions}} in your Qvest didn't include any of the question words and are thus not part of the Question Profile."
          values={{ count }}
        />
      </Typography>
    )
  }

  renderQuestionProfileCard(data) {
    const { topic, locale } = this.props
    return (
      <SingleColumn>
        <Card>
          <Title>
            <Heading.h3 variant="heading2">
              <FormattedMessage
                id="owner.AnalyticsFocus.fullProfile.heading"
                defaultMessage="Question Profile"
              />
            </Heading.h3>
            <Typography variant="medium" transparent>
              <FormattedMessage
                id="owner.AnalyticsFocus.fullProfile.description"
                defaultMessage="Topic: {topic}"
                values={{ topic }}
              />
            </Typography>
          </Title>
          <FullProfileColumns>
            <FullProfileRows>
              <div>
                <Heading.h4 variant="heading3">Experience</Heading.h4>
                <Typography variant="medium" weight="light">Groups that combine why-questions with who-, when- and where-questions are often focused on the reasons things are the way they are</Typography>
              </div>
              <div>
                <Heading.h4 variant="heading3">Process</Heading.h4>
                <Typography variant="medium" weight="light">Groups that combine who-, when- and where-questions with how-questions are often focused on the best way to utilize their resources</Typography>
              </div>
            </FullProfileRows>
            <IntlProvider locale={locale} messages={loadLocale(locale)}>
              <div>
                <ContainerDimensions>
                  {({ height, width }) => (
                    <PolarChart
                      primary
                      height={height - 40}
                      width={width - 40}
                      data={data}
                      round
                    />
                  )}
                </ContainerDimensions>
              </div>
            </IntlProvider>
            <FullProfileRows>
              <div>
                <Heading.h4 variant="heading3">Purpose</Heading.h4>
                <Typography variant="medium" weight="light">Groups that combine why-questions with what-questions are often focused on understanding and qualifying their overall goals</Typography>
              </div>
              <div>
                <Heading.h4 variant="heading3">Result</Heading.h4>
                <Typography variant="medium" weight="light">Groups that combine what-questions with how-questions are often focused on reaching the goal they set out to reach</Typography>
              </div>
            </FullProfileRows>
          </FullProfileColumns>
          {this.renderDisclaimer(42)}
        </Card>
      </SingleColumn>
    )
  }

  render() {
    const { questionProfile, minimal, locale } = this.props
    const { data, hasFocus, focusCount, direction, focuses, blindspots } = focusMapping.formatData(questionProfile)
    return (
      <ComponentRoot>
        <Content>
          <MainCard highlighted>
            <MainCardLayout>
              <div>
                {this.renderMainHeading(focusCount, direction)}
              </div>
              <IntlProvider locale={locale} messages={loadLocale(locale)}>
                <WideDataLayout>
                  <ContainerDimensions>
                    {({ height, width }) => (
                      <PolarChart
                        primary
                        labels
                        height={height}
                        width={width}
                        data={data}
                        show={hasFocus ? focuses : undefined}
                        sum={hasFocus}
                      />
                    )}
                  </ContainerDimensions>
                </WideDataLayout>
              </IntlProvider>
            </MainCardLayout>
          </MainCard>
          <MainCard>
            <SecondaryCardLayout>
              <div>
                {this.renderSecondHeading(blindspots)}
              </div>
              <IntlProvider locale={locale} messages={loadLocale(locale)}>
                <WideDataLayout>
                  {hasFocus ? (
                    <ContainerDimensions>
                      {({height, width}) => (
                        <PolarChart
                          primary
                          labels
                          height={height}
                          width={width}
                          data={data}
                          show={blindspots}
                          hatched
                        />
                      )}
                    </ContainerDimensions>
                  ) : null}
                </WideDataLayout>
              </IntlProvider>
            </SecondaryCardLayout>
          </MainCard>
        </Content>
        {(!minimal && hasFocus) ? this.renderGroupCards(data, focuses, blindspots) : null}
        {!minimal ? this.renderQuestionProfileCard(data) : null }
      </ComponentRoot>
    )
  }

  static propTypes = {
    questionProfile: PropTypes.shape({
      groups: PropTypes.arrayOf(PropTypes.shape({
        groupId: PropTypes.string.isRequired,
        count: PropTypes.shape({
          why: PropTypes.number.isRequired,
          who: PropTypes.number.isRequired,
          what: PropTypes.number.isRequired,
          how: PropTypes.number.isRequired,
          other: PropTypes.number.isRequired
        }).isRequired
      })).isRequired,
      count: PropTypes.shape({
        why: PropTypes.number.isRequired,
        who: PropTypes.number.isRequired,
        what: PropTypes.number.isRequired,
        how: PropTypes.number.isRequired,
        other: PropTypes.number.isRequired
      }).isRequired
    }),
    groups: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.string.isRequired,
      name: PropTypes.string
    })).isRequired,
    topic: PropTypes.string,
    minimal: PropTypes.bool,
    locale: PropTypes.string
  }

  static defaultProps = {
    minimal: false,
    locale: 'en'
  }
}

export default withTheme(injectIntl(AnalyticsFocus))
