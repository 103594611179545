import Rollbar from 'rollbar'

let rollBar

// Initialize Rollbar error reporting
if (process.env.ROLLBAR_ACCESS_TOKEN) {
  console.log('SHA: ' + process.env.COMMIT_SHA)
  console.log('Env: ' + process.env.ENVIRONMENT)
  rollBar = Rollbar.init({
    accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
    captureIp: false,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.ENVIRONMENT,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.COMMIT_SHA
        }
      },
      server: {
        root: 'webpack:///./'
      }
    }
  })
}

// Initialize matomo tracking
if (process.env.MATOMO_DOMAIN) {
  const MATOMO_SITE_ID = process.env.MATOMO_SITE_ID || '1'
  var _paq = window._paq = window._paq || []
  _paq.push(['trackPageView'])
  _paq.push(['enableLinkTracking']);
  (function() {
    var u=`https://${process.env.MATOMO_DOMAIN}/`
    _paq.push(['setTrackerUrl', u+'matomo.php'])
    _paq.push(['setSiteId', MATOMO_SITE_ID])
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]
    g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s)
  })()
}

export const addUserToRollbar = (user) => {
  if (rollBar) {
    rollBar.configure({
      payload: {
        person: { id: user.userId || user.participantId }
      }
    })
  }
}

const initPageTracking = (history) => {
  const _paq = window._paq
  if (_paq) {
    history.listen(() => {
      _paq.push(['setReferrerUrl', window.location.href])
      _paq.push(['setCustomUrl', window.location.pathname])
      _paq.push(['setDocumentTitle', document.title])
      _paq.push(['trackPageView'])
    })
  }
}

export const initParticipantTracking = (history, participants, qvestId) => {
  initPageTracking(history)
  if (participants) {
    try {
      // Find user
      const self = participants.find(p => p.isSelf)
      // Configure user in Matomo
      if (window._paq) {
        window._paq.push(['setCustomDimension', 1, qvestId])
        window._paq.push(['setUserId', self.participantId])
        window._paq.push(['trackPageView'])
      }
    } catch (error) {
      // NOTE: Intentionally stopping error propagation, this is not critical to the user experience
      log.warning('Failed to add user to Matomo: %s', error)
    }
    // Add participantId as person.id in Rollbar context
    addUserToRollbar(self)
  }
}

export const initOwnerTracking = (history, user) => {
  initPageTracking(history)
  if (user) {
    try {
      if (window._paq) {
        window._paq.push(['setUserId', user.userId])
        window._paq.push(['trackPageView'])
      }
    } catch (error) {
      // NOTE: Intentionally stopping error propagation, this is not critical to the user experience
      log.warning('Failed to add user to Matomo: %s', error)
    }
    // Add userId as person.id in Rollbar context
    addUserToRollbar(user)
  }
}

export const trackEvent = (category, action, name, value) => {
  if (window._paq) {
    window._paq.push(['trackEvent', category, action, name, value])
  }
}

export const log = {
  critical: rollBar ? (...args) => rollBar.critical(...args) : console.error,
  error: rollBar ? (...args) => rollBar.error(...args) : console.error,
  warning: rollBar ? (...args) => rollBar.warning(...args) : console.warn,
  info: rollBar ? (...args) => rollBar.info(...args) : console.info,
  debug: rollBar ? (...args) => rollBar.debug(...args) : console.debug
}
