import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'

import * as theme from '../../theme'
import Heading from '../Typography/Heading'
import Icon from '../Icon/Icon'

const ComponentRoot = styled.div`
  position: relative;
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  & > :last-child { margin-left: 10px; }
  &:hover {
    cursor: pointer;
    & > ${Heading} { text-decoration: underline; }
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: -10px;
  z-index: 10;
  min-width: 160px;
  background-color: ${({ theme }) => theme.background1};
  box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
  border-radius: 4px;
  padding: 20px;
  display: ${({ show }) => show ? 'flex' : 'none'};
  flex-direction: column;
  & > * + * { margin-top: 10px; }
`

const MenuItem = styled.button`
  background-color: inherit;
  border-style: none;
  outline: none;
  &:hover {
    ${props => props.disabled ? '' : `
      cursor: pointer;
      text-decoration: underline;
    `}
  }
  text-align: left;
`

export default class FilterDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDropdown: false,
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  unsubscribe = () => {
    document.removeEventListener('click', this.handleClickAnywhere)
  }

  handleDropdownClick = () => {
    const { showDropdown } = this.state
    if (showDropdown) return null
    this.setState({ showDropdown: true })
    document.addEventListener('click', this.handleClickAnywhere)
  }

  handleClickAnywhere = (event) => {
    event.preventDefault()
    this.setState({ showDropdown: false })
    this.unsubscribe()
  }

  handleClick = (value) => () => {
    const { onChange, value: chosenValue } = this.props
    if (value === chosenValue) return null
    onChange(value)
  }

  renderItems = () => {
    const { options, value: chosenValue } = this.props
    return options.map(({ label, value }) => (
      <MenuItem key={value} disabled={value === chosenValue} onClick={this.handleClick(value)}>
        <Heading primary={value == chosenValue} variant="heading5">{label}</Heading>
      </MenuItem>
    ))
  }

  render() {
    const { showDropdown } = this.state
    const { value, options } = this.props
    if (options && !options.length) return null
    const label = options.find(x => x.value === value).label
    return (
      <ComponentRoot>
        <Menu onClick={this.handleDropdownClick}>
          <Heading variant="heading5">{label}</Heading>
          <Icon variant={showDropdown ? 'angle-up' : 'angle-down'} />
        </Menu>
        <ThemeProvider theme={theme.yellow}>
          <Dropdown show={showDropdown}>
            {this.renderItems()}
          </Dropdown>
        </ThemeProvider>
      </ComponentRoot>
    )
  }

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
    value: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    options: [],
    value: '',
    onChange: () => { },
  }
}
