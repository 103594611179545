import * as oidcUtils from './oidcUtils'
import { getAuthProvider } from './authProviderUtils'
import Keycloak from 'keycloak-js'
import * as api from '../apiClient'

const HOST_NAME = process.env.HOST_NAME

const OPTIONS = {
  url: `${HOST_NAME}/auth`,
  clientId: 'qvest-web-app',
  realm: 'qvest-app',
  resource: 'qvest-web-app',
  'auth-server-url': `${HOST_NAME}/auth/`,
  'ssl-required': 'external',
  'public-client': true,
  'confidential-port': 0
}

let authProvider, keycloak


function isExpired(session) {
  const deadline = new Date(Date.now() + 259200000) // Buffer: 3 days before actual expiry
  return (new Date(session.expires_at) < deadline)
}

export function logout() {
  if (authProvider && authProvider.variant === 'oidc') {
    const returnTo = window.location
    oidcUtils.logout(returnTo)
  } else {
    keycloak.logout({ returnTo: `${HOST_NAME}/owner` })
  }
}

export async function getAuthHeader() {
  if (authProvider && authProvider.variant === 'oidc') {
    // OIDC
    return {} // Uses cookies rather than headers
  } else {
    // Keycloak
    const minValidity = 30 // seconds of validity left
    await keycloak.updateToken(minValidity)
    return { Authorization: `Bearer ${keycloak.token}` }
  }
}

// As a new (OIDC) user, finalize pending organization invitations of self
async function finalizeOrganizationInvitation() {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache', // Legacy (IE11) support
    },
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation m {
          finalizeOrganizationInvitations {
            organizations {
              organizationId
              name
            }
          }
        }
      `
    })
  }
  const response = await fetch('/api/graphql', options)
  if (response.status !== 200) {
    const body = await response.text()
    throw new Error(body)
  }
  const body = await response.json()
  return body.data.finalizeOrganizationInvitations
}

export async function createUser(credentials, inviteToken = null, fields = {}) {
  const options = {
    method: 'POST',
    body: {
      query: `
          mutation m($input:UserCreateInput!) {
            createUser(input:$input) {
              subscribeLink
            }
          }
        `,
      variables: {
        input: { credentials, inviteToken, ...fields }
      }
    }
  }
  const data = await api.invoke('/api/graphql/public', options, false)
  return data.createUser
}

export async function getFlowConfig(flowId, variant) {
  return oidcUtils.getFlowConfig(flowId, variant)
}

export async function getFlowErrors(errorId) {
  return oidcUtils.getFlowErrors(errorId)
}

export async function completeFlow() {
  const { organizations } = await finalizeOrganizationInvitation()
  if (organizations && organizations.length > 0) {
    const { organizationId } = organizations[0]
    window.location = `/owner/organization/${organizationId}`
    return true
  }
  return false
}

export async function initialize() {
  authProvider = await getAuthProvider()
  if (authProvider && authProvider.variant === 'oidc') {
    // OIDC
    const session = await oidcUtils.getSession()
    // If no active session found, initialize login flow by redirecting
    if (!session) {
      oidcUtils.initFlow(authProvider.authProviderId, window.location)
      return false
    }
    // If session inactive or expired (soon), refresh it by redirecting
    if (!session.active || isExpired(session)) {
      const refresh = true
      oidcUtils.initFlow(authProvider.authProviderId, window.location, refresh)
      return false
    }
    return true
  } else {
    // Keycloak
    keycloak = new Keycloak(OPTIONS)
    await keycloak.init({ onLoad: 'login-required' })
    return true
  }
}
