import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Route, withRouter } from 'react-router-dom'

import { makeGroupColorScales } from '../../../utils/d3Utils'
import Typography from '../Typography/Typography'
import Anchor from '../Anchor/Anchor'
import Heading from '../Typography/Heading'
import Modal from '../Modal/Modal'
import GroupLegend from '../GroupLegend/GroupLegend'
import RecipientList from '../../qvest/RecipientList/RecipientList'
import RecipientSelector from '../../qvest/RecipientSelector/RecipientSelector'

const ROUTING_REGEX = /\/participants.*/

const ComponentRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -15px;

  & > * {
    margin-bottom: 30px;
  }
`

const Column = styled.div`
  flex: 1;
  max-width: 100%;
  padding: 15px;
`

const LeftColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PaddedContent = styled.div`
  & > * { margin-bottom: 30px; }
`

const Counter = styled.div`
  & > * { display: block; }
`

const CounterNumber = styled.span`
  font-family: ${({ theme }) => theme.font2};
  font-size: 60px;
  line-height: 76px;
  font-weight: 300;
  color: ${({ theme }) => theme.one[0]};
`

function renderParticipantCount(participants) {
  if (!participants) return null
  return (
    <Counter>
      <CounterNumber>{participants.length}</CounterNumber>
      <Typography>
        <FormattedMessage defaultMessage="Participants" id="ParticipantsWidget.countLabel" />
      </Typography>
    </Counter>
  )
}

class ParticipantsWidget extends Component {
  toggleModal = () => {
    const { history, match } = this.props
    const newUrl = match.url.replace(ROUTING_REGEX, '')
    history.push(newUrl)
  }

  renderModal = (colors) => () => {
    const { groups, participants } = this.props
    return (
      <Modal
        onBackgroundClick={this.toggleModal}
        onEscape={this.toggleModal}
      >
        <RecipientSelector
          toggleModal={this.toggleModal}
          recipients={participants}
          avatarColors={colors}
          groups={groups}
          includeSelf={true}
        />
      </Modal>
    )
  }

  render() {
    const { theme, groups, participants, match } = this.props
    const hasGroups = (groups != null)

    let avatarColors, groupColors
    if (hasGroups) {
      const groupIds = groups.map(g => g.groupId)
      groupColors = makeGroupColorScales(theme, groupIds).primaryColors
      avatarColors = (participant) => groupColors(participant.groupingConnections[0].groupId)
    }

    const displayedParticipants = participants.slice(0, 6)

    return (
      <ComponentRoot>
        <Route path={`${match.path}/participants`} render={this.renderModal(avatarColors)} />
        <LeftColumn>
          <PaddedContent>
            <Heading.h2>
              <FormattedMessage id="ParticipantsWidget.header" defaultMessage="Participants" />
            </Heading.h2>
            {hasGroups ? <GroupLegend groups={groups} colors={groupColors} /> : null}
          </PaddedContent>
          {renderParticipantCount(participants)}
        </LeftColumn>
        <Column>
          <RecipientList
            recipients={displayedParticipants}
            colors={avatarColors}
            showGroups={hasGroups}
          />
          <Typography cta weight="light">
            <Anchor.Link inverted to={`${match.url}/participants`}>
              <FormattedMessage
                id="ParticipantsWidget.seeAll"
                defaultMessage="See all participants"
              />
            </Anchor.Link>
          </Typography>
        </Column>
      </ComponentRoot>
    )
  }
}

ParticipantsWidget.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groupingConnections: PropTypes.arrayOf(
        PropTypes.shape({
          groupName: PropTypes.string.isRequired
        })
      )
    })
  )
}

export default withRouter(withTheme(ParticipantsWidget))
