import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import FilterDropdown from '../../common/FilterDropdown/FilterDropdown'
import Typography from '../../common/Typography/Typography'

const ComponentRoot = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  & > *:nth-child(2) {
    margin-left: auto;
    margin-right: 10px;
  }
  & > *:nth-child(4) {
    margin-left: 100px;
    margin-right: 10px;
  }
`

const QvestFilter = (props) => {
  const {
    sortOptions,
    filterOptions,
    sortValue,
    filterValue,
    onSortChange,
    onFilterChange,
  } = props

  const currentFilterOption = filterOptions.find(f => f.value === filterValue)
  const currentFilterLabel = currentFilterOption ? currentFilterOption.label : ''

  return (
    <ComponentRoot>
      <Typography variant="heading4" transparent>{currentFilterLabel}</Typography>
      <Typography variant="caption" transparent>Showing</Typography>
      <FilterDropdown
        options={filterOptions}
        onChange={onFilterChange}
        value={filterValue}
      />
      <Typography variant="caption" transparent>Sort by</Typography>
      <FilterDropdown
        options={sortOptions}
        onChange={onSortChange}
        value={sortValue}
      />
    </ComponentRoot>
  )
}

QvestFilter.propTypes = {
  sortOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  sortValue: PropTypes.string,
  filterValue: PropTypes.string,
  onSortChange: PropTypes.func,
  onFilterChange: PropTypes.func,
}

export default QvestFilter
