import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IntlProvider } from 'react-intl'

import { loadLocale } from '../../../utils/i18nUtils'
import FeaturesProvider from '../../../components/common/FeaturesProvider/FeaturesProvider'
import PreviewTopBar from '../../../components/owner/PreviewTopBar/PreviewTopBar'
import QvestAbout from '../../../components/qvest/QvestAbout/QvestAbout'
import QvestHeader from '../../../components/qvest/QvestHeader/QvestHeader'
import QvestFooter from '../../../components/common/QvestFooter/QvestFooter'
import VideoManager from '../../../components/owner/VideoManager/VideoManager'
import { gql, useQuery } from '@apollo/client'
import { useInitiateVideoUpload, useRemoveVideo, useUpdateQvest } from '../../../operations/qvest'

const ComponentRoot = styled.div`
  height: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.two[0]};
  /* hide owner site footer */
  & + * { display: none; }
  & > :nth-child(2) { margin-top: 80px; }
`

const Body = styled.div`
  margin: 0 auto;
  padding: 40px;
  max-width: 1150px;
`

const QVEST_PAGE_PREVIEW_QUERY = gql`
  query q($qvestId:String!) {
    qvest(id: $qvestId) {
      qvestId
      name
      language
      topic
      state
      aboutTextSource
      hasWaves
      contactInfo {
        name
        email
        senderName
        senderTitle
      }
      organization {
        name
        logo
      }
      video {
        url
        uploadUrl
        uploadStatus
        transcodeStatus
        thumbnail
      }
      validity {
        general
        items {
          type
          name
        }
      }
    }
    participants(qvestId: $qvestId) {
      items {
        participantId,
        name,
        email,
        isSelf,
        groupingConnections {
          groupingName,
          groupName,
          groupId,
          groupingId
        }
      }
    }
    groupings(qvestId: $qvestId) {
      items {
        groupingId,
        name,
        groups {
          groupId,
          name
        }
      }
      validity {
        general,
        items {
          type,
          name
        }
      }
    }
    schedule(qvestId: $qvestId) {
      inviteAt,
      openAt,
      closeAt,
      expireAt,
    }
    configuration(qvestId: $qvestId) {
      features
    }
  }
`

// this is necessary so participant selector won't show nameless groups
function getGroupsIfValid(groupings) {
  const grouping = (groupings.items && groupings.items.length > 0) ? groupings.items[0] : null
  const validity = groupings.validity
  if (!grouping || !validity) return null

  const isGenerallyValid = (validity.general !== 'ERROR')
  const hasAllowedErrorsOnly = (
    validity.items.length > 0 &&
    validity.items.every(i => i.name === 'EMPTY_GROUPS' || i.name === 'GROUPLESS_PARTICIPANTS')
  )
  if (isGenerallyValid || hasAllowedErrorsOnly) {
    return grouping.groups
  }

  return null
}

const isTranscodingVideo = (data) =>
  (data.qvest && data.qvest.video && data.qvest.video.transcodeStatus === 'in_progress')


const OwnerPreviewQvestSitePage = ({ match, history }) => {
  const qvestId = match.params.qvestId
  const { data, startPolling, stopPolling } = useQuery(QVEST_PAGE_PREVIEW_QUERY, { variables: { qvestId }, fetchPolicy: 'cache-and-network' })
  const updateQvest = useUpdateQvest()
  const initiateVideoUpload = useInitiateVideoUpload()
  const removeVideo = useRemoveVideo()
  const [videoModal, setVideoModal] = useState(null)
  const [lastUpdate, setLastUpdate] = useState(null)

  // Poll for video transcode completion if that status is found to be in progress
  useEffect(() => {
    if (data && isTranscodingVideo(data)) return startPolling(5000)
    return stopPolling()
  }, [data])

  if (!data) return null

  const { qvest, participants, schedule, configuration, groupings } = data

  const locale = qvest.language
  const messages = loadLocale(locale)

  const groups = getGroupsIfValid(groupings)
  const validity = qvest.validity

  const handleSave = async (patch) => {
    await updateQvest(qvestId, patch)
    setLastUpdate(new Date())
  }

  const handleInitUpload = async (size) => {
    await initiateVideoUpload(qvest, size)
  }

  const handleRemoveVideo = async () => {
    await removeVideo(qvest)
    setVideoModal(null)
    setLastUpdate(new Date())
  }

  return (
    <ComponentRoot>
      <PreviewTopBar
        onSave={handleSave}
        onBack={() => history.push(`/owner/qvest/${qvestId}/topic`)}
        lastUpdate={lastUpdate}
      />
      <QvestHeader inline={true} qvest={qvest} schedule={schedule} />
      <Body>
        <IntlProvider locale={locale} messages={messages}>
          <FeaturesProvider value={configuration.features}>
            <QvestAbout
              isEditable={true}
              qvest={qvest}
              participants={participants.items}
              groups={groups}
              validity={validity}
              onSave={handleSave}
              onShowVideoManager={variant => setVideoModal(variant)}
            />
          </FeaturesProvider>
        </IntlProvider>
        <QvestFooter />
      </Body>
      <VideoManager
        variant={videoModal}
        video={qvest.video}
        onInitUpload={handleInitUpload}
        onRemoveVideo={handleRemoveVideo}
        onClose={() => setVideoModal(null)}
      />
    </ComponentRoot>
  )
}

export default OwnerPreviewQvestSitePage
