import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { areaRelativeRadiusScale } from '../../../utils/geometryUtils'


function determineColor({ nid, theme, primaryColor }) {
  return primaryColor || theme.data.primary[nid % theme.data.primary.length]
}

function determineHoverColor({ nid, theme, disabled, primaryColor, secondaryColor }) {
  if (disabled) {
    return primaryColor || theme.data.primary[nid % theme.data.primary.length]
  } else {
    return secondaryColor || theme.data.secondary[nid % theme.data.secondary.length]
  }
}

const Node = styled.circle`
  fill: ${props => determineColor(props)};
  user-select: none;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  
  &:hover {
    fill: ${props => determineHoverColor(props)};
  }
`

const NodeOutline = styled.circle`
  stroke: ${props => determineColor(props)};
  stroke-width: 3px;
  fill: none;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`


const MIN_RADIUS = {
  micro: 0,
  tiny: 0,
  small: 0,
  regular: 0
}

const MAX_RADIUS = {
  micro: 4,
  tiny: 7,
  small: 10,
  regular: 30
}

const GroupNode = (props) => {
  const {
    nid,
    value,
    minValue,
    maxValue,
    outlinedValue,
    size,
    disabled,
    primaryColor,
    secondaryColor,
    onClick
  } = props

  const maxRadius = MAX_RADIUS[size]
  const minRadius = MIN_RADIUS[size]

  const height = maxRadius * 2
  const width = maxRadius * 2

  const radiusScale = areaRelativeRadiusScale(minValue, maxValue, minRadius, maxRadius)

  let outlineNode
  if (value < outlinedValue) {
    outlineNode = (
      <NodeOutline
        nid={nid}
        r={radiusScale(outlinedValue)}
        cx={maxRadius}
        cy={maxRadius}
        disabled={disabled}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        onClick={() => onClick && onClick()}
      />
    )
  }

  return (
    <svg height={height} width={width}>
      {outlineNode}
      <Node
        nid={nid}
        r={radiusScale(value)}
        cx={maxRadius}
        cy={maxRadius}
        disabled={disabled}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        onClick={() => onClick && onClick()}
      />
    </svg>
  )
}

GroupNode.propTypes = {
  nid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  value: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  outlinedValue: PropTypes.number,
  size: PropTypes.oneOf(Object.keys(MIN_RADIUS)),
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

GroupNode.defaultProps = {
  outlinedValue: 5,
  size: 'regular',
  disabled: false
}

export default GroupNode
