import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Card from '../../../components/common/Card/Card'
import Heading from '../../../components/common/Typography/Heading'

import * as authUtils from '../../../actions/utils/authUtils'
import Typography from '../../../components/common/Typography/Typography'


const ComponentRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const NarrowCard = styled(Card)`
  max-width: 460px;
`


const Body = ({ loading }) => {
  let message
  if (loading) {
    message = (<Typography>Finalizing Qvest access...</Typography>)
  } else {
    message = (<Typography>Access finalized.</Typography>)
  }
  return (<Card.Body>{message}</Card.Body>)
}

const Message = ({ error }) => {
  if (error) {
    return (
      <Card.Message>
        <Typography>You are successfully logged in. Admin access required to proceed. Please contact us for further information on how to obtain access.</Typography>
      </Card.Message>
    )
  }
  return null
}

const OidcFinalizePage = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const completeFlow = async () => {
      const result = await authUtils.completeFlow()
      if (!result) {
        setError({ id: 1, type: 'error' })
      }
      setLoading(false)
    }
    completeFlow()
  }, [])

  return (
    <ComponentRoot>
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <NarrowCard>
        <Card.Header>
          <Heading variant="heading2">Welcome!</Heading>
        </Card.Header>
        <Message error={error} />
        <Body loading={loading} />
      </NarrowCard>
    </ComponentRoot>
  )
}

export default OidcFinalizePage
