import styled from 'styled-components'
import PropTypes from 'prop-types'

const Input = styled.input`
  width: 100%;
  padding: 4px 8px 4px 8px;
  background-color: ${({ theme, inverted }) => inverted ? theme.inverted.two[1] : theme.two[0]};
  opacity: ${({ disabled }) => disabled ? 0.5 : 1.0};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, inverted }) => inverted ? theme.inverted.two[0] : '#DEDFDF'};
  border-radius: 3px;
  color: ${({ theme, inverted }) => inverted ? theme.inverted.one[0] : theme.one[0]};
  caret-color: ${({ theme }) => theme.color[0]};
  font-family: ${({ theme }) => theme.font1};
  font-weight: 600;
  font-size: ${({ variant }) => (variant === 'large' ? 14 : 13)}px;
  line-height: ${({ variant }) => (variant === 'large' ? 24 : 21)}px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus {
    border: 1px solid ${({ theme }) => theme.color[0]};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme, inverted }) => inverted ? theme.inverted.two[2] : '#DEDFDF'};
  }
`

Input.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool
}

Input.defaultProps = {
  type: 'text',
  disabled: false
}

export default Input
