import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Table from '../../common/Table/Table'


const Row = styled.tr`
  font-weight: ${({ isTotal }) => isTotal ? '700' : '500'};
`

const OverallParticipationTable = ({ isLoading, participation }) => {
  const hasData = (participation && participation.length)
  return (
    <Table isLoading={isLoading}>
      <thead>
        <tr>
          <th><FormattedMessage id="owner.OverallParticipationTable.name" defaultMessage="Group" /></th>
          <th><FormattedMessage id="owner.OverallParticipationTable.participants" defaultMessage="Participants" /></th>
          <th><FormattedMessage id="owner.OverallParticipationTable.active" defaultMessage="Active" /></th>
          <th><FormattedMessage id="owner.OverallParticipationTable.activePct" defaultMessage="Percentage" /></th>
        </tr>
      </thead>
      <tbody>
        {hasData && participation.map(group => (
          <Row key={group.groupId} isTotal={group.groupId === '-1'}>
            <td>{(group.groupId !== '-1') ? group.name : ''}</td>
            <td>{group.participantCount}</td>
            <td>{group.participantActiveCount}</td>
            <td>{group.participationRate}%</td>
          </Row>
        ))}
      </tbody>
    </Table>
  )
}

OverallParticipationTable.propTypes = {
  isLoading: PropTypes.bool,
  participation: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string,
    name: PropTypes.string,
    participantCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    participantActiveCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    participationRate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  }))
}

OverallParticipationTable.defaultProps = {
  isLoading: false,
  participants: [],
}

export default OverallParticipationTable
