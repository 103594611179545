import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import Modal from '../../../components/common/Modal/Modal'
import Card from '../../../components/common/Card/Card'
import Button from '../../../components/common/Button/Button'
import Typography from '../../../components/common/Typography/Typography'
import OwnerOrganizationUsersTable from '../../../components/owner/OwnerOrganizationUsersTable/OwnerOrganizationUsersTable'
import OrganizationSettingsCard from '../../../components/owner/settings/OrganizationSettingsCard/OrganizationSettingsCard'
import ManageUserModal from '../../../components/owner/ManageUserModal/ManageUserModal'
import { filterByOrganizationId } from '../../../reducers/organization'
import { inviteUserToOrganization } from '../../../actions/userActions'
import {
  getUsersInOrganization,
  getPendingUsersInOrganization,
  updateOrganization,
  removeUserFromOrganization,
  uploadOrganizationLogo
} from '../../../actions/organizationActions'

const messages = defineMessages({
  title: {
    id: 'owner.OwnerOrganizationSettingsPage.title',
    defaultMessage: 'Settings | {organizationName}',
  }
})

const ComponentRoot = styled.div`
  padding: 20px;
  min-width: 1000px;
  & > * { margin-bottom: 20px; }
`

const TopRow = styled.div`
  position: relative;
  & > *:first-child {
    width: calc(50% - 10px);
  }
  & > *:last-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(50% - 10px);
  }
  & > *:last-child > * {
    height: 100%;
    /* overriding Card defaults */
    display: flex;
    flex-direction: column;
  }
`

const InternalScroll = styled.div`
  overflow-y: auto;
  height: 100%;
`

class OwnerOrganizationSettingsPage extends Component {
  state = {
    showInviteModal: false,
    showRemoveModal: false,
    userEmail: null,
    userName: null,
    userId: null,
    isInvitingUser: false,
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const organizationId = match.params.organizationId
    dispatch(getUsersInOrganization(organizationId))
    dispatch(getPendingUsersInOrganization(organizationId))
  }

  handleShowInviteModal = (name, email) => {
    this.setState({
      userEmail: email,
      userName: name,
      showInviteModal: true,
      showRemoveModal: false,
    })
  }

  handleShowRemoveModal = (userId) => {
    const { match, organizationStore } = this.props
    const organizationId = match.params.organizationId
    const organization = filterByOrganizationId(organizationStore, organizationId)
    const { fullName: userName } = organization.users.find(user => user.userId === userId)
    this.setState({ userId, userName, showInviteModal: false, showRemoveModal: true })
  }

  handleHideModals = () => {
    this.setState({
      showInviteModal: false,
      showRemoveModal: false,
      userName: null,
      userEmail: null,
      userId: null,
      isInvitingUser: false,
    })
    this.handleCancelInviteUser()
  }

  handleNameChange = (name) => {
    const { dispatch, match } = this.props
    const patch = { name }
    dispatch(updateOrganization(match.params.organizationId, patch))
  }

  handleRemoveUser = () => {
    const { dispatch, match } = this.props
    const { userId } = this.state
    this.handleHideModals()
    dispatch(removeUserFromOrganization(match.params.organizationId, userId))
  }

  handleInviteUser = () => {
    const { dispatch, match } = this.props
    const { userEmail, userName } = this.state
    const organizationId = match.params.organizationId
    dispatch(inviteUserToOrganization(userEmail, organizationId, userName))
      .then(() => {
        // TODO: loading state here
        this.setState({ userEmail: null, userName: null })
        this.handleHideModals()
      })
  }

  handleShowInviteUser = () => {
    this.setState({ isInvitingUser: true })
  }

  handleCancelInviteUser = () => {
    this.setState({ isInvitingUser: false })
  }

  handleLogoFileChange = (file) => {
    const { dispatch, match } = this.props
    return dispatch(uploadOrganizationLogo(match.params.organizationId, file))
  }

  renderModal() {
    const { match, organizationStore } = this.props
    const { showInviteModal, showRemoveModal, userName } = this.state
    if (!showInviteModal && !showRemoveModal) return null
    const organizationId = match.params.organizationId
    const organization = filterByOrganizationId(organizationStore, organizationId)
    return (
      <Modal onBackgroundClick={this.handleHideModals} onEscape={this.handleHideModals}>
        <ManageUserModal
          name={userName}
          organizationName={organization.name}
          isRemove={showRemoveModal}
          onInvite={this.handleInviteUser}
          onRemove={this.handleRemoveUser}
          onCancel={this.handleHideModals}
        />
      </Modal>
    )
  }

  renderUsers(users, pendingUsers) {
    const { organizationStore } = this.props
    const { isInvitingUser } = this.state
    const isDuplicateEmailError = organizationStore.error
      && Array.isArray(organizationStore.error)
      && organizationStore.error.find(err => err.message === 'ALREADY_IN_ORGANIZATION')

    const error = (
      <Card.Message error>
        <Typography cta tertiary variant="small">
          <FormattedMessage
            id="owner.OrganizationSettingsPage.duplicateError"
            defaultMessage="That email is already in use by an existing user in this Qvest organization."
          />
        </Typography>
      </Card.Message>
    )

    return (
      <Card>
        <Card.Header>
          <Typography variant="heading3">
            <FormattedMessage id="owner.OrganizationSettingsPage.users" defaultMessage="Users" />
          </Typography>
          <Button outline onClick={this.handleShowInviteUser}>
            <FormattedMessage id="owner.OwnerOrganizationSettingsPage.addUser" defaultMessage="Add user" />
          </Button>
        </Card.Header>
        {isDuplicateEmailError ? error : null}
        <InternalScroll>
          <OwnerOrganizationUsersTable
            users={users}
            pendingUsers={pendingUsers}
            showInvite={isInvitingUser}
            onRemove={this.handleShowRemoveModal}
            onCancel={this.handleCancelInviteUser}
            onInvite={this.handleShowInviteModal}
          />
        </InternalScroll>
      </Card>
    )
  }

  render() {
    const { match, organizationStore, intl } = this.props
    const organizationId = match.params.organizationId

    const organization = filterByOrganizationId(organizationStore, organizationId)
    const hasUsers = organization.hasOwnProperty('users')
    const hasPendingUsers = organization.hasOwnProperty('pendingUsers')
    const masterUser = (hasUsers ? organization.users.find(u => u.isMaster) : undefined)
    const title = intl.formatMessage(messages.title, {
      organizationName: organization && organization.name
    })

    return (
      <ComponentRoot>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <TopRow>
          <OrganizationSettingsCard
            hasLogoUpload
            organization={organization}
            masterUser={masterUser}
            onNameChange={this.handleNameChange}
            onLogoFileChange={this.handleLogoFileChange}
          />
          {hasUsers && hasPendingUsers && this.renderUsers(organization.users, organization.pendingUsers)}
        </TopRow>
        {this.renderModal()}
      </ComponentRoot >
    )
  }
}

function mapStateToProps(state) {
  return {
    organizationStore: state.organization.toJS(),
  }
}

export default connect(mapStateToProps)(injectIntl(OwnerOrganizationSettingsPage))
