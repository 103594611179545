import Immutable from 'immutable'

let INITIAL_DETAILS_STATE = Immutable.Map({
  status: null,
  error: null,
  isFetching: false
})

let INITIAL_STATE = Immutable.Map({
  api: INITIAL_DETAILS_STATE,
  database: INITIAL_DETAILS_STATE
})

function statusDetails(state = INITIAL_DETAILS_STATE, prefix, action) {
  switch (action.type) {
    case prefix + 'GET_STATUS_REQUEST': {
      return state.merge({
        isFetching: true
      })
    }
    case prefix + 'GET_STATUS_SUCCESS': {
      return state.merge({
        status: 'OK',
        error: null,
        isFetching: false
      })
    }
    case prefix + 'GET_STATUS_FAILURE': {
      return state.merge({
        status: 'ERROR',
        error: action.error.message,
        isFetching: false
      })
    }
    default:
      return state
  }
}

const status = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DB_GET_STATUS_REQUEST':
    case 'DB_GET_STATUS_SUCCESS':
    case 'DB_GET_STATUS_FAILURE':
      return state.merge({
        database: statusDetails(state.database, 'DB_', action)
      })
    case 'API_GET_STATUS_REQUEST':
    case 'API_GET_STATUS_SUCCESS':
    case 'API_GET_STATUS_FAILURE':
      return state.merge({
        api: statusDetails(state.api, 'API_', action)
      })
    default:
      return state
  }
}

export default status
