import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'


export const findRelevantAssignment = (question, themes) => {
  return question.themes.assignments.find(a => themes.includes(a.theme.themeId))
}

export const findQuestion = (questions, questionId) => {
  const question = questions.find(q => q.questionId === questionId)
  const index = questions.indexOf(question)
  return [question, index]
}

export const findRelevantThemeSuggestions = (questions, themes, suggestionsThemeSet, rejectedThemes) => {
  let suggestedThemes = suggestionsThemeSet ? suggestionsThemeSet.themes : []
  // Filter out irrelevant suggestions that ...
  suggestedThemes = suggestedThemes.filter(theme =>
    // ... have already been suggested
    !themes.find(t => t.themeId === theme.themeId) &&
    // ... have been rejected by user
    (!rejectedThemes || !rejectedThemes.includes(theme.themeId)) &&
    // ... contains questions already assigned to a theme
    !questions
      .filter(question => question.themes.assignments.find(a => a.theme.themeId === theme.themeId))
      .find(question => question.theme.themeSet.label === 'DEFAULT')
  )
  // Take a subset of remaining suggestions (if no existing themes take 3 and otherwise take 1)
  const sliceSize = (themes.length === 0) ? 3 : 1
  return suggestedThemes.slice(0, sliceSize)
}

export const resolvePosition = (previousQuestion, nextQuestion) => {
  if (previousQuestion && nextQuestion) {
    const diffPosition = (nextQuestion.position - previousQuestion.position)
    if (diffPosition === 0) return nextQuestion.position // Dodge division by zero. This should hardly ever happen, but concurrent edit by multiple users could theoretically create such a state
    // Between questions, new position is previous neighbour plus half of difference between neighbours
    return previousQuestion.position + (diffPosition / 2)
  } else if (nextQuestion) {
    // No previous neighbour, new position is next neighbour minus 1
    return nextQuestion.position - 1
  } else if (previousQuestion) {
    // No next neighbour, new position is previous neighbour plus 1
    return previousQuestion.position + 1
  }
  return 0
}

export const initializeQuestions = (questions, themes, themeSetId) => {
  const defaultTheme = { themeId: 'default', themeSet: { themeSetId } }
  const themeIds = themes.map(theme => theme.themeId)
  let defaultPosition = 0
  let questionsWithPosition = []
  for (const question of questions) {
    // Collect theme relevant to current themeSet (insert default theme if none found)
    let theme = defaultTheme
    let position = defaultPosition
    const assignment = findRelevantAssignment(question, themeIds)
    if (assignment && assignment.theme) {
      theme = assignment.theme
    }
    if (assignment && assignment.position) {
      position = assignment.position
    } else {
      defaultPosition += 1
    }
    // Yield new question with theme
    questionsWithPosition.push({ ...question, theme, position })
  }
  // Sort by position and pin count
  questionsWithPosition = sortBy(questionsWithPosition, q => -q.pins.count)
  questionsWithPosition = sortBy(questionsWithPosition, 'position')
  return questionsWithPosition
}

export const groupQuestionsByTheme = (questions, themes) => {
  // Group questions into themes
  const questionsByTheme = groupBy(questions, q => q.theme.themeId)
  // Add empty arrays for themes without questions
  themes.forEach(({ themeId }) => {
    if (!questionsByTheme.hasOwnProperty(themeId)) {
      questionsByTheme[themeId] = []
    }
  })
  return questionsByTheme
}
