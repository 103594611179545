import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import Card from '../../common/Card/Card'
import Icon from '../../common/Icon/Icon'
import Input from '../../common/Input/Input'
import Button from '../../common/Button/Button'
import Typography from '../../common/Typography/Typography'
import Anchor from '../../common/Anchor/Anchor'
import Checkbox from '../../common/Checkbox/Checkbox'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'

const ComponentRoot = styled.div`
  width: 100%;
  max-width: 460px;
`

const SuccessWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  & > *:first-child { margin-bottom: 20px; }
  & > *:nth-child(2) { width: 100%; }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > *:first-child { margin-right: auto; }
`

const Form = styled.form`
  & > label {
    display: block;
    margin-top: 18px;
  }
  & > label > *:first-child {
    display: inline-block;
    margin-bottom: 4px;
  }
  & > *:last-child { margin-top: 20px; }
`

const Label = styled.label`
  font-family: ${({ theme }) => theme.font3};
  font-weight: 900;
  font-size: 13px;
  line-height: 15px;
  color: ${({ theme }) => theme.one[0]};
`

const ConsentArea = styled.div`
  margin-top: 20px;
  & > :first-child { margin-bottom: 6px; }
`

const messages = defineMessages({
  missingData: { defaultMessage: 'Please fill out all mandatory fields', id: 'owner.JamSignupCard.submitTitle.missingData' },
  missingConsentTerms: { defaultMessage: 'Please indicate that you agree to our terms of service by checking the box', id: 'owner.JamSignupCard.submitTitle.missingConsentTerms'},
  missingConsentEmail: { defaultMessage: 'Please indicate that you agree to receive content from us by checking the box', id: 'owner.JamSignupCard.submitTitle.missingConsentEmail'}
})


class JamSignupCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: null,
      fullName: props.fullName,
      organizationName: null,
      phone: null,
      password: null,
      hasTermsConsent: false,
      hasEmailConsent: false,
    }

    this.onEmailChange = this.onEmailChange.bind(this)
    this.onFullNameChange = this.onFullNameChange.bind(this)
    this.onOrganizationNameChange = this.onOrganizationNameChange.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.onPhoneChange = this.onPhoneChange.bind(this)
    this.onToggleTermsConsent = this.onToggleTermsConsent.bind(this)
    this.onToggleEmailConsent = this.onToggleEmailConsent.bind(this)
    this.onSignupClick = this.onSignupClick.bind(this)
  }

  renderError() {
    const { error } = this.props
    if (!error) return null
    let message = (
      <FormattedMessage
        id="owner.JamSignupCard.genericError"
        defaultMessage="Unfortunately, something went wrong. Try again!"
      />
    )
    if (error.message === 'EMAIL_ALREADY_KNOWN') {
      message = (
        <FormattedMessage
          id="owner.JamSignupCard.accountExistsError"
          defaultMessage="A Qvest account with the given e-mail address already exists."
        />
      )
    }
    return (
      <Card.Message error>
        <Typography cta tertiary variant="medium">{message}</Typography>
      </Card.Message>
    )
  }

  renderEmail() {
    if (!this.props.showEmail) return null
    return (
      <Label for="email-field">
        <span>Email address</span>
        <Input
          id="email-field"
          placeholder="jonny@jam.now"
          value={this.state.email || ''}
          onChange={this.onEmailChange}
        />
      </Label>
    )
  }

  renderFullName() {
    if (!this.props.showFullName) return null
    return (
      <Label for="name-field">
        <span>Your full name</span>
        <Input
          id="name-field"
          placeholder="Jonny Jam"
          value={this.state.fullName || ''}
          onChange={this.onFullNameChange}
        />
      </Label>
    )
  }

  renderOrgName() {
    if (!this.props.showOrganizationName) return null
    return (
      <Label for="org-field">
        <span>Where you work</span>
        <Input
          id="org-field"
          placeholder="Adventures Ahead Inc."
          value={this.state.organizationName || ''}
          onChange={this.onOrganizationNameChange}
        />
      </Label>
    )
  }

  renderPassword() {
    if (!this.props.showPassword) return null
    return (
      <Label for="password-field">
        <span>Password</span>
        <Input
          id="password-field"
          type="password"
          placeholder="***********"
          value={this.state.password || ''}
          onChange={this.onPasswordChange}
        />
      </Label>
    )
  }

  renderPhone() {
    if (!this.props.showPhone) return null
    return (
      <Label for="phone-field">
        <span>Phone number</span>
        <Input
          id="phone-field"
          type="tel"
          placeholder="(optional)"
          value={this.state.phone || ''}
          onChange={this.onPhoneChange}
        />
      </Label>
    )
  }

  renderConsentBoxes() {
    const { hasTermsConsent, hasEmailConsent } = this.state
    if (!this.props.showConsentBoxes) return null
    const termsLink = (
      <Typography cta variant="small" weight="light">
        <Anchor inverted href="https://questionjam.com/terms" target="_blank" rel="noopener noreferrer">
          <FormattedMessage
            id="owner.JamSignupCard.termsConsentLink"
            defaultMessage="Terms of Service and the Privacy Policy."
          />
        </Anchor>
      </Typography>

    )
    return (
      <ConsentArea>
        <div>
          <label htmlFor="terms-consent-field">
            <Checkbox id="terms-consent-field" checked={hasTermsConsent} onChange={this.onToggleTermsConsent}>
              <Typography variant="small" weight="light">
                <FormattedMessage
                  id="owner.JamSignupCard.termsConsent"
                  defaultMessage="I agree to the {termsLink}"
                  values={{ termsLink }}
                />
              </Typography>
            </Checkbox>
          </label>
        </div>
        <div>
          <label htmlFor="email-consent-field">
            <Checkbox id="email-consent-field" checked={hasEmailConsent} onChange={this.onToggleEmailConsent}>
              <Typography variant="small" weight="light">
                <FormattedMessage
                  id="owner.JamSignupCard.emailConsent"
                  defaultMessage="I agree to receive marketing emails regarding Question Jam - e.g. relevant guides and case studies."
                />
              </Typography>
            </Checkbox>
          </label>
        </div>
      </ConsentArea>
    )
  }

  renderSignup() {
    const { isLoading, showEmail, showFullName, showOrganizationName, showPassword, showConsentBoxes, intl } = this.props
    const { email, fullName, organizationName, password, hasTermsConsent, hasEmailConsent } = this.state

    const hasSufficientData =
      (!showEmail || email) &&
      (!showFullName || fullName) &&
      (!showOrganizationName || organizationName) &&
      (!showPassword || password)

    const hasConsent = (hasTermsConsent && hasEmailConsent) || !showConsentBoxes

    let submitTitle
    if (!hasSufficientData) {
      submitTitle = intl.formatMessage(messages.missingData)
    } else if (!hasTermsConsent) {
      submitTitle = intl.formatMessage(messages.missingConsentTerms)
    } else if (!hasEmailConsent) {
      submitTitle = intl.formatMessage(messages.missingConsentEmail)
    }

    return (
      <Form>
        <Typography variant="medium" weight="light">
          <FormattedMessage
            id="owner.JamSignupCard.intro"
            defaultMessage="Create your own account by filling in the form below."
          />
        </Typography>
        {this.renderEmail()}
        {this.renderFullName()}
        {this.renderPhone()}
        {this.renderOrgName()}
        {this.renderPassword()}
        {this.renderConsentBoxes()}
        <Footer>
          <Typography cta variant="small" weight="light">
            <Anchor.Link inverted to="/owner">
              <FormattedMessage
                id="owner.JamSignupCard.alreadySignedUp"
                defaultMessage="Already have an account"
              />
            </Anchor.Link>
          </Typography>
          <Button
            disabled={isLoading || !hasSufficientData || !hasConsent}
            title={submitTitle}
            onClick={this.onSignupClick}
          >
            <FormattedMessage
              id="owner.JamSignupCard.submitButton"
              defaultMessage="Sign up"
            />
          </Button>
        </Footer>
      </Form>
    )
  }

  renderSuccess() {
    return (
      <SuccessWrapper>
        <Icon variant="paper-plane" size="medium" secondary />
        <Typography weight="light">
          <FormattedMessage
            id="owner.JamSignupCard.confirmEmail"
            defaultMessage="To keep your account safe, we'd like you to confirm your identity via email. Go click the link."
          />
        </Typography>
      </SuccessWrapper>
    )
  }

  render() {
    const { wasSent, isLoading } = this.props

    let headerText = (
      <FormattedMessage defaultMessage="Create Account" id="owner.JamSignupCard.header" />
    )

    if (wasSent) {
      headerText = (
        <FormattedMessage defaultMessage="You got an email" id="owner.JamSignupCard.headerEmailSent" />
      )
    }

    return (
      <ComponentRoot>
        <Card>
          <Card.Header>
            <Typography variant="heading3">{headerText}</Typography>
            {isLoading && <CenteredLoader height="auto" width="auto" />}
          </Card.Header>
          {this.renderError()}
          <Card.Body>
            {wasSent ? this.renderSuccess() : this.renderSignup()}
          </Card.Body>
        </Card>
      </ComponentRoot>
    )
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onFullNameChange(event) {
    this.setState({ fullName: event.target.value })
  }

  onOrganizationNameChange(event) {
    this.setState({ organizationName: event.target.value })
  }

  onPasswordChange(event) {
    this.setState({ password: event.target.value })
  }

  onPhoneChange(event) {
    this.setState({ phone: event.target.value })
  }

  onToggleTermsConsent() {
    this.setState({ hasTermsConsent: !this.state.hasTermsConsent })
  }

  onToggleEmailConsent() {
    this.setState({ hasEmailConsent: !this.state.hasEmailConsent })
  }

  onSignupClick(event) {
    event.preventDefault()
    const { onSignup } = this.props
    const { email, fullName, organizationName, password, phone, hasEmailConsent } = this.state
    const credentials = {
      fullName,
      password
    }
    if (email) {
      credentials.email = email.trim()
    }
    if (phone) {
      credentials.phone = phone.trim()
    }
    onSignup(credentials, organizationName, hasEmailConsent)
  }
}

JamSignupCard.propTypes = {
  onSignup: PropTypes.func,
  isLoading: PropTypes.bool,
  showEmail: PropTypes.bool,
  showFullName: PropTypes.bool,
  showOrganizationName: PropTypes.bool,
  showPassword: PropTypes.bool,
  showPhone: PropTypes.bool,
  showConsentBoxes: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  fullName: PropTypes.string,
}

JamSignupCard.defaultProps = {
  onSignup: () => { },
  isLoading: false,
  showEmail: false,
  showFullName: false,
  showOrganizationName: false,
  showPassword: false,
  showPhone: false,
  showConsentBoxes: true,
  fullName: null,
}

export default injectIntl(JamSignupCard)
