import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import styled, { withTheme } from 'styled-components'
import ContainerDimensions from 'react-container-dimensions'

import Typography from '../../../common/Typography/Typography'
import { BarChart, activityMapping } from 'data-viz'
import AnalyticsCard from '../../AnalyticsCard/AnalyticsCard'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 223px;
  padding: 30px 0 20px 0;
`

const Details = styled.div`
  flex: 0 0 21px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
`

const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const titleMessages = defineMessages({
  LOW: { id: 'owner.ActivityAnalyticsCard.title.low', defaultMessage: 'Low activity' },
  OK: { id: 'owner.ActivityAnalyticsCard.title.ok', defaultMessage: 'Good activity' },
  HIGH: { id: 'owner..ActivityAnalyticsCard.title.high', defaultMessage: 'High activity' },
  DIVERSE: { id: 'owner.ActivityAnalyticsCard.title.diverse', defaultMessage: 'Varied activity' },
})

class ActivityAnalyticsCard extends Component {
  handleClick = () => this.props.onClick('activity')
  renderTitle(data) {
    const { intl } = this.props
    if (data.isDiverse) {
      return intl.formatMessage(titleMessages.DIVERSE)
    } else {
      return intl.formatMessage(titleMessages[data.type])
    }
  }
  renderChart(data, colors) {
    const { locked } = this.props
    const { isDiverse, formattedSum, lowest, highest } = data
    formattedSum[0].fill = colors()
    return (
      <ChartContainer>
        <ContainerDimensions>
          {({ width }) =>
            <BarChart minimal inverted={locked} data={isDiverse ? [lowest, highest] : formattedSum} width={width} />
          }
        </ContainerDimensions>
      </ChartContainer>
    )
  }
  renderDetails(participationRate) {
    const { locked } = this.props
    const percentage = Math.round(participationRate)
    return (
      <Details>
        <Typography inverted={locked} variant="small" weight="light">
          <FormattedMessage defaultMessage="Total activity:" id="owner.ActivityAnalyticsCard.details.label" />
          &nbsp;
        </Typography>
        <Typography inverted={locked} variant="small" weight="bold">
          {percentage} %
        </Typography>
      </Details>
    )
  }
  render() {
    const { theme, participation, locked, loading } = this.props
    const colors = () => theme.inverted.two[0]

    if (loading) return (<AnalyticsCard loading />)

    let title, chart, details
    if (participation) {
      const data = activityMapping.formatData(participation, theme, colors)
      title = this.renderTitle(data)
      chart = this.renderChart(data, colors)
      details = this.renderDetails(data.sum.participationRate)
    } else {
      chart = this.renderChart({ isDiverse: false, formattedSum: [{ value: 0 }] }, colors)
    }

    return (
      <AnalyticsCard
        subTitle="Activity"
        title={title}
        onClick={this.handleClick}
        locked={locked}
        loading={loading}
      >
        <Content>
          {chart}
          {details}
        </Content>
      </AnalyticsCard>
    )
  }
}

export default withTheme(injectIntl(ActivityAnalyticsCard))
