import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'

import Card from '../../../../../components/common/Card/Card'
import Typography from '../../../../../components/common/Typography/Typography'
import Disclaimer from '../../../../../components/owner/Disclaimer/Disclaimer'
import AnalyticsKeyStakeholders from '../../../../../components/owner/AnalyticsKeyStakeholders/AnalyticsKeyStakeholders'
import { TitleBar } from '../../../../../components/owner/AnalyticsLayout/AnalyticsLayout'
import ParticipantsActivityTable from '../../../../../components/owner/ParticipantsActivityTable/ParticipantsActivityTable'
import { filterParticipantsActivityByQvestId } from '../../../../../reducers/stats'
import { filterGroupingsByQvest } from '../../../../../reducers/grouping'
import { getParticipantsActivity } from '../../../../../actions/statsActions'
import { getGroupingByQvestId } from '../../../../../actions/groupingActions'

const ComponentRoot = styled.div`
  & > * { max-width: 1400px; }
  & > :nth-child(3) {
    padding: 0 30px;
    margin-top: 30px;
    min-width: 1270px;
  }
  & > :last-child {
    margin: 50px 30px 30px 30px;
    max-width: 989px
  }
`

class OwnerKeyStakeholdersPage extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getParticipantsActivity(match.params.qvestId))
    dispatch(getGroupingByQvestId(match.params.qvestId))
  }

  handleBackClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics/home`)
  }

  renderChartCards(participantsActivity, grouping) {
    return (
      <AnalyticsKeyStakeholders activity={participantsActivity} groups={grouping.groups} />
    )
  }

  render() {
    const { participantsActivityStore, groupingStore, match } = this.props

    const grouping = filterGroupingsByQvest(groupingStore, match.params.qvestId)
    const participantsActivity = filterParticipantsActivityByQvestId(participantsActivityStore, match.params.qvestId)

    let charts
    let sortedParticipantsActivity = []
    if (participantsActivity && grouping) {
      charts = this.renderChartCards(participantsActivity, grouping)
      sortedParticipantsActivity = sortBy(participantsActivity, a => -a.questionReceivedCount)
    }

    const title = <FormattedMessage
      id="owner.AnalyticsStakeholders.title"
      defaultMessage="Key Stakeholders"
    />
    const description = <FormattedMessage
      id="owner.AnalyticsStakeholders.description"
      defaultMessage="Commitment to Qvest project"
    />

    return (
      <ComponentRoot>
        <TitleBar
          title={title}
          description={description}
          onBack={this.handleBackClick}
        />
        {charts}
        <div>
          <Card>
            <Card.Header>
              <Typography variant="heading3">Individual participants</Typography>
            </Card.Header>
            <ParticipantsActivityTable
              participants={sortedParticipantsActivity}
              isLoading={participantsActivityStore.isLoading}
            />
          </Card>
        </div>
        <Disclaimer />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    groupingStore: state.grouping.toJS(),
    participantsActivityStore: state.stats.get('participantsActivity').toJS()
  }
}

export default connect(mapStateToProps)(OwnerKeyStakeholdersPage)
