import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import Typography from '../Typography/Typography'
import Anchor from '../Anchor/Anchor'
import Icon from '../Icon/Icon'
import VideoBlock from './VideoBlock'
import EditableSpan from '../EditableSpan2/EditableSpan'
import EditablePurposeBox from '../../owner/EditablePurposeBox/EditablePurposeBox'
import { renderMarkdown } from '../../../utils/markdownUtils'
import Button from '../Button/Button'


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const IconButton = styled(Button)`
  height: 26px;
  & > * {
    display: block;
    width: 14px;
  }
`

const HeadingBlock = styled.div`
  display: flex;
  margin-bottom: 3px;

  & > :last-child {
    display: ${({ editable }) => editable ? 'block' : 'none' };
    margin: 4px 0 0 10px;
  }
`

/*
  Since we allow custom HTML, we can't rely on the Typography component to style text here.
  Styling is equivalent to typography variant "regular" and "bold".
*/
const AboutText = styled.div`
  font-family: ${props => props.theme.font1};
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: ${props => props.theme.default};
  width: 100%;
  opacity: ${({ loading }) => loading ? '0.5' : '1.0'};


  & p {
    margin-block-start: 0;
  }

  & strong {
    font-weight: 800;
  }

  & a {
    color: ${({ theme }) => theme.color[0]};
  }

  & a:hover {
    text-decoration: underline;
  }

  & > :nth-child(2) {
    margin-top: 25px;
  }

  @media (min-width: 700px) {
    column-count: 2;
    column-gap: 50px;
  }
`

const AboutBlock = styled.div`
  margin-bottom: 50px;
  width: 100%;
`

const ContactBox = styled.div`
  width: 100%;
  opacity: ${({ loading }) => loading ? '0.5' : '1.0'};
`

const Signature = styled.div`
  & > * { display: block; }
`

const placeHolders = defineMessages({
  aboutText: { defaultMessage: 'Motivational text on context and purpose', id: 'owner.PurposeWidget.placeholder.aboutText' }
})

const titles = defineMessages({
  edit: { defaultMessage: 'Edit purpose', id: 'owner.PurposeWidget.titles.edit' }
})

const SenderName = ({ contactInfo }) => {
  const hasSender = contactInfo && contactInfo.senderName
  if (!hasSender) return null
  const senderTitle = contactInfo.senderTitle
  const title = senderTitle ? <Typography weight="light">{senderTitle}</Typography> : null

  return (
    <Signature>
      <Typography>
        {contactInfo.senderName}
      </Typography>
      {title}
    </Signature>
  )
}

const ContactName = ({ contactInfo }) => {
  const hasContact = contactInfo && contactInfo.name
  if (!hasContact) return null
  const email = hasContact ? contactInfo.email : 'support@qvest.io'

  return (
    <Signature>
      <Typography weight="light">
        <FormattedMessage defaultMessage="Contact:" id="QvestAbout.contactLabel" />
      </Typography>
      <div>
        <Typography weight="light">
          {contactInfo.name}
          &nbsp;-&nbsp;
        </Typography>
        <Typography cta weight="light">
          <Anchor inverted href={`mailto:${email}`}>{email}</Anchor>
        </Typography>
      </div>
    </Signature>
  )
}

const ContactPlaceholder = ({ contactInfo }) => {
  if (contactInfo && (contactInfo.name || contactInfo.senderName)) return null
  return (
    <Typography>
      <FormattedMessage id="PurposeWidget.contactInfo" defaultMessage="[Contact Info]" />
    </Typography>
  )
}

const EmailError = ({ errors }) => {
  const hasInvalidEmail = errors && errors.some(e => e.name === 'INVALID_CONTACT_EMAIL')
  if (!hasInvalidEmail) return null

  return (
    <p>
      <Icon variant="exclamation-circle" error />
      &nbsp;
      <Typography variant="small" weight="light">
        <FormattedMessage defaultMessage="Contact email has invalid format" id="PurposeWidget.error.INVALID_CONTACT_EMAIL" />
      </Typography>
    </p>
  )
}

const DisplayedWidget = ({ contactInfo, errors, aboutTextSource, loading, intl }) => {
  const aboutText = aboutTextSource
    ? renderMarkdown(aboutTextSource)
    : `[${intl.formatMessage(placeHolders.aboutText)}]`

  return (
    <Fragment>
      <AboutBlock>
        <AboutText loading={loading} dangerouslySetInnerHTML={{ __html: aboutText }} />
      </AboutBlock>
      <ContactBox loading={loading}>
        <SenderName contactInfo={contactInfo} />
        <ContactName contactInfo={contactInfo} />
        <ContactPlaceholder contactInfo={contactInfo} />
        <EmailError errors={errors} />
      </ContactBox>
    </Fragment>
  )
}

const PurposeWidget = props => {
  const {
    video,
    isEditable,
    contactInfo,
    aboutTextSource,
    intl,
    disableContactEmail,
    onSave,
    onShowVideoManager
  } = props
  const [isEditing, setEditing] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = (patch) => {
    setEditing(false)
    setLoading(true)
    return onSave(patch)
      .finally(() => setLoading(false))
  }

  const handleCancel = () => setEditing(false)
  const handleStartEdit = () => setEditing(true)

  // heading
  const heading = (
    <HeadingBlock editable={isEditable && !isEditing}>
      <Typography variant="heading2" paragraph transparent={isLoading}>
        <FormattedMessage defaultMessage="Purpose" id="QvestAbout.mainHeading" />
      </Typography>
      <IconButton size="tiny" title={intl.formatMessage(titles.edit)} onClick={handleStartEdit}>
        <Icon clickable tertiary variant="edit" size="small" />
      </IconButton>
    </HeadingBlock>
  )

  // uneditable state
  let displayed = <DisplayedWidget {...props} loading={isLoading} />

  // editable state
  let content
  if (isEditable) {
    let editableContent
    if (isEditing) {
      // editing state
      editableContent = (
        <Fragment>
          {heading}
          <EditablePurposeBox
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onShowVideoManager={onShowVideoManager}
            video={video}
            contactInfo={contactInfo}
            aboutTextSource={aboutTextSource}
            aboutTextPlaceholder={`[${intl.formatMessage(placeHolders.aboutText)}]`}
            disableContactEmail={disableContactEmail}
          />
        </Fragment>
      )
    } else {
      // wrap regular
      editableContent = (
        <EditableSpan.View onStartEdit={handleStartEdit} showEditIcon={false}>
          {heading}
          {displayed}
        </EditableSpan.View>
      )
    }

    // wrap content
    content = <EditableSpan fluid>{editableContent}</EditableSpan>
  } else {
    content = (
      <Fragment>
        {heading}
        {displayed}
      </Fragment>
    )
  }

  return (
    <Wrapper>
      <VideoBlock video={video} />
      {content}
    </Wrapper>
  )
}

PurposeWidget.propTypes = {
  aboutTextSource: PropTypes.string,
  video: PropTypes.shape({
    url: PropTypes.string,
    transcodeStatus: PropTypes.string
  }),
  contactInfo: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    senderName: PropTypes.string,
    senderTitle: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  onSave: PropTypes.func,
  onShowVideoManager: PropTypes.func,
  disableContactEmail: PropTypes.bool,
}

PurposeWidget.defaultProps = {
  isEditable: false,
  onSave: () => { },
  disableContactEmail: false,
}

export default injectIntl(PurposeWidget)
