import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Disclaimer from '../../../../../components/owner/Disclaimer/Disclaimer'
import { TitleBar } from '../../../../../components/owner/AnalyticsLayout/AnalyticsLayout'
import AnalyticsInteraction from '../../../../../components/owner/AnalyticsInteraction/AnalyticsInteraction'
import { getGroupingByQvestId } from '../../../../../actions/groupingActions'
import { getGroupGraph } from '../../../../../actions/statsActions'
import { filterGroupingsByQvest } from '../../../../../reducers/grouping'

const ComponentRoot = styled.div`
  & > * { max-width: 1400px; }
  & > :last-child {
    margin: 50px 30px 30px 30px;
    max-width: 989px
  }
`

class OwnerInteractionPage extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getGroupGraph(match.params.qvestId))
    dispatch(getGroupingByQvestId(match.params.qvestId))
  }

  handleBackClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics/home`)
  }

  render() {
    const { groupGraphStore, groupingStore, match } = this.props

    const qvestId = match.params.qvestId
    const grouping = filterGroupingsByQvest(groupingStore, match.params.qvestId)

    // TODO: nicer loader than nothing
    if (!groupGraphStore.data.hasOwnProperty(qvestId) || !grouping) {
      return null
    }

    const groupGraphData = groupGraphStore.data[qvestId]

    const title = <FormattedMessage
      id="owner.AnalyticsInteraction.title"
      defaultMessage="Interaction"
    />
    const description = <FormattedMessage
      id="owner.AnalyticsInteraction.description"
      defaultMessage="Connections among Qvest stakeholders"
    />

    return (
      <ComponentRoot>
        <TitleBar
          title={title}
          description={description}
          onBack={this.handleBackClick}
        />
        <AnalyticsInteraction
          groups={grouping.groups}
          nodes={groupGraphData.nodes}
          edges={groupGraphData.edges}
        />
        <Disclaimer />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    groupGraphStore: state.stats.get('groupGraph').toJS(),
    groupingStore: state.grouping.toJS(),
  }
}

export default connect(mapStateToProps)(OwnerInteractionPage)
