import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { TransitionGroup, Transition } from 'react-transition-group'
import { isEnter } from '../../../utils/animationUtils'
import { determineTransition, makeQuestionUrl } from '../../../utils/questionUtils'
import { hasClosed } from '../../../utils/qvestUtils'
import { getOwnQuestions, markAsRead } from '../../../actions/questionActions'
import { filterQvestsById } from '../../../reducers/qvests'
import { filterOwnQuestions, filterQuestionsById } from '../../../reducers/questions'
import QuestionBackground from '../../../components/qvest/QuestionBackground/QuestionBackground'
import QuestionPane from './question/QuestionPane'
import QuestionView from './question/QuestionView'
import DimmerLoader from '../../../components/common/DimmerLoader/DimmerLoader'


const ComponentRoot = styled.div`
  opacity: ${props => isEnter(props) ?  '1' : '0'};
  transition: opacity 300ms ease-in;
`

function isUnread(question) {
  return (question.status === 'ASKED' && !question.questionReadAt) ||
         (question.status === 'ANSWERED' && !question.answerReadAt)
}


class QuestionPage extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props

    dispatch(getOwnQuestions(match.params.qvestId))
      .then(() => this.handleRead())
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleRead()
    }
  }

  render() {
    const { match, location, questionStore, qvestStore, animation } = this.props
    const { qvestId, questionId } = match.params
    const qvest = filterQvestsById(qvestStore, qvestId)
    const questions = filterOwnQuestions(questionStore, qvestId)
    const currentQuestion = filterQuestionsById(questionStore, questionId)

    if (questions.length === 0) {
      return (<DimmerLoader />)
    }

    if (qvest.isJam && hasClosed(qvest)) {
      return <Redirect to={`/qvest/${qvestId}/finale`} />
    }

    const nextQuestion = determineTransition(currentQuestion, questions, qvest.isJam)

    const rootPath = '/qvest/:qvestId/question/:questionId'

    return (
      <ComponentRoot animation={animation}>
        <Route
          path={`${rootPath}/(ask|answer)`}
          render={props => (
            <QuestionBackground currentQuestion={currentQuestion} nextQuestion={nextQuestion} isJam={qvest.isJam} {...props} />
          )}
        />
        <TransitionGroup appear={true} enter={true} exit={true}>
          <Transition key={location.key || 'start'} timeout={2000}>
            {state =>
              <Switch location={location}>
                <Route
                  path={`${rootPath}/ask`}
                  render={props => <QuestionPane realKey={`${rootPath}/ask`} left {...props} animation={state} />}
                />
                <Route
                  path={`${rootPath}/answer`}
                  render={props => <QuestionPane realKey={`${rootPath}/answer`} right {...props} animation={state} />}
                />
                <Route
                  path={`${rootPath}/view`}
                  render={props => <QuestionView realKey={`${rootPath}/view`} {...props} animation={state} />}
                />
                <Redirect to={makeQuestionUrl(qvestId, currentQuestion)} />
              </Switch>
            }
          </Transition>
        </TransitionGroup>
      </ComponentRoot>
    )
  }

  handleRead = () => {
    const { match, dispatch, questionStore } = this.props
    const { qvestId, questionId } = match.params
    const question = filterQuestionsById(questionStore, questionId)
    if (isUnread(question)) {
      dispatch(markAsRead(questionId))
        .then(() => dispatch(getOwnQuestions(qvestId)))
    }
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    participantStore: state.participants.toJS(),
    questionStore: state.questions.toJS()
  }
}

export default connect(mapStateToProps)(QuestionPage)
