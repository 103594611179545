import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { hexaToRgba } from '../../../utils/styleUtils'

import Icon from '../Icon/Icon'
import Button from '../Button/Button'


const EditIconWrapper = styled.div`
  position: absolute;
  top: -6px;
  right: -25px;
  cursor: pointer;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

const HoverArea = styled.div`
  position:absolute;
  top: -6px;
  right: -20px;
  width: 20px;
  height: calc(100% + 6px);
`

const HoverBackground = styled.div`
  position:absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 40px);
  height: calc(100% + 20px);
  background-color: ${({ theme }) => hexaToRgba(theme.main.primary + '19')};
`

const EditRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ fullWidth }) => fullWidth ? 'wrap' : 'nowrap'};
  align-items: center;
  padding: 5px 0;
  width: 100%;

  & > *:not(:first-child) {
    margin-top: ${({ fullWidth }) => fullWidth ? '8px' : '0'}
  }

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

const EditChildren = styled.div`
  display: inline;
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
`

const ViewWrapper = styled.div`

`

const ComponentRoot = styled.div`
  position: relative;
  cursor: pointer;
  width: ${({ fluid }) => fluid ? '100%' : 'auto'};

  & ${EditIconWrapper} {
    display: none;
  }

  & ${HoverBackground} {
    display: none;
  }

  & ${ViewWrapper} {
    display: ${({ showEdit }) => !showEdit ? 'block' : 'none'};
  }

  &:hover ${EditIconWrapper} {
    display: ${({ showEdit }) => showEdit ? 'none' : 'block'};
  }

  &:hover ${HoverBackground} {
    display: ${({ showEdit }) => showEdit ? 'none' : 'block'};
  }
`

const VerticalSpace = styled.div`
  height: 100%;
  width: 35px;
`

const IconButton = styled(Button)`
  && > * {
    cursor: pointer;
  }
`


class View extends Component {
  constructor(props) {
    super(props)

    this.onRemoveClick = this.onRemoveClick.bind(this)
    this.onCollapseClick = this.onCollapseClick.bind(this)
  }

  render() {
    const { onRemove, onCollapse } = this.props

    let deleteIcon = null
    if (onRemove) {
      deleteIcon = (
        <Icon
          clickable={true}
          variant="trash"
          title="Remove"
          onClick={this.onRemoveClick}
        />
      )
    }

    let collapseIcon = null
    if (onCollapse) {
      collapseIcon = (
        <Icon
          clickable={true}
          variant="chevron-up"
          title="Collapse"
          onClick={this.onCollapseClick}
        />
      )
    }

    return (
      <ViewWrapper>
        <HoverBackground />
        <HoverArea />
        {this.props.children}
        <EditIconWrapper>
          {collapseIcon}
          <Icon clickable={true} variant="edit" title="Edit" />
          {deleteIcon}
        </EditIconWrapper>
      </ViewWrapper>
    )
  }

  onRemoveClick(event) {
    event.stopPropagation()
    const { onRemove } = this.props
    if (onRemove) {
      onRemove()
    }
  }

  onCollapseClick(event) {
    event.stopPropagation()
    const { onCollapse } = this.props
    if (onCollapse) {
      onCollapse()
    }
  }
}

class Edit extends Component {
  constructor(props) {
    super(props)

    this.onDoneClick = this.onDoneClick.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onCancelClick = this.onCancelClick.bind(this)
  }

  render() {
    const { children, fullWidth, isEditing, onCancel, disabled } = this.props

    if (isEditing) {
      return (
        <EditRow onKeyDown={this.onKeyDown} fullWidth={fullWidth}>
          <EditChildren fullWidth={fullWidth}>
            {children}
          </EditChildren>
          <IconButton size="small" title="Done" onClick={this.onDoneClick} disabled={disabled}>
            <VerticalSpace />
            <Icon tertiary variant="check" />
            <VerticalSpace />
          </IconButton>
          {onCancel ? <Icon clickable={true} variant="times" onClick={this.onCancelClick} /> : null}
        </EditRow>
      )
    } else {
      return null
    }
  }

  onDoneClick() {
    const { onDone } = this.props
    if (onDone) {
      onDone()
    }
  }

  onCancelClick() {
    const { onCancel } = this.props
    if (onCancel) {
      onCancel()
    }
  }

  onKeyDown({ key }) {
    const { hasDoneKey, onCancel } = this.props
    if (hasDoneKey && key === 'Enter') {
      this.onDoneClick()
    } else if (key === 'Escape') {
      if (onCancel) {
        this.onCancelClick()
      } else {
        this.onDoneClick()
      }
    }
  }
}

class EditableSpan extends Component {
  constructor(props) {
    super(props)

    this.onClickInside = this.onClickInside.bind(this)
    this.onClickOutside = this.onClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.onClickOutside)
    this.rootElement.addEventListener('mouseup', this.onClickInside)
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onClickOutside)
    this.rootElement.removeEventListener('mouseup', this.onClickInside)
  }

  render() {
    const { isEditing, hasDoneKey, onCancel, onDone, onRemove, onCollapse, fluid } = this.props

    const children = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        isEditing,
        hasDoneKey,
        onDone,
        onCancel,
        onRemove,
        onCollapse
      })
    )

    return (
      <ComponentRoot
        ref={elm => this.rootElement = elm}
        showEdit={isEditing}
        fluid={fluid}
      >
        {children}
      </ComponentRoot>
    )
  }

  onClickInside(event) {
    event.stopPropagation()
    event.preventDefault()
    const { onStartEdit, isEditing } = this.props
    if (onStartEdit && !isEditing) {
      onStartEdit()
    }
  }

  onClickOutside() {
    const { isEditing, onCancel, onDone } = this.props
    if (isEditing) {
      if (onCancel) {
        onCancel()
      } else if (onDone) {
        onDone()
      }
    }
  }
}

EditableSpan.View = View
EditableSpan.Edit = Edit

EditableSpan.propTypes = {
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
  hasDoneKey: PropTypes.bool,
  onStartEdit: PropTypes.func,
  onDone: PropTypes.func,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func
}

EditableSpan.defaultProps = {
  fluid: false,
  disabled: false,
  isEditing: false,
  hasDoneKey: true,
}

export default EditableSpan
