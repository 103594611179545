import Immutable from 'immutable'

let INITIAL_STATE = Immutable.fromJS({
  isLoading: null,
  wasSent: false,
  data: {},
  myUserId: null,
  error: null
})


const user = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'GET_USER_REQUEST':
    case 'INVITE_USER_REQUEST':
    case 'CREATE_USER_REQUEST':
    case 'UPDATE_USER_REQUEST':
      return state.merge({
        isLoading: true,
        error: null
      })
    case 'GET_USER_SUCCESS':
    case 'UPDATE_USER_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(action.user.userId, action.user),
        myUserId: action.user.userId
      })
    case 'INVITE_USER_SUCCESS':
    case 'CREATE_USER_SUCCESS':
      return state.merge({
        isLoading: false,
        wasSent: true
      })
    case 'GET_USER_FAILURE':
    case 'INVITE_USER_FAILURE':
    case 'CREATE_USER_FAILURE':
    case 'UPDATE_USER_FAILURE':
      return state.merge({
        isLoading: false,
        error: action.error
      })
    default:
      return state
  }
}

export function filterMyUser(user) {
  if (user.myUserId && user.data.hasOwnProperty(user.myUserId)) {
    return user.data[user.myUserId]
  }
}


export default user
