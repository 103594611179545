import styled from 'styled-components'
import PropTypes from 'prop-types'

const Divider = styled.div`
  width: 100%;
  height: ${props => props.height}px;
`

Divider.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

Divider.defaultProps = {
  height: 100,
}

export default Divider
