import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ContainerDimensions from 'react-container-dimensions'
import { withTheme } from 'styled-components'
import { hexaToRgba } from '../../../utils/styleUtils'

const PADDING_LEFT = 100
const PADDING_RIGHT = 100
const CIRCLE_DISTANCE = 10
const COS45 = Math.cos(Math.PI / 4)
const TEXT_DISTANCE = 10
const SMALL_LINE_HEIGHT = 14
const LARGE_LINE_HEIGHT = 48

class DistributionChart extends Component {
  renderChart(width) {
    const { data: [first, last], inverted, theme } = this.props

    // define the diagram width without labels and numbers
    const innerWidth = width - PADDING_LEFT - PADDING_RIGHT

    // magic - takes two equations, and calculates r1, r2
    // - two circle areas are proportional to the values
    // - the width of the inner diagram is composed of r1, r2 and the horizontal distance of the centerpoints
    let areaRatio
    if (first.value && !last.value) {
      // second value missing, default to 80/20 ratio
      areaRatio = 0.2
    } else {
      // Otherwise do proper ratio
      areaRatio = Math.sqrt(last.value / first.value)
    }
    let r1 = (innerWidth - COS45 * CIRCLE_DISTANCE) / (1 + areaRatio + COS45 + COS45 * areaRatio)
    let r2 = areaRatio * r1
    let height = r1 + r2 + COS45 * (r1 + r2 + CIRCLE_DISTANCE)

    // in case the first circle is larger than the calculated inner width
    if (innerWidth < 2 * r1) {
      r1 = innerWidth / 2
      r2 = areaRatio * r1
      height = 2 * r1
      // in case the second circle is larger than the calculated inner width
    } else if (innerWidth < 2 * r2) {
      r2 = innerWidth / 2
      r1 = r2 / areaRatio
      height = 2 * r2
      // no data - render empty circles
    } else if (!r1 || r1 <= 0) {
      first.value = 0
      last.value = 0
      r1 = innerWidth / 3.5
      r2 = r1
      height = innerWidth
    }

    // Style for missing data in one or both circles
    let circleProps1 = {}, circleProps2 = {}
    const emptyStyle = {
      fill: 'rgba(0, 0, 0, .05)',
      stroke: '#3d4640',
      strokeWidth: 1,
      strokeDasharray: '5,5',
    }
    if (!first.value || first.value <= 0) {
      circleProps1 = { ...emptyStyle }
      if (first.fill) {
        circleProps1.fill = hexaToRgba(first.fill + '1a') // 10% opacity
        circleProps1.stroke = first.fill
      }
    }
    if (!last.value || last.value <= 0) {
      circleProps2 = { ...emptyStyle }
      if (last.fill) {
        circleProps2.fill = hexaToRgba(last.fill + '1a') // 10% opacity
        circleProps2.stroke = last.fill
      }
    }

    // compose svg
    return (
      <svg width={width} height={height}>
        {/* draw the two circles */}
        <g transform={`translate(${PADDING_LEFT}, 0)`}>
          <circle cx={r1} cy={r1} r={r1} fill={first.fill} {...circleProps1} />
          <circle cx={innerWidth - r2} cy={height - r2} r={r2} fill={last.fill} {...circleProps2} />
        </g>
        {/* draw first text */}
        <g
          transform={`translate(${PADDING_LEFT - TEXT_DISTANCE}, 0)`}
          fill={inverted ? theme.inverted.one[0] : theme.one[0]}
        >
          {this.renderText(first.value, first.labels)}
        </g>
        {/* draw second text */}
        <g
          transform={`translate(${PADDING_LEFT + innerWidth + TEXT_DISTANCE}, ${height})`}
          fill={inverted ? theme.inverted.one[0] : theme.one[0]}
        >
          {this.renderText(last.value, last.labels, true)}
        </g>
      </svg>
    )
  }

  renderText(value, labels, isBottom = false) {
    const baseline = isBottom ? 'baseline' : 'hanging'
    const anchor = isBottom ? 'start' : 'end'

    // this workaround is necessary for multiline text in svgs
    const labelList = labels.split(' ')
    const renderedLabels = labelList.map((label, i) => {
      const dy = isBottom
        ? (labelList.length - 1 - i) * -SMALL_LINE_HEIGHT
        : LARGE_LINE_HEIGHT + i * SMALL_LINE_HEIGHT
      return (
        <text
          key={i}
          dy={dy}
          textAnchor={anchor}
          alignmentBaseline={baseline}
          fontFamily="Raleway, sans-serif"
          fontSize="12px"
          fontWeight="500"
        >
          {label}
        </text>
      )
    })

    return (
      <Fragment>
        <text
          key="-1"
          dy={isBottom ? ((labelList.length + .5) * -SMALL_LINE_HEIGHT) : 0}
          textAnchor={anchor}
          alignmentBaseline={baseline}
          fontFamily="LL Circular Book Web, sans-serif"
          fontSize="45px"
          fontWeight="500"
        >
          {value}
        </text>
        {renderedLabels}
      </Fragment>
    )
  }

  render() {
    if (!this.props.data) return null
    return (
      <div>
        <ContainerDimensions>
          {({ width }) => this.renderChart(width)}
        </ContainerDimensions>
      </div>
    )
  }

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number,
      labels: PropTypes.string,
      fill: PropTypes.string,
    })).isRequired,
    inverted: PropTypes.bool,
  }

  static defaultProps = {
    inverted: false,
  }
}

export default withTheme(DistributionChart)
