import 'whatwg-fetch'

const SUPPORTED_LOGO_TYPES = ['image/png', 'image/jpg', 'image/jpeg']

const isSupportedLogo = (file) => (file.type != null) && (SUPPORTED_LOGO_TYPES.indexOf(file.type) !== -1)

export function validateLogoFile(file) {
  if (!isSupportedLogo(file)) {
    return new Error('UNSUPPORTED_FILE_TYPE')
  }
  if (file.size > 1048576) {
    return new Error('FILE_UPLOAD_TOO_LARGE')
  }
}

// Upload file using signed policy document
export function uploadFile(uploadInfo){
  const data = new FormData
  // Add meta fields to form data
  const fields = JSON.parse(uploadInfo.fields)
  for (const [key, value] of Object.entries(fields)) {
    if (key !== 'fileName') data.append(key, value)
  }
  // Add file content to form data
  const fileName = (fields.fileName || uploadInfo.file.name)
  data.append('file', uploadInfo.file, fileName)
  // Make POST request
  return fetch(uploadInfo.url, { method: 'POST', body: data })
    .then(res => {
      if (res.status !== 204 && res.status !== 201) {
        return res.text().then(err => { throw err })
      } else {
        return uploadInfo.url + (fields.fileName || '')
      }
    })
}


// Polyfill for IE11 support
// (https://stackoverflow.com/a/35170914/904065)
export function readAsBinaryString(reader, file) {
  if (FileReader.prototype.readAsBinaryString === undefined) {
    FileReader.prototype.readAsBinaryString = function (fileData) {
      var binary = ''
      var pt = this
      var reader = new FileReader()
      reader.onload = function () {
        var bytes = new Uint8Array(reader.result)
        var length = bytes.byteLength
        for (var i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        //pt.result  - readonly so assign content to another property
        pt.content = binary
        pt.onload()
      }
      reader.readAsArrayBuffer(fileData)
    }
  }
  return reader.readAsBinaryString(file)
}
