import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Table from '../../common/Table/Table'


const PerParticipantParticipationTable = ({ isLoading, participation }) => {
  const hasData = (participation && participation.length)
  return (
    <Table isLoading={isLoading}>
      <thead>
        <tr>
          <th><FormattedMessage id="owner.PerParticipantParticipation.name" defaultMessage="Group" /></th>
          <th><FormattedMessage id="owner.PerParticipantParticipation.participantCount" defaultMessage="Participants" /></th>
          <th><FormattedMessage id="owner.PerParticipantParticipation.questions" defaultMessage="Questions asked" /></th>
          <th><FormattedMessage id="owner.PerParticipantParticipation.questionsPerParticipant" defaultMessage="Question per participant" /></th>
          <th><FormattedMessage id="owner.PerParticipantParticipation.answers" defaultMessage="Answers given" /></th>
          <th><FormattedMessage id="owner.PerParticipantParticipation.answersPerParticipant" defaultMessage="Answers per participant" /></th>
        </tr>
      </thead>
      <tbody>
        {hasData && participation.map(group => (
          <tr key={group.groupId}>
            <td>{(group.groupId !== '-1') ? group.name : ''}</td>
            <td>{group.participantCount}</td>
            <td>{group.questionSentCount}</td>
            <td>{group.questionsPerParticipant}</td>
            <td>{group.answerSentCount}</td>
            <td>{group.answersPerParticipant}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

PerParticipantParticipationTable.propTypes = {
  isLoading: PropTypes.bool,
  participation: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string,
    name: PropTypes.string,
    participantCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    questionSentCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    questionsPerParticipant: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    answerSentCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    answersPerParticipant: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }))
}

PerParticipantParticipationTable.defaultProps = {
  isLoading: false,
  participation: [],
}

export default PerParticipantParticipationTable
