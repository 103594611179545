import React, { Fragment, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import styled, { withTheme } from 'styled-components'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { FormattedMessage, FormattedRelative, IntlProvider } from 'react-intl'
import { Helmet } from 'react-helmet'

import { loadLocale } from '../../utils/i18nUtils'
import { makeAvatarColorScale } from '../../utils/d3Utils'
import RecipientList from '../../components/qvest/RecipientList/RecipientList'
import Heading from '../../components/common/Typography/Heading'
import Typography from '../../components/common/Typography/Typography'
import Button from '../../components/common/Button/Button'
import Card from '../../components/common/Card/Card'
import JoinInfoCard from '../../components/qvest/JoinInfoCard/JoinInfoCard'
import { useToggleScheduleEnabled, useUpdateSchedule } from '../../operations/schedule'
import HighlightsSection from '../participant/results/sections/HighlightsSection'
import { questionDumpToExcelSheet } from '../../utils/xlsxUtils'
import { makeFilename } from '../../utils/stringUtils'
import { saveAs } from 'file-saver'


const PAGE_TITLE = 'Question Jam'
const JAM_LENGTH = 420000 // ms (7 min)

const PlayerCard = styled(Card)`
  padding: 25px 25px 40px 25px;

  & h2 {
    margin-bottom: 10px;
  }
`

const FloatingActions = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

const JAM_SECTION_QUERY = gql`
  query q($qvestId:String!) {
    qvest(id: $qvestId) {
      qvestId
      name
      language
      topic
      state
      accessCode
      schedule {
        inviteAt,
        openAt,
        closeAt
      }
      summary {
        participants
        questions
        answers
      }
    }
  }
`

const JAM_PARTICIPANTS_QUERY = gql`
  query q($qvestId:String!) {
    participants(qvestId: $qvestId) {
      items {
        participantId,
        name
      }
    }
  }
`

const JAM_RESULTS_QUERY = gql`
  query q($qvestId:String!) {
    wordFrequency(qvestId: $qvestId) {
      items {
        topWord,
        stem,
        count,
        isPotentialOutlier,
        isOutlier,
        words {
          text,
          count
        },
        groups {
          groupId,
          count
        }
      }
    }
  }
`

const JAM_DUMP_QUERY = gql`
  query q($qvestId:String!) {
    questionDump(qvestId: $qvestId) {
      answerContent
      answerGroupName
      answerParticipantName
      answerSentAt
      questionContent
      questionGroupName
      questionId
      questionParticipantName
      questionSentAt
      threadId
      metadata
    }
  }
`

// Sets up a timer to update boolean "hasClosed" variable, when a schedule becomes available
let checkClosedInterval
const useTimedClosedIndicator = (data) => {
  const [hasClosed, setHasClosed] = useState(null)
  useEffect(() => {
    if (data && data.qvest.schedule && data.qvest.schedule.closeAt) {
      const closeAt = new Date(data.qvest.schedule.closeAt)
      if (closeAt > Date.now()) {
        // Not closed yet... Setting up timer
        setHasClosed(false)
        checkClosedInterval = setInterval(() => {
          if (closeAt < Date.now()) {
            // Closed just now, clearing timer and updating boolean
            clearInterval(checkClosedInterval)
            setHasClosed(true)
          }
        }, 1000)
      } else {
        // Already closed
        setHasClosed(true)
      }
    }
  }, [data])
  return hasClosed
}

const OutputActions = withRouter(({ match, qvest }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [getQuestionDump] = useLazyQuery(JAM_DUMP_QUERY)

  const handleDownload = async () => {
    const qvestId = match.params.qvestId
    setIsLoading(true)
    const { data } = await getQuestionDump({ variables: { qvestId } })
    const questionDump = data.questionDump
    const sheet = questionDumpToExcelSheet(questionDump)
    const sheetBlob = new Blob([sheet], { type: 'application/octet-stream' })
    const fileName = makeFilename(qvest.name, 'Questions and Answers', 'xlsx')
    saveAs(sheetBlob, fileName)
    setIsLoading(false)
  }

  let text
  if (isLoading) {
    text = (<FormattedMessage defaultMessage="Preparing..." id="qvest.JamLandingPage.actions.downloading" />)
  } else {
    text = (<FormattedMessage defaultMessage="Download data" id="qvest.JamLandingPage.actions.download" />)
  }

  return (
    <FloatingActions>
      <Button disabled={isLoading} outline onClick={handleDownload}>{text}</Button>
    </FloatingActions>
  )
})

const PlayerSection = withTheme(withRouter(({ match, theme }) => {
  const qvestId = match.params.qvestId
  const { data } = useQuery(JAM_PARTICIPANTS_QUERY, { variables: { qvestId }, pollInterval: 3000 })

  if (!data || data.participants.items.length === 0) return null

  const participants = data.participants.items // TODO: Sort by by newly joined first and limit length?
  const avatarColors = makeAvatarColorScale(theme, participants)

  return (
    <PlayerCard>
      <Heading.h2 variant="heading2">
        <FormattedMessage defaultMessage="Players" id="qvest.JamLandingPage.players.heading" />
      </Heading.h2>
      <RecipientList showGroups={false} recipients={participants} colors={avatarColors} />
    </PlayerCard>
  )
}))

const StatusMessage = ({ hasStarted, hasClosed, schedule }) => {
  if (!schedule || hasClosed) return null
  if (hasStarted) {
    return (
      <FormattedMessage
        id="qvest.JamLandingPage.StatusMessage.open"
        defaultMessage="Our jam session is on! Players, please follow the instructions on your individual screens. Jam will end {relativeTimeOpen}."
        values={{ relativeTimeOpen: <FormattedRelative updateInterval={1000} value={schedule.closeAt} /> }}
      />
    )
  } else {
    return (
      <FormattedMessage
        id="qvest.JamLandingPage.StatusMessage.onboarding"
        defaultMessage="Our jam starts {relativeTimeOpen} ..."
        values={{ relativeTimeOpen: <FormattedRelative value={schedule.openAt} /> }}
      />
    )
  }
}

const OutputSection = ({ qvest }) => {
  const { qvestId } = qvest
  const { data } = useQuery(JAM_RESULTS_QUERY, { variables: { qvestId } })
  if (!data) return null
  return (
    <Card>
      <Card.Body wide>
        <HighlightsSection
          qvestId={qvestId}
          wordFrequencies={data.wordFrequency.items}
          summary={qvest.summary}
        />
        <OutputActions qvest={qvest} />
      </Card.Body>
    </Card>
  )
}

const Actions = ({ hasStarted, onStart }) => {
  const [isLoading, setIsLoading] = useState(false)
  if (hasStarted) return null

  const handleClick = async () => {
    setIsLoading(true)
    try {
      await onStart()
    } finally {
      setIsLoading(false)
    }
  }

  let text
  if (!isLoading) {
    text = <FormattedMessage defaultMessage="Start Jam Now!" id="qvest.JamLandingPage.actions.start" />
  } else {
    text = <FormattedMessage defaultMessage="Starting..." id="qvest.JamLandingPage.actions.loading" />
  }

  return (
    <div>
      <Button disabled={isLoading} size="large" onClick={handleClick}>{text}</Button>
    </div>
  )
}

const JamLandingPage = ({ match }) => {
  const qvestId = match.params.qvestId

  const { data } = useQuery(JAM_SECTION_QUERY, { variables: { qvestId } })
  const updateSchedule = useUpdateSchedule()
  const toggleScheduleEnabled = useToggleScheduleEnabled()
  const hasClosed = useTimedClosedIndicator(data)

  if (!data) {
    return <div>Loading</div>
  }

  const { qvest } = data
  const locale = qvest.language || 'en'
  const hasStarted = (qvest.schedule && qvest.schedule.openAt && new Date(qvest.schedule.openAt) < Date.now())

  const handleProceed = async () => {
    const openAt = new Date()
    const closeAt = (new Date(new Date().getTime() + JAM_LENGTH))
    await updateSchedule(qvest, { openAt, closeAt })
    await toggleScheduleEnabled(qvest, true)
  }

  const title = `${qvest.topic} | ${PAGE_TITLE}`

  return (
    <IntlProvider locale={locale} messages={loadLocale(locale)}>
      <Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div>
          <Heading primary variant="heading3">
            <FormattedMessage defaultMessage="Jam Topic" id="qvest.JamLandingPage.subHeading" />
          </Heading>
          <Heading.h1 variant="display1">{qvest.topic}</Heading.h1>
        </div>
        <div>
          <Typography variant="large">
            <StatusMessage hasStarted={hasStarted} hasClosed={hasClosed} schedule={qvest.schedule} />
          </Typography>
        </div>
        {!hasStarted ? <JoinInfoCard code={qvest.accessCode} /> : null}
        {hasClosed ? <OutputSection qvest={qvest} /> : null}
        <PlayerSection />
        <Actions hasStarted={hasStarted} onStart={handleProceed} />
      </Fragment>
    </IntlProvider>
  )
}

export default withRouter(withTheme(JamLandingPage))
