import React from 'react'
import GenericErrorDisplay from '../../components/common/GenericErrorDisplay/GenericErrorDisplay'

const GenericErrorPage = () => {
  return (
    <GenericErrorDisplay alwaysHasError={true} />
  )
}

export default GenericErrorPage
