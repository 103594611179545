import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import omit from 'lodash/omit'

import { makeGroupColorScales } from '../../../utils/d3Utils'
import Checkbox from '../../common/Checkbox/Checkbox'
import Typography from '../../common/Typography/Typography'
import Table from '../../common/Table/Table'
import GroupNode from '../../common/GroupNode/GroupNode'
import Icon from '../../common/Icon/Icon'
import Modal from '../../common/Modal/Modal'
import UpdateParticipantGroupModal from '../UpdateParticipantGroupModal/UpdateParticipantGroupModal'
import { countParticipantsInGroups } from '../../../utils/groupingUtils'

const PARTICIPANT_PER_GROUP_MIN = 3 // TODO: Move to some centralized constant object

const determineStatusColor = ({ error, loading, theme }) => {
  if (error) {
    return theme.alert.error
  } else if (loading) {
    return theme.one[2]
  }
  return theme.one[0]
}

const HeaderGroupCell = styled.th`
  & > :first-child {
    margin: 6px 6px 0 0;
  }
  & > :nth-child(2) {
    position: relative;
    top: -4px;
    left: 0;
  }
`

const HeaderRow = styled.tr`
  white-space: nowrap;
  & > *:first-child { width: 100%; }
`

const SpacedCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > *:first-child { margin-right: 20px; }
`

const StatusColoredSpan = styled.span`
  color: ${props => determineStatusColor(props)};
`

const RetryButton = styled.button`
  background:none;
  border:none;
  padding:0!important;
  font: inherit;
  border-bottom:1px solid #444;
  cursor: pointer;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  height: 37px;
  width: 100%;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  & > input {
    position: absolute;
    visibility: hidden;
  }
  & > :not(:last-child) {
    margin-right: 3px;
  }
`

const messages = defineMessages({
  saveFailed: { defaultMessage: 'Failed to save changes', id: 'owner.GroupingParticipantTable.tooltip.saveFailed' },
  retry: { defaultMessage: 'Retry', id: 'owner.GroupingParticipantTable.button.retry' }
})

function isGrouplessParticipant(participant) {
  return !participant.groupingConnections || (participant.groupingConnections.length === 0)
}

function validateConnectionUpdate(groups, participants, fromGroup) {
  const groupCounts = countParticipantsInGroups(groups, participants)
  const fromGroupCount = groupCounts[fromGroup.groupId]
  if (fromGroupCount < 2) {
    return { type: 'ERROR', name: 'EMPTY_GROUP' }
  } else if (fromGroupCount <= PARTICIPANT_PER_GROUP_MIN) {
    return { type: 'WARNING', name: 'TOO_FEW_PARTICIPANTS_PER_GROUP' }
  } else {
    return { type: 'OK' }
  }
}

const RadioCell = ({ groupId, participantId, checked, primaryColor, secondaryColor, disabled, onConnectionUpdate }) => (
  <td key={groupId}>
    <RadioLabel disabled={disabled}>
      <input
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={() => onConnectionUpdate(participantId, groupId)}
      />
      <GroupNode
        nid={groupId}
        size="small"
        minValue={checked ? 1 : 0}
        maxValue={7}
        value={checked ? 1 : 0}
        disabled={disabled}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
    </RadioLabel>
  </td>
)

const NameCell = injectIntl(({ participantId, selectedGroupId, name, error, loading, intl, onConnectionUpdate }) => {
  const handleRetry = () => onConnectionUpdate(participantId, selectedGroupId)
  return (
    <td>
      <StatusColoredSpan error={error} loading={loading}>
        {name}
      </StatusColoredSpan>
      {' '}
      <Icon error hidden={!error} variant="exclamation-circle" title={intl.formatMessage(messages.saveFailed)} />
      {' '}
      <RetryButton hidden={!error} onClick={handleRetry}>{intl.formatMessage(messages.retry)}</RetryButton>
    </td>
  )
})

class GroupingParticipantTable extends Component {
  state = {
    showAssigned: true,
    showModal: false,
    unsavedItems: {},
    unsavedData: null,
  }

  initiateConnectionUpdate = (participantId, groupId) => {
    const { qvest, unsavedGrouping, participants } = this.props
    const { unsavedItems } = this.state

    // show optimistic change
    const unsavedItem = {
      groupId,
      loading: false,
      error: null,
    }
    this.setState({
      unsavedItems: { ...unsavedItems, [participantId]: unsavedItem }
    }, () => {
      // show update modal if qvest is scheduled
      if (qvest.state) {
        const { groups, groupingId } = unsavedGrouping
        const participant = participants.find(p => p.participantId === participantId)
        const fromGroupId = participant.groupingConnections
          .find(c => c.groupingId === groupingId).groupId
        const fromGroup = groups.find(g => g.groupId === fromGroupId)
        const toGroup = groups.find(g => g.groupId === groupId)
        const validity = validateConnectionUpdate(groups, participants, fromGroup)
        this.setState({
          showModal: true,
          unsavedData: { participant, fromGroup, toGroup, validity }
        })
        return null
      }

      // else update immediately
      return this.handleConnectionUpdate(participantId, groupId)
    })

  }

  handleConnectionUpdate = (participantId, groupId) => {
    const { onConnectionUpdate } = this.props
    // set loading state
    const item = this.state.unsavedItems[participantId]
    const itemLoading = { ...item, loading: true }
    this.setState({
      unsavedItems: { ...this.state.unsavedItems, [participantId]: itemLoading },
    })
    // update backend
    return onConnectionUpdate(participantId, groupId)
      .then(() => {
        // clear unsaved entry
        this.setState({
          showModal: false,
          unsavedItems: omit(this.state.unsavedItems, participantId),
          unsavedData: null,
        })
      })
      .catch((error) => {
        // set returned error, stop loading
        const item = {
          ...this.state.unsavedItems[participantId],
          loading: false,
          error,
        }
        this.setState({
          showModal: false,
          unsavedItems: { ...this.state.unsavedItems, [participantId]: item },
          unsavedData: null,
        })
      })
  }

  toggleShowAssigned = () => {
    this.setState({
      showAssigned: !this.state.showAssigned
    })
  }

  renderUpdateModal() {
    const { unsavedData, unsavedItems } = this.state
    const { participant, fromGroup, toGroup, validity } = unsavedData
    const participantId = participant.participantId
    let loading = false
    if (unsavedItems[participantId] && unsavedItems[participantId].loading) {
      loading = true
    }
    const handleCancel = this.handleCloseModal(participantId)
    return (
      <Modal onBackgroundClick={handleCancel} onEscape={handleCancel}>
        <UpdateParticipantGroupModal
          loading={loading}
          participant={participant}
          fromGroup={fromGroup}
          toGroup={toGroup}
          validity={validity}
          onClose={handleCancel}
          onSubmit={this.handleConnectionUpdate}
          onCancel={handleCancel}
        />
      </Modal>
    )
  }

  handleCloseModal = (participantId) => () => {
    this.setState({
      showModal: false,
      unsavedItems: omit(this.state.unsavedItems, participantId),
      unsavedData: null,
    })
  }

  renderHeaderGroups(primaryColors, secondaryColors) {
    const { exampleGroupName, groups, counts } = this.props
    return groups.map((group, index) => {
      const count = counts[group.groupId]
      return (
        <HeaderGroupCell key={group.groupId}>
          <GroupNode
            disabled
            nid={index}
            size="small"
            minValue={0}
            maxValue={5}
            value={5}
            primaryColor={primaryColors(group.groupId)}
            secondaryColor={secondaryColors(group.groupId)}
          />
          <span>
            {group.name || `[${exampleGroupName} ${index + 1}]`} ({count})
          </span>
        </HeaderGroupCell>
      )
    })
  }

  renderRows(primaryColors, secondaryColors) {
    const { participants, groups, disabled } = this.props
    const { showAssigned, unsavedItems } = this.state
    const displayedParticipants = showAssigned ? participants : participants.filter(isGrouplessParticipant)
    return displayedParticipants.map(participant => {
      const { participantId, name, groupingConnections } = participant
      const connection = groupingConnections && groupingConnections.find(c => groups.some(g => g.groupId === c.groupId))
      let selectedGroupId = connection && connection.groupId
      let error = null
      let loading = false
      if (unsavedItems[participantId]) {
        error = unsavedItems[participantId].error
        loading = unsavedItems[participantId].loading
        selectedGroupId = unsavedItems[participantId].groupId
      }

      return (
        <tr key={participantId}>
          <NameCell
            participantId={participantId}
            selectedGroupId={selectedGroupId}
            name={name}
            error={error}
            loading={loading}
            onConnectionUpdate={this.initiateConnectionUpdate}
          />
          {groups.map(group =>
            <RadioCell
              key={group.groupId}
              groupId={group.groupId}
              participantId={participantId}
              checked={selectedGroupId === group.groupId}
              primaryColor={primaryColors(group.groupId)}
              secondaryColor={secondaryColors(group.groupId)}
              onConnectionUpdate={this.initiateConnectionUpdate}
              disabled={loading || disabled}
            />
          )}
        </tr>
      )
    })
  }

  render() {
    const { groups, theme } = this.props
    const { showAssigned, showModal } = this.state
    const ids = groups.map(g => g.groupId)
    const { primaryColors, secondaryColors } = makeGroupColorScales(theme, ids)

    return (
      <Fragment>
        {showModal ? this.renderUpdateModal() : null}
        <Table>
          <thead>
            <HeaderRow>
              <th>
                <SpacedCell>
                  <FormattedMessage id="owner.GroupingParticipantTable.name" defaultMessage="Name" />
                  <label>
                    <Checkbox checked={!showAssigned} onChange={this.toggleShowAssigned}>
                      <Typography variant="small">
                        <FormattedMessage
                          id="owner.GroupingParticipantTable.hideAssigned"
                          defaultMessage="Hide already sorted"
                        />
                      </Typography>
                    </Checkbox>
                  </label>
                </SpacedCell>
              </th>
              {this.renderHeaderGroups(primaryColors, secondaryColors)}
            </HeaderRow>
          </thead>
          <tbody>
            {this.renderRows(primaryColors, secondaryColors)}
          </tbody>
        </Table>
      </Fragment>
    )
  }
}

GroupingParticipantTable.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  groups: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  qvest: PropTypes.shape({
    state: PropTypes.string,
  }),
  counts: PropTypes.object,
  exampleGroupName: PropTypes.string,
  onConnectionUpdate: PropTypes.func
}

GroupingParticipantTable.defaultProps = {
  participants: [],
  groups: [],
  exampleGroupName: 'Group',
  onConnectionUpdate: () => { },
}

export default injectIntl(withTheme(GroupingParticipantTable))
