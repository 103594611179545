import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Typography from '../Typography/Typography'
import Anchor from '../Anchor/Anchor'
import Heading from '../Typography/Heading'

import asideImage from '../../../static/img/modal-aside.svg'

const ComponentRoot = styled.div`
  background-color: ${({ theme }) => theme.two[0]};
  border-radius: 3px;
  border-left: 3px solid ${({ theme }) => theme.color[0]};
  width: 800px;
  max-width: 100%;
  position: relative;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.04);
`

const Section = styled.section`
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 40px;
  & > *:first-child { margin-bottom: 30px; }
  @media (min-width: 450px) {
    padding: 80px;
  }
  @media (min-width: 700px) {
    padding-right: 400px;
  }
`

const Aside = styled.aside`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  & img {
    height: 100%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  @media (min-width: 700px) {
    display: flex;
    justify-content: flex-end;
  }
`

const QvestClosedSection = ({ qvestId, onClick }) => {
  const linkToOutput = (
    <Typography cta weight="light" variant="medium">
      <Anchor.Link inverted to={`/results/${qvestId}`} onClick={onClick}>
        <FormattedMessage id="qvest.QvestClosedModal.output" defaultMessage="output page" />
      </Anchor.Link>
    </Typography>
  )

  const linkToActivity = (
    <Typography cta weight="light" variant="medium">
      <Anchor.Link inverted to={`/qvest/${qvestId}/activity`} onClick={onClick}>
        <FormattedMessage id="qvest.QvestClosedModal.activity" defaultMessage="activity" />
      </Anchor.Link>
    </Typography>
  )

  return (
    <Section>
      <Heading.h2>
        <FormattedMessage id="qvest.QvestClosedModal.closed" defaultMessage="Qvest is no longer open" />
      </Heading.h2>
      <Typography.p weight="light" variant="medium">
        <FormattedMessage
          id="qvest.QvestClosedModal.message1"
          defaultMessage="The Qvest you are looking for has closed. You can visit the {linkToOutput}, or review your {linkToActivity}."
          values={{ linkToOutput, linkToActivity }}
        />
      </Typography.p>
      <Typography.p weight="light" variant="medium">
        <FormattedMessage
          id="qvest.QvestClosedModal.message2"
          defaultMessage="If you want to run your own Qvest, please go to qvest.io and create an account."
        />
      </Typography.p>
      <Typography cta weight="light" variant="medium">
        <Anchor inverted href="https://qvest.io" target="_blank" rel="noopener noreferrer">Qvest.io</Anchor>
      </Typography>
    </Section>
  )
}

const QvestExpiredSection = () => (
  <Section>
    <Heading.h2>
      <FormattedMessage id="qvest.QvestClosedModal.expired" defaultMessage="Qvest output page is no longer live" />
    </Heading.h2>
    <Typography.p weight="light" variant="medium">
      <FormattedMessage
        id="qvest.QvestClosedModal.message3"
        defaultMessage="If you need information on this specific Qvest, please contact the person listed at the bottom of the emails you have received during the Qvest."
      />
    </Typography.p>
    <Typography.p weight="light" variant="medium">
      <FormattedMessage
        id="qvest.QvestClosedModal.message4"
        defaultMessage="If you want to run your own Qvest, please go to qvest.io and create an account."
      />
    </Typography.p>
    <Typography cta weight="light" variant="medium">
      <Anchor inverted href="https://qvest.io" target="_blank" rel="noopener noreferrer">Qvest.io</Anchor>
    </Typography>
  </Section>
)

const SubscriptionInactiveSection = () => (
  <Section>
    <Heading.h2>
      <FormattedMessage id="qvest.QvestClosedModal.inactive" defaultMessage="Subscription inactive" />
    </Heading.h2>
    <Typography.p weight="light" variant="medium">
      <FormattedMessage
        id="qvest.QvestClosedModal.inactive.message1"
        defaultMessage="Your organization needs an active subscription for you to access this page."
      />
    </Typography.p>
    <Typography.p weight="light" variant="medium">
      <FormattedMessage
        id="qvest.QvestClosedModal.inactive.message2"
        defaultMessage="Please call us at {phoneNumber} and we'll set it up for you."
        values={{
          phoneNumber: (
            <Typography cta weight="light" variant="medium">
              <Anchor inverted href="tel:+4540878729" target="_blank" rel="noopener noreferrer">+45 40 87 87 29</Anchor>
            </Typography>
          )
        }}
      />
    </Typography.p>
  </Section>
)

const Content = (props) => {
  switch (props.variant) {
    case 'QVEST_CLOSED':
      return (<QvestClosedSection {...props} />)
    case 'QVEST_EXPIRED':
      return (<QvestExpiredSection />)
    case 'SUBSCRIPTION_INACTIVE':
      return (<SubscriptionInactiveSection />)
    default:
      throw Error('Invalid "variant" prop passed to AccessDeniedModal')
  }
}

const AccessDeniedModal = (props) => (
  <ComponentRoot>
    <Content {...props} />
    <Aside>
      <img src={asideImage} alt="Qvest abstract brackground" />
    </Aside>
  </ComponentRoot>
)

AccessDeniedModal.propTypes = {
  variant: PropTypes.oneOf([
    'QVEST_CLOSED',
    'QVEST_EXPIRED',
    'SUBSCRIPTION_INACTIVE'
  ]),
  qvestId: PropTypes.string,
  onClick: PropTypes.func,
}

AccessDeniedModal.defaultProps = {
  onClick: () => { },
}

export default AccessDeniedModal
