import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import styled from 'styled-components'

import Card from '../../common/Card/Card'
import Icon from '../../common/Icon/Icon'
import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import Input from '../../common/Input/Input'
import Button from '../../common/Button/Button'
import { isValidEmailAddress } from '../../../utils/validationUtils'

const ComponentRoot = styled.div`
  width: 550px;
`

const Form = styled.form`
  padding-top: 16px;
  & > :not(:last-child) {
    margin-bottom: 12px;
  }
`

const InputRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & + & { margin-top: 10px; }
  & > :first-child {
    flex: 1 0 120px;
    white-space: nowrap;
    margin-right: 10px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 18px;

  & > :not(:last-child) {
    margin-right: 14px;
  }
`


const title = defineMessages({
  QVEST_ONBOARDING_STARTED: { defaultMessage: 'Send a test invitation email', id: 'owner.TestNotificationModal.title.QVEST_ONBOARDING_STARTED' },
  QVEST_OPENED: { defaultMessage: 'Send a test opening email', id: 'owner.TestNotificationModal.title.QVEST_OPENED' },
  QVEST_CLOSED: { defaultMessage: 'Send a test closing email', id: 'owner.TestNotificationModal.title.QVEST_CLOSED' }
})

const errorMessage = defineMessages({
  INVALID_EMAIL: { defaultMessage: 'Please enter a valid email', id: 'owner.TestNotificationModal.error.INVALID_EMAIL' },
  UNEXPECTED_ERROR: { defaultMessage: 'Something went wrong, try again', id: 'owner.TestNotificationModal.error.UNEXPECTED_ERROR' }
})

const descriptionMessage = defineMessages({
  QVEST_ONBOARDING_STARTED: { defaultMessage: 'invitation email', id: 'owner.TestNotificationModal.description.QVEST_ONBOARDING_STARTED' },
  QVEST_OPENED: { defaultMessage: 'opening email', id: 'owner.TestNotificationModal.description.QVEST_OPENED' },
  QVEST_CLOSED: { defaultMessage: 'closing email', id: 'owner.TestNotificationModal.description.QVEST_CLOSED' }
})


class TestNotificationModal extends Component {
  constructor(props) {
    super(props)

    let validationError
    if (props.email && !isValidEmailAddress(props.email)) {
      validationError = 'INVALID_EMAIL'
    }

    this.state = {
      loading: props.loading,
      sent: props.sent,
      email: props.email,
      name: props.name,
      validationError
    }
  }

  handleSubmit = () => {
    const { name, email } = this.state
    if (email && isValidEmailAddress(email)) {
      const result = this.props.onSubmit(name, email)
      if (result instanceof Promise) {
        this.setState({ loading: true })
        result.then(() => this.setState({ loading: false, sent: true }))
      }
    } else {
      this.setState({ validationError: 'INVALID_EMAIL' })
    }
  }

  handleChange = (change) => {
    this.setState({
      ...change,
      validationError: null
    })
  }

  handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.handleSubmit()
    }
  }

  renderError() {
    const { error } = this.props
    const { validationError } = this.state
    const shownError = validationError || error
    if (!shownError) return null
    return (
      <div>
        <Icon variant="exclamation-circle" error />
        {' '}
        <Typography variant="small" weight="light">
          {this.props.intl.formatMessage(errorMessage[shownError])}
        </Typography>
      </div>
    )
  }

  renderSubmitText() {
    const { loading, sent } = this.state
    if (sent) {
      return (<FormattedMessage defaultMessage="Sent!" id="owner.TestNotificationModal.button.submitted" />)
    } else if (loading) {
      return (<FormattedMessage defaultMessage="Sending..." id="owner.TestNotificationModal.button.submitting" />)
    } else {
      return (<FormattedMessage defaultMessage="Send" id="owner.TestNotificationModal.button.submit" />)
    }
  }

  renderBody() {
    const { variant, intl } = this.props
    const { name, email, loading, sent } = this.state
    const mailVariant = (
      <strong>{intl.formatMessage(descriptionMessage[variant])}</strong>
    )
    return (
      <Card.Body wide>
        <Typography variant="medium" weight="light">
          <FormattedMessage
            id="owner.TestNotificationModal.description"
            defaultMessage="Credentials of recipient to receive a test {mailVariant}."
            values={{ mailVariant }}
          />
        </Typography>
        <Form>
          <InputRow>
            <Heading variant="heading4">
              <FormattedMessage defaultMessage="Name:" id="owner.TestNotificationModal.label.name" />
            </Heading>
            <Input
              disabled={loading || sent}
              type="text"
              placeholder="John Doe"
              value={name}
              onChange={({ target }) => this.handleChange({ name: target.value })}
              onKeyPress={this.handleKeyPress}
            />
          </InputRow>
          <InputRow>
            <Heading variant="heading4">
              <FormattedMessage defaultMessage="Email address:" id="owner.TestNotificationModal.label.email" />
            </Heading>
            <Input
              disabled={loading || sent}
              type="text"
              placeholder="jonny@quest.now"
              value={email}
              onChange={({ target }) => this.handleChange({ email: target.value })}
              onKeyPress={this.handleKeyPress}
            />
          </InputRow>
        </Form>
        <ButtonGroup>
          {this.renderError()}
          <Button outline disabled={loading || sent} onClick={this.props.onCancel}>
            <FormattedMessage defaultMessage="Cancel" id="owner.TestNotificationModal.button.cancel"/>
          </Button>
          <Button autoFocus disabled={loading || sent} onClick={this.handleSubmit}>
            {this.renderSubmitText()}
          </Button>
        </ButtonGroup>
      </Card.Body>
    )
  }

  render() {
    const { onClose } = this.props
    return (
      <ComponentRoot role="dialog" aria-modal="true" aria-labelledby="dialog-label">
        <Card>
          <Card.Header>
            <Heading.h3 id="dialog-label">
              <FormattedMessage defaultMessage="Send a test" id="owner.TestNotificationModal.title" />
            </Heading.h3>
            <Icon clickable variant="times" onClick={onClose} />
          </Card.Header>
          {this.renderBody()}
        </Card>
      </ComponentRoot>
    )
  }
}

TestNotificationModal.propTypes = {
  variant: PropTypes.oneOf(Object.keys(title)),
  email: PropTypes.string,
  error: PropTypes.oneOf(Object.keys(errorMessage)),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

TestNotificationModal.defaultProps = {
  variant: 'QVEST_OPENED',
  email: '',
  onClose: () => { },
  onSubmit: () => { },
  onCancel: () => { }
}

export default injectIntl(TestNotificationModal)
