import React from 'react'
import PropTypes from 'prop-types'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import GenericModal from '../../common/GenericModal/GenericModal'
import Typography from '../../common/Typography/Typography'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'


const MAIL_NOT_DELIVERED_STATUS = ['bounce', 'dropped']

const messages = defineMessages({
  title: { defaultMessage: 'Invite new participant',  id: 'owner.ConfirmParticipantModal.title' }
})

const mailDeliveryStatusMessages = defineMessages({
  bounce: { defaultMessage: 'Email appears to be invalid.', id: 'owner.ConfirmParticipantModal.error.bounce2' },
  dropped: { defaultMessage: 'The receiving server denied the invitation/opening email.', id: 'owner.ConfirmParticipantModal.error.dropped2' }
})

const generateErrorMessage = (participant, intl) => {
  if (!participant.lastMailEvent || (MAIL_NOT_DELIVERED_STATUS.indexOf(participant.lastMailEvent.eventName) === -1))
    return null
  const event = participant.lastMailEvent
  const text = intl.formatMessage(mailDeliveryStatusMessages[event.eventName])
  return {
    type: 'error',
    content: (
      <Typography tertiary>{text}</Typography>
    )
  }
}

const LoadingBody = ({ hidden, participant }) => {
  if (hidden) return null
  return (
    <div>
      <Typography weight="light">
        <FormattedMessage
          id="owner.ConfirmParticipantModal.loading"
          defaultMessage="E-mail sent to {email}, awaiting delivery confirmation."
          values={{ email: <Typography weight="bold">{participant.email}</Typography> }}
        />
      </Typography>
      <CenteredLoader size="medium" />
    </div>
  )
}

const Description = ({ hidden }) => {
  if (hidden) return null
  return (
    <div>
      <Typography weight="light">
        <FormattedMessage
          id="owner.ConfirmParticipantModal.description"
          defaultMessage="Contact your account manager for further elaboration."
        />
      </Typography>
    </div>

  )
}

const ConfirmParticipantModal = ({ participant, loading, intl, onCancel }) => {
  const modalProps = {
    title: intl.formatMessage(messages.title),
    message: generateErrorMessage(participant, intl),
    narrow: true,
    cancel: true,
    onCancel,
    loading
  }
  return (
    <GenericModal {...modalProps}>
      <LoadingBody hidden={!loading} participant={participant} />
      <Description hidden={loading} />
    </GenericModal>
  )
}

ConfirmParticipantModal.propTypes = {
  participant: PropTypes.shape({
    email: PropTypes.string.isRequired,
    lastMailEvent: PropTypes.shape({
      eventName: PropTypes.string
    })
  }).isRequired,
  loading: PropTypes.bool,
  onCancel: PropTypes.func
}

ConfirmParticipantModal.defaultProps = {
  onCancel: () => {}
}

export default injectIntl(ConfirmParticipantModal)
