import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { withTheme } from 'styled-components'
import { RadialChart } from 'data-viz'
import { recommendedThemeCount } from '../../../../utils/questionUtils'

import AnalyticsCard from '../../AnalyticsCard/AnalyticsCard'
import Typography from '../../../common/Typography/Typography'

const Details = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

class ThemesAnalyticsCard extends Component {
  handleClick = () => this.props.onClick('themes')

  render() {
    const { themes, questions, theme, locked, loading } = this.props

    // render loading tile
    if (loading) return <AnalyticsCard loading locked={locked} />

    // describe fallback data
    let title = null
    let questionCount = 0
    let chartData = [{ label: '', value: 1 }]

    // load actual data
    if (themes && questions) {
      questionCount = questions.length
      if (themes.length) {
        chartData = themes.map(({ questionIds }) => ({
          label: '',
          value: questionIds.length,
        }))
        title = <FormattedMessage
          id="owner.ThemesAnalyticsCard.title.withThemes"
          defaultMessage="{count} {count, plural, one {theme} other {themes}}"
          values={{ count: themes.length }}
        />
      } else if (questionCount) {
        title = <FormattedMessage
          id="owner.ThemesAnalyticsCard.title.noThemes"
          defaultMessage="Sort into {count} themes"
          values={{ count: recommendedThemeCount(questionCount) }}
        />
      }
    }

    // remove background color
    const colors = theme.inverted.two.map((val, i) => i === 1 ? '#4f524d' : val)

    const subTitle = <FormattedMessage
      id="owner.ThemesAnalyticsCard.subTitle"
      defaultMessage="Themes"
    />

    return (
      <AnalyticsCard
        subTitle={subTitle}
        title={title}
        onClick={this.handleClick}
        disabled={!questionCount && !locked}
        locked={locked}
      >
        <RadialChart
          noStroke
          height={140}
          width={320}
          radius={70}
          data={chartData}
          colors={colors}
        />
        <Details>
          <Typography inverted={locked} variant="small" weight="light">
            <FormattedMessage
              id="owner.ThemesAnalyticsCard.questions"
              defaultMessage="{questionCount} {questionCount, plural, one {question} other {questions}}"
              values={{ questionCount }}
            />
          </Typography>
        </Details>
      </AnalyticsCard>
    )
  }
}

export default withTheme(ThemesAnalyticsCard)
