import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, FormattedRelative } from 'react-intl'
import styled from 'styled-components'

import Typography from '../Typography/Typography'
import Button from '../Button/Button'
import Input from '../Input/Input'
import EditableSpan from '../EditableSpan/EditableSpan'


function makeYouTubeThumbUrl(url) {
  const regex = /http[s]?:\/\/w{0,3}\.?youtu\.?be(.com)?\/(watch\?v=|embed\/)?([^&?\n]+)/
  const match = regex.exec(url)
  if(!match) {
    return null
  } else {
    const videoId = match[3]
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
  }
}

const ComponentRoot = styled.div`
  padding-top: 20px;
  background-color: #F2EDE2;
  color: #3d4641;
  font-family: sans-serif;

  & {

    & .card {
      background-color: #ffffff;
      margin: 0 auto 0 auto;
      max-width: 600px;
    }

    & .header {
      padding: 25px 30px 25px 30px;
      border-bottom: 1px solid #f3f3f1;
    }

    & .header p {
      color: #bbbbbb;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }

    & .header h1 {
      margin: 0;
      line-height: 26px;
      font-size: 18px;
      font-weight: normal;
    }

    & .card-body {
      padding: 25px 30px 25px 30px;
    }

    & .card-body p {
      margin-bottom: 20px;
    }

    & .company-logo img {
      max-height: 50px;
      margin-bottom: 26px;
    }

    & .content {
      font-size: 16px;
    }

    & .video-thumb > img {
      width: 100%;
    }

    & .contact {
      font-size: 14px;
      margin: 0;
    }

    & .contact a,
    & .contact a:link,
    & .contact a:visited,
    & .contact a:hover,
    & .contact a:active {
      color: #f4bf6c;
      font-weight: 400;
    }

    .button-wrapper {
      margin: 45px 0 45px 0;
    }

    & .footer {
      padding: 0 20px 10px 20px;
      margin: 10px auto 0 auto;
      max-width: 500px;
    }

    & .footer table {
      width: 100%;
    }

    & .footer td {
      width: 50%;
    }

    & .footer a,
    & .footer a:link,
    & .footer a:visited,
    & .footer a:hover,
    & .footer a:active {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: #294457;
    }

    #colors-1 {
      background-color: #c5cfc6
    }

    #colors-2 {
      background-color: #f9f7f1
    }

    #colors-3 {
      background-color: #64936e
    }

    #colors-4 {
      background-color: #3d4641
    }

    #colors-5 {
      background-color: #f7a15d
    }

    #colors-6 {
      background-color: #cd5154
    }

    #colors-7 {
      background-color: #486f89
    }

    #color-bar {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
    }

    #color-bar td {
      height: 1px;
    }

    #footer-img {
      width: 100%;
      border-top: 1px solid #f3f3f1;
    }

    #footer-img td {
      text-align: center;
    }

    #footer-img td img {
      margin: 20px;
      height: 50px;
    }
  }
`

const NarrowField = styled.p`
  max-width: 120px;
`

const DEFAULT_LOGO = 'https://dev.qvest.io/static/img/logo-vertical.png'

class InviteEmail extends Component {
  renderEditableSender() {
    const { contactInfo, onEditing, isEditing, onChange, onSave } = this.props
    const value = contactInfo || {}
    return (
      <EditableSpan
        fluid
        isEditing={isEditing === 'sender'}
        onStartEdit={() => onEditing('sender')}
        onDone={onSave}
      >
        <EditableSpan.View>
          <div>
            <span>
              {value.senderName}
            </span>
            <br />
            <span>
              {value.senderTitle}
            </span>
          </div>
        </EditableSpan.View>
        <EditableSpan.Edit fullWidth>
          <div>
            <Typography weight="bold">Sender Name: </Typography>
            <Input
              autoFocus
              type="text"
              value={value.senderName}
              onChange={({ target }) => onChange({ contactInfo: { senderName: target.value } })}
            />
          </div>
          <div>
            <Typography weight="bold">Sender Title: </Typography>
            <Input
              type="text"
              value={value.senderTitle}
              onChange={({ target }) => onChange({ contactInfo: { senderTitle: target.value } })}
            />
          </div>
        </EditableSpan.Edit>
      </EditableSpan>
    )
  }

  renderVideoThumbnail() {
    const { video, videoUrl, url } = this.props
    if (!video && !videoUrl) return null
    let imgSrc
    if (video) {
      imgSrc = video.thumbnail
    } else {
      imgSrc = makeYouTubeThumbUrl(videoUrl)
    }
    return (
      <p>
        <a className="video-thumb" href={url} target="_blank" rel="noopener noreferrer">
          <img src={imgSrc} alt="video thumbnail" />
        </a>
      </p>
    )
  }

  renderSchedule() {
    let { schedule, onScheduleEdit } = this.props
    let startStamp = '[Open date]'
    if (schedule && schedule.inviteAt && schedule.openAt) {
      startStamp = <FormattedRelative initialNow={schedule.inviteAt} value={schedule.openAt} />
    }
    let closeStamp = '[Close date]'
    if (schedule && schedule.inviteAt && schedule.closeAt) {
      closeStamp = <FormattedRelative initialNow={schedule.inviteAt} value={schedule.closeAt} />
    }
    return (
      <Fragment>
        <NarrowField>
          <strong>
            <FormattedMessage defaultMessage="Qvest opens:" id="owner.inviteEmail.opens" />
          </strong>
          <br />
          <EditableSpan onStartEdit={onScheduleEdit}>
            <EditableSpan.View>{startStamp}</EditableSpan.View>
          </EditableSpan>
        </NarrowField>
        <NarrowField>
          <strong>
            <FormattedMessage defaultMessage="Qvest closes:" id="owner.inviteEmail.closes" />
          </strong>
          <br />
          <EditableSpan onStartEdit={onScheduleEdit}>
            <EditableSpan.View>{closeStamp}</EditableSpan.View>
          </EditableSpan>
        </NarrowField>
      </Fragment>
    )
  }

  renderTopic() {
    let { topic, onTopicEdit } = this.props
    return (
      <p>
        <strong>
          <FormattedMessage
            id="owner.inviteEmail.topic"
            defaultMessage="Qvest topic:"
          />
        </strong>
        <br />
        <EditableSpan onStartEdit={onTopicEdit}>
          <EditableSpan.View>{topic}</EditableSpan.View>
        </EditableSpan>
      </p>
    )
  }

  renderLogo() {
    const { logo } = this.props
    const src = logo || DEFAULT_LOGO
    return (
      <div className="company-logo">
        <img src={src} alt="logo" />
      </div>
    )
  }

  render() {
    const {
      organizationName,
      topic,
      url,
      participantCount
    } = this.props

    return (
      <ComponentRoot>
        <div className="card">
          <table id="color-bar">
            <tbody>
              <tr>
                <td id="colors-1"/>
                <td id="colors-2"/>
                <td id="colors-3"/>
                <td id="colors-4"/>
                <td id="colors-5"/>
                <td id="colors-6"/>
                <td id="colors-7"/>
              </tr>
            </tbody>
          </table>
          <div className="header">
            <p>{organizationName}</p>
            <h1>{topic}</h1>
          </div>
          <div className="card-body">
            {this.renderLogo()}

            <div className="content">
              <p>
                <FormattedMessage
                  id="owner.inviteEmail.greeting"
                  defaultMessage="Hi [Participant name]"
                />
              </p>

              <p>
                <FormattedMessage
                  id="owner.inviteEmail.invited"
                  defaultMessage="You are one of the {participantCount} people I have invited to join this Qvest."
                  values={{ participantCount }}
                />
              </p>

              <p>
                <FormattedMessage
                  id="owner.inviteEmail.definition"
                  defaultMessage="A Qvest is very simple. You and your co-participants exchange questions and answers about a specific topic."
                />
              </p>

              {this.renderTopic()}

              {this.renderSchedule()}

              {this.renderVideoThumbnail()}

              <div className="button-wrapper">
                <Button.a href={url} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage
                    id="owner.inviteEmail.button"
                    defaultMessage="More about our Qvest"
                  />
                </Button.a>
              </div>

              <p>
                <strong>
                  <FormattedMessage
                    id="owner.inviteEmail.regards"
                    defaultMessage="Kind Regards"
                  />
                </strong>
                <br />
                {this.renderEditableSender()}
              </p>
            </div>
          </div>

          <table id="footer-img">
            <tbody>
              <tr>
                <td>
                  <a href="https://qvest.io">
                    <img src={DEFAULT_LOGO} alt="Qvest logo" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="footer">
          <table>
            <tbody>
              <tr>
                <td>
                  <a href="https://qvest.io">
                    <FormattedMessage defaultMessage="About Qvest" id="owner.inviteEmail.about" />
                  </a>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <a href="/src/static">
                    <FormattedMessage defaultMessage="Notification Settings" id="owner.inviteEmail.notificationSettings" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ComponentRoot>
    )
  }
}

InviteEmail.propTypes = {
  organizationName: PropTypes.string,
  topic: PropTypes.string,
  logo: PropTypes.string,
  recipientName: PropTypes.string,
  participantCount: PropTypes.number,
  videoUrl: PropTypes.string,
  url: PropTypes.string,
  schedule: {
    inviteAt: PropTypes.instanceOf(Date),
    openAt: PropTypes.instanceOf(Date),
    closeAt: PropTypes.instanceOf(Date)
  },
  contactInfo: {
    name: PropTypes.string,
    email: PropTypes.string,
    senderName: PropTypes.string,
    senderTitle: PropTypes.string
  }
}

InviteEmail.defaultProps = {
  topic: '[Choose a motivational topic]',
  logo: 'https://dev.qvest.io/static/img/logo-vertical.png'
}

export default InviteEmail
