import * as api from './apiClient'
import { log } from '../utils/trackingUtils'
import { uploadFile, validateLogoFile } from '../utils/fileUploadUtils'
import { raiseUnexpectedErrorNotification } from './notificationActions'

export const getMyOrganizations = () => dispatch => {
  dispatch({ type: 'GET_ORGANIZATION_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
          query q {
            organizations {
              items {
                organizationId
                name
                isMaster
                logo
              }
            }
          }
        `,
    }
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const items = result.data.organizations.items
      let data = {}
      items.forEach(o => {
        data[o.organizationId] = o
      })
      const ids = items.map(o => o.organizationId)
      dispatch({ type: 'GET_ORGANIZATION_SUCCESS', data, ids })
    })
    .catch(error => {
      dispatch({ type: 'GET_ORGANIZATION_FAILURE', error })
    })
}

export const updateOrganization = (organizationId, patch) => dispatch => {
  dispatch({ type: 'UPDATE_ORGANIZATION_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        mutation m($organizationId:String!, $name:String) {
          updateOrganization(organizationId: $organizationId, patch: { name:$name }) {
            name
          }
        }
      `,
      variables: { organizationId, name: patch.name }
    },
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const organization = result.data.updateOrganization
      dispatch({ type: 'UPDATE_ORGANIZATION_SUCCESS', organizationId, organization })
    })
    .catch(error => {
      dispatch(raiseUnexpectedErrorNotification())
      dispatch({ type: 'UPDATE_ORGANIZATION_FAILURE', error })
    })
}

export const getUsersInOrganization = organizationId => dispatch => {
  dispatch({ type: 'GET_USERS_IN_ORGANIZATION_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        query m($organizationId:String!) {
          organization(organizationId: $organizationId) {
            users {
              userId
              fullName
              isMaster
              email
            }
          }
        }
      `,
      variables: { organizationId }
    },
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const users = result.data.organization.users
      dispatch({ type: 'GET_USERS_IN_ORGANIZATION_SUCCESS', organizationId, users })
    })
    .catch(error => {
      dispatch({ type: 'GET_USERS_IN_ORGANIZATION_FAILURE', error })
    })
}

export const getPendingUsersInOrganization = (organizationId) => dispatch => {
  dispatch({ type: 'GET_PENDING_USERS_IN_ORGANIZATION_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        query q($organizationId:String!) {
          pendingUsers(organizationId:$organizationId) {
            organizationUserInvitationId
            fullName
            expiredAt
          }
        }
      `,
      variables: { organizationId }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(result => {
      const pendingUsers = result.data.pendingUsers
      dispatch({ type: 'GET_PENDING_USERS_IN_ORGANIZATION_SUCCESS', organizationId, pendingUsers })
    })
    .catch(error => {
      dispatch({ type: 'GET_PENDING_USERS_IN_ORGANIZATION_FAILURE', error })
    })
}

export const removeUserFromOrganization = (organizationId, userId) => dispatch => {
  dispatch({ type: 'REMOVE_USER_FROM_ORGANIZATION_REQUEST' })
  const options = {
    method: 'POST',
    body: {
      query: `
        mutation m($organizationId: String!, $userId: String!) {
          removeUserFromOrganization(
            organizationId: $organizationId,
            userId: $userId
          ) {
            userId
          }
        }
      `,
      variables: { organizationId, userId }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(() => {
      dispatch({ type: 'REMOVE_USER_FROM_ORGANIZATION_SUCCESS', organizationId, userId })
    })
    .catch(error => {
      dispatch(raiseUnexpectedErrorNotification())
      dispatch({ type: 'REMOVE_USER_FROM_ORGANIZATION_FAILURE', error })
    })
}

export const uploadOrganizationLogo = (organizationId, file) => dispatch => {
  dispatch({ type: 'UPLOAD_ORGANIZATION_LOGO_REQUEST' })
  const validationError = validateLogoFile(file)
  if (validationError) {
    log.warning(validationError)
    dispatch({ type: 'UPLOAD_ORGANIZATION_LOGO_FAILURE', error: validationError })
    return Promise.reject(validationError)
  }
  const fileExtension = file.name.split('.').pop()
  const options = {
    method: 'POST',
    body: {
      query: `
          query q($organizationId:String!, $fileExtension:ImageFileExtension!) {
            logoUploadInfo(organizationId:$organizationId, fileExtension:$fileExtension) {
              url,
              fields
            }
          }
        `,
      variables: { organizationId, fileExtension }
    }
  }
  return api.invoke('/api/graphql', options)
    .then(({ data }) => uploadFile({ ...data.logoUploadInfo, file }))
    .then(url => {
      dispatch({ type: 'UPLOAD_ORGANIZATION_LOGO_SUCCESS', organizationId, url })
    })
    .catch(error => {
      log.error(error)
      dispatch({ type: 'UPLOAD_ORGANIZATION_LOGO_FAILURE', error })
      throw error
    })
}
