import styled from 'styled-components'

const TextArea = styled.textarea`
  width: 100%;
  resize: ${({ resize }) => resize || 'both'};
  padding: 4px 8px 4px 8px;
  background-color: ${({ theme }) => theme.main.tertiary};
  border: 1px solid #DEDFDF;
  border-radius: 3px;
  color: ${({ theme }) => theme.default};
  font-family: ${({ theme }) => theme.font1};
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus {
    border: 1px solid ${({ theme }) => theme.main.primary};
    outline: none;
  }

  &::placeholder {
    color: #DEDFDF;
  }
`

export default TextArea
