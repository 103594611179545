import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { GroupsNetworkGraph } from 'data-viz'

import { makeGroupColorScales } from '../../../../../utils/d3Utils'
import Card from '../../../../../components/common/Card/Card'
import Typography from '../../../../../components/common/Typography/Typography'
import Disclaimer from '../../../../../components/owner/Disclaimer/Disclaimer'
import { TitleBar } from '../../../../../components/owner/AnalyticsLayout/AnalyticsLayout'

import CenteredLoader from '../../../../../components/common/CenteredLoader/CenteredLoader'
import Button from '../../../../../components/common/Button/Button'
import QuestionAnswerTable from '../../../../../components/common/QuestionAnswerTable/QuestionAnswerTable'
import AnalyticsNetwork from '../../../../../components/owner/AnalyticsNetwork/AnalyticsNetwork'
import { getGroupGraph } from '../../../../../actions/statsActions'
import { searchQuestions } from '../../../../../actions/questionActions'
import {
  getConfiguration,
  initUnsavedConfiguration,
} from '../../../../../actions/configurationActions'
import { filterQuestionSearchResult } from '../../../../../reducers/questions'

const ComponentRoot = styled.div`
  & > * { max-width: 1400px; }
  & > :nth-child(3) {
    padding: 0 30px;
    margin-top: 30px;
    min-width: 1270px;
  }
  & > :last-child {
    margin: 50px 30px 30px 30px;
    max-width: 989px
  }
`

const ChartColumns = styled.div`
  display: flex;
  flex-direction: row;
`

const ButtonGroup = styled.div`
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`

const ResultGroup = styled.div`
  border: 1px solid ${({ theme }) => theme.one[3]};
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 50px;
`

const SelectionText = ({ variant, groupId1, groupId2, groupGraphData, colors, hide }) => {
  if (hide) return null
  let group1, group2
  if (groupId1) {
    const name = groupGraphData.nodes.find(n => n.id === groupId1).name
    group1 = (<strong style={{ color: colors(groupId1) }}>{name}</strong>)
  }
  if (groupId2) {
    const name = groupGraphData.nodes.find(n => n.id === groupId2).name
    group2 = (<strong style={{ color: colors(groupId2) }}>{name}</strong>)
  }
  let text
  if (variant === 'EDGE_SELF') {
    text = (
      <FormattedMessage
        id="owner.NetworkPage.detailCard.selectionText.EDGE_SELF"
        defaultMessage="Questions asked internally in {group}"
        values={{ group: group1 }}
      />
    )
  } else if (variant === 'EDGE') {
    text = (
      <FormattedMessage
        id="owner.NetworkPage.detailCard.selectionText.EDGE"
        defaultMessage="Questions asked by {group1} and sent to {group2}"
        values={{ group1, group2 }}
      />
    )
  } else if (variant === 'NODE_IN') {
    text = (
      <FormattedMessage
        id="owner.NetworkPage.detailCard.selectionText.NODE_IN"
        defaultMessage="Questions sent to {group}"
        values={{ group: group1 }}
      />
    )
  } else if (variant === 'NODE_OUT') {
    text = (
      <FormattedMessage
        id="owner.NetworkPage.detailCard.selectionText.NODE_OUT"
        defaultMessage="Questions asked by {group}"
        values={{ group: group1 }}
      />
    )
  } else if (variant === 'EMPTY') {
    text = (
      <FormattedMessage
        id="owner.NetworkPage.detailCard.selectionText.EMPTY"
        defaultMessage="No questions were sent to or from {group}"
        values={{ group: group1 }}
      />
    )
  } else {
    text = (
      <FormattedMessage
        id="owner.NetworkPage.detailCard.selectionText.DEFAULT"
        defaultMessage="Click on groups or connections between them to explore questions"
      />
    )
  }
  return (
    <div>
      <Typography weight="light">{text}</Typography>
    </div>
  )
}

const SearchResults = ({ mode, questions, groupGraphData, filter, colors }) => {
  if (!questions || !filter) return (<SelectionText />)
  const { sourceGroupId, targetGroupId } = filter.groupSearch
  if (!questions.length) {
    return (
      <SelectionText variant="EMPTY" groupId1={sourceGroupId} groupGraphData={groupGraphData} colors={colors} />
    )
  } else if (mode === 'EDGE_SELF') {
    return (
      <div>
        <ResultGroup>
          <SelectionText
            variant="EDGE_SELF"
            groupId1={sourceGroupId}
            groupGraphData={groupGraphData}
            colors={colors}
          />
          <QuestionAnswerTable data={questions} />
        </ResultGroup>
      </div>
    )
  } else if (mode === 'EDGE') {
    const sourceQuestions = questions.filter(q => q.questionGroupId === sourceGroupId)
    const targetQuestions = questions.filter(q => q.questionGroupId === targetGroupId)
    return (
      <div>
        <ResultGroup>
          <SelectionText
            hide={!sourceQuestions.length}
            variant="EDGE"
            groupId1={sourceGroupId}
            groupId2={targetGroupId}
            groupGraphData={groupGraphData}
            colors={colors}
          />
          <QuestionAnswerTable data={sourceQuestions} />
        </ResultGroup>
        <ResultGroup>
          <SelectionText
            hide={!targetQuestions.length}
            variant="EDGE"
            groupId1={targetGroupId}
            groupId2={sourceGroupId}
            groupGraphData={groupGraphData}
            colors={colors}
          />
          <QuestionAnswerTable data={targetQuestions} />
        </ResultGroup>
      </div>
    )
  } else if (mode === 'NODE') {
    const outQuestions = questions.filter(q => q.questionGroupId === sourceGroupId)
    const inQuestions = questions.filter(q => q.answerGroupId === sourceGroupId)
    return (
      <div>
        <ResultGroup>
          <SelectionText
            hide={!inQuestions.length}
            variant="NODE_IN"
            groupId1={targetGroupId}
            groupGraphData={groupGraphData}
            colors={colors}
          />
          <QuestionAnswerTable data={inQuestions} />
        </ResultGroup>
        <ResultGroup>
          <SelectionText
            hide={!outQuestions.length}
            variant="NODE_OUT"
            groupId1={sourceGroupId}
            groupGraphData={groupGraphData}
            colors={colors}
          />
          <QuestionAnswerTable data={outQuestions} />
        </ResultGroup>
      </div>
    )
  }
}


const NetworkDetailCard = withTheme(({ qvestId, groupGraphData, questionStore, colors, theme, onSearch }) => {
  const [graphKey, setGraphKey] = useState(1)
  const [mode, setMode] = useState(null)
  const [filter, setFilter] = useState(null)

  const makeGroupSearch = (groupSearch) => {
    const filter = { qvestId, groupSearch }
    setFilter(filter)
    onSearch(filter)
  }

  const handleResetClick = () => {
    setGraphKey(graphKey + 1)
  }

  const handleNetworkEdgeClick = (sourceGroupId, targetGroupId, isUniDirectional) => {
    if (sourceGroupId === targetGroupId) {
      setMode('EDGE_SELF')
    } else {
      setMode('EDGE')
    }
    makeGroupSearch({ sourceGroupId, targetGroupId, isUniDirectional })
  }

  const handleNetworkNodeClick = (groupId) => {
    setMode('NODE')
    makeGroupSearch({ sourceGroupId: groupId, targetGroupId: groupId, operator: 'OR' })
  }

  let groupGraph = null
  if (groupGraphData) {
    const colors = makeGroupColorScales(theme, groupGraphData.nodes.map(n => n.id)).primaryColors
    groupGraph = (
      <ChartColumns>
        <GroupsNetworkGraph
          key={graphKey}
          linkForceDistance={200}
          data={groupGraphData}
          colors={colors}
          width={800}
          height={500}
          onEdgeClick={handleNetworkEdgeClick}
          onNodeClick={handleNetworkNodeClick}
          showNodeTooltips={false}
        />
      </ChartColumns>
    )
  } else {
    groupGraph = <CenteredLoader size="medium" />
  }

  let questions
  if (filter) {
    questions = filterQuestionSearchResult(questionStore, filter)
  }

  return (
    <Card>
      <Card.Header>
        <Typography variant="heading3">
          <FormattedMessage defaultMessage="Clickable Network" id="owner.NetworkPage.detailCard.title" />
        </Typography>
        <ButtonGroup>
          <Button outline onClick={handleResetClick}>Reset</Button>
        </ButtonGroup>
      </Card.Header>
      <Card.Body>
        {groupGraph}
        <SearchResults
          mode={mode}
          questions={questions}
          groupGraphData={groupGraphData}
          filter={filter}
          colors={colors}
        />
      </Card.Body>
    </Card>
  )
})



class OwnerNetworkPage extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getGroupGraph(match.params.qvestId))
    dispatch(getConfiguration(match.params.qvestId))
      .then(() => dispatch(initUnsavedConfiguration(match.params.qvestId)))
  }

  handleBackClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics/home`)
  }

  handleGroupSearch = (filter) => {
    this.props.dispatch(searchQuestions(filter))
  }

  render() {
    const { groupGraphStore, questionStore, theme, match } = this.props
    const qvestId = match.params.qvestId

    let groupGraphData = null
    let colors = null
    if (groupGraphStore.data.hasOwnProperty(qvestId)) {
      groupGraphData = groupGraphStore.data[qvestId]
      colors = makeGroupColorScales(theme, groupGraphData.nodes.map(n => n.id)).primaryColors
    }

    const title = <FormattedMessage
      id="owner.NetworkPage.title"
      defaultMessage="Network"
    />
    const description = <FormattedMessage
      id="owner.AnalyticsNetwork.description"
      defaultMessage="Connections among Qvest stakeholders"
    />

    return (
      <ComponentRoot>
        <TitleBar
          title={title}
          description={description}
          onBack={this.handleBackClick}
        />
        <AnalyticsNetwork groupGraph={groupGraphData} colors={colors} />
        <div>
          <NetworkDetailCard
            qvestId={qvestId}
            colors={colors}
            groupGraphData={groupGraphData}
            questionStore={questionStore}
            onSearch={this.handleGroupSearch}
          />
        </div>
        <Disclaimer />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    groupGraphStore: state.stats.get('groupGraph').toJS(),
    configurationStore: state.configuration.toJS(),
    questionStore: state.questions.toJS()
  }
}

export default connect(mapStateToProps)(withTheme(OwnerNetworkPage))
