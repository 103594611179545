import Immutable from 'immutable'

let INITIAL_STATE = Immutable.fromJS({
  isLoading: null,
  error: null,
  data: {},
  myOrganizationIds: null
})

const users = (state = Immutable.List(), action) => {
  switch (action.type) {
    case 'GET_USERS_IN_ORGANIZATION_SUCCESS':
      return Immutable.fromJS(action.users)
    case 'REMOVE_USER_FROM_ORGANIZATION_SUCCESS':
      return state.delete(state.findIndex(u => u.get('userId') === action.userId))
    case 'INVITE_USER_TO_ORG_SUCCESS':
      return action.user ? state.unshift(action.user) : state
    default:
      return state
  }
}

const organization = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case 'UPDATE_ORGANIZATION_SUCCESS':
      return state.merge(action.organization)
    case 'UPLOAD_ORGANIZATION_LOGO_SUCCESS':
      return state.merge({
        logo: action.url,
        logoUploadedAt: new Date()
      })
    case 'GET_PENDING_USERS_IN_ORGANIZATION_SUCCESS':
      return state.merge({ pendingUsers: action.pendingUsers })
    case 'INVITE_USER_TO_ORG_SUCCESS':
      return state
        .update('pendingUsers', pu => action.pendingUser ? pu.unshift(action.pendingUser) : pu)
        .update('users', u => users(u, action))
    case 'GET_USERS_IN_ORGANIZATION_SUCCESS':
    case 'REMOVE_USER_FROM_ORGANIZATION_SUCCESS':
      return state.update('users', u => users(u, action))
    default:
      return state
  }
}

const organizationStore = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_ORGANIZATION_REQUEST':
    case 'GET_USERS_IN_ORGANIZATION_REQUEST':
    case 'GET_PENDING_USERS_IN_ORGANIZATION_REQUEST':
    case 'INVITE_USER_TO_ORG_REQUEST':
    case 'UPDATE_ORGANIZATION_REQUEST':
    case 'REMOVE_USER_FROM_ORGANIZATION_REQUEST':
    case 'UPLOAD_ORGANIZATION_LOGO_REQUEST':
      return state.merge({
        isLoading: true,
        error: null
      })
    case 'GET_ORGANIZATION_SUCCESS':
      return state.merge({
        myOrganizationIds: Immutable.fromJS(action.ids),
        data: state.get('data').mergeDeep(action.data),
        isLoading: false
      })
    case 'GET_USERS_IN_ORGANIZATION_SUCCESS':
    case 'GET_PENDING_USERS_IN_ORGANIZATION_SUCCESS':
    case 'INVITE_USER_TO_ORG_SUCCESS':
    case 'REMOVE_USER_FROM_ORGANIZATION_SUCCESS':
    case 'UPDATE_ORGANIZATION_SUCCESS':
    case 'UPLOAD_ORGANIZATION_LOGO_SUCCESS':
      return state.merge({
        data: state.get('data').update(action.organizationId, o => organization(o, action)),
        isLoading: false
      })
    case 'GET_ORGANIZATION_FAILURE':
    case 'GET_USERS_IN_ORGANIZATION_FAILURE':
    case 'GET_PENDING_USERS_IN_ORGANIZATION_FAILURE':
    case 'INVITE_USER_TO_ORG_FAILURE':
    case 'UPDATE_ORGANIZATION_FAILURE':
    case 'REMOVE_USER_FROM_ORGANIZATION_FAILURE':
    case 'UPLOAD_ORGANIZATION_LOGO_FAILURE':
      return state.merge({
        isLoading: false,
        error: action.error
      })
    default:
      return state
  }
}

export function filterFirstOrganization(state) {
  if (Object.keys(state.data).length === 0) {
    return null
  }
  // If organization(s) where user is master can be found, prefer the first one of those
  const ownOrgs = Object.values(state.data).filter(org => org.isMaster === true)
  if (ownOrgs && ownOrgs.length > 0) {
    return ownOrgs[0]
  }
  // Otherwise, settle for the first known organization
  return state.data[Object.keys(state.data)[0]]
}

export function filterByMyOrganizations(state) {
  if (!state.myOrganizationIds) {
    return null
  }
  return state.myOrganizationIds.map(id => state.data[id])
}

export function filterByOrganizationId(state, organizationId) {
  if (Object.keys(state.data).indexOf(organizationId) === -1) {
    return null
  }
  return state.data[organizationId]
}


export default organizationStore
