import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import styled, { withTheme } from 'styled-components'

import AnalyticsDistribution from '../../../../../components/owner/AnalyticsDistribution/AnalyticsDistribution'
import { TitleBar } from '../../../../../components/owner/AnalyticsLayout/AnalyticsLayout'
import { filterParticipationByQvestId } from '../../../../../reducers/stats'
import { getParticipation } from '../../../../../actions/statsActions'
import Card from '../../../../../components/common/Card/Card'
import Typography from '../../../../../components/common/Typography/Typography'
import Disclaimer from '../../../../../components/owner/Disclaimer/Disclaimer'
import { ButterflyBarChart } from 'data-viz'
import PerParticipantParticipationTable from '../../../../../components/owner/PerParticipantParticipationTable/PerParticipantParticipationTable'
import ContainerDimensions from 'react-container-dimensions'

const ComponentRoot = styled.div`
  & > * { max-width: 1400px; }
  & > :nth-child(3) {
    padding: 0 30px;
    margin-top: 30px;
    min-width: 1270px;
  }
  & > :last-child {
    margin: 50px 30px 30px 30px;
    max-width: 989px
  }
`

const round2 = (value) => Math.round(value * 100) / 100
function computePerParticipant(participation) {
  // Compute per participant values
  participation = participation.map(group => ({
    ...group,
    questionsPerParticipant: group.participantCount ? round2(group.questionSentCount / group.participantCount) : 0,
    answersPerParticipant: group.participantCount ? round2(group.answerSentCount / group.participantCount) : 0
  }))

  // Sort by questions per participant
  participation = participation
    .sort((groupA, groupB) => groupA.questionsPerParticipant - groupB.questionsPerParticipant)

  return participation
}

class OwnerAnalyticsDistributionPage extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getParticipation(match.params.qvestId))
  }

  handleBackClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics/home`)
  }

  renderPerParticipantChart(participation) {
    const ids = participation.map(group => group.groupId)
    const labels = participation.map(group => group.name)
    const values1 = participation.map(group => group.questionsPerParticipant)
    const values2 = participation.map(group => group.answersPerParticipant)
    return (
      <div>
        <ContainerDimensions>
          {({width}) => <ButterflyBarChart data={{ ids, labels, values1, values2 }} width={width} />}
        </ContainerDimensions>
      </div>
    )
  }

  render() {
    const { participationStore, match } = this.props

    const participation = filterParticipationByQvestId(participationStore, match.params.qvestId)

    // TODO: nicer loader than nothing
    if (!participation) return null

    // calculations for legacy card
    const perParticipantParticipation = computePerParticipant(participation)

    const title = <FormattedMessage
      id="owner.AnalyticsDistribution.title"
      defaultMessage="Distribution"
    />
    const description = <FormattedMessage
      id="owner.AnalyticsDistribution.description"
      defaultMessage="Connections among Qvest stakeholders"
    />

    return (
      <ComponentRoot>
        <TitleBar
          title={title}
          description={description}
          onBack={this.handleBackClick}
        />
        <AnalyticsDistribution participation={participation} />
        <div>
          <Card>
            <Card.Header>
              <Typography variant="heading3">
                <FormattedMessage
                  id="owner.AnalyticsDistributionPage.cardTitle"
                  defaultMessage="Average contribution"
                />
              </Typography>
            </Card.Header>
            <Card.Body>
              <Typography secondary variant="medium" weight="light">
                <FormattedMessage
                  id="owner.AnalyticsDistributionPage.cardSubTitle"
                  defaultMessage="Explore the average number of questions and answers contributed by each participant."
                />
              </Typography>
            </Card.Body>
            <Card.ChartBox>
              {participation ? this.renderPerParticipantChart(perParticipantParticipation) : null}
            </Card.ChartBox>
            {participation ? <PerParticipantParticipationTable participation={perParticipantParticipation} /> : null}
          </Card>
        </div>
        <Disclaimer />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    participationStore: state.stats.get('participation').toJS(),
  }
}

export default connect(mapStateToProps)(injectIntl(withTheme(OwnerAnalyticsDistributionPage)))
