import Typography from '../../common/Typography/Typography'
import { FormattedMessage, injectIntl } from 'react-intl'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'
import React from 'react'


const LoadingBody = ({ hidden, participant }) => {
  if (hidden) return null
  return (
    <div>
      <Typography weight="light">
        <FormattedMessage
          id="owner.ParticipantModal.loading"
          defaultMessage="E-mail sent to {email}, awaiting delivery confirmation."
          values={{ email: <Typography weight="bold">{participant.email}</Typography> }}
        />
      </Typography>
      <br />
      <br />
      <CenteredLoader size="medium" />
    </div>
  )
}

export default injectIntl(LoadingBody)
