import React, { Component } from 'react'
import styled from 'styled-components'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'

import Table from '../../common/Table/Table'
import EditableRow from './EditableRow'
import { getDuplicates, isDuplicate } from '../../../utils/participantUtils'

const ComponentRoot = styled.div`
  & > table {
    table-layout: fixed;
  }
`

const IconDummyCell = styled.th`
  width: 90px;
`


const deliveryErrorToStatus = {
  bounce: 'ERROR',
  dropped: 'ERROR',
  deferred: 'IN_PROGRESS',
  processed: 'IN_PROGRESS',
  delivered: 'DELIVERED',
  open: 'SEEN',
  group_resubscribe: 'SEEN',
  click: 'CLICKED',
  unsubscribe: 'REJECTED',
  group_unsubscribe: 'REJECTED',
  spamreport: 'REJECTED'
}

const validationErrorMessages = defineMessages({
  invalidFormat: { defaultMessage: 'Invalid e-mail address', id: 'owner.OwnerParticipantTable.validationError.invalidFormat' },
  duplicate: { defaultMessage: 'Duplicate e-mail address', id: 'owner.OwnerParticipantTable.validationError.duplicate' }
})

const mailDeliveryStatusMessages = defineMessages({
  bounce: { defaultMessage: 'E-mail address appears to be invalid. The receiving server denied the most recent Qvest mail.', id: 'owner.OwnerParticipantTable.deliveryStatus.bounce' },
  dropped: { defaultMessage: 'The most recent Qvest mail might have been misclassified as SPAM or the receiver chose to unsubscribe. The receiving server denied the mail.', id: 'owner.OwnerParticipantTable.deliveryStatus.dropped' },
  delivered: { defaultMessage: 'The most recent Qvest mail was accepted by receiving server.', id: 'owner.OwnerParticipantTable.deliveryStatus.delivered' },
  deferred: { defaultMessage: 'The receiving server delayed acceptance of the most recent Qvest mail. Retrying shortly.', id: 'owner.OwnerParticipantTable.deliveryStatus.deferred' },
  processed: { defaultMessage: 'Mail has been sent. Awaiting response from receiving server.', id: 'owner.OwnerParticipantTable.deliveryStatus.processed' },
  open: { defaultMessage: 'Most recent mail was opened', id: 'owner.OwnerParticipantTable.deliveryStatus.open' },
  group_resubscribe: { defaultMessage: 'Participant resubscribed to receiving Qvest mails after having unsubscribed', id: 'owner.OwnerParticipantTable.deliveryStatus.group_resubscribe' },
  click: { defaultMessage: 'Link in most recent mail was clicked', id: 'owner.OwnerParticipantTable.deliveryStatus.click' },
  unsubscribe: { defaultMessage: 'Participant unsubscribed from all Qvest mails', id: 'owner.OwnerParticipantTable.deliveryStatus.unsubscribe' },
  group_unsubscribe: { defaultMessage: 'Participant unsubscribed from Qvest notification mails', id: 'owner.OwnerParticipantTable.deliveryStatus.group_unsubscribe' },
  spamreport: { defaultMessage: 'Participant reported Qvest notification mail as SPAM', id: 'owner.OwnerParticipantTable.deliveryStatus.spamreport' }
})

const mailReplyStatusMessages = defineMessages({
  default: { defaultMessage: 'Email reply received. Click for details.', id: 'owner.OwnerParticipantTable.replyStatus.default' },
})


function isInvalid(participant, invalids) {
  if (!invalids) return false
  return (invalids.indexOf(participant.participantId) !== -1)
}

function getInvalids(validity) {
  if (!validity) return null
  const validationItem = validity.items.find(i => i.name === 'INVALID_FORMAT_PARTICIPANT_EMAILS')
  if (!validationItem) return null
  return validationItem.subjectIds
}

class OwnerParticipantTable extends Component {
  determineStatus(participant, duplicates, invalids) {
    const { intl } = this.props
    const { lastMailEvent, lastMailReply } = participant
    // Local validation status (duplicates and address format)
    if (isDuplicate(participant, duplicates)) {
      return {
        type: 'error',
        icon: 'exclamation-circle',
        name: 'duplicate',
        message: intl.formatMessage(validationErrorMessages.duplicate)
      }
    } else if(isInvalid(participant, invalids)) {
      return {
        type: 'error',
        icon: 'exclamation-circle',
        name: 'invalidFormat',
        message: intl.formatMessage(validationErrorMessages.invalidFormat)
      }
    }
    // Reply received
    if (lastMailReply) {
      return {
        type: 'warning',
        icon: 'exclamation-triangle',
        name: 'reply',
        message: intl.formatMessage(mailReplyStatusMessages.default),
        clickable: true
      }
    }
    // SendGrid informed status check
    if (lastMailEvent && deliveryErrorToStatus.hasOwnProperty(lastMailEvent.eventName)) {
      const status = deliveryErrorToStatus[lastMailEvent.eventName]
      switch (status) {
        case 'ERROR':
          return {
            type: 'error',
            icon: 'exclamation-circle',
            name: participant.lastMailEvent.eventName,
            message: intl.formatMessage(mailDeliveryStatusMessages[lastMailEvent.eventName])
          }
        case 'IN_PROGRESS':
          return {
            type: 'infos',
            icon: 'sync-alt',
            name: participant.lastMailEvent.eventName,
            message: intl.formatMessage(mailDeliveryStatusMessages[lastMailEvent.eventName])
          }
        case 'DELIVERED':
          return {
            type: 'infos',
            icon: 'envelope',
            name: 'delivered',
            message: intl.formatMessage(mailDeliveryStatusMessages[lastMailEvent.eventName])
          }
        case 'SEEN':
          return {
            type: 'infos',
            icon: 'eye',
            name: 'seen',
            message: intl.formatMessage(mailDeliveryStatusMessages[lastMailEvent.eventName])
          }
        case 'CLICKED':
          return {
            type: 'infos',
            icon: 'check-circle',
            name: 'clicked',
            message: intl.formatMessage(mailDeliveryStatusMessages[lastMailEvent.eventName])
          }
        case 'REJECTED':
          return {
            type: 'error',
            icon: 'hand-paper',
            name: 'rejected',
            message: intl.formatMessage(mailDeliveryStatusMessages[lastMailEvent.eventName])
          }
      }
    }
    return null
  }

  render() {
    const {
      participants,
      validity,
      showCreate,
      onRemove,
      onSuspend,
      onEdit,
      onCreate,
      onResend,
      onStatusClick,
      onCancel,
      onUndo,
      onError
    } = this.props
    if (!participants || participants.length === 0 && !showCreate) return null
    const duplicates = getDuplicates(validity)
    const invalids = getInvalids(validity)
    return (
      <ComponentRoot>
        <Table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="owner.OwnerParticipantTable.name" defaultMessage="Name" />
              </th>
              <th>
                <FormattedMessage id="owner.OwnerParticipantTable.email" defaultMessage="Email" />
              </th>
              <IconDummyCell>{/* empty header cell for the icons */}</IconDummyCell>
            </tr>
          </thead>
          <tbody>
            {showCreate &&
              <EditableRow
                key="createParticipant"
                showInput
                onError={onError}
                onCancel={onCancel}
                onEdit={onCreate}
              />}
            {participants.map(participant =>
              <EditableRow
                key={participant.participantId || participant.email}
                participant={participant}
                status={this.determineStatus(participant, duplicates, invalids)}
                onError={onError}
                onRemove={!participant.isSuspended ? onRemove : undefined}
                onSuspend={!participant.isSuspended ? onSuspend : undefined}
                onEdit={!participant.isSuspended ? onEdit : undefined}
                onResend={!participant.isSuspended ? onResend : undefined}
                onCancel={!participant.isSuspended ? onCancel : undefined}
                onUndo={participant.isSuspended ? onUndo : undefined}
                onStatusClick={onStatusClick}
              />)}
          </tbody>
        </Table>
      </ComponentRoot>
    )
  }
}

OwnerParticipantTable.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape({
    participantId: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  })),
  validity: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      subjectIds: PropTypes.arrayOf(PropTypes.string)
    }))
  }),
  onRemove: PropTypes.func,
  onSuspend: PropTypes.func,
  onEdit: PropTypes.func,
  onResend: PropTypes.func,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
}

OwnerParticipantTable.defaultProps = {
  participants: [],
}

export default injectIntl(OwnerParticipantTable)
