import React, { Component, Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import { makeGroupColorScales } from '../../../utils/d3Utils'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'

import {
  Title,
  Content,
  Card,
  MainCard,
  SubContent,
} from '../AnalyticsLayout/AnalyticsLayout'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Tooltip from '../../common/Tooltip/Tooltip'
import GroupList from '../../common/GroupList/GroupList'
import InteractionIcon from './InteractionIcon'


const MainCardLayout = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33%;
    max-width: 500px;
    text-align: center;
  }
  & > :nth-child(2) {
    height: 66%;
    width: 100%;
  }
`

const MediumCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const WideDataLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  & > :first-child {
    flex: 1;
  }
`

const NarrowCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & > :nth-child(2) {
    flex: 1;
  }
`

const OneColumn = styled.div`
  display: flex;
  flex-direction: column;
  & > :first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  & > :nth-child(2) {
    flex: 0
  }
`


const TwoColumn = styled.div`
  display: flex;
  & > * {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  & > *:not(:last-child) {
    margin-right: 30px
  }
`

const ColumnHeader = styled.div`
  margin-bottom: 12px;
`

const CategoryImgWrapper = styled.div`
  & > * {
    margin: auto;
    max-width: 100%;
  }
`


const subjectMessages = defineMessages({
  connected: { defaultMessage: '{count} {count, plural, one {connection} other {connections}}', id: 'owner.InteractionPage.subject.connected' },
  stronglyConnected: { defaultMessage: '{count} strong {count, plural, one {connection} other {connections}}', id: 'owner.InteractionPage.subject.stronglyConnected' },
  selfReferential: { defaultMessage: '{count} self-referential {count, plural, one {group} other {groups}}', id: 'owner.InteractionPage.subject.selfReferential' },
  disconnected: { defaultMessage: '{count} disconnected {count, plural, one {group} other {groups}}', id: 'owner.InteractionPage.subject.disconnected' },
})

const descriptionMessages = defineMessages({
  connected: { defaultMessage: 'across groups exchanged questions', id: 'owner.InteractionPage.description.connected' },
  stronglyConnected: { defaultMessage: 'across groups exchanged significantly more questions than typical groups in your Qvest', id: 'owner.InteractionPage.description.stronglyConnected' },
  selfReferential: { defaultMessage: 'exchanged questions within their own group', id: 'owner.InteractionPage.description.selfReferential' },
  disconnected: { defaultMessage: 'didn’t exchange questions with other groups', id: 'owner.InteractionPage.description.disconnected' },
})

const tooltipMessages = defineMessages({
  title: { defaultMessage: 'Interaction explanation', id: 'owner.InteractionPage.mainTooltip.title' },
  connected: { defaultMessage: 'Groups are considered connected when stakeholders exchange questions across groups. Similarly, groups are considered strongly connected when stakeholders exchange significantly more questions across groups than other groups in the Qvest. Groups are considered self-referential when stakeholders exchange questions within their own group. When stakeholders in a group don’t exchange questions with stakeholders from other groups, the group is considered disconnected. ', id: 'owner.InteractionPage.mainTooltip.connected' },
  stronglyConnected: { defaultMessage: 'Groups are considered strongly connected when stakeholders exchange significantly more questions across groups than other groups in the Qvest. Similarly, groups are considered connected when stakeholders exchange questions across groups. Groups are considered self-referential when stakeholders exchange questions within their own group. When stakeholders in a group don’t exchange questions with stakeholders from other groups, the group is considered disconnected. ', id: 'owner.InteractionPage.mainTooltip.stronglyConnected' },
  selfReferential: { defaultMessage: 'Groups are considered self-referential when stakeholders exchange questions within their own group. Groups are considered connected when stakeholders exchange questions across groups. Similarly, groups are considered strongly connected when stakeholders exchange significantly more questions across groups than other groups in the Qvest. When stakeholders in a group don’t exchange questions with stakeholders from other groups, the group is considered disconnected. ', id: 'owner.InteractionPage.mainTooltip.selfReferential' },
  disconnected: { defaultMessage: 'When stakeholders in a group don’t exchange questions with stakeholders from other groups, the group is considered disconnected. Groups are considered connected when stakeholders exchange questions across groups. Similarly, groups are considered strongly connected when stakeholders exchange significantly more questions across groups than other groups in the Qvest. When stakeholders exchange questions within their own group, groups are considered self-referential.', id: 'owner.InteractionPage.mainTooltip.disconnected' },
})

function sumEdgeValues(edges) {
  return edges.map(e => e.value).reduce((acc, v) => acc + v, 0)
}

export function formatData(nodes, edges) {
  let categories = [
    {key: 'connected', items: [], count: 0},
    {key: 'stronglyConnected', items: [], count: 0},
    {key: 'selfReferential', items: [], count: 0},
    {key: 'disconnected', items: [], count: 0}
  ]

  if (nodes && edges) {
    const totalQuestionCount = sumEdgeValues(edges)

    const selfReferentialEdges = edges.filter(e => e.source === e.target)
    const regularEdges = edges.filter(e => e.source !== e.target)

    const connectedEdges = regularEdges.filter(e => !e.isOutlier)
    const connectedCount = connectedEdges.length
    const connectedPercentage = (sumEdgeValues(connectedEdges) / totalQuestionCount)

    const stronglyConnectedEdges = regularEdges.filter(e => e.isOutlier)
    const stronglyConnectedCount = stronglyConnectedEdges.length
    const stronglyConnectedPercentage = (sumEdgeValues(stronglyConnectedEdges) / totalQuestionCount)

    const selfReferencedIds = selfReferentialEdges.map(e => e.source)
    const isSelfReferential = (node) => (selfReferencedIds.indexOf(node.id) !== -1)
    const selfReferentialNodes = nodes.filter(isSelfReferential)
    const selfReferentialCount = selfReferentialNodes.length
    const selfReferencedPercentage = (sumEdgeValues(selfReferentialEdges) / totalQuestionCount)

    const edgesByGroup = groupBy(edges.map(e => e.source).concat(edges.map(e => e.target)))
    const isDisconnected = (node) => (Object.keys(edgesByGroup).indexOf(node.id) === -1)
    const disconnectedNodes = nodes.filter(isDisconnected)
    const disconnectedCount = disconnectedNodes.length
    const disconnectedPercentage = 0

    categories = [
      {
        key: 'connected',
        nodes: nodes,
        edges: connectedEdges,
        count: connectedCount,
        percentage: connectedPercentage
      },
      {
        key: 'stronglyConnected',
        nodes: nodes,
        edges: stronglyConnectedEdges,
        count: stronglyConnectedCount,
        percentage: stronglyConnectedPercentage
      },
      {
        key: 'selfReferential',
        nodes: selfReferentialNodes,
        count: selfReferentialCount,
        percentage: selfReferencedPercentage
      },
      {
        key: 'disconnected',
        nodes: disconnectedNodes,
        count: disconnectedCount,
        percentage: disconnectedPercentage
      }
    ]

    categories = sortBy(categories, c => -c.count)
  }

  return categories
}

class AnalyticsInteraction extends Component {
  renderMainHeading(category) {
    const { intl } = this.props
    const groupCount = category.nodes.length
    const subjectText = intl.formatMessage(subjectMessages[category.key], { count: category.count })
    const tooltipTitle = intl.formatMessage(tooltipMessages.title)
    const tooltipText = intl.formatMessage(tooltipMessages[category.key])
    const subject = (
      <Tooltip.Area>
        <Tooltip.Reference>
          <Heading variant="heading2" primary>{subjectText}</Heading>
        </Tooltip.Reference>
        <Tooltip wide>
          <Heading.h3>{tooltipTitle}</Heading.h3>
          <Typography variant="medium" weight="light">{tooltipText}</Typography>
        </Tooltip>
      </Tooltip.Area>
    )
    let heading
    switch(category.key) {
      case 'stronglyConnected':
        heading = (
          <FormattedMessage
            id="owner.InteractionPage.heading.stronglyConnected"
            defaultMessage="{groupCount} {groupCount, plural, one {group} other {groups}}
            exchanged significantly more questions than typical groups in your Qvest,
            leading to {subject}"
            values={{ groupCount, subject }}
          />
        )
        break
      case 'connected':
        heading = (
          <FormattedMessage
            id="owner.InteractionPage.heading.connected"
            defaultMessage="{groupCount} {groupCount, plural, one {group} other {groups}}
            exchanged questions, leading to {subject}"
            values={{ groupCount, subject }}
          />
        )
        break
      case 'selfReferential':
        heading = (
          <FormattedMessage
            id="owner.InteractionPage.heading.selfReferential"
            defaultMessage="{subject} exchanged questions within their own group"
            values={{ subject }}
          />
        )
        break
      case 'disconnected':
        heading = (
          <FormattedMessage
            id="owner.InteractionPage.heading.disconnected"
            defaultMessage="{subject} didn’t exchange questions with other groups"
            values={{ subject }}
          />
        )
        break
    }
    return (
      <Title>
        <span>{heading}</span>
      </Title>
    )
  }

  renderCardHeading(category) {
    const { intl } = this.props
    return (
      <Title>
        <Heading.h3>
          {intl.formatMessage(subjectMessages[category.key], { count: category.count })}
        </Heading.h3>
        <Typography variant="medium" transparent>
          {intl.formatMessage(descriptionMessages[category.key])}
        </Typography>
      </Title>
    )
  }

  renderColumnHeader(category, isOneWay) {
    const oneWay = (
      <strong>
        <FormattedMessage
          id="owner.InteractionPage.column.oneWay"
          defaultMessage="one-way"
        />
      </strong>
    )
    const twoWay = (
      <strong>
        <FormattedMessage
          id="owner.InteractionPage.column.twoWay"
          defaultMessage="two-way"
        />
      </strong>
    )
    const oneWayCap = (
      <strong>
        <FormattedMessage
          id="owner.InteractionPage.column.capitalizedOneWay"
          defaultMessage="One-way"
        />
      </strong>
    )
    const twoWayCap = (
      <strong>
        <FormattedMessage
          id="owner.InteractionPage.column.capitalizedTwoWay"
          defaultMessage="Two-way"
        />
      </strong>
    )
    switch (category.key) {
      case 'connected':
        return (
          <FormattedMessage
            id="owner.InteractionPage.column.connected"
            defaultMessage="{direction} questioning"
            values={{ direction: isOneWay ? oneWayCap : twoWayCap }}
          />
        )
      case 'stronglyConnected':
        return (
          <FormattedMessage
            id="owner.InteractionPage.column.stronglyConnected"
            defaultMessage="Strong {direction} questioning"
            values={{ direction: isOneWay ? oneWay : twoWay }}
          />
        )
      case 'selfReferential':
        return (
          <FormattedMessage
            id="owner.InteractionPage.column.selfReferential"
            defaultMessage="Self-referential groups"
          />
        )
      case 'disconnected':
        return (
          <FormattedMessage
            id="owner.InteractionPage.column.disconnected"
            defaultMessage="Disconnected groups"
          />
        )
    }
  }

  renderPairRows(edges, groups, colors) {
    return edges.map(edge => {
      const sourceGroup = groups.find(g => g.groupId === edge.source)
      const targetGroup = groups.find(g => g.groupId === edge.target)
      return (
        <GroupList.PairRow
          key={sourceGroup.groupId + targetGroup.groupId}
          group1={sourceGroup}
          group2={targetGroup}
          colors={colors}
        />
      )
    })
  }

  renderSingleSquare(nodes, groups, colors) {
    return nodes.map(node => {
      const group = groups.find(g => g.groupId === node.id)
      return (
        <GroupList.SingleSquare
          key={group.groupId}
          colors={colors}
          group={group}
        />
      )
    })
  }

  renderWideContent(category, groups, isTall, colors) {
    if (category.key === 'connected' || category.key === 'stronglyConnected') {
      const edges = category.edges
      const UnidirectionalEdges = edges.filter(e => e.isUniDirectional)
      const OneWayEdges = edges.filter(e => !e.isUniDirectional)
      const headingTwoWay = this.renderColumnHeader(category, false)
      const headingOneWay = this.renderColumnHeader(category, true)
      return (
        <Fragment>
          <TwoColumn>
            <CategoryImgWrapper>
              <InteractionIcon type={category.key} isOneWay={false} />
            </CategoryImgWrapper>
            <CategoryImgWrapper>
              <InteractionIcon type={category.key} isOneWay={true} />
            </CategoryImgWrapper>
          </TwoColumn>
          <TwoColumn>
            <div>
              <div>
                <ColumnHeader>
                  {isTall ? <Typography variant="medium" weight="light">{headingTwoWay}</Typography> : null}
                </ColumnHeader>
                <GroupList maxRows={isTall ? 5 : 2}>
                  {this.renderPairRows(UnidirectionalEdges, groups, colors)}
                </GroupList>
              </div>
            </div>
            <div>
              <div>
                <ColumnHeader>
                  {isTall ? <Typography variant="medium" weight="light">{headingOneWay}</Typography> : null}
                </ColumnHeader>
                <GroupList maxRows={isTall ? 5 : 2}>
                  {this.renderPairRows(OneWayEdges, groups, colors)}
                </GroupList>
              </div>
            </div>
          </TwoColumn>
        </Fragment>
      )
    } else {
      const heading = this.renderColumnHeader(category)
      return (
        <OneColumn>
          <CategoryImgWrapper >
            <InteractionIcon type={category.key} />
          </CategoryImgWrapper>
          <div>
            <ColumnHeader>
              {isTall ? <Typography variant="medium">{heading}</Typography> : null}
            </ColumnHeader>
            <GroupList maxRows={isTall ? 5 : 2} isSingles={true}>
              {this.renderSingleSquare(category.nodes, groups, colors)}
            </GroupList>
          </div>
        </OneColumn>
      )
    }
  }

  renderNarrowContent(category, groups, colors) {
    let children, isOneWay
    let edges = category.edges
    const isSingles = (category.key !== 'connected' && category.key !== 'stronglyConnected')
    if (!isSingles) {
      const UnidirectionalEdges = edges.filter(e => e.isUniDirectional)
      const oneWayEdges = edges.filter(e => !e.isUniDirectional)
      const hasMostTwoWay = (UnidirectionalEdges.length > oneWayEdges)
      edges = hasMostTwoWay ? UnidirectionalEdges : oneWayEdges
      isOneWay = !hasMostTwoWay
      children = this.renderPairRows(edges, groups, colors)
    } else {
      children = this.renderSingleSquare(category.nodes, groups, colors)
    }
    return (
      <OneColumn>
        <CategoryImgWrapper>
          <InteractionIcon type={category.key} isOneWay={isOneWay} />
        </CategoryImgWrapper>
        <GroupList isSingles={isSingles} maxRows={2}>
          {children}
        </GroupList>
      </OneColumn>
    )
  }

  render() {
    const { nodes, edges, groups, theme } = this.props
    const colors = makeGroupColorScales(theme, nodes.map(n => n.id)).primaryColors
    const categories = formatData(nodes, edges)
    const primaryCategory = categories[0]
    const secondaryCategory = categories[1]
    const tertiaryCategory = categories[2]
    const quaternaryCategory = categories[3]

    return (
      <Content>
        <MainCard highlighted>
          <MainCardLayout>
            <div>
              <Heading.h2>{this.renderMainHeading(primaryCategory)}</Heading.h2>
            </div>
            <WideDataLayout>
              {this.renderWideContent(primaryCategory, groups, true, colors)}
            </WideDataLayout>
          </MainCardLayout>
        </MainCard>
        <SubContent>
          <Card>
            <MediumCardLayout>
              {this.renderCardHeading(secondaryCategory)}
              <WideDataLayout>
                {this.renderWideContent(secondaryCategory, groups, false, colors)}
              </WideDataLayout>
            </MediumCardLayout>
          </Card>
          <Card>
            <NarrowCardLayout>
              {this.renderCardHeading(tertiaryCategory)}
              {this.renderNarrowContent(tertiaryCategory, groups, colors)}
            </NarrowCardLayout>
          </Card>
          <Card>
            <NarrowCardLayout>
              {this.renderCardHeading(quaternaryCategory)}
              {this.renderNarrowContent(quaternaryCategory, groups, colors)}
            </NarrowCardLayout>
          </Card>
        </SubContent>
      </Content>
    )
  }

  static propTypes = {
    nodes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string
    })),
    edges: PropTypes.arrayOf(PropTypes.shape({
      source: PropTypes.string,
      target: PropTypes.string,
      isOutlier: PropTypes.bool,
      isPotentialOutlier: PropTypes.bool
    })),
    groups: PropTypes.arrayOf(PropTypes.shape({
      groupId: PropTypes.string,
      name: PropTypes.string
    }))
  }
}

export default withTheme(injectIntl(AnalyticsInteraction))
