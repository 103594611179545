
// Hashing algorithm: djb2Code
export function stringToIntHash(str){
  let hash = 5381
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = ((hash << 5) + hash) + char /* hash * 33 + c */
  }
  return hash
}

// NOTE: At time of writing URLSearchParams has not reached sufficient adoption and hence the complexity below.
export function getQueryStringValue(key) {
  return decodeURIComponent(window.location.search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'))
}

export function groupHighlightedWords(content, highlights) {
  // If highlights empty simply return one group with entire content
  if (!highlights || highlights.length === 0) {
    return [{ content, highlighted: false }]
  }
  // ... otherwise, start grouping
  const re = /([,.?!])?([^,.?!]+)([,.?!])?/
  const words = content ? content.split(' ') : []
  const wordGroups = []
  let currentGroup = []
  words.forEach(w => {
    if (w) {
      // If word not group-able by regex, give up and simply add it to current group
      const match = w.match(re)
      if (!match) {
        currentGroup.push(w)
        return
      }
      // Otherwise, destructure into match-groups
      const [, prefix, word, postfix] = match
      if (highlights.indexOf(word.toLowerCase()) === -1) {
        // Word not highlighted, simply add it to current group
        currentGroup.push(w)
      } else {
        // Highlighted word found
        // If prefix found, push it to current group
        if (prefix) {
          currentGroup.push(prefix)
        }
        // Save current group (not highlighted)
        wordGroups.push({
          content: currentGroup.join(' '),
          highlighted: false
        })
        // Pad current word with spaces if no prefix/postfixes
        let content = word
        if (!prefix) {
          content = ' ' + content
        }
        if (!postfix) {
          content = content + ' '
        }
        // Save new group (highlighted) containing only current word
        wordGroups.push({
          content: content,
          highlighted: true
        })
        // Initialize new group
        currentGroup = []
        // If postfix found, add to new group
        if (postfix) {
          currentGroup.push(postfix)
        }
      }
    }
  })
  // If anything remains in current group, save it as a non-highlighted group
  if (currentGroup.length > 0) {
    wordGroups.push({
      content: currentGroup.join(' '),
      highlighted: false
    })
  }
  return wordGroups
}

export function compareAlphabetical(a, b) {
  return a.localeCompare(b)
}

export function makeFilename(qvestName, featureName, fileExtension) {
  const prefix = 'Qvest'
  // Remove characters that have not been whitelisted
  // (Most of latin alphabet, accents and spaces are OK)
  const forbiddenChars = new RegExp(/([^A-Za-zÀ-ÖØ-öø-ÿ ]+)/, 'im')
  qvestName = qvestName.replace(forbiddenChars, '')
  // Concatenate everything delimited by underscores
  return `${prefix}_${qvestName}_${featureName}.${fileExtension}`
}

const WHITESPACE_REGEX = /^\s*$/

export const isWhitespace = text => WHITESPACE_REGEX.test(text)
