
export const msToHour = ms => {
  const allMinutes = ms / 1000 / 60
  return {
    hours: Math.floor(allMinutes / 60),
    minutes: Math.floor(allMinutes % 60),
  }
}

export const hourToMs = (hours, minutes) => (hours * 60 + minutes) * 60 * 1000
