import React from 'react'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'

import ItemTypes from './ItemTypes'
import Card from './Card'
import ColumnHeader from './ColumnHeader'
import Typography from '../../common/Typography/Typography'
import Button from '../../common/Button/Button'
import { FormattedMessage } from 'react-intl'

const ComponentRoot = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 20px;
`

const CardList = styled.div`
  overflow-y: auto;
  height: calc(100vh - ${({ yOffset }) => yOffset}px);

  /* scrollbar styles for webkit compatible browsers */
  &::-webkit-scrollbar {
  	width: 8px;
    padding-left: 4px;
  	background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-track {
  	/* box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  	border-radius: 5px;
    background-color: ${props => props.inverted
    ? props.theme.inverted.two[1]
    : props.theme.inverted.one[1]};
  }
  &::-webkit-scrollbar-thumb {
  	border-radius: 5px;
  	/* box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  	background-color: ${props => props.inverted
    ? props.theme.inverted.two[2]
    : props.theme.inverted.one[2]};
  }
`

const SearchDisclaimerSection = styled.div`
  text-align: center;
  padding: 0 30px 10px 30px;
`

const SearchDisclaimer = ({ inverted, hiddenCount, onClear }) => {
  if (!hiddenCount) return null
  let hiddenCountText = (
    <strong>
      <FormattedMessage
        id="owner.AnalyticsThemes.Column.searchDisclaimer.hiddenCountText"
        defaultMessage="{hiddenCount} {hiddenCount, plural, one {question} other {questions} } hidden"
        values={{ hiddenCount }}
      />
    </strong>
  )
  return (
    <SearchDisclaimerSection>
      <Typography.p secondary variant="medium" inverted={inverted} weight="light">
        <FormattedMessage
          id="owner.AnalyticsThemes.Column.searchDisclaimer.description"
          defaultMessage="{hiddenCountText} because {hiddenCount, plural, one {it doesn't} other {they don't} } match search criteria"
          values={{ hiddenCountText, hiddenCount }}
        />
      </Typography.p>
      <Button inverted={inverted} outline onClick={onClear}>
        <FormattedMessage defaultMessage="Clear search" id="owner.AnalyticsThemes.Column.searchDisclaimer.button" />
      </Button>
    </SearchDisclaimerSection>
  )
}

const Header = ({ theme, disabled, focusable, isLocked, onStartEdit, onEdit, onDone, onCancel, onDelete, onToggleCollapsed, onSort }) => {
  if (isLocked) return null
  return (
    <ColumnHeader
      theme={theme}
      disabled={disabled}
      focusable={focusable}
      onStartEdit={onStartEdit}
      onEdit={onEdit}
      onDone={onDone}
      onCancel={onCancel}
      onDelete={onDelete}
      onSort={onSort}
      onToggleCollapsed={onToggleCollapsed}
    />
  )
}

const Body = ({ inverted, questions, disabled, yOffset, theme, unfilteredCount, onCardHovered, onThemeHovered, onQuickMove, onClearSearch, onCardDrop }) => {
  let displayedQuestions = !theme.isCollapsed ? questions : []
  let hiddenCount = null
  if (unfilteredCount) {
    hiddenCount = (unfilteredCount - questions.length)
  }

  // Configure as a drop target for cards
  // (Only subscribing to hover events, drop events are propagated to parent)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true })
    }),
    // Activated when question cards are hovered over empty list or below cards currently in list
    hover: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        onThemeHovered(item.questionId, theme, false)
      }
    }
  })

  return (
    <CardList ref={drop} yOffset={yOffset} inverted={inverted}>
      {displayedQuestions.map(question => (
        <Card
          disabled={disabled}
          inverted={inverted}
          key={question.questionId}
          theme={theme}
          question={question}
          onHovered={onCardHovered}
          onDrop={onCardDrop}
          onQuickMove={onQuickMove}
        />
      ))}
      <SearchDisclaimer inverted={inverted} hiddenCount={hiddenCount} onClear={onClearSearch} />
    </CardList>
  )
}

const Column = (props) => {
  const { theme, onClick } = props

  // Configure as a drop target for cards
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true })
    }),
    // Activated when question cards are hovered above the header of the column (children intercept other areas)
    hover: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        props.onThemeHovered(item.questionId, theme, true)
      }
    },
    // Activated when questions a dropped anywhere inside the column
    drop: () => {
      return { themeId: theme.themeId }
    },
  })

  const handleClick = () => {
    if (onClick) {
      onClick(theme.themeId)
    }
  }

  return (
    <div ref={drop}>
      <ComponentRoot onClick={handleClick}>
        <Header {...props} />
        <Body {...props} isOver={isOver} />
      </ComponentRoot>
    </div>
  )
}

export default Column
