import Immutable from 'immutable'

let INITIAL_STATE = Immutable.fromJS({
  displayed: null
})


const notification = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'RAISE_NOTIFICATION':
      return state.set('displayed', action.notification)
    case 'CLEAR_NOTIFICATION':
      return state.set('displayed', null)
    default:
      return state
  }
}


export default notification
