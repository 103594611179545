import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import orderBy from 'lodash/orderBy'

import Heading from '../../common/Typography/Heading'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'
import QvestActivityItem from '../QvestActivityItem/QvestActivityItem'
import { prioritizeQuestions, getLatestActivityDate } from '../../../utils/questionUtils'
import partition from 'lodash/partition'


const ComponentRoot = styled.div`
  & > div { margin-bottom: 50px; }
  & > h3 { margin-bottom: 20px; }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px 5px -10px;
  & > * { margin: 10px; }

  @media (min-width: 700px) {
    margin: -15px -15px 5px -15px;
    & > * { margin: 15px; }
  }
`


const sortQuestions = (questions) => {
  const [locked, others] = partition(questions, q => q.status === 'LOCKED')
  return [
    ...orderBy(others, getLatestActivityDate, 'desc'),
    ...orderBy(locked, getLatestActivityDate, 'asc')
  ]
}

const renderQuestions = (qvestId, questions, isQvestClosed) => questions
  .map(question =>
    <QvestActivityItem
      key={question.questionId}
      qvestId={qvestId}
      question={question}
      isQvestClosed={isQvestClosed}
    />
  )

const QvestActivity = ({ qvestId, questions, qvestState }) => {
  const hasQuestions = (questions != null)
  const isQvestClosed = (qvestState === 'QVEST_CLOSED')

  let { outstanding, completed } = prioritizeQuestions(questions)
  outstanding = sortQuestions(outstanding)
  completed = sortQuestions(completed)

  return (
    <ComponentRoot>
      <Heading.h3>
        <FormattedMessage
          id="QvestActivity.tasksHeading"
          defaultMessage="Tasks ({count})"
          values={{ count: outstanding.length }}
        />
      </Heading.h3>
      <Grid>
        {hasQuestions ? renderQuestions(qvestId, outstanding, isQvestClosed) : null}
      </Grid>
      <Heading.h3>
        <FormattedMessage
          id="QvestActivity.doneHeading"
          defaultMessage="Done ({count})"
          values={{ count: completed.length }}
        />
      </Heading.h3>
      <Grid>
        {hasQuestions ? renderQuestions(qvestId, completed, isQvestClosed) : null}
        {!hasQuestions ? <CenteredLoader /> : null}
      </Grid>
    </ComponentRoot>
  )
}

export default QvestActivity
