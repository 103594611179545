import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'

import Button from '../../common/Button/Button'
import Input from '../../common/Input/Input'
import Checkbox from '../../common/Checkbox/Checkbox'
import Anchor from '../../common/Anchor/Anchor'

const errorMessages = defineMessages({
  NAME_CONFLICT: { id: 'qvest.JoinQvestForm.error.NAME_CONFLICT', defaultMessage: 'Player with this name already joined. Pick a different name.' },
  QVEST_OPENED: { id: 'qvest.JoinQvestForm.error.QVEST_OPENED', defaultMessage: 'Too late! Jam has started and no longer accepts players.' }
})

const WrapperForm = styled.form`
  display: ${({ show }) => show ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-start;

  & > :last-child {
    align-self: flex-end;
  }
`

const FieldSet = styled.fieldset`
  display: block;
  padding: 0;
  border: none;
  width: 100%;
  margin-bottom: 20px;
`

const HugeInput = styled(Input)`
  font-family: ${({ theme }) => theme.font4};
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding: 10px;
`

const Label = styled.label`
  font-family: ${({ theme }) => theme.font2};
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.one[0]};
  margin-bottom: 5px;
`

const ErrorWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  background-color: rgba(248, 105, 92, .2);
  color: #f8695c;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
`

const ErrorText = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-family: ${props => props.theme.font4};
`

const ErrorMessage = injectIntl(({ error, intl }) => {
  if (!errorMessages.hasOwnProperty(error)) return null
  return (
    <ErrorWrapper>
      <ErrorText>
        {intl.formatMessage(errorMessages[error])}
      </ErrorText>
    </ErrorWrapper>
  )
})

const SubmitButton = ({ disabled, loading }) => {
  if (loading) {
    return (
      <Button disabled size="large">
        <FormattedMessage id="qvest.JoinQvestForm.button.loading" defaultMessage="Loading..." />
      </Button>
    )
  }
  return (
    <Button disabled={disabled} size="large">
      <FormattedMessage id="qvest.JoinQvestForm.button.submit" defaultMessage="Ready to Jam!" />
    </Button>
  )
}

const JoinQvestForm = ({ show, error, loading, onSubmit }) => {
  const [name, setName] = useState(null)
  const [consent, setConsent] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit({ name })
  }

  const termsLink = (
    <Anchor href="https://questionjam.com/terms" target="_blank" rel="noopener noreferrer">
      <FormattedMessage defaultMessage="Terms of Service" id="qvest.JoinQvestForm.consent.termsLink" />
    </Anchor>
  )

  return (
    <WrapperForm show={show} onSubmit={handleSubmit}>
      <FieldSet>
        <Label htmlFor="name">
          <FormattedMessage id="qvest.JoinQvestForm.label.name" defaultMessage="Your name" />:
        </Label>
        <HugeInput
          id="name"
          type="text"
          onChange={({ target }) => setName(target.value)}
          value={name}
        />
      </FieldSet>
      <FieldSet>
        <Label htmlFor="consent">
          <Checkbox id="consent" onChange={() => setConsent(!consent)} checked={consent}>
            <FormattedMessage
              id="qvest.JoinQvestForm.consent"
              defaultMessage="I agree to the {termsLink}"
              values={{ termsLink }}
            />
          </Checkbox>
        </Label>
      </FieldSet>
      <ErrorMessage error={error} />
      <SubmitButton disabled={!name || !consent} loading={loading} />
    </WrapperForm>
  )
}

JoinQvestForm.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default JoinQvestForm
