import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const LINE_HEIGHT = 22

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`

const Input = styled.textarea`
  padding: 0;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
  resize: none;
  border-style: none;
  font-family: ${({ theme }) => theme.font4};
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.one[0]};
  line-height: ${LINE_HEIGHT}px;
  caret-color: ${({ theme }) => theme.color[0]};

  &::placeholder {
    color: ${({ theme }) => theme.one[2]};
    padding: 0 12px;
    border-left: 1px solid ${({ theme }) => theme.color[0]};
    height: 18px;
  }
  &:focus {
    outline: none !important;
  }
  &:focus::placeholder {
    color: transparent;
    border: none;
  }
`

class AskInput extends Component {
  constructor(props) {
    super(props)
    this.state = { rows: props.minRows }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)

    // expand for long texts
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const { minRows } = this.props
    const prevRows = this.node.rows

    // render text area with min # of rows, so scrollHeight can take its minimum value
    if (prevRows > minRows) this.node.rows = minRows

    // calculate ideal number of rows that could contain the text
    const nextRows = Math.ceil((this.node.scrollHeight) / LINE_HEIGHT)

    // if rows don't change, setState won't trigger a render cycle
    if (nextRows === prevRows && nextRows > minRows) this.node.rows = nextRows

    this.setState({ rows: nextRows })
  }

  handleChange = (event) => {
    const { onChange } = this.props
    this.handleResize()
    onChange(event)
  }

  render() {
    const { placeholder, value, disabled } = this.props
    return (
      <Wrapper>
        <Input
          ref={x => { this.node = x }}
          placeholder={placeholder}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onChange={this.handleChange}
          value={value}
          disabled={disabled}
          rows={this.state.rows}
        />
      </Wrapper>
    )
  }

  static propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    minRows: PropTypes.number,
  }

  static defaultProps = {
    onFocus: () => { },
    onBlur: () => { },
    onChange: () => { },
    placeholder: '',
    minRows: 5,
    disabled: false,
  }
}

export default AskInput
