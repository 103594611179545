import Immutable from 'immutable'

let INITIAL_STATE = Immutable.fromJS({
  wordFrequencies: {
    isLoading: false,
    data: {}
  },
  groupGraph: {
    isLoading: false,
    data: {}
  },
  questionDistribution: {
    isLoading: false,
    data: {}
  },
  qvestSummary: {
    isLoading: false,
    data: {}
  },
  questionDump: {
    isLoading: false,
    data: {}
  },
  participation: {
    isLoading: false,
    data: {}
  },
  participantsActivity: {
    isLoading: false,
    data: {}
  }
})

const wordFrequencies = (state = {}, action) => {
  switch (action.type) {
    case 'GET_WORD_FREQUENCIES_REQUEST':
      return state.merge(state, {
        isLoading: true
      })
    case 'GET_WORD_FREQUENCIES_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(action.qvestId, action.frequencies)
      })
    case 'GET_WORD_FREQUENCIES_FAILURE':
      return state.merge(state, {
        isLoading: false
      })
    default:
      return state
  }
}

const groupGraph = (state = {}, action) => {
  switch (action.type) {
    case 'GET_GROUP_GRAPH_REQUEST':
      return state.merge(state, {
        isLoading: true
      })
    case 'GET_GROUP_GRAPH_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(action.qvestId, action.graph)
      })
    case 'GET_GROUP_GRAPH_FAILURE':
      return state.merge(state, {
        isLoading: false
      })
    default:
      return state
  }
}

const questionDistribution = (state = {}, action) => {
  switch (action.type) {
    case 'GET_QUESTION_DISTRIBUTION_REQUEST':
      return state.merge(state, {
        isLoading: true
      })
    case 'GET_QUESTION_DISTRIBUTION_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(action.qvestId, action.distribution)
      })
    case 'GET_QUESTION_DISTRIBUTION_FAILURE':
      return state.merge(state, {
        isLoading: false
      })
    default:
      return state
  }
}

const qvestSummary = (state = {}, action) => {
  switch (action.type) {
    case 'GET_QVEST_SUMMARY_REQUEST':
      return state.merge(state, {
        isLoading: true
      })
    case 'GET_QVEST_SUMMARY_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(action.qvestId, action.summary)
      })
    case 'GET_QVEST_SUMMARY_FAILURE':
      return state.merge(state, {
        isLoading: false
      })
    default:
      return state
  }
}

const questionDump = (state = {}, action) => {
  switch (action.type) {
    case 'GET_QUESTION_DUMP_REQUEST':
      return state.merge(state, {
        isLoading: true
      })
    case 'GET_QUESTION_DUMP_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(action.qvestId, action.distribution)
      })
    case 'GET_QUESTION_DUMP_FAILURE':
      return state.merge(state, {
        isLoading: false
      })
    default:
      return state
  }
}

const participation = (state = {}, action) => {
  switch (action.type) {
    case 'GET_PARTICIPATION_REQUEST':
      return state.merge(state, {
        isLoading: true
      })
    case 'GET_PARTICIPATION_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(action.qvestId, action.activity)
      })
    case 'GET_PARTICIPATION_FAILURE':
      return state.merge(state, {
        isLoading: false
      })
    default:
      return state
  }
}

const participantsActivity = (state = {}, action) => {
  switch (action.type) {
    case 'GET_PARTICIPANTS_ACTIVITY_REQUEST':
      return state.merge(state, {
        isLoading: true
      })
    case 'GET_PARTICIPANTS_ACTIVITY_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(action.qvestId, action.activity)
      })
    case 'GET_PARTICIPANTS_ACTIVITY_FAILURE':
      return state.merge(state, {
        isLoading: false
      })
    default:
      return state
  }
}

const stats = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_WORD_FREQUENCIES_REQUEST':
    case 'GET_WORD_FREQUENCIES_SUCCESS':
    case 'GET_WORD_FREQUENCIES_FAILURE':
      return state.set('wordFrequencies',
        wordFrequencies(state.get('wordFrequencies'), action))
    case 'GET_GROUP_GRAPH_REQUEST':
    case 'GET_GROUP_GRAPH_SUCCESS':
    case 'GET_GROUP_GRAPH_FAILURE':
      return state.set('groupGraph',
        groupGraph(state.get('groupGraph'), action))
    case 'GET_QUESTION_DISTRIBUTION_REQUEST':
    case 'GET_QUESTION_DISTRIBUTION_SUCCESS':
    case 'GET_QUESTION_DISTRIBUTION_FAILURE':
      return state.set('questionDistribution',
        questionDistribution(state.get('questionDistribution'), action))
    case 'GET_QVEST_SUMMARY_REQUEST':
    case 'GET_QVEST_SUMMARY_SUCCESS':
    case 'GET_QVEST_SUMMARY_FAILURE':
      return state.set('qvestSummary',
        qvestSummary(state.get('qvestSummary'), action))
    case 'GET_QUESTION_DUMP_REQUEST':
    case 'GET_QUESTION_DUMP_SUCCESS':
    case 'GET_QUESTION_DUMP_FAILURE':
      return state.set('questionDump',
        questionDump(state.get('questionDump'), action))
    case 'GET_PARTICIPATION_REQUEST':
    case 'GET_PARTICIPATION_SUCCESS':
    case 'GET_PARTICIPATION_FAILURE':
      return state.set('participation',
        participation(state.get('participation'), action))
    case 'GET_PARTICIPANTS_ACTIVITY_REQUEST':
    case 'GET_PARTICIPANTS_ACTIVITY_SUCCESS':
    case 'GET_PARTICIPANTS_ACTIVITY_FAILURE':
      return state.set('participantsActivity',
        participantsActivity(state.get('participantsActivity'), action))
    default:
      return state
  }
}

export function filterWordFrequenciesByQvestId(wordFrequencies, qvestId) {
  if (!wordFrequencies.data.hasOwnProperty(qvestId)) {
    return null
  }
  return wordFrequencies.data[qvestId]
}

export function filterGroupGraphByQvestId(groupGraph, qvestId) {
  if (!groupGraph.data.hasOwnProperty(qvestId)) {
    return null
  }
  return groupGraph.data[qvestId]
}

export function filterQuestionDistributionByQvestId(questionDistribution, qvestId) {
  if (!questionDistribution.data.hasOwnProperty(qvestId)) {
    return null
  }
  return questionDistribution.data[qvestId]
}

export function filterQuestionDumpByQvestId(questionDump, qvestId) {
  if (!questionDump.data.hasOwnProperty(qvestId)) {
    return null
  }
  return questionDump.data[qvestId]
}

export function filterQuestionDumpByQvestIdDict(questionDump, qvestId) {
  if (!questionDump.data.hasOwnProperty(qvestId)) {
    return null
  }
  let dump = filterQuestionDumpByQvestId(questionDump, qvestId)
  dump = dump.filter(question => question.questionContent != null)
  return dump.reduce((obj, question) => { obj[question.questionId] = question; return obj }, {})
}

export function filterQvestSummaryByQvestId(qvestSummary, qvestId) {
  if (!qvestSummary.data.hasOwnProperty(qvestId)) {
    return null
  }
  return qvestSummary.data[qvestId]
}

export function filterParticipationByQvestId(participation, qvestId) {
  if (!participation.data.hasOwnProperty(qvestId)) {
    return null
  }
  return participation.data[qvestId]
}

export function filterParticipantsActivityByQvestId(participantsActivity, qvestId) {
  if (!participantsActivity.data.hasOwnProperty(qvestId)) {
    return null
  }
  return participantsActivity.data[qvestId]
}


export default stats
