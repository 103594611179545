import React, { Component } from 'react'
import styled from 'styled-components'

import DayPicker from '../DayPicker/DayPicker'
import HourPicker from '../HourPicker/HourPicker'
import isSameDay from 'date-fns/isSameDay'
import { hourToMs } from '../../../utils/timeUtils'


const ComponentRoot = styled.div`
  display: flex;

  & > :not(:last-child) {
    margin-right: 26px;
  }
`


const DEFAULT_HOUR = 43200000 // ms (12 hours)

const coerceDate = (date, lowerBound, upperBound) => {
  if (date < lowerBound) {
    return lowerBound
  }
  if (date > upperBound) {
    return upperBound
  }
  return date
}

const coerceHour = (date, hour, lowerBound, upperBound) => {
  if(lowerBound && isSameDay(date, lowerBound)) {
    const hourLowerBound = hourToMs(lowerBound.getHours(), lowerBound.getMinutes())
    if (hourLowerBound > hour)
      return hourLowerBound
  }
  if(upperBound && isSameDay(date, upperBound)) {
    const hourUpperBound = hourToMs(upperBound.getHours(), upperBound.getMinutes())
    if (hourUpperBound < hour)
      return hourUpperBound
  }
  return hour
}

class DateTimeInput extends Component {
  constructor(props) {
    super(props)

    const value = props.value
    if (value) {
      this.state = {
        hour: hourToMs(value.getHours(), value.getMinutes()),
        date: value
      }
    } else {
      this.state = {
        hour: undefined,
        date: undefined
      }
    }
  }

  componentDidUpdate(prevProps) {
    // update on prop change
    const value = this.props.value
    if (value !== prevProps.value) {
      this.setState({
        hour: hourToMs(value.getHours(), value.getMinutes()),
        date: value,
      })
    }
  }

  handleChange = (date, hour) => {
    const { onChange, value, lowerBound, upperBound } = this.props

    if(!date) {
      date = value
    }

    if(!hour && date) {
      hour = DEFAULT_HOUR
    }

    date = coerceDate(date, lowerBound, upperBound)
    hour = coerceHour(date, hour, lowerBound, upperBound)

    if (date && onChange) {
      const dateNoHour = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      const newDate = new Date(dateNoHour.getTime() + hour)
      onChange(newDate)
      this.setState({ date, hour })
    }
  }

  handleHourChange = (value) => {
    this.setState({ hour: value }, () => this.handleChange(this.state.date, value))
  }

  handleDateChange = (date) => {
    this.setState({ date }, () => this.handleChange(date, this.state.hour))
  }

  render() {
    const { value, markedDays, lowerBound, upperBound, disabled } = this.props

    let hour
    if (this.state.hour) {
      hour = this.state.hour
    } else if (value) {
      hour = hourToMs(value.getHours(), value.getMinutes())
    } else {
      hour = DEFAULT_HOUR
    }

    let date
    if (this.state.date) {
      date = this.state.date
    } else if (value) {
      date = value
    }

    const modifiers = {}
    if (markedDays) modifiers.marked = markedDays

    let disabledDays = []
    if (upperBound) {
      disabledDays = disabledDays.concat({ after: upperBound })
    }
    if (lowerBound) {
      disabledDays = disabledDays.concat({ before: lowerBound })
    }

    let hourUpperBound
    if(isSameDay(date, upperBound)) {
      hourUpperBound = hourToMs(upperBound.getHours(), upperBound.getMinutes())
    }

    let hourLowerBound
    if(isSameDay(date, lowerBound)) {
      hourLowerBound = hourToMs(lowerBound.getHours(), lowerBound.getMinutes())
    }

    return (
      <ComponentRoot>
        <DayPicker
          value={date}
          markedDays={markedDays}
          disabledDays={disabledDays}
          disabled={disabled}
          onChange={this.handleDateChange}
        />
        <HourPicker
          key={date && date.toDateString() /* Recycle if date changes such that upper/lower bounds are enforced */}
          value={hour}
          upperBound={hourUpperBound}
          lowerBound={hourLowerBound}
          disabled={disabled}
          onChange={this.handleHourChange}
        />
      </ComponentRoot>
    )
  }
}

export default DateTimeInput
