import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, FormattedRelative } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import Icon from '../../common/Icon/Icon'
import Card from '../../common/Card/Card'
import Button from '../../common/Button/Button'
import Table from '../../common/Table/Table'

const ComponentRoot = styled.div`
  width: 620px;
`

const CloseButton = styled.button`
  background-color: transparent;
  border-style: none;
  cursor: pointer;
  /* phasing out clickable prop on Icon for better accessibility */
  && > * { cursor: pointer; }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 110px;
  & > * + * { margin-left: 20px; }
`

const Row = styled.tr`
  & > *:first-child {
    padding: 15px 20px;
    vertical-align: top;
  }
  & > *:last-child {
    padding: 15px 20px 15px 0;
    text-align: right;
  }
`

const MultilineCell = styled.td`
  max-width: 250px;
  & > * { display: block; }
  & > * + * { margin-top: 15px; }
`

const isPast = (date) => (date.getTime() < (new Date()).getTime())

const renderTable = ({ participantCount, groups, topic, inviteAt, openAt, waveAt, closeAt }) => {
  const groupDetails = (groups.length !== 0)
    ? <Typography variant="caption" transparent>{groups.map(g => g.name).join(', ')}</Typography>
    : null

  let openDetails = '-'
  if (openAt) {
    const isPastOpen = isPast(openAt)
    openAt = isPastOpen ? new Date() : openAt // If past, simply show "now"
    openDetails = <FormattedRelative value={openAt} updateInterval={isPastOpen ? false : 10000}  />
  }

  let closeDetails = '-'
  if (closeAt) {
    closeAt = isPast(closeAt) ? new Date() : closeAt // If past, simply show "now"
    closeDetails = <FormattedRelative value={closeAt}  />
  }

  let inviteRow
  if (inviteAt) {
    const isPastInvite = isPast(inviteAt)
    inviteAt = isPastInvite ? new Date() : inviteAt // If past, simply show "now"
    inviteRow = (
      <Row>
        <td>
          <Typography variant="caption">
            <FormattedMessage
              id="owner.SummaryModal.invite"
              defaultMessage="Invitation email will be sent"
            />:
          </Typography>
        </td>
        <td>
          <Heading variant="heading4">
            <FormattedRelative value={inviteAt} updateInterval={isPastInvite ? false : 10000} />
          </Heading>
        </td>
      </Row>
    )
  }

  let wavesRow
  if (waveAt) {
    wavesRow = (
      <Row>
        <td>
          <Typography variant="caption">
            <FormattedMessage
              id="owner.SummaryModal.wave"
              defaultMessage="Extra question wave"
            />:
          </Typography>
        </td>
        <MultilineCell>
          <Heading variant="heading4">Yes</Heading>
          <Typography variant="caption" transparent>
            <FormattedRelative value={waveAt} />
          </Typography>
        </MultilineCell>
      </Row>
    )
  }

  return (
    <Table odd>
      <tbody>
        <Row>
          <td>
            <Typography variant="caption">
              <FormattedMessage
                id="owner.SummaryModal.participants"
                defaultMessage="Participants"
              />:
            </Typography>
          </td>
          <td>
            <Heading variant="heading4">{participantCount}</Heading>
          </td>
        </Row>
        <Row>
          <td>
            <Typography variant="caption">
              <FormattedMessage
                id="owner.SummaryModal.groups"
                defaultMessage="Groups"
              />:
            </Typography>
          </td>
          <MultilineCell>
            <Heading variant="heading4">{groups.length}</Heading>
            {groupDetails}
          </MultilineCell>
        </Row>
        <Row>
          <td>
            <Typography variant="caption">
              <FormattedMessage
                id="owner.SummaryModal.topic"
                defaultMessage="Topic"
              />:
            </Typography>
          </td>
          <td>
            <Heading variant="heading4">{topic}</Heading>
          </td>
        </Row>
        {inviteRow}
        <Row>
          <td>
            <Typography variant="caption">
              <FormattedMessage
                id="owner.SummaryModal.open"
                defaultMessage="Your Qvest opens"
              />:
            </Typography>
          </td>
          <td>
            <Heading variant="heading4">{openDetails}</Heading>
          </td>
        </Row>
        {wavesRow}
        <Row>
          <td>
            <Typography variant="caption">
              <FormattedMessage
                id="owner.SummaryModal.close"
                defaultMessage="Your Qvest closes"
              />:
            </Typography>
          </td>
          <td>
            <Heading variant="heading4">{closeDetails}</Heading>
          </td>
        </Row>
      </tbody>
    </Table>
  )
}

const SummaryModal = (props) => {
  const { onClose, onConfirm } = props
  return (
    <ComponentRoot>
      <Card>
        <Card.Header>
          <Heading variant="heading3">
            <FormattedMessage id="owner.SummaryModal.title" defaultMessage="Your Qvest Summary" />
          </Heading>
          <CloseButton onClick={onClose}>
            <Icon variant="times" />
          </CloseButton>
        </Card.Header>
        {renderTable(props)}
        <Card.Body>
          <ButtonWrapper>
            <Button onClick={onClose} outline>
              <FormattedMessage id="owner.SummaryModal.back" defaultMessage="Go back" />
            </Button>
            <Button onClick={onConfirm}>
              <FormattedMessage id="owner.SummaryModal.confirm" defaultMessage="Confirm" />
            </Button>
          </ButtonWrapper>
        </Card.Body>
      </Card>
    </ComponentRoot>
  )
}

SummaryModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  participantCount: PropTypes.number,
  groups: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })),
  topic: PropTypes.string,
  inviteAt: PropTypes.instanceOf(Date),
  openAt: PropTypes.instanceOf(Date),
  waveAt: PropTypes.instanceOf(Date),
  closeAt: PropTypes.instanceOf(Date)
}

SummaryModal.defaultProps = {
  onClose: () => { },
  onConfirm: () => { },
  participantCount: 0,
  groups: [],
  topic: '-',
}

export default SummaryModal
