import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage, FormattedRelative } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'

const ComponentRoot = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.two[0]};
  border-color: ${({ theme }) => theme.two[1]};
  border-style: solid;
  border-width: 0 0 1px 0;
  & > div > * { width: 100%; }
  & > div {
    padding: 0 40px;
    max-width: 1200px;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    & > div {
      padding: 0 20px;
    }
  }
`

const DetailsBar = styled.div`
  display: flex;
  padding: 30px 0;
  height: 100px;
  & > * + * { margin-left: 40px; }
  & > *:first-child { margin-right: auto; }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 0;
    height: auto;
    & > * + * { margin-left: 0; }
    & > * { margin-top: 30px; }
  }
  @media (max-width: 500px) {
    & > *:last-child { width: 100%; }
  }
`

const DetailsItem = styled.div`
  text-align: right;
  & > *:first-child { margin-bottom: 5px; }
  & > * { display: block; }
  & > a { text-decoration: none; }

  @media (max-width: 800px) {
    text-align: left;
  }
`

const Title = styled.div`
  & > *:last-child { display: none; }
  @media (max-width: 800px) {
    width: 100%;
    & > *:first-child { display: none; }
    & > *:last-child { display: block; }
  }
`

const Header = props => {
  const { schedule, contactInfo } = props
  let expireAt, closeAt
  if (schedule) {
    expireAt = schedule.expireAt
    closeAt = schedule.closeAt
  }
  const { email, name } = contactInfo
  return (
    <ComponentRoot>
      <div>
        <DetailsBar>
          <Title>
            <Heading variant="heading1">
              <FormattedMessage id="result.Header.output" defaultMessage="Qvest output" />
            </Heading>
            <Heading variant="heading2">
              <FormattedMessage id="result.Header.output" defaultMessage="Qvest output" />
            </Heading>
          </Title>
          {closeAt && <DetailsItem>
            <Typography variant="caption">
              <FormattedMessage id="result.Header.endedAt" defaultMessage="Qvest ended" />
            </Typography>
            <Heading variant="heading4">
              <FormattedRelative value={closeAt} />
            </Heading>
          </DetailsItem>}
          {expireAt && <DetailsItem>
            <Typography variant="caption">
              <FormattedMessage id="result.Header.outputClosedAt" defaultMessage="Output page will close" />
            </Typography>
            <Heading variant="heading4">
              <FormattedRelative value={expireAt} />
            </Heading>
          </DetailsItem>}
          {email && <DetailsItem>
            <Typography variant="caption">
              <FormattedMessage id="result.Header.contact" defaultMessage="Contact" />
            </Typography>
            <a href={`mailto:${email}`}>
              <Heading primary variant="heading4">
                {name}
              </Heading>
            </a>
          </DetailsItem>}
        </DetailsBar>
      </div>
    </ComponentRoot>
  )
}

Header.defaultProps = {
  schedule: {},
  contactInfo: {},
}

Header.propTypes = {
  contactInfo: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  schedule: PropTypes.shape({
    closeAt: PropTypes.instanceOf(Date),
    expireAt: PropTypes.instanceOf(Date),
  })
}

export default Header
