import React from 'react'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'
import ItemTypes from './ItemTypes'


const ComponentRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 69px;
  border-radius: 4px;
  border: 3px solid ${({ theme, isOver }) => isOver ? theme.main.secondary : theme.main.primary};
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme, isOver }) => isOver ? theme.main.secondary : 'none'};

  & > * {
    color: ${({ theme, isOver }) => isOver ? theme.tertiary : theme.main.primary};
  }

  &:hover {
    background-color: ${({ theme }) => theme.main.secondary};
    border-color: ${({ theme }) => theme.main.secondary};

    & > * {
      color: ${({ theme }) => theme.tertiary};
    }
  }
  &:active {
    opacity: 0.4;
  }
`

const CreateColumn = ({ show, onAdd, onThemeHovered, handleColumnFocused }) => {
  // Configure as a drop source for cards
  // (Activated when question cards are dragged create button/area)
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    drop: ({ questionId, question }) => {
      onAdd(questionId, question.position)
      return { themeId: 'new' }
    },
    hover: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        onThemeHovered(item.questionId)
      }
    }
  })

  const handleClick = () => {
    handleColumnFocused()
    onAdd()
  }

  // Hide if show prop not set
  if (!show) return null
  // Render
  return (
    <div ref={drop}>
      <ComponentRoot onClick={() => handleClick()} isOver={isOver}>
        <Typography variant="heading4" secondary>Add Theme</Typography>
      </ComponentRoot>
    </div>
  )
}

export default CreateColumn
