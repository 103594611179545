import React from 'react'
import { defineMessages } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import Icon from '../../common/Icon/Icon'


const MAIL_NOT_DELIVERED_STATUS = ['bounce', 'dropped']
const mailDeliveryStatusMessages = defineMessages({
  bounce: { defaultMessage: 'Email address appears to be invalid.', id: 'owner.ParticipantModal.error.bounce' },
  dropped: { defaultMessage: 'The receiving server denied the invitation/opening email.', id: 'owner.ParticipantModal.error.dropped' }
})

export const generateErrorMessage = (participant, intl) => {
  if (!participant.lastMailEvent || (MAIL_NOT_DELIVERED_STATUS.indexOf(participant.lastMailEvent.eventName) === -1))
    return null
  const event = participant.lastMailEvent
  const text = intl.formatMessage(mailDeliveryStatusMessages[event.eventName])
  return {
    type: 'error',
    content: (
      <Typography tertiary>
        <Icon tertiary variant="exclamation-circle" />
        {' '}
        <span>{text}</span>
      </Typography>
    )
  }
}
