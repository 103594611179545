import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

import * as theme from '../../theme'

const ComponentRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > *:last-child > *:last-child { margin-left: 10px; }
  & > *:first-child {
    margin-right: 20px;
    text-align: right;
  }
  & > *:last-child {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  /* locally reduce line height */
  & > :first-child { line-height: 18px; }
`

const NextBar = ({ children }) => (
  <ThemeProvider theme={theme.yellow}>
    <ComponentRoot>
      {children}
    </ComponentRoot>
  </ThemeProvider>
)

export default NextBar
