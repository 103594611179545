import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import styled from 'styled-components'

import Typography from '../Typography/Typography'
import Icon from '../Icon/Icon'

const ComponentRoot = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid ${({ theme, isOpen }) => isOpen ? theme.color[0] : theme.two[1]};
  border-radius: 3px;
  ${({ isOpen, theme }) => isOpen ? `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: ${theme.two[0]};
  ` : ''}
  background-color: white;
  padding: 8px 12px;
  outline: none;
  cursor: pointer;
  & > * + * { margin-left: 20px; }
  &:focus, &:hover {
    border-color: ${props => props.theme.color[0]};
    /* this keeps the button from jumping around vertically */
    ${({ isOpen, theme }) => isOpen ? `
      border-bottom-color: ${theme.two[0]};
    ` : ''}
  }
`

const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  border: 1px solid ${({ theme }) => theme.color[0]};
  background-color: ${({ theme }) => theme.two[0]};
  border-top-style: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  & > *:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  max-height: 300px;
  overflow-y: auto;
`

const MenuItem = styled.div`
  padding: 6px 12px;
  background-color: ${({ selected, highlighted, theme }) => highlighted
    ? theme.color[3]
    : (selected ? '#f6f6f6' : 'inherit')};
  & ${Typography} {
    ${({ highlighted, theme }) => highlighted ? `color: ${theme.color[0]}` : ''}
  }
`

class Dropdown extends Component {
  /* eslint-disable react/jsx-key */
  renderProp = (downshiftProps) => {
    const { value, options, placeholder } = this.props
    const {
      isOpen,
      getToggleButtonProps,
      getRootProps,
      getItemProps,
      highlightedIndex,
      selectedItem,
    } = downshiftProps
    const buttonItem = value
      ? options.find(option => option.value === value)
      : placeholder

    return (
      <ComponentRoot {...getRootProps({ refKey: 'ref' })}>
        <Button {...getToggleButtonProps()} isOpen={isOpen} onKeyPress={this.handleKeyPress}>
          {this.renderItem(buttonItem)}
          <Icon variant={isOpen ? 'caret-up' : 'caret-down'} />
        </Button>
        {isOpen && (
          <Menu ref={node => { this.node = node }}>
            {options.map((item, index) => (
              <MenuItem
                {...getItemProps({ key: index, index, item })}
                highlighted={highlightedIndex === index}
                selected={selectedItem === item.value}
              >
                {this.renderItem(item)}
              </MenuItem>
            ))}
          </Menu>
        )}
      </ComponentRoot>
    )
  }
  /* eslint-enable react/jsx-key */

  handleKeyPress = (event) => {
    const { options } = this.props
    // do nothing if inputs are not strings
    // TODO: maybe fallback on values?
    if (!Array.isArray(options) || options.length === 0 || !options[0].text) return null
    // find first element matching input char
    const index = options.findIndex(({ text }) =>
      text[0].toLowerCase() === event.key.toLowerCase())
    // if item cannot be found then don't do anything
    if (index === -1) return null
    // else scroll into view
    if (this.node.children[index].scrollIntoView) {
      // not implemented in Safari
      this.node.children[index].scrollIntoView()
    }
  }

  renderItem = item => item.text
    ? <Typography variant="medium">{item.text}</Typography>
    : item.node

  render() {
    const { onChange, value } = this.props
    return (
      <Downshift
        onChange={onChange}
        selectedItem={value}
        itemToString={item => (item ? item.value : '')}
      >
        {this.renderProp}
      </Downshift>
    )
  }

  static propTypes = {
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
      node: PropTypes.node,
    })),
    placeholder: PropTypes.shape({
      text: PropTypes.string,
      node: PropTypes.node,
    }),
    onChange: PropTypes.func,
  }
}

export default Dropdown
