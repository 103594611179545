import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { gql, useLazyQuery, useQuery } from '@apollo/client'

import { hasStarted } from '../../../utils/qvestUtils'
import Typography from '../../../components/common/Typography/Typography.js'
import JamHeader from '../../../components/qvest/JamHeader/JamHeader.js'


const ComponentRoot = styled.div`
  padding: 80px 20px;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.two[0]};
`

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`

const LOBBY_PAGE_QUERY = gql`
  query q($qvestId:String!) {
    qvest(id: $qvestId) {
      qvestId
      name
      language
      topic
      state
      isJam
      schedule {
        inviteAt,
        openAt,
        closeAt
      }
    }
  }
`

const OWN_QUESTIONS_QUERY = gql`
  query q($filter:QuestionSearchFilter!) {
    questions(filter:$filter) {
      items {
        questionId
      }
    }
  }
`


const LobbyPage = ({ match }) => {
  const qvestId = match.params.qvestId
  const { data } = useQuery(LOBBY_PAGE_QUERY, { variables: { qvestId }, pollInterval: 3000 })
  const [getOwnQuestions] = useLazyQuery(OWN_QUESTIONS_QUERY, { fetchPolicy: 'network-only' })

  const redirectToFirstQuestion = async () => {
    const options = {
      variables: {
        filter: { qvestId, involvesSelf: true, allowEmpty: true }
      }
    }
    const { data, error } = await getOwnQuestions(options)
    if (!error) {
      if (data.questions.items.length > 0) {
        // First question found, redirect to it
        const questionId = data.questions.items[0].questionId
        window.location = `/qvest/${qvestId}/question/${questionId}/ask`
      } else {
        // If first questions not ready yet, poll until they are
        setTimeout(() => redirectToFirstQuestion(), 2000)
      }
    }
  }

  useEffect(() => {
    if (data && hasStarted(data.qvest)) {
      redirectToFirstQuestion() // Redirect to first question, since jam is now open
    }
  }, [data])

  let topic, message
  if (data) {
    topic = data.qvest.topic
    message = (
      <Typography>
        <FormattedMessage
          defaultMessage="Yay, You are in! The jam will begin soon, please wait ..."
          id="qvest.LobbyPage.pleaseWait"
        />
      </Typography>
    )
  }

  return (
    <ComponentRoot>
      <JamHeader topic={topic} />
      <Content>
        {message}
      </Content>
    </ComponentRoot>
  )
}

export default LobbyPage
