import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'

import Typography from '../../common/Typography/Typography'
import RecipientAvatar from '../RecipientAvatar/RecipientAvatar'
import { compareAlphabetical } from '../../../utils/stringUtils'


const messages = defineMessages({
  hoverBusy: { id: 'RecipientList.hover.busy', defaultMessage: '{name} is busy answering other questions. Pick a different recipient for your question.' },
})


const ComponentRoot = styled.div`
  overflow-y: scroll;
  height: 100%;
  &::-webkit-scrollbar-track { background-color: inherit; }
  &::-webkit-scrollbar {
    width: 18px;
    padding: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.main.primary};
    border: 3px solid white;
  }
`

const HorizontalRule = styled.hr`
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.one[3]};
  margin: 0 10px;
`

const RecipientDetails = styled.div`
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ clickable }) => clickable ? 'pointer' : 'default'};
  padding: 14px;
  margin: 0 10px;
  opacity: ${({ disabled }) => disabled ? 0.4 : 1};

  &:hover {
    box-shadow: ${({ clickable }) => `0 2px 20px 0 rgba(42,45,43,${clickable ? '0.12' : '0'})`};
  }
`

const Avatar = styled.div`
  flex: 0 0 40px;
`

const Participant = styled.div`
  flex: 1 1 200px;
  display: flex;
  align-items: center;
  overflow: hidden;
  & > * {
    margin-right: 20px;
  }
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Group = styled.div`
  flex: 1 1 80px;
  display: flex;
  align-items: center;
  overflow: hidden;
  & > :not(:last-child) {
    margin-right: 20px;
  }
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const NoMatchWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  & > * { margin: auto; }
`

// Sorting: available (not busy) first and otherwise alphabetic by name
const compareRecipients = (a, b, disableBusy) => {
  if (disableBusy) {
    return (a.isBusy - b.isBusy) || compareAlphabetical(a.name, b.name)
  }
  return compareAlphabetical(a.name, b.name)
}

class RecipientList extends Component {
  handleClick = (recipient) => {
    const { onClick, disableBusy } = this.props
    if (onClick && (!disableBusy || !recipient.isBusy)) {
      onClick(recipient.participantId)
    }
  }

  renderAvatar(recipient) {
    const color = this.props.colors(recipient)
    return (
      <RecipientAvatar size="small" color={color} />
    )
  }

  renderGroupingLabel = (recipient) => {
    if (recipient.groupingConnections) {
      const groupName = recipient.groupingConnections[0].groupName
      return (
        <Typography variant="medium" title={groupName}>
          {groupName}
        </Typography>
      )
    } else {
      return (
        <Typography variant="medium" secondary>
          <FormattedMessage defaultMessage="(unassigned)" id="RecipientList.grouping.unassigned"/>
        </Typography>
      )
    }
  }

  renderStatusLabel = (recipient) => {
    if (this.props.disableBusy && recipient.isBusy) {
      return (
        <Group>
          <Typography variant="medium" secondary>
            <FormattedMessage defaultMessage="(busy)" id="RecipientList.status.busy"/>
          </Typography>
        </Group>
      )
    }
  }

  renderRecipient(recipient) {
    const { colors, showGroups, disableBusy, intl, onClick } = this.props
    const disabled = (disableBusy && recipient.isBusy)
    const clickable = (onClick != null) || disabled
    const hoverTitle = (disabled ? intl.formatMessage(messages.hoverBusy, { name:  recipient.name}) : recipient.name)
    return (
      <Fragment key={recipient.participantId}>
        <RecipientDetails
          clickable={clickable}
          disabled={disabled}
          key={recipient.participantId}
          onClick={() => this.handleClick(recipient)}
          title={hoverTitle}
        >
          <Participant>
            <Avatar>{this.renderAvatar(recipient, colors)}</Avatar>
            <Typography variant="medium">{recipient.name}</Typography>
          </Participant>
          {showGroups ? <Group>{this.renderGroupingLabel(recipient)}</Group> : null}
          {!showGroups ? <Group>{this.renderStatusLabel(recipient)}</Group> : null}
        </RecipientDetails>
        <HorizontalRule />
      </Fragment>
    )
  }

  renderNoMatch = () => (
    <NoMatchWrapper>
      <Typography variant="caption" transparent>
        <FormattedMessage id="RecipientList.nomatch" defaultMessage="No match found" />
      </Typography>
    </NoMatchWrapper>
  )

  render() {
    const { recipients, disableBusy } = this.props
    let rows
    if (recipients.length) {
      rows = recipients.concat().sort((r1, r2) => compareRecipients(r1, r2, disableBusy))
      rows = rows.map(recipient => this.renderRecipient(recipient))
    } else {
      rows = this.renderNoMatch()
    }
    return (
      <ComponentRoot>
        {rows}
      </ComponentRoot>
    )
  }
}

RecipientList.propTypes = {
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      groupingConnections: PropTypes.arrayOf(
        PropTypes.shape({
          groupName: PropTypes.string.isRequired
        })
      ),
      participantId: PropTypes.string,
      isBusy: PropTypes.bool
    })
  ),
  showGroups: PropTypes.bool,
  disableBusy: PropTypes.bool,
  onClick: PropTypes.func,
  colors: PropTypes.func
}

RecipientList.defaultProps = {
  recipients: [],
  showGroups: true
}

export default injectIntl(RecipientList)
