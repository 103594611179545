import React from 'react'
import { hexaToRgba } from '../../../utils/styleUtils'


const RecipientAvatar = ({ color, size }) => {
  const radius = (size === 'small') ? 40 : 45
  return (
    <svg width={radius} height={radius}>
      <g transform={`translate(${radius / 2},${radius / 2})`}>
        <circle r={radius / 2} fill={hexaToRgba(color + '33')} />
      </g>
      <g transform={`rotate(-149, ${radius/2}, ${radius/2})`}>
        <path d={`M0,${radius/2} a1,1 0 0,0 ${radius},0`} fill={color} />
      </g>
    </svg>
  )
}

export default RecipientAvatar
