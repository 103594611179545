import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled, { withTheme } from 'styled-components'

import Typography from '../Typography/Typography'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'


const UploadIconSvg = styled.svg``
const UploadIcon = ({ theme }) => (
  <UploadIconSvg width="35px" height="28px" viewBox="0 0 30 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Upload Icon</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1" transform="translate(-292.000000, -454.000000)" fill={theme.one[0]}>
        <g id="Group-2" transform="translate(54.000000, 409.000000)">
          <path d="M267.150943,56.2839623 C266.682547,56.2839623 266.301887,56.6646226 266.301887,57.1330189 L266.301887,64.995283 C266.301887,66.2603774 265.271698,67.290566 264.005189,67.290566 L241.994811,67.290566 C240.728302,67.290566 239.698113,66.2603774 239.698113,64.995283 L239.698113,57.1330189 C239.698113,56.6646226 239.318868,56.2839623 238.849057,56.2839623 C238.379245,56.2839623 238,56.6646226 238,57.1330189 L238,64.995283 C238,67.1971698 239.791509,68.9886792 241.994811,68.9886792 L264.005189,68.9886792 C266.208491,68.9886792 268,67.1971698 268,64.995283 L268,57.1330189 C268,56.6646226 267.61934,56.2839623 267.150943,56.2839623 L267.150943,56.2839623 Z M246.475,51.1740566 C246.142453,51.5066038 246.142453,52.0443396 246.475,52.3754717 C246.806132,52.7066038 247.342453,52.7066038 247.673585,52.3754717 L252.150943,47.8995283 L252.150943,61.4490566 C252.150943,61.9188679 252.530189,62.2981132 253,62.2981132 C253.469811,62.2981132 253.849057,61.9188679 253.849057,61.4490566 L253.849057,47.8995283 L258.326415,52.3754717 C258.657547,52.7066038 259.193868,52.7066038 259.525,52.3754717 C259.857547,52.0443396 259.857547,51.5066038 259.525,51.1740566 L253.601415,45.2490566 C253.52217,45.1712264 253.428774,45.1089623 253.324057,45.0650943 C253.220755,45.0226415 253.110377,45 253,45 C252.889623,45 252.779245,45.0226415 252.675943,45.0650943 C252.571226,45.1089623 252.47783,45.1712264 252.398585,45.2490566 L246.475,51.1740566 Z" id="Upload" />
        </g>
      </g>
    </g>
  </UploadIconSvg>
)

const ComponentRoot = styled.label`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ locked }) => locked ? '207px' : '146px'};
  padding: 14px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${({ theme, locked }) => locked ? theme.one[3] : theme.one[3]};
  border-radius: 4px;
  cursor: ${({ locked }) => locked ? 'default' : 'pointer'};
  user-select: none;

  & > :not(${Button}) {
    opacity: ${({ locked }) => locked ? '0.5' : '1'};
  }

  & > :not(:last-child) {
    margin-bottom: 14px;
  }

  &:hover {
    border-color: ${({ theme, locked }) => locked ? theme.one[3] : theme.color[0]};
  }
  &:hover ${UploadIconSvg} path {
    fill: ${({ theme, locked }) => locked ? 'unset' : theme.color[0]};
  }
  &:hover ${Typography} {
    color: ${({ theme, locked }) => locked ? theme.one[0] : theme.color[0]};
  }
`


const ACCEPT_TYPES = {
  'image': 'image/png, image/jpeg',
  'excel': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  'video': 'video/*',
  'texttrack': 'text/srt, text/vtt',
}

class FileDrop extends Component {
  handleFileChange = (e) => {
    const { onFileChange } = this.props

    if (e.target.files.length < 1) {
      throw Error('Invalid file count')
    }
    const file = e.target.files[0]

    if (onFileChange) {
      onFileChange(file)
    }
  }

  renderUnlock() {
    return (
      <Button outline>
        <Icon variant="lock" />
        <span>&nbsp;&nbsp;Unlock</span>
      </Button>
    )
  }

  render() {
    const { locked, isOver, fileName, fileType, onUnlock, theme } = this.props
    let text = this.props.text
    if (isOver) {
      text = (
        <Typography>
          <FormattedMessage
            id="owner.ImportArea.dragOverText"
            defaultMessage="Drop file here!"
          />
        </Typography>
      )
    } else if (!text) {
      text = (
        <Typography>
          <FormattedMessage
            id="owner.ImportArea.defaultText"
            defaultMessage="Choose file or drop here ..."
          />
        </Typography>
      )
    }
    const accept = ACCEPT_TYPES[fileType]
    return (
      <ComponentRoot
        isOver={isOver}
        locked={locked}
        hasFile={fileName}
        htmlFor="attachmentName"
        onClick={locked ? onUnlock : undefined}
        onChange={this.handleFileChange}
      >
        <UploadIcon theme={theme} />
        {text}
        {locked ? this.renderUnlock() : null}
        {!locked ? <input type="file" id="attachmentName" name="attachmentName" style={{ display: 'none' }} accept={accept} /> : null}
      </ComponentRoot>
    )
  }
}

FileDrop.propTypes = {
  isOver: PropTypes.bool,
  fileName: PropTypes.string,
  fileType: PropTypes.oneOf([
    'excel',
    'image',
    'video',
    'texttrack',
  ]),
  onFileChange: PropTypes.func,
  onUnlock: PropTypes.func,
}

FileDrop.defaultProps = {
  onUnlock: () => { },
  fileType: 'excel',
  isOver: false,
}

export default withTheme(FileDrop)
