import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import ContainerDimensions from 'react-container-dimensions'
import { GroupsNetworkGraph } from 'data-viz'

import { makeGroupColorScales } from '../../../utils/d3Utils'
import {
  Title,
  Content,
  Card,
  MainCard,
  SubContent,
} from '../AnalyticsLayout/AnalyticsLayout'
import Heading from '../../common/Typography/Heading'
import Typography from '../../common/Typography/Typography'
import Tooltip from '../../common/Tooltip/Tooltip'
import NetworkDetailsGraph from '../../common/NetworkDetailsGraph/NetworkDetailsGraph'
import { Legend } from 'data-viz'

const MainCardLayout = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
    max-width: 500px;
    text-align: center;
  }
  & > :nth-child(2) {
    height: 50%;
    width: 100%;
  }
  & > :nth-child(3) {
    margin: 0 auto;
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

const MediumCardLayout = styled.div`
  height: 100%;
  & > *:first-child {
    height: 25%;
  }
  & > *:nth-child(2) {
    margin-top: 20px;
    width: 100%;
    height: 55%;
  }
  & > *:last-child {
    height: calc(20% - 20px);
  }
`

const SmallCardLayout = styled.div`
  height: 100%;
  & > :first-child {
    width: 100%;
    height: 35%;
  }
  & > :nth-child(2) {
    margin-top: 10px;
    width: 100%;
    height: 50%;
  }
  & > *:last-child {
    height: calc(15% - 10px);
  }
`

const BottomGroup = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  top: 5px;
`

const TooltipWarning = styled.div`
  margin-top: 20px;
  & > *:first-child {
    margin-bottom: 10px;
  }
`

const messages = defineMessages({
  connectionLabel: {
    id: 'owner.AnalyticsNetwork.connectionLabel',
    defaultMessage: '{isValueAprox, select, true {about } other {}}{count} {count, plural, one {connection} other {connections}}',
  },
  allGroups: { id: 'owner.AnalyticsNetwork.allgroups', defaultMessage: 'All groups' },
  HIGH: { id: 'owner.AnalyticsNetwork.high', defaultMessage: 'high collaboration' },
  GOOD: { id: 'owner.AnalyticsNetwork.good', defaultMessage: 'good collaboration' },
  LOW: { id: 'owner.AnalyticsNetwork.low', defaultMessage: 'low collaboration' },
})

const typeMessages = defineMessages({
  HIGH: { id: 'owner.AnalyticsNetwork.strong', defaultMessage: 'meaning that your network is strong' },
  GOOD: { id: 'owner.AnalyticsNetwork.stable', defaultMessage: 'meaning that your network is stable' },
  LOW: { id: 'owner.AnalyticsNetwork.unstable', defaultMessage: 'meaning that your network is unstable' }
})

const subMessages = defineMessages({
  HIGH: { id: 'owner.AnalyticsNetwork.avgSubHigh', defaultMessage: 'Perfect. Your network is strongly connected.' },
  GOOD: { id: 'owner.AnalyticsNetwork.avgSubGood', defaultMessage: 'Nice. Your network is stable.' },
  LOW: { id: 'owner.AnalyticsNetwork.avgSubLow', defaultMessage: 'Be aware. Your network is unstable.' },
})

const tooltipMessages = defineMessages({
  title: { id: 'owner.AnalyticsNetwork.mainTooltip.title', defaultMessage: 'Network explanation' },
  HIGH: { id: 'owner.AnalyticsNetwork.mainTooltip.high', defaultMessage: 'Collaboration is considered high when the least connected groups are connected to more than 80%  of the other groups. Similarly, collaboration is considered good when the least connected groups are connected to more than 40% of the other groups. When the least connected groups are connected to less than 40% of the other groups, collaboration is considered low.' },
  GOOD: { id: 'owner.AnalyticsNetwork.mainTooltip.good', defaultMessage: 'Collaboration is considered good when the least connected groups are connected to more than 40%  of the other groups. Similarly, collaboration is considered high when the least connected groups are connected to more than 80% of the other groups. When the least connected groups are connected to less than 40% of the other groups, collaboration is considered low.' },
  LOW: { id: 'owner.AnalyticsNetwork..mainTooltip.low', defaultMessage: 'Collaboration is considered low when the least connected groups are connected to less than 40%  of the other groups. Similarly, collaboration is considered good when the least connected groups are connected to more than 40% of the other groups. When the least connected groups are connected to more than 80% of the other groups, collaboration is considered high.' },
})

const MIN_COUNT_OUTLIER = 4
const GOOD_CONNECTIVITY_THRESHOLD = 40
const HIGH_CONNECTIVITY_THRESHOLD = 80

function determineType(score) {
  if (score > HIGH_CONNECTIVITY_THRESHOLD) return 'HIGH'
  else if (score > GOOD_CONNECTIVITY_THRESHOLD) return 'GOOD'
  return 'LOW'
}

export function formatData(nodes, edges, colors, theme) {
  const nodeCount = nodes.length

  // Map node data for display
  const graphData = nodes.map(n => ({
    value: n.degree,
    percentage: Math.round(n.connectedness * 100),
    fill: colors(n.id),
    id: n.id
  }))

  // Sort by connectedness
  graphData.sort((a,b) => a.value - b.value)

  // bottom two graph input
  const leastActive = graphData[0]
  const mostActive = graphData.slice(-1)[0]

  // calculate average graph input
  const allConnectionCount = graphData.reduce((acc, curr) => acc + curr.value, 0)
  const avgConnectionCount = allConnectionCount / nodeCount
  const displayedAvgConnectionCount = Math.round(avgConnectionCount)
  const avgActive = {
    value: displayedAvgConnectionCount,
    percentage: Math.round(100 * avgConnectionCount / (nodeCount - 1)),
    fill: theme.main.primary,
    isValueAprox: (avgConnectionCount !== displayedAvgConnectionCount)
  }

  // Calculate main highlight type
  const sampleSize = Math.max(Math.round(graphData.length * 0.2), 1)
  const leastConnectedNodes = graphData.slice(0, sampleSize)
  const lowSum = leastConnectedNodes.reduce((acc, curr) => acc + curr.percentage, 0)
  const lowAvg = (lowSum / leastConnectedNodes.length)  // Mean of 20% least connected (low)
  const globalSum = graphData.reduce((acc, curr) => acc + curr.percentage, 0)
  const globalAvg = (globalSum / graphData.length) // Mean of all nodes (global)
  const combinedScore = lowAvg * 0.5 + globalAvg * 0.5 // Combined score (weighted sum 50/50 between the two means)

  const type = determineType(combinedScore)

  return {
    type,
    avgActive,
    mostActive,
    leastActive,
  }
}

class AnalyticsNetwork extends Component {
  renderTitleHighlight(type, groupCount) {
    const { intl } = this.props
    const tooltipTitle = intl.formatMessage(tooltipMessages.title)
    const tooltipText = intl.formatMessage(tooltipMessages[type])
    let tooltipFooter = null
    if (groupCount < MIN_COUNT_OUTLIER) {
      tooltipFooter = (
        <TooltipWarning>
          <Heading.h3>
            <FormattedMessage id="owner.AnalyticsNetwork.fewGroupsitle" defaultMessage="Low group count" />
          </Heading.h3>
          <Typography variant="medium" weight="light">
            <FormattedMessage
              id="owner.AnalyticsNetwork.fewGroups"
              defaultMessage="Qvest is designed for 4 or more groups. If you have less than 4 groups your network might not be very useful."
            />
          </Typography>
        </TooltipWarning>
      )
    }
    return (
      <Tooltip.Area>
        <Tooltip.Reference>
          <Heading variant="heading2" primary>
            {intl.formatMessage(messages[type])}
          </Heading>
        </Tooltip.Reference>
        <Tooltip>
          <Heading.h3>{tooltipTitle}</Heading.h3>
          <Typography variant="medium" weight="light">
            {tooltipText}
          </Typography>
          {tooltipFooter}
        </Tooltip>
      </Tooltip.Area>
    )
  }

  addConnectionLabel(item) {
    const { intl } = this.props
    return {
      ...item,
      label: intl.formatMessage(messages.connectionLabel, {
        count: item.value,
        isValueAprox: item.isValueAprox,
      }),
    }
  }

  renderLegends(leastActive, mostActive, colors) {
    const { groupGraph, theme, intl } = this.props
    const groupNamesById = groupGraph.nodes.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
    const leastLegendData = { fill: leastActive.fill, label: groupNamesById[leastActive.id] }
    const mostLegendData = { fill: mostActive.fill, label: groupNamesById[mostActive.id] }
    const avgLegendData = { fill: theme.main.primary, label: intl.formatMessage(messages.allGroups) }
    const mainLegendData = groupGraph.nodes.map(({ name, id }) => ({ fill: colors(id), label: name }))
    return {
      leastLegendData,
      mostLegendData,
      avgLegendData,
      mainLegendData
    }
  }

  render() {
    const { groupGraph, theme, intl } = this.props

    if (!groupGraph) return null

    const { nodes, edges } = groupGraph
    const ids = nodes.map(n => n.id)
    const colors = makeGroupColorScales(theme, ids).primaryColors
    const { type, avgActive, mostActive, leastActive } = formatData(nodes, edges, colors, theme)

    const networkTypeText = intl.formatMessage(typeMessages[type])

    const { leastLegendData, mostLegendData, avgLegendData, mainLegendData } =
      this.renderLegends(leastActive, mostActive, colors)

    const mostActiveLabeled = this.addConnectionLabel(mostActive)
    const leastActiveLabeled = this.addConnectionLabel(leastActive)
    const avgActiveLabeled = this.addConnectionLabel(avgActive)

    return (
      <Content>
        <MainCard highlighted>
          <MainCardLayout>
            <div>
              <Heading variant="heading2">
                <FormattedMessage
                  id="owner.AnalyticsNetwork.title"
                  defaultMessage="There was overall {connectivity} among the different groups in your Qvest, {network}"
                  values={{ connectivity: this.renderTitleHighlight(type, nodes.length), network: networkTypeText }}
                />
              </Heading>
            </div>
            <div>
              <ContainerDimensions>
                {({ width, height }) =>
                  <GroupsNetworkGraph data={groupGraph} colors={colors} width={width} height={height} />}
              </ContainerDimensions>
            </div>
            <div>
              <Legend horizontal data={mainLegendData} />
            </div>
          </MainCardLayout>
        </MainCard>
        <SubContent>
          <Card>
            <MediumCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage
                    id="owner.AnalyticsNetwork.mediumCardTitle"
                    defaultMessage="Total"
                  />
                </Heading.h3>
                <Typography variant="small" weight="light" transparent>
                  {intl.formatMessage(subMessages[type])}
                </Typography>
              </Title>
              <NetworkDetailsGraph {...avgActiveLabeled} />
              <BottomGroup>
                <Legend data={[avgLegendData]} />
              </BottomGroup>
            </MediumCardLayout>
          </Card>
          <Card>
            <SmallCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage id="owner.AnalyticsNetwork.highest" defaultMessage="Highest" />
                </Heading.h3>
                <Typography variant="small" weight="light" transparent>
                  <FormattedMessage
                    id="owner.AnalyticsNetwork.highestSub"
                    defaultMessage="This group had the highest number of connections"
                  />
                </Typography>
              </Title>
              <NetworkDetailsGraph {...mostActiveLabeled} />
              <BottomGroup>
                <Legend data={[mostLegendData]} />
              </BottomGroup>
            </SmallCardLayout>
          </Card>
          <Card>
            <SmallCardLayout>
              <Title>
                <Heading.h3>
                  <FormattedMessage id="owner.AnalyticsNetwork.lowest" defaultMessage="Lowest" />
                </Heading.h3>
                <Typography variant="small" weight="light" transparent>
                  <FormattedMessage
                    id="owner.AnalyticsNetwork.lowestSub"
                    defaultMessage="This group had the lowest number of connections"
                  />
                </Typography>
              </Title>
              <NetworkDetailsGraph {...leastActiveLabeled} />
              <BottomGroup>
                <Legend data={[leastLegendData]} />
              </BottomGroup>
            </SmallCardLayout>
          </Card>
        </SubContent>
      </Content>
    )
  }

  static propTypes = {
    groupGraph: PropTypes.object
  }
}

export default withTheme(injectIntl(AnalyticsNetwork))
