import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, defineMessages } from 'react-intl'

import Typography from '../../../common/Typography/Typography'
import GenericModal from '../../../common/GenericModal/GenericModal'


const messages = defineMessages({
  title: { defaultMessage: 'Logo upload failed', id: 'owner.FailedLogoUploadModal.title'}
})

const errorMessages = defineMessages({
  UNSUPPORTED_FILE_TYPE: { defaultMessage: 'Suggested logo file type not supported', id: 'owner.FailedLogoUploadModal.errorMessages.UNSUPPORTED_FILE_TYPE'},
  FILE_UPLOAD_TOO_LARGE: { defaultMessage: 'Suggested logo file is too big', id: 'owner.FailedLogoUploadModal.errorMessages.FILE_UPLOAD_TOO_LARGE'},
  UNEXPECTED: { defaultMessage: 'An unexpected error occured', id: 'owner.FailedLogoUploadModal.errorMessages.UNEXPECTED'}
})

const contentMessages = defineMessages({
  UNSUPPORTED_FILE_TYPE: { defaultMessage: 'Image files in PNG and JPG/JPEG format are accepted.', id: 'owner.FailedLogoUploadModal.contentMessages.UNSUPPORTED_FILE_TYPE'},
  FILE_UPLOAD_TOO_LARGE: { defaultMessage: 'Image files below 1 megabyte in size are accepted.', id: 'owner.FailedLogoUploadModal.contentMessages.FILE_UPLOAD_TOO_LARGE'},
  UNEXPECTED: { defaultMessage: 'We have made a note of the issue and will look into it. Please refresh your browser and try again.', id: 'owner.FailedLogoUploadModal.contentMessages.UNEXPECTED'},
})

const FailedLogoUploadModal = ({ onSubmit, onClose, error, intl }) => {
  let errorType = 'UNEXPECTED'
  if (errorMessages.hasOwnProperty(error.message)) {
    errorType = error.message
  }
  const modalProps = {
    title: intl.formatMessage(messages.title),
    message: {
      type: 'error',
      content: (<Typography tertiary>{intl.formatMessage(errorMessages[errorType])}</Typography>)
    },
    ok: true
  }
  return (
    <GenericModal {...modalProps} onSubmit={onSubmit} onCancel={onClose}>
      <Typography>{intl.formatMessage(contentMessages[errorType])}</Typography>
    </GenericModal>
  )
}

FailedLogoUploadModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string
  }).isRequired
}

FailedLogoUploadModal.defaultProps = {
  onSubmit: () => {},
  onClose: () => {}
}


export default injectIntl(FailedLogoUploadModal)
