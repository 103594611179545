import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const FONT_FAMILY = {
  display1: 'font2', // LL Circular Book Web
  display2: 'font2', // LL Circular Book Web
  display3: 'font2', // LL Circular Book Web
  heading1: 'font3', // LL Circular Black Web
  heading2: 'font3', // LL Circular Black Web
  heading3: 'font3', // LL Circular Black Web
  heading4: 'font3', // LL Circular Black Web
  heading5: 'font3', // LL Circular Black Web
  heading6: 'font3'  // LL Circular Black Web
}
const FONT_WEIGHT = {
  display1: '300',
  display2: '300',
  display3: '300',
  heading1: '900',
  heading2: '900',
  heading3: '900',
  heading4: '900',
  heading5: '900',
  heading6: '900'
}

const FONT_SIZES = {
  display1: '65px',
  display2: '45px',
  display3: '38px',
  heading1: '38px',
  heading2: '25px',
  heading3: '20px',
  heading4: '16px',
  heading5: '14px',
  heading6: '10px',
}

const LINE_HEIGHTS = {
  display1: '85px',
  display2: '60px',
  display3: '48px',
  heading1: '48px',
  heading2: '32px',
  heading3: '25px',
  heading4: '18px',
  heading5: '16px',
  heading6: '15px',
}

const determineColor = ({ theme, primary, secondary, tertiary, inverted }) => {
  const rank = (primary && 'primary')
    || (secondary && 'secondary')
    || (tertiary && 'tertiary')
  if (rank) {
    return theme.main[rank]
  } else {
    return inverted ? theme.inverted.one[0] : theme.one[0]
  }
}

const Heading = styled.span`
  font-family: ${({ theme, variant }) => theme[FONT_FAMILY[variant]]};
  font-weight: ${({ variant }) => FONT_WEIGHT[variant]};
  font-size: ${({ variant }) => FONT_SIZES[variant]};
  line-height: ${({ variant }) => LINE_HEIGHTS[variant]};
  color: ${determineColor};
  opacity: ${({ transparent }) => transparent ? '0.5' : '1.0'};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const NormalizedHeading = styled(Heading)`
  margin: 0;
  padding: 0;
`

const HeadingH1 = (props) => (
  <NormalizedHeading as="h1" {...props} variant={props.variant || 'heading1'}>{props.children}</NormalizedHeading>
)
Heading.h1 = HeadingH1
const HeadingH2 = (props) => (
  <NormalizedHeading as="h2" {...props} variant={props.variant || 'heading2'}>{props.children}</NormalizedHeading>
)
Heading.h2 = HeadingH2
const HeadingH3 = (props) => (
  <NormalizedHeading as="h3" {...props} variant={props.variant || 'heading3'}>{props.children}</NormalizedHeading>
)
Heading.h3 = HeadingH3
const HeadingH4 = (props) => (
  <NormalizedHeading as="h4" {...props} variant={props.variant || 'heading4'}>{props.children}</NormalizedHeading>
)
Heading.h4 = HeadingH4
const HeadingH5 = (props) => (
  <NormalizedHeading as="h5" {...props} variant={props.variant || 'heading5'}>{props.children}</NormalizedHeading>
)
Heading.h5 = HeadingH5
const HeadingH6 = (props) => (
  <NormalizedHeading as="h6" {...props} variant={props.variant || 'heading6'}>{props.children}</NormalizedHeading>
)
Heading.h6 = HeadingH6


Heading.propTypes = {
  variant: PropTypes.oneOf(Object.keys(FONT_SIZES)),
  cta: PropTypes.bool,
  transparent: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  inverted: PropTypes.bool,
}

Heading.defaultProps = {
  cta: false,
  transparent: false,
  primary: false,
  secondary: false,
  tertiary: false,
  inverted: false,
}

export default Heading
