import { FormattedMessage } from 'react-intl'
import Typography from '../../common/Typography/Typography'
import React, { Fragment } from 'react'
import styled from 'styled-components'

const Description = styled.div`
  position: relative;
  top: 0;
  left: 0;

  align-items: flex-end;
  justify-content: ${({ mobile }) => mobile ? 'flex-start' : 'flex-end'};
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -6px;
  text-align: ${({ mobile }) => mobile ? 'left' : 'right'};

  && {
    display: ${({ mobile }) => mobile ? 'flex' : 'none'};
  }

  @media (min-width: 700px) {
    && {
      display: ${({ mobile }) => mobile ? 'none' : 'flex'};
    }
  }

  & > * {
    flex: 0 1 280px;
    margin: 6px;
  }

  & ${Typography} {
    font-family: ${({ theme }) => theme.font2};
    font-weight: normal;
  }
`

const Link = styled.span`
  &, &:visited, &:link {
    color: ${({ theme }) => theme.color[0]};
    cursor: pointer;
  }
  &:hover, &:active {
    text-decoration: underline
  }
`

const Hidable = styled.div`
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
`

const Guide = ({ onClick }) => {
  const link = (
    <Link onClick={onClick}>
      <FormattedMessage defaultMessage="questioning guidelines" id="qvest.AskDisclaimer.guide.link" />
    </Link>
  )

  return (
    <Fragment>
      <br />
      <Typography variant="small">
        <FormattedMessage
          id="qvest.AskDisclaimer.guide"
          defaultMessage="See {link}."
          values={{ link }}
        />
      </Typography>
    </Fragment>
  )
}

const DisclaimerText = ({ variant }) => {
  let subject
  if (variant === 'answer') {
    subject = (
      <FormattedMessage defaultMessage="answers" id="qvest.AskDisclaimer.hint.subject.answer" />
    )
  } else {
    subject = (
      <FormattedMessage defaultMessage="questions" id="qvest.AskDisclaimer.hint.subject.question" />
    )
  }

  return (
    <FormattedMessage
      id="qvest.AskDisclaimer.hint"
      defaultMessage="Please note that all {subject} will be reported exactly as written when the Qvest closes."
      values={{ subject }}
    />
  )
}

const AskDisclaimer = ({ variant, invisible, onGuideClick }) => {
  const anonymityText = (
    <div>
      <Typography variant="small">
        <DisclaimerText variant={variant} />
      </Typography>
      {(variant === 'question') ? <Guide onClick={onGuideClick} /> : null}
    </div>
  )

  return (
    <Fragment>
      <Description mobile>
        <Hidable visible={!invisible}>
          {anonymityText}
        </Hidable>
      </Description>
      <Description>
        {anonymityText}
      </Description>
    </Fragment>
  )
}

export default AskDisclaimer
