import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import * as d3 from 'd3'
import { makeGroupColorScales } from '../../../utils/d3Utils'
import { hexaToRgba } from '../../../utils/styleUtils'
import { truncateText } from '../../../utils/svgUtils'


const ComponentRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.font1};
  font-size: 13px;
  line-height: 20px;
`

const Text = styled.text`
  font-size: 13px;
  font-family: ${({ theme }) => theme.font2};
`

// Approximate how much to fill circle from left to right
//
// (The calculation is simplified by conceptually fitting
// a rhombus inside the circle and relying on triangle area formula)
function computeFillWidth(q, a, radius) {
  const fullArea = (radius * radius) / 2
  let width
  if (q < a) {
    const q_share = q / a
    const q_area = fullArea * q_share
    width = Math.sqrt(2) * Math.sqrt(q_area)
  } else if (q > a) {
    const a_share = a / q
    const a_area = fullArea * a_share
    const a_width = Math.sqrt(2) * Math.sqrt(a_area)
    width = (radius * 2) - a_width
  } else {
    width = radius
  }
  return width
}

function renderCircleClipPath(clipId, radius, questions, answers) {
  const valueWidth = computeFillWidth(questions, answers, radius)
  return (
    <defs>
      <clipPath id={clipId}>
        <rect x={-radius} y={-radius} width={valueWidth} height={radius * 2} />
      </clipPath>
      <clipPath id={`${clipId}-inverse`}>
        <rect
          x={valueWidth - radius}
          y={-radius}
          width={radius * 2 - valueWidth}
          height={radius * 2} />
      </clipPath>
    </defs>
  )
}

function renderFillCircle(clipId, color, radius) {
  return (
    <circle
      fill={color}
      r={radius}
      clipPath={`url(#${clipId})`}
    />
  )
}

export class Chart extends Component {
  handleResize = () => {
    const maxWidth = (this.props.itemHeight / 1.5)
    truncateText(this.elm, maxWidth)
  }

  componentDidMount() {
    if (this.props.label) {
      this.handleResize()
    }
  }

  render() {
    const { itemWidth, itemHeight, id, questions, answers, label, color, altColor } = this.props
    const radius = (itemHeight / 5)
    const fullCircle = (Math.PI * 2) // 360 degrees
    const tilt = (fullCircle * 0.1) // 36 degrees
    const isEmpty = ((questions + answers) === 0)
    const hasGroup = (id != null)

    const angles = d3.pie().sort(null)([1, 1])
    const labelArc = d3.arc()
      .startAngle(d => d.startAngle + tilt)
      .endAngle(d => d.endAngle + tilt)
      .innerRadius(radius + 10)
      .outerRadius(radius + 10)

    let circleClipPath, fillCircle
    if (!isEmpty && hasGroup) {
      const clipId = 'mindset-card-clip-' + id
      circleClipPath = renderCircleClipPath(clipId, radius, questions, answers)
      fillCircle = renderFillCircle(clipId, color, radius)
    }

    const label1 = labelArc.centroid(angles[0])
    const label2 = labelArc.centroid(angles[1])
    const labels = [
      <Text key={0} transform={`translate(${label1})`} textAnchor="start">
        A {answers}
      </Text>,
      <Text key={1} transform={`translate(${label2})`} textAnchor="end">
        Q {questions}
      </Text>
    ]

    let fill = color
    if (!hasGroup) {
      fill = hexaToRgba(color + '10')
    } else if (isEmpty) {
      fill = 'transparent'
    }

    let strokeWidth = 0
    let strokeDashArray
    if (isEmpty) {
      strokeWidth = 1
    }
    if (!hasGroup) {
      strokeDashArray = [5, 5]
    }

    let opacity = 0.5
    if (altColor || isEmpty && hasGroup) {
      opacity = 1
    }

    return (
      <svg width={itemWidth} height={itemHeight}>
        <g transform={`translate(${itemWidth / 2},${itemHeight / 2.3})`}>
          {circleClipPath}
          <g>
            <circle
              fill={altColor || fill}
              stroke={color}
              strokeWidth={strokeWidth}
              strokeDasharray={strokeDashArray}
              r={radius}
              opacity={opacity}
              clipPath={`url(#mindset-card-clip-${id}-inverse)`}
            />
            {fillCircle}
          </g>
          {label && <Fragment>
            <g>{labels}</g>
            <g transform={`translate(-${radius + 30},${radius + 30})`}>
              <circle
                fill={hasGroup ? color : fill}
                stroke={color}
                strokeWidth={1}
                opacity={hasGroup ? 1 : 0.5}
                r={4}
              />
              <text
                ref={elm => this.elm = elm}
                data-text={label}
                textAnchor="left"
                transform={'translate(12,4)'}
              >
                {label}
              </text>
            </g>
          </Fragment>}
        </g>
      </svg>
    )
  }
}

// Determine dimensions (grid layout)
function determineDimensions(groupCount, size) {
  let itemWidth, itemHeight
  if (size === 'small') {
    itemWidth = 180
    itemHeight = 160
  } else if (size === 'medium') {
    const width = 530
    const height = 160
    if (groupCount > 2) {
      itemWidth = (width / 3)
      itemHeight = height
    } else {
      itemWidth = (width / 2)
      itemHeight = height
    }
  } else {
    const width = 530
    const height = 420
    if (groupCount > 6) {
      /* 3-by-3 */
      itemWidth = (width / 3)
      itemHeight = (height / 3)
    } else if (groupCount > 4) {
      /* 2-by-3 */
      itemWidth = (width / 3)
      itemHeight = (height / 2)
    } else if (groupCount > 2) {
      /* 2-by-2 */
      itemWidth = (width / 2)
      itemHeight = (height / 2)
    } else if (groupCount > 1) {
      /* 1-by-2 */
      itemWidth = (width / 2)
      itemHeight = height
    } else {
      /* 1-by-1 */
      itemWidth = width
      itemHeight = height
    }
  }
  return { itemWidth, itemHeight }
}

function renderNoGroupsIndicator(itemWidth, itemHeight, theme) {
  return (
    <Chart
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      questions={0}
      answers={0}
      label="No group"
      color={theme.default}
    />
  )
}

const QuestionAnswerCircles = ({ theme, groups, colors, size }) => {
  const { itemWidth, itemHeight } = determineDimensions(groups.length, size)
  const hasNoGroups = (!groups || groups.length === 0)
  if (!colors) {
    const ids = groups.map(g => g.id)
    colors = makeGroupColorScales(theme, ids).primaryColors
  }
  return (
    <ComponentRoot>
      {groups.map((group) => (
        <Chart
          id={group.id}
          key={group.id}
          itemWidth={itemWidth}
          itemHeight={itemHeight}
          questions={group.questionSentCount}
          answers={group.answerSentCount}
          label={group.name}
          color={colors(group.id)}
        />
      ))}
      {hasNoGroups ? renderNoGroupsIndicator(itemWidth, itemHeight, theme) : null}
    </ComponentRoot>
  )
}

QuestionAnswerCircles.propTypes = {
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large',
  ]),
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    questionSentCount: PropTypes.number.isRequired,
    answerSentCount: PropTypes.number.isRequired
  })),
  colors: PropTypes.func
}

QuestionAnswerCircles.defaultProps = {
  size: 'large'
}

export default withTheme(QuestionAnswerCircles)
