import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { isEnter } from '../../../utils/animationUtils'
import QvestAbout from '../../../components/qvest/QvestAbout/QvestAbout'
import QvestHeader from '../../../components/qvest/QvestHeader/QvestHeader'
import QvestFooter from '../../../components/common/QvestFooter/QvestFooter'
import { filterQvestsById } from '../../../reducers/qvests'
import { filterGroupingsByQvest } from '../../../reducers/grouping'
import { filterParticipantsByQvest } from '../../../reducers/participants'
import { filterSchedulesByQvestsId } from '../../../reducers/schedules'


const ComponentRoot = styled.div`
  opacity: ${props => isEnter(props) ?  '1' : '0'};
  transition: opacity 300ms ease-in;
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 25px;
  @media (min-width: 512px) {
    padding: 0 50px;
    & > :nth-child(2) {
      margin-top: 82px;
    }
  }
`


class QvestSitePage extends Component {
  render() {
    const { match, animation, qvestStore, scheduleStore, groupingStore, participantStore } = this.props
    const { qvestId } = match.params

    const qvest = filterQvestsById(qvestStore, qvestId)
    const participants = filterParticipantsByQvest(participantStore, qvestId)
    const grouping = filterGroupingsByQvest(groupingStore, qvestId)
    const schedule = filterSchedulesByQvestsId(scheduleStore, qvestId)

    return (
      <ComponentRoot animation={animation}>
        <QvestHeader qvest={qvest} schedule={schedule} />
        <QvestAbout
          isEditable={qvestStore.isEditable}
          qvest={qvest}
          participants={participants}
          groups={grouping ? grouping.groups : null}
        />
        <QvestFooter />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    groupingStore: state.grouping.toJS(),
    participantStore: state.participants.toJS(),
    scheduleStore: state.schedules.toJS()
  }
}

export default connect(mapStateToProps)(QvestSitePage)
