import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import Icon from '../Icon/Icon'

const rotate360 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const AnimatedIcon = styled(Icon)`
  animation: ${rotate360} 2s linear infinite;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
  width: ${props => props.width};
`

const CenteredLoader = ({ width, height, size }) => (
  <LoaderWrapper height={height} width={width}>
    <AnimatedIcon variant="sync-alt" size={size} />
  </LoaderWrapper>
)

CenteredLoader.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  size: PropTypes.oneOf([
    'regular',
    'medium',
    'large'
  ]),
}

CenteredLoader.defaultProps = {
  height: '100%',
  width: '100%',
  size: 'regular'
}

export default CenteredLoader
