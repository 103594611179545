import { QuestionThemesRadialChart, themesMapping } from 'data-viz'
import Button from '../../common/Button/Button'
import { FormattedMessage } from 'react-intl'
import Anchor from '../../common/Anchor/Anchor'
import React from 'react'
import styled from 'styled-components'


const ChartWrapper = styled.div`
  padding: 0 20px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px 0 20px 0;
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.inverted.two[1]};
  & > * {
    display: block;
    width: 48%;
  }
  & > * > ${Button} {
    width: 100%;
  }
`


const ResultBox = ({ show, themes, colors, questionsByTheme, insightsLink, onDownload }) => {
  if (!show) return null
  let data = themes.map(theme => ({
    ...theme,
    questionIds: questionsByTheme[theme.themeId].map(q => q.questionId)
  }))
  data = themesMapping.sortForOptimalDisplay(data)
  return (
    <div>
      <ChartWrapper>
        <QuestionThemesRadialChart
          height={200}
          width={330}
          radius={80}
          colors={colors}
          themes={data}
        />
      </ChartWrapper>
      <ButtonWrapper>
        <Button onClick={onDownload} inverted outline>
          <FormattedMessage id="owner.AnalyticsThemes.download" defaultMessage="Download" />
        </Button>
        <Anchor.Link inverted to={insightsLink}>
          <Button inverted>
            <FormattedMessage id="owner.AnalyticsThemes.insights" defaultMessage="Project Map" />
          </Button>
        </Anchor.Link>
      </ButtonWrapper>
    </div>
  )
}

export default ResultBox
