import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import AnalyticsFocus from '../../../../../components/owner/AnalyticsFocus/AnalyticsFocus'
import Button from '../../../../../components/common/Button/Button'
import Disclaimer from '../../../../../components/owner/Disclaimer/Disclaimer'
import { TitleBar } from '../../../../../components/owner/AnalyticsLayout/AnalyticsLayout'
import { filterQvestsById } from '../../../../../reducers/qvests'
import { filterQuestionDumpByQvestIdDict, filterQuestionDistributionByQvestId } from '../../../../../reducers/stats'
import { filterGroupingsByQvest } from '../../../../../reducers/grouping'
import { getQuestionDistribution, getQuestionDump, downloadQuestionProfile } from '../../../../../actions/statsActions'
import { getGroupingByQvestId } from '../../../../../actions/groupingActions'

const ComponentRoot = styled.div`
  & > *:first-child { max-width: 1400px; }
  & > :last-child {
    margin: 50px 30px 30px 30px;
    max-width: 989px
  }
`

const messages = defineMessages({
  whyTitle: { id: 'owner.Focus.sheet.whyTitle', defaultMessage: 'Why' },
  whoTitle: { id: 'owner.Focus.sheet.whoTitle', defaultMessage: 'Who' },
  whatTitle: { id: 'owner.Focus.sheet.whatTitle', defaultMessage: 'What' },
  howTitle: { id: 'owner.Focus.sheet.howTitle', defaultMessage: 'How' },
  otherTitle: { id: 'owner.Focus.sheet.otherTitle', defaultMessage: 'Other' },
  whyQuestions: { id: 'owner.Focus.sheet.whyQuestions', defaultMessage: 'Why questions' },
  whyAnswers: { id: 'owner.Focus.sheet.whyAnswers', defaultMessage: 'Why answers' },
  whoQuestions: { id: 'owner.Focus.sheet.whoQuestions', defaultMessage: 'Who questions' },
  whoAnswers: { id: 'owner.Focus.sheet.whoAnswers', defaultMessage: 'Who answers' },
  whatQuestions: { id: 'owner.Focus.sheet.whatQuestions', defaultMessage: 'What questions' },
  whatAnswers: { id: 'owner.Focus.sheet.whatAnswers', defaultMessage: 'What answers' },
  howQuestions: { id: 'owner.Focus.sheet.howQuestions', defaultMessage: 'How questions' },
  howAnswers: { id: 'owner.Focus.sheet.howAnswers', defaultMessage: 'How answers' },
  otherQuestions: { id: 'owner.Focus.sheet.otherQuestions', defaultMessage: 'Other questions' },
  otherAnswers: { id: 'owner.Focus.sheet.otherAnswers', defaultMessage: 'Other answers' }
})


function prepareTranslatedHeaders(intl) {
  const headers = {}
  Object.keys(messages).forEach(key => headers[key] = intl.formatMessage(messages[key]))
  return headers
}

class OwnerFocusPage extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getQuestionDump(match.params.qvestId))
    dispatch(getQuestionDistribution(match.params.qvestId))
    dispatch(getGroupingByQvestId(match.params.qvestId))
  }

  handleDownloadClick = () => {
    const { qvestStore, questionDumpStore, questionDistributionStore, match, dispatch, intl } = this.props
    const qvest = filterQvestsById(qvestStore, match.params.qvestId)
    const questions = filterQuestionDumpByQvestIdDict(questionDumpStore, match.params.qvestId)
    const questionDistribution = filterQuestionDistributionByQvestId(questionDistributionStore, match.params.qvestId)
    const headers = prepareTranslatedHeaders(intl)
    dispatch(downloadQuestionProfile(qvest.name, questions, questionDistribution, headers))
  }

  handleBackClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics/home`)
  }

  render() {
    const { questionDistributionStore, groupingStore, qvestStore, match } = this.props

    const qvest = filterQvestsById(qvestStore, match.params.qvestId)
    const questionDistribution = filterQuestionDistributionByQvestId(questionDistributionStore, match.params.qvestId)
    const grouping = filterGroupingsByQvest(groupingStore, match.params.qvestId)

    if (!questionDistribution || !grouping) {
      return null
    }

    const title = <FormattedMessage
      id="owner.AnalyticsFocus.title"
      defaultMessage="Focus"
    />
    const description = <FormattedMessage
      id="owner.AnalyticsFocus.description"
      defaultMessage="Communication about Qvest topic"
    />

    return (
      <ComponentRoot>
        <TitleBar
          title={title}
          description={description}
          onBack={this.handleBackClick}
        >
          <Button outline inverted onClick={this.handleDownloadClick}>
            <FormattedMessage id="owner.AnalyticsFocus.download" defaultMessage="Download" />
          </Button>
        </TitleBar>
        <AnalyticsFocus
          questionProfile={questionDistribution}
          groups={grouping.groups}
          topic={qvest.topic}
          locale={qvest.language}
          onDownload={this.handleDownloadClick}
        />
        <Disclaimer />
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    groupingStore: state.grouping.toJS(),
    questionDumpStore: state.stats.get('questionDump').toJS(),
    questionDistributionStore: state.stats.get('questionDistribution').toJS()
  }
}

export default connect(mapStateToProps)(injectIntl(OwnerFocusPage))
