import Immutable, { List, Map } from 'immutable'
import uuid from 'uuid/v4'


let INITIAL_STATE = Immutable.fromJS({
  isLoading: false,
  isDirty: false,
  lastUpdate: null,
  editingGroupId: null,
  data: {},
  unsaved: {
    grouping: {},
    groups: List.of([]),
    connections: []
  }
})

const groups = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GROUPING_REMOVE_GROUP':
      return state.update(
        state.findIndex(g => g.get('groupId') === action.groupId),
        g => g.set('isDeleted', true)
      )
    case 'GROUPING_CHANGE_GROUP':
      return state.set(
        state.findIndex(g => g.get('groupId') === action.group.groupId),
        Immutable.fromJS(action.group)
      )
    case 'GROUPING_ADD_GROUP':
      return state.push(Immutable.fromJS(action.group))
    case 'CHANGE_GROUPING_NAME':
      return (state.size > 0) ? state : List.of(1, 2, 3).map(() => (Map({ groupId: uuid(), name: '' })))
    default:
      return state
  }
}

const unsaved = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GROUPING_ADD_GROUP':
    case 'GROUPING_CHANGE_GROUP':
      return state
        .update('groups', gs => groups(gs, action))
    case 'GROUPING_REMOVE_GROUP':
      return state
        .update('groups', gs => groups(gs, action))
    case 'CHANGE_GROUPING_NAME':
      return state
        .set('name', action.name)
        .update('groups', gs => groups(gs, action))
    case 'INIT_UNSAVED_GROUPING':
      return Immutable.fromJS(action.grouping)
    case 'RESET_UNSAVED_GROUPING':
      return INITIAL_STATE.get('unsaved')
    default:
      return state
  }
}


const store = (state = INITIAL_STATE, action) => {
  const { qvestId, grouping, validity } = action
  switch (action.type) {
    case 'GET_GROUPING_REQUEST':
    case 'UPDATE_GROUPING_REQUEST':
      return state.merge(state, {
        isLoading: true
      })
    case 'GET_GROUPING_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(qvestId, { grouping, validity })
      })
    case 'UPDATE_GROUPING_SUCCESS':
      return state.merge({
        isLoading: false,
        lastUpdate: new Date(),
        data: state.get('data').set(qvestId, { grouping, validity })
      })
    case 'GET_GROUPING_FAILURE':
    case 'UPDATE_GROUPING_FAILURE':
      return state.merge(state, {
        isLoading: false,
        error: action.error
      })
    case 'INIT_UNSAVED_GROUPING':
    case 'RESET_UNSAVED_GROUPING':
      return state
        .update('unsaved', u => unsaved(u, action))
        .set('isDirty', false)
    case 'GROUPING_ADD_GROUP':
      return state
        .update('unsaved', u => unsaved(u, action))
        .set('editingGroupId', action.group.groupId)
        .set('isDirty', true)
    case 'CHANGE_GROUPING_NAME':
    case 'GROUPING_CHANGE_GROUP':
    case 'GROUPING_REMOVE_GROUP':
      return state
        .update('unsaved', u => unsaved(u, action))
        .set('isDirty', true)
    case 'GROUPING_EDITING_GROUP':
      return state
        .set('editingGroupId', action.groupId)
    default:
      return state
  }
}

export function filterGroupingsByQvest(store, qvestId) {
  if (!store.data.hasOwnProperty(qvestId)) {
    return null
  }
  return store.data[qvestId].grouping
}

export function filterUnsavedGrouping(store) {
  const grouping = store.unsaved
  grouping.groups = grouping.groups.filter(group => !group.isDeleted)
  return grouping
}

export function filterGroupingValidityByQvest(store, qvestId) {
  if (!store.data.hasOwnProperty(qvestId)) {
    return null
  }
  const { validity, groups } = store.data[qvestId]
  // Add reference to participants to each validation item, if any found
  if (validity.items && validity.items.length > 0) {
    validity.items = validity.items.map(item => ({ ...item, resource: { groups } }))
  }
  return validity
}

export default store
