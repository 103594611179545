import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import styled, { withTheme } from 'styled-components'

import AnalyticsCard from '../../AnalyticsCard/AnalyticsCard'
import { formatData } from '../../AnalyticsMindset/AnalyticsMindset'
import { Chart } from '../../../common/QuestionAnswerCircles/QuestionAnswerCircles'
import Typography from '../../../common/Typography/Typography'

const ChartContainer = styled.div`
  position: relative;
`

const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    background-color: ${({ color }) => color};
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-right: 10px;
  }
`

const titleMessages = defineMessages({
  reactive: { id: 'owner.MindsetAnalyticsCard.title.reactive', defaultMessage: '{count} reactive {count, plural, one {group} other {groups} }' },
  proactive: { id: 'owner.MindsetAnalyticsCard.title.proactive', defaultMessage: '{count} proactive {count, plural, one {group} other {groups} }' },
  active: { id: 'owner.MindsetAnalyticsCard.title.active', defaultMessage: '{count} Q&A {count, plural, one {group} other {groups} }' },
  inactive: { id: 'owner.MindsetAnalyticsCard.title.inactive', defaultMessage: '{count} inactive {count, plural, one {group} other {groups} }' },
})

class MindsetAnalyticsCard extends Component {
  handleClick = () => this.props.onClick('mindset')

  render() {
    const { intl, participation, locked, loading, theme } = this.props

    // render loading tile
    if (loading) return <AnalyticsCard loading locked={locked} />

    // describe fallback data
    let title = null
    let questionSentCount = 0
    let answerSentCount = 0
    // this is here to make clipPath in svgs unique in storybook
    // does not change anythin in the app itself
    let id = Math.random()

    // load actual data
    if (participation) {
      const { categories } = formatData(participation)
      const mainCategory = categories[0]
      const count = mainCategory.groups.length
      title = intl.formatMessage(titleMessages[mainCategory.key], { count })
      questionSentCount = participation
        .reduce((acc, val) => acc + val.questionSentCount, 0)
      answerSentCount = participation
        .reduce((acc, val) => acc + val.answerSentCount, 0)
    }

    const subTitle = <FormattedMessage
      id="owner.MindsetAnalyticsCard.subTitle"
      defaultMessage="Mindset"
    />

    return (
      <AnalyticsCard
        subTitle={subTitle}
        title={title}
        disabled={!participation && !locked}
        onClick={this.handleClick}
        locked={locked}
      >
        <ChartContainer>
          <Chart
            id={id}
            itemWidth={320}
            itemHeight={220}
            questions={questionSentCount || 1}
            answers={answerSentCount}
            color={theme.inverted.two[0]}
            altColor={theme.inverted.one[0]}
          />
          <Legend>
            <LegendItem color={theme.inverted.two[0]}>
              <div />
              <Typography inverted={locked} variant="small" weight="light">
                <FormattedMessage
                  id="owner.MindsetAnalyticsCard.totalQuestions"
                  defaultMessage="Total Questions"
                />
                :&nbsp;
              </Typography>
              <Typography inverted={locked} variant="small" weight="bold">
                {questionSentCount}
              </Typography>
            </LegendItem>
            <LegendItem color={theme.inverted.one[0]}>
              <div />
              <Typography inverted={locked} variant="small" weight="light">
                <FormattedMessage
                  id="owner.MindsetAnalyticsCard.totalAnswers"
                  defaultMessage="Total Answers"
                />
                :&nbsp;
              </Typography>
              <Typography inverted={locked} variant="small" weight="bold">
                {answerSentCount}
              </Typography>
            </LegendItem>
          </Legend>
        </ChartContainer>
      </AnalyticsCard>
    )
  }
}

export default withTheme(injectIntl(MindsetAnalyticsCard))
