import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import { makeGroupColorScales } from '../../../utils/d3Utils'
import Typography from '../../common/Typography/Typography'
import GroupNode from '../../common/GroupNode/GroupNode'
import EditableSpan from '../../common/EditableSpan/EditableSpan'
import Input from '../../common/Input/Input'
import AddGroupButton from './AddGroupButton'


const ComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  
  & > :not(:last-child) {
    margin-right: 20px;
  }
`

const GroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 14px;
`

const NamePadding = styled.div`
  padding: 0 20px;
`

const ButtonPadding = styled.div`
  padding-top: 42px;
`


class GroupsEditor extends Component {
  constructor(props) {
    super(props)

    this.onAddGroup = this.onAddGroup.bind(this)
    this.onGroupChange = this.onGroupChange.bind(this)
    this.onRemoveGroup = this.onRemoveGroup.bind(this)
  }

  renderEditableName(nid, group, exampleName) {
    const { editingId, disabled, onStartEdit } = this.props
    const { name, groupId } = group
    const placeholder = exampleName ? `[${exampleName} ${nid + 1}]` : `[Group name ${nid + 1}]`
    const viewElm = (
      <NamePadding>
        <Typography variant="heading4">{name || placeholder}</Typography>
      </NamePadding>
    )
    if (disabled) {
      return viewElm
    }
    return (
      <EditableSpan
        isEditing={editingId === groupId}
        onStartEdit={() => onStartEdit(groupId)}
        onDone={() => onStartEdit(null)}
        onRemove={() => this.onRemoveGroup(groupId)}
      >
        <EditableSpan.View>
          {viewElm}
        </EditableSpan.View>
        <EditableSpan.Edit>
          <Input
            autoFocus
            type="text"
            value={name || ''}
            placeholder={placeholder}
            onChange={({ target }) => this.onGroupChange(groupId, { name: target.value })}
          />
        </EditableSpan.Edit>
      </EditableSpan>
    )
  }

  renderAddButton() {
    if (!this.props.disabled) {
      return (
        <ButtonPadding>
          <AddGroupButton onClick={this.onAddGroup} />
        </ButtonPadding>
      )
    }
  }

  render() {
    const { groups, counts, disabled, onStartEdit, exampleName, theme } = this.props

    const ids = groups.map(g => g.groupId)
    const { primaryColors, secondaryColors } = makeGroupColorScales(theme, ids)

    const minValue = Math.min(...Object.values(counts))

    let maxValue = Math.max(...Object.values(counts))
    maxValue = (maxValue) > 7 ? maxValue : 7

    return (
      <ComponentWrapper>
        {groups.map((group, nid) => (
          <GroupColumn key={group.groupId}>
            <GroupHeader>
              {this.renderEditableName(nid, group, exampleName)}
            </GroupHeader>
            <GroupNode
              nid={nid}
              value={counts[group.groupId]}
              minValue={minValue}
              maxValue={maxValue}
              outlinedValue={3}
              disabled={disabled}
              primaryColor={primaryColors(group.groupId)}
              secondaryColor={secondaryColors(group.groupId)}
              onClick={() => onStartEdit(group.groupId)}
            />
          </GroupColumn>
        ))}
        {this.renderAddButton()}
      </ComponentWrapper>
    )
  }
  onAddGroup() {
    const { onAdd } = this.props
    if (onAdd) {
      onAdd()
    }
  }

  onGroupChange(groupId, changes) {
    const { onChange } = this.props
    if (onChange) {
      onChange({
        groupId,
        ...changes
      })
    }
  }

  onRemoveGroup(id) {
    const { onRemove } = this.props
    if (onRemove) {
      onRemove(id)
    }
  }
}

GroupsEditor.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  counts: PropTypes.object.isRequired,
  exampleName: PropTypes.string,
  editingId: PropTypes.string,
  disabled: PropTypes.bool,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
}

GroupsEditor.defaultProps = {
  disabled: false
}

export default withTheme(GroupsEditor)
