import React, { Component } from 'react'
import { FormattedRelative, injectIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

import Heading from '../../common/Typography/Heading'

import logoHorizontalPng from '../../../static/img/logo-horizontal.png'
import jamLogoPng from '../../../static/img/jam-logo.png'

const HeaderWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: ${({ light }) => light ? '82px' : '164px'};
  background: ${({ theme, light }) => light ? 'transparent' : theme.two[0]};

  @media (min-width: 700px) {
    position: ${({ inline }) => inline ? 'relative' : 'fixed'};
    align-items: center;
    z-index: 10;
    height: 82px;
    padding: 25px 20px;
  }

  img { cursor: pointer; }
`

const LogoImg = styled.img`
  max-height: 45px;
  max-width: 140px;
  margin-right: 20px;
`

const SideItem = styled.div`
  height: 82px;
  display: flex;
  align-items: center;
`

const blink = keyframes`
  0% {
    color: #3d4641;
  }
  50% {
    color: #f56a73;
  }
  100% {
    color: #3d4641;
  }
`

const CountdownSpan = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  font-family: ${({ theme }) => theme.font2};
  font-size: 15px;
  animation: ${blink} 1s ease-in-out infinite;
  animation-play-state: ${({ blink }) => blink ? 'running' : 'paused'};
  & > :last-child { padding-left: 4px; }

  @media (min-width: 700px) {
    max-width: none;
  }
`

const HorizontalRule = styled.hr`
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.one[3]};
  margin: 20px 0;
`

const Topic = styled.div`
  position: absolute;
  top: 46px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;

  @media (min-width: 700px) {
    top: 0;
    align-items: center;
    & ${HorizontalRule} { display: none }
  }
`

const WrapperLink = styled(Link)`
  line-height: 1px;
`

const countdownMessages = defineMessages({
  QVEST_ONBOARDING_STARTED: { defaultMessage: 'Opens', id: 'QvestHeader.countdown.QVEST_ONBOARDING_STARTED' },
  QVEST_OPENED: { defaultMessage: 'Closes', id: 'QvestHeader.countdown.QVEST_OPENED' },
  QVEST_CLOSED: { defaultMessage: 'Qvest is closed', id: 'QvestHeader.countdown.QVEST_CLOSED' }
})

const BLINK_INTERVAL = 30000 // final 30 seconds


class QvestHeader extends Component {
  state = {
    blinkCountdown: false
  }

  handleBlinkCountdownStart() {
    this.setState({ blinkCountdown: true })
  }

  componentDidMount() {
    const { schedule } = this.props
    if (!schedule) return
    const remainingTime = (schedule.closeAt - Date.now())
    const timeTillBlink = (remainingTime - BLINK_INTERVAL)
    setTimeout(() => this.handleBlinkCountdownStart(), timeTillBlink)
  }

  renderLogo() {
    const { qvest } = this.props
    const { qvestId, organization, isJam } = qvest
    const src = (isJam ? jamLogoPng : (organization.logo || logoHorizontalPng))
    return (
      <WrapperLink to={`/qvest/${qvestId}`}>
        <LogoImg src={src} alt={organization.name} />
      </WrapperLink>
    )
  }

  renderCountdown() {
    const { qvest, schedule, intl } = this.props
    const { blinkCountdown } = this.state
    if (!schedule) return null
    let eventDate
    const updateInterval = (qvest.isJam ? 1000 : 10000) // Refresh display more frequently if jam
    if (qvest.state === 'QVEST_ONBOARDING_STARTED') {
      eventDate = (<FormattedRelative updateInterval={updateInterval} value={schedule.openAt} />)
    } else if (qvest.state === 'QVEST_OPENED') {
      eventDate = (<FormattedRelative updateInterval={updateInterval} value={schedule.closeAt} />)
    } else if (qvest.state === 'QVEST_CLOSED') {
      eventDate = null
    } else {
      return null
    }
    return (
      <CountdownSpan blink={blinkCountdown}>
        {intl.formatMessage(countdownMessages[qvest.state]) + ' '}
        <strong>{eventDate}</strong>
      </CountdownSpan>
    )
  }

  renderTopic() {
    const { qvest, light } = this.props
    if (light) return null
    return (
      <Topic>
        <Heading.h1 variant="heading3">{qvest.topic}</Heading.h1>
        <HorizontalRule />
      </Topic>
    )
  }

  render() {
    const { light, inline } = this.props
    return (
      <HeaderWrapper light={light} inline={inline}>
        <SideItem left>
          {this.renderLogo()}
        </SideItem>
        {this.renderTopic()}
        <SideItem right>
          {this.renderCountdown()}
        </SideItem>
      </HeaderWrapper>
    )
  }
}

QvestHeader.propTypes = {
  qvest: PropTypes.shape({
    qvestId: PropTypes.string.isRequired,
    organization: PropTypes.shape({
      logo: PropTypes.string,
      name: PropTypes.string,
    }),
    state: PropTypes.string,
  }).isRequired,
  schedule: PropTypes.shape({
    openAt: PropTypes.instanceOf(Date),
    closeAt: PropTypes.instanceOf(Date)
  }),
  light: PropTypes.bool,
  inline: PropTypes.bool
}

QvestHeader.defaultProps = {
  light: false,
  inline: false
}

export default injectIntl(QvestHeader)
