import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { hexaToRgba } from '../../../utils/styleUtils'
import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import Button from '../../common/Button/Button'
import Icon from '../../common/Icon/Icon'


function determineBackground({ theme, secondary, inverted }) {
  if (secondary) {
    if (inverted) {
      return theme.inverted.two[1]
    } else {
      return theme.two[1]
    }
  } else {
    return theme.color[1]
  }
}

const loading = keyframes`
  /* define bounding rect position for loading animation*/
  from {
    background-position:
      -150% 0,
      50% 45%,
      30px 40px,
      30px 60px,
      50% 90%,
      50% 90%,
      calc(50% - 60px) 90%,
      calc(50% + 60px) 90%;
  }
  to {
    background-position:
      150% 0,
      50% 45%,
      30px 40px,
      30px 60px,
      50% 90%,
      50% 90%,
      calc(50% - 60px) 90%,
      calc(50% + 60px) 90%;
  }
`

const ComponentRoot = styled.div`
  position: relative;
  width: 380px;
  height: 380px;
  /* static color */
  background-color: ${props => determineBackground(props)};
  border-radius: 5px;
  overflow: hidden;
  cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer'};


  /* render loading animation when element is empty */
  &:empty::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    /* define shapes */
    background-image:
    linear-gradient(
        to right,
        transparent 0,
        ${props => hexaToRgba(determineBackground(props) + 'aa')} 50%,
        transparent 100%
      ),
      /* middle circle */
      radial-gradient(circle 60px, rgba(0, 0, 0, .1) 100%, transparent 0),
      /* top title */
      linear-gradient(rgba(0, 0, 0, .1) 40px, transparent 0),
      /* bottom title */
      linear-gradient(rgba(0, 0, 0, .1) 40px, transparent 0),
      /* button */
      linear-gradient(rgba(0, 0, 0, .1) 40px, transparent 0),
      linear-gradient(${props => determineBackground(props)} 40px, transparent 0),
      radial-gradient(circle 20px, rgba(0, 0, 0, .1) 100%, transparent 0),
      radial-gradient(circle 20px, rgba(0, 0, 0, .1) 100%, transparent 0);

    /* define bounding rect size */
    background-size:
      200px 380px,
      120px 120px,
      200px 6px,
      160px 6px,
      120px 40px,
      120px 40px,
      40px 40px,
      40px 40px;

    background-repeat: no-repeat;
    animation: ${loading} 2s infinite;
  }
`

const Triangle = styled.div`
  position: absolute;
  width: 30px;
  height: 20px;
  left: 0;
  bottom: 0;
  border-bottom-left-radius: 5px;
  background-color: ${({ theme }) => theme.color[1]};
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 20px 0;
    border-color: transparent ${props => props.secondary ? determineBackground(props) : props.theme.color[1]} transparent transparent;
  }
`

const Layout = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > *:first-child > *:first-child {
    text-transform: uppercase;
  }
  & > *:last-child { margin: 0 auto; }
`

const ButtonContainer = styled.div`
  z-index: 10;
`

const TitleContainer = styled.div`
  text-shadow:
    -2px 2px 0 ${props => determineBackground(props)},
    2px -2px 0 ${props => determineBackground(props)},
    -2px 2px 0 ${props => determineBackground(props)},
    2px 2px 0 ${props => determineBackground(props)};
  z-index: 10;
`

class AnalyticsCard extends Component {
  renderContent() {
    const { locked, subTitle, children, title, disabled } = this.props

    const displayedTitle = title || <FormattedMessage
      defaultMessage="No data collected" id="owner.AnalyticsCard.noData" />

    return (
      <Fragment>
        <Triangle secondary={locked} inverted={locked} />
        <Layout>
          <TitleContainer secondary={locked} inverted={locked}>
            <Typography variant="tiny" inverted={locked}>{subTitle}</Typography>
            <Heading.h2 inverted={locked}>{displayedTitle}</Heading.h2>
          </TitleContainer>
          <div>
            {children}
          </div>
          <ButtonContainer>
            <Button outline={locked} secondary={!locked} disabled={disabled} inverted>
              {locked ? <Icon variant="lock" inverted={locked} /> : null}
              {locked ? <span>&nbsp;&nbsp;Unlock</span> : 'Explore data'}
            </Button>
          </ButtonContainer>
        </Layout>
      </Fragment>
    )
  }

  render() {
    const { locked, loading, onClick, disabled } = this.props
    return (
      <ComponentRoot
        secondary={locked}
        inverted={locked}
        onClick={disabled ? null : onClick}
        disabled={disabled}
      >
        {!loading ? this.renderContent() : null}
      </ComponentRoot>
    )
  }
}

AnalyticsCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  loading: PropTypes.bool,
  locked: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

AnalyticsCard.defaultProps = {
  loading: false,
  locked: false,
  disabled: false,
  onClick: () => { }
}

export default AnalyticsCard
