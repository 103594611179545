import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'

import Heading from '../../common/Typography/Heading'


const ComponentRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1190px;
  margin-bottom: 25px;
`


const messages = defineMessages({
  COMMITMENT: { defaultMessage: 'Commitment to Qvest project', id: 'owner.AnalyticsGrid.title.commitment' },
  CONNECTIONS: { defaultMessage: 'Connections among Qvest stakeholders', id: 'owner.AnalyticsGrid.title.connections' },
  COMMUNICATION: { defaultMessage: 'Communication about Qvest topic', id: 'owner.AnalyticsGrid.title.communication' }
})

const RowTitle = ({ variant, intl }) => (
  <ComponentRoot>
    <Heading variant="heading3" inverted>{intl.formatMessage(messages[variant])}</Heading>
  </ComponentRoot>
)

RowTitle.propTypes = {
  variant: PropTypes.oneOf([
    'COMMITMENT',
    'CONNECTIONS',
    'COMMUNICATION'
  ])
}

RowTitle.defaultProps = {
  variant: 'COMMITMENT'
}

export default injectIntl(RowTitle)
