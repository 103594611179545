import styled from 'styled-components'
import EmbeddedVideo from '../EmbeddedVideo/EmbeddedVideo'
import React from 'react'
import Icon from '../Icon/Icon'
import Heading from '../Typography/Heading'
import CenteredLoader from '../CenteredLoader/CenteredLoader'

const ComponentRoot = styled.div`
  width: 100%;
  & > * { margin-bottom: 50px; }
`

const Skeleton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  background-color: ${({ theme }) => theme.one[3]};
  padding: 40px;
`

const Message = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  & * {
    color: ${({ theme }) => theme.one[1]};
  }
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const SkeletonPlayer = styled.div`
  flex: 0 60px;
  display: flex;
  align-items: center;
  width: 100%;
`

const SkeletonPlayButton = styled.div`
  flex: 0 100px;
  background-color: #CCCECC;
  width: 100px;
  height: 60px;
  border-radius: 30px;
  margin-right: 50px;
`

const SkeletonTracker = styled.div`
  flex: 1;
  position: relative;
  top: 0;
  left: 0;
`

const SkeletonTrackerBar = styled.div`
  background-color: #CCCECC;
  width: 100%;
  height: 20px;
  border-radius: 20px;
`

const SkeletonTrackerKnob = styled.div`
  position: absolute;
  top: -22px;
  left: 15%;
  background-color: #CCCECC;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 16px solid ${({ theme }) => theme.one[3]};
`

const StatusIcon = ({ status }) => {
  if (status === 'in_progress') {
    return (
      <CenteredLoader height="auto" width="auto" size="large" />
    )
  } else {
    return (
      <Icon variant="exclamation-circle" size="large" />
    )
  }
}

const StatusText = ({ status }) => {
  if (status === 'in_progress') {
    return (
      <Heading variant="heading2">Preparing video... This may take up to 10 minutes.</Heading>
    )
  } else {
    return (
      <Heading variant="heading2">Video upload failed. Please try again.</Heading>
    )
  }
}


const VideoBlock = ({ video }) => {
  if (!video) return null
  if (video.transcodeStatus === 'in_progress' || video.transcodeStatus === 'error') {
    return (
      <ComponentRoot>
        <Skeleton>
          <Message>
            <StatusText status={video.transcodeStatus} />
            <StatusIcon status={video.transcodeStatus} />
          </Message>
          <SkeletonPlayer>
            <SkeletonPlayButton />
            <SkeletonTracker>
              <SkeletonTrackerBar />
              <SkeletonTrackerKnob />
            </SkeletonTracker>
          </SkeletonPlayer>
        </Skeleton>
      </ComponentRoot>
    )
  }
  return (
    <ComponentRoot>
      <EmbeddedVideo url={video.url} />
    </ComponentRoot>
  )
}

export default VideoBlock
