import Immutable from 'immutable'

let INITIAL_STATE = Immutable.Map({
  isFetching: null,
  wasSent: false,
  error: null
})


const user = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'SEND_LOGIN_REQUEST': {
      return state.merge({
        isFetching: true,
        error: null
      })
    }
    case 'SEND_LOGIN_SUCCESS': {
      return state.merge({
        isFetching: false,
        wasSent: true
      })
    }
    case 'SEND_LOGIN_FAILURE': {
      return state.merge({
        isFetching: false,
        error: action.error
      })
    }
    default:
      return state
  }
}


export default user