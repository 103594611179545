import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { TransitionGroup, Transition } from 'react-transition-group'

import { isEnter } from '../../../utils/animationUtils'
import BgEmptySvg from '../../../static/img/question-bg-empty.svg'
import BgAskedSvg from '../../../static/img/question-bg-asked.svg'
import BgNoNextSvg from '../../../static/img/question-bg-no-next.svg'
import BgAnsweredSvg from '../../../static/img/question-bg-answered.svg'


const ComponentRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${props => isEnter(props) ? '1' : '0'};
  transform: translateX(${({ deltaX }) => deltaX}px);
  transition: opacity 300ms ease-in;

  height: 100vh;
  width: 300vw;
  background: ${({ bg }) => bg};
  background-position: left;
  background-size: auto 100%;

  @media (min-width: 512px) {
    transition: transform 1200ms ease-in, opacity 300ms ease-in;
  }
`


class QuestionBackground extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.computeViewPortDimensions()
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateViewPortDimensions)
    this.updateViewPortDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewPortDimensions)
  }

  computeViewPortDimensions = () => {
    return {
      viewPortWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      viewPortHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    }
  }

  updateViewPortDimensions = () => {
    this.setState(this.computeViewPortDimensions())
  }

  computeDeltaX = (isLeft) => {
    const { viewPortWidth, viewPortHeight } = this.state
    const imgWidth = (viewPortHeight * 1.6)
    const square = (imgWidth / 8)

    let delta
    if (!isLeft) {
      delta = -(2 * square)
    } else {
      delta = (viewPortWidth - (2 * square)) - (4 * square)
    }

    delta = Math.ceil(delta)

    return delta
  }

  isLeftPane(currentQuestion) {
    return (currentQuestion.status === 'EMPTY') || (currentQuestion.status === 'ASKED_BY_SELF')
  }

  determineBackgroundImage(currentQuestion, nextQuestion) {
    const status = currentQuestion.status
    const nextStatus = nextQuestion ? nextQuestion.status : null

    const bgImages = {
      // Ask your question
      'EMPTY': BgEmptySvg,
      // Answer someone else's question
      'ASKED': BgAskedSvg,
      // Do nothing
      'NO_NEXT': BgNoNextSvg,
      // See someone else's answer
      'ANSWERED': BgAnsweredSvg,
    }

    const options = Object.keys(bgImages)
    const currentIsDone = (options.indexOf(status) === -1)

    if (currentIsDone) {
      // Use next status, if current question is done ...
      return nextStatus ? bgImages[nextStatus] : bgImages['NO_NEXT']
    } else {
      // ... use current question status, if it isn't
      return bgImages[status]
    }
  }

  determineBgValue = () => {
    const { isJam, theme, currentQuestion, nextQuestion } = this.props
    if (isJam) {
      // Question Jam: fill color from theme
      return theme.background3
    } else {
      // Qvest: SVG pattern image
      const path = this.determineBackgroundImage(currentQuestion, nextQuestion)
      return `url(${path})`
    }
  }

  render() {
    const { currentQuestion, nextQuestion } = this.props
    const bg = this.determineBgValue()
    const isLeft = this.isLeftPane(currentQuestion, nextQuestion)
    const deltaX = this.computeDeltaX(isLeft)
    return (
      <TransitionGroup appear={true}>
        <Transition key={bg} timeout={300}>
          {state => <ComponentRoot bg={bg} deltaX={deltaX} animation={state} />}
        </Transition>
      </TransitionGroup>
    )
  }
}

QuestionBackground.propTypes = {
  currentQuestion: PropTypes.shape({
    status: PropTypes.string
  }),
  nextQuestion: PropTypes.shape({
    status: PropTypes.string
  })
}

QuestionBackground.defaultProps = {
}

export default withTheme(QuestionBackground)
