import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { defineMessages, injectIntl } from 'react-intl'

import { possessive } from '../../../utils/i18nUtils'
import { makeQuestionUrl } from '../../../utils/questionUtils'
import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import Button from '../../common/Button/Button'
import questionAskGraySvg from '../../../static/img/question-ask-gray.svg'


const NextAction = styled.div`
  margin-top: 30px;
  & > *:not(:last-child) {
    margin-bottom: 50px;
    display: inline-block;
  }
`

const TextBox = styled.div`
  & > * {
    margin-bottom: 10px;
    display: block;
  }
`

const HorizontalRule = styled.hr`
  border-style: none;
  border-bottom: 1px solid ${({ theme }) => theme.one[3]};;
  margin: 20px 0;
`

const ExtraInfoIcon = styled.img`
  width: 40px;
  height: 40px;
`

const ExtraInfo = styled.div`
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: 15px;
  }
`

const title = defineMessages({
  FIRST_QUESTION: { defaultMessage: 'You cannot answer a question until you have asked your opening question.', id: 'qvest.QvestJobDone.title.FIRST_QUESTION' },
  ASKED_BY_SELF: { defaultMessage: 'Thank you for your question.', id: 'qvest.QvestJobDone.title.ASKED_BY_SELF' },
  ANSWERED_BY_SELF: { defaultMessage: 'Thank you for your answer.', id: 'qvest.QvestJobDone.title.ANSWERED_BY_SELF' }
})

const subTitle = defineMessages({
  FIRST_QUESTION: { defaultMessage: '⠀', id: 'qvest.QvestJobDone.subTitle.FIRST_QUESTION' },
  ASKED_BY_SELF: { defaultMessage: 'You will be notified as soon as {name} answers.', id: 'qvest.QvestJobDone.subTitle.ASKED_BY_SELF' },
  ANSWERED_BY_SELF: { defaultMessage: 'Your answer has been sent to the person who asked.', id: 'qvest.QvestJobDone.subTitle.ANSWERED_BY_SELF' },
  NO_NEXT: { defaultMessage: 'You have no outstanding tasks. You will be notified as soon as you receive a new question or answer.', id: 'qvest.QvestJobDone.subTitle.NO_NEXT' }
})

const nextAction = defineMessages({
  EMPTY: { defaultMessage: 'Now, keep the Qvest rolling by asking a question', id: 'qvest.QvestJobDone.nextAction.EMPTY' },
  ASKED: { defaultMessage: 'Answer the next question you have received', id: 'qvest.QvestJobDone.nextAction.ASKED' },
  ANSWERED: { defaultMessage: 'See {possessiveName} answer to your previous question', id: 'qvest.QvestJobDone.nextAction.ANSWERED' },
  FIRST_QUESTION: { defaultMessage: 'Ask your opening question to get the Qvest rolling', id: 'qvest.QvestJobDone.nextAction.FIRST_QUESTION' }
})


const nextActionJam = defineMessages({
  EMPTY: { defaultMessage: 'Now, keep jamming by asking a question', id: 'qvest.QvestJobDone.nextActionJam.EMPTY' },
  ASKED: { defaultMessage: 'Answer the next question you have received', id: 'qvest.QvestJobDone.nextActionJam.ASKED' },
  ANSWERED: { defaultMessage: 'See {possessiveName} answer to your previous question', id: 'qvest.QvestJobDone.nextActionJam.ANSWERED' },
  FIRST_QUESTION: { defaultMessage: 'Ask your opening question to start the Jam', id: 'qvest.QvestJobDone.nextActionJam.FIRST_QUESTION' }
})

const nextButton = defineMessages({
  EMPTY: { defaultMessage: 'Ask now', id: 'qvest.QvestJobDone.nextButton.EMPTY' },
  ASKED: { defaultMessage: 'Answer now', id: 'qvest.QvestJobDone.nextButton.ASKED' },
  ANSWERED: { defaultMessage: 'See answer', id: 'qvest.QvestJobDone.nextButton.ANSWERED' },
  FIRST_QUESTION: { defaultMessage: 'Ask now', id: 'qvest.QvestJobDone.nextButton.FIRST_QUESTION' }
})

const extraInfo = defineMessages({
  QUESTION_WAVE: { defaultMessage: 'You can ask an additional question {relativeTime}.', id: 'qvest.QvestJobDone.extraInfo.QUESTION_WAVE' },
})


class QvestJobDone extends Component {
  renderExtraInfo() {
    const { nextWaveAt, nextQuestion, intl } = this.props
    if (!nextWaveAt || nextQuestion) return null
    const relativeTime = intl.formatRelative(nextWaveAt)
    return (
      <Fragment>
        <HorizontalRule />
        <ExtraInfo>
          <ExtraInfoIcon src={questionAskGraySvg} />
          <Heading variant="heading4">{intl.formatMessage(extraInfo.QUESTION_WAVE, { relativeTime })}</Heading>
        </ExtraInfo>
      </Fragment>
    )
  }

  renderNextAction() {
    const { intl, qvestId, isJam, nextQuestion, participants, redirectToFirstQuestion } = this.props
    if (!nextQuestion) return null
    const nextActionMessages = (isJam ? nextActionJam : nextAction)
    let possessiveName, nextButtonText, nextActionText
    if (redirectToFirstQuestion) {
      nextButtonText = intl.formatMessage(nextButton['FIRST_QUESTION'])
      nextActionText = intl.formatMessage(nextActionMessages['FIRST_QUESTION'])
    } else {
      const answerer = participants.find(p => p.participantId === nextQuestion.answerParticipantId)
      const name = answerer ? answerer.name : ''
      possessiveName = possessive(name, intl.locale)
      nextButtonText = intl.formatMessage(nextButton[nextQuestion.status])
      nextActionText = intl.formatMessage(nextActionMessages[nextQuestion.status], { possessiveName })
    }
    const url = makeQuestionUrl(qvestId, nextQuestion)
    return (
      <NextAction>
        <Heading variant="display2">
          {nextActionText}
        </Heading>
        <Button.Link to={url}>{nextButtonText}</Button.Link>
      </NextAction>
    )
  }

  renderSubtitleText() {
    const { intl, currentQuestion, nextQuestion, participants, redirectToFirstQuestion } = this.props
    if (!nextQuestion) {
      return intl.formatMessage(subTitle['NO_NEXT'])
    } else if (redirectToFirstQuestion) {
      return intl.formatMessage(subTitle['FIRST_QUESTION'])
    } else {
      const status = currentQuestion.status
      const answerer = participants.find(p => p.participantId === currentQuestion.answerParticipantId)
      const name = answerer ? answerer.name : ''
      return intl.formatMessage(subTitle[status], { name })
    }
  }

  renderTitleText() {
    const { intl, currentQuestion, nextQuestion, redirectToFirstQuestion } = this.props
    if (nextQuestion && redirectToFirstQuestion) {
      return intl.formatMessage(title['FIRST_QUESTION'])
    } else {
      return intl.formatMessage(title[currentQuestion.status])
    }
  }

  render() {
    return (
      <div>
        <TextBox>
          <Heading variant="heading3" paragraph>
            {this.renderTitleText()}
          </Heading>
          <Typography transparent weight="light">
            {this.renderSubtitleText()}
          </Typography>
          {this.renderNextAction()}
        </TextBox>
        {this.renderExtraInfo()}
      </div>
    )
  }
}

export default injectIntl(QvestJobDone)
