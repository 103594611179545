import * as api from './apiClient'
import { raiseUnexpectedErrorNotification } from './notificationActions'


export function getUser() {
  return dispatch => {
    dispatch({ type: 'GET_USER_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q {
            user {
              userId
              createdAt
              email
              fullName
              phone
              locale
              timeZone
              source
              organizations {
                organizationId
                name
              }
            }
          }
        `
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data: { user } }) => {
        dispatch({ type: 'GET_USER_SUCCESS', user: user })
        return user
      })
      .catch(error => {
        dispatch({ type: 'GET_USER_FAILURE', error })
      })
  }
}

export function createUser(credentials, inviteToken, fields = {}) {
  return dispatch => {
    dispatch({ type: 'CREATE_USER_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($input:UserCreateInput!) {
            createUser(input:$input) {
              subscribeLink
            }
          }
        `,
        variables: {
          input: { credentials, inviteToken, ...fields }
        }
      }
    }
    return api.invoke('/api/graphql/public', options, false)
      .then(({ data }) => {
        if (data.createUser && data.createUser.subscribeLink) {
          window.location.href = data.createUser.subscribeLink
        } else {
          dispatch({ type: 'CREATE_USER_SUCCESS' })
        }
      })
      .catch(error => {
        dispatch({ type: 'CREATE_USER_FAILURE', error })
      })
  }
}

export function inviteUser(email) {
  return dispatch => {
    dispatch({ type: 'INVITE_USER_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($email:String!) {
            inviteUser(email:$email)
          }
        `,
        variables: { email }
      }
    }
    return api.invoke('/api/graphql/public', options, false)
      .then(() => {
        dispatch({ type: 'INVITE_USER_SUCCESS' })
      })
      .catch(error => {
        dispatch({ type: 'INVITE_USER_FAILURE', error })
      })
  }
}

export function inviteUserToOrganization(email, organizationId, fullName) {
  return dispatch => {
    dispatch({ type: 'INVITE_USER_TO_ORG_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($email:String!, $organizationId:String!, $fullName:String!) {
            inviteUserToOrganization(email:$email, organizationId:$organizationId, fullName:$fullName) {
              user {
                userId,
                email,
                fullName
              }
              pendingUser {
                organizationUserInvitationId
                fullName
                expiredAt
              }
            }
          }
        `,
        variables: { email, organizationId, fullName }
      }
    }
    return api.invoke('/api/graphql', options)
      .then((result) => {
        dispatch({
          type: 'INVITE_USER_TO_ORG_SUCCESS',
          organizationId,
          ...result.data.inviteUserToOrganization
        })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'INVITE_USER_TO_ORG_FAILURE', error })
      })
  }
}

export function updateUser(patch) {
  return dispatch => {
    dispatch({ type: 'UPDATE_USER_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
              mutation m($patch:UserPatch!) {
                updateUser(patch:$patch) {
                  userId
                  createdAt
                  email
                  fullName
                  phone
                  locale
                  timeZone
                  organizations {
                    organizationId
                    name
                  }
                }
              }
            `,
        variables: { patch }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const user = data.updateUser
        dispatch({ type: 'UPDATE_USER_SUCCESS', user })
      })
      .catch(error => {
        dispatch({ type: 'UPDATE_USER_FAILURE', error })
      })
  }
}
