import update from 'immutability-helper'
import { findQuestion } from './utilities'
import { sortBy } from 'lodash'

const questionReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ASSIGN_THEME':
      return update(state, {
        $merge: {
          theme: action.theme,
          position: action.position
        }
      })
    case 'CONFIRM_THEME_ASSIGNMENT':
      return update(state, {
        $merge: {
          isUnsaved: false,
          originIndex: null,
          originTheme: null
        }
      })
    case 'REVERT_THEME_ASSIGNMENT':
      return update(state, {
        $merge: {
          isUnsaved: false,
          position: action.oldPosition,
          theme: state.originTheme,
          originIndex: null,
          originTheme: null
        }
      })
    case 'DELETE_THEME':
      if (state.theme.themeId === action.themeId) {
        return update(state, {
          theme: {
            $set: { themeId: 'default', themeSet: { themeSetId: action.themeSetId } }
          }
        })
      }
      return state
    case 'MOVE_QUESTION':
      return update(state, {
        $merge: {
          theme: action.toTheme,
          isDragging: true,
          originIndex: (state.originIndex ? state.originIndex : action.fromIndex),
          originTheme: (state.originTheme ? state.originTheme : action.fromTheme)
        }
      })
    case 'END_MOVE_QUESTION':
      return update(state, {
        $merge: {
          isDragging: false,
          isUnsaved: true,
        }
      })
    case 'FINALIZE_QUESTION_SORTING':
      return update(state, {
        score: { $set: action.scores[state.questionId] }
      })
    case 'CLEAR_QUESTION_SORTING':
      return update(state, {
        score: { $set: null }
      })
    case 'TOGGLE_THEME_SUGGESTION':
      if (state.questionId in action.updatedQuestions) {
        return update(state, {
          theme: {
            $set: action.updatedQuestions[state.questionId].theme
          }
        })
      }
      return state
    default:
      return state
  }
}

const themeReducer = (state = {}, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME_DELETE_FLAG':
      return update(state, {
        $merge: {
          isDeleting: action.isDeleting,
          isEditing: false
        }
      })
    case 'TOGGLE_THEME_COLLAPSED':
      return update(state, {
        $merge: {
          isCollapsed: action.isCollapsed,
          isEditing: false
        }
      })
    case 'FINALIZE_ADD_THEME':
      return update(state, {
        $merge: {
          isEditing: false,
          isSaving: true
        }
      })
    case 'REVERT_ADD_THEME_FINALIZATION':
      return update(state, {
        $merge: {
          isEditing: true,
          isSaving: false
        }
      })
    case 'START_THEME_EDIT':
      return update(state, {
        $merge: {
          isEditing: true,
          previousName: action.name
        }
      })
    case 'EDIT_THEME':
      return update(state, {
        $merge: action.patch
      })
    case 'FINALIZE_EDIT_THEME':
      return update(state, {
        isEditing: { $set: false }
      })
    case 'CONFIRM_EDIT_THEME_FINALIZATION':
      return update(state, {
        $unset: ['previousName']
      })
    case 'CANCEL_THEME_EDITING':
    case 'REVERT_EDIT_THEME_FINALIZATION':
      return update(state, {
        $merge: {
          isEditing: false,
          name: state.previousName,
          previousName: null
        }
      })
    case 'TOGGLE_THEME_FOCUS':
      return update(state, {
        isFocused: { $set: action.isFocused }
      })
    default:
      return state
  }
}

const findThemeIndex = (state, themeId) => state.findIndex(t => t.themeId === themeId)

const themesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME_DELETE_FLAG':
    case 'TOGGLE_THEME_COLLAPSED':
    case 'FINALIZE_ADD_THEME':
    case 'REVERT_ADD_THEME_FINALIZATION':
    case 'START_THEME_EDIT':
    case 'EDIT_THEME':
    case 'FINALIZE_EDIT_THEME':
    case 'CONFIRM_EDIT_THEME_FINALIZATION':
    case 'REVERT_EDIT_THEME_FINALIZATION':
    case 'CANCEL_THEME_EDITING':
    case 'TOGGLE_THEME_FOCUS':
      return update(state, {
        [findThemeIndex(state, action.themeId)]: { $apply: theme => themeReducer(theme, action) }
      })
    case 'START_ADD_THEME':
      return update(state, {
        $push: [{ ...action.theme, isEditing: true }]
      })
    case 'DELETE_THEME':
    case 'CANCEL_ADD_SUGGESTION':
    case 'CANCEL_ADD_THEME':
      return state.filter(t => t.themeId !== action.themeId)
    case 'CONFIRM_ADD_THEME_FINALIZATION':
      return update(state, {
        [findThemeIndex(state, action.themeId)]: { $set: action.theme }
      })
    case 'TOGGLE_THEME_SUGGESTION':
      return update(state, {
        $push: action.suggestedThemeSlice
      })
    default:
      return state
  }
}

const revertMove = (questions, action) => {
  const [question, index] = findQuestion(questions, action.questionId)
  return update(questions, {
    $splice: [
      [index, 1],
      [question.originIndex, 0, questionReducer(question, action)]
    ]
  })
}

const makeMove = (questions, action) => {
  // Account for index shift caused by removal, if any
  const toIndex = (action.fromIndex < action.toIndex) ? action.toIndex - 1 : action.toIndex
  // Two splice operations: remove question existing index followed by insert into new one
  return update(questions, {
    $splice: [
      [action.fromIndex, 1],
      [toIndex, 0, questionReducer(action.question, action)]
    ]
  })
}

// Descending comparison function where only unassigned questions are considered
// (stable sort ensures that all assigned questions remain in same order)
const similarityScore = (question) => (question.theme.themeId === 'default' ? -question.score : null)

const questionsReducer = (state = [], action) => {
  switch (action.type) {
    case 'INIT_QUESTIONS':
      return action.questions
    case 'END_MOVE_QUESTION':
    case 'ASSIGN_THEME':
    case 'CONFIRM_THEME_ASSIGNMENT':
      return update(state, {
        [state.findIndex(q => q.questionId === action.questionId)]: { $apply: q => questionReducer(q, action) }
      })
    case 'DELETE_THEME':
    case 'CLEAR_QUESTION_SORTING':
    case 'TOGGLE_THEME_SUGGESTION':
      return state.map(question => questionReducer(question, action))
    case 'MOVE_QUESTION':
      return makeMove(state, action)
    case 'REVERT_THEME_ASSIGNMENT':
      return revertMove(state, action)
    case 'FINALIZE_QUESTION_SORTING':
      return sortBy(state.map(question => questionReducer(question, action)), similarityScore)
    default:
      return state
  }
}

// TODO: Make this go null when any ongoing mutation and appear when there are none
const lastUpdateReducer = (state = null, action) => {
  switch (action.type) {
    case 'CONFIRM_ADD_THEME_FINALIZATION':
    case 'CONFIRM_EDIT_THEME_FINALIZATION':
    case 'CONFIRM_THEME_ASSIGNMENT':
    case 'DELETE_THEME':
      return new Date()
    default:
      return state
  }
}

const findUpdateIndex = (state, questionId) => state.findIndex(t => t.questionId === questionId)

const updateReducer = (state = {}, action) => {
  switch(action.type) {
    case 'ASSIGN_THEME':
      return update(state, {
        $merge: { status: 'PENDING' }
      })
    default:
      return state
  }

}

const updatesReducer = (state = [], action) => {
  switch(action.type) {
    case 'END_MOVE_QUESTION':
      return update(state, {
        $push: [{
          questionId: action.questionId,
          operation: 'THEME_ASSIGNMENT',
          status: action.status
        }]
      })
    case 'ASSIGN_THEME':
      return update(state, {
        [findUpdateIndex(state, action.questionId)]: { $apply: u => updateReducer(u, action) }
      })
    case 'CONFIRM_THEME_ASSIGNMENT':
    case 'REVERT_THEME_ASSIGNMENT':
      return update(state, {
        $splice: [
          [findUpdateIndex(state, action.questionId), 1]
        ]
      })
    default:
      return state
  }
}

const sortingReducer = (state = {}, action) => {
  switch (action.type) {
    case 'INIT_QUESTION_SORTING':
      return update(state, {
        $merge: {
          themeId: action.themeId,
          loading: true
        }
      })
    case 'FINALIZE_QUESTION_SORTING':
      return update(state, {
        loading: { $set: false }
      })
    case 'CLEAR_QUESTION_SORTING':
      if (state.themeId === action.themeId) {
        return {}
      }
      return state
    case 'CANCEL_QUESTION_SORTING':
      if (state.themeId === action.themeId) {
        return update(state, {
          loading: { $set: false }
        })
      }
      return state
    default:
      return state
  }
}

const suggestingReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CANCEL_ADD_SUGGESTION':
      return update(state, {
        rejectedThemes: { $apply: rt => rt ? [...rt, action.themeId] : [action.themeId] }
      })
    case 'TOGGLE_THEME_SUGGESTION':
      return update(state, {
        isSuggesting: { $set: true },
      })
    default:
      return state
  }
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'INIT_UNSAVED_THEME_SET':
      return update(state, {
        themeSet: { $set: action.themeSet }
      })
    case 'CANCEL_ADD_SUGGESTION':
    case 'TOGGLE_THEME_SUGGESTION':
      return update(state, {
        themeSet: { themes: { $apply: ts => themesReducer(ts, action) } },
        questions: { $apply: qs => questionsReducer(qs, action) },
        suggesting: { $apply: s => suggestingReducer(s, action) }
      })
    case 'TOGGLE_THEME_DELETE_FLAG':
    case 'TOGGLE_THEME_COLLAPSED':
    case 'TOGGLE_THEME_FOCUS':
    case 'START_ADD_THEME':
    case 'CANCEL_ADD_THEME':
    case 'FINALIZE_ADD_THEME':
    case 'CONFIRM_ADD_THEME_FINALIZATION':
    case 'REVERT_ADD_THEME_FINALIZATION':
    case 'START_THEME_EDIT':
    case 'EDIT_THEME':
    case 'FINALIZE_EDIT_THEME':
    case 'CONFIRM_EDIT_THEME_FINALIZATION':
    case 'REVERT_EDIT_THEME_FINALIZATION':
    case 'CANCEL_THEME_EDITING':
      return update(state, {
        themeSet: { themes: { $apply: ts => themesReducer(ts, action) } },
        lastUpdate: { $apply: lu => lastUpdateReducer(lu, action) }
      })
    case 'INIT_QUESTIONS':
    case 'ASSIGN_THEME':
    case 'CONFIRM_THEME_ASSIGNMENT':
    case 'REVERT_THEME_ASSIGNMENT':
      return update(state, {
        questions: { $apply: qs => questionsReducer(qs, action) },
        updates: { $apply: us => updatesReducer(us, action) },
        lastUpdate: { $apply: lu => lastUpdateReducer(lu, action) }
      })
    case 'MOVE_QUESTION':
      return update(state, {
        questions: { $apply: qs => questionsReducer(qs, action) }
      })
    case 'CLEAR_QUESTION_SORTING':
    case 'FINALIZE_QUESTION_SORTING':
      if (state.sorting && state.sorting.themeId === action.themeId) {
        return update(state, {
          sorting: { $apply: s => sortingReducer(s, action) },
          questions: { $apply: qs => questionsReducer(qs, action) }
        })
      }
      return state
    case 'INIT_QUESTION_SORTING':
    case 'CANCEL_QUESTION_SORTING':
      return update(state, {
        sorting: { $apply: s => sortingReducer(s, action) },
      })
    case 'END_MOVE_QUESTION':
      return update(state, {
        questions: { $apply: qs => questionsReducer(qs, action) },
        updates: { $apply: us => updatesReducer(us, action) }
      })
    case 'DELETE_THEME':
      return update(state, {
        themeSet: { themes: { $apply: ts => themesReducer(ts, action) } },
        questions: { $apply: qs => questionsReducer(qs, action) },
        lastUpdate: { $apply: lu => lastUpdateReducer(lu, action) }
      })
    default:
      return state
  }
}

export default reducer
