import Immutable from 'immutable'
import uuid from 'uuid/v4'


function newTheme(questionId) {
  return Immutable.fromJS({
    themeId: `client/${uuid()}`,
    isEditing: true,
    name: '',
    questionIds: questionId ? [questionId] : []
  })
}

let INITIAL_STATE = Immutable.fromJS({
  isLoading: false,
  lastUpdate: null,
  data: {},
  unsaved: null
})

const theme = (state = {}, action) => {
  switch (action.type) {
    case 'RENAME_QUESTION_THEME':
      if (state.get('themeId') === action.themeId) {
        return state.set('name', action.name)
      }
      return state
    case 'ADD_QUESTION_THEME':
    case 'MOVE_QUESTION_BETWEEN_THEMES':
      // Remove question from previous theme
      if (state.get('themeId') === action.fromThemeId) {
        state = state.update('questionIds',
          questionIds => questionIds.delete(questionIds.indexOf(action.questionId))
        )
      }
      // Add question to new theme
      if (state.get('themeId') === action.toThemeId) {
        state = state.update('questionIds',
          questionIds => questionIds.filter(q => q !== action.questionId).insert(0, action.questionId)
        )
      }
      return state.set('isEditing', false)
    case 'DELETE_QUESTION_THEME':
      // Concatenate questions (if any) from deleted theme onto default theme
      if (state.get('isDefault')) {
        return state.update('questionIds',
          questionIds => questionIds.concat(action.theme.questionIds)
        )
      }
      return state
    default:
      return state
  }
}

const unsaved = (state = {}, action) => {
  switch (action.type) {
    case 'MOVE_QUESTION_BETWEEN_THEMES':
    case 'RENAME_QUESTION_THEME':
      return state
        .update('themes', themes =>
          themes.map(t => theme(t, action))
        )
        .set('isDirty', true)
    case 'SET_THEME_SET_RATING':
      return state.set('alignmentRating', action.rating)
    case 'DELETE_QUESTION_THEME':
      return state
        .update('themes', themes => themes
          .delete(themes.findIndex(t => t.get('themeId') === action.theme.themeId))
          .map(t => theme(t, action))
        )
        .set('isDirty', true)
    case 'ADD_QUESTION_THEME':
      return state
        .update('themes', themes => themes
          .map(t => theme(t, action))
          .push(newTheme(action.questionId))
        )
        .set('isDirty', true)
    case 'INIT_UNSAVED_THEME_SET':
      return Immutable.fromJS(action.themeSet)
    default:
      return state
  }
}


const themes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MOVE_QUESTION_BETWEEN_THEMES':
    case 'RENAME_QUESTION_THEME':
    case 'DELETE_QUESTION_THEME':
    case 'ADD_QUESTION_THEME':
    case 'SET_THEME_SET_RATING':
    case 'INIT_UNSAVED_THEME_SET':
      return state.update('unsaved', u => unsaved(u, action))
    case 'GET_THEME_SET_REQUEST':
    case 'UPDATE_THEME_SET_REQUEST':
    case 'UPDATE_THEME_SET_META_REQUEST':
      return state.set('isLoading', true)
    case 'GET_THEME_SET_SUCCESS':
      return state
        .set('isLoading', false)
        .update('data', data => data.set(action.qvestId, Immutable.fromJS(action.themeSet)))
    case 'UPDATE_THEME_SET_SUCCESS':
    case 'UPDATE_THEME_SET_META_SUCCESS':
      return state
        .set('isLoading', false)
        .set('lastUpdate', new Date)
        .update('data', data => data.set(action.qvestId, Immutable.fromJS(action.themeSet)))
    case 'GET_THEME_SET_FAILURE':
    case 'UPDATE_THEME_SET_FAILURE':
    case 'UPDATE_THEME_SET_META_FAILURE':
      return state.set('isLoading', false)
    default:
      return state
  }
}


export function filterThemeSetsByQvestId(themes, qvestId) {
  if (!themes.data.hasOwnProperty(qvestId)) {
    return null
  }
  return themes.data[qvestId]
}


export default themes
