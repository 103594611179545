import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import debounce from 'lodash/debounce'
import { Helmet } from 'react-helmet'

import { maxValiditySeverity } from '../../../../utils/validationUtils'
import { determineNextWaveAt } from '../../../../utils/qvestUtils'
import { filterScheduleValidityByQvest } from '../../../../reducers/schedules'
import { filterQvestValidityById, filterQvestsById } from '../../../../reducers/qvests'
import { filterParticipantsByQvest, filterValidityByQvest } from '../../../../reducers/participants'
import { filterGroupingValidityByQvest, filterGroupingsByQvest } from '../../../../reducers/grouping'
import { filterSubscriptionValidityByOrganization } from '../../../../reducers/subscription'
import { changeUnsavedSchedule, saveSchedule, toggleScheduleEnabled, sendTestNotification } from '../../../../actions/scheduleActions'
import { getSubscriptionsValidity } from '../../../../actions/subscriptionActions'
import { getQvest } from '../../../../actions/qvestActions'
import { getParticipants } from '../../../../actions/participantActions'
import { getGroupingByQvestId } from '../../../../actions/groupingActions'
import Card from '../../../../components/common/Card/Card'
import Typography from '../../../../components/common/Typography/Typography'
import Modal from '../../../../components/common/Modal/Modal'
import ScheduledModal from '../../../../components/owner/ScheduledModal/ScheduledModal'
import SummaryModal from '../../../../components/owner/SummaryModal/SummaryModal'
import TestNotificationModal from '../../../../components/owner/TestNotificationModal/TestNotificationModal'
import ValidationTable from '../../../../components/owner/ValidationTable/ValidationTable'
import ScheduleCard from '../../../../components/owner/ScheduleCard/ScheduleCard'
import { filterMyUser } from '../../../../reducers/user'


const messages = defineMessages({
  title: { id: 'owner.OwnerQvestScheduleTab.title', defaultMessage: 'Scheduling | {qvestName}' },
})

const ComponentRoot = styled.div`
  padding: 20px;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`

const MinWidth = styled.div`
  min-width: 1350px;
`

const MODAL_CLOSE_DELAY = 1500 // ms

class OwnerQvestScheduleTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: undefined,
      shownModal: null,
      testVariant: null
    }

    this.debouncedOnSave = debounce(this.handleSave, 2000, { leading: true })
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const qvestId = match.params.qvestId
    dispatch(getQvest(qvestId))
      .then(qvest => {
        if (qvest) dispatch(getSubscriptionsValidity(qvest.organizationId))
      })
    dispatch(getParticipants(qvestId))
    dispatch(getGroupingByQvestId(qvestId))
  }

  componentWillUnmount() {
    this.debouncedOnSave.flush()
  }

  handleValidationClick = (sectionId) => {
    const { match, history } = this.props
    const qvestId = match.params.qvestId

    switch (sectionId) {
      case 'subscription':
        return null // Does nothing. If org has no sub then all interaction is blocked anyway (In case we choose to open up again, this handler needs to lead user to information about how to obtain an active subscription)
      case 'email':
        return history.push(`/owner/preview/qvest/${qvestId}/invite-email`)
      case 'page':
        return history.push(`/owner/preview/qvest/${qvestId}`)
      default:
        return history.push(`/owner/qvest/${qvestId}/${sectionId}`)
    }
  }

  handleCloseModal = () => {
    this.setState({ shownModal: null })
  }

  handleScheduledSubmit = () => {
    const { match, history, qvestStore } = this.props
    const qvest = filterQvestsById(qvestStore, match.params.qvestId)
    history.push(`/owner/organization/${qvest.organizationId}`)
  }

  handleShowSummary = () => {
    this.setState({ shownModal: 'summary' })
  }

  handleShowTest = (testVariant) => {
    this.setState({ shownModal: 'test', testVariant })
  }

  handleChange = ({ inviteAt, openAt, closeAt }) => {
    const { dispatch, match } = this.props
    const qvestId = match.params.qvestId
    if (inviteAt || openAt || closeAt) {
      dispatch(changeUnsavedSchedule(qvestId, { inviteAt, openAt, closeAt }))
      this.debouncedOnSave()
    }
  }

  handleTest = (name, email) => {
    const { testVariant } = this.state
    const { dispatch, match } = this.props
    const qvestId = match.params.qvestId
    return dispatch(sendTestNotification(qvestId, testVariant, name, email))
      .then(success => success && setTimeout(this.handleCloseModal, MODAL_CLOSE_DELAY))
  }

  handleSave = () => {
    const { dispatch, match } = this.props
    return dispatch(saveSchedule(match.params.qvestId))
  }

  handleInitiateClick = () => {
    const { dispatch, match } = this.props
    const qvestId = match.params.qvestId
    let savePromise = this.debouncedOnSave.flush() || Promise.resolve()
    savePromise
      .then(() => dispatch(toggleScheduleEnabled(qvestId, true)))
      // Refresh qvest such that new qvest state will update across UI (TODO: Decoupled schedule from qvest)
      .then(() => dispatch(getQvest(qvestId)))
      .then(() => this.setState({ shownModal: 'scheduled' }))
      // Schedule another refresh such that any immediate state change will be recognized
      .then(() => setTimeout(() => dispatch(getQvest(qvestId)), 5000))
  }

  handleUnscheduleClick = () => {
    const { dispatch, match } = this.props
    const qvestId = match.params.qvestId
    dispatch(toggleScheduleEnabled(qvestId, false))
      .then(() => dispatch(getQvest(qvestId)))
  }

  renderScheduledModal() {
    if (this.state.shownModal !== 'scheduled') return null
    return (
      <Modal onBackgroundClick={this.handleCloseModal} onEscape={this.handleCloseModal}>
        <ScheduledModal onSubmit={this.handleScheduledSubmit} />
      </Modal>
    )
  }

  renderSummaryModal() {
    if (this.state.shownModal !== 'summary') return null
    const { qvestStore, participantStore, groupingStore, scheduleStore, match } = this.props
    const qvestId = match.params.qvestId
    const qvest = filterQvestsById(qvestStore, qvestId)
    const grouping = filterGroupingsByQvest(groupingStore, qvestId)
    const participants = filterParticipantsByQvest(participantStore, qvestId)
    const schedule = scheduleStore.unsaved
    const waveAt = determineNextWaveAt(qvest, schedule)
    return (
      <Modal onBackgroundClick={this.handleCloseModal} onEscape={this.handleCloseModal}>
        <SummaryModal
          topic={qvest.topic}
          groups={grouping ? grouping.groups : null}
          participantCount={participants.length}
          inviteAt={qvest.hasInvite ? schedule.inviteAt : null}
          openAt={schedule.openAt}
          closeAt={schedule.closeAt}
          waveAt={waveAt}
          onClose={this.handleCloseModal}
          onConfirm={this.handleInitiateClick}
        />
      </Modal>
    )
  }

  renderTestModal() {
    const { userStore, scheduleStore } = this.props
    const { shownModal, testVariant } = this.state
    if (shownModal !== 'test') return null
    const user = filterMyUser(userStore)
    const error = scheduleStore.error
    return (
      <Modal onBackgroundClick={this.handleCloseModal} onEscape={this.handleCloseModal}>
        <TestNotificationModal
          loading={scheduleStore.isLoading}
          error={error ? error.message : null}
          name={user.fullName}
          email={user.email}
          variant={testVariant}
          onSubmit={this.handleTest}
          onCancel={this.handleCloseModal}
          onClose={this.handleCloseModal}
        />
      </Modal>
    )
  }

  renderValidationCard(validityItems) {
    return (
      <MinWidth>
        <Card>
          <Card.Header>
            <Typography variant="heading3">
              <FormattedMessage id="owner.QvestScheduleTab.recap" defaultMessage="Checklist" />
            </Typography>
          </Card.Header>
          <ValidationTable onClick={this.handleValidationClick} items={validityItems} />
        </Card>
      </MinWidth>
    )
  }

  renderScheduleCard(qvest, generalValidity, scheduleValidity) {
    const { scheduleStore } = this.props
    const schedule = scheduleStore.unsaved

    if (!schedule || !qvest) {
      return null
    }

    const isScheduled = (qvest.state === 'QVEST_SCHEDULED')

    return (
      <ScheduleCard
        qvest={qvest}
        schedule={schedule}
        scheduleValidity={scheduleValidity}
        generalValidity={generalValidity}
        lastUpdate={scheduleStore.lastUpdate}
        onSubmit={isScheduled ? this.handleUnscheduleClick : this.handleShowSummary}
        onChange={this.handleChange}
        onSendTest={this.handleShowTest}
      />
    )
  }

  render() {
    const {
      qvestStore,
      participantStore,
      groupingStore,
      subscriptionStore,
      scheduleStore,
      match,
      intl,
    } = this.props
    const qvestId = match.params.qvestId
    const qvest = filterQvestsById(qvestStore, qvestId)
    const qvestValidity = filterQvestValidityById(qvestStore, qvestId)
    const participantsValidity = filterValidityByQvest(participantStore, qvestId)
    const groupingValidity = filterGroupingValidityByQvest(groupingStore, qvestId)
    const subscriptionValidity = filterSubscriptionValidityByOrganization(subscriptionStore, qvest.organizationId)

    if (!participantsValidity || !groupingValidity || !subscriptionValidity) return null

    const validityItems = [
      ...qvestValidity.items,
      ...participantsValidity.items,
      ...groupingValidity.items,
      ...subscriptionValidity.items
    ]

    const scheduleValidity = filterScheduleValidityByQvest(scheduleStore, qvestId)
    const generalValidity = maxValiditySeverity([
      qvestValidity,
      participantsValidity,
      groupingValidity,
      subscriptionValidity
    ])

    return (
      <ComponentRoot>
        <Helmet>
          <title>{intl.formatMessage(messages.title, { qvestName: qvest && qvest.name })}</title>
        </Helmet>
        {this.renderSummaryModal()}
        {this.renderScheduledModal()}
        {this.renderTestModal()}
        {this.renderValidationCard(validityItems)}
        {this.renderScheduleCard(qvest, generalValidity, scheduleValidity)}
      </ComponentRoot>
    )
  }
}

function mapStateToProps(state) {
  return {
    scheduleStore: state.schedules.toJS(),
    qvestStore: state.qvests.toJS(),
    participantStore: state.participants.toJS(),
    groupingStore: state.grouping.toJS(),
    subscriptionStore: state.subscription.toJS(),
    userStore: state.user.toJS()
  }
}

export default connect(mapStateToProps)(injectIntl(OwnerQvestScheduleTab))
