import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { hexaToRgba } from '../../../utils/styleUtils'
import CenteredLoader from '../CenteredLoader/CenteredLoader'
import Message from './Message'


const ComponentRoot = styled.div`
  width: 100%;
  background-color: ${({ secondary, theme }) => secondary
    ? theme.background2
    : theme.background1};
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.04);
`

const RelativePosition = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  max-height: ${({ limitHeight }) => limitHeight ? '100vh' : 'none'};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 20px;
  min-height: 68px;
  border-bottom: 1px solid ${props => hexaToRgba(props.theme.default + '1A')}; /* 10% opacity */
  & > *:nth-child(2) { margin-left: auto; }
`

const Body = styled.div`
  padding: ${({ wide }) => wide ? '25px' : '14px 20px'};
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  & > * + * { margin-left: 20px; }
`

const ChartBox = styled.div`
  background-color: ${props => hexaToRgba(props.theme.background2 + '10')}; /* ~6% opacity */
  border-width: 1px;
  border-color: ${props => hexaToRgba(props.theme.main.primary + '10')}; /* ~6% opacity */
  border-style: solid none;
  padding: 20px;
`

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba(255,255,255,0.7);
  border-radius: 4px;
`

const renderLoader = (isLoading) => {
  return (
    <LoadingOverlay visible={isLoading}>
      <CenteredLoader size="medium" />
    </LoadingOverlay>
  )
}

const Card = ({ className, cardRef, secondary, isLoading, children, limitHeight }) => (
  <ComponentRoot className={className} ref={cardRef} secondary={secondary}>
    <RelativePosition limitHeight={limitHeight}>
      {children}
      {renderLoader(isLoading)}
    </RelativePosition>
  </ComponentRoot>
)

Card.propTypes = {
  cardRef: PropTypes.object,
  secondary: PropTypes.bool,
  isLoading: PropTypes.bool,
  limitHeight: PropTypes.bool
}

Card.defaultProps = {
  secondary: false,
  isLoading: false,
  limitHeight: false
}

Card.Header = Header
Card.Body = Body
Card.ChartBox = ChartBox
Card.Message = Message
Card.Footer = Footer

export default Card
