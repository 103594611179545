import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { Switch, Route, Redirect } from 'react-router-dom'
import { defineMessages, injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

import Modal from '../../../../components/common/Modal/Modal'
import ReportModal from '../../../../components/owner/ReportModal/ReportModal'
import AnalyticsSidebar from '../../../../components/owner/AnalyticsSidebar/AnalyticsSidebar'

import { filterQvestsById, filterQvestsByOrganization } from '../../../../reducers/qvests'
import { filterSubscriptionAnalyticsTier } from '../../../../reducers/subscription'
import { getQvestSummary } from '../../../../actions/statsActions'
import { filterQvestSummaryByQvestId } from '../../../../reducers/stats'
import { hasSufficientData } from '../../../../components/owner/AnalyticsGrid/AnalyticsGrid.js'
import OwnerAnalyticsHomePage from './analytics/OwnerAnalyticsHomePage'
import OwnerMindsetPage from './analytics/OwnerMindsetPage'
import OwnerAnalyticsActivityPage from './analytics/OwnerAnalyticsActivityPage'
import OwnerAnalyticsDistributionPage from './analytics/OwnerAnalyticsDistributionPage'
import OwnerKeyStakeholdersPage from './analytics/OwnerKeyStakeholdersPage'
import OwnerInteractionPage from './analytics/OwnerInteractionPage'
import OwnerNetworkPage from './analytics/OwnerNetworkPage'
import OwnerHighlightsPage from './analytics/OwnerHighlightsPage'
import OwnerFocusPage from './analytics/OwnerFocusPage'
import OwnerQuestionThemesPage from './analytics/OwnerQuestionThemesPage'
import OwnerInsightsPage from './analytics/OwnerInsightsPage'
import { getQvestReportInfo, getQvestsByOrganization, orderReport, clearQvestReport } from '../../../../actions/qvestActions'
import { isDoneQvestState } from '../../../../utils/qvestUtils'
import ComparisonReportModal from '../../../../components/owner/ComparisonReportModal/ComparisonReportModal'

const TABS = [{
  id: 'activity',
  group: 'commitment',
  isNew: false,
  tier: 1
}, {
  id: 'mindset',
  group: 'commitment',
  isNew: false,
  tier: 2
}, {
  id: 'stakeholders',
  group: 'commitment',
  isNew: false,
  tier: 3
}, {
  id: 'distribution',
  group: 'connections',
  isNew: false,
  tier: 1
}, {
  id: 'interaction',
  group: 'connections',
  isNew: false,
  tier: 2
}, {
  id: 'network',
  group: 'connections',
  isNew: false,
  tier: 3
}, {
  id: 'highlights',
  group: 'communication',
  isNew: false,
  tier: 1
}, {
  id: 'focus',
  group: 'communication',
  isNew: false,
  tier: 2
}, {
  id: 'themes',
  group: 'communication',
  isNew: false,
  tier: 3
}, {
  id: 'qvestReport',
  group: 'reports',
  isNew: false,
  tier: 3
}, {
  id: 'comparisonReport',
  group: 'reports',
  isNew: false,
  tier: 3
}]

const TAB_GROUPS = {
  commitment: 'main',
  connections: 'main',
  communication: 'main',
  reports: 'download'
}

const messages = defineMessages({
  home: { id: 'owner.QvestAnalyticsTab.home', defaultMessage: 'Analytics home' },
  activity: { id: 'owner.QvestAnalyticsTab.activity', defaultMessage: 'Activity' },
  mindset: { id: 'owner.QvestAnalyticsTab.mindset', defaultMessage: 'Mindset' },
  stakeholders: { id: 'owner.QvestAnalyticsTab.stakeholders', defaultMessage: 'Key Stakeholders' },
  distribution: { id: 'owner.QvestAnalyticsTab.distribution', defaultMessage: 'Distribution' },
  interaction: { id: 'owner.QvestAnalyticsTab.interaction', defaultMessage: 'Interaction' },
  network: { id: 'owner.QvestAnalyticsTab.network', defaultMessage: 'Network' },
  highlights: { id: 'owner.QvestAnalyticsTab.highlights', defaultMessage: 'Highlights' },
  profile: { id: 'owner.QvestAnalyticsTab.profile', defaultMessage: 'Question Profile' },
  focus: { id: 'owner.QvestAnalyticsTab.focus', defaultMessage: 'Focus' },
  themes: { id: 'owner.QvestAnalyticsTab.themes', defaultMessage: 'Theme Builder' },
  commitment: { id: 'owner.QvestAnalyticsTab.commitment', defaultMessage: 'Commitment to Qvest project' },
  connections: { id: 'owner.QvestAnalyticsTab.connections', defaultMessage: 'Connections among Qvest stakeholders' },
  communication: { id: 'owner.QvestAnalyticsTab.communication', defaultMessage: 'Communication about Qvest topic' },
  reports: { id: 'owner.QvestAnalyticsTab.reports', defaultMessage: 'Download Report' },
  qvestReport: { id: 'owner.QvestAnalyticsTab.qvestReport', defaultMessage: 'Single Qvest Report' },
  comparisonReport: { id: 'owner.QvestAnalyticsTab.comparisonReport', defaultMessage: 'Comparison Qvest Report' },
  title: { id: 'owner.OwnerQvestAnalyticsTab.title', defaultMessage: 'Analytics | {qvestName}' },
})

class QvestAnalyticsTab extends Component {
  state = {
    modal: null,
    reportLoading: null,
    reportComplete: null,
    selectedComparison: null,
    isGeneratingReport: false,
    reportError: null
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getQvestSummary(match.params.qvestId))
  }

  handleTabClick = (tabId, parentId) => {
    const { match, history } = this.props
    const qvestId = match.params.qvestId
    if (parentId === 'download') {
      return this.handleDownloadClick(tabId)
    }
    history.push(`/owner/qvest/${qvestId}/analytics/${tabId}`)
  }

  handleDownloadClick = (tabId) => {
    const { dispatch, match, qvestStore } = this.props
    const qvestId = match.params.qvestId
    this.setState({ modal: tabId, reportError: null })
    if (tabId === 'qvestReport') {
      dispatch(getQvestReportInfo(qvestId))
        .catch(reportError => this.setState({ reportError }))
    } else if (tabId === 'comparisonReport') {
      const qvest = filterQvestsById(qvestStore, qvestId)
      dispatch(getQvestsByOrganization(qvest.organizationId, true))
    }
  }

  handleGenerateClick = () => {
    const { dispatch, match } = this.props
    const qvestId = match.params.qvestId
    dispatch(clearQvestReport(qvestId))
      .then(() => dispatch(getQvestReportInfo(qvestId)))
      .catch(reportError => this.setState({ reportError }))
  }

  handleComparisonSelection = (qvestIds) => {
    this.setState({ selectedComparison: qvestIds })
  }

  handleComparisonOrder = (format) => {
    const qvestIds = this.state.selectedComparison
    const { dispatch } = this.props
    this.setState({ reportLoading: true })
    return dispatch(orderReport(qvestIds, format))
      .then(() => this.setState({ reportLoading: false, reportComplete: true }))
  }

  handleCloseModal = () => {
    this.setState({
      modal: null,
      selectedComparison: null,
      reportLoading: null,
      reportComplete: null,
      reportError: null
    })
  }

  createTabs(userTier, hasData) {
    const { intl } = this.props
    return TABS.map(({ id, group, isNew, tier }) => ({
      id,
      isNew,
      label: intl.formatMessage(messages[id]),
      group: intl.formatMessage(messages[group]),
      parent: TAB_GROUPS[group],
      disabled: !hasData || (tier > userTier)
    }))
  }

  renderReportModal(qvest) {
    if (this.state.modal !== 'qvestReport') return null
    let options
    if (qvest.report) {
      options = qvest.report.assets
    }
    const closeModal = () => this.setState({ modal: null })
    return (
      <Modal onBackgroundClick={closeModal} onEscape={closeModal}>
        <ReportModal
          options={options}
          onGenerate={this.handleGenerateClick}
          onCancel={this.handleCloseModal}
          error={this.state.reportError}
        />
      </Modal>
    )
  }

  renderComparisonReportModal(qvest) {
    const { qvestStore } = this.props
    const { modal, selectedComparison, reportLoading, reportComplete } = this.state
    if (modal !== 'comparisonReport') return null
    let qvests = filterQvestsByOrganization(qvestStore, qvest.organizationId)
    const hasSelection = (selectedComparison != null)
    const handleSubmit = (hasSelection ? this.handleComparisonOrder : this.handleComparisonSelection)
    return (
      <Modal onEscape={this.handleCloseModal}>
        <ComparisonReportModal
          format={hasSelection}
          loading={reportLoading}
          complete={reportComplete}
          main={qvest.qvestId}
          qvests={qvests}
          onSubmit={handleSubmit}
          onCancel={this.handleCloseModal}
        />
      </Modal>
    )
  }

  render() {
    const { qvestStore, summaryStore, subscriptionStore, match, intl } = this.props
    const qvestId = match.params.qvestId
    const qvest = filterQvestsById(qvestStore, qvestId)
    const userTier = filterSubscriptionAnalyticsTier(subscriptionStore, qvest) || 1
    const summary = filterQvestSummaryByQvestId(summaryStore, qvestId)
    const hasData = hasSufficientData(summary)
    const isDone = isDoneQvestState(qvest.state)
    const showInsights = (process.env.INSIGHTS_ENABLED !== 'false') && (userTier === 3)
    const pathPrefix = '/owner/qvest/:qvestId/analytics'
    return (
      <AnalyticsSidebar
        tabs={this.createTabs(userTier, hasData)}
        isDone={isDone}
        showInsights={showInsights}
        activeTab={match.params.tabId}
        onTabClick={this.handleTabClick}
      >
        <Helmet>
          <title>{intl.formatMessage(messages.title, { qvestName: qvest && qvest.name })}</title>
        </Helmet>
        <Switch>
          <Route path={`${pathPrefix}/home`} component={OwnerAnalyticsHomePage} />
          <Route path={`${pathPrefix}/mindset`} component={OwnerMindsetPage} />
          <Route path={`${pathPrefix}/activity`} component={OwnerAnalyticsActivityPage} />
          <Route path={`${pathPrefix}/distribution`} component={OwnerAnalyticsDistributionPage} />
          <Route path={`${pathPrefix}/stakeholders`} component={OwnerKeyStakeholdersPage} />
          <Route path={`${pathPrefix}/interaction`} component={OwnerInteractionPage} />
          <Route path={`${pathPrefix}/network`} component={OwnerNetworkPage} />
          <Route path={`${pathPrefix}/highlights`} component={OwnerHighlightsPage} />
          <Route path={`${pathPrefix}/focus`} component={OwnerFocusPage} />
          <Route path={`${pathPrefix}/themes`} component={OwnerQuestionThemesPage} />
          <Route path={`${pathPrefix}/project-map`} component={OwnerInsightsPage} />
          <Redirect to={`/owner/qvest/${qvestId}/analytics/home`} />
        </Switch>
        {this.renderReportModal(qvest)}
        {this.renderComparisonReportModal(qvest)}
        <div ref={elm => this.stageElm = elm} />
      </AnalyticsSidebar>
    )
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    subscriptionStore: state.subscription.toJS(),
    groupingStore: state.grouping.toJS(),
    participationStore: state.stats.get('participation').toJS(),
    participantsActivityStore: state.stats.get('participantsActivity').toJS(),
    summaryStore: state.stats.get('qvestSummary').toJS(),
    groupGraphStore: state.stats.get('groupGraph').toJS(),
    wordFrequenciesStore: state.stats.get('wordFrequencies').toJS(),
    questionDistributionStore: state.stats.get('questionDistribution').toJS(),
    themeStore: state.themes.toJS()
  }
}

export default connect(mapStateToProps)(injectIntl(withTheme(QvestAnalyticsTab)))
