import XLSX from 'xlsx'
import trimWhitespace from 'lodash/trim'
import flatten from 'lodash/flatten'
import { pivotColumns } from './objectUtils'
import { readAsBinaryString }  from './fileUploadUtils'
import { makeFilename } from './stringUtils'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'

function toArrayBuffer(s) {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i=0; i !== s.length; ++i)
    view[i] = s.charCodeAt(i) & 0xFF
  return buf
}

export function questionDumpToExcelSheet(questionDump) {
  // Initialize workbook (spreadsheet)
  const wb = XLSX.utils.book_new()

  // Prepare unique integer ids to replace less readable thread ids
  const threadIdToInteger = {}
  questionDump.map(q => q.threadId).forEach((tid, id) => {
    if (!threadIdToInteger.hasOwnProperty(tid)) {
      threadIdToInteger[tid] = (id + 1)
    }
  })

  // Make "Questions" sheet
  const displayedQuestions = questionDump.filter(question => question.questionContent != null)
  const formattedDump = displayedQuestions.map(question => ({
    'Thread': threadIdToInteger[question.threadId],
    'Question': question.questionContent,
    'Answer': question.answerContent,
    'Questioner': question.questionParticipantName,
    'Respondent': question.answerParticipantName,
    'Question asked date': question.questionSentAt,
    'Answer given date': question.answerSentAt,
    'Questioner Group 1': question.questionGroupName,
    'Respondent Group 1': question.answerGroupName
  }))
  const ws = XLSX.utils.json_to_sheet(formattedDump)
  ws['!cols'] = [
    { width: 6 },
    { width: 40 },
    { width: 40 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 18 },
    { width: 20 },
    { width: 20 }
  ]
  XLSX.utils.book_append_sheet(wb, ws, 'Questions')

  // Make metadata sheet, if any metadata found
  const hasMetadata = questionDump.some(question => question.metadata != null)
  if (hasMetadata) {
    const questionerList = []
    const questionsByQuestioner = groupBy(questionDump, question => question.questionParticipantName)
    for (const [questioner, questions] of Object.entries(questionsByQuestioner)) {
      let row = {
        'Participant': questioner
      }
      // Merge metadata into row, if any
      const question = questions[0]
      if (question.metadata != null) {
        const metadata = JSON.parse(question.metadata)
        row = { ...row, ...metadata }
      }
      questionerList.push(row)
    }
    const sortedQuestionerList = sortBy(questionerList, 'Participant')
    const participantSheet = XLSX.utils.json_to_sheet(sortedQuestionerList)
    XLSX.utils.book_append_sheet(wb, participantSheet, 'metadata')
  }

  // Write workbook (use type 'binary')
  const wbout = XLSX.write(wb, { bookType:'xlsx', type:'binary' })

  // Return as array buffer
  return toArrayBuffer(wbout)
}

export function excelSheetToParticipants(file) {
  return new Promise((resolve, reject) => {
    // Read and parse excel sheet
    const reader = new FileReader()
    reader.onload = (event) => {
      let data = (event ? event.target.result : reader.content)
      try {
        // Parse file
        const workbook = XLSX.read(data, { type: 'binary' })
        const sheetNames = workbook.SheetNames.filter(name => name !== 'metadata')
        const hasMultipleSheets = (sheetNames.length > 1)
        const hasMetadata = workbook.SheetNames.some(name => name === 'metadata')

        // Identify columns (look for header row, revert to first 2 columns if no header found)
        const identifyNameHeader = (value) => {
          const valueLower = value.toLowerCase()
          const isName = valueLower.includes('name') || valueLower.includes('navn')
          return isName ? value : null
        }
        const identifyEmailHeader = (value) => {
          const valueLower = value.toLowerCase()
          const isEmail = valueLower.includes('mail')
          return isEmail ? value : null
        }
        // Convert sheet to array of participants
        const sheetToParticipants = (sheetName, worksheet, metadata) => {
          const cells = ['A1','B1','C1','D1','E1','F1','G1','H1','I1','J1']
          let header, nameCell, emailCell
          for (const cell of cells) {
            if(worksheet[cell]) {
              nameCell = identifyNameHeader(worksheet[cell].v)
              if (nameCell) {
                break
              }
            }
          }
          for (const cell of cells) {
            if(worksheet[cell]) {
              emailCell = identifyEmailHeader(worksheet[cell].v)
              if (emailCell) {
                break
              }
            }
          }
          if (!(nameCell && emailCell)) {
            header = ['name', 'email']
            nameCell = header[0]
            emailCell = header[1]
          }

          // Convert to JSON
          let json = XLSX.utils.sheet_to_json(worksheet, { header })

          // Filter out rows where both cells are empty
          const isEmptyField = (obj, field) => (obj[field] && !obj[field].match(/\w+/))
          json = json.filter(obj => !isEmptyField(obj, nameCell) && !isEmptyField(obj, emailCell))

          // Throw if rows found with missing values in one cell
          if (json.some(p => !p[nameCell])) return reject(new Error('Rows without name'))
          if (json.some(p => !p[emailCell])) return reject(new Error('Rows without e-mail'))

          // Extract relevant values
          let participants = json.map(p => ({
            name: trimWhitespace(p[nameCell]),
            email: trimWhitespace(p[emailCell])
          }))

          // Join metadata, if any
          if (hasMetadata) {
            participants = participants.map(p => ({
              ...p,
              metadata: (metadata.hasOwnProperty(p.email) ? metadata[p.email] : undefined)
            }))
          }

          // Add grouping connection with sheet name, if multiple sheets
          if (hasMultipleSheets) {
            return participants.map(participant => ({
              ...participant,
              groupingConnections: { groupName: sheetName }
            }))
          }

          return participants
        }

        // Prepare metadata dictionary, if needed
        let metadata = {}
        if (hasMetadata) {
          const worksheet = workbook.Sheets.metadata
          const emailKey = worksheet['A1'].v
          const data = XLSX.utils.sheet_to_json(worksheet)
          for (const row of data) {
            const values = omit(row, [emailKey])
            metadata[row[emailKey]] = values
          }
        }

        // Convert sheets to lists of participants
        const sheetParticipants = sheetNames.map(sheetName => ({
          sheetName,
          participants: sheetToParticipants(sheetName, workbook.Sheets[sheetName], metadata)
        }))

        // Flatten into combined list of all imported paticipants
        const allParticipants = flatten(sheetParticipants.map(sp => sp.participants))

        // Validate length, if less than 1 participant found reject entirely
        if (allParticipants.length < 1) {
          return reject(new Error('No rows found'))
        }

        // Collect group names and participant counts, if multiple sheets
        let groups
        if (hasMultipleSheets) {
          groups = sheetParticipants.map(sp => ({
            name: sp.sheetName,
            count: sp.participants.length
          }))
        }

        resolve({ groups, participants: allParticipants })
      } catch(err) {
        console.error(err)
        reject(err)
      }
    }
    reader.onerror = (err) => {
      reject(err)
    }
    reader.onabort = () => {
      reject()
    }
    readAsBinaryString(reader, file)
  })
}

export function participantsToExcelSheet(qvestName, participants) {
  return new Promise((resolve, reject) => {
    // Bucket into groups
    const mapGroupName = participant => {
      if (participant.groupingConnections && participant.groupingConnections.length > 0) {
        return participant.groupingConnections[0].groupName
      }
      return '(Ungrouped)'
    }
    const grouped = groupBy(participants, mapGroupName)
    // Create sheet for each group [name, email]
    const sheets = []
    for (const [groupName, participants] of Object.entries(grouped)) {
      const rows = participants.map(({ name, email }) => ({ name, email }))
      const content = XLSX.utils.json_to_sheet(rows)
      sheets.push({ groupName, content })
    }
    // Make workbook with all sheets
    const wb = XLSX.utils.book_new()
    sheets.forEach(sheet => {
      try {
        const sheetName = sheet.groupName.substring(0,31) // Sheet name is not allowed to exceed 31 characters
        XLSX.utils.book_append_sheet(wb, sheet.content, sheetName)
      } catch (error) {
        reject(error)
      }
    })
    // Download as xlsx-file
    const fileName = makeFilename(qvestName, 'Participants', 'xlsx')
    const options = { bookType:'xlsx', type:'binary' }
    try {
      XLSX.writeFile(wb, fileName, options)
    } catch (ex) {
      reject(ex)
    }
    resolve()
  })
}

export function questionThemesToExcelSheet(qvestName, questions, participants, themes) {
  return new Promise((resolve, reject) => {
    // Prepare Q/A tab
    const qaTab = {}
    themes.forEach(theme => {
      const questionsContent = theme.questionIds.map(questionId => questions[questionId].questionContent)
      const answersContent = theme.questionIds.map(questionId => questions[questionId].answerContent)
      qaTab[`${theme.name} (Q)`] = questionsContent
      qaTab[`${theme.name} (A)`] = answersContent
    })
    const qaTabPivoted = pivotColumns(qaTab)
    const qaSheet = XLSX.utils.json_to_sheet(qaTabPivoted)

    // Wrap text (TODO: Make this work)
    Object.keys(qaSheet)
      .filter(key => key !== '!ref')
      .forEach(key => qaSheet[key].r = `<t>${qaSheet[key].v}</t>`)

    // Prepare participants tab
    const parTab = {}
    themes.forEach(theme => {
      const participantNames = []
      theme.questionIds.forEach(questionId => {
        const name = questions[questionId].questionParticipantName
        if (participantNames.indexOf(name) === -1) participantNames.push(name)
      })
      parTab[theme.name] = participantNames
    })
    const parTabPivoted = pivotColumns(parTab)
    const parSheet = XLSX.utils.json_to_sheet(parTabPivoted)

    // Prepare distribution tab
    const disTab = {}
    themes.forEach(theme => disTab[theme.name] = theme.questionIds.length)
    const disSheet = XLSX.utils.json_to_sheet([disTab])

    // Make workbook with all sheets
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, qaSheet, 'Question Theme List')
    XLSX.utils.book_append_sheet(wb, parSheet, 'Participant Theme List')
    XLSX.utils.book_append_sheet(wb, disSheet, 'Distribution across themes')

    // Download as xlsx-file
    const fileName = makeFilename(qvestName, 'Question Themes', 'xlsx')
    const options = { bookType:'xlsx', type:'binary' }
    try {
      XLSX.writeFile(wb, fileName, options)
    } catch (ex) {
      reject(ex)
    }
    resolve()
  })
}

export function questionThemesToExcelSheet2(qvestName, questions, themes) {
  return new Promise((resolve, reject) => {
    // Group questions by theme
    const themeSetId = themes[0].themeSet.themeSetId
    const questionsByTheme = groupBy(questions, question => {
      if (question.themes) {
        const assignment = question.themes.assignments.find(a => a.themeSet.themeSetId === themeSetId)
        if (assignment) return assignment.theme.themeId
      }
      return undefined
    })
    themes.forEach(({ themeId })  => {
      if (!questionsByTheme.hasOwnProperty(themeId)) {
        questionsByTheme[themeId] = []
      }
    })

    // Prepare Q/A tab
    const qaTab = {}
    themes.forEach(theme => {
      const questionsContent = questionsByTheme[theme.themeId].map(q => q.questionContent)
      const answersContent = questionsByTheme[theme.themeId].map(q => q.answerContent)
      qaTab[`${theme.name} (Q)`] = questionsContent
      qaTab[`${theme.name} (A)`] = answersContent
    })
    const qaTabPivoted = pivotColumns(qaTab)
    const qaSheet = XLSX.utils.json_to_sheet(qaTabPivoted)

    // Wrap text (TODO: Make this work)
    Object.keys(qaSheet)
      .filter(key => key !== '!ref')
      .forEach(key => qaSheet[key].r = `<t>${qaSheet[key].v}</t>`)

    // Prepare distribution tab
    const disTab = {}
    themes.forEach(theme => disTab[theme.name] = questionsByTheme[theme.themeId].length)
    const disSheet = XLSX.utils.json_to_sheet([disTab])

    // Make workbook with all sheets
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, qaSheet, 'Question Theme List')
    XLSX.utils.book_append_sheet(wb, disSheet, 'Distribution across themes')

    // Download as xlsx-file
    const fileName = makeFilename(qvestName, 'Question Themes', 'xlsx')
    const options = { bookType:'xlsx', type:'binary' }
    try {
      XLSX.writeFile(wb, fileName, options)
    } catch (ex) {
      reject(ex)
    }
    resolve()
  })
}

export function questionDistributionToExcelSheet(qvestName, questions, distribution, headers) {
  return new Promise((resolve, reject) => {
    // Prepare "Why" sheet
    const whyTab = {}
    const whyEntries = distribution.raw.filter(e => e.isWhy)
    whyTab[headers.whyQuestions] = whyEntries.map(e => questions[e.questionId].questionContent)
    whyTab[headers.whyAnswers] = whyEntries.map(e => questions[e.questionId].answerContent)
    const whySheet = XLSX.utils.json_to_sheet(pivotColumns(whyTab))

    // Prepare "Who" sheet
    const whoTab = {}
    const whoEntries = distribution.raw.filter(e => e.isWho)
    whoTab[headers.whoQuestions] = whoEntries.map(e => questions[e.questionId].questionContent)
    whoTab[headers.whoAnswers] = whoEntries.map(e => questions[e.questionId].answerContent)
    const whoSheet = XLSX.utils.json_to_sheet(pivotColumns(whoTab))

    // Prepare "What" sheet
    const whatTab = {}
    const whatEntries = distribution.raw.filter(e => e.isWhat)
    whatTab[headers.whatQuestions] = whatEntries.map(e => questions[e.questionId].questionContent)
    whatTab[headers.whatAnswers] = whatEntries.map(e => questions[e.questionId].answerContent)
    const whatSheet = XLSX.utils.json_to_sheet(pivotColumns(whatTab))

    // Prepare "How" sheet
    const howTab = {}
    const howEntries = distribution.raw.filter(e => e.isHow)
    howTab[headers.howQuestions] = howEntries.map(e => questions[e.questionId].questionContent)
    howTab[headers.howAnswers] = howEntries.map(e => questions[e.questionId].answerContent)
    const howSheet = XLSX.utils.json_to_sheet(pivotColumns(howTab))

    // Prepare "Other" sheet
    const otherTab = {}
    const otherEntries = distribution.raw.filter(e => !(e.isWhy || e.isWho || e.isWhat || e.isHow))
    otherTab[headers.otherQuestions] = otherEntries.map(e => questions[e.questionId].questionContent)
    otherTab[headers.otherAnswers] = otherEntries.map(e => questions[e.questionId].answerContent)
    const otherSheet = XLSX.utils.json_to_sheet(pivotColumns(otherTab))

    // Make workbook with all sheets
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, whySheet, headers.whyTitle)
    XLSX.utils.book_append_sheet(wb, whoSheet, headers.whoTitle)
    XLSX.utils.book_append_sheet(wb, whatSheet, headers.whatTitle)
    XLSX.utils.book_append_sheet(wb, howSheet, headers.howTitle)
    XLSX.utils.book_append_sheet(wb, otherSheet, headers.otherTitle)

    // Download as xlsx-file
    const fileName = makeFilename(qvestName, 'Question Profile', 'xlsx')
    const options = { bookType:'xlsx', type:'binary' }
    try {
      XLSX.writeFile(wb, fileName, options)
    } catch (ex) {
      reject(ex)
    }
    resolve()
  })
}
