
const YOUTUBE_REGEX = /http[s]?:\/\/w{0,3}\.?youtu\.?be(.com)?\/(watch\?v=|embed\/)?([^&?\n]+)/

// from: https://github.com/regexhq/vimeo-regex
const VIMEO_REGEX = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/

export function youTubeUrlToVideoId(url) {
  const match = YOUTUBE_REGEX.exec(url)
  if (match) {
    return match[3]
  }
}

export function isVimeoUrl(url) {
  return (VIMEO_REGEX.exec(url) !== null)
}

export function vimeoUrlToVideoId(url) {
  const match = VIMEO_REGEX.exec(url)
  if (match) {
    return match[4]
  }
}
