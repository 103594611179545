import 'react-day-picker/lib/style.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, defineMessages } from 'react-intl'
import styled from 'styled-components'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'


const DateTimeWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;

  & input {
    height: 45px;
    width: 185px;
    padding: 12px 15px;
    background-color: ${({theme}) => theme.main.tertiary};
    border: 1px solid ${({ theme }) => theme.two[1]};
    border-radius: 3px;
    color: ${({theme}) => theme.default};
    font-family: ${({theme}) => theme.font1};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:focus, &:hover:not(:disabled) {
      border: 1px solid ${({theme}) => theme.main.primary};
      outline: none;
    }

    &::placeholder {
      color: ${({ theme, disabled }) => disabled ? theme.one[2] : theme.one[0]};
    }

    &:disabled {
      background: ${({ theme }) => theme.two[0]};
      color: ${({ theme }) => theme.one[2]};
      user-select: none;
    }
  }

  & svg g {
    fill: ${({ theme, disabled }) => disabled ? theme.one[2] : theme.one[0]};
  }

  & .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 3px;
  }

  & .DayPicker-Caption > div {
    font-family: ${({ theme }) => theme.font3};
    font-weight: 900;
    font-size: 16px;
    color: ${({ theme }) => theme.one[0]};
  }

  & .DayPicker-Weekday {
    font-family: ${({ theme }) => theme.font3};
    color: ${({ theme }) => theme.one[0]};
  }

  & .DayPicker-Day {
    padding: 8px 10px;
    margin: 2px;
    font-family: ${({ theme }) => theme.font2};
    font-size: 13px;
    color: ${({ theme }) => theme.one[0]};
  }
  /* Clear styling of today */
  & .DayPicker-Day, & .DayPicker-Day--today {
    color: ${({ theme }) => theme.one[0]};
    font-weight: normal;
    /* fixing rounded background issues */
    height: 35px;
  }
  /* Selected day */
  &&& .DayPicker-Day--selected {
    color: ${({ theme }) => theme.one[0]};
    background-color: ${({ theme }) => theme.color[0]};
  }
  /* Disabled days (outside of bounds) */
  & .DayPicker-Day--disabled {
    opacity: 0.5;
  }
  /* Days at bounds */
  & .DayPicker-Day--marked {
    background-color: ${({ theme }) => theme.color[2]};
    opacity: 1;
  }
  /* Default day hover */
  && .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
    color: ${({ theme }) => theme.one[0]};
    background-color: ${({ theme }) => theme.color[0]};
    cursor: pointer;
  }
  /* Selected day hover */
  &&& .DayPicker-Day--selected:hover {
    color: ${({ theme }) => theme.one[4]};
    background-color: ${({ theme }) => theme.color[0]};
  }
`


const FloatingIcon = styled.div`
  position: absolute;
  top: 12px;
  left: 149px;
`

const CalendarSvg = (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="01-Scheduling" transform="translate(-255.000000, -754.000000)" fill="#3D4641">
        <g id="Schedule" transform="translate(23.000000, 457.000000)">
          <g id="Group-7" transform="translate(83.000000, 125.000000)">
            <g id="Group-2" transform="translate(0.000000, 126.000000)">
              <g id="Group-3" transform="translate(0.000000, 33.000000)">
                <g id="calendar" transform="translate(149.000000, 13.000000)">
                  <path d="M18.8077363,5.92641509 L1.19226371,5.92641509 L1.19226371,4.11698113 C1.19226371,3.21792453 1.96290956,2.48679245 2.91055536,2.48679245 L4.14756625,2.48679245 L4.14756625,3.20188679 C4.14756625,3.51509434 4.41406056,3.76792453 4.74320091,3.76792453 C5.07234127,3.76792453 5.33982996,3.51509434 5.33982996,3.20188679 L5.33982996,2.48679245 L14.66017,2.48679245 L14.66017,3.20188679 C14.66017,3.51509434 14.9266643,3.76792453 15.2558047,3.76792453 C15.5849451,3.76792453 15.8524337,3.51509434 15.8524337,3.20188679 L15.8524337,2.48679245 L17.0884503,2.48679245 C18.0360961,2.48679245 18.8077363,3.21792453 18.8077363,4.11698113 L18.8077363,5.92641509 Z M18.8077363,17.2377358 C18.8077363,18.1367925 18.0360961,18.8679245 17.0884503,18.8679245 L2.91055536,18.8679245 C1.96290956,18.8679245 1.19226371,18.1367925 1.19226371,17.2377358 L1.19226371,7.05943396 L18.8077363,7.05943396 L18.8077363,17.2377358 Z M17.0884503,1.35471698 L15.8524337,1.35471698 L15.8524337,0.566037736 C15.8524337,0.253773585 15.5849451,0 15.2558047,0 C14.9266643,0 14.66017,0.253773585 14.66017,0.566037736 L14.66017,1.35471698 L5.33982996,1.35471698 L5.33982996,0.566037736 C5.33982996,0.253773585 5.07234127,0 4.74320091,0 C4.41406056,0 4.14756625,0.253773585 4.14756625,0.566037736 L4.14756625,1.35471698 L2.91055536,1.35471698 C1.30562323,1.35471698 0,2.59433962 0,4.11698113 L0,17.2377358 C0,18.7613208 1.30562323,20 2.91055536,20 L17.0884503,20 C18.6933824,20 20,18.7613208 20,17.2377358 L20,4.11698113 C20,2.59433962 18.6933824,1.35471698 17.0884503,1.35471698 L17.0884503,1.35471698 Z" id="Event"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const DATE_FORMAT = 'dd.MM.y' // TODO: Make this locale dependant when we start to offer language options for owners

const messages = defineMessages({
  placeholder: { defaultMessage: 'Choose date', id: 'dayPicker.placeholder' }
})


function parseDate(str) {
  const parsed = dateFnsParse(str, DATE_FORMAT, new Date(2000, 1, 1, 0, 0, 0, 0))
  if (DateUtils.isDate(parsed) && parsed.getFullYear() >= new Date().getFullYear()) {
    return parsed
  }
  return undefined
}

function formatDate(date) {
  return dateFnsFormat(date, DATE_FORMAT)
}


class DayPicker extends Component {
  handleChange = (date) => {
    if (date) {
      const value = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0,0, 0, 0)
      this.props.onChange(value)
    }
  }

  render() {
    const { value, markedDays, disabledDays, disabled, intl } = this.props

    const modifiers = {}
    if (markedDays) modifiers.marked = markedDays

    return (
      <DateTimeWrapper disabled={disabled}>
        <DayPickerInput
          format={DATE_FORMAT}
          parseDate={parseDate}
          formatDate={formatDate}
          placeholder={intl.formatMessage(messages.placeholder)}
          dayPickerProps={{
            showOutsideDays: true,
            enableOutsideDaysClick: true,
            firstDayOfWeek: 1,
            modifiers: { marked: markedDays },
            disabledDays
          }}
          value={value}
          inputProps={{ disabled: disabled, readOnly: true }}
          onDayChange={this.handleChange}
        />
        <FloatingIcon>{CalendarSvg}</FloatingIcon>
      </DateTimeWrapper>
    )
  }
}

DayPicker.propTypes = {
  onChange: PropTypes.func
}

DayPicker.defaultProps = {
  onChange: () => { }
}

export default injectIntl(DayPicker)
