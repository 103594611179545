import React from 'react'
import styled from 'styled-components'

const RootComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > * {
    margin: auto;
  }
  & path,circle { 
    stroke: ${({ theme, inverted }) => inverted ? theme.inverted.one[0] : theme.one[0] }; 
  }
  & polygon { 
    fill: ${({ theme, inverted }) => inverted ? theme.inverted.one[0] : theme.one[0] }; 
  }
`

const svg = (
  <svg width="182px" height="139px" viewBox="0 0 182 139" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1080.000000, -891.000000)">
        <g transform="translate(155.000000, 778.000000)">
          <g transform="translate(788.000000, 0.000000)">
            <g transform="translate(138.000000, 113.000000)">
              <circle cx="20" cy="60.6162791" r="20" strokeOpacity="0.4" stroke="#000000" strokeLinecap="round" strokeDasharray="3,3,3,3"></circle>
              <circle cx="150" cy="61" r="30" strokeOpacity="0.4" stroke="#000000" strokeLinecap="round" strokeDasharray="3,3,3,3"></circle>
              <g id="Group-18" transform="translate(30.000000, 16.000000)">
                <path d="M40,9 L12,29" id="Line-4" stroke="#000000" strokeWidth="2" strokeLinecap="round"></path>
                <path d="M74.5,47.5 L21.5,47.5" id="Line-4-Copy-3" stroke="#000000" strokeWidth="5" strokeLinecap="round"></path>
                <path d="M20.8663967,88.1597745 C21.0542981,88.3621298 21.0425809,88.6784954 20.8402255,88.8663967 C20.6378702,89.0542981 20.3215046,89.0425809 20.1336033,88.8402255 L7.13360325,74.8402255 C6.94570187,74.6378702 6.95741912,74.3215046 7.15977445,74.1336033 C7.36212979,73.9457019 7.67849536,73.9574191 7.86639675,74.1597745 L20.8663967,88.1597745 Z" id="Line-4-Copy-4" fill="#000000" fillRule="nonzero"></path>
                <path d="M55,18 L46,80" id="Line-4-Copy" stroke="#000000" strokeWidth="2" strokeLinecap="round"></path>
                <path d="M84.5,75.5 L61.5,95.5" id="Line-4-Copy-5" stroke="#000000" strokeLinecap="round"></path>
                <path d="M74.5,12.5 L87.5,23.5" id="Line-4-Copy-2" stroke="#000000" strokeWidth="6" strokeLinecap="round"></path>
                <polygon fill="#000000" transform="translate(85.940500, 74.218396) rotate(111.000000) translate(-85.940500, -74.218396) " points="85.9405005 71.4807074 89.8338277 76.9560846 82.0471733 76.9560846"></polygon>
                <polygon fill="#000000" transform="translate(5.940500, 72.218396) rotate(23.000000) translate(-5.940500, -72.218396) " points="5.9405005 69.4807074 9.83382774 74.9560846 2.04717326 74.9560846"></polygon>
                <polygon fill="#000000" transform="translate(17.828293, 47.718396) rotate(-28.000000) translate(-17.828293, -47.718396) " points="17.8282935 43.6691508 23.5868142 51.7676412 12.0697728 51.7676412"></polygon>
                <polygon fill="#000000" transform="translate(10.695618, 30.218396) rotate(-68.000000) translate(-10.695618, -30.218396) " points="10.6956177 26.9560848 15.3350223 33.4807072 6.05621306 33.4807072"></polygon>
                <polygon fill="#000000" transform="translate(40.695618, 8.218396) rotate(111.000000) translate(-40.695618, -8.218396) " points="40.6956177 4.95608477 45.3350223 11.4807072 36.0562131 11.4807072"></polygon>
                <polygon fill="#000000" transform="translate(55.318059, 16.718396) rotate(70.000000) translate(-55.318059, -16.718396) " points="55.3180591 13.7183961 59.584425 19.7183959 51.0516932 19.7183959"></polygon>
                <polygon fill="#000000" transform="translate(45.850398, 80.718396) rotate(250.000000) translate(-45.850398, -80.718396) " points="45.8503977 77.7183961 50.1167636 83.7183959 41.5840318 83.7183959"></polygon>
                <polygon fill="#000000" transform="translate(78.828293, 47.718396) rotate(152.000000) translate(-78.828293, -47.718396) " points="78.8282935 43.6691508 84.5868142 51.7676412 73.0697728 51.7676412"></polygon>
                <polygon fill="#000000" transform="translate(87.960969, 24.218396) rotate(194.000000) translate(-87.960969, -24.218396) " points="87.9609692 19.3822169 94.838606 29.0545751 81.0833325 29.0545751"></polygon>
                <polygon fill="#000000" transform="translate(72.960969, 11.218396) rotate(14.000000) translate(-72.960969, -11.218396) " points="72.9609692 6.3822169 79.838606 16.0545751 66.0833325 16.0545751"></polygon>
                <polygon fill="#000000" transform="translate(21.940500, 90.218396) rotate(199.000000) translate(-21.940500, -90.218396) " points="21.9405005 87.4807074 25.8338277 92.9560846 18.0471733 92.9560846"></polygon>
                <polygon fill="#000000" transform="translate(61.940500, 95.218396) rotate(292.000000) translate(-61.940500, -95.218396) " points="61.9405005 92.4807074 65.8338277 97.9560846 58.0471733 97.9560846"></polygon>
              </g>
              <circle cx="73" cy="123" r="15" strokeOpacity="0.4" stroke="#000000" strokeLinecap="round" strokeDasharray="3,3,3,3"></circle>
              <circle cx="90" cy="10.6162791" r="10" strokeOpacity="0.4" stroke="#000000" strokeLinecap="round" strokeDasharray="3,3,3,3"></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const NetworkNoData = ({ inverted }) => (
  <RootComponent inverted={inverted}>
    {svg}
  </RootComponent>
)

export default NetworkNoData
