import 'whatwg-fetch'
import * as authUtils from './utils/authUtils'
import { log } from '../utils/trackingUtils'

function logGraphQLErrors(errors) {
  errors.forEach(error => {
    const { message, location, path } = error
    log.error(message, { location, path }, new Error(message))
  })
}

export async function invoke(url, options, includeAuthHeader = true) {
  // Add default headers
  options = options || {}
  options.headers = {
    ...options.headers,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache' // Legacy (IE11) support
  }
  // Add authentication headers
  if (includeAuthHeader) {
    const authHeader = await authUtils.getAuthHeader()
    options.headers = {
      ...options.headers,
      ...authHeader
    }
  }
  // Prepare body
  if (options.body) {
    options.body = JSON.stringify(options.body)
  }
  // Make request
  const response = await fetch(url, options)
  let body = await response.text()
  // Attempt parse, if needed
  let contentType = response.headers.get('content-type')
  if(contentType != null && contentType.indexOf('application/json') != -1) {
    try {
      body = JSON.parse(body)
    }
    catch (ex) {
      console.error(`Failed to parse error response: ${ex.message}`)
      throw ex
    }
  }
  // Reject promise if response status not 200
  if (response.status === 200 && !body.errors) {
    return body
  } else {
    if (body.hasOwnProperty('message')) {
      // If regular error, log the message
      log.error(body.message, new Error(body.message))
      throw body
    } else if (body.hasOwnProperty('errors') && Array.isArray(body.errors)) {
      // If array of GraphQL errors, loop through them and log them individually
      logGraphQLErrors(body.errors)
      throw body.errors
    } else {
      // If unknown error format, log generic message and attach body
      const message = 'API request failed'
      log.error(message, { body }, new Error(message))
      throw { message, body }
    }
  }
}
