import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl } from 'react-intl'

import Button from '../../common/Button/Button'
import RightArrow from './RightArrow'

import loaderIcon from '../../../static/img/dotloader.svg'

const messages = defineMessages({
  QUESTION_ALREADY_ANSWERED: {
    id: 'qvest.SubmitButton.questionAlreadyAnsweredError',
    defaultMessage: 'It seems like you already answered this question.',
  },
  QUESTION_ALREADY_SENT: {
    id: 'qvest.SubmitButton.questionAlreadySentError',
    defaultMessage: 'It seems like you already sent your question.',
  },
  QVEST_NOT_OPEN: {
    id: 'qvest.SubmitButton.qvestNotOpenError',
    defaultMessage: 'The Qvest isn\'t open yet. Check back later.',
  },
  UNEXPECETED_ERROR: {
    id: 'qvest.SubmitButton.unexpectedError',
    defaultMessage: 'We\'re sorry - an unexpected error occurred.',
  },
  submitAnswer: {
    id: 'qvest.SubmitButton.submitAnswer',
    defaultMessage: 'Send Answer',
  },
  submitQuestion: {
    id: 'qvest.SubmitButton.submitQuestion',
    defaultMessage: 'Send Question',
  },
  selectRecipient: {
    id: 'qvest.SubmitButton.selectRecipient',
    defaultMessage: 'Select Recipient',
  },
  inProgress: {
    id: 'qvest.SubmitButton.inProgress',
    defaultMessage: 'Sending',
  },
  inProgressRecipient: {
    id: 'qvest.SubmitButton.inProgressRecipient',
    defaultMessage: 'Finding available recipients',
  },
  // this is now the fallback for 'Validation errors'
  // redefine this error when api changes to graphql
  default: {
    id: 'qvest.SubmitButton.defaultError',
    defaultMessage: 'Something went wrong when sending your question. Please try again',
  }
})

const ComponentRoot = styled.div`
  width: 100%;
  ${props => props.isError && '& > :first-child { margin-bottom: 10px; }'}
  @media (min-width: 513px) { width: 300px; }
`

const StyledButton = styled(Button)`
  align-self: flex-end;
  display: flex;
  align-items: center;
  width: 100%;
  & > img, & > svg {
    margin-left: auto;
    width: 40px;
  }
  & > svg g { fill: ${({ disabled, theme }) => disabled ? theme.one[0] : theme.two[0] }; }
`

// none of these match current theme options
const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(248, 105, 92, .2);
  color: #f8695c;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  & > img, & > svg {
    width: 45px;
    padding-left: 10px;
    margin-left: auto;
  }
`

// none of these match current theme options
const ErrorText = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-family: ${props => props.theme.font4};
`

// none of these match current theme options
const ErrorSign = styled.span`
  margin: 0 12px 0 18px;
  font-size: 40px;
  line-height: 40px;
  font-family: ${props => props.theme.font2};
`

const determineText = (isLoading, variant) => {
  if (isLoading) {
    if (variant === 'SELECT_RECIPIENT') {
      return messages.inProgressRecipient
    } else {
      return messages.inProgress
    }
  }
  switch (variant) {
    case 'SEND_QUESTION':
      return messages.submitQuestion
    case 'SEND_ANSWER':
      return messages.submitAnswer
    case 'SELECT_RECIPIENT':
      return messages.selectRecipient
    default:
      return messages.submitQuestion
  }
}

const renderError = (intl, messageEnum) => (
  <ErrorWrapper>
    <ErrorText>{intl.formatMessage(messages[messageEnum] || messages.default)}</ErrorText>
    <ErrorSign>!</ErrorSign>
  </ErrorWrapper>
)

const SubmitButton = ({ intl, cta, disabled, isLoading, errorMessage, className, variant }) => (
  <ComponentRoot className={className} isError={errorMessage}>
    {errorMessage && renderError(intl, errorMessage)}
    <StyledButton
      type="submit"
      disabled={disabled || isLoading}
      outline={!cta}
    >
      {intl.formatMessage(determineText(isLoading, variant))}
      {isLoading
        ? <img src={loaderIcon} alt="loading" />
        : <RightArrow />}
    </StyledButton>
  </ComponentRoot>
)

SubmitButton.propTypes = {
  variant: PropTypes.oneOf([
    'SEND_QUESTION',
    'SEND_ANSWER',
    'SELECT_RECIPIENT'
  ]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  cta: PropTypes.bool,
  errorMessage: PropTypes.string,
}

SubmitButton.defaultProps = {
  isQuestion: false,
  isLoading: false,
  disabled: false,
  cta: false,
  errorMessage: '',
}

export default injectIntl(SubmitButton)
