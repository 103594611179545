const common = {
  /* Background colors */
  background1: 'white',
  background2: '#D4DADD',
  background3: '#F0F3F5',
  background4: '#C8D3DC',
  background5: '#F0F3F5',
  // default text color
  default: '#3d4640',
  primary: 'purple',
  secondary: 'hotpink',
  tertiary: 'white',

  // Normal (light)
  // - palette dark-green -> brighter
  // - palette white -> darker
  one: [  // Default typography
    '#3d4641',
    '#646b67',
    '#9ea2a0',
    '#e2e4e3',
    '#ffffff',
  ],
  two: [   // Default background
    '#ffffff',
    '#e2e4e3',
    '#9ea2a0',
    '#646b67',
    '#3d4641',
  ],

  // Inverted (dark)
  // - palette beige -> darker
  // - palette black -> brighter
  inverted: {
    one: [   // Inverted default typography
      '#f2eee1',
      '#d7d8d7',
      '#8f918f',
      '#3E4341',
      '#2A2D2B',
    ],
    two: [    // Inverted default background
      '#2A2D2B',
      '#3E4341',
      '#8f918f',
      '#d7d8d7',
      '#ffffff',
    ]
  },
  orange: ['#f6c692', '#f6cfa5', '#f5ddc2', '#f6efe4'],

  /* Call-to-action colors */
  cta: {
    default: '#fc8f85',
    primary: '#fc8f85',
    secondary: '#fda59d',
    tertiary: 'white',
  },
  /* Alerts */
  alert: {
    error: '#fc8f85',
    warning: '#f7a15d',
    ok: '#64936E'
  },
  /* Data visualization colors */
  data: {
    primary: ['#3c6f8b', '#de454f', '#ff9d4d', '#c3d0c6', '#56956b', '#422b77', '#3b4640', '#fb958e', '#3d4640'],
    secondary: ['#21b2d8', '#f56a73', '#e6c589', '#d0e5d5', '#81e9c6', '#8049ff', '#4a9185', '#ff8b71', '#57605a'],
  },
  /* Fonts */
  font1: 'Raleway, sans-serif',
  font2: '\'LL Circular Book Web\', sans-serif',
  font3: '\'LL Circular Black Web\', sans-serif',
  font4: '\'Andale Mono MT Pro\', monospace',
}

export const red = {
  ...common,
  background2: '#fce8e7',
  color: ['#e8655c', '#ee9b91'],
  main: {
    primary: '#e8655c',
    secondary: '#fc8f85'
  },
}

export const yellow = {
  ...common,
  background2: '#FFF4E3',
  color: ['#f4bf6c', '#f6c692', '#f9dfb5', '#fef6e9'],
  main: {
    primary: '#f4bf6c',
    secondary: '#f6c692'
  }
}

export const purple = {
  ...common,
  background2: '#FFF4F0',
  color: ['#956CF5', '#AF91F6', '#C9B5F9', '#EFE9FE'],
  main: {
    primary: '#956CF5',
    secondary: '#AF91F6'
  }
}

export const salmon = {
  ...common,
  background2: '#f5fcff',
  color: ['#F5956C', '#F6AF91', '#F9C9B5', '#FEEFE9'],
  main: {
    primary: '#F5956C',
    secondary: '#F6AF91'
  }
}

export const ocean = {
  ...common,
  background2: '#E4F4EA',
  background3: '#4A9185',
  color: ['#21B2D8', '#47D8FE', '#b6ecfa', '#ebfbff'],
  main: {
    primary: '#21B2D8',
    secondary: '#47D8FE'
  }
}

export const blue = {
  ...common,
  background2: '#ecf0f3',
  color: ['#486f89', '#a2b8c7'],
  main: {
    primary: '#294457',
    secondary: '#486f8a'
  },
}

export const gray = {
  ...common,
  background2: '#f6f7f8',
  color: ['#a3abae', '#b0b7ba'],
  main: {
    primary: '#a3abae',
    secondary: '#b0b7ba'
  }
}

export const green = {
  ...common,
  background2: '#eff4f0',
  color: ['#afbb87', '#ccd8bb'],
  main: {
    primary: '#3d4640',
    secondary: '#64936e'
  },
}

export const orange = {
  ...common,
  color: ['#f6c692', '#f6cfa5']
}

export default common
