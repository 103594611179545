import Immutable from 'immutable'
import isEqual from 'lodash/isEqual'

let INITIAL_STATE = Immutable.fromJS({
  isLoading: true,
  isEditable: false,
  isDirty: false,
  isCreating: false,
  filter: 'ALL',
  lastUpdate: null,
  data: {},
  organizationQvests: {},
  unsaved: {}
  // TODO: Init default values here?
  /*unsaved: {
    topic: '[Context and Purpose]',
    headline: '[Headline of text]',
    aboutText: '[Descriptive body of text]',
    inviteText: '[Inviting body of text]',
    inviteSubject: 'QVEST - Invitation',
    contactInfo: {
      name: null,
      email: 'support@qvest.io'
    }
  }*/
})

const unsaved = (state = INITIAL_STATE.unsaved, action) => {
  switch (action.type) {
    case 'CHANGE_UNSAVED_QVEST':
      return state.mergeDeep(action.patch)
    case 'INIT_UNSAVED_QVEST':
      return Immutable.fromJS(action.qvest)
    default:
      return state
  }
}

const qvests = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_QVEST_REQUEST':
    case 'GET_QVEST_VIDEO_REQUEST':
    case 'GET_ORGANIZATION_QVESTS_REQUEST':
    case 'CREATE_QVEST_REQUEST':
    case 'UPDATE_QVEST_REQUEST':
      return state.merge({
        isLoading: true
      })
    case 'GET_QVEST_SUCCESS':
    case 'CREATE_QVEST_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').set(action.qvest.qvestId, Immutable.fromJS(action.qvest))
      })
    case 'UPDATE_QVEST_SUCCESS':
    case 'GET_QVEST_REPORT_INFO_SUCCESS':
    case 'GET_QVEST_VIDEO_SUCCESS':
    case 'INITIATE_VIDEO_UPLOAD_SUCCESS':
    case 'REMOVE_QVEST_VIDEO_SUCCESS':
      return state.merge({
        isLoading: false,
        lastUpdate: new Date(),
        data: state.get('data').update(action.qvest.qvestId, qvest => qvest.merge(action.qvest))
      })
    case 'GET_ORGANIZATION_QVESTS_SUCCESS':
      return state.merge({
        isLoading: false,
        data: state.get('data').mergeDeep(action.data),
        organizationQvests: state.get('organizationQvests').set(action.organizationId, action.ids)
      })
    case 'GET_QVEST_FAILURE':
    case 'GET_ORGANIZATION_QVESTS_FAILURE':
    case 'CREATE_QVEST_FAILURE':
    case 'UPDATE_QVEST_FAILURE':
      return state.merge({
        isLoading: false,
        error: action.error
      })
    case 'INIT_UNSAVED_QVEST':
      return state
        .update('unsaved', u => unsaved(u, action))
        .set('isEditable', true)
    case 'CHANGE_UNSAVED_QVEST':
      return state
        .update('unsaved', u => unsaved(u, action))
        .set('isDirty', true)
    case 'SHOW_CREATE_QVEST':
      return state.set('isCreating', action.show)
    case 'CHANGE_QVEST_FILTER':
      return state.set('filter', action.filter)
    case 'CLEAR_QVEST_REPORT_INFO':
      return state.deleteIn(['data', action.qvestId, 'report'])
    default:
      return state
  }
}

export function filterQvestsById(qvests, qvestId) {
  if (!qvests.data.hasOwnProperty(qvestId)) {
    return null
  }
  return qvests.data[qvestId]
}

export function filterVideoByQvestId(qvests, qvestId) {
  const qvest = filterQvestsById(qvests, qvestId)
  if (qvest) {
    return qvest.video
  }
}

export function filterQvestValidityById(qvests, qvestId) {
  if (!qvests.data.hasOwnProperty(qvestId)) {
    return null
  }
  const resource = { ...qvests.data[qvestId] }
  const validity = resource.validity
  delete resource.validity
  // Add reference to qvest to each validation item, if any found
  if (validity.items && validity.items.length > 0) {
    validity.items = validity.items.map(item => ({ ...item, resource }))
  }
  return validity

}

export function filterQvestsByOrganization(qvests, organizationId) {
  if (!qvests.organizationQvests.hasOwnProperty(organizationId)) {
    return null
  }
  return qvests.organizationQvests[organizationId].map(id => qvests.data[id])
}

export function hasUnsavedQvestState(qvests, qvestId) {
  if (!qvests.data.hasOwnProperty(qvestId)) {
    return false
  }
  return !isEqual(qvests.data[qvestId], qvests.unsaved)
}

export function isDraftQvestState(qvests, qvestId) {
  const qvest = filterQvestsById(qvests, qvestId)
  if (!qvest) return null
  return (qvest.state == null)
}

export function hasStartedQvestState(qvests, qvestId) {
  const qvest = filterQvestsById(qvests, qvestId)
  if (!qvest) return null
  return (qvest.state != null) && (qvest.state !== 'QVEST_SCHEDULED')
}

export function isDoneQvestState(qvests, qvestId) {
  const qvest = filterQvestsById(qvests, qvestId)
  if (!qvest) return null
  return (qvest.state === 'QVEST_CLOSED') || (qvest.state === 'QVEST_EXPIRED')
}

export function isActiveQvestState(qvests, qvestId) {
  const qvest = filterQvestsById(qvests, qvestId)
  if (!qvest) return null
  return (qvest.state === 'QVEST_ONBOARDING_STARTED') ||
    (qvest.state === 'QVEST_OPENED') ||
    (qvest.state === 'QVEST_CLOSED')
}

export default qvests
