import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { hexaToRgba } from '../../../utils/styleUtils'

const PADDINGS = {
  tiny: '5px 6px',
  small: '6px 8px',
  default: '14px 20px',
  large: '18px 34px',
}

const FONT_SIZE = {
  tiny: '10px',
  small: '13px',
  default: '15px',
  large: '18px'
}

const LINE_HEIGHT = {
  tiny: '10px',
  small: '12px',
  default: '15px',
  large: '18px'
}

const BORDER_RADIUS = {
  tiny: '50%',
  small: '5px',
  default: '23px',
  large: '27px'
}

const determineLabelColor = ({ theme, secondary, outline, inverted }) => {
  if (secondary) {
    if (inverted) return theme.inverted.two[1]
    return theme.two[0]
  } else if (outline) {
    if (inverted) return theme.inverted.one[0]
    return theme.one[0]
  } else {
    if (inverted) return theme.inverted.two[1]
    return theme.two[0]
  }
}

const determineLabelHoverColor = ({ theme, secondary, outline, inverted }) => {
  if (secondary) {
    if (inverted) return theme.color[1]
    return theme.color[1]
  } else if (outline) {
    if (inverted) return theme.color[1]
    return theme.color[1]
  } else {
    if (inverted) return theme.inverted.two[1]
    return theme.two[0]
  }
}

const determineLabelActiveColor = ({ theme, secondary, outline, inverted }) => {
  if (secondary) {
    if (inverted) return theme.inverted.two[1]
    return theme.two[0]
  } else if (outline) {
    if (inverted) return theme.inverted.two[1]
    return theme.two[0]
  } else {
    if (inverted) return theme.color[1]
    return theme.one[0]
  }
}

const determineBackgroundColor = ({ theme, secondary, outline, inverted }) => {
  if (secondary) {
    if (inverted) return theme.inverted.one[0]
    return theme.one[0]
  } else if (outline) {
    return 'transparent'
  } else {
    return theme.color[0]
  }
}

const determineBackgroundHoverColor = ({ theme, secondary, outline, inverted }) => {
  if (secondary) {
    if (inverted) return theme.inverted.one[0]
    return theme.one[0]
  } else if (outline) {
    return 'transparent'
  } else {
    return theme.color[1]
  }
}

const determineBackgroundActiveColor = ({ theme, secondary, outline, inverted }) => {
  if (secondary) {
    if (inverted) return theme.color[1]
    return theme.color[1]
  } else if (outline) {
    if (inverted) return theme.color[1]
    return theme.color[1]
  } else {
    if (inverted) return theme.inverted.two[0]
    return theme.two[0]
  }
}



const Button = styled.button`
  min-width: ${({ size }) => (size === 'default' || size === 'large' ) ? '100px' : '0' };

  font-family: 'LL Circular Black Web', sans-serif;
  font-weight: 900;
  font-size: ${({ size }) => FONT_SIZE[size]};
  line-height: ${({ size }) => LINE_HEIGHT[size]};
  text-decoration: none;
  border-radius: ${({ size }) => BORDER_RADIUS[size]};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  padding: ${({ size }) => PADDINGS[size]};
  border-style: ${({ outline }) => outline ? 'solid' : 'none'};
  border-width: 1px;
  color: ${props => determineLabelColor(props)};
  background-color: ${determineBackgroundColor};
  border-color: ${(props) => hexaToRgba(determineLabelColor(props) + '33')};
  transition: all 0.2s;
  transition-property: opacity, color, border-color, background-color;

  &:hover:not(:disabled) {
    color: ${props => determineLabelHoverColor(props)};
    background-color: ${determineBackgroundHoverColor};
    border-color: ${({ theme }) => theme.color[1]};
  }

  &:hover:not(:disabled) > * {
    color: ${props => determineLabelHoverColor(props)};
  }

  &:hover:active:not(:disabled) {
    color: ${determineLabelActiveColor};
    background-color: ${determineBackgroundActiveColor};

    & > * {
      color: ${({ theme }) => theme.tertiary}
    }
  }

  &:disabled {
    opacity: 0.6;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
  }
`

const ButtonAnchor = (props) => (<Button as='a' {...props}>{props.children}</Button>)
Button.a = ButtonAnchor
const ButtonLink = (props) => (<Button as={Link} {...props}>{props.children}</Button>)
Button.Link = ButtonLink

Button.propTypes = {
  size: PropTypes.oneOf([
    'tiny',
    'small',
    'default',
    'large',
  ]),
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  inverted: PropTypes.bool,
}

Button.defaultProps = {
  size: 'default',
  outline: false,
  disabled: false,
  secondary: false,
  inverted: false,
}

export default Button
