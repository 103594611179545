import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedRelative, IntlProvider, injectIntl } from 'react-intl'

import Typography from '../../common/Typography/Typography'


class LastUpdateStatus extends Component {
  render() {
    const { lastUpdate, inverted, intl } = this.props

    if(!lastUpdate) {
      return null
    }

    const dateString = intl.formatDate(lastUpdate)
    const timeString = intl.formatTime(lastUpdate)
    const dateTimeString = `${timeString} ${dateString}`
    return (
      <IntlProvider locale="en">
        <div>
          <Typography inverted={inverted} variant="small" weight="light">
            <span>Saved: </span>
          </Typography>
          <Typography inverted={inverted} variant="small" weight="light">
            <time
              title={dateTimeString}
              dateTime={lastUpdate.toISOString()}
            >
              {lastUpdate ? <FormattedRelative value={lastUpdate}/> : null}
            </time>
          </Typography>
        </div>
      </IntlProvider>
    )
  }

  static propTypes = {
    lastUpdate: PropTypes.instanceOf(Date),
    inverted: PropTypes.bool,
  }

  static defaultProps = {
    inverted: false,
  }
}

export default injectIntl(LastUpdateStatus)
