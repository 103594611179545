import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Typography from '../../common/Typography/Typography'


class Disclaimer extends Component {
  render() {
    const { inverted } = this.props
    return (
      <div>
        <Typography secondary weight="bold" variant="small" inverted={inverted}>
          <FormattedMessage
            id="owner.Disclaimer.title"
            defaultMessage="A note about Analytics:"
          />
        </Typography>
        {' '}
        <Typography secondary weight="light" variant="small" inverted={inverted}>
          <FormattedMessage
            id="owner.Disclaimer.body"
            defaultMessage="All data generated in a Qvest is specific to the context represented in the topic and to the period of time when the Qvest occurred. Qvest exists to give you specific insight, not generalized knowledge.  As with any dataset, it is important to remember that the results are part of the picture, not the whole picture. It is also important to explore the data with a curious and open mind – by asking questions of the findings."
          />
        </Typography>
      </div>
    )
  }
}

Disclaimer.propTypes = {
  inverted: PropTypes.bool
}

Disclaimer.defaultProps = {
  inverted: false
}

export default Disclaimer
