import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'
import RecipientAvatar from '../RecipientAvatar/RecipientAvatar'


const ComponentRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;

  & > * { transition: 0.2s; }
  & > :not(:last-child) { margin-right: 20px; }
`

const EmptyRoot = styled(ComponentRoot)`
  &:hover {
    & > * { color: ${({ theme }) => theme.color[1]}; }
    & > :first-child { border-color: ${({ theme }) => theme.color[1]}; }
  }
`

const PopulatedRoot = styled(ComponentRoot)`
  &:hover {
    & > :last-child { text-decoration: underline }
  }
`

const BrowseButton = styled.div`
  padding: 8px;
  border-radius: 50%;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  height: 45px;
  width: 45px;
  border: 1px solid ${({ theme }) => theme.one[3]};
`

class RecipientField extends Component {
  renderAvatar() {
    const { participant, colors } = this.props
    return (<RecipientAvatar color={colors(participant)} />)
  }

  render() {
    const { participant, onClick } = this.props
    if (participant) {
      return (
        <PopulatedRoot onClick={onClick}>
          {this.renderAvatar()}
          <Typography>
            {participant.name}
          </Typography>
          {' '}
          <Typography cta variant="medium">
            <FormattedMessage defaultMessage="Change" id="RecipientField.change" />
          </Typography>
        </PopulatedRoot>
      )
    }
    return (
      <EmptyRoot onClick={onClick}>
        <BrowseButton size="large">+</BrowseButton>
        <Typography>
          <FormattedMessage defaultMessage="Choose respondent" id="qvest.QvestAsk.choose" />
        </Typography>
      </EmptyRoot>
    )
  }
}

export default injectIntl(RecipientField)
