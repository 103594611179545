import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { gql,  useQuery } from '@apollo/client'

import Typography from '../../../components/common/Typography/Typography.js'
import JamHeader from '../../../components/qvest/JamHeader/JamHeader.js'


const ComponentRoot = styled.div`
  padding: 80px 20px;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.two[0]};
`

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`

const FINALE_PAGE_QUERY = gql`
  query q($qvestId:String!) {
    qvest(id: $qvestId) {
      qvestId
      name
      language
      topic
      state
      schedule {
        inviteAt,
        openAt,
        closeAt
      }
    }
  }
`


const FinalePage = ({ match }) => {
  const qvestId = match.params.qvestId
  const { data } = useQuery(FINALE_PAGE_QUERY, { variables: { qvestId } })

  let topic, message
  if (data) {
    topic = data.qvest.topic
    message = (
      <Typography>
        <FormattedMessage
          defaultMessage="That's it! Our jam is done. See the output on the main screen."
          id="qvest.FinalePage.closure"
        />
      </Typography>
    )
  }

  return (
    <ComponentRoot>
      <JamHeader topic={topic} />
      <Content>
        {message}
      </Content>
    </ComponentRoot>
  )
}

export default FinalePage
