import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import { withTheme } from 'styled-components'

import AnalyticsCard from '../../AnalyticsCard/AnalyticsCard'
import { formatData, messages } from '../../AnalyticsDistribution/AnalyticsDistribution'
import DistributionChart from '../../../common/DistributionChart/DistributionChart'


const titleMessages = defineMessages({
  NORMAL: { id: 'owner.DistributionAnalyticsCard.mainHeading.normal', defaultMessage: 'No difference' },
  DIFFER: { id: 'owner.DistributionAnalyticsCard.mainHeading.differ', defaultMessage: 'Difference' },
  EXTREME: { id: 'owner.DistributionAnalyticsCard.mainHeading.extreme', defaultMessage: 'Big difference' },
})


class DistributionAnalyticsCard extends Component {
  handleClick = () => this.props.onClick('distribution')

  render() {
    const { theme, intl, participation, locked, loading } = this.props

    // render loading tile
    if (loading) return <AnalyticsCard loading />

    let title, dataWithLabels
    if (participation) {
      let counter = 0
      const colors = () => {
        // alternate between two colors
        return counter++ % 2 ? theme.inverted.one[0] : theme.inverted.two[0]
      }
      const { type, data } = formatData(participation, colors, theme)
      title = intl.formatMessage(titleMessages[type])
      dataWithLabels = data.map(group => ({
        ...group,
        labels: intl.formatMessage(messages.distributionLabel, { questionCount: group.questionReceivedCount })
      }))
    }

    // fallback chart input
    if (!dataWithLabels) {
      dataWithLabels = [{
        value: 0,
        labels: intl.formatMessage(messages.distributionLabel, { questionCount: 0 }),
      }, {
        value: 0,
        labels: intl.formatMessage(messages.distributionLabel, { questionCount: 0 }),
      }]
    }

    const subTitle = <FormattedMessage
      id="owner.DistributionAnalyticsCard.subTitle"
      defaultMessage="Distribution"
    />

    return (
      <AnalyticsCard
        subTitle={subTitle}
        title={title}
        onClick={this.handleClick}
        disabled={!participation && !locked}
        locked={locked}
      >
        <DistributionChart data={dataWithLabels} />
      </AnalyticsCard>
    )
  }
}

export default withTheme(injectIntl(DistributionAnalyticsCard))
