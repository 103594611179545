import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import styled, { withTheme } from 'styled-components'
import { GroupsNetworkGraph } from 'data-viz'

import AnalyticsCard from '../../AnalyticsCard/AnalyticsCard'
import NetworkNoData from './network/NetworkNoData'
import { formatData } from '../../AnalyticsNetwork/AnalyticsNetwork'


const ChartWrapper = styled.div`
  height: 200px;
`

const titleMessages = defineMessages({
  HIGH: { id: 'owner.NetworkAnalyticsCard..title.high', defaultMessage: 'High collaboration' },
  GOOD: { id: 'owner.NetworkAnalyticsCard.title.good', defaultMessage: 'Good collaboration' },
  LOW: { id: 'owner.NetworkAnalyticsCard.title.low', defaultMessage: 'Low collaboration' },
})


class NetworkAnalyticsCard extends Component {
  handleClick = () => this.props.onClick('network')
  renderChart(groupGraph) {
    const { locked } = this.props
    return (
      <ChartWrapper>
        <GroupsNetworkGraph
          secondary
          inverted={locked}
          data={groupGraph}
          linkForceDistance={300}
          width="320"
          height="200"
          showNodeTooltips={false}
        />
      </ChartWrapper>
    )
  }
  render() {
    const { theme, intl, groupGraph, locked, loading } = this.props
    const colors = () => locked ? theme.inverted.two[1] : theme.color[1]
    let title, chart
    if (groupGraph && !loading) {
      const { type } = formatData(groupGraph.nodes, groupGraph.edges, colors, theme)
      title = intl.formatMessage(titleMessages[type])
      chart = this.renderChart(groupGraph, colors)
    } else {
      chart = <NetworkNoData inverted={locked} />
    }
    return (
      <AnalyticsCard
        subTitle="Network"
        title={title}
        onClick={this.handleClick}
        disabled={!groupGraph && !locked}
        locked={locked}
        loading={loading}
      >
        {chart}
      </AnalyticsCard>
    )
  }
}

export default withTheme(injectIntl(NetworkAnalyticsCard))
