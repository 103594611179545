import * as api from './apiClient'
import { log } from '../utils/trackingUtils'
import { excelSheetToParticipants, participantsToExcelSheet } from '../utils/xlsxUtils'
import { raiseUnexpectedErrorNotification } from './notificationActions'

export function getParticipants(qvestId) {
  return dispatch => {
    dispatch({ type: 'GET_PARTICIPANTS_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q {
            participants(qvestId: "${qvestId}") {
              validity {
                general,
                items {
                  type,
                  name,
                  subjectIds
                }
              },
              items {
                participantId,
                name,
                email,
                isSelf,
                isSuspended,
                groupingConnections {
                  groupingName,
                  groupName,
                  groupId,
                  groupingId
                }
                lastMailEvent {
                  eventName
                  reason
                  emittedAt
                }
                lastMailReply {
                  text
                  recordedAt
                }
              }
            }
          }
        `
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data: { participants } }) => {
        let data = {}
        participants.items.forEach(p => {
          data[p.participantId] = p
        })
        const ids = participants.items.map(p => p.participantId)
        const validity = participants.validity
        dispatch({ type: 'GET_PARTICIPANTS_SUCCESS', data, ids, qvestId, validity })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'GET_PARTICIPANTS_FAILURE', error })
      })
  }
}

export function getParticipantsLight(qvestId) {
  return dispatch => {
    dispatch({type: 'GET_PARTICIPANTS_REQUEST'})
    const options = {
      method: 'POST',
      body: {
        query: `
          query q {
            participants(qvestId: "${qvestId}") {
              items {
                participantId,
                name,
                email,
                isSelf,
                isBusy,
                groupingConnections {
                  groupingName,
                  groupName,
                  groupId,
                  groupingId
                }
              }
            }
          }
        `
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({data: {participants}}) => {
        let data = {}
        participants.items.forEach(p => {
          data[p.participantId] = p
        })
        const ids = participants.items.map(p => p.participantId)
        const validity = participants.validity
        dispatch({type: 'GET_PARTICIPANTS_SUCCESS', data, ids, qvestId, validity})
        return participants.items
      })
      .catch(error => {
        dispatch({type: 'GET_PARTICIPANTS_FAILURE', error})
      })
  }
}

export function importParticipantsFile(file) {
  return dispatch => {
    const fileName = file.name
    dispatch({ type: 'IMPORT_PARTICIPANTS_FILE_REQUEST' })
    excelSheetToParticipants(file)
      .then(result => {
        const { groups, participants } = result
        dispatch({ type: 'IMPORT_PARTICIPANTS_FILE_SUCCESS', fileName, groups, participants })
      })
      .catch(error => {
        if (error) log.warning(error)
        dispatch({type: 'IMPORT_PARTICIPANTS_FILE_FAILURE', error})
      })
  }
}

export function clearParticipantsFile() {
  return { type: 'CLEAR_PARTICIPANTS_FILE' }
}

export function downloadParticipantsFile(qvestName, participants) {
  return dispatch => {
    dispatch({ type: 'DOWNLOAD_PARTICIPANTS_FILE_REQUEST' })
    participantsToExcelSheet(qvestName, participants)
      .then(() => dispatch({ type: 'DOWNLOAD_PARTICIPANTS_FILE_SUCCESS' }))
      .catch(error => { dispatch({type: 'DOWNLOAD_PARTICIPANTS_FILE_FAILURE', error}) })
  }
}

export function createParticipantsBulk(qvestId, participants) {
  return dispatch => {
    dispatch({ type: 'CREATE_PARTICIPANTS_BULK_REQUEST' })
    participants = participants.map(participant => {
      if (participant.metadata) {
        return {
          ...participant,
          metadata: JSON.stringify(participant.metadata)
        }
      }
      return participant
    })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($qvestId:String, $participants:[ParticipantInput]){
            createParticipantsBulk(qvestId: $qvestId, participants: $participants) {
              validity {
                general,
                items {
                  type,
                  name,
                  subjectIds
                }
              },
              items {
                participantId,
                name,
                email,
                isSuspended,
                groupingConnections {
                  groupingName,
                  groupName,
                  groupId,
                  groupingId
                }
                lastMailEvent {
                  eventName
                  reason
                  emittedAt
                }
              }
            }
          }
        `,
        variables: { qvestId, participants }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data: { createParticipantsBulk } }) => {
        let data = {}
        createParticipantsBulk.items.forEach(p => {
          data[p.participantId] = p
        })
        const ids = createParticipantsBulk.items.map(p => p.participantId)
        const validity = createParticipantsBulk.validity
        dispatch({ type: 'CREATE_PARTICIPANTS_BULK_SUCCESS', data, ids, qvestId, validity })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'CREATE_PARTICIPANTS_BULK_FAILURE', error })
      })
  }
}

export function createParticipant(qvestId, participant) {
  return dispatch => {
    dispatch({ type: 'CREATE_PARTICIPANT_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($qvestId:String!, $participant:ParticipantInput!){
            createParticipant(qvestId:$qvestId, participant:$participant) {
              item {
                participantId,
                name,
                email,
                isSuspended
              },
              validity {
                general,
                items {
                  type,
                  name,
                  subjectIds
                }
              }
            }
          }
        `,
        variables: {
          qvestId,
          participant
        }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const { item, validity } = data.createParticipant
        dispatch({ type: 'CREATE_PARTICIPANT_SUCCESS', qvestId, validity, participant: item })
        return item
      })
      .catch(errors => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'CREATE_PARTICIPANT_FAILURE', error: errors[0] })
        throw errors
      })
  }
}

export function getParticipant(qvestId, participantId) {
  return dispatch => {
    dispatch({ type: 'GET_PARTICIPANT_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          query q {
            participant(participantId: "${participantId}") {
              item {
                participantId,
                name,
                email,
                isSelf,
                isSuspended,
                groupingConnections {
                  groupingName,
                  groupName,
                  groupId,
                  groupingId
                }
                lastMailEvent {
                  eventName
                  reason
                  emittedAt
                }
              }
            }
          }
        `
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const participant = data.participant.item
        dispatch({ type: 'GET_PARTICIPANT_SUCCESS', qvestId, participant })
        return participant
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'GET_PARTICIPANT_FAILURE', error })
        throw error
      })
  }
}

export function updateParticipant(qvestId, participantId, patch) {
  return dispatch => {
    dispatch({ type: 'UPDATE_PARTICIPANT_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($participantId:String!, $patch:ParticipantUpdate!){
            updateParticipant(participantId:$participantId, patch:$patch) {
              item {
                participantId
                name
                email
                isSuspended
                groupingConnections {
                  groupingName
                  groupName
                  groupId
                  groupingId
                }
                lastMailEvent {
                  eventName
                  reason
                  emittedAt
                }
              }
              validity {
                general
                items {
                  type
                  name
                  subjectIds
                }
              }
            }
          }
        `,
        variables: {
          participantId,
          patch
        }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const { item, validity } = data.updateParticipant
        dispatch({ type: 'UPDATE_PARTICIPANT_SUCCESS', qvestId, validity, participant: item })
      })
      .catch(errors => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'UPDATE_PARTICIPANT_FAILURE', error: errors[0] })
        throw errors
      })
  }
}

export function deleteParticipant(participantId, qvestId) {
  return dispatch => {
    dispatch({ type: 'DELETE_PARTICIPANT_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($participantId:String!){
            deleteParticipant(participantId: $participantId) {
              item {
                participantId,
                name,
                email,
                isSuspended
              },
              validity {
                general,
                items {
                  type,
                  name,
                  subjectIds
                }
              }
            }
          }
        `,
        variables: {
          participantId
        }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const { item, validity } = data.deleteParticipant
        dispatch({ type: 'DELETE_PARTICIPANT_SUCCESS', qvestId, validity, participant: item })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'DELETE_PARTICIPANT_FAILURE', error })
      })
  }
}

export function deleteAllParticipants(qvestId) {
  return dispatch => {
    dispatch({ type: 'DELETE_ALL_PARTICIPANTS_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($qvestId:String!){
            deleteAllParticipants(qvestId: $qvestId) {
              items {
                participantId,
                name,
                email,
                isSuspended
              },
              validity {
                general,
                items {
                  type,
                  name,
                  subjectIds
                }
              }
            }
          }
        `,
        variables: { qvestId }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const { items, validity } = data.deleteAllParticipants
        const ids = items.map(p => p.participantId)
        dispatch({ type: 'DELETE_ALL_PARTICIPANTS_SUCCESS', ids, qvestId, validity })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'DELETE_ALL_PARTICIPANTS_FAILURE', error })
      })
  }
}

export function toggleParticipantSuspension(participant, qvestId) {
  const { participantId, isSuspended } = participant
  const suspend = (isSuspended != null) ? !isSuspended : true
  return dispatch => {
    dispatch({ type: 'TOGGLE_PARTICIPANT_SUSPENSION_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($participantId:String!, $suspend:Boolean!){
            toggleParticipantSuspension(participantId: $participantId, suspend: $suspend) {
              participantId,
              name,
              email,
              isSuspended
            }
          }
        `,
        variables: {
          participantId,
          suspend
        }
      }
    }
    api.invoke('/api/graphql', options)
      .then(({ data }) => {
        const participant = data.toggleParticipantSuspension
        dispatch({ type: 'TOGGLE_PARTICIPANT_SUSPENSION_SUCCESS', participant, qvestId })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'TOGGLE_PARTICIPANT_SUSPENSION_FAILURE', error })
      })
  }
}

export function sendParticipantLogin(qvestId, email) {
  return dispatch => {
    dispatch({ type: 'SEND_LOGIN_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($qvestId:String!, $email:String!){
            sendParticipantLogin(qvestId: $qvestId, email: $email)
          }
        `,
        variables: { qvestId, email }
      }
    }
    return api.invoke('/api/graphql/public', options, false)
      .then(() => {
        dispatch({ type: 'SEND_LOGIN_SUCCESS' })
      })
      .catch(error => {
        dispatch({ type: 'SEND_LOGIN_FAILURE', error })
      })
  }
}

export function resendParticipantNotification(participantId) {
  return dispatch => {
    dispatch({ type: 'RESEND_PARTICIPANT_NOTIFICATION_REQUEST' })
    const options = {
      method: 'POST',
      body: {
        query: `
          mutation m($participantId:String!){
            resendLastNotification(participantId: $participantId)
          }
        `,
        variables: { participantId }
      }
    }
    return api.invoke('/api/graphql', options)
      .then(() => {
        dispatch({ type: 'RESEND_PARTICIPANT_NOTIFICATION_SUCCESS' })
      })
      .catch(error => {
        dispatch(raiseUnexpectedErrorNotification())
        dispatch({ type: 'RESEND_PARTICIPANT_NOTIFICATION_FAILURE', error })
      })
  }
}
