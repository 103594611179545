import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider, withTheme } from 'styled-components'
import * as theme from '../../theme'

import Title from './Title'
import ActivityAnalyticsCard from './cards/ActivityAnalyticsCard'
import MindsetAnalyticsCard from './cards/MindsetAnalyticsCard'
import KeyStakeholdersAnalyticsCard from './cards/KeyStakeholdersAnalyticsCard'
import DistributionAnalyticsCard from './cards/DistributionAnalyticsCard'
import InteractionAnalyticsCard from './cards/InteractionAnalyticsCard'
import NetworkAnalyticsCard from './cards/NetworkAnalyticsCard'
import HighlightsAnalyticsCard from './cards/HighlightsAnalyticsCard'
import FocusAnalyticsCard from './cards/FocusAnalyticsCard'
import ThemesAnalyticsCard from './cards/ThemesAnalyticsCard'


const ComponentRoot = styled.div`
  padding: 25px 0;
`

const Backdrop = styled.div`
  background-color: #2a2d2b;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
`

const Row = styled.div`
  padding: 0 25px;
`

const Content = styled.div`
  display: flex;
  margin-bottom: 25px;
  & > * {
    flex: 0 0 380px;
  }
  & > *:not(:last-child) {
    margin-right: 25px;
  }
`

const Spacer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.inverted.two[1]};
  margin: 45px 0 25px 0;
`


export function hasSufficientData(qvestSummary) {
  if (!qvestSummary) return false
  return (qvestSummary.questions > 0)
}

class AnalyticsGrid extends Component {

  render() {
    const {
      tier,
      loading,
      participation,
      groups,
      participantsActivity,
      groupGraph,
      wordFrequencies,
      questionProfile,
      themes,
      onNavigateClick,
      onUnlockClick,
      questions
    } = this.props
    return (
      <ComponentRoot>
        <Backdrop />
        <ThemeProvider theme={theme.orange}>
          <Row>
            <Title variant="COMMITMENT" />
            <Content>
              <ActivityAnalyticsCard
                participation={participation}
                loading={loading.activity}
                onClick={onNavigateClick}
              />
              <MindsetAnalyticsCard
                participation={participation}
                loading={loading.mindset}
                locked={tier < 2}
                onClick={(tier < 2) ? onUnlockClick : onNavigateClick}
              />
              <KeyStakeholdersAnalyticsCard
                groups={groups}
                participantActivity={participantsActivity}
                loading={loading.stakeholders}
                locked={tier < 3}
                onClick={(tier < 3) ? onUnlockClick : onNavigateClick}
              />
            </Content>
          </Row>
        </ThemeProvider>
        <Spacer />
        <ThemeProvider theme={theme.green}>
          <Row>
            <Title variant="CONNECTIONS" />
            <Content>
              <DistributionAnalyticsCard
                participation={participation}
                loading={loading.distribution}
                onClick={onNavigateClick}
              />
              <InteractionAnalyticsCard
                groupGraph={groupGraph}
                loading={loading.interaction}
                locked={tier < 2}
                onClick={(tier < 2) ? onUnlockClick : onNavigateClick}
              />
              <NetworkAnalyticsCard
                groupGraph={groupGraph}
                loading={loading.network}
                locked={tier < 3}
                onClick={(tier < 3) ? onUnlockClick : onNavigateClick}
              />
            </Content>
          </Row>
        </ThemeProvider>
        <Spacer />
        <ThemeProvider theme={theme.blue}>
          <Row>
            <Title variant="COMMUNICATION" />
            <Content>
              <HighlightsAnalyticsCard
                groups={groups}
                wordFrequencies={wordFrequencies}
                loading={loading.highlights}
                onClick={onNavigateClick}
              />
              <FocusAnalyticsCard
                questionProfile={questionProfile}
                loading={loading.focus}
                locked={tier < 2}
                onClick={(tier < 2) ? onUnlockClick : onNavigateClick}
              />
              <ThemesAnalyticsCard
                questions={questions}
                themes={themes}
                loading={loading.themes}
                locked={tier < 3}
                onClick={(tier < 3) ? onUnlockClick : onNavigateClick}
              />
            </Content>
          </Row>
        </ThemeProvider>
      </ComponentRoot>
    )
  }
}

AnalyticsGrid.propTypes = {
  title: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.element
  ]),
  subTitle: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.element
  ]),
  loading: PropTypes.object,
  tier: PropTypes.number
}

AnalyticsGrid.defaultProps = {
  loading: {
    themes: false,
    mindset: false,
    distribution: false,
  },
  tier: 1
}

export default withTheme(AnalyticsGrid)
