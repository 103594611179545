import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import styled, { withTheme } from 'styled-components'

import RadialBarChart from '../../../common/RadialBarChart/RadialBarChart'
import AnalyticsCard from '../../AnalyticsCard/AnalyticsCard'
import { formatData } from '../../AnalyticsKeyStakeholders/AnalyticsKeyStakeholders'


const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
`


const titleMessages = defineMessages({
  outliers: {
    id: 'owner.KeyStakeholdersAnalyticsCard.title.outliers',
    defaultMessage: '{count} key {count, plural, one {stakeholder} other {stakeholders}}'
  },
})

class KeyStakeholdersAnalyticsCard extends Component {
  handleClick = () => this.props.onClick('stakeholders')
  renderChart(questionPercentage) {
    const { locked } = this.props
    return (
      <ChartWrapper>
        <RadialBarChart
          value={questionPercentage.outliers}
          text="Of all questions"
          height={170}
          width={170}
          inverted={locked}
        />
      </ChartWrapper>
    )
  }
  render() {
    const { intl, groups, participantActivity, locked, loading } = this.props
    if (loading) return (<AnalyticsCard loading locked={locked} />)

    let title, chart
    if (participantActivity && !loading) {
      const colors = () => 'white' // FIXME: Replace with mono color scale
      const { outliers, questionPercentage } = formatData(participantActivity, groups, colors)
      title = intl.formatMessage(titleMessages.outliers, { count: outliers.length })
      chart = this.renderChart(questionPercentage)
    } else {
      chart = this.renderChart({ outliers: 0 })
    }

    return (
      <AnalyticsCard
        subTitle="Key stakeholders"
        title={title}
        onClick={this.handleClick}
        disabled={!participantActivity && !locked}
        locked={locked}
        loading={loading}
      >
        {chart}
      </AnalyticsCard>
    )
  }
}

export default withTheme(injectIntl(KeyStakeholdersAnalyticsCard))
