import styled from 'styled-components'
import Icon from '../Icon/Icon'
import { defineMessages, injectIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import Button from '../Button/Button'


const ComponentRoot = styled(Button)`
  height: 26px;
  background-color: ${({ pinned }) => pinned ? '#EBECEC' : '#CCCECC'};
  color: ${({ theme, pinned }) => pinned ? theme.one[0] : theme.one[3]};
  cursor: ${({ clickable }) => clickable ? 'pointer' : 'not-allowed'};

  & > * {
    display: block;
    width: 14px;
    font-size: 15px;
    position: relative;
    top: 1px;
    color: ${({ theme, pinned }) => pinned ? theme.one[0] : theme.two[0]};
  }

  &&&:hover > * {
    color: ${({ theme, pinned }) => pinned ? theme.one[0] : theme.one[4]};
  }

  &:active {
    color: ${({ theme }) => theme.two[0]};
  }
`

const tooltipMessages = defineMessages({
  pin: { defaultMessage: 'Pin question', id: 'QuestionAnswerTable.PinIcon.tooltip.pin' },
  unpin: { defaultMessage: 'Unpin question', id: 'QuestionAnswerTable.PinIcon.tooltip.unpin' },
  out: { defaultMessage: 'You have used your 3 pins. Unpin a question to pin this one.', id: 'QuestionAnswerTable.PinIcon.tooltip.out' },
  locked: { defaultMessage: 'This is a preview. Only participants may pin questions.', id: 'QuestionAnswerTable.PinIcon.tooltip.locked' },
  closed: { defaultMessage: 'Times up! Pinning has closed.', id: 'QuestionAnswerTable.PinIcon.tooltip.closed' }
})

export default injectIntl((props) => {
  const { show, out, closed, locked, intl, onToggle } = props
  const [pinned, setPinned] = useState(null)
  useEffect(() => setPinned(props.pinned), [])

  let action
  if (locked) action ='locked'        // Locked preview (owner perspective)
  else if (closed) action = 'closed'  // Pinning closed (times up)
  else if (pinned) action ='unpin'    // Already pinned, you may unpin
  else if (out) action = 'out'        // Out of pins
  else action = 'pin'                 // You may pin
  const clickable = (action === 'unpin') || (action === 'pin')
  if (!show) return null

  const handleClick = async () => {
    if(!clickable) return
    setPinned(!pinned) // Optimistic update
    onToggle(!pinned)
      .catch(error => {
        setPinned(pinned)
        throw error
      })
  }

  return (
    <ComponentRoot
      disabled={!clickable}
      size="tiny"
      pinned={pinned}
      clickable={clickable}
      title={intl.formatMessage(tooltipMessages[action])}
      onClick={handleClick}
    >
      <Icon variant="thumbtack" size="small" />
    </ComponentRoot>
  )
})
