import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContainerDimensions from 'react-container-dimensions'

const TEXT_VERTICAL_SHIFT = 10

class PerParticipantChart extends Component {
  renderChart(width, height) {
    const { value, valueLabel, iconLabel, fill, compact } = this.props

    // calculate group positions
    const x1 = width / 4
    const x2 = x1 * 3
    const y1 = compact ? (height / 2) : (height / 2) + TEXT_VERTICAL_SHIFT

    // compose svg
    return (
      <svg width={width} height={height}>
        {/* draw middle line */}
        <g>
          <rect x={(width / 2) - 1} width="1" height={height} fill="#edeeee" />
        </g>
        {/* draw left group */}
        <g transform={`translate(${x1}, ${y1})`}>
          <text
            fill="#3d4640"
            textAnchor="middle"
            fontFamily="LL Circular Book Web, sans-serif"
            fontSize={compact ? '38px' : '82px'}
            fontWeight="500"
          >
            {value}
          </text>
          <text
            dy={TEXT_VERTICAL_SHIFT}
            fill="#3d4640"
            textAnchor="middle"
            alignmentBaseline="hanging"
            fontFamily="Raleway, sans-serif"
            fontSize="13px"
            fontWeight="500"
          >
            {valueLabel}
          </text>
        </g>
        {/* draw right group */}
        <g transform={`translate(${x2}, ${y1})`}>
          {/* inline dude svg */}
          <g transform={`translate(${compact ? -12.5 : -20}, ${compact ? -25 : -40})`}>
            <svg
              width={compact ? 25 : 40}
              height={compact ? 25 : 40}
              fill={fill}
              viewBox="1198 148 40 40"
            >
              <path d="M1234.75831,184.651982 C1234.26183,185.294864 1233.48656,185.678269 1232.68455,185.678269 L1203.88687,185.678269 C1203.08487,185.678269 1202.3096,185.294864 1201.81312,184.651982 C1201.34528,184.047829 1201.18488,183.31781 1201.35865,182.595537 C1203.28155,174.687321 1210.2418,169.162802 1218.28667,169.162802 C1226.32963,169.162802 1233.28988,174.687321 1235.21278,182.595537 C1235.38654,183.31781 1235.22614,184.047829 1234.75831,184.651982 M1210.14251,158.582369 C1210.14251,154.029917 1213.79545,150.323668 1218.28667,150.323668 C1222.77598,150.323668 1226.42892,154.029917 1226.42892,158.582369 C1226.42892,163.136758 1222.77598,166.839134 1218.28667,166.839134 C1213.79545,166.839134 1210.14251,163.136758 1210.14251,158.582369 M1237.43738,182.039793 C1235.71117,174.937116 1230.39502,169.536525 1223.72693,167.607881 C1226.71917,165.745074 1228.72036,162.397057 1228.72036,158.582369 C1228.72036,152.748027 1224.04009,148 1218.28667,148 C1212.53133,148 1207.85107,152.748027 1207.85107,158.582369 C1207.85107,162.397057 1209.85226,165.745074 1212.8445,167.607881 C1206.17641,169.536525 1200.86026,174.937116 1199.13404,182.039793 C1198.79224,183.447548 1199.11113,184.923077 1200.01052,186.082974 C1200.95001,187.3029 1202.36498,188 1203.88687,188 L1232.68455,188 C1234.20645,188 1235.62142,187.3029 1236.56091,186.082974 C1237.4603,184.923077 1237.77919,183.447548 1237.43738,182.039793" />
            </svg>
          </g>
          <text
            dy={TEXT_VERTICAL_SHIFT}
            fill="#3d4640"
            textAnchor="middle"
            alignmentBaseline="hanging"
            fontFamily="Raleway, sans-serif"
            fontSize="13px"
            fontWeight="500"
          >
            {iconLabel}
          </text>
        </g>
      </svg>
    )
  }

  render() {
    return (
      <div>
        <ContainerDimensions>
          {({ width, height }) => this.renderChart(width, height)}
        </ContainerDimensions>
      </div>
    )
  }
}

PerParticipantChart.propTypes = {
  value: PropTypes.number,
  valueLabel: PropTypes.string,
  iconLabel: PropTypes.string,
  fill: PropTypes.string,
  compact: PropTypes.bool,
}

PerParticipantChart.defaultProps = {
  value: 0,
  fill: '#3d4640',
  compact: false,
}

export default PerParticipantChart
