import React, { Component, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import DimmerLoader from '../../../components/common/DimmerLoader/DimmerLoader'
import QvestTabbedBar from '../../../components/owner/QvestTabbedBar/QvestTabbedBar'
import OwnerSubscriptionOverlay from '../OwnerSubscriptionOverlay'
import { getQvest, changeUnsavedQvest, updateQvest } from '../../../actions/qvestActions'
import { getParticipants } from '../../../actions/participantActions'
import { resetUnsavedGrouping, getGroupingByQvestId } from '../../../actions/groupingActions'
import { getQvestSchedule, initUnsavedSchedule } from '../../../actions/scheduleActions'
import { filterQvestsById } from '../../../reducers/qvests'
import { filterParticipantsByQvest } from '../../../reducers/participants'
import { filterByMyOrganizations } from '../../../reducers/organization'
import { filterUnsavedGrouping } from '../../../reducers/grouping'
import OwnerQvestParticipantsTab from './tabs/OwnerQvestParticipantsTab'
import OwnerQvestGroupsTab from './tabs/OwnerQvestGroupsTab'
import OwnerQvestTopicTab from './tabs/OwnerQvestTopicTab'
import OwnerQvestScheduleTab from './tabs/OwnerQvestScheduleTab'
import OwnerQvestParticipantOutputTab from './tabs/OwnerQvestParticipantOutputTab'
import QvestAnalyticsTab from './tabs/QvestAnalyticsTab'

const TABS = ['participants', 'groups', 'topic', 'schedule', 'participant-output', 'analytics']

const messages = defineMessages({
  participants: { id: 'owner.OwnerQvestPage.participants', defaultMessage: 'Participants' },
  groups: { id: 'owner.OwnerQvestPage.groups', defaultMessage: 'Groups' },
  topic: { id: 'owner.OwnerQvestPage.topic', defaultMessage: 'Topic' },
  schedule: { id: 'owner.OwnerQvestPage.schedule', defaultMessage: 'Scheduling' },
  'participant-output': { id: 'owner.OwnerQvestPage.participantOutput', defaultMessage: 'Participant output' },
  analytics: { id: 'owner.OwnerQvestPage.analytics', defaultMessage: 'Analytics' },
})

const ContentWrapper = styled.div`
  margin-top: 80px;
`

const determineTopicStatus = (qvest) => {
  const { validity } = qvest
  if (validity) {
    // When there are no errors topic is done (warnings are allowed)
    const isDone = (validity.general !== 'ERROR')
    if (isDone) {
      return 'DONE'
    }
    // If any of the "completely missing errors" present, then a draft must have been started
    const isDraft = (
      !validity.items.some(i => i.name === 'NO_TOPIC') ||
      !validity.items.some(i => i.name === 'NO_PAGE') ||
      (qvest.hasInvite && !validity.items.some(i => i.name === 'NO_INVITE_EMAIL'))
    )
    if (isDraft) {
      return 'DRAFT'
    }
  }
  // Otherwise, we don't show a status
  return null
}

class OwnerQvestPage extends Component {
  constructor(props) {
    super(props)
    this.handleTabClick = this.handleTabClick.bind(this)
    this.handleChangeUnsavedQvest = this.handleChangeUnsavedQvest.bind(this)
    this.handleSaveQvest = this.handleSaveQvest.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const qvestId = match.params.qvestId
    // TODO: refactor this to graphql
    dispatch(getQvest(qvestId))
      .then(qvest => dispatch({ type: 'INIT_UNSAVED_QVEST', qvest }))
    dispatch(getParticipants(qvestId))
    dispatch(resetUnsavedGrouping())
    dispatch(getGroupingByQvestId(qvestId))
    dispatch(getQvestSchedule(qvestId))
      .then(schedule => dispatch(initUnsavedSchedule(schedule)))
  }

  handleTabClick(tabId) {
    const { match, history } = this.props
    const qvestId = match.params.qvestId
    history.push(`/owner/qvest/${qvestId}/${tabId}`)
  }

  handleChangeUnsavedQvest(patch) {
    const { dispatch } = this.props
    dispatch(changeUnsavedQvest(patch))
  }

  handleCancel() {
    const { dispatch, qvestStore, match } = this.props
    const qvest = filterQvestsById(qvestStore, match.params.qvestId)
    dispatch(changeUnsavedQvest({
      name: qvest.name,
      language: qvest.language,
    }))
  }

  handleSaveQvest() {
    const { dispatch, match, qvestStore } = this.props
    if (!qvestStore.unsaved.name) return this.handleCancel()
    dispatch(updateQvest(match.params.qvestId, {
      name: qvestStore.unsaved.name,
      language: qvestStore.unsaved.language
    }))
  }

  createTabs(qvest) {
    const { intl, participantStore, groupingStore, match } = this.props
    const participants = filterParticipantsByQvest(participantStore, match.params.qvestId)
    const grouping = filterUnsavedGrouping(groupingStore)
    const groups = grouping.groups
    const selectedTabId = match.params.tabId

    // Filter suspended participants in tab count
    const participantCount = participants ? participants.filter(p => !p.isSuspended).length : null

    const subTexts = {}
    TABS.map(id => {
      switch (id) {
        case 'participants':
          subTexts[id] = participantCount
          break
        case 'groups':
          subTexts[id] = (groups && groups.length)
          break
        case 'topic':
          subTexts[id] = determineTopicStatus(qvest)
          break
        case 'schedule':
          subTexts[id] = qvest.state
          break
        default:
          subTexts[id] = ''
      }
    })

    return TABS.map(id => ({
      id,
      text: intl.formatMessage(messages[id]),
      subText: subTexts[id],
      active: id === selectedTabId,
    }))
  }

  render() {
    const { qvestStore, organizationStore, match } = this.props
    const qvestId = match.params.qvestId
    const organizations = filterByMyOrganizations(organizationStore)
    const qvest = filterQvestsById(qvestStore, qvestId)

    if (!organizations || !qvest) {
      return <DimmerLoader />
    }

    const pathPrefix = '/owner/qvest/:qvestId'
    return (
      <Fragment>
        <Helmet>
          <title>{qvest && qvest.name}</title>
        </Helmet>
        <QvestTabbedBar
          tabs={this.createTabs(qvest)}
          qvest={qvestStore.unsaved}
          onTabClick={this.handleTabClick}
          onSave={this.handleSaveQvest}
          onChange={this.handleChangeUnsavedQvest}
          onCancel={this.handleCancel}
        />
        <ContentWrapper>
          <Switch>
            <Route path={`${pathPrefix}/participants`} component={OwnerQvestParticipantsTab} />
            <Route path={`${pathPrefix}/groups`} component={OwnerQvestGroupsTab} />
            <Route path={`${pathPrefix}/topic`} component={OwnerQvestTopicTab} />
            <Route path={`${pathPrefix}/schedule`} component={OwnerQvestScheduleTab} />
            <Route path={`${pathPrefix}/participant-output`} component={OwnerQvestParticipantOutputTab} />
            <Route path={`${pathPrefix}/analytics/:tabId?`} component={QvestAnalyticsTab} />
            <Redirect to={`/owner/qvest/${qvestId}/participants`} />
          </Switch>
        </ContentWrapper>
        <OwnerSubscriptionOverlay key={qvest.organizationId} organizationId={qvest.organizationId} />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  organizationStore: state.organization.toJS(),
  qvestStore: state.qvests.toJS(),
  participantStore: state.participants.toJS(),
  groupingStore: state.grouping.toJS(),
  questionStore: state.questions.toJS()
})

export default connect(mapStateToProps)(injectIntl(OwnerQvestPage))
