import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'
import Card from '../../common/Card/Card'
import scheduledPng from '../../../static/img/schedule-modal.png'
import Heading from '../../common/Typography/Heading'


const ComponentRoot = styled.div`
  width: 464px;
`

const SpaciousCardBody = styled.div`
  align-items: center;
  padding: 24px 40px 32px 40px;
  text-align: center;
`

const descriptionMessages = defineMessages({
  invited: {
    defaultMessage: 'This is a test. The link in a real invitation email leads participants to your Qvest page.',
    id: 'owner.PagePreviewModal.description.invited'
  },
  opened: {
    defaultMessage: 'This is a test. The link in a real opening email leads participants to ask their first question at this point.',
    id: 'owner.PagePreviewModal.description.opened'
  },
  closed: {
    defaultMessage: 'This is a test. The link in a real closing-email leads participants to the actual Output page for their Qvest.',
    id: 'owner.PagePreviewModal.description.closed'
  }
})

const PagePreviewModal = ({ variant, intl }) => (
  <ComponentRoot>
    <Card>
      <img src={scheduledPng} alt="Checkmark illustration" />
      <SpaciousCardBody>
        <Heading.h2>
          <FormattedMessage defaultMessage="Everything looks fine" id="owner.PagePreviewModal.heading" />
        </Heading.h2>
        <Typography as='p' variant="medium" weight="light">
          {intl.formatMessage(descriptionMessages[variant])}
        </Typography>
      </SpaciousCardBody>
    </Card>
  </ComponentRoot>
)

PagePreviewModal.propTypes = {
  variant: PropTypes.oneOf([
    'invited',
    'opened',
    'closed'
  ]),
}

PagePreviewModal.defaultProps = {
  variant: 'invited'
}

export default injectIntl(PagePreviewModal)
