import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import Input from '../../common/Input/Input'
import Table from '../../common/Table/Table'
import Card from '../../common/Card/Card'
import Icon from '../../common/Icon/Icon'
import Button from '../../common/Button/Button'
import LanguageSelector from '../../common/LanguageSelector/LanguageSelector'


const ComponentRoot = styled.div`
  width: 670px;
`

const Form = styled.div`
  display: flex;
  & > :nth-child(1) {
    flex: 3;
    margin-right: 10px;
  }
  & > :nth-child(2) {
    flex: 1;
  }
`

const CloseButton = styled.button`
  display: ${({ show }) => show ? 'block' : 'none' };
  background-color: transparent;
  border-style: none;
  cursor: pointer;
  /* phasing out clickable prop on Icon for better accessibility */
  && > * { cursor: pointer; }
`

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 225px;
  border-width: 1px 0;
  border-style: solid;
  border-color:  ${({ theme }) => theme.one[3]};
`

const ScrollHint = styled.div`
  position: absolute;
  bottom: 58px;
  right: 30px;
  display: flex;
  align-items: center;
  background-color: #3d4641;
  color: ${({ theme }) => theme.inverted.one[0]};
  border-radius: 10px;
  padding: 5px 10px;
  font-family: ${({ theme }) => theme.font3};
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  visibility: ${({ show }) => show ? 'visible' : 'hidden'};

  & > :first-child {
    margin-right: 7px;
  }
`

const TableHeader = styled.div`
  display: ${({ show }) => show ? 'flex' : 'none'};
  align-items: center;
  height: 26px;
  margin-top: 16px;
  & > * { display: inline-block }
  & > :not(:last-child) { margin-right: 6px; }
`

function searchReplaceOrg(content, organizationName) {
  if (!organizationName) return content
  return content.replace('[organization]', organizationName)
}

const ResultRow = ({ content, clickable, onClick }) => (
  <Table.Row clickable={clickable} onClick={() => onClick(content)}>
    <td>
      {content}
    </td>
  </Table.Row>
)

const NoResultsRow = ({ show }) => {
  if (!show) return null
  return (
    <Table.Row>
      <td>
        <FormattedMessage
          defaultMessage="No topic matches your input."
          id="owner.TopicSuggestionModal.noResults"
        />
      </td>
    </Table.Row>
  )
}

const ResultTable = ({ show, submitable, suggestions, organizationName, onSubmit }) => {
  if (!show) return null
  return (
    <TableWrapper>
      <Table>
        <tbody>
          {suggestions.map(item => (
            <ResultRow
              key={item.id}
              content={searchReplaceOrg(item.content, organizationName)}
              clickable={submitable}
              onClick={onSubmit}
            />
          ))}
          <NoResultsRow show={suggestions && suggestions.length === 0} />
        </tbody>
      </Table>
      <ScrollHint show={suggestions && suggestions.length > 5}>
        <Icon inverted variant="arrow-down" />
        <span>{suggestions.length} suggestions found</span>
      </ScrollHint>
    </TableWrapper>
  )
}

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  return [ htmlElRef, setFocus ]
}

const LoadingIndicator = ({ show }) => {
  if(!show) return null
  return (
    <Typography secondary variant="medium">
      {'('}
      <FormattedMessage
        id="owner.TopicSuggestionModal.loading"
        defaultMessage="Loading"
      />
      {')'}
    </Typography>
  )
}

const TopicSuggestionModal = (props) => {
  const { loading, suggestions, organizationName, cancelable, submitable, onQuery, onSubmit, onCancel } = props
  const [query, setQuery] = useState('')
  const [language, setLanguage] = useState('en')
  const [inputRef, setInputFocus] = useFocus()
  const hasQuery = (query != null && query.length > 0)

  const handleSearch = (event) => {
    const query = event.target.value
    setQuery(query)
    onQuery(query, language)
  }

  const handleLanguageChange = (language) => {
    setLanguage(language)
    if (query) {
      onQuery(query, language)
    }
  }

  useEffect(() => setInputFocus(), [])

  return (
    <ComponentRoot>
      <Card>
        <Card.Header>
          <Heading id="dialog-label" variant="heading3">
            <FormattedMessage
              id="owner.TopicSuggestionModal.title"
              defaultMessage="Qvest Topic Generator"
            />
          </Heading>
          <CloseButton show={cancelable} onClick={onCancel}>
            <Icon variant="times" />
          </CloseButton>
        </Card.Header>
        <Card.Body>
          <Typography id="dialog-desc" weight="light">
            <FormattedMessage
              id="owner.TopicSuggestionModal.description"
              defaultMessage="Find topic inspiration by typing a few words that describe the topic of your Qvest"
            />
          </Typography>
          <br />
          <br />
          <Form>
            <Input
              type="text"
              variant="large"
              placeholder="Type words relevant to your Qvest topic - e.g. strategy or leadership"
              value={query}
              ref={inputRef}
              onChange={handleSearch}
            />
            <LanguageSelector value={language} onChange={handleLanguageChange} />
          </Form>
          <TableHeader show={hasQuery}>
            <Heading.h4>
              <FormattedMessage defaultMessage="Topic inspiration" id="owner.TopicSuggestionModal.result.title" />
            </Heading.h4>
            <LoadingIndicator show={loading} />
          </TableHeader>
        </Card.Body>
        <ResultTable
          show={hasQuery && suggestions}
          submitable={submitable}
          suggestions={suggestions}
          organizationName={organizationName}
          onSubmit={onSubmit}
        />
        <Card.Footer>
          {cancelable ?
            <Button onClick={onCancel} outline>
              <FormattedMessage id="owner.TopicSuggestionModal.close" defaultMessage="Close" />
            </Button>
            : null
          }
        </Card.Footer>
      </Card>
    </ComponentRoot>
  )
}

TopicSuggestionModal.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    language: PropTypes.string,
    content: PropTypes.string
  })),
  organizationName: PropTypes.string,
  cancelable: PropTypes.bool,
  submitable: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onQuery: PropTypes.func
}

TopicSuggestionModal.defaultProps = {
  onSubmit: () => { },
  onCancel: () => { },
  onQuery: () => { }
}

export default TopicSuggestionModal
