import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'
import Card from '../../common/Card/Card'
import Heading from '../../common/Typography/Heading'
import Button from '../../common/Button/Button'


const ComponentRoot = styled.div`
  width: 488px;
  max-width: 100%;
`

const SpaciousCardBody = styled.div`
  padding: 40px 40px 10px 40px;

  @media (max-width: 700px) {
    padding: 30px 20px 10px 20px;
  }
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px 40px 40px;
`

const TitleShortUnderline = styled.div`
  height: 3px;
  width: 86px;
  background-color: ${({ theme }) => theme.main.primary};
  margin: 14px 0 25px 0;
`

const ListSection = styled.span`
  & > :first-child {
    margin-bottom: 0;
  }

  & ol {
    list-style: none;
    counter-reset: qvest-counter;
    padding: 0;
    margin-top: 0;
  }
  & ol li {
    counter-increment: qvest-counter;
    position: relative;
  }
  & ol li::before {
    content: counter(qvest-counter) ".";
    color: ${({ theme }) => theme.one[0]};
    font-family: ${({ theme }) => theme.font1};
    font-size: 16px;
    line-height: 26px;
    margin-right: 6px;
  }
`


const QuestioningGuideModal = ({ onSubmit }) => (
  <ComponentRoot>
    <Card>
      <SpaciousCardBody>
        <Heading.h2>
          <FormattedMessage defaultMessage="Questioning guidelines" id="qvest.QuestioningGuideModal.heading" />
        </Heading.h2>
        <TitleShortUnderline />
        <Typography as='p' variant="medium" weight="light">
          <FormattedMessage defaultMessage="In Qvest, it is your questions that matter." id="qvest.QuestioningGuideModal.intro" />
        </Typography>
        <ListSection>
          <Typography as='p' variant="medium" weight="light">
            <FormattedMessage defaultMessage="Your question can be anything that is:" id="qvest.QuestioningGuideModal.yourQuestion" />
          </Typography>
          <ol>
            <li>
              <Typography variant="medium" weight="light">
                <FormattedMessage defaultMessage="Relevant to the Qvest topic" id="qvest.QuestioningGuideModal.yourQuestion.bullet1" />
              </Typography>
            </li>
            <li>
              <Typography variant="medium" weight="light">
                <FormattedMessage defaultMessage="Important for you to receive an answer to" id="qvest.QuestioningGuideModal.yourQuestion.bullet2" />
              </Typography>
            </li>
          </ol>
        </ListSection>
        <Typography as='p' variant="medium" weight="light">
          <FormattedMessage defaultMessage="You can send your question anonymously to anyone on the participant list." id="qvest.QuestioningGuideModal.anonymous" />
        </Typography>
        <Typography as='p' variant="medium" weight="light">
          <FormattedMessage defaultMessage="Please note that all questions will be reported exactly as written when the Qvest closes." id="qvest.QuestioningGuideModal.reported" />
        </Typography>
      </SpaciousCardBody>
      <Footer>
        <Button onClick={onSubmit}>
          <FormattedMessage defaultMessage="I'm ready to ask now" id="qvest.QuestioningGuideModal.submit" />
        </Button>
      </Footer>
    </Card>
  </ComponentRoot>
)

QuestioningGuideModal.propTypes = {
  onSubmit: PropTypes.func
}

export default QuestioningGuideModal
