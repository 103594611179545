import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import ContainerDimensions from 'react-container-dimensions'
import { WordCloud } from 'data-viz'

import CenteredLoader from '../../../../../components/common/CenteredLoader/CenteredLoader'
import Card from '../../../../../components/common/Card/Card'
import Button from '../../../../../components/common/Button/Button'
import Typography from '../../../../../components/common/Typography/Typography'
import Disclaimer from '../../../../../components/owner/Disclaimer/Disclaimer'
import { TitleBar } from '../../../../../components/owner/AnalyticsLayout/AnalyticsLayout'
import QuestionAnswerTable from '../../../../../components/common/QuestionAnswerTable/QuestionAnswerTable'
import AnalyticsHighlights from '../../../../../components/owner/AnalyticsHighlights/AnalyticsHighlights'
import { getWordFrequencies, downloadQuestionDump } from '../../../../../actions/statsActions'
import { filterWordFrequenciesByQvestId } from '../../../../../reducers/stats'
import { searchQuestions } from '../../../../../actions/questionActions'
import { filterQuestionSearchResult } from '../../../../../reducers/questions'
import { getGroupingByQvestId } from '../../../../../actions/groupingActions'
import { filterGroupingsByQvest } from '../../../../../reducers/grouping'
import { filterQvestsById } from '../../../../../reducers/qvests'


const ComponentRoot = styled.div`
  & > * { max-width: 1400px; }
  & > :nth-child(3) {
    padding: 0 30px;
    margin-top: 30px;
    min-width: 1270px;
  }
  & > :last-child {
    margin: 50px 30px 30px 30px;
    max-width: 989px
  }
`

class OwnerHighlightsPage extends Component {
  state = {
    showAllSearch: null,
    selectedWordSearch: null
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    dispatch(getWordFrequencies(match.params.qvestId))
    dispatch(getGroupingByQvestId(match.params.qvestId))
  }

  handleBackClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics/home`)
  }

  onShowAllClick = () => {
    const { dispatch, match } = this.props
    const searchParameters = { qvestId: match.params.qvestId }
    dispatch(searchQuestions(searchParameters))
    this.setState({
      showAllSearch: searchParameters,
      selectedWordSearch: null
    })
  }

  onDownloadClick = () => {
    const { qvestStore, match, dispatch } = this.props
    const qvest = filterQvestsById(qvestStore, match.params.qvestId)
    dispatch(downloadQuestionDump(match.params.qvestId, qvest.name))
  }

  renderDescription() {
    const showAll = (
      <Typography cta clickable onClick={this.onShowAllClick}>
        <FormattedMessage
          id="owner.HighlightsPage.showAll"
          defaultMessage="here to show all"
        />
      </Typography>
    )
    return (
      <Typography>
        <FormattedMessage
          id="owner.HighlightsPage.interactionHint"
          defaultMessage="Click the words and see the questions and answers or {showAll}"
          values={{ showAll }}
        />
      </Typography>
    )
  }

  render() {
    const { wordFrequenciesStore, questionStore, groupingStore, match } = this.props

    const wordFrequencies = filterWordFrequenciesByQvestId(wordFrequenciesStore, match.params.qvestId)
    const grouping = filterGroupingsByQvest(groupingStore, match.params.qvestId)

    let questions, highlightedWords
    if (this.state.showAllSearch) {
      questions = filterQuestionSearchResult(questionStore, this.state.showAllSearch)
    } else if (this.state.selectedWordSearch) {
      questions = filterQuestionSearchResult(questionStore, this.state.selectedWordSearch)
      highlightedWords = this.state.selectedWordFreq.words.map(w => w.text)
    }

    let questionAnswerTable
    if (questions) {
      questionAnswerTable = (
        <QuestionAnswerTable data={questions} highlights={highlightedWords}/>
      )
    }

    let highlights
    if (wordFrequencies && grouping) {
      highlights = (
        <AnalyticsHighlights
          wordFrequencies={wordFrequencies}
          groups={grouping.groups}
        />
      )
    }

    let wordCloud
    if (wordFrequencies) {
      wordCloud = (
        <ContainerDimensions>
          {({width}) =>
            <WordCloud
              words={wordFrequencies}
              height={600}
              width={width - 80}
              onWordClick={this.onWordClick}
              highlightedWords={highlightedWords || []}
            />
          }
        </ContainerDimensions>
      )
    } else {
      wordCloud = <CenteredLoader size="medium" />
    }

    const title = <FormattedMessage
      id="owner.AnalyticsHighlights.title"
      defaultMessage="Highlights"
    />
    const description = <FormattedMessage
      id="owner.AnalyticsHighlights.description"
      defaultMessage="Communication about Qvest topic"
    />

    return (
      <ComponentRoot>
        <TitleBar
          title={title}
          description={description}
          onBack={this.handleBackClick}
        />
        {highlights}
        <div>
          <Card>
            <Card.Header>
              <Typography variant="heading3">Conversation Highlights</Typography>
              <Button outline onClick={this.onDownloadClick}>Download</Button>
            </Card.Header>
            <Card.Body>
              {this.renderDescription()}
              {wordCloud}
              {questionAnswerTable}
            </Card.Body>
          </Card>
        </div>
        <Disclaimer />
      </ComponentRoot>
    )
  }

  onWordClick = (word) => {
    const { dispatch, match, wordFrequenciesStore } = this.props
    const wordFrequencies = filterWordFrequenciesByQvestId(wordFrequenciesStore, match.params.qvestId)
    const wordFreq = wordFrequencies.find(w => w.topWord === word)
    const searchParameters = {
      qvestId: match.params.qvestId,
      contentTextSearch: wordFreq.stem
    }
    dispatch(searchQuestions(searchParameters))
    this.setState({
      showAllSearch: null,
      selectedWordSearch: searchParameters,
      selectedWordFreq: wordFreq
    })
  }
}

function mapStateToProps(state) {
  return {
    qvestStore: state.qvests.toJS(),
    groupingStore: state.grouping.toJS(),
    questionStore: state.questions.toJS(),
    wordFrequenciesStore: state.stats.get('wordFrequencies').toJS()
  }
}

export default connect(mapStateToProps)(OwnerHighlightsPage)
