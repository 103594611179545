import React from 'react'
import { withTheme } from 'styled-components'

const oneWay = ({ nodeStyle, linkStyle }) => (
  <svg width="213px" height="64px" viewBox="0 0 213 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1245.000000, -408.000000)">
        <g transform="translate(133.000000, 270.000000)">
          <g transform="translate(1113.000000, 139.000000)">
            <path style={linkStyle} d="M116.581395,23.9651163 L116.581395,15 L135.581395,30.4651163 L116.581395,45.9302326 L116.581395,36.9651163 L78,36.9651163 L78,23.9651163 L116.581395,23.9651163 Z" fill="#777D79"></path>
            <path style={nodeStyle} d="M30,60 C46.5685425,60 60,46.5685425 60,30 C60,13.4314575 46.5685425,0 30,0 C13.4314575,0 0,13.4314575 0,30 C0,46.5685425 13.4314575,60 30,60 Z"></path>
            <path style={nodeStyle} d="M181,60 C197.568542,60 211,46.5685425 211,30 C211,13.4314575 197.568542,0 181,0 C164.431458,0 151,13.4314575 151,30 C151,46.5685425 164.431458,60 181,60 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const twoWay = ({ nodeStyle, linkStyle }) => (
  <svg width="213px" height="64px" viewBox="0 0 213 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-907.000000, -408.000000)">
        <g transform="translate(133.000000, 270.000000)">
          <g transform="translate(775.000000, 139.000000)">
            <path style={linkStyle} d="M121.616279,23.1151163 L121.616279,14.15 L140.616279,29.6151163 L121.616279,45.0802326 L121.616279,36.1151163 L94,36.1151163 L94,44.9302326 L75,29.4651163 L94,14 L94,23.1151163 L121.616279,23.1151163 Z"></path>
            <path style={nodeStyle} d="M30,60 C46.5685425,60 60,46.5685425 60,30 C60,13.4314575 46.5685425,0 30,0 C13.4314575,0 0,13.4314575 0,30 C0,46.5685425 13.4314575,60 30,60 Z"></path>
            <path style={nodeStyle} d="M181,60 C197.568542,60 211,46.5685425 211,30 C211,13.4314575 197.568542,0 181,0 C164.431458,0 151,13.4314575 151,30 C151,46.5685425 164.431458,60 181,60 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const strongOneWay = ({ nodeStyle, linkStyle }) => (
  <svg width="213px" height="64px" viewBox="0 0 257 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-509.000000, -548.000000)">
        <g transform="translate(133.000000, 270.000000)">
          <g>
            <g transform="translate(43.000000, 278.000000)">
              <g transform="translate(334.000000, 0.000000)">
                <path style={linkStyle} d="M142.616279,15 L142.616279,0.38372093 L179.616279,30.5 L142.616279,60.6162791 L142.616279,47 L84,47 L84,15 L142.616279,15 Z" fill="#777D79"></path>
                <path style={nodeStyle} d="M225,63 C241.568542,63 255,49.5685425 255,33 C255,16.4314575 241.568542,3 225,3 C208.431458,3 195,16.4314575 195,33 C195,49.5685425 208.431458,63 225,63 Z"></path>
                <path style={nodeStyle} d="M30,63 C46.5685425,63 60,49.5685425 60,33 C60,16.4314575 46.5685425,3 30,3 C13.4314575,3 0,16.4314575 0,33 C0,49.5685425 13.4314575,63 30,63 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const strongTwoWay = ({ nodeStyle, linkStyle }) => (
  <svg width="213px" height="64px" viewBox="0 0 261 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-175.000000, -548.000000)">
        <g transform="translate(133.000000, 270.000000)">
          <g>
            <g transform="translate(43.000000, 278.000000)">
              <path style={linkStyle} d="M150.616279,15 L150.616279,0.38372093 L187.616279,30.5 L150.616279,60.6162791 L150.616279,47 L107.616279,47 L107.616279,60.6162791 L70.6162791,30.5 L107.616279,0.38372093 L107.616279,15 L150.616279,15 Z" fill="#777D79"></path>
              <path style={nodeStyle} d="M229,63 C245.568542,63 259,49.5685425 259,33 C259,16.4314575 245.568542,3 229,3 C212.431458,3 199,16.4314575 199,33 C199,49.5685425 212.431458,63 229,63 Z"></path>
              <path style={nodeStyle} d="M30,63 C46.5685425,63 60,49.5685425 60,33 C60,16.4314575 46.5685425,3 30,3 C13.4314575,3 0,16.4314575 0,33 C0,49.5685425 13.4314575,63 30,63 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const selfReferential = ({ nodeStyle, linkStyle }) => (
  <svg width="75px" height="64px" viewBox="0 0 75 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M30,79 C46.5685425,79 60,65.5685425 60,49 C60,32.4314575 46.5685425,19 30,19 C13.4314575,19 0,32.4314575 0,49 C0,65.5685425 13.4314575,79 30,79 Z" id="self-referen2" fill="black"></path>
      <rect id="self-referen3" width="75px" height="64px" fill="white"></rect>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-901.000000, -754.000000)">
        <g transform="translate(133.000000, 270.000000)">
          <g transform="translate(769.000000, 484.000000)">
            <mask id="self-referen-mask-2">
              <use href="#self-referen3"></use>
              <use href="#self-referen2"></use>
            </mask>
            <path style={linkStyle} mask="url(#self-referen-mask-2)" d="M51.5,45 C39.0735931,45 29,34.9264069 29,22.5 C29,10.0735931 39.0735931,0 51.5,0 C63.9264069,0 74,10.0735931 74,22.5 C74,34.9264069 63.9264069,45 51.5,45 Z M51.5,38.7209302 C60.4585724,38.7209302 67.7209302,31.4585724 67.7209302,22.5 C67.7209302,13.5414276 60.4585724,6.27906977 51.5,6.27906977 C42.5414276,6.27906977 35.2790698,13.5414276 35.2790698,22.5 C35.2790698,31.4585724 42.5414276,38.7209302 51.5,38.7209302 Z" fill="#777D79" fillRule="nonzero"></path>
            <mask id="mask-1" fill="white">
              <path style={nodeStyle} d="M30,79 C46.5685425,79 60,65.5685425 60,49 C60,32.4314575 46.5685425,19 30,19 C13.4314575,19 0,32.4314575 0,49 C0,65.5685425 13.4314575,79 30,79 Z" id="self-referen1"></path>
            </mask>
            <use id="Mask-Copy-9" stroke="#D8D8D8" strokeLinecap="round" href="#self-referen1"></use>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const disconnected = ({ nodeStyle }) => (
  <svg width="62px" height="64px" viewBox="0 0 62 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1327.000000, -768.000000)">
        <g transform="translate(133.000000, 270.000000)">
          <path style={nodeStyle} d="M1225,559 C1241.56854,559 1255,545.568542 1255,529 C1255,512.431458 1241.56854,499 1225,499 C1208.43146,499 1195,512.431458 1195,529 C1195,545.568542 1208.43146,559 1225,559 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)

const SVG = {
  connected: {
    oneWay: oneWay,
    twoWay: twoWay
  },
  stronglyConnected: {
    oneWay: strongOneWay,
    twoWay: strongTwoWay
  },
  selfReferential: selfReferential,
  disconnected: disconnected
}

const InteractionIcon = ({ type, isOneWay, inverted, empty, theme }) => {
  let IconSvg
  const direction = isOneWay ? 'oneWay' : 'twoWay'
  if (type === 'connected' || type === 'stronglyConnected') {
    IconSvg = SVG[type][direction]
  } else {
    IconSvg = SVG[type]
  }

  const nodeStyle = {
    stroke: (inverted ? theme.inverted.one[1] : theme.one[1]),
    strokeDasharray:  (empty ?  '4,4,4,4' : null)
  }
  const linkStyle = {
    fill: (inverted ? theme.inverted.one[0] : theme.one[0])
  }

  return (
    <IconSvg nodeStyle={nodeStyle} linkStyle={linkStyle} />
  )
}

export default withTheme(InteractionIcon)
