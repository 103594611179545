import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import Icon from '../../common/Icon/Icon'
import Button from '../../common/Button/Button'
import Input from '../../common/Input/Input'
import LanguageSelector from '../../common/LanguageSelector/LanguageSelector'


const ComponentRoot = styled.div`
  position: fixed;
  left: 0;
  top: 45px;
  z-index: 10;
  width: 100%;
  height: 100px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.background1};
  border-top: 1px solid ${({ theme }) => theme.background3};
  display: flex;
  align-items: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  & + * { margin-top: 100px; }
  & > :first-child {
    position: relative;
    bottom: 2px;
  }
`

const ButtonGroup = styled.div`
  margin-left: auto;

  & > :not(:last-child) {
    margin-right: 20px;
  }
`

// TODO: Get rid of most of the form component styling by introducing "large" variant of form elements, perhaps even large EditableSpan component
const CreateForm = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
  & > :first-child {
    flex: 0 0 236px;
  }
  & > :nth-child(2) {
    flex: 1;
    height: 44px;
    font-size: 14px;
    font-weight: 600;
  }
  & > :nth-child(3) {
    width: 120px;
  }
`


const messages = defineMessages({
  placeholder: { id: 'owner.OwnerOrganizationPage.placeholder', defaultMessage: 'Save my Qvest as...' },
})


class OrganizationBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: 'Qvest name',
      language: 'en'
    }
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutside)
    this.rootElement.addEventListener('mouseup', this.handleClickInside)
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside)
    this.rootElement.removeEventListener('mouseup', this.handleClickInside)
  }

  handleQvestChange = (change) => {
    this.setState(change)
  }

  handleSave = () => {
    const { name, language } = this.state
    this.props.onSave({ name, language })
  }

  handleKeyDown = ({ key }) => {
    const { onCancelEdit } = this.props
    if (key === 'Enter') {
      this.handleSave()
    } else if (key === 'Escape') {
      onCancelEdit()
    }
  }

  handleClickInside = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  handleClickOutside = () => {
    const { isCreatingQvest, onCancelEdit } = this.props
    if (isCreatingQvest) {
      onCancelEdit()
    }
  }

  renderCreateQvest() {
    const { intl, onCancelEdit } = this.props
    return (
      <CreateForm>
        <Heading.h2>Start a new Qvest</Heading.h2>
        <Input
          autoFocus
          type="text"
          placeholder={intl.formatMessage(messages.placeholder)}
          value={this.state.name}
          onChange={({ target }) => this.handleQvestChange({ name: target.value })}
        />
        <LanguageSelector
          value={this.state.language}
          onChange={language => this.handleQvestChange({ language })}
        />
        <Button onClick={this.handleSave}>
          <Icon clickable tertiary variant="check"/>
        </Button>
        <Icon clickable variant="times" onClick={onCancelEdit} />
      </CreateForm>
    )
  }

  renderMenu() {
    const { organization, onStartEdit } = this.props
    const { name } = organization
    return (
      <Fragment>
        {organization && <Typography variant="heading1">{name}</Typography>}
        <ButtonGroup>
          <Button onClick={onStartEdit}>
            <FormattedMessage id="owner.OrganizationBar.newQvest" defaultMessage="New Qvest"/>
          </Button>
        </ButtonGroup>
      </Fragment>
    )
  }

  render() {
    return (
      <ComponentRoot
        ref={elm => this.rootElement = elm}
        onKeyDown={this.handleKeyDown}
      >
        {this.props.isCreatingQvest ? this.renderCreateQvest() : this.renderMenu()}
      </ComponentRoot>
    )
  }
}


OrganizationBar.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  onStartEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onSave: PropTypes.func,
  isCreatingQvest: PropTypes.bool
}

OrganizationBar.defaultProps = {
  onStartEdit: () => { },
  onCancelEdit: () => { },
  onSave: () => { },
  isCreatingQvest: false
}

export default injectIntl(OrganizationBar)
