import React, { Component, Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { GroupsNetworkGraph } from 'data-viz'

import DynamicContainerDimensions from '../DynamicContainerDimensions/DynamicContainerDimensions'
import { makeGroupColorScales } from '../../../utils/d3Utils'
import CenteredLoader from '../../common/CenteredLoader/CenteredLoader'
import Typography from '../../common/Typography/Typography'
import Heading from '../../common/Typography/Heading'
import GroupLegend from '../../common/GroupLegend/GroupLegend'

const ChartWrapper = styled.div`
  min-width: 400px;
  max-width: 100%;
  @media (max-width: 500px) {
    min-width: 300px;
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > *:first-child { width: 100%; }
  @media (min-width: 900px) {
    flex-wrap: nowrap;
    & > *:first-child { width: 50%; }
  }
`

class GroupDynamicsCard extends Component {
  renderData() {
    const { theme, groupGraphData, groupGraphConfig } = this.props
    const colors = makeGroupColorScales(theme, groupGraphData.nodes.map(n => n.id)).primaryColors

    let graphComponent = null, legend = null
    if (groupGraphData && groupGraphConfig) {
      graphComponent = (
        <DynamicContainerDimensions>
          {({ width }) =>
            <GroupsNetworkGraph
              {...groupGraphConfig}
              data={groupGraphData}
              colors={colors}
              width={width}
              height={300}
            />
          }
        </DynamicContainerDimensions>
      )

      legend = <GroupLegend
        groups={groupGraphData.nodes.map(n => ({ groupId: n.id, name: n.name }))}
        colors={colors}
      />
    }
    return (
      <ColumnWrapper>
        <ChartWrapper>
          {graphComponent}
        </ChartWrapper>
        {legend}
      </ColumnWrapper>
    )
  }

  renderError(error, callToAction) {
    let errorMessage
    switch (error.message) {
      case 'NO_DATA':
        errorMessage = (
          <FormattedMessage
            id="result.GroupDynamicsCard.noData"
            defaultMessage="Not enough questions and answers to draw network."
          />
        )
        break
      default:
        errorMessage = (
          <FormattedMessage
            id="result.GroupDynamicsCard.unexpectedError"
            defaultMessage="We're sorry – an unexpected error occurred."
          />
        )
    }
    return (
      <Typography cta={callToAction} weight="bold">
        {errorMessage}
      </Typography>
    )
  }

  render() {
    const { error, isLoading, groupGraphData, cardRef } = this.props

    let body
    if (error) {
      body = this.renderError(error, true)
    } else if (isLoading) {
      body = (<CenteredLoader height={484} />)
    } else if (groupGraphData == null) {
      body = this.renderError({ message: 'NO_DATA' })
    } else {
      body = this.renderData()
    }

    return (
      <Fragment>
        <Heading.h2 ref={cardRef}>
          <FormattedMessage id="result.GroupDynamicsCard.title" defaultMessage="Network" />
        </Heading.h2>
        {body}
      </Fragment>
    )
  }
}

export default withTheme(GroupDynamicsCard)
