import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

import AnalyticsInsights from '../../../../../components/owner/AnalyticsInsights/AnalyticsInsights'
import { getGroupGraph } from '../../../../../actions/statsActions'
import { filterQvestSummaryByQvestId } from '../../../../../reducers/stats'


const ANALYTICS_INSIGHTS_QUERY = gql`
  query q ($qvestId:String!) {
    themeSet(qvestId: $qvestId) {
      themeSetId
      themes {
        name
        themeId
        createdAt
        themeSet {
          themeSetId
        }
        questionIds
      }
    }
  }
`


class OwnerInsightsPage extends Component {
  state = {
    themeSet: null,
    error: null
  }

  componentDidMount() {
    const { dispatch, match, client } = this.props
    const qvestId = match.params.qvestId

    dispatch(getGroupGraph(qvestId))

    this.apolloObservable = client.watchQuery({ query: ANALYTICS_INSIGHTS_QUERY, variables: { qvestId } }).subscribe({
      next: (result) => this.setState({ themeSet: result.data.themeSet }),
      error: (errors) => this.setState({ error: errors })
    })
  }

  componentWillUnmount() {
    if (this.apolloObservable) {
      this.apolloObservable.unsubscribe()
    }
  }

  handleNetworkClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics/network`)
  }

  handleThemesClick = () => {
    const { match, history } = this.props
    history.push(`/owner/qvest/${match.params.qvestId}/analytics/themes`)
  }

  handleDownload = () => {
    // console.log('Download')
  }

  handleLearnMore = () => {
    // console.log('Learn more')
  }

  render() {
    const { groupGraphStore, summaryStore, match } = this.props
    const { themeSet } = this.state
    const summary = filterQvestSummaryByQvestId(summaryStore, match.params.qvestId)

    const questionCount = summary ? summary.questions : 0
    const yOffset = 130 // Fixed nav bar + tabbed bar height

    return (
      <AnalyticsInsights
        yOffset={yOffset}
        groupGraph={groupGraphStore.data && groupGraphStore.data[match.params.qvestId]}
        themes={themeSet && themeSet.themes}
        questionCount={questionCount}
        onNetworkClick={this.handleNetworkClick}
        onThemesClick={this.handleThemesClick}
        onDownload={this.handleDownload}
        onLearnMore={this.handleLearnMore}
      />
    )
  }
}


function mapStateToProps(state) {
  return {
    groupGraphStore: state.stats.get('groupGraph').toJS(),
    summaryStore: state.stats.get('qvestSummary').toJS()
  }
}

export default connect(mapStateToProps)(withApollo(OwnerInsightsPage))
