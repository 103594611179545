import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import CenteredLoader from '../CenteredLoader/CenteredLoader'

const isHoverable = ({ clickable, disabled }) => (clickable && !disabled)

const ComponentRoot = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: ${({ theme }) => theme.one[0] };
  font-family: ${({ theme }) => theme.font1 };

  & > thead > tr,& > tbody > tr {
    height: 45px;
    word-wrap: break-word;
    & > * { padding-left: 25px; }
    & > :last-child { padding-right: 25px; }
  }

  & > thead {
    background-color: #f6f6f3;
    text-align: left;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
  }

  & > tbody {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  & > tbody > *:nth-child(${({ odd }) => odd ? 'odd' : 'even'}) {
    background-color: #f6f6f3;
  }

  & a {
    color: ${({ theme }) => theme.default };
  }
`

export const HoverIcons = styled.div`
  display: flex;
  float: right;
  & > *:not(:first-child) { margin-left: 8px; }
`

export const Row = styled.tr`
  opacity: ${({ disabled }) => disabled ? '0.5' : '1.0'};

  & ${HoverIcons} { visibility: hidden; }
  &:hover ${HoverIcons} { visibility: visible; }

  user-select: ${props => isHoverable(props) ? 'none' : 'default'};
  cursor: ${props => isHoverable(props) ? 'pointer' : 'unset'};
  ${props => isHoverable(props) ? `
    &&:hover {
      color: ${props.theme.color[0]};
      background-color: ${props.theme.color[3]};
    }
  ` : ''}
`

const Table = ({ isLoading, children, odd }) => {
  if (isLoading) return <CenteredLoader height={200} />
  return (
    <ComponentRoot odd={odd}>
      {children}
    </ComponentRoot>
  )
}

Table.propTypes = {
  isLoading: PropTypes.bool
}

Table.defaultProps = {
  isLoading: false
}

Table.Row = Row

export default Table
